// variables
// --------------------------

$fa-css-prefix : fa !default;
$fa-style : 900 !default;
$fa-style-family : "Font Awesome 6 Pro" !default;

$fa-display : inline-block !default;

$fa-fw-width : fa-divide(20em, 16) !default;
$fa-inverse : #fff !default;

$fa-border-color : #eee !default;
$fa-border-padding : .2em .25em .15em !default;
$fa-border-radius : .1em !default;
$fa-border-style : solid !default;
$fa-border-width : .08em !default;

$fa-size-scale-2xs : 10 !default;
$fa-size-scale-xs : 12 !default;
$fa-size-scale-sm : 14 !default;
$fa-size-scale-base : 16 !default;
$fa-size-scale-lg : 20 !default;
$fa-size-scale-xl : 24 !default;
$fa-size-scale-2xl : 32 !default;

$fa-sizes: (
     "2xs" : $fa-size-scale-2xs,
     "xs" : $fa-size-scale-xs,
     "sm" : $fa-size-scale-sm,
     "lg" : $fa-size-scale-lg,
     "xl" : $fa-size-scale-xl,
     "2xl" : $fa-size-scale-2xl) !default;

$fa-li-width : 2em !default;
$fa-li-margin : $fa-li-width * fa-divide(5, 4) !default;

$fa-pull-margin : .3em !default;

$fa-primary-opacity : 1 !default;
$fa-secondary-opacity : .4 !default;

$fa-stack-vertical-align: middle !default;
$fa-stack-width : ($fa-fw-width * 2) !default;
$fa-stack-z-index : auto !default;

$fa-font-display : block !default;
$fa-font-path : "../webfonts" !default;

$fa-var-0: \30;
$fa-var-1: \31;
$fa-var-2: \32;
$fa-var-3: \33;
$fa-var-4: \34;
$fa-var-5: \35;
$fa-var-6: \36;
$fa-var-7: \37;
$fa-var-8: \38;
$fa-var-9: \39;
$fa-var-fill-drip: \f576;
$fa-var-arrows-to-circle: \e4bd;
$fa-var-circle-chevron-right: \f138;
$fa-var-chevron-circle-right: \f138;
$fa-var-wagon-covered: \f8ee;
$fa-var-line-height: \f871;
$fa-var-bagel: \e3d7;
$fa-var-transporter-7: \e2a8;
$fa-var-at: \40;
$fa-var-rectangles-mixed: \e323;
$fa-var-phone-arrow-up-right: \e224;
$fa-var-phone-arrow-up: \e224;
$fa-var-phone-outgoing: \e224;
$fa-var-trash-can: \f2ed;
$fa-var-trash-alt: \f2ed;
$fa-var-circle-l: \e114;
$fa-var-head-side-goggles: \f6ea;
$fa-var-head-vr: \f6ea;
$fa-var-text-height: \f034;
$fa-var-user-xmark: \f235;
$fa-var-user-times: \f235;
$fa-var-face-hand-yawn: \e379;
$fa-var-gauge-simple-min: \f62d;
$fa-var-tachometer-slowest: \f62d;
$fa-var-stethoscope: \f0f1;
$fa-var-coffin: \f6c6;
$fa-var-message: \f27a;
$fa-var-comment-alt: \f27a;
$fa-var-salad: \f81e;
$fa-var-bowl-salad: \f81e;
$fa-var-info: \f129;
$fa-var-robot-astromech: \e2d2;
$fa-var-ring-diamond: \e5ab;
$fa-var-fondue-pot: \e40d;
$fa-var-theta: \f69e;
$fa-var-face-hand-peeking: \e481;
$fa-var-square-user: \e283;
$fa-var-down-left-and-up-right-to-center: \f422;
$fa-var-compress-alt: \f422;
$fa-var-explosion: \e4e9;
$fa-var-file-lines: \f15c;
$fa-var-file-alt: \f15c;
$fa-var-file-text: \f15c;
$fa-var-wave-square: \f83e;
$fa-var-ring: \f70b;
$fa-var-building-un: \e4d9;
$fa-var-dice-three: \f527;
$fa-var-tire-pressure-warning: \f633;
$fa-var-wifi-fair: \f6ab;
$fa-var-wifi-2: \f6ab;
$fa-var-calendar-days: \f073;
$fa-var-calendar-alt: \f073;
$fa-var-mp3-player: \f8ce;
$fa-var-anchor-circle-check: \e4aa;
$fa-var-tally-4: \e297;
$fa-var-rectangle-history: \e4a2;
$fa-var-building-circle-arrow-right: \e4d1;
$fa-var-volleyball: \f45f;
$fa-var-volleyball-ball: \f45f;
$fa-var-sun-haze: \f765;
$fa-var-text-size: \f894;
$fa-var-ufo: \e047;
$fa-var-fork: \f2e3;
$fa-var-utensil-fork: \f2e3;
$fa-var-arrows-up-to-line: \e4c2;
$fa-var-mobile-signal: \e1ef;
$fa-var-barcode-scan: \f465;
$fa-var-sort-down: \f0dd;
$fa-var-sort-desc: \f0dd;
$fa-var-folder-arrow-down: \e053;
$fa-var-folder-download: \e053;
$fa-var-circle-minus: \f056;
$fa-var-minus-circle: \f056;
$fa-var-face-icicles: \e37c;
$fa-var-shovel: \f713;
$fa-var-door-open: \f52b;
$fa-var-films: \e17a;
$fa-var-right-from-bracket: \f2f5;
$fa-var-sign-out-alt: \f2f5;
$fa-var-face-glasses: \e377;
$fa-var-nfc: \e1f7;
$fa-var-atom: \f5d2;
$fa-var-soap: \e06e;
$fa-var-icons: \f86d;
$fa-var-heart-music-camera-bolt: \f86d;
$fa-var-microphone-lines-slash: \f539;
$fa-var-microphone-alt-slash: \f539;
$fa-var-closed-captioning-slash: \e135;
$fa-var-calculator-simple: \f64c;
$fa-var-calculator-alt: \f64c;
$fa-var-bridge-circle-check: \e4c9;
$fa-var-sliders-up: \f3f1;
$fa-var-sliders-v: \f3f1;
$fa-var-location-minus: \f609;
$fa-var-map-marker-minus: \f609;
$fa-var-pump-medical: \e06a;
$fa-var-fingerprint: \f577;
$fa-var-ski-boot: \e3cc;
$fa-var-standard-definition: \e28a;
$fa-var-rectangle-sd: \e28a;
$fa-var-h1: \f313;
$fa-var-hand-point-right: \f0a4;
$fa-var-magnifying-glass-location: \f689;
$fa-var-search-location: \f689;
$fa-var-message-bot: \e3b8;
$fa-var-forward-step: \f051;
$fa-var-step-forward: \f051;
$fa-var-face-smile-beam: \f5b8;
$fa-var-smile-beam: \f5b8;
$fa-var-light-ceiling: \e016;
$fa-var-message-exclamation: \f4a5;
$fa-var-comment-alt-exclamation: \f4a5;
$fa-var-bowl-scoop: \e3de;
$fa-var-bowl-shaved-ice: \e3de;
$fa-var-square-x: \e286;
$fa-var-utility-pole-double: \e2c4;
$fa-var-flag-checkered: \f11e;
$fa-var-chevrons-up: \f325;
$fa-var-chevron-double-up: \f325;
$fa-var-football: \f44e;
$fa-var-football-ball: \f44e;
$fa-var-user-vneck: \e461;
$fa-var-school-circle-exclamation: \e56c;
$fa-var-crop: \f125;
$fa-var-angles-down: \f103;
$fa-var-angle-double-down: \f103;
$fa-var-users-rectangle: \e594;
$fa-var-people-roof: \e537;
$fa-var-square-arrow-right: \f33b;
$fa-var-arrow-square-right: \f33b;
$fa-var-location-plus: \f60a;
$fa-var-map-marker-plus: \f60a;
$fa-var-lightbulb-exclamation-on: \e1ca;
$fa-var-people-line: \e534;
$fa-var-beer-mug-empty: \f0fc;
$fa-var-beer: \f0fc;
$fa-var-crate-empty: \e151;
$fa-var-diagram-predecessor: \e477;
$fa-var-transporter: \e042;
$fa-var-calendar-circle-user: \e471;
$fa-var-arrow-up-long: \f176;
$fa-var-long-arrow-up: \f176;
$fa-var-person-carry-box: \f4cf;
$fa-var-person-carry: \f4cf;
$fa-var-fire-flame-simple: \f46a;
$fa-var-burn: \f46a;
$fa-var-person: \f183;
$fa-var-male: \f183;
$fa-var-laptop: \f109;
$fa-var-file-csv: \f6dd;
$fa-var-menorah: \f676;
$fa-var-union: \f6a2;
$fa-var-chevrons-left: \f323;
$fa-var-chevron-double-left: \f323;
$fa-var-circle-heart: \f4c7;
$fa-var-heart-circle: \f4c7;
$fa-var-truck-plane: \e58f;
$fa-var-record-vinyl: \f8d9;
$fa-var-bring-forward: \f856;
$fa-var-square-p: \e279;
$fa-var-face-grin-stars: \f587;
$fa-var-grin-stars: \f587;
$fa-var-sigma: \f68b;
$fa-var-camera-movie: \f8a9;
$fa-var-bong: \f55c;
$fa-var-clarinet: \f8ad;
$fa-var-truck-flatbed: \e2b6;
$fa-var-spaghetti-monster-flying: \f67b;
$fa-var-pastafarianism: \f67b;
$fa-var-arrow-down-up-across-line: \e4af;
$fa-var-leaf-heart: \f4cb;
$fa-var-house-building: \e1b1;
$fa-var-cheese-swiss: \f7f0;
$fa-var-spoon: \f2e5;
$fa-var-utensil-spoon: \f2e5;
$fa-var-jar-wheat: \e517;
$fa-var-envelopes-bulk: \f674;
$fa-var-mail-bulk: \f674;
$fa-var-file-circle-exclamation: \e4eb;
$fa-var-bow-arrow: \f6b9;
$fa-var-cart-xmark: \e0dd;
$fa-var-hexagon-xmark: \f2ee;
$fa-var-times-hexagon: \f2ee;
$fa-var-xmark-hexagon: \f2ee;
$fa-var-circle-h: \f47e;
$fa-var-hospital-symbol: \f47e;
$fa-var-merge: \e526;
$fa-var-pager: \f815;
$fa-var-cart-minus: \e0db;
$fa-var-address-book: \f2b9;
$fa-var-contact-book: \f2b9;
$fa-var-pan-frying: \e42c;
$fa-var-grid: \e195;
$fa-var-grid-3: \e195;
$fa-var-football-helmet: \f44f;
$fa-var-hand-love: \e1a5;
$fa-var-trees: \f724;
$fa-var-strikethrough: \f0cc;
$fa-var-page: \e428;
$fa-var-k: \4b;
$fa-var-diagram-previous: \e478;
$fa-var-gauge-min: \f628;
$fa-var-tachometer-alt-slowest: \f628;
$fa-var-folder-grid: \e188;
$fa-var-eggplant: \e16c;
$fa-var-ram: \f70a;
$fa-var-landmark-flag: \e51c;
$fa-var-lips: \f600;
$fa-var-pencil: \f303;
$fa-var-pencil-alt: \f303;
$fa-var-backward: \f04a;
$fa-var-caret-right: \f0da;
$fa-var-comments: \f086;
$fa-var-paste: \f0ea;
$fa-var-file-clipboard: \f0ea;
$fa-var-desktop-arrow-down: \e155;
$fa-var-code-pull-request: \e13c;
$fa-var-pumpkin: \f707;
$fa-var-clipboard-list: \f46d;
$fa-var-pen-field: \e211;
$fa-var-blueberries: \e2e8;
$fa-var-truck-ramp-box: \f4de;
$fa-var-truck-loading: \f4de;
$fa-var-note: \e1ff;
$fa-var-arrow-down-to-square: \e096;
$fa-var-user-check: \f4fc;
$fa-var-cloud-xmark: \e35f;
$fa-var-vial-virus: \e597;
$fa-var-book-blank: \f5d9;
$fa-var-book-alt: \f5d9;
$fa-var-golf-flag-hole: \e3ac;
$fa-var-message-arrow-down: \e1db;
$fa-var-comment-alt-arrow-down: \e1db;
$fa-var-face-unamused: \e39f;
$fa-var-sheet-plastic: \e571;
$fa-var-circle-9: \e0f6;
$fa-var-blog: \f781;
$fa-var-user-ninja: \f504;
$fa-var-pencil-slash: \e215;
$fa-var-bowling-pins: \f437;
$fa-var-person-arrow-up-from-line: \e539;
$fa-var-down-right: \e16b;
$fa-var-scroll-torah: \f6a0;
$fa-var-torah: \f6a0;
$fa-var-blinds-open: \f8fc;
$fa-var-fence: \e303;
$fa-var-up: \f357;
$fa-var-arrow-alt-up: \f357;
$fa-var-broom-ball: \f458;
$fa-var-quidditch: \f458;
$fa-var-quidditch-broom-ball: \f458;
$fa-var-drumstick: \f6d6;
$fa-var-square-v: \e284;
$fa-var-face-awesome: \e409;
$fa-var-gave-dandy: \e409;
$fa-var-dial-off: \e162;
$fa-var-toggle-off: \f204;
$fa-var-face-smile-horns: \e391;
$fa-var-box-archive: \f187;
$fa-var-archive: \f187;
$fa-var-grapes: \e306;
$fa-var-person-drowning: \e545;
$fa-var-dial-max: \e15e;
$fa-var-circle-m: \e115;
$fa-var-calendar-image: \e0d4;
$fa-var-circle-caret-down: \f32d;
$fa-var-caret-circle-down: \f32d;
$fa-var-arrow-down-9-1: \f886;
$fa-var-sort-numeric-desc: \f886;
$fa-var-sort-numeric-down-alt: \f886;
$fa-var-face-grin-tongue-squint: \f58a;
$fa-var-grin-tongue-squint: \f58a;
$fa-var-shish-kebab: \f821;
$fa-var-spray-can: \f5bd;
$fa-var-alarm-snooze: \f845;
$fa-var-scarecrow: \f70d;
$fa-var-truck-monster: \f63b;
$fa-var-gift-card: \f663;
$fa-var-w: \57;
$fa-var-code-pull-request-draft: \e3fa;
$fa-var-square-b: \e264;
$fa-var-elephant: \f6da;
$fa-var-earth-africa: \f57c;
$fa-var-globe-africa: \f57c;
$fa-var-rainbow: \f75b;
$fa-var-circle-notch: \f1ce;
$fa-var-tablet-screen-button: \f3fa;
$fa-var-tablet-alt: \f3fa;
$fa-var-paw: \f1b0;
$fa-var-message-question: \e1e3;
$fa-var-cloud: \f0c2;
$fa-var-trowel-bricks: \e58a;
$fa-var-square-3: \e258;
$fa-var-face-flushed: \f579;
$fa-var-flushed: \f579;
$fa-var-hospital-user: \f80d;
$fa-var-microwave: \e01b;
$fa-var-tent-arrow-left-right: \e57f;
$fa-var-cart-circle-arrow-up: \e3f0;
$fa-var-trash-clock: \e2b0;
$fa-var-gavel: \f0e3;
$fa-var-legal: \f0e3;
$fa-var-sprinkler-ceiling: \e44c;
$fa-var-browsers: \e0cb;
$fa-var-trillium: \e588;
$fa-var-music-slash: \f8d1;
$fa-var-truck-ramp: \f4e0;
$fa-var-binoculars: \f1e5;
$fa-var-microphone-slash: \f131;
$fa-var-box-tissue: \e05b;
$fa-var-circle-c: \e101;
$fa-var-star-christmas: \f7d4;
$fa-var-chart-bullet: \e0e1;
$fa-var-motorcycle: \f21c;
$fa-var-tree-christmas: \f7db;
$fa-var-tire-flat: \f632;
$fa-var-sunglasses: \f892;
$fa-var-badge: \f335;
$fa-var-message-pen: \f4a4;
$fa-var-comment-alt-edit: \f4a4;
$fa-var-message-edit: \f4a4;
$fa-var-bell-concierge: \f562;
$fa-var-concierge-bell: \f562;
$fa-var-pen-ruler: \f5ae;
$fa-var-pencil-ruler: \f5ae;
$fa-var-chess-rook-piece: \f448;
$fa-var-chess-rook-alt: \f448;
$fa-var-square-root: \f697;
$fa-var-album-collection-circle-plus: \e48e;
$fa-var-people-arrows: \e068;
$fa-var-people-arrows-left-right: \e068;
$fa-var-face-angry-horns: \e368;
$fa-var-mars-and-venus-burst: \e523;
$fa-var-tombstone: \f720;
$fa-var-square-caret-right: \f152;
$fa-var-caret-square-right: \f152;
$fa-var-scissors: \f0c4;
$fa-var-cut: \f0c4;
$fa-var-list-music: \f8c9;
$fa-var-sun-plant-wilt: \e57a;
$fa-var-toilets-portable: \e584;
$fa-var-hockey-puck: \f453;
$fa-var-mustache: \e5bc;
$fa-var-hyphen: \2d;
$fa-var-table: \f0ce;
$fa-var-user-chef: \e3d2;
$fa-var-message-image: \e1e0;
$fa-var-comment-alt-image: \e1e0;
$fa-var-users-medical: \f830;
$fa-var-sensor-triangle-exclamation: \e029;
$fa-var-sensor-alert: \e029;
$fa-var-magnifying-glass-arrow-right: \e521;
$fa-var-tachograph-digital: \f566;
$fa-var-digital-tachograph: \f566;
$fa-var-face-mask: \e37f;
$fa-var-pickleball: \e435;
$fa-var-star-sharp-half: \e28c;
$fa-var-users-slash: \e073;
$fa-var-clover: \e139;
$fa-var-meat: \f814;
$fa-var-reply: \f3e5;
$fa-var-mail-reply: \f3e5;
$fa-var-star-and-crescent: \f699;
$fa-var-empty-set: \f656;
$fa-var-house-fire: \e50c;
$fa-var-square-minus: \f146;
$fa-var-minus-square: \f146;
$fa-var-helicopter: \f533;
$fa-var-bird: \e469;
$fa-var-compass: \f14e;
$fa-var-square-caret-down: \f150;
$fa-var-caret-square-down: \f150;
$fa-var-heart-half-stroke: \e1ac;
$fa-var-heart-half-alt: \e1ac;
$fa-var-file-circle-question: \e4ef;
$fa-var-laptop-code: \f5fc;
$fa-var-joystick: \f8c5;
$fa-var-grill-fire: \e5a4;
$fa-var-rectangle-vertical-history: \e237;
$fa-var-swatchbook: \f5c3;
$fa-var-prescription-bottle: \f485;
$fa-var-bars: \f0c9;
$fa-var-navicon: \f0c9;
$fa-var-keyboard-left: \e1c3;
$fa-var-people-group: \e533;
$fa-var-hourglass-end: \f253;
$fa-var-hourglass-3: \f253;
$fa-var-heart-crack: \f7a9;
$fa-var-heart-broken: \f7a9;
$fa-var-face-beam-hand-over-mouth: \e47c;
$fa-var-droplet-percent: \f750;
$fa-var-humidity: \f750;
$fa-var-square-up-right: \f360;
$fa-var-external-link-square-alt: \f360;
$fa-var-face-kiss-beam: \f597;
$fa-var-kiss-beam: \f597;
$fa-var-corn: \f6c7;
$fa-var-roller-coaster: \e324;
$fa-var-photo-film-music: \e228;
$fa-var-radar: \e024;
$fa-var-sickle: \f822;
$fa-var-film: \f008;
$fa-var-coconut: \e2f6;
$fa-var-ruler-horizontal: \f547;
$fa-var-shield-cross: \f712;
$fa-var-cassette-tape: \f8ab;
$fa-var-square-terminal: \e32a;
$fa-var-people-robbery: \e536;
$fa-var-lightbulb: \f0eb;
$fa-var-caret-left: \f0d9;
$fa-var-comment-middle: \e149;
$fa-var-trash-can-list: \e2ab;
$fa-var-block: \e46a;
$fa-var-circle-exclamation: \f06a;
$fa-var-exclamation-circle: \f06a;
$fa-var-school-circle-xmark: \e56d;
$fa-var-arrow-right-from-bracket: \f08b;
$fa-var-sign-out: \f08b;
$fa-var-face-frown-slight: \e376;
$fa-var-circle-chevron-down: \f13a;
$fa-var-chevron-circle-down: \f13a;
$fa-var-sidebar-flip: \e24f;
$fa-var-unlock-keyhole: \f13e;
$fa-var-unlock-alt: \f13e;
$fa-var-temperature-list: \e299;
$fa-var-cloud-showers-heavy: \f740;
$fa-var-headphones-simple: \f58f;
$fa-var-headphones-alt: \f58f;
$fa-var-sitemap: \f0e8;
$fa-var-pipe-section: \e438;
$fa-var-space-station-moon-construction: \e034;
$fa-var-space-station-moon-alt: \e034;
$fa-var-circle-dollar-to-slot: \f4b9;
$fa-var-donate: \f4b9;
$fa-var-memory: \f538;
$fa-var-face-sleeping: \e38d;
$fa-var-road-spikes: \e568;
$fa-var-fire-burner: \e4f1;
$fa-var-squirrel: \f71a;
$fa-var-arrow-up-to-line: \f341;
$fa-var-arrow-to-top: \f341;
$fa-var-flag: \f024;
$fa-var-face-cowboy-hat: \e36e;
$fa-var-hanukiah: \f6e6;
$fa-var-chart-scatter-3d: \e0e8;
$fa-var-square-code: \e267;
$fa-var-feather: \f52d;
$fa-var-volume-low: \f027;
$fa-var-volume-down: \f027;
$fa-var-xmark-to-slot: \f771;
$fa-var-times-to-slot: \f771;
$fa-var-vote-nay: \f771;
$fa-var-box-taped: \f49a;
$fa-var-box-alt: \f49a;
$fa-var-comment-slash: \f4b3;
$fa-var-swords: \f71d;
$fa-var-cloud-sun-rain: \f743;
$fa-var-album: \f89f;
$fa-var-circle-n: \e118;
$fa-var-compress: \f066;
$fa-var-wheat-awn: \e2cd;
$fa-var-wheat-alt: \e2cd;
$fa-var-ankh: \f644;
$fa-var-hands-holding-child: \e4fa;
$fa-var-asterisk: \2a;
$fa-var-key-skeleton-left-right: \e3b4;
$fa-var-comment-lines: \f4b0;
$fa-var-luchador-mask: \f455;
$fa-var-luchador: \f455;
$fa-var-mask-luchador: \f455;
$fa-var-square-check: \f14a;
$fa-var-check-square: \f14a;
$fa-var-shredder: \f68a;
$fa-var-book-open-cover: \e0c0;
$fa-var-book-open-alt: \e0c0;
$fa-var-sandwich: \f81f;
$fa-var-peseta-sign: \e221;
$fa-var-square-parking-slash: \f617;
$fa-var-parking-slash: \f617;
$fa-var-train-tunnel: \e454;
$fa-var-heading: \f1dc;
$fa-var-header: \f1dc;
$fa-var-ghost: \f6e2;
$fa-var-face-anguished: \e369;
$fa-var-hockey-sticks: \f454;
$fa-var-abacus: \f640;
$fa-var-film-simple: \f3a0;
$fa-var-film-alt: \f3a0;
$fa-var-list: \f03a;
$fa-var-list-squares: \f03a;
$fa-var-tree-palm: \f82b;
$fa-var-square-phone-flip: \f87b;
$fa-var-phone-square-alt: \f87b;
$fa-var-cart-plus: \f217;
$fa-var-gamepad: \f11b;
$fa-var-border-center-v: \f89d;
$fa-var-circle-dot: \f192;
$fa-var-dot-circle: \f192;
$fa-var-clipboard-medical: \e133;
$fa-var-face-dizzy: \f567;
$fa-var-dizzy: \f567;
$fa-var-egg: \f7fb;
$fa-var-up-to-line: \f34d;
$fa-var-arrow-alt-to-top: \f34d;
$fa-var-house-medical-circle-xmark: \e513;
$fa-var-watch-fitness: \f63e;
$fa-var-clock-nine-thirty: \e34d;
$fa-var-campground: \f6bb;
$fa-var-folder-plus: \f65e;
$fa-var-jug: \f8c6;
$fa-var-futbol: \f1e3;
$fa-var-futbol-ball: \f1e3;
$fa-var-soccer-ball: \f1e3;
$fa-var-snow-blowing: \f761;
$fa-var-paintbrush: \f1fc;
$fa-var-paint-brush: \f1fc;
$fa-var-lock: \f023;
$fa-var-arrow-down-from-line: \f345;
$fa-var-arrow-from-top: \f345;
$fa-var-gas-pump: \f52f;
$fa-var-signal-bars-slash: \f694;
$fa-var-signal-alt-slash: \f694;
$fa-var-monkey: \f6fb;
$fa-var-rectangle-pro: \e235;
$fa-var-pro: \e235;
$fa-var-house-night: \e010;
$fa-var-hot-tub-person: \f593;
$fa-var-hot-tub: \f593;
$fa-var-blanket: \f498;
$fa-var-map-location: \f59f;
$fa-var-map-marked: \f59f;
$fa-var-house-flood-water: \e50e;
$fa-var-comments-question-check: \e14f;
$fa-var-tree: \f1bb;
$fa-var-arrows-cross: \e0a2;
$fa-var-backpack: \f5d4;
$fa-var-square-small: \e27e;
$fa-var-folder-arrow-up: \e054;
$fa-var-folder-upload: \e054;
$fa-var-bridge-lock: \e4cc;
$fa-var-crosshairs-simple: \e59f;
$fa-var-sack-dollar: \f81d;
$fa-var-pen-to-square: \f044;
$fa-var-edit: \f044;
$fa-var-square-sliders: \f3f0;
$fa-var-sliders-h-square: \f3f0;
$fa-var-car-side: \f5e4;
$fa-var-message-middle-top: \e1e2;
$fa-var-comment-middle-top-alt: \e1e2;
$fa-var-lightbulb-on: \f672;
$fa-var-knife: \f2e4;
$fa-var-utensil-knife: \f2e4;
$fa-var-share-nodes: \f1e0;
$fa-var-share-alt: \f1e0;
$fa-var-wave-sine: \f899;
$fa-var-heart-circle-minus: \e4ff;
$fa-var-circle-w: \e12c;
$fa-var-circle-calendar: \e102;
$fa-var-calendar-circle: \e102;
$fa-var-hourglass-half: \f252;
$fa-var-hourglass-2: \f252;
$fa-var-microscope: \f610;
$fa-var-sunset: \f767;
$fa-var-sink: \e06d;
$fa-var-calendar-exclamation: \f334;
$fa-var-truck-container-empty: \e2b5;
$fa-var-hand-heart: \f4bc;
$fa-var-bag-shopping: \f290;
$fa-var-shopping-bag: \f290;
$fa-var-arrow-down-z-a: \f881;
$fa-var-sort-alpha-desc: \f881;
$fa-var-sort-alpha-down-alt: \f881;
$fa-var-mitten: \f7b5;
$fa-var-reply-clock: \e239;
$fa-var-reply-time: \e239;
$fa-var-person-rays: \e54d;
$fa-var-right: \f356;
$fa-var-arrow-alt-right: \f356;
$fa-var-circle-f: \e10e;
$fa-var-users: \f0c0;
$fa-var-face-pleading: \e386;
$fa-var-eye-slash: \f070;
$fa-var-flask-vial: \e4f3;
$fa-var-police-box: \e021;
$fa-var-cucumber: \e401;
$fa-var-head-side-brain: \f808;
$fa-var-hand: \f256;
$fa-var-hand-paper: \f256;
$fa-var-person-biking-mountain: \f84b;
$fa-var-biking-mountain: \f84b;
$fa-var-utensils-slash: \e464;
$fa-var-print-magnifying-glass: \f81a;
$fa-var-print-search: \f81a;
$fa-var-folder-bookmark: \e186;
$fa-var-om: \f679;
$fa-var-pi: \f67e;
$fa-var-flask-round-potion: \f6e1;
$fa-var-flask-potion: \f6e1;
$fa-var-face-shush: \e38c;
$fa-var-worm: \e599;
$fa-var-house-circle-xmark: \e50b;
$fa-var-plug: \f1e6;
$fa-var-calendar-circle-exclamation: \e46e;
$fa-var-square-i: \e272;
$fa-var-chevron-up: \f077;
$fa-var-face-saluting: \e484;
$fa-var-gauge-simple-low: \f62c;
$fa-var-tachometer-slow: \f62c;
$fa-var-face-persevering: \e385;
$fa-var-circle-camera: \e103;
$fa-var-camera-circle: \e103;
$fa-var-hand-spock: \f259;
$fa-var-spider-web: \f719;
$fa-var-circle-microphone: \e116;
$fa-var-microphone-circle: \e116;
$fa-var-book-arrow-up: \e0ba;
$fa-var-popsicle: \e43e;
$fa-var-command: \e142;
$fa-var-blinds: \f8fb;
$fa-var-stopwatch: \f2f2;
$fa-var-saxophone: \f8dc;
$fa-var-square-2: \e257;
$fa-var-field-hockey-stick-ball: \f44c;
$fa-var-field-hockey: \f44c;
$fa-var-arrow-up-square-triangle: \f88b;
$fa-var-sort-shapes-up-alt: \f88b;
$fa-var-face-scream: \e38b;
$fa-var-square-m: \e276;
$fa-var-camera-web: \f832;
$fa-var-webcam: \f832;
$fa-var-comment-arrow-down: \e143;
$fa-var-lightbulb-cfl: \e5a6;
$fa-var-window-frame-open: \e050;
$fa-var-face-kiss: \f596;
$fa-var-kiss: \f596;
$fa-var-bridge-circle-xmark: \e4cb;
$fa-var-period: \2e;
$fa-var-face-grin-tongue: \f589;
$fa-var-grin-tongue: \f589;
$fa-var-up-to-dotted-line: \e457;
$fa-var-thought-bubble: \e32e;
$fa-var-raygun: \e025;
$fa-var-flute: \f8b9;
$fa-var-acorn: \f6ae;
$fa-var-video-arrow-up-right: \e2c9;
$fa-var-grate-droplet: \e194;
$fa-var-seal-exclamation: \e242;
$fa-var-chess-bishop: \f43a;
$fa-var-message-sms: \e1e5;
$fa-var-coffee-beans: \e13f;
$fa-var-hat-witch: \f6e7;
$fa-var-face-grin-wink: \f58c;
$fa-var-grin-wink: \f58c;
$fa-var-clock-three-thirty: \e357;
$fa-var-ear-deaf: \f2a4;
$fa-var-deaf: \f2a4;
$fa-var-deafness: \f2a4;
$fa-var-hard-of-hearing: \f2a4;
$fa-var-alarm-clock: \f34e;
$fa-var-eclipse: \f749;
$fa-var-face-relieved: \e389;
$fa-var-road-circle-check: \e564;
$fa-var-dice-five: \f523;
$fa-var-octagon-minus: \f308;
$fa-var-minus-octagon: \f308;
$fa-var-square-rss: \f143;
$fa-var-rss-square: \f143;
$fa-var-face-zany: \e3a4;
$fa-var-tricycle: \e5c3;
$fa-var-land-mine-on: \e51b;
$fa-var-square-arrow-up-left: \e263;
$fa-var-i-cursor: \f246;
$fa-var-salt-shaker: \e446;
$fa-var-stamp: \f5bf;
$fa-var-file-plus: \f319;
$fa-var-draw-square: \f5ef;
$fa-var-toilet-paper-under-slash: \e2a1;
$fa-var-toilet-paper-reverse-slash: \e2a1;
$fa-var-stairs: \e289;
$fa-var-drone-front: \f860;
$fa-var-drone-alt: \f860;
$fa-var-glass-empty: \e191;
$fa-var-dial-high: \e15c;
$fa-var-user-helmet-safety: \f82c;
$fa-var-user-construction: \f82c;
$fa-var-user-hard-hat: \f82c;
$fa-var-i: \49;
$fa-var-hryvnia-sign: \f6f2;
$fa-var-hryvnia: \f6f2;
$fa-var-arrow-down-left-and-arrow-up-right-to-center: \e092;
$fa-var-pills: \f484;
$fa-var-face-grin-wide: \f581;
$fa-var-grin-alt: \f581;
$fa-var-tooth: \f5c9;
$fa-var-basketball-hoop: \f435;
$fa-var-objects-align-bottom: \e3bb;
$fa-var-v: \56;
$fa-var-sparkles: \f890;
$fa-var-squid: \e450;
$fa-var-leafy-green: \e41d;
$fa-var-circle-arrow-up-right: \e0fc;
$fa-var-calendars: \e0d7;
$fa-var-bangladeshi-taka-sign: \e2e6;
$fa-var-bicycle: \f206;
$fa-var-hammer-war: \f6e4;
$fa-var-circle-d: \e104;
$fa-var-spider-black-widow: \f718;
$fa-var-staff-snake: \e579;
$fa-var-rod-asclepius: \e579;
$fa-var-rod-snake: \e579;
$fa-var-staff-aesculapius: \e579;
$fa-var-pear: \e20c;
$fa-var-head-side-cough-slash: \e062;
$fa-var-triangle: \f2ec;
$fa-var-apartment: \e468;
$fa-var-truck-medical: \f0f9;
$fa-var-ambulance: \f0f9;
$fa-var-pepper: \e432;
$fa-var-piano: \f8d4;
$fa-var-gun-squirt: \e19d;
$fa-var-wheat-awn-circle-exclamation: \e598;
$fa-var-snowman: \f7d0;
$fa-var-user-alien: \e04a;
$fa-var-shield-check: \f2f7;
$fa-var-mortar-pestle: \f5a7;
$fa-var-road-barrier: \e562;
$fa-var-chart-candlestick: \e0e2;
$fa-var-briefcase-blank: \e0c8;
$fa-var-school: \f549;
$fa-var-igloo: \f7ae;
$fa-var-bracket-round: \28;
$fa-var-parenthesis: \28;
$fa-var-joint: \f595;
$fa-var-horse-saddle: \f8c3;
$fa-var-mug-marshmallows: \f7b7;
$fa-var-filters: \e17e;
$fa-var-bell-on: \f8fa;
$fa-var-angle-right: \f105;
$fa-var-dial-med: \e15f;
$fa-var-horse: \f6f0;
$fa-var-q: \51;
$fa-var-monitor-waveform: \f611;
$fa-var-monitor-heart-rate: \f611;
$fa-var-link-simple: \e1cd;
$fa-var-whistle: \f460;
$fa-var-g: \47;
$fa-var-wine-glass-crack: \f4bb;
$fa-var-fragile: \f4bb;
$fa-var-slot-machine: \e3ce;
$fa-var-notes-medical: \f481;
$fa-var-car-wash: \f5e6;
$fa-var-escalator: \e171;
$fa-var-comment-image: \e148;
$fa-var-temperature-half: \f2c9;
$fa-var-temperature-2: \f2c9;
$fa-var-thermometer-2: \f2c9;
$fa-var-thermometer-half: \f2c9;
$fa-var-dong-sign: \e169;
$fa-var-donut: \e406;
$fa-var-doughnut: \e406;
$fa-var-capsules: \f46b;
$fa-var-poo-storm: \f75a;
$fa-var-poo-bolt: \f75a;
$fa-var-tally-1: \e294;
$fa-var-face-frown-open: \f57a;
$fa-var-frown-open: \f57a;
$fa-var-square-dashed: \e269;
$fa-var-square-j: \e273;
$fa-var-hand-point-up: \f0a6;
$fa-var-money-bill: \f0d6;
$fa-var-arrow-up-big-small: \f88e;
$fa-var-sort-size-up: \f88e;
$fa-var-barcode-read: \f464;
$fa-var-baguette: \e3d8;
$fa-var-bowl-soft-serve: \e46b;
$fa-var-face-holding-back-tears: \e482;
$fa-var-square-up: \f353;
$fa-var-arrow-alt-square-up: \f353;
$fa-var-train-subway-tunnel: \e2a3;
$fa-var-subway-tunnel: \e2a3;
$fa-var-square-exclamation: \f321;
$fa-var-exclamation-square: \f321;
$fa-var-semicolon: \3b;
$fa-var-bookmark: \f02e;
$fa-var-fan-table: \e004;
$fa-var-align-justify: \f039;
$fa-var-battery-low: \e0b1;
$fa-var-battery-1: \e0b1;
$fa-var-credit-card-front: \f38a;
$fa-var-brain-arrow-curved-right: \f677;
$fa-var-mind-share: \f677;
$fa-var-umbrella-beach: \f5ca;
$fa-var-helmet-un: \e503;
$fa-var-location-smile: \f60d;
$fa-var-map-marker-smile: \f60d;
$fa-var-arrow-left-to-line: \f33e;
$fa-var-arrow-to-left: \f33e;
$fa-var-bullseye: \f140;
$fa-var-sushi: \e48a;
$fa-var-nigiri: \e48a;
$fa-var-message-captions: \e1de;
$fa-var-comment-alt-captions: \e1de;
$fa-var-trash-list: \e2b1;
$fa-var-bacon: \f7e5;
$fa-var-option: \e318;
$fa-var-hand-point-down: \f0a7;
$fa-var-arrow-up-from-bracket: \e09a;
$fa-var-trash-plus: \e2b2;
$fa-var-objects-align-top: \e3c0;
$fa-var-folder: \f07b;
$fa-var-folder-blank: \f07b;
$fa-var-face-anxious-sweat: \e36a;
$fa-var-credit-card-blank: \f389;
$fa-var-file-waveform: \f478;
$fa-var-file-medical-alt: \f478;
$fa-var-microchip-ai: \e1ec;
$fa-var-mug: \f874;
$fa-var-plane-up-slash: \e22e;
$fa-var-radiation: \f7b9;
$fa-var-pen-circle: \e20e;
$fa-var-chart-simple: \e473;
$fa-var-crutches: \f7f8;
$fa-var-circle-parking: \f615;
$fa-var-parking-circle: \f615;
$fa-var-mars-stroke: \f229;
$fa-var-leaf-oak: \f6f7;
$fa-var-square-bolt: \e265;
$fa-var-vial: \f492;
$fa-var-gauge: \f624;
$fa-var-dashboard: \f624;
$fa-var-gauge-med: \f624;
$fa-var-tachometer-alt-average: \f624;
$fa-var-wand-magic-sparkles: \e2ca;
$fa-var-magic-wand-sparkles: \e2ca;
$fa-var-lambda: \f66e;
$fa-var-e: \45;
$fa-var-pizza: \f817;
$fa-var-bowl-chopsticks-noodles: \e2ea;
$fa-var-h3: \f315;
$fa-var-pen-clip: \f305;
$fa-var-pen-alt: \f305;
$fa-var-bridge-circle-exclamation: \e4ca;
$fa-var-badge-percent: \f646;
$fa-var-user: \f007;
$fa-var-sensor: \e028;
$fa-var-comma: \2c;
$fa-var-school-circle-check: \e56b;
$fa-var-toilet-paper-under: \e2a0;
$fa-var-toilet-paper-reverse: \e2a0;
$fa-var-light-emergency: \e41f;
$fa-var-arrow-down-to-arc: \e4ae;
$fa-var-dumpster: \f793;
$fa-var-van-shuttle: \f5b6;
$fa-var-shuttle-van: \f5b6;
$fa-var-building-user: \e4da;
$fa-var-light-switch: \e017;
$fa-var-square-caret-left: \f191;
$fa-var-caret-square-left: \f191;
$fa-var-highlighter: \f591;
$fa-var-wave-pulse: \f5f8;
$fa-var-heart-rate: \f5f8;
$fa-var-key: \f084;
$fa-var-hat-santa: \f7a7;
$fa-var-tamale: \e451;
$fa-var-box-check: \f467;
$fa-var-bullhorn: \f0a1;
$fa-var-steak: \f824;
$fa-var-location-crosshairs-slash: \f603;
$fa-var-location-slash: \f603;
$fa-var-person-dolly: \f4d0;
$fa-var-globe: \f0ac;
$fa-var-synagogue: \f69b;
$fa-var-file-chart-column: \f659;
$fa-var-file-chart-line: \f659;
$fa-var-person-half-dress: \e548;
$fa-var-folder-image: \e18a;
$fa-var-calendar-pen: \f333;
$fa-var-calendar-edit: \f333;
$fa-var-road-bridge: \e563;
$fa-var-face-smile-tear: \e393;
$fa-var-message-plus: \f4a8;
$fa-var-comment-alt-plus: \f4a8;
$fa-var-location-arrow: \f124;
$fa-var-c: \43;
$fa-var-tablet-button: \f10a;
$fa-var-rectangle-history-circle-user: \e4a4;
$fa-var-building-lock: \e4d6;
$fa-var-chart-line-up: \e0e5;
$fa-var-mailbox: \f813;
$fa-var-truck-bolt: \e3d0;
$fa-var-pizza-slice: \f818;
$fa-var-money-bill-wave: \f53a;
$fa-var-chart-area: \f1fe;
$fa-var-area-chart: \f1fe;
$fa-var-house-flag: \e50d;
$fa-var-person-circle-minus: \e540;
$fa-var-scalpel: \f61d;
$fa-var-ban: \f05e;
$fa-var-cancel: \f05e;
$fa-var-bell-exclamation: \f848;
$fa-var-circle-bookmark: \e100;
$fa-var-bookmark-circle: \e100;
$fa-var-egg-fried: \f7fc;
$fa-var-face-weary: \e3a1;
$fa-var-uniform-martial-arts: \e3d1;
$fa-var-camera-rotate: \e0d8;
$fa-var-sun-dust: \f764;
$fa-var-comment-text: \e14d;
$fa-var-spray-can-sparkles: \f5d0;
$fa-var-air-freshener: \f5d0;
$fa-var-signal-bars: \f690;
$fa-var-signal-alt: \f690;
$fa-var-signal-alt-4: \f690;
$fa-var-signal-bars-strong: \f690;
$fa-var-diamond-exclamation: \e405;
$fa-var-star: \f005;
$fa-var-dial-min: \e161;
$fa-var-repeat: \f363;
$fa-var-cross: \f654;
$fa-var-page-caret-down: \e429;
$fa-var-file-caret-down: \e429;
$fa-var-box: \f466;
$fa-var-venus-mars: \f228;
$fa-var-clock-seven-thirty: \e351;
$fa-var-arrow-pointer: \f245;
$fa-var-mouse-pointer: \f245;
$fa-var-clock-four-thirty: \e34b;
$fa-var-signal-bars-good: \f693;
$fa-var-signal-alt-3: \f693;
$fa-var-cactus: \f8a7;
$fa-var-maximize: \f31e;
$fa-var-expand-arrows-alt: \f31e;
$fa-var-charging-station: \f5e7;
$fa-var-shapes: \f61f;
$fa-var-triangle-circle-square: \f61f;
$fa-var-plane-tail: \e22c;
$fa-var-gauge-simple-max: \f62b;
$fa-var-tachometer-fastest: \f62b;
$fa-var-circle-u: \e127;
$fa-var-shield-slash: \e24b;
$fa-var-square-phone-hangup: \e27a;
$fa-var-phone-square-down: \e27a;
$fa-var-arrow-up-left: \e09d;
$fa-var-transporter-1: \e043;
$fa-var-peanuts: \e431;
$fa-var-shuffle: \f074;
$fa-var-random: \f074;
$fa-var-person-running: \f70c;
$fa-var-running: \f70c;
$fa-var-mobile-retro: \e527;
$fa-var-grip-lines-vertical: \f7a5;
$fa-var-arrow-up-from-square: \e09c;
$fa-var-file-dashed-line: \f877;
$fa-var-page-break: \f877;
$fa-var-bracket-curly-right: \7d;
$fa-var-spider: \f717;
$fa-var-clock-three: \e356;
$fa-var-hands-bound: \e4f9;
$fa-var-scalpel-line-dashed: \f61e;
$fa-var-scalpel-path: \f61e;
$fa-var-file-invoice-dollar: \f571;
$fa-var-pipe-smoking: \e3c4;
$fa-var-face-astonished: \e36b;
$fa-var-window: \f40e;
$fa-var-plane-circle-exclamation: \e556;
$fa-var-ear: \f5f0;
$fa-var-file-lock: \e3a6;
$fa-var-diagram-venn: \e15a;
$fa-var-x-ray: \f497;
$fa-var-goal-net: \e3ab;
$fa-var-coffin-cross: \e051;
$fa-var-spell-check: \f891;
$fa-var-location-xmark: \f60e;
$fa-var-map-marker-times: \f60e;
$fa-var-map-marker-xmark: \f60e;
$fa-var-lasso: \f8c8;
$fa-var-slash: \f715;
$fa-var-person-to-portal: \e022;
$fa-var-portal-enter: \e022;
$fa-var-calendar-star: \f736;
$fa-var-computer-mouse: \f8cc;
$fa-var-mouse: \f8cc;
$fa-var-arrow-right-to-bracket: \f090;
$fa-var-sign-in: \f090;
$fa-var-pegasus: \f703;
$fa-var-files-medical: \f7fd;
$fa-var-nfc-lock: \e1f8;
$fa-var-person-ski-lift: \f7c8;
$fa-var-ski-lift: \f7c8;
$fa-var-square-6: \e25b;
$fa-var-shop-slash: \e070;
$fa-var-store-alt-slash: \e070;
$fa-var-wind-turbine: \f89b;
$fa-var-sliders-simple: \e253;
$fa-var-badge-sheriff: \f8a2;
$fa-var-server: \f233;
$fa-var-virus-covid-slash: \e4a9;
$fa-var-intersection: \f668;
$fa-var-shop-lock: \e4a5;
$fa-var-family: \e300;
$fa-var-hourglass-start: \f251;
$fa-var-hourglass-1: \f251;
$fa-var-user-hair-buns: \e3d3;
$fa-var-blender-phone: \f6b6;
$fa-var-hourglass-clock: \e41b;
$fa-var-person-seat-reclined: \e21f;
$fa-var-paper-plane-top: \e20a;
$fa-var-paper-plane-alt: \e20a;
$fa-var-send: \e20a;
$fa-var-message-arrow-up: \e1dc;
$fa-var-comment-alt-arrow-up: \e1dc;
$fa-var-lightbulb-exclamation: \f671;
$fa-var-layer-minus: \f5fe;
$fa-var-layer-group-minus: \f5fe;
$fa-var-circle-e: \e109;
$fa-var-building-wheat: \e4db;
$fa-var-gauge-max: \f626;
$fa-var-tachometer-alt-fastest: \f626;
$fa-var-person-breastfeeding: \e53a;
$fa-var-apostrophe: \27;
$fa-var-fire-hydrant: \e17f;
$fa-var-right-to-bracket: \f2f6;
$fa-var-sign-in-alt: \f2f6;
$fa-var-video-plus: \f4e1;
$fa-var-square-right: \f352;
$fa-var-arrow-alt-square-right: \f352;
$fa-var-comment-smile: \f4b4;
$fa-var-venus: \f221;
$fa-var-passport: \f5ab;
$fa-var-inbox-in: \f310;
$fa-var-inbox-arrow-down: \f310;
$fa-var-heart-pulse: \f21e;
$fa-var-heartbeat: \f21e;
$fa-var-circle-8: \e0f5;
$fa-var-clouds-moon: \f745;
$fa-var-clock-ten-thirty: \e355;
$fa-var-people-carry-box: \f4ce;
$fa-var-people-carry: \f4ce;
$fa-var-folder-user: \e18e;
$fa-var-trash-can-xmark: \e2ae;
$fa-var-temperature-high: \f769;
$fa-var-microchip: \f2db;
$fa-var-left-long-to-line: \e41e;
$fa-var-crown: \f521;
$fa-var-weight-hanging: \f5cd;
$fa-var-xmarks-lines: \e59a;
$fa-var-file-prescription: \f572;
$fa-var-calendar-range: \e0d6;
$fa-var-flower-daffodil: \f800;
$fa-var-hand-back-point-up: \e1a2;
$fa-var-weight-scale: \f496;
$fa-var-weight: \f496;
$fa-var-star-exclamation: \f2f3;
$fa-var-books: \f5db;
$fa-var-user-group: \f500;
$fa-var-user-friends: \f500;
$fa-var-arrow-up-a-z: \f15e;
$fa-var-sort-alpha-up: \f15e;
$fa-var-layer-plus: \f5ff;
$fa-var-layer-group-plus: \f5ff;
$fa-var-play-pause: \e22f;
$fa-var-block-question: \e3dd;
$fa-var-snooze: \f880;
$fa-var-zzz: \f880;
$fa-var-scanner-image: \f8f3;
$fa-var-tv-retro: \f401;
$fa-var-square-t: \e280;
$fa-var-farm: \f864;
$fa-var-barn-silo: \f864;
$fa-var-chess-knight: \f441;
$fa-var-bars-sort: \e0ae;
$fa-var-pallet-boxes: \f483;
$fa-var-palette-boxes: \f483;
$fa-var-pallet-alt: \f483;
$fa-var-face-laugh-squint: \f59b;
$fa-var-laugh-squint: \f59b;
$fa-var-code-simple: \e13d;
$fa-var-bolt-slash: \e0b8;
$fa-var-panel-fire: \e42f;
$fa-var-binary-circle-check: \e33c;
$fa-var-comment-minus: \f4b1;
$fa-var-burrito: \f7ed;
$fa-var-violin: \f8ed;
$fa-var-objects-column: \e3c1;
$fa-var-square-chevron-down: \f329;
$fa-var-chevron-square-down: \f329;
$fa-var-comment-plus: \f4b2;
$fa-var-triangle-instrument: \f8e2;
$fa-var-triangle-music: \f8e2;
$fa-var-wheelchair: \f193;
$fa-var-user-pilot-tie: \e2c1;
$fa-var-piano-keyboard: \f8d5;
$fa-var-bed-empty: \f8f9;
$fa-var-circle-arrow-up: \f0aa;
$fa-var-arrow-circle-up: \f0aa;
$fa-var-toggle-on: \f205;
$fa-var-rectangle-vertical: \f2fb;
$fa-var-rectangle-portrait: \f2fb;
$fa-var-person-walking: \f554;
$fa-var-walking: \f554;
$fa-var-l: \4c;
$fa-var-signal-stream: \f8dd;
$fa-var-down-to-bracket: \e4e7;
$fa-var-circle-z: \e130;
$fa-var-stars: \f762;
$fa-var-fire: \f06d;
$fa-var-bed-pulse: \f487;
$fa-var-procedures: \f487;
$fa-var-house-day: \e00e;
$fa-var-shuttle-space: \f197;
$fa-var-space-shuttle: \f197;
$fa-var-shirt-long-sleeve: \e3c7;
$fa-var-chart-pie-simple: \f64e;
$fa-var-chart-pie-alt: \f64e;
$fa-var-face-laugh: \f599;
$fa-var-laugh: \f599;
$fa-var-folder-open: \f07c;
$fa-var-album-collection-circle-user: \e48f;
$fa-var-candy: \e3e7;
$fa-var-bowl-hot: \f823;
$fa-var-soup: \f823;
$fa-var-flatbread: \e40b;
$fa-var-heart-circle-plus: \e500;
$fa-var-code-fork: \e13b;
$fa-var-city: \f64f;
$fa-var-signal-bars-weak: \f691;
$fa-var-signal-alt-1: \f691;
$fa-var-microphone-lines: \f3c9;
$fa-var-microphone-alt: \f3c9;
$fa-var-clock-twelve: \e358;
$fa-var-pepper-hot: \f816;
$fa-var-citrus-slice: \e2f5;
$fa-var-sheep: \f711;
$fa-var-unlock: \f09c;
$fa-var-colon-sign: \e140;
$fa-var-headset: \f590;
$fa-var-badger-honey: \f6b4;
$fa-var-h4: \f86a;
$fa-var-store-slash: \e071;
$fa-var-road-circle-xmark: \e566;
$fa-var-signal-slash: \f695;
$fa-var-user-minus: \f503;
$fa-var-mars-stroke-up: \f22a;
$fa-var-mars-stroke-v: \f22a;
$fa-var-champagne-glasses: \f79f;
$fa-var-glass-cheers: \f79f;
$fa-var-taco: \f826;
$fa-var-hexagon-plus: \f300;
$fa-var-plus-hexagon: \f300;
$fa-var-clipboard: \f328;
$fa-var-house-circle-exclamation: \e50a;
$fa-var-file-arrow-up: \f574;
$fa-var-file-upload: \f574;
$fa-var-wifi: \f1eb;
$fa-var-wifi-3: \f1eb;
$fa-var-wifi-strong: \f1eb;
$fa-var-messages: \f4b6;
$fa-var-comments-alt: \f4b6;
$fa-var-bath: \f2cd;
$fa-var-bathtub: \f2cd;
$fa-var-umbrella-simple: \e2bc;
$fa-var-umbrella-alt: \e2bc;
$fa-var-rectangle-history-circle-plus: \e4a3;
$fa-var-underline: \f0cd;
$fa-var-prescription-bottle-pill: \e5c0;
$fa-var-user-pen: \f4ff;
$fa-var-user-edit: \f4ff;
$fa-var-binary-slash: \e33e;
$fa-var-square-o: \e278;
$fa-var-signature: \f5b7;
$fa-var-stroopwafel: \f551;
$fa-var-bold: \f032;
$fa-var-anchor-lock: \e4ad;
$fa-var-building-ngo: \e4d7;
$fa-var-transporter-3: \e045;
$fa-var-engine-warning: \f5f2;
$fa-var-engine-exclamation: \f5f2;
$fa-var-circle-down-right: \e108;
$fa-var-square-k: \e274;
$fa-var-manat-sign: \e1d5;
$fa-var-money-check-pen: \f872;
$fa-var-money-check-edit: \f872;
$fa-var-not-equal: \f53e;
$fa-var-border-top-left: \f853;
$fa-var-border-style: \f853;
$fa-var-map-location-dot: \f5a0;
$fa-var-map-marked-alt: \f5a0;
$fa-var-tilde: \7e;
$fa-var-jedi: \f669;
$fa-var-square-poll-vertical: \f681;
$fa-var-poll: \f681;
$fa-var-arrow-down-square-triangle: \f889;
$fa-var-sort-shapes-down-alt: \f889;
$fa-var-mug-hot: \f7b6;
$fa-var-dog-leashed: \f6d4;
$fa-var-car-battery: \f5df;
$fa-var-battery-car: \f5df;
$fa-var-face-downcast-sweat: \e371;
$fa-var-mailbox-flag-up: \e5bb;
$fa-var-memo-circle-info: \e49a;
$fa-var-gift: \f06b;
$fa-var-dice-two: \f528;
$fa-var-volume: \f6a8;
$fa-var-volume-medium: \f6a8;
$fa-var-transporter-5: \e2a6;
$fa-var-gauge-circle-bolt: \e496;
$fa-var-coin-front: \e3fc;
$fa-var-file-slash: \e3a7;
$fa-var-message-arrow-up-right: \e1dd;
$fa-var-treasure-chest: \f723;
$fa-var-chess-queen: \f445;
$fa-var-paintbrush-fine: \f5a9;
$fa-var-paint-brush-alt: \f5a9;
$fa-var-paint-brush-fine: \f5a9;
$fa-var-paintbrush-alt: \f5a9;
$fa-var-glasses: \f530;
$fa-var-hood-cloak: \f6ef;
$fa-var-square-quote: \e329;
$fa-var-up-left: \e2bd;
$fa-var-bring-front: \f857;
$fa-var-chess-board: \f43c;
$fa-var-burger-cheese: \f7f1;
$fa-var-cheeseburger: \f7f1;
$fa-var-building-circle-check: \e4d2;
$fa-var-repeat-1: \f365;
$fa-var-arrow-down-to-line: \f33d;
$fa-var-arrow-to-bottom: \f33d;
$fa-var-grid-5: \e199;
$fa-var-right-long-to-line: \e444;
$fa-var-person-chalkboard: \e53d;
$fa-var-mars-stroke-right: \f22b;
$fa-var-mars-stroke-h: \f22b;
$fa-var-hand-back-fist: \f255;
$fa-var-hand-rock: \f255;
$fa-var-tally: \f69c;
$fa-var-tally-5: \f69c;
$fa-var-square-caret-up: \f151;
$fa-var-caret-square-up: \f151;
$fa-var-cloud-showers-water: \e4e4;
$fa-var-chart-bar: \f080;
$fa-var-bar-chart: \f080;
$fa-var-hands-bubbles: \e05e;
$fa-var-hands-wash: \e05e;
$fa-var-less-than-equal: \f537;
$fa-var-train: \f238;
$fa-var-up-from-dotted-line: \e456;
$fa-var-eye-low-vision: \f2a8;
$fa-var-low-vision: \f2a8;
$fa-var-traffic-light-go: \f638;
$fa-var-face-exhaling: \e480;
$fa-var-sensor-fire: \e02a;
$fa-var-user-unlock: \e058;
$fa-var-hexagon-divide: \e1ad;
$fa-var-00: \e467;
$fa-var-crow: \f520;
$fa-var-cassette-betamax: \f8a4;
$fa-var-betamax: \f8a4;
$fa-var-sailboat: \e445;
$fa-var-window-restore: \f2d2;
$fa-var-nfc-magnifying-glass: \e1f9;
$fa-var-file-binary: \e175;
$fa-var-circle-v: \e12a;
$fa-var-square-plus: \f0fe;
$fa-var-plus-square: \f0fe;
$fa-var-bowl-scoops: \e3df;
$fa-var-mistletoe: \f7b4;
$fa-var-custard: \e403;
$fa-var-lacrosse-stick: \e3b5;
$fa-var-hockey-mask: \f6ee;
$fa-var-sunrise: \f766;
$fa-var-panel-ews: \e42e;
$fa-var-torii-gate: \f6a1;
$fa-var-cloud-exclamation: \e491;
$fa-var-message-lines: \f4a6;
$fa-var-comment-alt-lines: \f4a6;
$fa-var-frog: \f52e;
$fa-var-bucket: \e4cf;
$fa-var-floppy-disk-pen: \e182;
$fa-var-image: \f03e;
$fa-var-window-frame: \e04f;
$fa-var-microphone: \f130;
$fa-var-cow: \f6c8;
$fa-var-square-ring: \e44f;
$fa-var-down-from-line: \f349;
$fa-var-arrow-alt-from-top: \f349;
$fa-var-caret-up: \f0d8;
$fa-var-shield-xmark: \e24c;
$fa-var-shield-times: \e24c;
$fa-var-screwdriver: \f54a;
$fa-var-circle-sort-down: \e031;
$fa-var-sort-circle-down: \e031;
$fa-var-folder-closed: \e185;
$fa-var-house-tsunami: \e515;
$fa-var-square-nfi: \e576;
$fa-var-forklift: \f47a;
$fa-var-arrow-up-from-ground-water: \e4b5;
$fa-var-bracket-square-right: \5d;
$fa-var-martini-glass: \f57b;
$fa-var-glass-martini-alt: \f57b;
$fa-var-rotate-left: \f2ea;
$fa-var-rotate-back: \f2ea;
$fa-var-rotate-backward: \f2ea;
$fa-var-undo-alt: \f2ea;
$fa-var-table-columns: \f0db;
$fa-var-columns: \f0db;
$fa-var-square-a: \e25f;
$fa-var-tick: \e32f;
$fa-var-lemon: \f094;
$fa-var-head-side-mask: \e063;
$fa-var-handshake: \f2b5;
$fa-var-gem: \f3a5;
$fa-var-dolly: \f472;
$fa-var-dolly-box: \f472;
$fa-var-smoking: \f48d;
$fa-var-minimize: \f78c;
$fa-var-compress-arrows-alt: \f78c;
$fa-var-refrigerator: \e026;
$fa-var-monument: \f5a6;
$fa-var-octagon-xmark: \f2f0;
$fa-var-times-octagon: \f2f0;
$fa-var-xmark-octagon: \f2f0;
$fa-var-align-slash: \f846;
$fa-var-snowplow: \f7d2;
$fa-var-angles-right: \f101;
$fa-var-angle-double-right: \f101;
$fa-var-truck-ramp-couch: \f4dd;
$fa-var-truck-couch: \f4dd;
$fa-var-cannabis: \f55f;
$fa-var-circle-play: \f144;
$fa-var-play-circle: \f144;
$fa-var-arrow-up-right-and-arrow-down-left-from-center: \e0a0;
$fa-var-tablets: \f490;
$fa-var-360-degrees: \e2dc;
$fa-var-ethernet: \f796;
$fa-var-euro-sign: \f153;
$fa-var-eur: \f153;
$fa-var-euro: \f153;
$fa-var-chair: \f6c0;
$fa-var-circle-check: \f058;
$fa-var-check-circle: \f058;
$fa-var-square-dashed-circle-plus: \e5c2;
$fa-var-money-simple-from-bracket: \e313;
$fa-var-bat: \f6b5;
$fa-var-circle-stop: \f28d;
$fa-var-stop-circle: \f28d;
$fa-var-head-side-headphones: \f8c2;
$fa-var-phone-rotary: \f8d3;
$fa-var-compass-drafting: \f568;
$fa-var-drafting-compass: \f568;
$fa-var-plate-wheat: \e55a;
$fa-var-calendar-circle-minus: \e46f;
$fa-var-chopsticks: \e3f7;
$fa-var-car-wrench: \f5e3;
$fa-var-car-mechanic: \f5e3;
$fa-var-icicles: \f7ad;
$fa-var-person-shelter: \e54f;
$fa-var-neuter: \f22c;
$fa-var-id-badge: \f2c1;
$fa-var-kazoo: \f8c7;
$fa-var-marker: \f5a1;
$fa-var-face-laugh-beam: \f59a;
$fa-var-laugh-beam: \f59a;
$fa-var-square-arrow-down-left: \e261;
$fa-var-battery-bolt: \f376;
$fa-var-tree-large: \f7dd;
$fa-var-helicopter-symbol: \e502;
$fa-var-aperture: \e2df;
$fa-var-universal-access: \f29a;
$fa-var-file-magnifying-glass: \f865;
$fa-var-file-search: \f865;
$fa-var-up-right: \e2be;
$fa-var-circle-chevron-up: \f139;
$fa-var-chevron-circle-up: \f139;
$fa-var-user-police: \e333;
$fa-var-lari-sign: \e1c8;
$fa-var-volcano: \f770;
$fa-var-teddy-bear: \e3cf;
$fa-var-stocking: \f7d5;
$fa-var-person-walking-dashed-line-arrow-right: \e553;
$fa-var-image-slash: \e1b7;
$fa-var-mask-snorkel: \e3b7;
$fa-var-smoke: \f760;
$fa-var-sterling-sign: \f154;
$fa-var-gbp: \f154;
$fa-var-pound-sign: \f154;
$fa-var-battery-exclamation: \e0b0;
$fa-var-viruses: \e076;
$fa-var-square-person-confined: \e577;
$fa-var-user-tie: \f508;
$fa-var-arrow-down-long: \f175;
$fa-var-long-arrow-down: \f175;
$fa-var-tent-arrow-down-to-line: \e57e;
$fa-var-certificate: \f0a3;
$fa-var-crystal-ball: \e362;
$fa-var-reply-all: \f122;
$fa-var-mail-reply-all: \f122;
$fa-var-suitcase: \f0f2;
$fa-var-person-skating: \f7c5;
$fa-var-skating: \f7c5;
$fa-var-star-shooting: \e036;
$fa-var-binary-lock: \e33d;
$fa-var-filter-circle-dollar: \f662;
$fa-var-funnel-dollar: \f662;
$fa-var-camera-retro: \f083;
$fa-var-circle-arrow-down: \f0ab;
$fa-var-arrow-circle-down: \f0ab;
$fa-var-comment-pen: \f4ae;
$fa-var-comment-edit: \f4ae;
$fa-var-file-import: \f56f;
$fa-var-arrow-right-to-file: \f56f;
$fa-var-banjo: \f8a3;
$fa-var-square-arrow-up-right: \f14c;
$fa-var-external-link-square: \f14c;
$fa-var-light-emergency-on: \e420;
$fa-var-kerning: \f86f;
$fa-var-box-open: \f49e;
$fa-var-square-f: \e270;
$fa-var-scroll: \f70e;
$fa-var-spa: \f5bb;
$fa-var-arrow-left-from-line: \f344;
$fa-var-arrow-from-right: \f344;
$fa-var-strawberry: \e32b;
$fa-var-location-pin-lock: \e51f;
$fa-var-pause: \f04c;
$fa-var-clock-eight-thirty: \e346;
$fa-var-plane-engines: \f3de;
$fa-var-plane-alt: \f3de;
$fa-var-hill-avalanche: \e507;
$fa-var-temperature-empty: \f2cb;
$fa-var-temperature-0: \f2cb;
$fa-var-thermometer-0: \f2cb;
$fa-var-thermometer-empty: \f2cb;
$fa-var-bomb: \f1e2;
$fa-var-gauge-low: \f627;
$fa-var-tachometer-alt-slow: \f627;
$fa-var-registered: \f25d;
$fa-var-trash-can-plus: \e2ac;
$fa-var-address-card: \f2bb;
$fa-var-contact-card: \f2bb;
$fa-var-vcard: \f2bb;
$fa-var-scale-unbalanced-flip: \f516;
$fa-var-balance-scale-right: \f516;
$fa-var-globe-snow: \f7a3;
$fa-var-subscript: \f12c;
$fa-var-diamond-turn-right: \f5eb;
$fa-var-directions: \f5eb;
$fa-var-integral: \f667;
$fa-var-burst: \e4dc;
$fa-var-house-laptop: \e066;
$fa-var-laptop-house: \e066;
$fa-var-face-tired: \f5c8;
$fa-var-tired: \f5c8;
$fa-var-money-bills: \e1f3;
$fa-var-blinds-raised: \f8fd;
$fa-var-smog: \f75f;
$fa-var-ufo-beam: \e048;
$fa-var-circle-caret-up: \f331;
$fa-var-caret-circle-up: \f331;
$fa-var-user-vneck-hair-long: \e463;
$fa-var-square-a-lock: \e44d;
$fa-var-crutch: \f7f7;
$fa-var-gas-pump-slash: \f5f4;
$fa-var-cloud-arrow-up: \f0ee;
$fa-var-cloud-upload: \f0ee;
$fa-var-cloud-upload-alt: \f0ee;
$fa-var-palette: \f53f;
$fa-var-transporter-4: \e2a5;
$fa-var-objects-align-right: \e3bf;
$fa-var-arrows-turn-right: \e4c0;
$fa-var-vest: \e085;
$fa-var-pig: \f706;
$fa-var-inbox-full: \e1ba;
$fa-var-circle-envelope: \e10c;
$fa-var-envelope-circle: \e10c;
$fa-var-triangle-person-digging: \f85d;
$fa-var-construction: \f85d;
$fa-var-ferry: \e4ea;
$fa-var-bullseye-arrow: \f648;
$fa-var-arrows-down-to-people: \e4b9;
$fa-var-seedling: \f4d8;
$fa-var-sprout: \f4d8;
$fa-var-clock-seven: \e350;
$fa-var-left-right: \f337;
$fa-var-arrows-alt-h: \f337;
$fa-var-boxes-packing: \e4c7;
$fa-var-circle-arrow-left: \f0a8;
$fa-var-arrow-circle-left: \f0a8;
$fa-var-flashlight: \f8b8;
$fa-var-group-arrows-rotate: \e4f6;
$fa-var-bowl-food: \e4c6;
$fa-var-square-9: \e25e;
$fa-var-candy-cane: \f786;
$fa-var-arrow-down-wide-short: \f160;
$fa-var-sort-amount-asc: \f160;
$fa-var-sort-amount-down: \f160;
$fa-var-square-dollar: \f2e9;
$fa-var-dollar-square: \f2e9;
$fa-var-usd-square: \f2e9;
$fa-var-phone-arrow-right: \e5be;
$fa-var-hand-holding-seedling: \f4bf;
$fa-var-message-check: \f4a2;
$fa-var-comment-alt-check: \f4a2;
$fa-var-cloud-bolt: \f76c;
$fa-var-thunderstorm: \f76c;
$fa-var-text-slash: \f87d;
$fa-var-remove-format: \f87d;
$fa-var-watch: \f2e1;
$fa-var-circle-down-left: \e107;
$fa-var-text: \f893;
$fa-var-projector: \f8d6;
$fa-var-face-smile-wink: \f4da;
$fa-var-smile-wink: \f4da;
$fa-var-tombstone-blank: \f721;
$fa-var-tombstone-alt: \f721;
$fa-var-chess-king-piece: \f440;
$fa-var-chess-king-alt: \f440;
$fa-var-circle-6: \e0f3;
$fa-var-left: \f355;
$fa-var-arrow-alt-left: \f355;
$fa-var-file-word: \f1c2;
$fa-var-file-powerpoint: \f1c4;
$fa-var-square-down: \f350;
$fa-var-arrow-alt-square-down: \f350;
$fa-var-objects-align-center-vertical: \e3bd;
$fa-var-arrows-left-right: \f07e;
$fa-var-arrows-h: \f07e;
$fa-var-house-lock: \e510;
$fa-var-cloud-arrow-down: \f0ed;
$fa-var-cloud-download: \f0ed;
$fa-var-cloud-download-alt: \f0ed;
$fa-var-wreath: \f7e2;
$fa-var-children: \e4e1;
$fa-var-meter-droplet: \e1ea;
$fa-var-chalkboard: \f51b;
$fa-var-blackboard: \f51b;
$fa-var-user-large-slash: \f4fa;
$fa-var-user-alt-slash: \f4fa;
$fa-var-signal-strong: \f68f;
$fa-var-signal-4: \f68f;
$fa-var-lollipop: \e424;
$fa-var-lollypop: \e424;
$fa-var-list-tree: \e1d2;
$fa-var-envelope-open: \f2b6;
$fa-var-draw-circle: \f5ed;
$fa-var-cat-space: \e001;
$fa-var-handshake-simple-slash: \e05f;
$fa-var-handshake-alt-slash: \e05f;
$fa-var-rabbit-running: \f709;
$fa-var-rabbit-fast: \f709;
$fa-var-memo-pad: \e1da;
$fa-var-mattress-pillow: \e525;
$fa-var-alarm-plus: \f844;
$fa-var-alicorn: \f6b0;
$fa-var-comment-question: \e14b;
$fa-var-gingerbread-man: \f79d;
$fa-var-guarani-sign: \e19a;
$fa-var-burger-fries: \e0cd;
$fa-var-mug-tea: \f875;
$fa-var-border-top: \f855;
$fa-var-arrows-rotate: \f021;
$fa-var-refresh: \f021;
$fa-var-sync: \f021;
$fa-var-circle-book-open: \e0ff;
$fa-var-book-circle: \e0ff;
$fa-var-arrows-to-dotted-line: \e0a6;
$fa-var-fire-extinguisher: \f134;
$fa-var-garage-open: \e00b;
$fa-var-shelves-empty: \e246;
$fa-var-cruzeiro-sign: \e152;
$fa-var-watch-apple: \e2cb;
$fa-var-watch-calculator: \f8f0;
$fa-var-list-dropdown: \e1cf;
$fa-var-cabinet-filing: \f64b;
$fa-var-burger-soda: \f858;
$fa-var-square-arrow-up: \f33c;
$fa-var-arrow-square-up: \f33c;
$fa-var-greater-than-equal: \f532;
$fa-var-pallet-box: \e208;
$fa-var-face-confounded: \e36c;
$fa-var-shield-halved: \f3ed;
$fa-var-shield-alt: \f3ed;
$fa-var-truck-plow: \f7de;
$fa-var-book-atlas: \f558;
$fa-var-atlas: \f558;
$fa-var-virus: \e074;
$fa-var-comment-middle-top: \e14a;
$fa-var-envelope-circle-check: \e4e8;
$fa-var-layer-group: \f5fd;
$fa-var-restroom-simple: \e23a;
$fa-var-arrows-to-dot: \e4be;
$fa-var-border-outer: \f851;
$fa-var-hashtag-lock: \e415;
$fa-var-clock-two-thirty: \e35b;
$fa-var-archway: \f557;
$fa-var-heart-circle-check: \e4fd;
$fa-var-house-chimney-crack: \f6f1;
$fa-var-house-damage: \f6f1;
$fa-var-file-zipper: \f1c6;
$fa-var-file-archive: \f1c6;
$fa-var-heart-half: \e1ab;
$fa-var-comment-check: \f4ac;
$fa-var-square: \f0c8;
$fa-var-memo: \e1d8;
$fa-var-martini-glass-empty: \f000;
$fa-var-glass-martini: \f000;
$fa-var-couch: \f4b8;
$fa-var-cedi-sign: \e0df;
$fa-var-italic: \f033;
$fa-var-glass-citrus: \f869;
$fa-var-calendar-lines-pen: \e472;
$fa-var-church: \f51d;
$fa-var-person-snowmobiling: \f7d1;
$fa-var-snowmobile: \f7d1;
$fa-var-face-hushed: \e37b;
$fa-var-comments-dollar: \f653;
$fa-var-pickaxe: \e5bf;
$fa-var-link-simple-slash: \e1ce;
$fa-var-democrat: \f747;
$fa-var-face-confused: \e36d;
$fa-var-pinball: \e229;
$fa-var-z: \5a;
$fa-var-person-skiing: \f7c9;
$fa-var-skiing: \f7c9;
$fa-var-deer: \f78e;
$fa-var-input-pipe: \e1be;
$fa-var-road-lock: \e567;
$fa-var-a: \41;
$fa-var-bookmark-slash: \e0c2;
$fa-var-temperature-arrow-down: \e03f;
$fa-var-temperature-down: \e03f;
$fa-var-mace: \f6f8;
$fa-var-feather-pointed: \f56b;
$fa-var-feather-alt: \f56b;
$fa-var-sausage: \f820;
$fa-var-trash-can-clock: \e2aa;
$fa-var-p: \50;
$fa-var-snowflake: \f2dc;
$fa-var-stomach: \f623;
$fa-var-newspaper: \f1ea;
$fa-var-rectangle-ad: \f641;
$fa-var-ad: \f641;
$fa-var-guitar-electric: \f8be;
$fa-var-arrow-turn-down-right: \e3d6;
$fa-var-moon-cloud: \f754;
$fa-var-bread-slice-butter: \e3e1;
$fa-var-circle-arrow-right: \f0a9;
$fa-var-arrow-circle-right: \f0a9;
$fa-var-user-group-crown: \f6a5;
$fa-var-users-crown: \f6a5;
$fa-var-circle-i: \e111;
$fa-var-toilet-paper-check: \e5b2;
$fa-var-filter-circle-xmark: \e17b;
$fa-var-locust: \e520;
$fa-var-sort: \f0dc;
$fa-var-unsorted: \f0dc;
$fa-var-list-ol: \f0cb;
$fa-var-list-1-2: \f0cb;
$fa-var-list-numeric: \f0cb;
$fa-var-chart-waterfall: \e0eb;
$fa-var-face-party: \e383;
$fa-var-kidneys: \f5fb;
$fa-var-wifi-exclamation: \e2cf;
$fa-var-chart-network: \f78a;
$fa-var-person-dress-burst: \e544;
$fa-var-dice-d4: \f6d0;
$fa-var-money-check-dollar: \f53d;
$fa-var-money-check-alt: \f53d;
$fa-var-vector-square: \f5cb;
$fa-var-bread-slice: \f7ec;
$fa-var-language: \f1ab;
$fa-var-wheat-awn-slash: \e338;
$fa-var-face-kiss-wink-heart: \f598;
$fa-var-kiss-wink-heart: \f598;
$fa-var-dagger: \f6cb;
$fa-var-podium: \f680;
$fa-var-memo-circle-check: \e1d9;
$fa-var-route-highway: \f61a;
$fa-var-down-to-line: \f34a;
$fa-var-arrow-alt-to-bottom: \f34a;
$fa-var-filter: \f0b0;
$fa-var-square-g: \e271;
$fa-var-circle-phone: \e11b;
$fa-var-phone-circle: \e11b;
$fa-var-clipboard-prescription: \f5e8;
$fa-var-user-nurse-hair: \e45d;
$fa-var-question: \3f;
$fa-var-file-signature: \f573;
$fa-var-toggle-large-on: \e5b1;
$fa-var-up-down-left-right: \f0b2;
$fa-var-arrows-alt: \f0b2;
$fa-var-dryer-heat: \f862;
$fa-var-dryer-alt: \f862;
$fa-var-house-chimney-user: \e065;
$fa-var-hand-holding-heart: \f4be;
$fa-var-arrow-up-small-big: \f88f;
$fa-var-sort-size-up-alt: \f88f;
$fa-var-train-track: \e453;
$fa-var-puzzle-piece: \f12e;
$fa-var-money-check: \f53c;
$fa-var-star-half-stroke: \f5c0;
$fa-var-star-half-alt: \f5c0;
$fa-var-file-exclamation: \f31a;
$fa-var-code: \f121;
$fa-var-whiskey-glass: \f7a0;
$fa-var-glass-whiskey: \f7a0;
$fa-var-moon-stars: \f755;
$fa-var-building-circle-exclamation: \e4d3;
$fa-var-clothes-hanger: \e136;
$fa-var-mobile-notch: \e1ee;
$fa-var-mobile-iphone: \e1ee;
$fa-var-magnifying-glass-chart: \e522;
$fa-var-arrow-up-right-from-square: \f08e;
$fa-var-external-link: \f08e;
$fa-var-cubes-stacked: \e4e6;
$fa-var-images-user: \e1b9;
$fa-var-won-sign: \f159;
$fa-var-krw: \f159;
$fa-var-won: \f159;
$fa-var-image-polaroid-user: \e1b6;
$fa-var-virus-covid: \e4a8;
$fa-var-square-ellipsis: \e26e;
$fa-var-pie: \f705;
$fa-var-chess-knight-piece: \f442;
$fa-var-chess-knight-alt: \f442;
$fa-var-austral-sign: \e0a9;
$fa-var-cloud-plus: \e35e;
$fa-var-f: \46;
$fa-var-leaf: \f06c;
$fa-var-bed-bunk: \f8f8;
$fa-var-road: \f018;
$fa-var-taxi: \f1ba;
$fa-var-cab: \f1ba;
$fa-var-person-circle-plus: \e541;
$fa-var-chart-pie: \f200;
$fa-var-pie-chart: \f200;
$fa-var-bolt-lightning: \e0b7;
$fa-var-clock-eight: \e345;
$fa-var-sack-xmark: \e56a;
$fa-var-file-excel: \f1c3;
$fa-var-file-contract: \f56c;
$fa-var-fish-fins: \e4f2;
$fa-var-circle-q: \e11e;
$fa-var-building-flag: \e4d5;
$fa-var-face-grin-beam: \f582;
$fa-var-grin-beam: \f582;
$fa-var-object-ungroup: \f248;
$fa-var-face-disguise: \e370;
$fa-var-circle-arrow-down-right: \e0fa;
$fa-var-alien-8bit: \f8f6;
$fa-var-alien-monster: \f8f6;
$fa-var-hand-point-ribbon: \e1a6;
$fa-var-poop: \f619;
$fa-var-object-exclude: \e49c;
$fa-var-telescope: \e03e;
$fa-var-location-pin: \f041;
$fa-var-map-marker: \f041;
$fa-var-square-list: \e489;
$fa-var-kaaba: \f66b;
$fa-var-toilet-paper: \f71e;
$fa-var-helmet-safety: \f807;
$fa-var-hard-hat: \f807;
$fa-var-hat-hard: \f807;
$fa-var-comment-code: \e147;
$fa-var-sim-cards: \e251;
$fa-var-starship: \e039;
$fa-var-eject: \f052;
$fa-var-circle-right: \f35a;
$fa-var-arrow-alt-circle-right: \f35a;
$fa-var-plane-circle-check: \e555;
$fa-var-seal: \e241;
$fa-var-user-cowboy: \f8ea;
$fa-var-hexagon-vertical-nft: \e505;
$fa-var-face-rolling-eyes: \f5a5;
$fa-var-meh-rolling-eyes: \f5a5;
$fa-var-bread-loaf: \f7eb;
$fa-var-rings-wedding: \f81b;
$fa-var-object-group: \f247;
$fa-var-french-fries: \f803;
$fa-var-chart-line: \f201;
$fa-var-line-chart: \f201;
$fa-var-calendar-arrow-down: \e0d0;
$fa-var-calendar-download: \e0d0;
$fa-var-send-back: \f87e;
$fa-var-mask-ventilator: \e524;
$fa-var-signature-lock: \e3ca;
$fa-var-arrow-right: \f061;
$fa-var-signs-post: \f277;
$fa-var-map-signs: \f277;
$fa-var-octagon-plus: \f301;
$fa-var-plus-octagon: \f301;
$fa-var-cash-register: \f788;
$fa-var-person-circle-question: \e542;
$fa-var-melon-slice: \e311;
$fa-var-space-station-moon: \e033;
$fa-var-message-smile: \f4aa;
$fa-var-comment-alt-smile: \f4aa;
$fa-var-cup-straw: \e363;
$fa-var-left-from-line: \f348;
$fa-var-arrow-alt-from-right: \f348;
$fa-var-h: \48;
$fa-var-basket-shopping-simple: \e0af;
$fa-var-shopping-basket-alt: \e0af;
$fa-var-hands-holding-heart: \f4c3;
$fa-var-hands-heart: \f4c3;
$fa-var-clock-nine: \e34c;
$fa-var-tarp: \e57b;
$fa-var-face-sleepy: \e38e;
$fa-var-hand-horns: \e1a9;
$fa-var-screwdriver-wrench: \f7d9;
$fa-var-tools: \f7d9;
$fa-var-arrows-to-eye: \e4bf;
$fa-var-circle-three-quarters: \e125;
$fa-var-trophy-star: \f2eb;
$fa-var-trophy-alt: \f2eb;
$fa-var-plug-circle-bolt: \e55b;
$fa-var-face-thermometer: \e39a;
$fa-var-shirt-running: \e3c8;
$fa-var-book-circle-arrow-up: \e0bd;
$fa-var-face-nauseated: \e381;
$fa-var-heart: \f004;
$fa-var-file-chart-pie: \f65a;
$fa-var-mars-and-venus: \f224;
$fa-var-house-user: \e1b0;
$fa-var-home-user: \e1b0;
$fa-var-circle-arrow-down-left: \e0f9;
$fa-var-dumpster-fire: \f794;
$fa-var-hexagon-minus: \f307;
$fa-var-minus-hexagon: \f307;
$fa-var-left-to-line: \f34b;
$fa-var-arrow-alt-to-left: \f34b;
$fa-var-house-crack: \e3b1;
$fa-var-paw-simple: \f701;
$fa-var-paw-alt: \f701;
$fa-var-arrow-left-long-to-line: \e3d4;
$fa-var-brackets-round: \e0c5;
$fa-var-parentheses: \e0c5;
$fa-var-martini-glass-citrus: \f561;
$fa-var-cocktail: \f561;
$fa-var-user-shakespeare: \e2c2;
$fa-var-arrow-right-to-arc: \e4b2;
$fa-var-face-surprise: \f5c2;
$fa-var-surprise: \f5c2;
$fa-var-bottle-water: \e4c5;
$fa-var-circle-pause: \f28b;
$fa-var-pause-circle: \f28b;
$fa-var-gauge-circle-plus: \e498;
$fa-var-folders: \f660;
$fa-var-angel: \f779;
$fa-var-value-absolute: \f6a6;
$fa-var-rabbit: \f708;
$fa-var-toilet-paper-slash: \e072;
$fa-var-apple-whole: \f5d1;
$fa-var-apple-alt: \f5d1;
$fa-var-kitchen-set: \e51a;
$fa-var-diamond-half: \e5b7;
$fa-var-lock-keyhole: \f30d;
$fa-var-lock-alt: \f30d;
$fa-var-r: \52;
$fa-var-temperature-quarter: \f2ca;
$fa-var-temperature-1: \f2ca;
$fa-var-thermometer-1: \f2ca;
$fa-var-thermometer-quarter: \f2ca;
$fa-var-square-info: \f30f;
$fa-var-info-square: \f30f;
$fa-var-wifi-slash: \f6ac;
$fa-var-toilet-paper-xmark: \e5b3;
$fa-var-hands-holding-dollar: \f4c5;
$fa-var-hands-usd: \f4c5;
$fa-var-cube: \f1b2;
$fa-var-arrow-down-triangle-square: \f888;
$fa-var-sort-shapes-down: \f888;
$fa-var-bitcoin-sign: \e0b4;
$fa-var-shutters: \e449;
$fa-var-shield-dog: \e573;
$fa-var-solar-panel: \f5ba;
$fa-var-lock-open: \f3c1;
$fa-var-table-tree: \e293;
$fa-var-house-chimney-heart: \e1b2;
$fa-var-tally-3: \e296;
$fa-var-elevator: \e16d;
$fa-var-money-bill-transfer: \e528;
$fa-var-money-bill-trend-up: \e529;
$fa-var-house-flood-water-circle-arrow-right: \e50f;
$fa-var-square-poll-horizontal: \f682;
$fa-var-poll-h: \f682;
$fa-var-circle: \f111;
$fa-var-cart-circle-exclamation: \e3f2;
$fa-var-sword: \f71c;
$fa-var-backward-fast: \f049;
$fa-var-fast-backward: \f049;
$fa-var-recycle: \f1b8;
$fa-var-user-astronaut: \f4fb;
$fa-var-interrobang: \e5ba;
$fa-var-plane-slash: \e069;
$fa-var-circle-dashed: \e105;
$fa-var-trademark: \f25c;
$fa-var-basketball: \f434;
$fa-var-basketball-ball: \f434;
$fa-var-fork-knife: \f2e6;
$fa-var-utensils-alt: \f2e6;
$fa-var-satellite-dish: \f7c0;
$fa-var-badge-check: \f336;
$fa-var-circle-up: \f35b;
$fa-var-arrow-alt-circle-up: \f35b;
$fa-var-slider: \e252;
$fa-var-mobile-screen-button: \f3cd;
$fa-var-mobile-alt: \f3cd;
$fa-var-clock-one-thirty: \e34f;
$fa-var-inbox-out: \f311;
$fa-var-inbox-arrow-up: \f311;
$fa-var-cloud-slash: \e137;
$fa-var-volume-high: \f028;
$fa-var-volume-up: \f028;
$fa-var-users-rays: \e593;
$fa-var-wallet: \f555;
$fa-var-octagon-check: \e426;
$fa-var-flatbread-stuffed: \e40c;
$fa-var-clipboard-check: \f46c;
$fa-var-cart-circle-plus: \e3f3;
$fa-var-truck-clock: \f48c;
$fa-var-shipping-timed: \f48c;
$fa-var-pool-8-ball: \e3c5;
$fa-var-file-audio: \f1c7;
$fa-var-turn-down-left: \e331;
$fa-var-lock-hashtag: \e423;
$fa-var-chart-radar: \e0e7;
$fa-var-staff: \f71b;
$fa-var-burger: \f805;
$fa-var-hamburger: \f805;
$fa-var-utility-pole: \e2c3;
$fa-var-transporter-6: \e2a7;
$fa-var-wrench: \f0ad;
$fa-var-bugs: \e4d0;
$fa-var-vector-polygon: \e2c7;
$fa-var-diagram-nested: \e157;
$fa-var-rupee-sign: \f156;
$fa-var-rupee: \f156;
$fa-var-file-image: \f1c5;
$fa-var-circle-question: \f059;
$fa-var-question-circle: \f059;
$fa-var-image-user: \e1b8;
$fa-var-buoy: \e5b5;
$fa-var-plane-departure: \f5b0;
$fa-var-handshake-slash: \e060;
$fa-var-book-bookmark: \e0bb;
$fa-var-border-center-h: \f89c;
$fa-var-can-food: \e3e6;
$fa-var-typewriter: \f8e7;
$fa-var-arrow-right-from-arc: \e4b1;
$fa-var-circle-k: \e113;
$fa-var-face-hand-over-mouth: \e378;
$fa-var-popcorn: \f819;
$fa-var-house-water: \f74f;
$fa-var-house-flood: \f74f;
$fa-var-object-subtract: \e49e;
$fa-var-code-branch: \f126;
$fa-var-warehouse-full: \f495;
$fa-var-warehouse-alt: \f495;
$fa-var-hat-cowboy: \f8c0;
$fa-var-bridge: \e4c8;
$fa-var-phone-flip: \f879;
$fa-var-phone-alt: \f879;
$fa-var-arrow-down-from-dotted-line: \e090;
$fa-var-square-quarters: \e44e;
$fa-var-truck-front: \e2b7;
$fa-var-cat: \f6be;
$fa-var-trash-xmark: \e2b4;
$fa-var-circle-caret-left: \f32e;
$fa-var-caret-circle-left: \f32e;
$fa-var-files: \e178;
$fa-var-anchor-circle-exclamation: \e4ab;
$fa-var-face-clouds: \e47d;
$fa-var-user-crown: \f6a4;
$fa-var-truck-field: \e58d;
$fa-var-route: \f4d7;
$fa-var-cart-circle-check: \e3f1;
$fa-var-clipboard-question: \e4e3;
$fa-var-panorama: \e209;
$fa-var-comment-medical: \f7f5;
$fa-var-teeth-open: \f62f;
$fa-var-user-tie-hair-long: \e460;
$fa-var-file-circle-minus: \e4ed;
$fa-var-head-side-medical: \f809;
$fa-var-tags: \f02c;
$fa-var-wine-glass: \f4e3;
$fa-var-forward-fast: \f050;
$fa-var-fast-forward: \f050;
$fa-var-face-meh-blank: \f5a4;
$fa-var-meh-blank: \f5a4;
$fa-var-user-robot: \e04b;
$fa-var-square-parking: \f540;
$fa-var-parking: \f540;
$fa-var-card-diamond: \e3ea;
$fa-var-face-zipper: \e3a5;
$fa-var-face-raised-eyebrow: \e388;
$fa-var-house-signal: \e012;
$fa-var-square-chevron-up: \f32c;
$fa-var-chevron-square-up: \f32c;
$fa-var-bars-progress: \f828;
$fa-var-tasks-alt: \f828;
$fa-var-faucet-drip: \e006;
$fa-var-arrows-to-line: \e0a7;
$fa-var-dolphin: \e168;
$fa-var-arrow-up-right: \e09f;
$fa-var-circle-r: \e120;
$fa-var-cart-flatbed: \f474;
$fa-var-dolly-flatbed: \f474;
$fa-var-ban-smoking: \f54d;
$fa-var-smoking-ban: \f54d;
$fa-var-circle-sort-up: \e032;
$fa-var-sort-circle-up: \e032;
$fa-var-terminal: \f120;
$fa-var-mobile-button: \f10b;
$fa-var-house-medical-flag: \e514;
$fa-var-basket-shopping: \f291;
$fa-var-shopping-basket: \f291;
$fa-var-tape: \f4db;
$fa-var-chestnut: \e3f6;
$fa-var-bus-simple: \f55e;
$fa-var-bus-alt: \f55e;
$fa-var-eye: \f06e;
$fa-var-face-sad-cry: \f5b3;
$fa-var-sad-cry: \f5b3;
$fa-var-heat: \e00c;
$fa-var-ticket-airline: \e29a;
$fa-var-boot-heeled: \e33f;
$fa-var-arrows-minimize: \e0a5;
$fa-var-compress-arrows: \e0a5;
$fa-var-audio-description: \f29e;
$fa-var-person-military-to-person: \e54c;
$fa-var-file-shield: \e4f0;
$fa-var-hexagon: \f312;
$fa-var-manhole: \e1d6;
$fa-var-user-slash: \f506;
$fa-var-pen: \f304;
$fa-var-tower-observation: \e586;
$fa-var-floppy-disks: \e183;
$fa-var-toilet-paper-blank-under: \e29f;
$fa-var-toilet-paper-reverse-alt: \e29f;
$fa-var-file-code: \f1c9;
$fa-var-signal: \f012;
$fa-var-signal-5: \f012;
$fa-var-signal-perfect: \f012;
$fa-var-pump: \e442;
$fa-var-bus: \f207;
$fa-var-heart-circle-xmark: \e501;
$fa-var-arrow-up-left-from-circle: \e09e;
$fa-var-house-chimney: \e3af;
$fa-var-home-lg: \e3af;
$fa-var-window-maximize: \f2d0;
$fa-var-dryer: \f861;
$fa-var-face-frown: \f119;
$fa-var-frown: \f119;
$fa-var-chess-bishop-piece: \f43b;
$fa-var-chess-bishop-alt: \f43b;
$fa-var-shirt-tank-top: \e3c9;
$fa-var-diploma: \f5ea;
$fa-var-scroll-ribbon: \f5ea;
$fa-var-screencast: \e23e;
$fa-var-walker: \f831;
$fa-var-prescription: \f5b1;
$fa-var-shop: \f54f;
$fa-var-store-alt: \f54f;
$fa-var-floppy-disk: \f0c7;
$fa-var-save: \f0c7;
$fa-var-vihara: \f6a7;
$fa-var-face-kiss-closed-eyes: \e37d;
$fa-var-scale-unbalanced: \f515;
$fa-var-balance-scale-left: \f515;
$fa-var-file-user: \f65c;
$fa-var-user-police-tie: \e334;
$fa-var-face-tongue-money: \e39d;
$fa-var-tennis-ball: \f45e;
$fa-var-square-l: \e275;
$fa-var-sort-up: \f0de;
$fa-var-sort-asc: \f0de;
$fa-var-calendar-arrow-up: \e0d1;
$fa-var-calendar-upload: \e0d1;
$fa-var-comment-dots: \f4ad;
$fa-var-commenting: \f4ad;
$fa-var-plant-wilt: \e5aa;
$fa-var-scarf: \f7c1;
$fa-var-album-circle-plus: \e48c;
$fa-var-user-nurse-hair-long: \e45e;
$fa-var-diamond: \f219;
$fa-var-square-left: \f351;
$fa-var-arrow-alt-square-left: \f351;
$fa-var-face-grin-squint: \f585;
$fa-var-grin-squint: \f585;
$fa-var-circle-ellipsis-vertical: \e10b;
$fa-var-hand-holding-dollar: \f4c0;
$fa-var-hand-holding-usd: \f4c0;
$fa-var-grid-dividers: \e3ad;
$fa-var-bacterium: \e05a;
$fa-var-hand-pointer: \f25a;
$fa-var-drum-steelpan: \f56a;
$fa-var-hand-scissors: \f257;
$fa-var-hands-praying: \f684;
$fa-var-praying-hands: \f684;
$fa-var-face-pensive: \e384;
$fa-var-user-music: \f8eb;
$fa-var-arrow-rotate-right: \f01e;
$fa-var-arrow-right-rotate: \f01e;
$fa-var-arrow-rotate-forward: \f01e;
$fa-var-redo: \f01e;
$fa-var-messages-dollar: \f652;
$fa-var-comments-alt-dollar: \f652;
$fa-var-sensor-on: \e02b;
$fa-var-balloon: \e2e3;
$fa-var-biohazard: \f780;
$fa-var-chess-queen-piece: \f446;
$fa-var-chess-queen-alt: \f446;
$fa-var-location-crosshairs: \f601;
$fa-var-location: \f601;
$fa-var-mars-double: \f227;
$fa-var-house-person-leave: \e00f;
$fa-var-house-leave: \e00f;
$fa-var-house-person-depart: \e00f;
$fa-var-ruler-triangle: \f61c;
$fa-var-card-club: \e3e9;
$fa-var-child-dress: \e59c;
$fa-var-users-between-lines: \e591;
$fa-var-lungs-virus: \e067;
$fa-var-spinner-third: \f3f4;
$fa-var-face-grin-tears: \f588;
$fa-var-grin-tears: \f588;
$fa-var-phone: \f095;
$fa-var-computer-mouse-scrollwheel: \f8cd;
$fa-var-mouse-alt: \f8cd;
$fa-var-calendar-xmark: \f273;
$fa-var-calendar-times: \f273;
$fa-var-child-reaching: \e59d;
$fa-var-table-layout: \e290;
$fa-var-narwhal: \f6fe;
$fa-var-ramp-loading: \f4d4;
$fa-var-calendar-circle-plus: \e470;
$fa-var-toothbrush: \f635;
$fa-var-border-inner: \f84e;
$fa-var-paw-claws: \f702;
$fa-var-kiwi-fruit: \e30c;
$fa-var-traffic-light-slow: \f639;
$fa-var-rectangle-code: \e322;
$fa-var-head-side-virus: \e064;
$fa-var-keyboard-brightness: \e1c0;
$fa-var-books-medical: \f7e8;
$fa-var-lightbulb-slash: \f673;
$fa-var-house-blank: \e487;
$fa-var-home-blank: \e487;
$fa-var-square-5: \e25a;
$fa-var-square-heart: \f4c8;
$fa-var-heart-square: \f4c8;
$fa-var-puzzle: \e443;
$fa-var-user-gear: \f4fe;
$fa-var-user-cog: \f4fe;
$fa-var-pipe-circle-check: \e436;
$fa-var-arrow-up-1-9: \f163;
$fa-var-sort-numeric-up: \f163;
$fa-var-octagon-exclamation: \e204;
$fa-var-dial-low: \e15d;
$fa-var-door-closed: \f52a;
$fa-var-laptop-mobile: \f87a;
$fa-var-phone-laptop: \f87a;
$fa-var-conveyor-belt-boxes: \f46f;
$fa-var-conveyor-belt-alt: \f46f;
$fa-var-shield-virus: \e06c;
$fa-var-starfighter-twin-ion-engine-advanced: \e28e;
$fa-var-starfighter-alt-advanced: \e28e;
$fa-var-dice-six: \f526;
$fa-var-starfighter-twin-ion-engine: \e038;
$fa-var-starfighter-alt: \e038;
$fa-var-rocket-launch: \e027;
$fa-var-mosquito-net: \e52c;
$fa-var-vent-damper: \e465;
$fa-var-bridge-water: \e4ce;
$fa-var-ban-bug: \f7f9;
$fa-var-debug: \f7f9;
$fa-var-person-booth: \f756;
$fa-var-text-width: \f035;
$fa-var-garage-car: \e00a;
$fa-var-square-kanban: \e488;
$fa-var-hat-wizard: \f6e8;
$fa-var-pen-fancy: \f5ac;
$fa-var-coffee-pot: \e002;
$fa-var-mouse-field: \e5a8;
$fa-var-person-digging: \f85e;
$fa-var-digging: \f85e;
$fa-var-shower-down: \e24d;
$fa-var-shower-alt: \e24d;
$fa-var-box-circle-check: \e0c4;
$fa-var-brightness: \e0c9;
$fa-var-car-side-bolt: \e344;
$fa-var-ornament: \f7b8;
$fa-var-phone-arrow-down-left: \e223;
$fa-var-phone-arrow-down: \e223;
$fa-var-phone-incoming: \e223;
$fa-var-cloud-word: \e138;
$fa-var-hand-fingers-crossed: \e1a3;
$fa-var-trash: \f1f8;
$fa-var-gauge-simple: \f629;
$fa-var-gauge-simple-med: \f629;
$fa-var-tachometer-average: \f629;
$fa-var-arrow-down-small-big: \f88d;
$fa-var-sort-size-down-alt: \f88d;
$fa-var-book-medical: \f7e6;
$fa-var-face-melting: \e483;
$fa-var-poo: \f2fe;
$fa-var-pen-clip-slash: \e20f;
$fa-var-pen-alt-slash: \e20f;
$fa-var-quote-right: \f10e;
$fa-var-quote-right-alt: \f10e;
$fa-var-scroll-old: \f70f;
$fa-var-guitars: \f8bf;
$fa-var-phone-xmark: \e227;
$fa-var-hose: \e419;
$fa-var-clock-six: \e352;
$fa-var-shirt: \f553;
$fa-var-t-shirt: \f553;
$fa-var-tshirt: \f553;
$fa-var-square-r: \e27c;
$fa-var-cubes: \f1b3;
$fa-var-envelope-open-dollar: \f657;
$fa-var-divide: \f529;
$fa-var-sun-cloud: \f763;
$fa-var-lamp-floor: \e015;
$fa-var-square-7: \e25c;
$fa-var-tenge-sign: \f7d7;
$fa-var-tenge: \f7d7;
$fa-var-headphones: \f025;
$fa-var-hands-holding: \f4c2;
$fa-var-campfire: \f6ba;
$fa-var-circle-ampersand: \e0f8;
$fa-var-snowflakes: \f7cf;
$fa-var-hands-clapping: \e1a8;
$fa-var-republican: \f75e;
$fa-var-leaf-maple: \f6f6;
$fa-var-arrow-left: \f060;
$fa-var-person-circle-xmark: \e543;
$fa-var-ruler: \f545;
$fa-var-cup-straw-swoosh: \e364;
$fa-var-temperature-sun: \f76a;
$fa-var-temperature-hot: \f76a;
$fa-var-align-left: \f036;
$fa-var-dice-d6: \f6d1;
$fa-var-restroom: \f7bd;
$fa-var-high-definition: \e1ae;
$fa-var-rectangle-hd: \e1ae;
$fa-var-j: \4a;
$fa-var-galaxy: \e008;
$fa-var-users-viewfinder: \e595;
$fa-var-file-video: \f1c8;
$fa-var-cherries: \e0ec;
$fa-var-up-right-from-square: \f35d;
$fa-var-external-link-alt: \f35d;
$fa-var-circle-sort: \e030;
$fa-var-sort-circle: \e030;
$fa-var-table-cells: \f00a;
$fa-var-th: \f00a;
$fa-var-file-pdf: \f1c1;
$fa-var-siren: \e02d;
$fa-var-arrow-up-to-dotted-line: \e0a1;
$fa-var-image-landscape: \e1b5;
$fa-var-landscape: \e1b5;
$fa-var-tank-water: \e452;
$fa-var-curling-stone: \f44a;
$fa-var-curling: \f44a;
$fa-var-gamepad-modern: \e5a2;
$fa-var-gamepad-alt: \e5a2;
$fa-var-messages-question: \e1e7;
$fa-var-book-bible: \f647;
$fa-var-bible: \f647;
$fa-var-o: \4f;
$fa-var-suitcase-medical: \f0fa;
$fa-var-medkit: \f0fa;
$fa-var-briefcase-arrow-right: \e2f2;
$fa-var-expand-wide: \f320;
$fa-var-clock-eleven-thirty: \e348;
$fa-var-rv: \f7be;
$fa-var-user-secret: \f21b;
$fa-var-otter: \f700;
$fa-var-dreidel: \f792;
$fa-var-person-dress: \f182;
$fa-var-female: \f182;
$fa-var-comment-dollar: \f651;
$fa-var-business-time: \f64a;
$fa-var-briefcase-clock: \f64a;
$fa-var-flower-tulip: \f801;
$fa-var-people-pants-simple: \e21a;
$fa-var-cloud-drizzle: \f738;
$fa-var-table-cells-large: \f009;
$fa-var-th-large: \f009;
$fa-var-book-tanakh: \f827;
$fa-var-tanakh: \f827;
$fa-var-solar-system: \e02f;
$fa-var-seal-question: \e243;
$fa-var-phone-volume: \f2a0;
$fa-var-volume-control-phone: \f2a0;
$fa-var-disc-drive: \f8b5;
$fa-var-hat-cowboy-side: \f8c1;
$fa-var-table-rows: \e292;
$fa-var-rows: \e292;
$fa-var-location-exclamation: \f608;
$fa-var-map-marker-exclamation: \f608;
$fa-var-face-fearful: \e375;
$fa-var-clipboard-user: \f7f3;
$fa-var-bus-school: \f5dd;
$fa-var-film-slash: \e179;
$fa-var-square-arrow-down-right: \e262;
$fa-var-book-sparkles: \f6b8;
$fa-var-book-spells: \f6b8;
$fa-var-washing-machine: \f898;
$fa-var-washer: \f898;
$fa-var-child: \f1ae;
$fa-var-lira-sign: \f195;
$fa-var-user-visor: \e04c;
$fa-var-file-plus-minus: \e177;
$fa-var-chess-clock-flip: \f43e;
$fa-var-chess-clock-alt: \f43e;
$fa-var-satellite: \f7bf;
$fa-var-plane-lock: \e558;
$fa-var-steering-wheel: \f622;
$fa-var-tag: \f02b;
$fa-var-stretcher: \f825;
$fa-var-book-section: \e0c1;
$fa-var-book-law: \e0c1;
$fa-var-inboxes: \e1bb;
$fa-var-coffee-bean: \e13e;
$fa-var-brackets-curly: \f7ea;
$fa-var-ellipsis-stroke-vertical: \f39c;
$fa-var-ellipsis-v-alt: \f39c;
$fa-var-comment: \f075;
$fa-var-square-1: \e256;
$fa-var-cake-candles: \f1fd;
$fa-var-birthday-cake: \f1fd;
$fa-var-cake: \f1fd;
$fa-var-head-side: \f6e9;
$fa-var-envelope: \f0e0;
$fa-var-dolly-empty: \f473;
$fa-var-face-tissue: \e39c;
$fa-var-angles-up: \f102;
$fa-var-angle-double-up: \f102;
$fa-var-paperclip: \f0c6;
$fa-var-chart-line-down: \f64d;
$fa-var-arrow-right-to-city: \e4b3;
$fa-var-lock-a: \e422;
$fa-var-ribbon: \f4d6;
$fa-var-lungs: \f604;
$fa-var-person-pinball: \e21d;
$fa-var-arrow-up-9-1: \f887;
$fa-var-sort-numeric-up-alt: \f887;
$fa-var-apple-core: \e08f;
$fa-var-circle-y: \e12f;
$fa-var-h6: \e413;
$fa-var-litecoin-sign: \e1d3;
$fa-var-circle-small: \e122;
$fa-var-border-none: \f850;
$fa-var-arrow-turn-down-left: \e2e1;
$fa-var-circle-nodes: \e4e2;
$fa-var-parachute-box: \f4cd;
$fa-var-message-medical: \f7f4;
$fa-var-comment-alt-medical: \f7f4;
$fa-var-rugby-ball: \e3c6;
$fa-var-comment-music: \f8b0;
$fa-var-indent: \f03c;
$fa-var-tree-deciduous: \f400;
$fa-var-tree-alt: \f400;
$fa-var-puzzle-piece-simple: \e231;
$fa-var-puzzle-piece-alt: \e231;
$fa-var-truck-field-un: \e58e;
$fa-var-nfc-trash: \e1fd;
$fa-var-hourglass: \f254;
$fa-var-hourglass-empty: \f254;
$fa-var-mountain: \f6fc;
$fa-var-file-xmark: \f317;
$fa-var-file-times: \f317;
$fa-var-house-heart: \f4c9;
$fa-var-home-heart: \f4c9;
$fa-var-house-chimney-blank: \e3b0;
$fa-var-meter-bolt: \e1e9;
$fa-var-user-doctor: \f0f0;
$fa-var-user-md: \f0f0;
$fa-var-slash-back: \5c;
$fa-var-circle-info: \f05a;
$fa-var-info-circle: \f05a;
$fa-var-fishing-rod: \e3a8;
$fa-var-hammer-crash: \e414;
$fa-var-message-heart: \e5c9;
$fa-var-cloud-meatball: \f73b;
$fa-var-camera-polaroid: \f8aa;
$fa-var-camera: \f030;
$fa-var-camera-alt: \f030;
$fa-var-square-virus: \e578;
$fa-var-cart-arrow-up: \e3ee;
$fa-var-meteor: \f753;
$fa-var-car-on: \e4dd;
$fa-var-sleigh: \f7cc;
$fa-var-arrow-down-1-9: \f162;
$fa-var-sort-numeric-asc: \f162;
$fa-var-sort-numeric-down: \f162;
$fa-var-buoy-mooring: \e5b6;
$fa-var-square-4: \e259;
$fa-var-hand-holding-droplet: \f4c1;
$fa-var-hand-holding-water: \f4c1;
$fa-var-tricycle-adult: \e5c4;
$fa-var-waveform: \f8f1;
$fa-var-water: \f773;
$fa-var-star-sharp-half-stroke: \e28d;
$fa-var-star-sharp-half-alt: \e28d;
$fa-var-nfc-signal: \e1fb;
$fa-var-plane-prop: \e22b;
$fa-var-calendar-check: \f274;
$fa-var-clock-desk: \e134;
$fa-var-calendar-clock: \e0d2;
$fa-var-calendar-time: \e0d2;
$fa-var-braille: \f2a1;
$fa-var-prescription-bottle-medical: \f486;
$fa-var-prescription-bottle-alt: \f486;
$fa-var-plate-utensils: \e43b;
$fa-var-family-pants: \e302;
$fa-var-hose-reel: \e41a;
$fa-var-house-window: \e3b3;
$fa-var-landmark: \f66f;
$fa-var-truck: \f0d1;
$fa-var-crosshairs: \f05b;
$fa-var-cloud-rainbow: \f73e;
$fa-var-person-cane: \e53c;
$fa-var-alien: \f8f5;
$fa-var-tent: \e57d;
$fa-var-vest-patches: \e086;
$fa-var-people-dress-simple: \e218;
$fa-var-check-double: \f560;
$fa-var-arrow-down-a-z: \f15d;
$fa-var-sort-alpha-asc: \f15d;
$fa-var-sort-alpha-down: \f15d;
$fa-var-bowling-ball-pin: \e0c3;
$fa-var-bell-school-slash: \f5d6;
$fa-var-plus-large: \e59e;
$fa-var-money-bill-wheat: \e52a;
$fa-var-camera-viewfinder: \e0da;
$fa-var-screenshot: \e0da;
$fa-var-message-music: \f8af;
$fa-var-comment-alt-music: \f8af;
$fa-var-car-building: \f859;
$fa-var-border-bottom-right: \f854;
$fa-var-border-style-alt: \f854;
$fa-var-octagon: \f306;
$fa-var-comment-arrow-up-right: \e145;
$fa-var-octagon-divide: \e203;
$fa-var-cookie: \f563;
$fa-var-arrow-rotate-left: \f0e2;
$fa-var-arrow-left-rotate: \f0e2;
$fa-var-arrow-rotate-back: \f0e2;
$fa-var-arrow-rotate-backward: \f0e2;
$fa-var-undo: \f0e2;
$fa-var-tv-music: \f8e6;
$fa-var-hard-drive: \f0a0;
$fa-var-hdd: \f0a0;
$fa-var-reel: \e238;
$fa-var-face-grin-squint-tears: \f586;
$fa-var-grin-squint-tears: \f586;
$fa-var-dumbbell: \f44b;
$fa-var-rectangle-list: \f022;
$fa-var-list-alt: \f022;
$fa-var-tarp-droplet: \e57c;
$fa-var-alarm-exclamation: \f843;
$fa-var-house-medical-circle-check: \e511;
$fa-var-traffic-cone: \f636;
$fa-var-grate: \e193;
$fa-var-arrow-down-right: \e093;
$fa-var-person-skiing-nordic: \f7ca;
$fa-var-skiing-nordic: \f7ca;
$fa-var-calendar-plus: \f271;
$fa-var-person-from-portal: \e023;
$fa-var-portal-exit: \e023;
$fa-var-plane-arrival: \f5af;
$fa-var-cowbell-circle-plus: \f8b4;
$fa-var-cowbell-more: \f8b4;
$fa-var-circle-left: \f359;
$fa-var-arrow-alt-circle-left: \f359;
$fa-var-distribute-spacing-vertical: \e366;
$fa-var-signal-bars-fair: \f692;
$fa-var-signal-alt-2: \f692;
$fa-var-sportsball: \e44b;
$fa-var-game-console-handheld-crank: \e5b9;
$fa-var-train-subway: \f239;
$fa-var-subway: \f239;
$fa-var-chart-gantt: \e0e4;
$fa-var-face-smile-upside-down: \e395;
$fa-var-ball-pile: \f77e;
$fa-var-badge-dollar: \f645;
$fa-var-money-bills-simple: \e1f4;
$fa-var-money-bills-alt: \e1f4;
$fa-var-list-timeline: \e1d1;
$fa-var-indian-rupee-sign: \e1bc;
$fa-var-indian-rupee: \e1bc;
$fa-var-inr: \e1bc;
$fa-var-crop-simple: \f565;
$fa-var-crop-alt: \f565;
$fa-var-money-bill-1: \f3d1;
$fa-var-money-bill-alt: \f3d1;
$fa-var-left-long: \f30a;
$fa-var-long-arrow-alt-left: \f30a;
$fa-var-keyboard-down: \e1c2;
$fa-var-circle-up-right: \e129;
$fa-var-cloud-bolt-moon: \f76d;
$fa-var-thunderstorm-moon: \f76d;
$fa-var-dna: \f471;
$fa-var-virus-slash: \e075;
$fa-var-bracket-round-right: \29;
$fa-var-circle-5: \e0f2;
$fa-var-minus: \f068;
$fa-var-subtract: \f068;
$fa-var-fire-flame: \f6df;
$fa-var-flame: \f6df;
$fa-var-right-to-line: \f34c;
$fa-var-arrow-alt-to-right: \f34c;
$fa-var-gif: \e190;
$fa-var-chess: \f439;
$fa-var-trash-slash: \e2b3;
$fa-var-arrow-left-long: \f177;
$fa-var-long-arrow-left: \f177;
$fa-var-plug-circle-check: \e55c;
$fa-var-font-case: \f866;
$fa-var-street-view: \f21d;
$fa-var-arrow-down-left: \e091;
$fa-var-franc-sign: \e18f;
$fa-var-flask-round-poison: \f6e0;
$fa-var-flask-poison: \f6e0;
$fa-var-volume-off: \f026;
$fa-var-book-circle-arrow-right: \e0bc;
$fa-var-chart-user: \f6a3;
$fa-var-user-chart: \f6a3;
$fa-var-hands-asl-interpreting: \f2a3;
$fa-var-american-sign-language-interpreting: \f2a3;
$fa-var-asl-interpreting: \f2a3;
$fa-var-hands-american-sign-language-interpreting: \f2a3;
$fa-var-presentation-screen: \f685;
$fa-var-presentation: \f685;
$fa-var-circle-bolt: \e0fe;
$fa-var-face-smile-halo: \e38f;
$fa-var-cart-circle-arrow-down: \e3ef;
$fa-var-house-person-return: \e011;
$fa-var-house-person-arrive: \e011;
$fa-var-house-return: \e011;
$fa-var-message-xmark: \f4ab;
$fa-var-comment-alt-times: \f4ab;
$fa-var-message-times: \f4ab;
$fa-var-file-certificate: \f5f3;
$fa-var-file-award: \f5f3;
$fa-var-user-doctor-hair-long: \e459;
$fa-var-camera-security: \f8fe;
$fa-var-camera-home: \f8fe;
$fa-var-gear: \f013;
$fa-var-cog: \f013;
$fa-var-droplet-slash: \f5c7;
$fa-var-tint-slash: \f5c7;
$fa-var-book-heart: \f499;
$fa-var-mosque: \f678;
$fa-var-duck: \f6d8;
$fa-var-mosquito: \e52b;
$fa-var-star-of-david: \f69a;
$fa-var-flag-swallowtail: \f74c;
$fa-var-flag-alt: \f74c;
$fa-var-person-military-rifle: \e54b;
$fa-var-car-garage: \f5e2;
$fa-var-cart-shopping: \f07a;
$fa-var-shopping-cart: \f07a;
$fa-var-book-font: \e0bf;
$fa-var-shield-plus: \e24a;
$fa-var-vials: \f493;
$fa-var-eye-dropper-full: \e172;
$fa-var-distribute-spacing-horizontal: \e365;
$fa-var-tablet-rugged: \f48f;
$fa-var-temperature-snow: \f768;
$fa-var-temperature-frigid: \f768;
$fa-var-moped: \e3b9;
$fa-var-face-smile-plus: \f5b9;
$fa-var-smile-plus: \f5b9;
$fa-var-radio-tuner: \f8d8;
$fa-var-radio-alt: \f8d8;
$fa-var-face-swear: \e399;
$fa-var-water-arrow-down: \f774;
$fa-var-water-lower: \f774;
$fa-var-scanner-touchscreen: \f48a;
$fa-var-circle-7: \e0f4;
$fa-var-plug-circle-plus: \e55f;
$fa-var-person-ski-jumping: \f7c7;
$fa-var-ski-jump: \f7c7;
$fa-var-place-of-worship: \f67f;
$fa-var-water-arrow-up: \f775;
$fa-var-water-rise: \f775;
$fa-var-waveform-lines: \f8f2;
$fa-var-waveform-path: \f8f2;
$fa-var-split: \e254;
$fa-var-film-canister: \f8b7;
$fa-var-film-cannister: \f8b7;
$fa-var-folder-xmark: \f65f;
$fa-var-folder-times: \f65f;
$fa-var-toilet-paper-blank: \f71f;
$fa-var-toilet-paper-alt: \f71f;
$fa-var-tablet-screen: \f3fc;
$fa-var-tablet-android-alt: \f3fc;
$fa-var-hexagon-vertical-nft-slanted: \e506;
$fa-var-folder-music: \e18d;
$fa-var-display-medical: \e166;
$fa-var-desktop-medical: \e166;
$fa-var-share-all: \f367;
$fa-var-peapod: \e31c;
$fa-var-chess-clock: \f43d;
$fa-var-axe: \f6b2;
$fa-var-square-d: \e268;
$fa-var-grip-vertical: \f58e;
$fa-var-mobile-signal-out: \e1f0;
$fa-var-arrow-turn-up: \f148;
$fa-var-level-up: \f148;
$fa-var-u: \55;
$fa-var-arrow-up-from-dotted-line: \e09b;
$fa-var-square-root-variable: \f698;
$fa-var-square-root-alt: \f698;
$fa-var-light-switch-on: \e019;
$fa-var-arrow-down-arrow-up: \f883;
$fa-var-sort-alt: \f883;
$fa-var-raindrops: \f75c;
$fa-var-dash: \e404;
$fa-var-minus-large: \e404;
$fa-var-clock: \f017;
$fa-var-clock-four: \f017;
$fa-var-input-numeric: \e1bd;
$fa-var-truck-tow: \e2b8;
$fa-var-backward-step: \f048;
$fa-var-step-backward: \f048;
$fa-var-pallet: \f482;
$fa-var-car-bolt: \e341;
$fa-var-arrows-maximize: \f31d;
$fa-var-expand-arrows: \f31d;
$fa-var-faucet: \e005;
$fa-var-cloud-sleet: \f741;
$fa-var-lamp-street: \e1c5;
$fa-var-list-radio: \e1d0;
$fa-var-pen-nib-slash: \e4a1;
$fa-var-baseball-bat-ball: \f432;
$fa-var-square-up-left: \e282;
$fa-var-overline: \f876;
$fa-var-s: \53;
$fa-var-timeline: \e29c;
$fa-var-keyboard: \f11c;
$fa-var-arrows-from-dotted-line: \e0a3;
$fa-var-usb-drive: \f8e9;
$fa-var-ballot: \f732;
$fa-var-caret-down: \f0d7;
$fa-var-location-dot-slash: \f605;
$fa-var-map-marker-alt-slash: \f605;
$fa-var-cards: \e3ed;
$fa-var-house-chimney-medical: \f7f2;
$fa-var-clinic-medical: \f7f2;
$fa-var-boxing-glove: \f438;
$fa-var-glove-boxing: \f438;
$fa-var-temperature-three-quarters: \f2c8;
$fa-var-temperature-3: \f2c8;
$fa-var-thermometer-3: \f2c8;
$fa-var-thermometer-three-quarters: \f2c8;
$fa-var-bell-school: \f5d5;
$fa-var-mobile-screen: \f3cf;
$fa-var-mobile-android-alt: \f3cf;
$fa-var-plane-up: \e22d;
$fa-var-folder-heart: \e189;
$fa-var-circle-location-arrow: \f602;
$fa-var-location-circle: \f602;
$fa-var-face-head-bandage: \e37a;
$fa-var-sushi-roll: \e48b;
$fa-var-maki-roll: \e48b;
$fa-var-makizushi: \e48b;
$fa-var-car-bump: \f5e0;
$fa-var-piggy-bank: \f4d3;
$fa-var-racquet: \f45a;
$fa-var-car-mirrors: \e343;
$fa-var-industry-windows: \f3b3;
$fa-var-industry-alt: \f3b3;
$fa-var-bolt-auto: \e0b6;
$fa-var-battery-half: \f242;
$fa-var-battery-3: \f242;
$fa-var-flux-capacitor: \f8ba;
$fa-var-mountain-city: \e52e;
$fa-var-coins: \f51e;
$fa-var-honey-pot: \e418;
$fa-var-olive: \e316;
$fa-var-khanda: \f66d;
$fa-var-filter-list: \e17c;
$fa-var-outlet: \e01c;
$fa-var-sliders: \f1de;
$fa-var-sliders-h: \f1de;
$fa-var-cauldron: \f6bf;
$fa-var-people: \e216;
$fa-var-folder-tree: \f802;
$fa-var-network-wired: \f6ff;
$fa-var-croissant: \f7f6;
$fa-var-map-pin: \f276;
$fa-var-hamsa: \f665;
$fa-var-cent-sign: \e3f5;
$fa-var-swords-laser: \e03d;
$fa-var-flask: \f0c3;
$fa-var-person-pregnant: \e31e;
$fa-var-square-u: \e281;
$fa-var-wand-sparkles: \f72b;
$fa-var-router: \f8da;
$fa-var-ellipsis-vertical: \f142;
$fa-var-ellipsis-v: \f142;
$fa-var-sword-laser-alt: \e03c;
$fa-var-ticket: \f145;
$fa-var-power-off: \f011;
$fa-var-coin: \f85c;
$fa-var-laptop-slash: \e1c7;
$fa-var-right-long: \f30b;
$fa-var-long-arrow-alt-right: \f30b;
$fa-var-circle-b: \e0fd;
$fa-var-person-dress-simple: \e21c;
$fa-var-pipe-collar: \e437;
$fa-var-lights-holiday: \f7b2;
$fa-var-citrus: \e2f4;
$fa-var-flag-usa: \f74d;
$fa-var-laptop-file: \e51d;
$fa-var-tty: \f1e4;
$fa-var-teletype: \f1e4;
$fa-var-chart-tree-map: \e0ea;
$fa-var-diagram-next: \e476;
$fa-var-person-rifle: \e54e;
$fa-var-clock-five-thirty: \e34a;
$fa-var-pipe-valve: \e439;
$fa-var-arrow-up-from-arc: \e4b4;
$fa-var-face-spiral-eyes: \e485;
$fa-var-compress-wide: \f326;
$fa-var-circle-phone-hangup: \e11d;
$fa-var-phone-circle-down: \e11d;
$fa-var-house-medical-circle-exclamation: \e512;
$fa-var-badminton: \e33a;
$fa-var-closed-captioning: \f20a;
$fa-var-person-hiking: \f6ec;
$fa-var-hiking: \f6ec;
$fa-var-right-from-line: \f347;
$fa-var-arrow-alt-from-left: \f347;
$fa-var-venus-double: \f226;
$fa-var-images: \f302;
$fa-var-calculator: \f1ec;
$fa-var-shuttlecock: \f45b;
$fa-var-user-hair: \e45a;
$fa-var-eye-evil: \f6db;
$fa-var-people-pulling: \e535;
$fa-var-n: \4e;
$fa-var-garage: \e009;
$fa-var-cable-car: \f7da;
$fa-var-tram: \f7da;
$fa-var-shovel-snow: \f7c3;
$fa-var-cloud-rain: \f73d;
$fa-var-face-lying: \e37e;
$fa-var-sprinkler: \e035;
$fa-var-building-circle-xmark: \e4d4;
$fa-var-person-sledding: \f7cb;
$fa-var-sledding: \f7cb;
$fa-var-game-console-handheld: \f8bb;
$fa-var-ship: \f21a;
$fa-var-clock-six-thirty: \e353;
$fa-var-battery-slash: \f377;
$fa-var-tugrik-sign: \e2ba;
$fa-var-arrows-down-to-line: \e4b8;
$fa-var-download: \f019;
$fa-var-shelves: \f480;
$fa-var-inventory: \f480;
$fa-var-cloud-snow: \f742;
$fa-var-face-grin: \f580;
$fa-var-grin: \f580;
$fa-var-delete-left: \f55a;
$fa-var-backspace: \f55a;
$fa-var-oven: \e01d;
$fa-var-eye-dropper: \f1fb;
$fa-var-eye-dropper-empty: \f1fb;
$fa-var-eyedropper: \f1fb;
$fa-var-comment-captions: \e146;
$fa-var-comments-question: \e14e;
$fa-var-scribble: \e23f;
$fa-var-rotate-exclamation: \e23c;
$fa-var-file-circle-check: \e5a0;
$fa-var-glass: \f804;
$fa-var-loader: \e1d4;
$fa-var-forward: \f04e;
$fa-var-user-pilot: \e2c0;
$fa-var-mobile: \f3ce;
$fa-var-mobile-android: \f3ce;
$fa-var-mobile-phone: \f3ce;
$fa-var-code-pull-request-closed: \e3f9;
$fa-var-face-meh: \f11a;
$fa-var-meh: \f11a;
$fa-var-align-center: \f037;
$fa-var-book-skull: \f6b7;
$fa-var-book-dead: \f6b7;
$fa-var-id-card: \f2c2;
$fa-var-drivers-license: \f2c2;
$fa-var-face-dotted: \e47f;
$fa-var-face-worried: \e3a3;
$fa-var-outdent: \f03b;
$fa-var-dedent: \f03b;
$fa-var-heart-circle-exclamation: \e4fe;
$fa-var-house: \f015;
$fa-var-home: \f015;
$fa-var-home-alt: \f015;
$fa-var-home-lg-alt: \f015;
$fa-var-vector-circle: \e2c6;
$fa-var-car-circle-bolt: \e342;
$fa-var-calendar-week: \f784;
$fa-var-flying-disc: \e3a9;
$fa-var-laptop-medical: \f812;
$fa-var-square-down-right: \e26c;
$fa-var-b: \42;
$fa-var-seat-airline: \e244;
$fa-var-moon-over-sun: \f74a;
$fa-var-eclipse-alt: \f74a;
$fa-var-pipe: \7c;
$fa-var-file-medical: \f477;
$fa-var-potato: \e440;
$fa-var-dice-one: \f525;
$fa-var-circle-a: \e0f7;
$fa-var-helmet-battle: \f6eb;
$fa-var-butter: \e3e4;
$fa-var-blanket-fire: \e3da;
$fa-var-kiwi-bird: \f535;
$fa-var-castle: \e0de;
$fa-var-golf-club: \f451;
$fa-var-arrow-right-arrow-left: \f0ec;
$fa-var-exchange: \f0ec;
$fa-var-rotate-right: \f2f9;
$fa-var-redo-alt: \f2f9;
$fa-var-rotate-forward: \f2f9;
$fa-var-utensils: \f2e7;
$fa-var-cutlery: \f2e7;
$fa-var-arrow-up-wide-short: \f161;
$fa-var-sort-amount-up: \f161;
$fa-var-balloons: \e2e4;
$fa-var-mill-sign: \e1ed;
$fa-var-bowl-rice: \e2eb;
$fa-var-timeline-arrow: \e29d;
$fa-var-skull: \f54c;
$fa-var-game-board-simple: \f868;
$fa-var-game-board-alt: \f868;
$fa-var-circle-video: \e12b;
$fa-var-video-circle: \e12b;
$fa-var-chart-scatter-bubble: \e0e9;
$fa-var-house-turret: \e1b4;
$fa-var-banana: \e2e5;
$fa-var-hand-holding-skull: \e1a4;
$fa-var-people-dress: \e217;
$fa-var-loveseat: \f4cc;
$fa-var-couch-small: \f4cc;
$fa-var-tower-broadcast: \f519;
$fa-var-broadcast-tower: \f519;
$fa-var-truck-pickup: \f63c;
$fa-var-block-quote: \e0b5;
$fa-var-up-long: \f30c;
$fa-var-long-arrow-alt-up: \f30c;
$fa-var-stop: \f04d;
$fa-var-code-merge: \f387;
$fa-var-money-check-dollar-pen: \f873;
$fa-var-money-check-edit-alt: \f873;
$fa-var-up-from-line: \f346;
$fa-var-arrow-alt-from-bottom: \f346;
$fa-var-upload: \f093;
$fa-var-hurricane: \f751;
$fa-var-people-pants: \e219;
$fa-var-mound: \e52d;
$fa-var-windsock: \f777;
$fa-var-circle-half: \e110;
$fa-var-brake-warning: \e0c7;
$fa-var-toilet-portable: \e583;
$fa-var-compact-disc: \f51f;
$fa-var-file-arrow-down: \f56d;
$fa-var-file-download: \f56d;
$fa-var-saxophone-fire: \f8db;
$fa-var-sax-hot: \f8db;
$fa-var-camera-web-slash: \f833;
$fa-var-webcam-slash: \f833;
$fa-var-folder-medical: \e18c;
$fa-var-folder-gear: \e187;
$fa-var-folder-cog: \e187;
$fa-var-hand-wave: \e1a7;
$fa-var-arrow-up-arrow-down: \e099;
$fa-var-sort-up-down: \e099;
$fa-var-caravan: \f8ff;
$fa-var-shield-cat: \e572;
$fa-var-message-slash: \f4a9;
$fa-var-comment-alt-slash: \f4a9;
$fa-var-bolt: \f0e7;
$fa-var-zap: \f0e7;
$fa-var-trash-can-check: \e2a9;
$fa-var-glass-water: \e4f4;
$fa-var-oil-well: \e532;
$fa-var-person-simple: \e220;
$fa-var-vault: \e2c5;
$fa-var-mars: \f222;
$fa-var-toilet: \f7d8;
$fa-var-plane-circle-xmark: \e557;
$fa-var-yen-sign: \f157;
$fa-var-cny: \f157;
$fa-var-jpy: \f157;
$fa-var-rmb: \f157;
$fa-var-yen: \f157;
$fa-var-notes: \e202;
$fa-var-ruble-sign: \f158;
$fa-var-rouble: \f158;
$fa-var-rub: \f158;
$fa-var-ruble: \f158;
$fa-var-trash-undo: \f895;
$fa-var-trash-arrow-turn-left: \f895;
$fa-var-champagne-glass: \f79e;
$fa-var-glass-champagne: \f79e;
$fa-var-objects-align-center-horizontal: \e3bc;
$fa-var-sun: \f185;
$fa-var-trash-can-slash: \e2ad;
$fa-var-trash-alt-slash: \e2ad;
$fa-var-screen-users: \f63d;
$fa-var-users-class: \f63d;
$fa-var-guitar: \f7a6;
$fa-var-square-arrow-left: \f33a;
$fa-var-arrow-square-left: \f33a;
$fa-var-square-8: \e25d;
$fa-var-face-smile-hearts: \e390;
$fa-var-brackets-square: \f7e9;
$fa-var-brackets: \f7e9;
$fa-var-laptop-arrow-down: \e1c6;
$fa-var-hockey-stick-puck: \e3ae;
$fa-var-house-tree: \e1b3;
$fa-var-signal-fair: \f68d;
$fa-var-signal-2: \f68d;
$fa-var-face-laugh-wink: \f59c;
$fa-var-laugh-wink: \f59c;
$fa-var-circle-dollar: \f2e8;
$fa-var-dollar-circle: \f2e8;
$fa-var-usd-circle: \f2e8;
$fa-var-horse-head: \f7ab;
$fa-var-arrows-repeat: \f364;
$fa-var-repeat-alt: \f364;
$fa-var-bore-hole: \e4c3;
$fa-var-industry: \f275;
$fa-var-image-polaroid: \f8c4;
$fa-var-wave-triangle: \f89a;
$fa-var-circle-down: \f358;
$fa-var-arrow-alt-circle-down: \f358;
$fa-var-grill: \e5a3;
$fa-var-arrows-turn-to-dots: \e4c1;
$fa-var-chart-mixed: \f643;
$fa-var-analytics: \f643;
$fa-var-florin-sign: \e184;
$fa-var-arrow-down-short-wide: \f884;
$fa-var-sort-amount-desc: \f884;
$fa-var-sort-amount-down-alt: \f884;
$fa-var-less-than: \3c;
$fa-var-display-code: \e165;
$fa-var-desktop-code: \e165;
$fa-var-face-drooling: \e372;
$fa-var-oil-temperature: \f614;
$fa-var-oil-temp: \f614;
$fa-var-square-question: \f2fd;
$fa-var-question-square: \f2fd;
$fa-var-air-conditioner: \f8f4;
$fa-var-angle-down: \f107;
$fa-var-mountains: \f6fd;
$fa-var-omega: \f67a;
$fa-var-car-tunnel: \e4de;
$fa-var-person-dolly-empty: \f4d1;
$fa-var-pan-food: \e42b;
$fa-var-head-side-cough: \e061;
$fa-var-grip-lines: \f7a4;
$fa-var-thumbs-down: \f165;
$fa-var-user-lock: \f502;
$fa-var-arrow-right-long: \f178;
$fa-var-long-arrow-right: \f178;
$fa-var-tickets-airline: \e29b;
$fa-var-anchor-circle-xmark: \e4ac;
$fa-var-ellipsis: \f141;
$fa-var-ellipsis-h: \f141;
$fa-var-nfc-slash: \e1fc;
$fa-var-chess-pawn: \f443;
$fa-var-kit-medical: \f479;
$fa-var-first-aid: \f479;
$fa-var-grid-2-plus: \e197;
$fa-var-bells: \f77f;
$fa-var-person-through-window: \e5a9;
$fa-var-toolbox: \f552;
$fa-var-envelope-dot: \e16f;
$fa-var-envelope-badge: \e16f;
$fa-var-hands-holding-circle: \e4fb;
$fa-var-bug: \f188;
$fa-var-bowl-chopsticks: \e2e9;
$fa-var-credit-card: \f09d;
$fa-var-credit-card-alt: \f09d;
$fa-var-circle-s: \e121;
$fa-var-box-ballot: \f735;
$fa-var-car: \f1b9;
$fa-var-automobile: \f1b9;
$fa-var-hand-holding-hand: \e4f7;
$fa-var-user-tie-hair: \e45f;
$fa-var-podium-star: \f758;
$fa-var-user-hair-mullet: \e45c;
$fa-var-business-front: \e45c;
$fa-var-party-back: \e45c;
$fa-var-trian-balbot: \e45c;
$fa-var-microphone-stand: \f8cb;
$fa-var-book-open-reader: \f5da;
$fa-var-book-reader: \f5da;
$fa-var-family-dress: \e301;
$fa-var-circle-x: \e12e;
$fa-var-cabin: \e46d;
$fa-var-mountain-sun: \e52f;
$fa-var-chart-simple-horizontal: \e474;
$fa-var-arrows-left-right-to-line: \e4ba;
$fa-var-hand-back-point-left: \e19f;
$fa-var-message-dots: \f4a3;
$fa-var-comment-alt-dots: \f4a3;
$fa-var-messaging: \f4a3;
$fa-var-file-heart: \e176;
$fa-var-beer-mug: \e0b3;
$fa-var-beer-foam: \e0b3;
$fa-var-dice-d20: \f6cf;
$fa-var-drone: \f85f;
$fa-var-truck-droplet: \e58c;
$fa-var-file-circle-xmark: \e5a1;
$fa-var-temperature-arrow-up: \e040;
$fa-var-temperature-up: \e040;
$fa-var-medal: \f5a2;
$fa-var-bed: \f236;
$fa-var-book-copy: \e0be;
$fa-var-square-h: \f0fd;
$fa-var-h-square: \f0fd;
$fa-var-square-c: \e266;
$fa-var-clock-two: \e35a;
$fa-var-square-ellipsis-vertical: \e26f;
$fa-var-podcast: \f2ce;
$fa-var-bee: \e0b2;
$fa-var-temperature-full: \f2c7;
$fa-var-temperature-4: \f2c7;
$fa-var-thermometer-4: \f2c7;
$fa-var-thermometer-full: \f2c7;
$fa-var-bell: \f0f3;
$fa-var-candy-bar: \e3e8;
$fa-var-chocolate-bar: \e3e8;
$fa-var-xmark-large: \e59b;
$fa-var-pinata: \e3c3;
$fa-var-arrows-from-line: \e0a4;
$fa-var-superscript: \f12b;
$fa-var-bowl-spoon: \e3e0;
$fa-var-hexagon-check: \e416;
$fa-var-plug-circle-xmark: \e560;
$fa-var-star-of-life: \f621;
$fa-var-phone-slash: \f3dd;
$fa-var-traffic-light-stop: \f63a;
$fa-var-paint-roller: \f5aa;
$fa-var-accent-grave: \60;
$fa-var-handshake-angle: \f4c4;
$fa-var-hands-helping: \f4c4;
$fa-var-circle-0: \e0ed;
$fa-var-dial-med-low: \e160;
$fa-var-location-dot: \f3c5;
$fa-var-map-marker-alt: \f3c5;
$fa-var-crab: \e3ff;
$fa-var-box-open-full: \f49c;
$fa-var-box-full: \f49c;
$fa-var-file: \f15b;
$fa-var-greater-than: \3e;
$fa-var-quotes: \e234;
$fa-var-pretzel: \e441;
$fa-var-person-swimming: \f5c4;
$fa-var-swimmer: \f5c4;
$fa-var-arrow-down: \f063;
$fa-var-user-robot-xmarks: \e4a7;
$fa-var-message-quote: \e1e4;
$fa-var-comment-alt-quote: \e1e4;
$fa-var-candy-corn: \f6bd;
$fa-var-folder-magnifying-glass: \e18b;
$fa-var-folder-search: \e18b;
$fa-var-notebook: \e201;
$fa-var-droplet: \f043;
$fa-var-tint: \f043;
$fa-var-bullseye-pointer: \f649;
$fa-var-eraser: \f12d;
$fa-var-hexagon-image: \e504;
$fa-var-earth-americas: \f57d;
$fa-var-earth: \f57d;
$fa-var-earth-america: \f57d;
$fa-var-globe-americas: \f57d;
$fa-var-crate-apple: \f6b1;
$fa-var-apple-crate: \f6b1;
$fa-var-person-burst: \e53b;
$fa-var-game-board: \f867;
$fa-var-hat-chef: \f86b;
$fa-var-hand-back-point-right: \e1a1;
$fa-var-dove: \f4ba;
$fa-var-snowflake-droplets: \e5c1;
$fa-var-battery-empty: \f244;
$fa-var-battery-0: \f244;
$fa-var-grid-4: \e198;
$fa-var-socks: \f696;
$fa-var-face-sunglasses: \e398;
$fa-var-inbox: \f01c;
$fa-var-square-0: \e255;
$fa-var-section: \e447;
$fa-var-square-this-way-up: \f49f;
$fa-var-box-up: \f49f;
$fa-var-gauge-high: \f625;
$fa-var-tachometer-alt: \f625;
$fa-var-tachometer-alt-fast: \f625;
$fa-var-square-ampersand: \e260;
$fa-var-envelope-open-text: \f658;
$fa-var-lamp-desk: \e014;
$fa-var-hospital: \f0f8;
$fa-var-hospital-alt: \f0f8;
$fa-var-hospital-wide: \f0f8;
$fa-var-poll-people: \f759;
$fa-var-whiskey-glass-ice: \f7a1;
$fa-var-glass-whiskey-rocks: \f7a1;
$fa-var-wine-bottle: \f72f;
$fa-var-chess-rook: \f447;
$fa-var-user-bounty-hunter: \e2bf;
$fa-var-bars-staggered: \f550;
$fa-var-reorder: \f550;
$fa-var-stream: \f550;
$fa-var-diagram-sankey: \e158;
$fa-var-cloud-hail-mixed: \f73a;
$fa-var-circle-up-left: \e128;
$fa-var-dharmachakra: \f655;
$fa-var-objects-align-left: \e3be;
$fa-var-oil-can-drip: \e205;
$fa-var-face-smiling-hands: \e396;
$fa-var-broccoli: \e3e2;
$fa-var-route-interstate: \f61b;
$fa-var-ear-muffs: \f795;
$fa-var-hotdog: \f80f;
$fa-var-transporter-empty: \e046;
$fa-var-person-walking-with-cane: \f29d;
$fa-var-blind: \f29d;
$fa-var-angle-90: \e08d;
$fa-var-rectangle-terminal: \e236;
$fa-var-kite: \f6f4;
$fa-var-drum: \f569;
$fa-var-scrubber: \f2f8;
$fa-var-ice-cream: \f810;
$fa-var-heart-circle-bolt: \e4fc;
$fa-var-fish-bones: \e304;
$fa-var-deer-rudolph: \f78f;
$fa-var-fax: \f1ac;
$fa-var-paragraph: \f1dd;
$fa-var-head-side-heart: \e1aa;
$fa-var-square-e: \e26d;
$fa-var-meter-fire: \e1eb;
$fa-var-cloud-hail: \f739;
$fa-var-check-to-slot: \f772;
$fa-var-vote-yea: \f772;
$fa-var-money-from-bracket: \e312;
$fa-var-star-half: \f089;
$fa-var-car-bus: \f85a;
$fa-var-speaker: \f8df;
$fa-var-timer: \e29e;
$fa-var-boxes-stacked: \f468;
$fa-var-boxes: \f468;
$fa-var-boxes-alt: \f468;
$fa-var-grill-hot: \e5a5;
$fa-var-ballot-check: \f733;
$fa-var-link: \f0c1;
$fa-var-chain: \f0c1;
$fa-var-ear-listen: \f2a2;
$fa-var-assistive-listening-systems: \f2a2;
$fa-var-file-minus: \f318;
$fa-var-tree-city: \e587;
$fa-var-play: \f04b;
$fa-var-font: \f031;
$fa-var-cup-togo: \f6c5;
$fa-var-coffee-togo: \f6c5;
$fa-var-square-down-left: \e26b;
$fa-var-burger-lettuce: \e3e3;
$fa-var-rupiah-sign: \e23d;
$fa-var-magnifying-glass: \f002;
$fa-var-search: \f002;
$fa-var-table-tennis-paddle-ball: \f45d;
$fa-var-ping-pong-paddle-ball: \f45d;
$fa-var-table-tennis: \f45d;
$fa-var-person-dots-from-line: \f470;
$fa-var-diagnoses: \f470;
$fa-var-chevrons-down: \f322;
$fa-var-chevron-double-down: \f322;
$fa-var-trash-can-arrow-up: \f82a;
$fa-var-trash-restore-alt: \f82a;
$fa-var-signal-good: \f68e;
$fa-var-signal-3: \f68e;
$fa-var-location-question: \f60b;
$fa-var-map-marker-question: \f60b;
$fa-var-floppy-disk-circle-xmark: \e181;
$fa-var-floppy-disk-times: \e181;
$fa-var-save-circle-xmark: \e181;
$fa-var-save-times: \e181;
$fa-var-naira-sign: \e1f6;
$fa-var-peach: \e20b;
$fa-var-taxi-bus: \e298;
$fa-var-bracket-curly: \7b;
$fa-var-bracket-curly-left: \7b;
$fa-var-lobster: \e421;
$fa-var-cart-flatbed-empty: \f476;
$fa-var-dolly-flatbed-empty: \f476;
$fa-var-colon: \3a;
$fa-var-cart-arrow-down: \f218;
$fa-var-wand: \f72a;
$fa-var-walkie-talkie: \f8ef;
$fa-var-file-pen: \f31c;
$fa-var-file-edit: \f31c;
$fa-var-receipt: \f543;
$fa-var-table-picnic: \e32d;
$fa-var-square-pen: \f14b;
$fa-var-pen-square: \f14b;
$fa-var-pencil-square: \f14b;
$fa-var-circle-microphone-lines: \e117;
$fa-var-microphone-circle-alt: \e117;
$fa-var-display-slash: \e2fa;
$fa-var-desktop-slash: \e2fa;
$fa-var-suitcase-rolling: \f5c1;
$fa-var-person-circle-exclamation: \e53f;
$fa-var-transporter-2: \e044;
$fa-var-hands-holding-diamond: \f47c;
$fa-var-hand-receiving: \f47c;
$fa-var-money-bill-simple-wave: \e1f2;
$fa-var-chevron-down: \f078;
$fa-var-battery-full: \f240;
$fa-var-battery: \f240;
$fa-var-battery-5: \f240;
$fa-var-bell-plus: \f849;
$fa-var-book-arrow-right: \e0b9;
$fa-var-hospitals: \f80e;
$fa-var-club: \f327;
$fa-var-skull-crossbones: \f714;
$fa-var-droplet-degree: \f748;
$fa-var-dewpoint: \f748;
$fa-var-code-compare: \e13a;
$fa-var-list-ul: \f0ca;
$fa-var-list-dots: \f0ca;
$fa-var-hand-holding-magic: \f6e5;
$fa-var-watermelon-slice: \e337;
$fa-var-circle-ellipsis: \e10a;
$fa-var-school-lock: \e56f;
$fa-var-tower-cell: \e585;
$fa-var-sd-cards: \e240;
$fa-var-down-long: \f309;
$fa-var-long-arrow-alt-down: \f309;
$fa-var-envelopes: \e170;
$fa-var-phone-office: \f67d;
$fa-var-ranking-star: \e561;
$fa-var-chess-king: \f43f;
$fa-var-nfc-pen: \e1fa;
$fa-var-person-harassing: \e549;
$fa-var-hat-winter: \f7a8;
$fa-var-brazilian-real-sign: \e46c;
$fa-var-landmark-dome: \f752;
$fa-var-landmark-alt: \f752;
$fa-var-bone-break: \f5d8;
$fa-var-arrow-up: \f062;
$fa-var-down-from-dotted-line: \e407;
$fa-var-tv: \f26c;
$fa-var-television: \f26c;
$fa-var-tv-alt: \f26c;
$fa-var-border-left: \f84f;
$fa-var-circle-divide: \e106;
$fa-var-shrimp: \e448;
$fa-var-list-check: \f0ae;
$fa-var-tasks: \f0ae;
$fa-var-diagram-subtask: \e479;
$fa-var-jug-detergent: \e519;
$fa-var-circle-user: \f2bd;
$fa-var-user-circle: \f2bd;
$fa-var-square-y: \e287;
$fa-var-user-doctor-hair: \e458;
$fa-var-planet-ringed: \e020;
$fa-var-mushroom: \e425;
$fa-var-user-shield: \f505;
$fa-var-megaphone: \f675;
$fa-var-circle-exclamation-check: \e10d;
$fa-var-wind: \f72e;
$fa-var-box-dollar: \f4a0;
$fa-var-box-usd: \f4a0;
$fa-var-car-burst: \f5e1;
$fa-var-car-crash: \f5e1;
$fa-var-y: \59;
$fa-var-user-headset: \f82d;
$fa-var-arrows-retweet: \f361;
$fa-var-retweet-alt: \f361;
$fa-var-person-snowboarding: \f7ce;
$fa-var-snowboarding: \f7ce;
$fa-var-square-chevron-right: \f32b;
$fa-var-chevron-square-right: \f32b;
$fa-var-lacrosse-stick-ball: \e3b6;
$fa-var-truck-fast: \f48b;
$fa-var-shipping-fast: \f48b;
$fa-var-user-magnifying-glass: \e5c5;
$fa-var-star-sharp: \e28b;
$fa-var-comment-heart: \e5c8;
$fa-var-circle-1: \e0ee;
$fa-var-circle-star: \e123;
$fa-var-star-circle: \e123;
$fa-var-fish: \f578;
$fa-var-cloud-fog: \f74e;
$fa-var-fog: \f74e;
$fa-var-waffle: \e466;
$fa-var-music-note: \f8cf;
$fa-var-music-alt: \f8cf;
$fa-var-hexagon-exclamation: \e417;
$fa-var-cart-shopping-fast: \e0dc;
$fa-var-object-union: \e49f;
$fa-var-user-graduate: \f501;
$fa-var-starfighter: \e037;
$fa-var-circle-half-stroke: \f042;
$fa-var-adjust: \f042;
$fa-var-arrow-right-long-to-line: \e3d5;
$fa-var-square-arrow-down: \f339;
$fa-var-arrow-square-down: \f339;
$fa-var-diamond-half-stroke: \e5b8;
$fa-var-clapperboard: \e131;
$fa-var-square-chevron-left: \f32a;
$fa-var-chevron-square-left: \f32a;
$fa-var-phone-intercom: \e434;
$fa-var-link-horizontal: \e1cb;
$fa-var-chain-horizontal: \e1cb;
$fa-var-mango: \e30f;
$fa-var-music-note-slash: \f8d0;
$fa-var-music-alt-slash: \f8d0;
$fa-var-circle-radiation: \f7ba;
$fa-var-radiation-alt: \f7ba;
$fa-var-face-tongue-sweat: \e39e;
$fa-var-globe-stand: \f5f6;
$fa-var-baseball: \f433;
$fa-var-baseball-ball: \f433;
$fa-var-circle-p: \e11a;
$fa-var-award-simple: \e0ab;
$fa-var-jet-fighter-up: \e518;
$fa-var-diagram-project: \f542;
$fa-var-project-diagram: \f542;
$fa-var-pedestal: \e20d;
$fa-var-chart-pyramid: \e0e6;
$fa-var-sidebar: \e24e;
$fa-var-snowman-head: \f79b;
$fa-var-frosty-head: \f79b;
$fa-var-copy: \f0c5;
$fa-var-burger-glass: \e0ce;
$fa-var-volume-xmark: \f6a9;
$fa-var-volume-mute: \f6a9;
$fa-var-volume-times: \f6a9;
$fa-var-hand-sparkles: \e05d;
$fa-var-bars-filter: \e0ad;
$fa-var-paintbrush-pencil: \e206;
$fa-var-party-bell: \e31a;
$fa-var-user-vneck-hair: \e462;
$fa-var-jack-o-lantern: \f30e;
$fa-var-grip: \f58d;
$fa-var-grip-horizontal: \f58d;
$fa-var-share-from-square: \f14d;
$fa-var-share-square: \f14d;
$fa-var-keynote: \f66c;
$fa-var-child-combatant: \e4e0;
$fa-var-child-rifle: \e4e0;
$fa-var-gun: \e19b;
$fa-var-square-phone: \f098;
$fa-var-phone-square: \f098;
$fa-var-plus: \2b;
$fa-var-add: \2b;
$fa-var-expand: \f065;
$fa-var-computer: \e4e5;
$fa-var-fort: \e486;
$fa-var-cloud-check: \e35c;
$fa-var-xmark: \f00d;
$fa-var-close: \f00d;
$fa-var-multiply: \f00d;
$fa-var-remove: \f00d;
$fa-var-times: \f00d;
$fa-var-face-smirking: \e397;
$fa-var-arrows-up-down-left-right: \f047;
$fa-var-arrows: \f047;
$fa-var-chalkboard-user: \f51c;
$fa-var-chalkboard-teacher: \f51c;
$fa-var-rhombus: \e23b;
$fa-var-claw-marks: \f6c2;
$fa-var-peso-sign: \e222;
$fa-var-face-smile-tongue: \e394;
$fa-var-cart-circle-xmark: \e3f4;
$fa-var-building-shield: \e4d8;
$fa-var-circle-phone-flip: \e11c;
$fa-var-phone-circle-alt: \e11c;
$fa-var-baby: \f77c;
$fa-var-users-line: \e592;
$fa-var-quote-left: \f10d;
$fa-var-quote-left-alt: \f10d;
$fa-var-tractor: \f722;
$fa-var-key-skeleton: \f6f3;
$fa-var-trash-arrow-up: \f829;
$fa-var-trash-restore: \f829;
$fa-var-arrow-down-up-lock: \e4b0;
$fa-var-arrow-down-to-bracket: \e094;
$fa-var-lines-leaning: \e51e;
$fa-var-square-q: \e27b;
$fa-var-ruler-combined: \f546;
$fa-var-symbols: \f86e;
$fa-var-icons-alt: \f86e;
$fa-var-copyright: \f1f9;
$fa-var-highlighter-line: \e1af;
$fa-var-bracket-square: \5b;
$fa-var-bracket: \5b;
$fa-var-bracket-left: \5b;
$fa-var-island-tropical: \f811;
$fa-var-island-tree-palm: \f811;
$fa-var-arrow-right-from-line: \f343;
$fa-var-arrow-from-left: \f343;
$fa-var-h2: \f314;
$fa-var-equals: \3d;
$fa-var-cake-slice: \e3e5;
$fa-var-shortcake: \e3e5;
$fa-var-peanut: \e430;
$fa-var-wrench-simple: \e2d1;
$fa-var-blender: \f517;
$fa-var-teeth: \f62e;
$fa-var-tally-2: \e295;
$fa-var-shekel-sign: \f20b;
$fa-var-ils: \f20b;
$fa-var-shekel: \f20b;
$fa-var-sheqel: \f20b;
$fa-var-sheqel-sign: \f20b;
$fa-var-cars: \f85b;
$fa-var-axe-battle: \f6b3;
$fa-var-user-hair-long: \e45b;
$fa-var-map: \f279;
$fa-var-file-circle-info: \e493;
$fa-var-face-disappointed: \e36f;
$fa-var-lasso-sparkles: \e1c9;
$fa-var-clock-eleven: \e347;
$fa-var-rocket: \f135;
$fa-var-siren-on: \e02e;
$fa-var-clock-ten: \e354;
$fa-var-candle-holder: \f6bc;
$fa-var-video-arrow-down-left: \e2c8;
$fa-var-photo-film: \f87c;
$fa-var-photo-video: \f87c;
$fa-var-floppy-disk-circle-arrow-right: \e180;
$fa-var-save-circle-arrow-right: \e180;
$fa-var-folder-minus: \f65d;
$fa-var-planet-moon: \e01f;
$fa-var-face-eyes-xmarks: \e374;
$fa-var-chart-scatter: \f7ee;
$fa-var-display-arrow-down: \e164;
$fa-var-store: \f54e;
$fa-var-arrow-trend-up: \e098;
$fa-var-plug-circle-minus: \e55e;
$fa-var-olive-branch: \e317;
$fa-var-angle: \e08c;
$fa-var-vacuum-robot: \e04e;
$fa-var-sign-hanging: \f4d9;
$fa-var-sign: \f4d9;
$fa-var-square-divide: \e26a;
$fa-var-signal-stream-slash: \e250;
$fa-var-bezier-curve: \f55b;
$fa-var-eye-dropper-half: \e173;
$fa-var-store-lock: \e4a6;
$fa-var-bell-slash: \f1f6;
$fa-var-cloud-bolt-sun: \f76e;
$fa-var-thunderstorm-sun: \f76e;
$fa-var-camera-slash: \e0d9;
$fa-var-comment-quote: \e14c;
$fa-var-tablet: \f3fb;
$fa-var-tablet-android: \f3fb;
$fa-var-school-flag: \e56e;
$fa-var-message-code: \e1df;
$fa-var-glass-half: \e192;
$fa-var-glass-half-empty: \e192;
$fa-var-glass-half-full: \e192;
$fa-var-fill: \f575;
$fa-var-message-minus: \f4a7;
$fa-var-comment-alt-minus: \f4a7;
$fa-var-angle-up: \f106;
$fa-var-drumstick-bite: \f6d7;
$fa-var-link-horizontal-slash: \e1cc;
$fa-var-chain-horizontal-slash: \e1cc;
$fa-var-holly-berry: \f7aa;
$fa-var-nose: \e5bd;
$fa-var-chevron-left: \f053;
$fa-var-bacteria: \e059;
$fa-var-clouds: \f744;
$fa-var-money-bill-simple: \e1f1;
$fa-var-hand-lizard: \f258;
$fa-var-table-pivot: \e291;
$fa-var-filter-slash: \e17d;
$fa-var-trash-can-undo: \f896;
$fa-var-trash-can-arrow-turn-left: \f896;
$fa-var-trash-undo-alt: \f896;
$fa-var-notdef: \e1fe;
$fa-var-disease: \f7fa;
$fa-var-person-to-door: \e433;
$fa-var-turntable: \f8e4;
$fa-var-briefcase-medical: \f469;
$fa-var-genderless: \f22d;
$fa-var-chevron-right: \f054;
$fa-var-signal-weak: \f68c;
$fa-var-signal-1: \f68c;
$fa-var-clock-five: \e349;
$fa-var-retweet: \f079;
$fa-var-car-rear: \f5de;
$fa-var-car-alt: \f5de;
$fa-var-pump-soap: \e06b;
$fa-var-computer-classic: \f8b1;
$fa-var-frame: \e495;
$fa-var-video-slash: \f4e2;
$fa-var-battery-quarter: \f243;
$fa-var-battery-2: \f243;
$fa-var-ellipsis-stroke: \f39b;
$fa-var-ellipsis-h-alt: \f39b;
$fa-var-radio: \f8d7;
$fa-var-baby-carriage: \f77d;
$fa-var-carriage-baby: \f77d;
$fa-var-face-expressionless: \e373;
$fa-var-down-to-dotted-line: \e408;
$fa-var-cloud-music: \f8ae;
$fa-var-traffic-light: \f637;
$fa-var-cloud-minus: \e35d;
$fa-var-thermometer: \f491;
$fa-var-shield-minus: \e249;
$fa-var-vr-cardboard: \f729;
$fa-var-car-tilt: \f5e5;
$fa-var-gauge-circle-minus: \e497;
$fa-var-brightness-low: \e0ca;
$fa-var-hand-middle-finger: \f806;
$fa-var-percent: \25;
$fa-var-percentage: \25;
$fa-var-truck-moving: \f4df;
$fa-var-glass-water-droplet: \e4f5;
$fa-var-conveyor-belt: \f46e;
$fa-var-location-check: \f606;
$fa-var-map-marker-check: \f606;
$fa-var-coin-vertical: \e3fd;
$fa-var-display: \e163;
$fa-var-person-sign: \f757;
$fa-var-face-smile: \f118;
$fa-var-smile: \f118;
$fa-var-phone-hangup: \e225;
$fa-var-signature-slash: \e3cb;
$fa-var-thumbtack: \f08d;
$fa-var-thumb-tack: \f08d;
$fa-var-wheat-slash: \e339;
$fa-var-trophy: \f091;
$fa-var-clouds-sun: \f746;
$fa-var-person-praying: \f683;
$fa-var-pray: \f683;
$fa-var-hammer: \f6e3;
$fa-var-face-vomit: \e3a0;
$fa-var-speakers: \f8e0;
$fa-var-tty-answer: \e2b9;
$fa-var-teletype-answer: \e2b9;
$fa-var-mug-tea-saucer: \e1f5;
$fa-var-diagram-lean-canvas: \e156;
$fa-var-alt: \e08a;
$fa-var-dial: \e15b;
$fa-var-dial-med-high: \e15b;
$fa-var-hand-peace: \f25b;
$fa-var-circle-trash: \e126;
$fa-var-trash-circle: \e126;
$fa-var-rotate: \f2f1;
$fa-var-sync-alt: \f2f1;
$fa-var-circle-quarters: \e3f8;
$fa-var-spinner: \f110;
$fa-var-tower-control: \e2a2;
$fa-var-arrow-up-triangle-square: \f88a;
$fa-var-sort-shapes-up: \f88a;
$fa-var-whale: \f72c;
$fa-var-robot: \f544;
$fa-var-peace: \f67c;
$fa-var-party-horn: \e31b;
$fa-var-gears: \f085;
$fa-var-cogs: \f085;
$fa-var-sun-bright: \e28f;
$fa-var-sun-alt: \e28f;
$fa-var-warehouse: \f494;
$fa-var-lock-keyhole-open: \f3c2;
$fa-var-lock-open-alt: \f3c2;
$fa-var-square-fragile: \f49b;
$fa-var-box-fragile: \f49b;
$fa-var-square-wine-glass-crack: \f49b;
$fa-var-arrow-up-right-dots: \e4b7;
$fa-var-square-n: \e277;
$fa-var-splotch: \f5bc;
$fa-var-face-grin-hearts: \f584;
$fa-var-grin-hearts: \f584;
$fa-var-meter: \e1e8;
$fa-var-mandolin: \f6f9;
$fa-var-dice-four: \f524;
$fa-var-sim-card: \f7c4;
$fa-var-transgender: \f225;
$fa-var-transgender-alt: \f225;
$fa-var-mercury: \f223;
$fa-var-up-from-bracket: \e590;
$fa-var-knife-kitchen: \f6f5;
$fa-var-border-right: \f852;
$fa-var-arrow-turn-down: \f149;
$fa-var-level-down: \f149;
$fa-var-spade: \f2f4;
$fa-var-card-spade: \e3ec;
$fa-var-line-columns: \f870;
$fa-var-arrow-right-to-line: \f340;
$fa-var-arrow-to-right: \f340;
$fa-var-person-falling-burst: \e547;
$fa-var-flag-pennant: \f456;
$fa-var-pennant: \f456;
$fa-var-conveyor-belt-empty: \e150;
$fa-var-award: \f559;
$fa-var-ticket-simple: \f3ff;
$fa-var-ticket-alt: \f3ff;
$fa-var-building: \f1ad;
$fa-var-angles-left: \f100;
$fa-var-angle-double-left: \f100;
$fa-var-camcorder: \f8a8;
$fa-var-video-handheld: \f8a8;
$fa-var-pancakes: \e42d;
$fa-var-album-circle-user: \e48d;
$fa-var-qrcode: \f029;
$fa-var-dice-d10: \f6cd;
$fa-var-fireplace: \f79a;
$fa-var-browser: \f37e;
$fa-var-pen-paintbrush: \f618;
$fa-var-pencil-paintbrush: \f618;
$fa-var-fish-cooked: \f7fe;
$fa-var-chair-office: \f6c1;
$fa-var-nesting-dolls: \e3ba;
$fa-var-clock-rotate-left: \f1da;
$fa-var-history: \f1da;
$fa-var-trumpet: \f8e3;
$fa-var-face-grin-beam-sweat: \f583;
$fa-var-grin-beam-sweat: \f583;
$fa-var-fire-smoke: \f74b;
$fa-var-phone-missed: \e226;
$fa-var-file-export: \f56e;
$fa-var-arrow-right-from-file: \f56e;
$fa-var-shield: \f132;
$fa-var-shield-blank: \f132;
$fa-var-arrow-up-short-wide: \f885;
$fa-var-sort-amount-up-alt: \f885;
$fa-var-arrows-repeat-1: \f366;
$fa-var-repeat-1-alt: \f366;
$fa-var-gun-slash: \e19c;
$fa-var-avocado: \e0aa;
$fa-var-binary: \e33b;
$fa-var-glasses-round: \f5f5;
$fa-var-glasses-alt: \f5f5;
$fa-var-phone-plus: \f4d2;
$fa-var-ditto: \22;
$fa-var-person-seat: \e21e;
$fa-var-house-medical: \e3b2;
$fa-var-golf-ball-tee: \f450;
$fa-var-golf-ball: \f450;
$fa-var-circle-chevron-left: \f137;
$fa-var-chevron-circle-left: \f137;
$fa-var-house-chimney-window: \e00d;
$fa-var-scythe: \f710;
$fa-var-pen-nib: \f5ad;
$fa-var-ban-parking: \f616;
$fa-var-parking-circle-slash: \f616;
$fa-var-tent-arrow-turn-left: \e580;
$fa-var-face-diagonal-mouth: \e47e;
$fa-var-diagram-cells: \e475;
$fa-var-cricket-bat-ball: \f449;
$fa-var-cricket: \f449;
$fa-var-tents: \e582;
$fa-var-wand-magic: \f0d0;
$fa-var-magic: \f0d0;
$fa-var-dog: \f6d3;
$fa-var-pen-line: \e212;
$fa-var-atom-simple: \f5d3;
$fa-var-atom-alt: \f5d3;
$fa-var-ampersand: \26;
$fa-var-carrot: \f787;
$fa-var-arrow-up-from-line: \f342;
$fa-var-arrow-from-bottom: \f342;
$fa-var-moon: \f186;
$fa-var-pen-slash: \e213;
$fa-var-wine-glass-empty: \f5ce;
$fa-var-wine-glass-alt: \f5ce;
$fa-var-square-star: \e27f;
$fa-var-cheese: \f7ef;
$fa-var-send-backward: \f87f;
$fa-var-yin-yang: \f6ad;
$fa-var-music: \f001;
$fa-var-compass-slash: \f5e9;
$fa-var-clock-one: \e34e;
$fa-var-file-music: \f8b6;
$fa-var-code-commit: \f386;
$fa-var-temperature-low: \f76b;
$fa-var-person-biking: \f84a;
$fa-var-biking: \f84a;
$fa-var-skeleton: \f620;
$fa-var-circle-g: \e10f;
$fa-var-circle-arrow-up-left: \e0fb;
$fa-var-coin-blank: \e3fb;
$fa-var-broom: \f51a;
$fa-var-vacuum: \e04d;
$fa-var-shield-heart: \e574;
$fa-var-card-heart: \e3eb;
$fa-var-lightbulb-cfl-on: \e5a7;
$fa-var-melon: \e310;
$fa-var-gopuram: \f664;
$fa-var-earth-oceania: \e47b;
$fa-var-globe-oceania: \e47b;
$fa-var-container-storage: \f4b7;
$fa-var-face-pouting: \e387;
$fa-var-square-xmark: \f2d3;
$fa-var-times-square: \f2d3;
$fa-var-xmark-square: \f2d3;
$fa-var-face-explode: \e2fe;
$fa-var-exploding-head: \e2fe;
$fa-var-hashtag: \23;
$fa-var-up-right-and-down-left-from-center: \f424;
$fa-var-expand-alt: \f424;
$fa-var-oil-can: \f613;
$fa-var-t: \54;
$fa-var-transformer-bolt: \e2a4;
$fa-var-hippo: \f6ed;
$fa-var-chart-column: \e0e3;
$fa-var-cassette-vhs: \f8ec;
$fa-var-vhs: \f8ec;
$fa-var-infinity: \f534;
$fa-var-vial-circle-check: \e596;
$fa-var-chimney: \f78b;
$fa-var-object-intersect: \e49d;
$fa-var-person-arrow-down-to-line: \e538;
$fa-var-voicemail: \f897;
$fa-var-block-brick: \e3db;
$fa-var-wall-brick: \e3db;
$fa-var-fan: \f863;
$fa-var-bags-shopping: \f847;
$fa-var-paragraph-left: \f878;
$fa-var-paragraph-rtl: \f878;
$fa-var-person-walking-luggage: \e554;
$fa-var-caravan-simple: \e000;
$fa-var-caravan-alt: \e000;
$fa-var-turtle: \f726;
$fa-var-up-down: \f338;
$fa-var-arrows-alt-v: \f338;
$fa-var-cloud-moon-rain: \f73c;
$fa-var-booth-curtain: \f734;
$fa-var-calendar: \f133;
$fa-var-box-heart: \f49d;
$fa-var-trailer: \e041;
$fa-var-user-doctor-message: \f82e;
$fa-var-user-md-chat: \f82e;
$fa-var-bahai: \f666;
$fa-var-haykal: \f666;
$fa-var-amp-guitar: \f8a1;
$fa-var-sd-card: \f7c2;
$fa-var-volume-slash: \f2e2;
$fa-var-border-bottom: \f84d;
$fa-var-wifi-weak: \f6aa;
$fa-var-wifi-1: \f6aa;
$fa-var-dragon: \f6d5;
$fa-var-shoe-prints: \f54b;
$fa-var-circle-plus: \f055;
$fa-var-plus-circle: \f055;
$fa-var-face-grin-tongue-wink: \f58b;
$fa-var-grin-tongue-wink: \f58b;
$fa-var-hand-holding: \f4bd;
$fa-var-plug-circle-exclamation: \e55d;
$fa-var-link-slash: \f127;
$fa-var-chain-broken: \f127;
$fa-var-chain-slash: \f127;
$fa-var-unlink: \f127;
$fa-var-clone: \f24d;
$fa-var-person-walking-arrow-loop-left: \e551;
$fa-var-arrow-up-z-a: \f882;
$fa-var-sort-alpha-up-alt: \f882;
$fa-var-fire-flame-curved: \f7e4;
$fa-var-fire-alt: \f7e4;
$fa-var-tornado: \f76f;
$fa-var-file-circle-plus: \e494;
$fa-var-delete-right: \e154;
$fa-var-book-quran: \f687;
$fa-var-quran: \f687;
$fa-var-circle-quarter: \e11f;
$fa-var-anchor: \f13d;
$fa-var-border-all: \f84c;
$fa-var-function: \f661;
$fa-var-face-angry: \f556;
$fa-var-angry: \f556;
$fa-var-people-simple: \e21b;
$fa-var-cookie-bite: \f564;
$fa-var-arrow-trend-down: \e097;
$fa-var-rss: \f09e;
$fa-var-feed: \f09e;
$fa-var-face-monocle: \e380;
$fa-var-draw-polygon: \f5ee;
$fa-var-scale-balanced: \f24e;
$fa-var-balance-scale: \f24e;
$fa-var-calendar-lines: \e0d5;
$fa-var-calendar-note: \e0d5;
$fa-var-arrow-down-big-small: \f88c;
$fa-var-sort-size-down: \f88c;
$fa-var-gauge-simple-high: \f62a;
$fa-var-tachometer: \f62a;
$fa-var-tachometer-fast: \f62a;
$fa-var-do-not-enter: \f5ec;
$fa-var-shower: \f2cc;
$fa-var-dice-d8: \f6d2;
$fa-var-desktop: \f390;
$fa-var-desktop-alt: \f390;
$fa-var-m: \4d;
$fa-var-grip-dots-vertical: \e411;
$fa-var-face-viewfinder: \e2ff;
$fa-var-soft-serve: \e400;
$fa-var-creemee: \e400;
$fa-var-h5: \e412;
$fa-var-hand-back-point-down: \e19e;
$fa-var-table-list: \f00b;
$fa-var-th-list: \f00b;
$fa-var-comment-sms: \f7cd;
$fa-var-sms: \f7cd;
$fa-var-rectangle: \f2fa;
$fa-var-rectangle-landscape: \f2fa;
$fa-var-clipboard-list-check: \f737;
$fa-var-turkey: \f725;
$fa-var-book: \f02d;
$fa-var-user-plus: \f234;
$fa-var-ice-skate: \f7ac;
$fa-var-check: \f00c;
$fa-var-battery-three-quarters: \f241;
$fa-var-battery-4: \f241;
$fa-var-tomato: \e330;
$fa-var-sword-laser: \e03b;
$fa-var-house-circle-check: \e509;
$fa-var-buildings: \e0cc;
$fa-var-angle-left: \f104;
$fa-var-cart-flatbed-boxes: \f475;
$fa-var-dolly-flatbed-alt: \f475;
$fa-var-diagram-successor: \e47a;
$fa-var-truck-arrow-right: \e58b;
$fa-var-square-w: \e285;
$fa-var-arrows-split-up-and-left: \e4bc;
$fa-var-lamp: \f4ca;
$fa-var-airplay: \e089;
$fa-var-hand-fist: \f6de;
$fa-var-fist-raised: \f6de;
$fa-var-shield-quartered: \e575;
$fa-var-slash-forward: \2f;
$fa-var-location-pen: \f607;
$fa-var-map-marker-edit: \f607;
$fa-var-cloud-moon: \f6c3;
$fa-var-pot-food: \e43f;
$fa-var-briefcase: \f0b1;
$fa-var-person-falling: \e546;
$fa-var-image-portrait: \f3e0;
$fa-var-portrait: \f3e0;
$fa-var-user-tag: \f507;
$fa-var-rug: \e569;
$fa-var-print-slash: \f686;
$fa-var-earth-europe: \f7a2;
$fa-var-globe-europe: \f7a2;
$fa-var-cart-flatbed-suitcase: \f59d;
$fa-var-luggage-cart: \f59d;
$fa-var-hand-back-point-ribbon: \e1a0;
$fa-var-rectangle-xmark: \f410;
$fa-var-rectangle-times: \f410;
$fa-var-times-rectangle: \f410;
$fa-var-window-close: \f410;
$fa-var-tire-rugged: \f634;
$fa-var-lightbulb-dollar: \f670;
$fa-var-cowbell: \f8b3;
$fa-var-baht-sign: \e0ac;
$fa-var-corner: \e3fe;
$fa-var-chevrons-right: \f324;
$fa-var-chevron-double-right: \f324;
$fa-var-book-open: \f518;
$fa-var-book-journal-whills: \f66a;
$fa-var-journal-whills: \f66a;
$fa-var-inhaler: \f5f9;
$fa-var-handcuffs: \e4f8;
$fa-var-snake: \f716;
$fa-var-triangle-exclamation: \f071;
$fa-var-exclamation-triangle: \f071;
$fa-var-warning: \f071;
$fa-var-note-medical: \e200;
$fa-var-database: \f1c0;
$fa-var-down-left: \e16a;
$fa-var-share: \f064;
$fa-var-arrow-turn-right: \f064;
$fa-var-mail-forward: \f064;
$fa-var-face-thinking: \e39b;
$fa-var-turn-down-right: \e455;
$fa-var-bottle-droplet: \e4c4;
$fa-var-mask-face: \e1d7;
$fa-var-hill-rockslide: \e508;
$fa-var-scanner-keyboard: \f489;
$fa-var-circle-o: \e119;
$fa-var-grid-horizontal: \e307;
$fa-var-message-dollar: \f650;
$fa-var-comment-alt-dollar: \f650;
$fa-var-right-left: \f362;
$fa-var-exchange-alt: \f362;
$fa-var-columns-3: \e361;
$fa-var-paper-plane: \f1d8;
$fa-var-road-circle-exclamation: \e565;
$fa-var-dungeon: \f6d9;
$fa-var-hand-holding-box: \f47b;
$fa-var-input-text: \e1bf;
$fa-var-window-flip: \f40f;
$fa-var-window-alt: \f40f;
$fa-var-align-right: \f038;
$fa-var-scanner-gun: \f488;
$fa-var-scanner: \f488;
$fa-var-tire: \f631;
$fa-var-engine: \e16e;
$fa-var-money-bill-1-wave: \f53b;
$fa-var-money-bill-wave-alt: \f53b;
$fa-var-life-ring: \f1cd;
$fa-var-hands: \f2a7;
$fa-var-sign-language: \f2a7;
$fa-var-signing: \f2a7;
$fa-var-circle-caret-right: \f330;
$fa-var-caret-circle-right: \f330;
$fa-var-wheat: \f72d;
$fa-var-file-spreadsheet: \f65b;
$fa-var-audio-description-slash: \e0a8;
$fa-var-calendar-day: \f783;
$fa-var-water-ladder: \f5c5;
$fa-var-ladder-water: \f5c5;
$fa-var-swimming-pool: \f5c5;
$fa-var-arrows-up-down: \f07d;
$fa-var-arrows-v: \f07d;
$fa-var-chess-pawn-piece: \f444;
$fa-var-chess-pawn-alt: \f444;
$fa-var-face-grimace: \f57f;
$fa-var-grimace: \f57f;
$fa-var-wheelchair-move: \e2ce;
$fa-var-wheelchair-alt: \e2ce;
$fa-var-turn-down: \f3be;
$fa-var-level-down-alt: \f3be;
$fa-var-square-s: \e27d;
$fa-var-rectangle-barcode: \f463;
$fa-var-barcode-alt: \f463;
$fa-var-person-walking-arrow-right: \e552;
$fa-var-square-envelope: \f199;
$fa-var-envelope-square: \f199;
$fa-var-dice: \f522;
$fa-var-unicorn: \f727;
$fa-var-bowling-ball: \f436;
$fa-var-pompebled: \e43d;
$fa-var-brain: \f5dc;
$fa-var-watch-smart: \e2cc;
$fa-var-book-user: \f7e7;
$fa-var-sensor-cloud: \e02c;
$fa-var-sensor-smoke: \e02c;
$fa-var-clapperboard-play: \e132;
$fa-var-bandage: \f462;
$fa-var-band-aid: \f462;
$fa-var-calendar-minus: \f272;
$fa-var-circle-xmark: \f057;
$fa-var-times-circle: \f057;
$fa-var-xmark-circle: \f057;
$fa-var-circle-4: \e0f1;
$fa-var-gifts: \f79c;
$fa-var-album-collection: \f8a0;
$fa-var-hotel: \f594;
$fa-var-earth-asia: \f57e;
$fa-var-globe-asia: \f57e;
$fa-var-id-card-clip: \f47f;
$fa-var-id-card-alt: \f47f;
$fa-var-magnifying-glass-plus: \f00e;
$fa-var-search-plus: \f00e;
$fa-var-thumbs-up: \f164;
$fa-var-cloud-showers: \f73f;
$fa-var-user-clock: \f4fd;
$fa-var-onion: \e427;
$fa-var-clock-twelve-thirty: \e359;
$fa-var-arrow-down-to-dotted-line: \e095;
$fa-var-hand-dots: \f461;
$fa-var-allergies: \f461;
$fa-var-file-invoice: \f570;
$fa-var-window-minimize: \f2d1;
$fa-var-rectangle-wide: \f2fc;
$fa-var-comment-arrow-up: \e144;
$fa-var-garlic: \e40e;
$fa-var-mug-saucer: \f0f4;
$fa-var-coffee: \f0f4;
$fa-var-brush: \f55d;
$fa-var-tree-decorated: \f7dc;
$fa-var-mask: \f6fa;
$fa-var-calendar-heart: \e0d3;
$fa-var-magnifying-glass-minus: \f010;
$fa-var-search-minus: \f010;
$fa-var-flower: \f7ff;
$fa-var-ruler-vertical: \f548;
$fa-var-user-large: \f406;
$fa-var-user-alt: \f406;
$fa-var-starship-freighter: \e03a;
$fa-var-train-tram: \e5b4;
$fa-var-bridge-suspension: \e4cd;
$fa-var-trash-check: \e2af;
$fa-var-user-nurse: \f82f;
$fa-var-boombox: \f8a5;
$fa-var-syringe: \f48e;
$fa-var-cloud-sun: \f6c4;
$fa-var-shield-exclamation: \e247;
$fa-var-stopwatch-20: \e06f;
$fa-var-square-full: \f45c;
$fa-var-grip-dots: \e410;
$fa-var-comment-exclamation: \f4af;
$fa-var-pen-swirl: \e214;
$fa-var-falafel: \e40a;
$fa-var-circle-2: \e0ef;
$fa-var-magnet: \f076;
$fa-var-jar: \e516;
$fa-var-gramophone: \f8bd;
$fa-var-dice-d12: \f6ce;
$fa-var-note-sticky: \f249;
$fa-var-sticky-note: \f249;
$fa-var-down: \f354;
$fa-var-arrow-alt-down: \f354;
$fa-var-hundred-points: \e41c;
$fa-var-100: \e41c;
$fa-var-paperclip-vertical: \e3c2;
$fa-var-wind-warning: \f776;
$fa-var-wind-circle-exclamation: \f776;
$fa-var-location-pin-slash: \f60c;
$fa-var-map-marker-slash: \f60c;
$fa-var-face-sad-sweat: \e38a;
$fa-var-bug-slash: \e490;
$fa-var-cupcake: \e402;
$fa-var-light-switch-off: \e018;
$fa-var-toggle-large-off: \e5b0;
$fa-var-pen-fancy-slash: \e210;
$fa-var-truck-container: \f4dc;
$fa-var-boot: \f782;
$fa-var-arrow-up-from-water-pump: \e4b6;
$fa-var-file-check: \f316;
$fa-var-bone: \f5d7;
$fa-var-cards-blank: \e4df;
$fa-var-circle-3: \e0f0;
$fa-var-bench-tree: \e2e7;
$fa-var-keyboard-brightness-low: \e1c1;
$fa-var-ski-boot-ski: \e3cd;
$fa-var-brain-circuit: \e0c6;
$fa-var-user-injured: \f728;
$fa-var-block-brick-fire: \e3dc;
$fa-var-firewall: \e3dc;
$fa-var-face-sad-tear: \f5b4;
$fa-var-sad-tear: \f5b4;
$fa-var-plane: \f072;
$fa-var-tent-arrows-down: \e581;
$fa-var-exclamation: \21;
$fa-var-arrows-spin: \e4bb;
$fa-var-face-smile-relaxed: \e392;
$fa-var-comment-xmark: \f4b5;
$fa-var-comment-times: \f4b5;
$fa-var-print: \f02f;
$fa-var-turkish-lira-sign: \e2bb;
$fa-var-try: \e2bb;
$fa-var-turkish-lira: \e2bb;
$fa-var-face-nose-steam: \e382;
$fa-var-circle-waveform-lines: \e12d;
$fa-var-waveform-circle: \e12d;
$fa-var-dollar-sign: \24;
$fa-var-dollar: \24;
$fa-var-usd: \24;
$fa-var-ferris-wheel: \e174;
$fa-var-computer-speaker: \f8b2;
$fa-var-skull-cow: \f8de;
$fa-var-x: \58;
$fa-var-magnifying-glass-dollar: \f688;
$fa-var-search-dollar: \f688;
$fa-var-users-gear: \f509;
$fa-var-users-cog: \f509;
$fa-var-person-military-pointing: \e54a;
$fa-var-building-columns: \f19c;
$fa-var-bank: \f19c;
$fa-var-institution: \f19c;
$fa-var-museum: \f19c;
$fa-var-university: \f19c;
$fa-var-circle-t: \e124;
$fa-var-sack: \f81c;
$fa-var-grid-2: \e196;
$fa-var-camera-cctv: \f8ac;
$fa-var-cctv: \f8ac;
$fa-var-umbrella: \f0e9;
$fa-var-trowel: \e589;
$fa-var-horizontal-rule: \f86c;
$fa-var-bed-front: \f8f7;
$fa-var-bed-alt: \f8f7;
$fa-var-d: \44;
$fa-var-stapler: \e5af;
$fa-var-masks-theater: \f630;
$fa-var-theater-masks: \f630;
$fa-var-kip-sign: \e1c4;
$fa-var-face-woozy: \e3a2;
$fa-var-cloud-question: \e492;
$fa-var-pineapple: \e31f;
$fa-var-hand-point-left: \f0a5;
$fa-var-gallery-thumbnails: \e3aa;
$fa-var-circle-j: \e112;
$fa-var-eyes: \e367;
$fa-var-handshake-simple: \f4c6;
$fa-var-handshake-alt: \f4c6;
$fa-var-page-caret-up: \e42a;
$fa-var-file-caret-up: \e42a;
$fa-var-jet-fighter: \f0fb;
$fa-var-fighter-jet: \f0fb;
$fa-var-comet: \e003;
$fa-var-square-share-nodes: \f1e1;
$fa-var-share-alt-square: \f1e1;
$fa-var-shield-keyhole: \e248;
$fa-var-barcode: \f02a;
$fa-var-plus-minus: \e43c;
$fa-var-square-sliders-vertical: \f3f2;
$fa-var-sliders-v-square: \f3f2;
$fa-var-video: \f03d;
$fa-var-video-camera: \f03d;
$fa-var-message-middle: \e1e1;
$fa-var-comment-middle-alt: \e1e1;
$fa-var-graduation-cap: \f19d;
$fa-var-mortar-board: \f19d;
$fa-var-hand-holding-medical: \e05c;
$fa-var-person-circle-check: \e53e;
$fa-var-square-z: \e288;
$fa-var-message-text: \e1e6;
$fa-var-comment-alt-text: \e1e6;
$fa-var-turn-up: \f3bf;
$fa-var-level-up-alt: \f3bf;

$fa-var-monero: \f3d0;
$fa-var-hooli: \f427;
$fa-var-yelp: \f1e9;
$fa-var-cc-visa: \f1f0;
$fa-var-lastfm: \f202;
$fa-var-shopware: \f5b5;
$fa-var-creative-commons-nc: \f4e8;
$fa-var-aws: \f375;
$fa-var-redhat: \f7bc;
$fa-var-yoast: \f2b1;
$fa-var-cloudflare: \e07d;
$fa-var-ups: \f7e0;
$fa-var-wpexplorer: \f2de;
$fa-var-dyalog: \f399;
$fa-var-bity: \f37a;
$fa-var-stackpath: \f842;
$fa-var-buysellads: \f20d;
$fa-var-first-order: \f2b0;
$fa-var-modx: \f285;
$fa-var-guilded: \e07e;
$fa-var-vnv: \f40b;
$fa-var-square-js: \f3b9;
$fa-var-js-square: \f3b9;
$fa-var-microsoft: \f3ca;
$fa-var-qq: \f1d6;
$fa-var-orcid: \f8d2;
$fa-var-java: \f4e4;
$fa-var-invision: \f7b0;
$fa-var-creative-commons-pd-alt: \f4ed;
$fa-var-centercode: \f380;
$fa-var-glide-g: \f2a6;
$fa-var-drupal: \f1a9;
$fa-var-hire-a-helper: \f3b0;
$fa-var-creative-commons-by: \f4e7;
$fa-var-unity: \e049;
$fa-var-whmcs: \f40d;
$fa-var-rocketchat: \f3e8;
$fa-var-vk: \f189;
$fa-var-untappd: \f405;
$fa-var-mailchimp: \f59e;
$fa-var-css3-alt: \f38b;
$fa-var-square-reddit: \f1a2;
$fa-var-reddit-square: \f1a2;
$fa-var-vimeo-v: \f27d;
$fa-var-contao: \f26d;
$fa-var-square-font-awesome: \e5ad;
$fa-var-deskpro: \f38f;
$fa-var-sistrix: \f3ee;
$fa-var-square-instagram: \e055;
$fa-var-instagram-square: \e055;
$fa-var-battle-net: \f835;
$fa-var-the-red-yeti: \f69d;
$fa-var-square-hacker-news: \f3af;
$fa-var-hacker-news-square: \f3af;
$fa-var-edge: \f282;
$fa-var-napster: \f3d2;
$fa-var-square-snapchat: \f2ad;
$fa-var-snapchat-square: \f2ad;
$fa-var-google-plus-g: \f0d5;
$fa-var-artstation: \f77a;
$fa-var-markdown: \f60f;
$fa-var-sourcetree: \f7d3;
$fa-var-google-plus: \f2b3;
$fa-var-diaspora: \f791;
$fa-var-foursquare: \f180;
$fa-var-stack-overflow: \f16c;
$fa-var-github-alt: \f113;
$fa-var-phoenix-squadron: \f511;
$fa-var-pagelines: \f18c;
$fa-var-algolia: \f36c;
$fa-var-red-river: \f3e3;
$fa-var-creative-commons-sa: \f4ef;
$fa-var-safari: \f267;
$fa-var-google: \f1a0;
$fa-var-square-font-awesome-stroke: \f35c;
$fa-var-font-awesome-alt: \f35c;
$fa-var-atlassian: \f77b;
$fa-var-linkedin-in: \f0e1;
$fa-var-digital-ocean: \f391;
$fa-var-nimblr: \f5a8;
$fa-var-chromecast: \f838;
$fa-var-evernote: \f839;
$fa-var-hacker-news: \f1d4;
$fa-var-creative-commons-sampling: \f4f0;
$fa-var-adversal: \f36a;
$fa-var-creative-commons: \f25e;
$fa-var-watchman-monitoring: \e087;
$fa-var-fonticons: \f280;
$fa-var-weixin: \f1d7;
$fa-var-shirtsinbulk: \f214;
$fa-var-codepen: \f1cb;
$fa-var-git-alt: \f841;
$fa-var-lyft: \f3c3;
$fa-var-rev: \f5b2;
$fa-var-windows: \f17a;
$fa-var-wizards-of-the-coast: \f730;
$fa-var-square-viadeo: \f2aa;
$fa-var-viadeo-square: \f2aa;
$fa-var-meetup: \f2e0;
$fa-var-centos: \f789;
$fa-var-adn: \f170;
$fa-var-cloudsmith: \f384;
$fa-var-pied-piper-alt: \f1a8;
$fa-var-square-dribbble: \f397;
$fa-var-dribbble-square: \f397;
$fa-var-codiepie: \f284;
$fa-var-node: \f419;
$fa-var-mix: \f3cb;
$fa-var-steam: \f1b6;
$fa-var-cc-apple-pay: \f416;
$fa-var-scribd: \f28a;
$fa-var-openid: \f19b;
$fa-var-instalod: \e081;
$fa-var-expeditedssl: \f23e;
$fa-var-sellcast: \f2da;
$fa-var-square-twitter: \f081;
$fa-var-twitter-square: \f081;
$fa-var-r-project: \f4f7;
$fa-var-delicious: \f1a5;
$fa-var-freebsd: \f3a4;
$fa-var-vuejs: \f41f;
$fa-var-accusoft: \f369;
$fa-var-ioxhost: \f208;
$fa-var-fonticons-fi: \f3a2;
$fa-var-app-store: \f36f;
$fa-var-cc-mastercard: \f1f1;
$fa-var-itunes-note: \f3b5;
$fa-var-golang: \e40f;
$fa-var-kickstarter: \f3bb;
$fa-var-grav: \f2d6;
$fa-var-weibo: \f18a;
$fa-var-uncharted: \e084;
$fa-var-firstdraft: \f3a1;
$fa-var-square-youtube: \f431;
$fa-var-youtube-square: \f431;
$fa-var-wikipedia-w: \f266;
$fa-var-wpressr: \f3e4;
$fa-var-rendact: \f3e4;
$fa-var-angellist: \f209;
$fa-var-galactic-republic: \f50c;
$fa-var-nfc-directional: \e530;
$fa-var-skype: \f17e;
$fa-var-joget: \f3b7;
$fa-var-fedora: \f798;
$fa-var-stripe-s: \f42a;
$fa-var-meta: \e49b;
$fa-var-laravel: \f3bd;
$fa-var-hotjar: \f3b1;
$fa-var-bluetooth-b: \f294;
$fa-var-sticker-mule: \f3f7;
$fa-var-creative-commons-zero: \f4f3;
$fa-var-hips: \f452;
$fa-var-behance: \f1b4;
$fa-var-reddit: \f1a1;
$fa-var-discord: \f392;
$fa-var-chrome: \f268;
$fa-var-app-store-ios: \f370;
$fa-var-cc-discover: \f1f2;
$fa-var-wpbeginner: \f297;
$fa-var-confluence: \f78d;
$fa-var-mdb: \f8ca;
$fa-var-dochub: \f394;
$fa-var-accessible-icon: \f368;
$fa-var-ebay: \f4f4;
$fa-var-amazon: \f270;
$fa-var-unsplash: \e07c;
$fa-var-yarn: \f7e3;
$fa-var-square-steam: \f1b7;
$fa-var-steam-square: \f1b7;
$fa-var-500px: \f26e;
$fa-var-square-vimeo: \f194;
$fa-var-vimeo-square: \f194;
$fa-var-asymmetrik: \f372;
$fa-var-font-awesome: \f2b4;
$fa-var-font-awesome-flag: \f2b4;
$fa-var-font-awesome-logo-full: \f2b4;
$fa-var-gratipay: \f184;
$fa-var-apple: \f179;
$fa-var-hive: \e07f;
$fa-var-gitkraken: \f3a6;
$fa-var-keybase: \f4f5;
$fa-var-apple-pay: \f415;
$fa-var-padlet: \e4a0;
$fa-var-amazon-pay: \f42c;
$fa-var-square-github: \f092;
$fa-var-github-square: \f092;
$fa-var-stumbleupon: \f1a4;
$fa-var-fedex: \f797;
$fa-var-phoenix-framework: \f3dc;
$fa-var-shopify: \e057;
$fa-var-neos: \f612;
$fa-var-hackerrank: \f5f7;
$fa-var-researchgate: \f4f8;
$fa-var-swift: \f8e1;
$fa-var-angular: \f420;
$fa-var-speakap: \f3f3;
$fa-var-angrycreative: \f36e;
$fa-var-y-combinator: \f23b;
$fa-var-empire: \f1d1;
$fa-var-envira: \f299;
$fa-var-square-gitlab: \e5ae;
$fa-var-gitlab-square: \e5ae;
$fa-var-studiovinari: \f3f8;
$fa-var-pied-piper: \f2ae;
$fa-var-wordpress: \f19a;
$fa-var-product-hunt: \f288;
$fa-var-firefox: \f269;
$fa-var-linode: \f2b8;
$fa-var-goodreads: \f3a8;
$fa-var-square-odnoklassniki: \f264;
$fa-var-odnoklassniki-square: \f264;
$fa-var-jsfiddle: \f1cc;
$fa-var-sith: \f512;
$fa-var-themeisle: \f2b2;
$fa-var-page4: \f3d7;
$fa-var-hashnode: \e499;
$fa-var-react: \f41b;
$fa-var-cc-paypal: \f1f4;
$fa-var-squarespace: \f5be;
$fa-var-cc-stripe: \f1f5;
$fa-var-creative-commons-share: \f4f2;
$fa-var-bitcoin: \f379;
$fa-var-keycdn: \f3ba;
$fa-var-opera: \f26a;
$fa-var-itch-io: \f83a;
$fa-var-umbraco: \f8e8;
$fa-var-galactic-senate: \f50d;
$fa-var-ubuntu: \f7df;
$fa-var-draft2digital: \f396;
$fa-var-stripe: \f429;
$fa-var-houzz: \f27c;
$fa-var-gg: \f260;
$fa-var-dhl: \f790;
$fa-var-square-pinterest: \f0d3;
$fa-var-pinterest-square: \f0d3;
$fa-var-xing: \f168;
$fa-var-blackberry: \f37b;
$fa-var-creative-commons-pd: \f4ec;
$fa-var-playstation: \f3df;
$fa-var-quinscape: \f459;
$fa-var-less: \f41d;
$fa-var-blogger-b: \f37d;
$fa-var-opencart: \f23d;
$fa-var-vine: \f1ca;
$fa-var-paypal: \f1ed;
$fa-var-gitlab: \f296;
$fa-var-typo3: \f42b;
$fa-var-reddit-alien: \f281;
$fa-var-yahoo: \f19e;
$fa-var-dailymotion: \e052;
$fa-var-affiliatetheme: \f36b;
$fa-var-pied-piper-pp: \f1a7;
$fa-var-bootstrap: \f836;
$fa-var-odnoklassniki: \f263;
$fa-var-nfc-symbol: \e531;
$fa-var-ethereum: \f42e;
$fa-var-speaker-deck: \f83c;
$fa-var-creative-commons-nc-eu: \f4e9;
$fa-var-patreon: \f3d9;
$fa-var-avianex: \f374;
$fa-var-ello: \f5f1;
$fa-var-gofore: \f3a7;
$fa-var-bimobject: \f378;
$fa-var-facebook-f: \f39e;
$fa-var-square-google-plus: \f0d4;
$fa-var-google-plus-square: \f0d4;
$fa-var-mandalorian: \f50f;
$fa-var-first-order-alt: \f50a;
$fa-var-osi: \f41a;
$fa-var-google-wallet: \f1ee;
$fa-var-d-and-d-beyond: \f6ca;
$fa-var-periscope: \f3da;
$fa-var-fulcrum: \f50b;
$fa-var-cloudscale: \f383;
$fa-var-forumbee: \f211;
$fa-var-mizuni: \f3cc;
$fa-var-schlix: \f3ea;
$fa-var-square-xing: \f169;
$fa-var-xing-square: \f169;
$fa-var-bandcamp: \f2d5;
$fa-var-wpforms: \f298;
$fa-var-cloudversify: \f385;
$fa-var-usps: \f7e1;
$fa-var-megaport: \f5a3;
$fa-var-magento: \f3c4;
$fa-var-spotify: \f1bc;
$fa-var-optin-monster: \f23c;
$fa-var-fly: \f417;
$fa-var-aviato: \f421;
$fa-var-itunes: \f3b4;
$fa-var-cuttlefish: \f38c;
$fa-var-blogger: \f37c;
$fa-var-flickr: \f16e;
$fa-var-viber: \f409;
$fa-var-soundcloud: \f1be;
$fa-var-digg: \f1a6;
$fa-var-tencent-weibo: \f1d5;
$fa-var-symfony: \f83d;
$fa-var-maxcdn: \f136;
$fa-var-etsy: \f2d7;
$fa-var-facebook-messenger: \f39f;
$fa-var-audible: \f373;
$fa-var-think-peaks: \f731;
$fa-var-bilibili: \e3d9;
$fa-var-erlang: \f39d;
$fa-var-cotton-bureau: \f89e;
$fa-var-dashcube: \f210;
$fa-var-42-group: \e080;
$fa-var-innosoft: \e080;
$fa-var-stack-exchange: \f18d;
$fa-var-elementor: \f430;
$fa-var-square-pied-piper: \e01e;
$fa-var-pied-piper-square: \e01e;
$fa-var-creative-commons-nd: \f4eb;
$fa-var-palfed: \f3d8;
$fa-var-superpowers: \f2dd;
$fa-var-resolving: \f3e7;
$fa-var-xbox: \f412;
$fa-var-searchengin: \f3eb;
$fa-var-tiktok: \e07b;
$fa-var-square-facebook: \f082;
$fa-var-facebook-square: \f082;
$fa-var-renren: \f18b;
$fa-var-linux: \f17c;
$fa-var-glide: \f2a5;
$fa-var-linkedin: \f08c;
$fa-var-hubspot: \f3b2;
$fa-var-deploydog: \f38e;
$fa-var-twitch: \f1e8;
$fa-var-ravelry: \f2d9;
$fa-var-mixer: \e056;
$fa-var-square-lastfm: \f203;
$fa-var-lastfm-square: \f203;
$fa-var-vimeo: \f40a;
$fa-var-mendeley: \f7b3;
$fa-var-uniregistry: \f404;
$fa-var-figma: \f799;
$fa-var-creative-commons-remix: \f4ee;
$fa-var-cc-amazon-pay: \f42d;
$fa-var-dropbox: \f16b;
$fa-var-instagram: \f16d;
$fa-var-cmplid: \e360;
$fa-var-facebook: \f09a;
$fa-var-gripfire: \f3ac;
$fa-var-jedi-order: \f50e;
$fa-var-uikit: \f403;
$fa-var-fort-awesome-alt: \f3a3;
$fa-var-phabricator: \f3db;
$fa-var-ussunnah: \f407;
$fa-var-earlybirds: \f39a;
$fa-var-trade-federation: \f513;
$fa-var-autoprefixer: \f41c;
$fa-var-whatsapp: \f232;
$fa-var-slideshare: \f1e7;
$fa-var-google-play: \f3ab;
$fa-var-viadeo: \f2a9;
$fa-var-line: \f3c0;
$fa-var-google-drive: \f3aa;
$fa-var-servicestack: \f3ec;
$fa-var-simplybuilt: \f215;
$fa-var-bitbucket: \f171;
$fa-var-imdb: \f2d8;
$fa-var-deezer: \e077;
$fa-var-raspberry-pi: \f7bb;
$fa-var-jira: \f7b1;
$fa-var-docker: \f395;
$fa-var-screenpal: \e570;
$fa-var-bluetooth: \f293;
$fa-var-gitter: \f426;
$fa-var-d-and-d: \f38d;
$fa-var-microblog: \e01a;
$fa-var-cc-diners-club: \f24c;
$fa-var-gg-circle: \f261;
$fa-var-pied-piper-hat: \f4e5;
$fa-var-kickstarter-k: \f3bc;
$fa-var-yandex: \f413;
$fa-var-readme: \f4d5;
$fa-var-html5: \f13b;
$fa-var-sellsy: \f213;
$fa-var-sass: \f41e;
$fa-var-wirsindhandwerk: \e2d0;
$fa-var-wsh: \e2d0;
$fa-var-buromobelexperte: \f37f;
$fa-var-salesforce: \f83b;
$fa-var-octopus-deploy: \e082;
$fa-var-medapps: \f3c6;
$fa-var-ns8: \f3d5;
$fa-var-pinterest-p: \f231;
$fa-var-apper: \f371;
$fa-var-fort-awesome: \f286;
$fa-var-waze: \f83f;
$fa-var-cc-jcb: \f24b;
$fa-var-snapchat: \f2ab;
$fa-var-snapchat-ghost: \f2ab;
$fa-var-fantasy-flight-games: \f6dc;
$fa-var-rust: \e07a;
$fa-var-wix: \f5cf;
$fa-var-square-behance: \f1b5;
$fa-var-behance-square: \f1b5;
$fa-var-supple: \f3f9;
$fa-var-rebel: \f1d0;
$fa-var-css3: \f13c;
$fa-var-staylinked: \f3f5;
$fa-var-kaggle: \f5fa;
$fa-var-space-awesome: \e5ac;
$fa-var-deviantart: \f1bd;
$fa-var-cpanel: \f388;
$fa-var-goodreads-g: \f3a9;
$fa-var-square-git: \f1d2;
$fa-var-git-square: \f1d2;
$fa-var-square-tumblr: \f174;
$fa-var-tumblr-square: \f174;
$fa-var-trello: \f181;
$fa-var-creative-commons-nc-jp: \f4ea;
$fa-var-get-pocket: \f265;
$fa-var-perbyte: \e083;
$fa-var-grunt: \f3ad;
$fa-var-weebly: \f5cc;
$fa-var-connectdevelop: \f20e;
$fa-var-leanpub: \f212;
$fa-var-black-tie: \f27e;
$fa-var-themeco: \f5c6;
$fa-var-python: \f3e2;
$fa-var-android: \f17b;
$fa-var-bots: \e340;
$fa-var-free-code-camp: \f2c5;
$fa-var-hornbill: \f592;
$fa-var-js: \f3b8;
$fa-var-ideal: \e013;
$fa-var-git: \f1d3;
$fa-var-dev: \f6cc;
$fa-var-sketch: \f7c6;
$fa-var-yandex-international: \f414;
$fa-var-cc-amex: \f1f3;
$fa-var-uber: \f402;
$fa-var-github: \f09b;
$fa-var-php: \f457;
$fa-var-alipay: \f642;
$fa-var-youtube: \f167;
$fa-var-skyatlas: \f216;
$fa-var-firefox-browser: \e007;
$fa-var-replyd: \f3e6;
$fa-var-suse: \f7d6;
$fa-var-jenkins: \f3b6;
$fa-var-twitter: \f099;
$fa-var-rockrms: \f3e9;
$fa-var-pinterest: \f0d2;
$fa-var-buffer: \f837;
$fa-var-npm: \f3d4;
$fa-var-yammer: \f840;
$fa-var-btc: \f15a;
$fa-var-dribbble: \f17d;
$fa-var-stumbleupon-circle: \f1a3;
$fa-var-internet-explorer: \f26b;
$fa-var-stubber: \e5c7;
$fa-var-telegram: \f2c6;
$fa-var-telegram-plane: \f2c6;
$fa-var-old-republic: \f510;
$fa-var-odysee: \e5c6;
$fa-var-square-whatsapp: \f40c;
$fa-var-whatsapp-square: \f40c;
$fa-var-node-js: \f3d3;
$fa-var-edge-legacy: \e078;
$fa-var-slack: \f198;
$fa-var-slack-hash: \f198;
$fa-var-medrt: \f3c8;
$fa-var-usb: \f287;
$fa-var-tumblr: \f173;
$fa-var-vaadin: \f408;
$fa-var-quora: \f2c4;
$fa-var-reacteurope: \f75d;
$fa-var-medium: \f23a;
$fa-var-medium-m: \f23a;
$fa-var-amilia: \f36d;
$fa-var-mixcloud: \f289;
$fa-var-flipboard: \f44d;
$fa-var-viacoin: \f237;
$fa-var-critical-role: \f6c9;
$fa-var-sitrox: \e44a;
$fa-var-discourse: \f393;
$fa-var-joomla: \f1aa;
$fa-var-mastodon: \f4f6;
$fa-var-airbnb: \f834;
$fa-var-wolf-pack-battalion: \f514;
$fa-var-buy-n-large: \f8a6;
$fa-var-gulp: \f3ae;
$fa-var-creative-commons-sampling-plus: \f4f1;
$fa-var-strava: \f428;
$fa-var-ember: \f423;
$fa-var-canadian-maple-leaf: \f785;
$fa-var-teamspeak: \f4f9;
$fa-var-pushed: \f3e1;
$fa-var-wordpress-simple: \f411;
$fa-var-nutritionix: \f3d6;
$fa-var-wodu: \e088;
$fa-var-google-pay: \e079;
$fa-var-intercom: \f7af;
$fa-var-zhihu: \f63f;
$fa-var-korvue: \f42f;
$fa-var-pix: \e43a;
$fa-var-steam-symbol: \f3f6;

$fa-icons: (
     "0": $fa-var-0,
     "1": $fa-var-1,
     "2": $fa-var-2,
     "3": $fa-var-3,
     "4": $fa-var-4,
     "5": $fa-var-5,
     "6": $fa-var-6,
     "7": $fa-var-7,
     "8": $fa-var-8,
     "9": $fa-var-9,
     "fill-drip": $fa-var-fill-drip,
     "arrows-to-circle": $fa-var-arrows-to-circle,
     "circle-chevron-right": $fa-var-circle-chevron-right,
     "chevron-circle-right": $fa-var-chevron-circle-right,
     "wagon-covered": $fa-var-wagon-covered,
     "line-height": $fa-var-line-height,
     "bagel": $fa-var-bagel,
     "transporter-7": $fa-var-transporter-7,
     "at": $fa-var-at,
     "rectangles-mixed": $fa-var-rectangles-mixed,
     "phone-arrow-up-right": $fa-var-phone-arrow-up-right,
     "phone-arrow-up": $fa-var-phone-arrow-up,
     "phone-outgoing": $fa-var-phone-outgoing,
     "trash-can": $fa-var-trash-can,
     "trash-alt": $fa-var-trash-alt,
     "circle-l": $fa-var-circle-l,
     "head-side-goggles": $fa-var-head-side-goggles,
     "head-vr": $fa-var-head-vr,
     "text-height": $fa-var-text-height,
     "user-xmark": $fa-var-user-xmark,
     "user-times": $fa-var-user-times,
     "face-hand-yawn": $fa-var-face-hand-yawn,
     "gauge-simple-min": $fa-var-gauge-simple-min,
     "tachometer-slowest": $fa-var-tachometer-slowest,
     "stethoscope": $fa-var-stethoscope,
     "coffin": $fa-var-coffin,
     "message": $fa-var-message,
     "comment-alt": $fa-var-comment-alt,
     "salad": $fa-var-salad,
     "bowl-salad": $fa-var-bowl-salad,
     "info": $fa-var-info,
     "robot-astromech": $fa-var-robot-astromech,
     "ring-diamond": $fa-var-ring-diamond,
     "fondue-pot": $fa-var-fondue-pot,
     "theta": $fa-var-theta,
     "face-hand-peeking": $fa-var-face-hand-peeking,
     "square-user": $fa-var-square-user,
     "down-left-and-up-right-to-center": $fa-var-down-left-and-up-right-to-center,
     "compress-alt": $fa-var-compress-alt,
     "explosion": $fa-var-explosion,
     "file-lines": $fa-var-file-lines,
     "file-alt": $fa-var-file-alt,
     "file-text": $fa-var-file-text,
     "wave-square": $fa-var-wave-square,
     "ring": $fa-var-ring,
     "building-un": $fa-var-building-un,
     "dice-three": $fa-var-dice-three,
     "tire-pressure-warning": $fa-var-tire-pressure-warning,
     "wifi-fair": $fa-var-wifi-fair,
     "wifi-2": $fa-var-wifi-2,
     "calendar-days": $fa-var-calendar-days,
     "calendar-alt": $fa-var-calendar-alt,
     "mp3-player": $fa-var-mp3-player,
     "anchor-circle-check": $fa-var-anchor-circle-check,
     "tally-4": $fa-var-tally-4,
     "rectangle-history": $fa-var-rectangle-history,
     "building-circle-arrow-right": $fa-var-building-circle-arrow-right,
     "volleyball": $fa-var-volleyball,
     "volleyball-ball": $fa-var-volleyball-ball,
     "sun-haze": $fa-var-sun-haze,
     "text-size": $fa-var-text-size,
     "ufo": $fa-var-ufo,
     "fork": $fa-var-fork,
     "utensil-fork": $fa-var-utensil-fork,
     "arrows-up-to-line": $fa-var-arrows-up-to-line,
     "mobile-signal": $fa-var-mobile-signal,
     "barcode-scan": $fa-var-barcode-scan,
     "sort-down": $fa-var-sort-down,
     "sort-desc": $fa-var-sort-desc,
     "folder-arrow-down": $fa-var-folder-arrow-down,
     "folder-download": $fa-var-folder-download,
     "circle-minus": $fa-var-circle-minus,
     "minus-circle": $fa-var-minus-circle,
     "face-icicles": $fa-var-face-icicles,
     "shovel": $fa-var-shovel,
     "door-open": $fa-var-door-open,
     "films": $fa-var-films,
     "right-from-bracket": $fa-var-right-from-bracket,
     "sign-out-alt": $fa-var-sign-out-alt,
     "face-glasses": $fa-var-face-glasses,
     "nfc": $fa-var-nfc,
     "atom": $fa-var-atom,
     "soap": $fa-var-soap,
     "icons": $fa-var-icons,
     "heart-music-camera-bolt": $fa-var-heart-music-camera-bolt,
     "microphone-lines-slash": $fa-var-microphone-lines-slash,
     "microphone-alt-slash": $fa-var-microphone-alt-slash,
     "closed-captioning-slash": $fa-var-closed-captioning-slash,
     "calculator-simple": $fa-var-calculator-simple,
     "calculator-alt": $fa-var-calculator-alt,
     "bridge-circle-check": $fa-var-bridge-circle-check,
     "sliders-up": $fa-var-sliders-up,
     "sliders-v": $fa-var-sliders-v,
     "location-minus": $fa-var-location-minus,
     "map-marker-minus": $fa-var-map-marker-minus,
     "pump-medical": $fa-var-pump-medical,
     "fingerprint": $fa-var-fingerprint,
     "ski-boot": $fa-var-ski-boot,
     "standard-definition": $fa-var-standard-definition,
     "rectangle-sd": $fa-var-rectangle-sd,
     "h1": $fa-var-h1,
     "hand-point-right": $fa-var-hand-point-right,
     "magnifying-glass-location": $fa-var-magnifying-glass-location,
     "search-location": $fa-var-search-location,
     "message-bot": $fa-var-message-bot,
     "forward-step": $fa-var-forward-step,
     "step-forward": $fa-var-step-forward,
     "face-smile-beam": $fa-var-face-smile-beam,
     "smile-beam": $fa-var-smile-beam,
     "light-ceiling": $fa-var-light-ceiling,
     "message-exclamation": $fa-var-message-exclamation,
     "comment-alt-exclamation": $fa-var-comment-alt-exclamation,
     "bowl-scoop": $fa-var-bowl-scoop,
     "bowl-shaved-ice": $fa-var-bowl-shaved-ice,
     "square-x": $fa-var-square-x,
     "utility-pole-double": $fa-var-utility-pole-double,
     "flag-checkered": $fa-var-flag-checkered,
     "chevrons-up": $fa-var-chevrons-up,
     "chevron-double-up": $fa-var-chevron-double-up,
     "football": $fa-var-football,
     "football-ball": $fa-var-football-ball,
     "user-vneck": $fa-var-user-vneck,
     "school-circle-exclamation": $fa-var-school-circle-exclamation,
     "crop": $fa-var-crop,
     "angles-down": $fa-var-angles-down,
     "angle-double-down": $fa-var-angle-double-down,
     "users-rectangle": $fa-var-users-rectangle,
     "people-roof": $fa-var-people-roof,
     "square-arrow-right": $fa-var-square-arrow-right,
     "arrow-square-right": $fa-var-arrow-square-right,
     "location-plus": $fa-var-location-plus,
     "map-marker-plus": $fa-var-map-marker-plus,
     "lightbulb-exclamation-on": $fa-var-lightbulb-exclamation-on,
     "people-line": $fa-var-people-line,
     "beer-mug-empty": $fa-var-beer-mug-empty,
     "beer": $fa-var-beer,
     "crate-empty": $fa-var-crate-empty,
     "diagram-predecessor": $fa-var-diagram-predecessor,
     "transporter": $fa-var-transporter,
     "calendar-circle-user": $fa-var-calendar-circle-user,
     "arrow-up-long": $fa-var-arrow-up-long,
     "long-arrow-up": $fa-var-long-arrow-up,
     "person-carry-box": $fa-var-person-carry-box,
     "person-carry": $fa-var-person-carry,
     "fire-flame-simple": $fa-var-fire-flame-simple,
     "burn": $fa-var-burn,
     "person": $fa-var-person,
     "male": $fa-var-male,
     "laptop": $fa-var-laptop,
     "file-csv": $fa-var-file-csv,
     "menorah": $fa-var-menorah,
     "union": $fa-var-union,
     "chevrons-left": $fa-var-chevrons-left,
     "chevron-double-left": $fa-var-chevron-double-left,
     "circle-heart": $fa-var-circle-heart,
     "heart-circle": $fa-var-heart-circle,
     "truck-plane": $fa-var-truck-plane,
     "record-vinyl": $fa-var-record-vinyl,
     "bring-forward": $fa-var-bring-forward,
     "square-p": $fa-var-square-p,
     "face-grin-stars": $fa-var-face-grin-stars,
     "grin-stars": $fa-var-grin-stars,
     "sigma": $fa-var-sigma,
     "camera-movie": $fa-var-camera-movie,
     "bong": $fa-var-bong,
     "clarinet": $fa-var-clarinet,
     "truck-flatbed": $fa-var-truck-flatbed,
     "spaghetti-monster-flying": $fa-var-spaghetti-monster-flying,
     "pastafarianism": $fa-var-pastafarianism,
     "arrow-down-up-across-line": $fa-var-arrow-down-up-across-line,
     "leaf-heart": $fa-var-leaf-heart,
     "house-building": $fa-var-house-building,
     "cheese-swiss": $fa-var-cheese-swiss,
     "spoon": $fa-var-spoon,
     "utensil-spoon": $fa-var-utensil-spoon,
     "jar-wheat": $fa-var-jar-wheat,
     "envelopes-bulk": $fa-var-envelopes-bulk,
     "mail-bulk": $fa-var-mail-bulk,
     "file-circle-exclamation": $fa-var-file-circle-exclamation,
     "bow-arrow": $fa-var-bow-arrow,
     "cart-xmark": $fa-var-cart-xmark,
     "hexagon-xmark": $fa-var-hexagon-xmark,
     "times-hexagon": $fa-var-times-hexagon,
     "xmark-hexagon": $fa-var-xmark-hexagon,
     "circle-h": $fa-var-circle-h,
     "hospital-symbol": $fa-var-hospital-symbol,
     "merge": $fa-var-merge,
     "pager": $fa-var-pager,
     "cart-minus": $fa-var-cart-minus,
     "address-book": $fa-var-address-book,
     "contact-book": $fa-var-contact-book,
     "pan-frying": $fa-var-pan-frying,
     "grid": $fa-var-grid,
     "grid-3": $fa-var-grid-3,
     "football-helmet": $fa-var-football-helmet,
     "hand-love": $fa-var-hand-love,
     "trees": $fa-var-trees,
     "strikethrough": $fa-var-strikethrough,
     "page": $fa-var-page,
     "k": $fa-var-k,
     "diagram-previous": $fa-var-diagram-previous,
     "gauge-min": $fa-var-gauge-min,
     "tachometer-alt-slowest": $fa-var-tachometer-alt-slowest,
     "folder-grid": $fa-var-folder-grid,
     "eggplant": $fa-var-eggplant,
     "ram": $fa-var-ram,
     "landmark-flag": $fa-var-landmark-flag,
     "lips": $fa-var-lips,
     "pencil": $fa-var-pencil,
     "pencil-alt": $fa-var-pencil-alt,
     "backward": $fa-var-backward,
     "caret-right": $fa-var-caret-right,
     "comments": $fa-var-comments,
     "paste": $fa-var-paste,
     "file-clipboard": $fa-var-file-clipboard,
     "desktop-arrow-down": $fa-var-desktop-arrow-down,
     "code-pull-request": $fa-var-code-pull-request,
     "pumpkin": $fa-var-pumpkin,
     "clipboard-list": $fa-var-clipboard-list,
     "pen-field": $fa-var-pen-field,
     "blueberries": $fa-var-blueberries,
     "truck-ramp-box": $fa-var-truck-ramp-box,
     "truck-loading": $fa-var-truck-loading,
     "note": $fa-var-note,
     "arrow-down-to-square": $fa-var-arrow-down-to-square,
     "user-check": $fa-var-user-check,
     "cloud-xmark": $fa-var-cloud-xmark,
     "vial-virus": $fa-var-vial-virus,
     "book-blank": $fa-var-book-blank,
     "book-alt": $fa-var-book-alt,
     "golf-flag-hole": $fa-var-golf-flag-hole,
     "message-arrow-down": $fa-var-message-arrow-down,
     "comment-alt-arrow-down": $fa-var-comment-alt-arrow-down,
     "face-unamused": $fa-var-face-unamused,
     "sheet-plastic": $fa-var-sheet-plastic,
     "circle-9": $fa-var-circle-9,
     "blog": $fa-var-blog,
     "user-ninja": $fa-var-user-ninja,
     "pencil-slash": $fa-var-pencil-slash,
     "bowling-pins": $fa-var-bowling-pins,
     "person-arrow-up-from-line": $fa-var-person-arrow-up-from-line,
     "down-right": $fa-var-down-right,
     "scroll-torah": $fa-var-scroll-torah,
     "torah": $fa-var-torah,
     "blinds-open": $fa-var-blinds-open,
     "fence": $fa-var-fence,
     "up": $fa-var-up,
     "arrow-alt-up": $fa-var-arrow-alt-up,
     "broom-ball": $fa-var-broom-ball,
     "quidditch": $fa-var-quidditch,
     "quidditch-broom-ball": $fa-var-quidditch-broom-ball,
     "drumstick": $fa-var-drumstick,
     "square-v": $fa-var-square-v,
     "face-awesome": $fa-var-face-awesome,
     "gave-dandy": $fa-var-gave-dandy,
     "dial-off": $fa-var-dial-off,
     "toggle-off": $fa-var-toggle-off,
     "face-smile-horns": $fa-var-face-smile-horns,
     "box-archive": $fa-var-box-archive,
     "archive": $fa-var-archive,
     "grapes": $fa-var-grapes,
     "person-drowning": $fa-var-person-drowning,
     "dial-max": $fa-var-dial-max,
     "circle-m": $fa-var-circle-m,
     "calendar-image": $fa-var-calendar-image,
     "circle-caret-down": $fa-var-circle-caret-down,
     "caret-circle-down": $fa-var-caret-circle-down,
     "arrow-down-9-1": $fa-var-arrow-down-9-1,
     "sort-numeric-desc": $fa-var-sort-numeric-desc,
     "sort-numeric-down-alt": $fa-var-sort-numeric-down-alt,
     "face-grin-tongue-squint": $fa-var-face-grin-tongue-squint,
     "grin-tongue-squint": $fa-var-grin-tongue-squint,
     "shish-kebab": $fa-var-shish-kebab,
     "spray-can": $fa-var-spray-can,
     "alarm-snooze": $fa-var-alarm-snooze,
     "scarecrow": $fa-var-scarecrow,
     "truck-monster": $fa-var-truck-monster,
     "gift-card": $fa-var-gift-card,
     "w": $fa-var-w,
     "code-pull-request-draft": $fa-var-code-pull-request-draft,
     "square-b": $fa-var-square-b,
     "elephant": $fa-var-elephant,
     "earth-africa": $fa-var-earth-africa,
     "globe-africa": $fa-var-globe-africa,
     "rainbow": $fa-var-rainbow,
     "circle-notch": $fa-var-circle-notch,
     "tablet-screen-button": $fa-var-tablet-screen-button,
     "tablet-alt": $fa-var-tablet-alt,
     "paw": $fa-var-paw,
     "message-question": $fa-var-message-question,
     "cloud": $fa-var-cloud,
     "trowel-bricks": $fa-var-trowel-bricks,
     "square-3": $fa-var-square-3,
     "face-flushed": $fa-var-face-flushed,
     "flushed": $fa-var-flushed,
     "hospital-user": $fa-var-hospital-user,
     "microwave": $fa-var-microwave,
     "tent-arrow-left-right": $fa-var-tent-arrow-left-right,
     "cart-circle-arrow-up": $fa-var-cart-circle-arrow-up,
     "trash-clock": $fa-var-trash-clock,
     "gavel": $fa-var-gavel,
     "legal": $fa-var-legal,
     "sprinkler-ceiling": $fa-var-sprinkler-ceiling,
     "browsers": $fa-var-browsers,
     "trillium": $fa-var-trillium,
     "music-slash": $fa-var-music-slash,
     "truck-ramp": $fa-var-truck-ramp,
     "binoculars": $fa-var-binoculars,
     "microphone-slash": $fa-var-microphone-slash,
     "box-tissue": $fa-var-box-tissue,
     "circle-c": $fa-var-circle-c,
     "star-christmas": $fa-var-star-christmas,
     "chart-bullet": $fa-var-chart-bullet,
     "motorcycle": $fa-var-motorcycle,
     "tree-christmas": $fa-var-tree-christmas,
     "tire-flat": $fa-var-tire-flat,
     "sunglasses": $fa-var-sunglasses,
     "badge": $fa-var-badge,
     "message-pen": $fa-var-message-pen,
     "comment-alt-edit": $fa-var-comment-alt-edit,
     "message-edit": $fa-var-message-edit,
     "bell-concierge": $fa-var-bell-concierge,
     "concierge-bell": $fa-var-concierge-bell,
     "pen-ruler": $fa-var-pen-ruler,
     "pencil-ruler": $fa-var-pencil-ruler,
     "chess-rook-piece": $fa-var-chess-rook-piece,
     "chess-rook-alt": $fa-var-chess-rook-alt,
     "square-root": $fa-var-square-root,
     "album-collection-circle-plus": $fa-var-album-collection-circle-plus,
     "people-arrows": $fa-var-people-arrows,
     "people-arrows-left-right": $fa-var-people-arrows-left-right,
     "face-angry-horns": $fa-var-face-angry-horns,
     "mars-and-venus-burst": $fa-var-mars-and-venus-burst,
     "tombstone": $fa-var-tombstone,
     "square-caret-right": $fa-var-square-caret-right,
     "caret-square-right": $fa-var-caret-square-right,
     "scissors": $fa-var-scissors,
     "cut": $fa-var-cut,
     "list-music": $fa-var-list-music,
     "sun-plant-wilt": $fa-var-sun-plant-wilt,
     "toilets-portable": $fa-var-toilets-portable,
     "hockey-puck": $fa-var-hockey-puck,
     "mustache": $fa-var-mustache,
     "hyphen": $fa-var-hyphen,
     "table": $fa-var-table,
     "user-chef": $fa-var-user-chef,
     "message-image": $fa-var-message-image,
     "comment-alt-image": $fa-var-comment-alt-image,
     "users-medical": $fa-var-users-medical,
     "sensor-triangle-exclamation": $fa-var-sensor-triangle-exclamation,
     "sensor-alert": $fa-var-sensor-alert,
     "magnifying-glass-arrow-right": $fa-var-magnifying-glass-arrow-right,
     "tachograph-digital": $fa-var-tachograph-digital,
     "digital-tachograph": $fa-var-digital-tachograph,
     "face-mask": $fa-var-face-mask,
     "pickleball": $fa-var-pickleball,
     "star-sharp-half": $fa-var-star-sharp-half,
     "users-slash": $fa-var-users-slash,
     "clover": $fa-var-clover,
     "meat": $fa-var-meat,
     "reply": $fa-var-reply,
     "mail-reply": $fa-var-mail-reply,
     "star-and-crescent": $fa-var-star-and-crescent,
     "empty-set": $fa-var-empty-set,
     "house-fire": $fa-var-house-fire,
     "square-minus": $fa-var-square-minus,
     "minus-square": $fa-var-minus-square,
     "helicopter": $fa-var-helicopter,
     "bird": $fa-var-bird,
     "compass": $fa-var-compass,
     "square-caret-down": $fa-var-square-caret-down,
     "caret-square-down": $fa-var-caret-square-down,
     "heart-half-stroke": $fa-var-heart-half-stroke,
     "heart-half-alt": $fa-var-heart-half-alt,
     "file-circle-question": $fa-var-file-circle-question,
     "laptop-code": $fa-var-laptop-code,
     "joystick": $fa-var-joystick,
     "grill-fire": $fa-var-grill-fire,
     "rectangle-vertical-history": $fa-var-rectangle-vertical-history,
     "swatchbook": $fa-var-swatchbook,
     "prescription-bottle": $fa-var-prescription-bottle,
     "bars": $fa-var-bars,
     "navicon": $fa-var-navicon,
     "keyboard-left": $fa-var-keyboard-left,
     "people-group": $fa-var-people-group,
     "hourglass-end": $fa-var-hourglass-end,
     "hourglass-3": $fa-var-hourglass-3,
     "heart-crack": $fa-var-heart-crack,
     "heart-broken": $fa-var-heart-broken,
     "face-beam-hand-over-mouth": $fa-var-face-beam-hand-over-mouth,
     "droplet-percent": $fa-var-droplet-percent,
     "humidity": $fa-var-humidity,
     "square-up-right": $fa-var-square-up-right,
     "external-link-square-alt": $fa-var-external-link-square-alt,
     "face-kiss-beam": $fa-var-face-kiss-beam,
     "kiss-beam": $fa-var-kiss-beam,
     "corn": $fa-var-corn,
     "roller-coaster": $fa-var-roller-coaster,
     "photo-film-music": $fa-var-photo-film-music,
     "radar": $fa-var-radar,
     "sickle": $fa-var-sickle,
     "film": $fa-var-film,
     "coconut": $fa-var-coconut,
     "ruler-horizontal": $fa-var-ruler-horizontal,
     "shield-cross": $fa-var-shield-cross,
     "cassette-tape": $fa-var-cassette-tape,
     "square-terminal": $fa-var-square-terminal,
     "people-robbery": $fa-var-people-robbery,
     "lightbulb": $fa-var-lightbulb,
     "caret-left": $fa-var-caret-left,
     "comment-middle": $fa-var-comment-middle,
     "trash-can-list": $fa-var-trash-can-list,
     "block": $fa-var-block,
     "circle-exclamation": $fa-var-circle-exclamation,
     "exclamation-circle": $fa-var-exclamation-circle,
     "school-circle-xmark": $fa-var-school-circle-xmark,
     "arrow-right-from-bracket": $fa-var-arrow-right-from-bracket,
     "sign-out": $fa-var-sign-out,
     "face-frown-slight": $fa-var-face-frown-slight,
     "circle-chevron-down": $fa-var-circle-chevron-down,
     "chevron-circle-down": $fa-var-chevron-circle-down,
     "sidebar-flip": $fa-var-sidebar-flip,
     "unlock-keyhole": $fa-var-unlock-keyhole,
     "unlock-alt": $fa-var-unlock-alt,
     "temperature-list": $fa-var-temperature-list,
     "cloud-showers-heavy": $fa-var-cloud-showers-heavy,
     "headphones-simple": $fa-var-headphones-simple,
     "headphones-alt": $fa-var-headphones-alt,
     "sitemap": $fa-var-sitemap,
     "pipe-section": $fa-var-pipe-section,
     "space-station-moon-construction": $fa-var-space-station-moon-construction,
     "space-station-moon-alt": $fa-var-space-station-moon-alt,
     "circle-dollar-to-slot": $fa-var-circle-dollar-to-slot,
     "donate": $fa-var-donate,
     "memory": $fa-var-memory,
     "face-sleeping": $fa-var-face-sleeping,
     "road-spikes": $fa-var-road-spikes,
     "fire-burner": $fa-var-fire-burner,
     "squirrel": $fa-var-squirrel,
     "arrow-up-to-line": $fa-var-arrow-up-to-line,
     "arrow-to-top": $fa-var-arrow-to-top,
     "flag": $fa-var-flag,
     "face-cowboy-hat": $fa-var-face-cowboy-hat,
     "hanukiah": $fa-var-hanukiah,
     "chart-scatter-3d": $fa-var-chart-scatter-3d,
     "square-code": $fa-var-square-code,
     "feather": $fa-var-feather,
     "volume-low": $fa-var-volume-low,
     "volume-down": $fa-var-volume-down,
     "xmark-to-slot": $fa-var-xmark-to-slot,
     "times-to-slot": $fa-var-times-to-slot,
     "vote-nay": $fa-var-vote-nay,
     "box-taped": $fa-var-box-taped,
     "box-alt": $fa-var-box-alt,
     "comment-slash": $fa-var-comment-slash,
     "swords": $fa-var-swords,
     "cloud-sun-rain": $fa-var-cloud-sun-rain,
     "album": $fa-var-album,
     "circle-n": $fa-var-circle-n,
     "compress": $fa-var-compress,
     "wheat-awn": $fa-var-wheat-awn,
     "wheat-alt": $fa-var-wheat-alt,
     "ankh": $fa-var-ankh,
     "hands-holding-child": $fa-var-hands-holding-child,
     "asterisk": $fa-var-asterisk,
     "key-skeleton-left-right": $fa-var-key-skeleton-left-right,
     "comment-lines": $fa-var-comment-lines,
     "luchador-mask": $fa-var-luchador-mask,
     "luchador": $fa-var-luchador,
     "mask-luchador": $fa-var-mask-luchador,
     "square-check": $fa-var-square-check,
     "check-square": $fa-var-check-square,
     "shredder": $fa-var-shredder,
     "book-open-cover": $fa-var-book-open-cover,
     "book-open-alt": $fa-var-book-open-alt,
     "sandwich": $fa-var-sandwich,
     "peseta-sign": $fa-var-peseta-sign,
     "square-parking-slash": $fa-var-square-parking-slash,
     "parking-slash": $fa-var-parking-slash,
     "train-tunnel": $fa-var-train-tunnel,
     "heading": $fa-var-heading,
     "header": $fa-var-header,
     "ghost": $fa-var-ghost,
     "face-anguished": $fa-var-face-anguished,
     "hockey-sticks": $fa-var-hockey-sticks,
     "abacus": $fa-var-abacus,
     "film-simple": $fa-var-film-simple,
     "film-alt": $fa-var-film-alt,
     "list": $fa-var-list,
     "list-squares": $fa-var-list-squares,
     "tree-palm": $fa-var-tree-palm,
     "square-phone-flip": $fa-var-square-phone-flip,
     "phone-square-alt": $fa-var-phone-square-alt,
     "cart-plus": $fa-var-cart-plus,
     "gamepad": $fa-var-gamepad,
     "border-center-v": $fa-var-border-center-v,
     "circle-dot": $fa-var-circle-dot,
     "dot-circle": $fa-var-dot-circle,
     "clipboard-medical": $fa-var-clipboard-medical,
     "face-dizzy": $fa-var-face-dizzy,
     "dizzy": $fa-var-dizzy,
     "egg": $fa-var-egg,
     "up-to-line": $fa-var-up-to-line,
     "arrow-alt-to-top": $fa-var-arrow-alt-to-top,
     "house-medical-circle-xmark": $fa-var-house-medical-circle-xmark,
     "watch-fitness": $fa-var-watch-fitness,
     "clock-nine-thirty": $fa-var-clock-nine-thirty,
     "campground": $fa-var-campground,
     "folder-plus": $fa-var-folder-plus,
     "jug": $fa-var-jug,
     "futbol": $fa-var-futbol,
     "futbol-ball": $fa-var-futbol-ball,
     "soccer-ball": $fa-var-soccer-ball,
     "snow-blowing": $fa-var-snow-blowing,
     "paintbrush": $fa-var-paintbrush,
     "paint-brush": $fa-var-paint-brush,
     "lock": $fa-var-lock,
     "arrow-down-from-line": $fa-var-arrow-down-from-line,
     "arrow-from-top": $fa-var-arrow-from-top,
     "gas-pump": $fa-var-gas-pump,
     "signal-bars-slash": $fa-var-signal-bars-slash,
     "signal-alt-slash": $fa-var-signal-alt-slash,
     "monkey": $fa-var-monkey,
     "rectangle-pro": $fa-var-rectangle-pro,
     "pro": $fa-var-pro,
     "house-night": $fa-var-house-night,
     "hot-tub-person": $fa-var-hot-tub-person,
     "hot-tub": $fa-var-hot-tub,
     "blanket": $fa-var-blanket,
     "map-location": $fa-var-map-location,
     "map-marked": $fa-var-map-marked,
     "house-flood-water": $fa-var-house-flood-water,
     "comments-question-check": $fa-var-comments-question-check,
     "tree": $fa-var-tree,
     "arrows-cross": $fa-var-arrows-cross,
     "backpack": $fa-var-backpack,
     "square-small": $fa-var-square-small,
     "folder-arrow-up": $fa-var-folder-arrow-up,
     "folder-upload": $fa-var-folder-upload,
     "bridge-lock": $fa-var-bridge-lock,
     "crosshairs-simple": $fa-var-crosshairs-simple,
     "sack-dollar": $fa-var-sack-dollar,
     "pen-to-square": $fa-var-pen-to-square,
     "edit": $fa-var-edit,
     "square-sliders": $fa-var-square-sliders,
     "sliders-h-square": $fa-var-sliders-h-square,
     "car-side": $fa-var-car-side,
     "message-middle-top": $fa-var-message-middle-top,
     "comment-middle-top-alt": $fa-var-comment-middle-top-alt,
     "lightbulb-on": $fa-var-lightbulb-on,
     "knife": $fa-var-knife,
     "utensil-knife": $fa-var-utensil-knife,
     "share-nodes": $fa-var-share-nodes,
     "share-alt": $fa-var-share-alt,
     "wave-sine": $fa-var-wave-sine,
     "heart-circle-minus": $fa-var-heart-circle-minus,
     "circle-w": $fa-var-circle-w,
     "circle-calendar": $fa-var-circle-calendar,
     "calendar-circle": $fa-var-calendar-circle,
     "hourglass-half": $fa-var-hourglass-half,
     "hourglass-2": $fa-var-hourglass-2,
     "microscope": $fa-var-microscope,
     "sunset": $fa-var-sunset,
     "sink": $fa-var-sink,
     "calendar-exclamation": $fa-var-calendar-exclamation,
     "truck-container-empty": $fa-var-truck-container-empty,
     "hand-heart": $fa-var-hand-heart,
     "bag-shopping": $fa-var-bag-shopping,
     "shopping-bag": $fa-var-shopping-bag,
     "arrow-down-z-a": $fa-var-arrow-down-z-a,
     "sort-alpha-desc": $fa-var-sort-alpha-desc,
     "sort-alpha-down-alt": $fa-var-sort-alpha-down-alt,
     "mitten": $fa-var-mitten,
     "reply-clock": $fa-var-reply-clock,
     "reply-time": $fa-var-reply-time,
     "person-rays": $fa-var-person-rays,
     "right": $fa-var-right,
     "arrow-alt-right": $fa-var-arrow-alt-right,
     "circle-f": $fa-var-circle-f,
     "users": $fa-var-users,
     "face-pleading": $fa-var-face-pleading,
     "eye-slash": $fa-var-eye-slash,
     "flask-vial": $fa-var-flask-vial,
     "police-box": $fa-var-police-box,
     "cucumber": $fa-var-cucumber,
     "head-side-brain": $fa-var-head-side-brain,
     "hand": $fa-var-hand,
     "hand-paper": $fa-var-hand-paper,
     "person-biking-mountain": $fa-var-person-biking-mountain,
     "biking-mountain": $fa-var-biking-mountain,
     "utensils-slash": $fa-var-utensils-slash,
     "print-magnifying-glass": $fa-var-print-magnifying-glass,
     "print-search": $fa-var-print-search,
     "folder-bookmark": $fa-var-folder-bookmark,
     "om": $fa-var-om,
     "pi": $fa-var-pi,
     "flask-round-potion": $fa-var-flask-round-potion,
     "flask-potion": $fa-var-flask-potion,
     "face-shush": $fa-var-face-shush,
     "worm": $fa-var-worm,
     "house-circle-xmark": $fa-var-house-circle-xmark,
     "plug": $fa-var-plug,
     "calendar-circle-exclamation": $fa-var-calendar-circle-exclamation,
     "square-i": $fa-var-square-i,
     "chevron-up": $fa-var-chevron-up,
     "face-saluting": $fa-var-face-saluting,
     "gauge-simple-low": $fa-var-gauge-simple-low,
     "tachometer-slow": $fa-var-tachometer-slow,
     "face-persevering": $fa-var-face-persevering,
     "circle-camera": $fa-var-circle-camera,
     "camera-circle": $fa-var-camera-circle,
     "hand-spock": $fa-var-hand-spock,
     "spider-web": $fa-var-spider-web,
     "circle-microphone": $fa-var-circle-microphone,
     "microphone-circle": $fa-var-microphone-circle,
     "book-arrow-up": $fa-var-book-arrow-up,
     "popsicle": $fa-var-popsicle,
     "command": $fa-var-command,
     "blinds": $fa-var-blinds,
     "stopwatch": $fa-var-stopwatch,
     "saxophone": $fa-var-saxophone,
     "square-2": $fa-var-square-2,
     "field-hockey-stick-ball": $fa-var-field-hockey-stick-ball,
     "field-hockey": $fa-var-field-hockey,
     "arrow-up-square-triangle": $fa-var-arrow-up-square-triangle,
     "sort-shapes-up-alt": $fa-var-sort-shapes-up-alt,
     "face-scream": $fa-var-face-scream,
     "square-m": $fa-var-square-m,
     "camera-web": $fa-var-camera-web,
     "webcam": $fa-var-webcam,
     "comment-arrow-down": $fa-var-comment-arrow-down,
     "lightbulb-cfl": $fa-var-lightbulb-cfl,
     "window-frame-open": $fa-var-window-frame-open,
     "face-kiss": $fa-var-face-kiss,
     "kiss": $fa-var-kiss,
     "bridge-circle-xmark": $fa-var-bridge-circle-xmark,
     "period": $fa-var-period,
     "face-grin-tongue": $fa-var-face-grin-tongue,
     "grin-tongue": $fa-var-grin-tongue,
     "up-to-dotted-line": $fa-var-up-to-dotted-line,
     "thought-bubble": $fa-var-thought-bubble,
     "raygun": $fa-var-raygun,
     "flute": $fa-var-flute,
     "acorn": $fa-var-acorn,
     "video-arrow-up-right": $fa-var-video-arrow-up-right,
     "grate-droplet": $fa-var-grate-droplet,
     "seal-exclamation": $fa-var-seal-exclamation,
     "chess-bishop": $fa-var-chess-bishop,
     "message-sms": $fa-var-message-sms,
     "coffee-beans": $fa-var-coffee-beans,
     "hat-witch": $fa-var-hat-witch,
     "face-grin-wink": $fa-var-face-grin-wink,
     "grin-wink": $fa-var-grin-wink,
     "clock-three-thirty": $fa-var-clock-three-thirty,
     "ear-deaf": $fa-var-ear-deaf,
     "deaf": $fa-var-deaf,
     "deafness": $fa-var-deafness,
     "hard-of-hearing": $fa-var-hard-of-hearing,
     "alarm-clock": $fa-var-alarm-clock,
     "eclipse": $fa-var-eclipse,
     "face-relieved": $fa-var-face-relieved,
     "road-circle-check": $fa-var-road-circle-check,
     "dice-five": $fa-var-dice-five,
     "octagon-minus": $fa-var-octagon-minus,
     "minus-octagon": $fa-var-minus-octagon,
     "square-rss": $fa-var-square-rss,
     "rss-square": $fa-var-rss-square,
     "face-zany": $fa-var-face-zany,
     "tricycle": $fa-var-tricycle,
     "land-mine-on": $fa-var-land-mine-on,
     "square-arrow-up-left": $fa-var-square-arrow-up-left,
     "i-cursor": $fa-var-i-cursor,
     "salt-shaker": $fa-var-salt-shaker,
     "stamp": $fa-var-stamp,
     "file-plus": $fa-var-file-plus,
     "draw-square": $fa-var-draw-square,
     "toilet-paper-under-slash": $fa-var-toilet-paper-under-slash,
     "toilet-paper-reverse-slash": $fa-var-toilet-paper-reverse-slash,
     "stairs": $fa-var-stairs,
     "drone-front": $fa-var-drone-front,
     "drone-alt": $fa-var-drone-alt,
     "glass-empty": $fa-var-glass-empty,
     "dial-high": $fa-var-dial-high,
     "user-helmet-safety": $fa-var-user-helmet-safety,
     "user-construction": $fa-var-user-construction,
     "user-hard-hat": $fa-var-user-hard-hat,
     "i": $fa-var-i,
     "hryvnia-sign": $fa-var-hryvnia-sign,
     "hryvnia": $fa-var-hryvnia,
     "arrow-down-left-and-arrow-up-right-to-center": $fa-var-arrow-down-left-and-arrow-up-right-to-center,
     "pills": $fa-var-pills,
     "face-grin-wide": $fa-var-face-grin-wide,
     "grin-alt": $fa-var-grin-alt,
     "tooth": $fa-var-tooth,
     "basketball-hoop": $fa-var-basketball-hoop,
     "objects-align-bottom": $fa-var-objects-align-bottom,
     "v": $fa-var-v,
     "sparkles": $fa-var-sparkles,
     "squid": $fa-var-squid,
     "leafy-green": $fa-var-leafy-green,
     "circle-arrow-up-right": $fa-var-circle-arrow-up-right,
     "calendars": $fa-var-calendars,
     "bangladeshi-taka-sign": $fa-var-bangladeshi-taka-sign,
     "bicycle": $fa-var-bicycle,
     "hammer-war": $fa-var-hammer-war,
     "circle-d": $fa-var-circle-d,
     "spider-black-widow": $fa-var-spider-black-widow,
     "staff-snake": $fa-var-staff-snake,
     "rod-asclepius": $fa-var-rod-asclepius,
     "rod-snake": $fa-var-rod-snake,
     "staff-aesculapius": $fa-var-staff-aesculapius,
     "pear": $fa-var-pear,
     "head-side-cough-slash": $fa-var-head-side-cough-slash,
     "triangle": $fa-var-triangle,
     "apartment": $fa-var-apartment,
     "truck-medical": $fa-var-truck-medical,
     "ambulance": $fa-var-ambulance,
     "pepper": $fa-var-pepper,
     "piano": $fa-var-piano,
     "gun-squirt": $fa-var-gun-squirt,
     "wheat-awn-circle-exclamation": $fa-var-wheat-awn-circle-exclamation,
     "snowman": $fa-var-snowman,
     "user-alien": $fa-var-user-alien,
     "shield-check": $fa-var-shield-check,
     "mortar-pestle": $fa-var-mortar-pestle,
     "road-barrier": $fa-var-road-barrier,
     "chart-candlestick": $fa-var-chart-candlestick,
     "briefcase-blank": $fa-var-briefcase-blank,
     "school": $fa-var-school,
     "igloo": $fa-var-igloo,
     "bracket-round": $fa-var-bracket-round,
     "parenthesis": $fa-var-parenthesis,
     "joint": $fa-var-joint,
     "horse-saddle": $fa-var-horse-saddle,
     "mug-marshmallows": $fa-var-mug-marshmallows,
     "filters": $fa-var-filters,
     "bell-on": $fa-var-bell-on,
     "angle-right": $fa-var-angle-right,
     "dial-med": $fa-var-dial-med,
     "horse": $fa-var-horse,
     "q": $fa-var-q,
     "monitor-waveform": $fa-var-monitor-waveform,
     "monitor-heart-rate": $fa-var-monitor-heart-rate,
     "link-simple": $fa-var-link-simple,
     "whistle": $fa-var-whistle,
     "g": $fa-var-g,
     "wine-glass-crack": $fa-var-wine-glass-crack,
     "fragile": $fa-var-fragile,
     "slot-machine": $fa-var-slot-machine,
     "notes-medical": $fa-var-notes-medical,
     "car-wash": $fa-var-car-wash,
     "escalator": $fa-var-escalator,
     "comment-image": $fa-var-comment-image,
     "temperature-half": $fa-var-temperature-half,
     "temperature-2": $fa-var-temperature-2,
     "thermometer-2": $fa-var-thermometer-2,
     "thermometer-half": $fa-var-thermometer-half,
     "dong-sign": $fa-var-dong-sign,
     "donut": $fa-var-donut,
     "doughnut": $fa-var-doughnut,
     "capsules": $fa-var-capsules,
     "poo-storm": $fa-var-poo-storm,
     "poo-bolt": $fa-var-poo-bolt,
     "tally-1": $fa-var-tally-1,
     "face-frown-open": $fa-var-face-frown-open,
     "frown-open": $fa-var-frown-open,
     "square-dashed": $fa-var-square-dashed,
     "square-j": $fa-var-square-j,
     "hand-point-up": $fa-var-hand-point-up,
     "money-bill": $fa-var-money-bill,
     "arrow-up-big-small": $fa-var-arrow-up-big-small,
     "sort-size-up": $fa-var-sort-size-up,
     "barcode-read": $fa-var-barcode-read,
     "baguette": $fa-var-baguette,
     "bowl-soft-serve": $fa-var-bowl-soft-serve,
     "face-holding-back-tears": $fa-var-face-holding-back-tears,
     "square-up": $fa-var-square-up,
     "arrow-alt-square-up": $fa-var-arrow-alt-square-up,
     "train-subway-tunnel": $fa-var-train-subway-tunnel,
     "subway-tunnel": $fa-var-subway-tunnel,
     "square-exclamation": $fa-var-square-exclamation,
     "exclamation-square": $fa-var-exclamation-square,
     "semicolon": $fa-var-semicolon,
     "bookmark": $fa-var-bookmark,
     "fan-table": $fa-var-fan-table,
     "align-justify": $fa-var-align-justify,
     "battery-low": $fa-var-battery-low,
     "battery-1": $fa-var-battery-1,
     "credit-card-front": $fa-var-credit-card-front,
     "brain-arrow-curved-right": $fa-var-brain-arrow-curved-right,
     "mind-share": $fa-var-mind-share,
     "umbrella-beach": $fa-var-umbrella-beach,
     "helmet-un": $fa-var-helmet-un,
     "location-smile": $fa-var-location-smile,
     "map-marker-smile": $fa-var-map-marker-smile,
     "arrow-left-to-line": $fa-var-arrow-left-to-line,
     "arrow-to-left": $fa-var-arrow-to-left,
     "bullseye": $fa-var-bullseye,
     "sushi": $fa-var-sushi,
     "nigiri": $fa-var-nigiri,
     "message-captions": $fa-var-message-captions,
     "comment-alt-captions": $fa-var-comment-alt-captions,
     "trash-list": $fa-var-trash-list,
     "bacon": $fa-var-bacon,
     "option": $fa-var-option,
     "hand-point-down": $fa-var-hand-point-down,
     "arrow-up-from-bracket": $fa-var-arrow-up-from-bracket,
     "trash-plus": $fa-var-trash-plus,
     "objects-align-top": $fa-var-objects-align-top,
     "folder": $fa-var-folder,
     "folder-blank": $fa-var-folder-blank,
     "face-anxious-sweat": $fa-var-face-anxious-sweat,
     "credit-card-blank": $fa-var-credit-card-blank,
     "file-waveform": $fa-var-file-waveform,
     "file-medical-alt": $fa-var-file-medical-alt,
     "microchip-ai": $fa-var-microchip-ai,
     "mug": $fa-var-mug,
     "plane-up-slash": $fa-var-plane-up-slash,
     "radiation": $fa-var-radiation,
     "pen-circle": $fa-var-pen-circle,
     "chart-simple": $fa-var-chart-simple,
     "crutches": $fa-var-crutches,
     "circle-parking": $fa-var-circle-parking,
     "parking-circle": $fa-var-parking-circle,
     "mars-stroke": $fa-var-mars-stroke,
     "leaf-oak": $fa-var-leaf-oak,
     "square-bolt": $fa-var-square-bolt,
     "vial": $fa-var-vial,
     "gauge": $fa-var-gauge,
     "dashboard": $fa-var-dashboard,
     "gauge-med": $fa-var-gauge-med,
     "tachometer-alt-average": $fa-var-tachometer-alt-average,
     "wand-magic-sparkles": $fa-var-wand-magic-sparkles,
     "magic-wand-sparkles": $fa-var-magic-wand-sparkles,
     "lambda": $fa-var-lambda,
     "e": $fa-var-e,
     "pizza": $fa-var-pizza,
     "bowl-chopsticks-noodles": $fa-var-bowl-chopsticks-noodles,
     "h3": $fa-var-h3,
     "pen-clip": $fa-var-pen-clip,
     "pen-alt": $fa-var-pen-alt,
     "bridge-circle-exclamation": $fa-var-bridge-circle-exclamation,
     "badge-percent": $fa-var-badge-percent,
     "user": $fa-var-user,
     "sensor": $fa-var-sensor,
     "comma": $fa-var-comma,
     "school-circle-check": $fa-var-school-circle-check,
     "toilet-paper-under": $fa-var-toilet-paper-under,
     "toilet-paper-reverse": $fa-var-toilet-paper-reverse,
     "light-emergency": $fa-var-light-emergency,
     "arrow-down-to-arc": $fa-var-arrow-down-to-arc,
     "dumpster": $fa-var-dumpster,
     "van-shuttle": $fa-var-van-shuttle,
     "shuttle-van": $fa-var-shuttle-van,
     "building-user": $fa-var-building-user,
     "light-switch": $fa-var-light-switch,
     "square-caret-left": $fa-var-square-caret-left,
     "caret-square-left": $fa-var-caret-square-left,
     "highlighter": $fa-var-highlighter,
     "wave-pulse": $fa-var-wave-pulse,
     "heart-rate": $fa-var-heart-rate,
     "key": $fa-var-key,
     "hat-santa": $fa-var-hat-santa,
     "tamale": $fa-var-tamale,
     "box-check": $fa-var-box-check,
     "bullhorn": $fa-var-bullhorn,
     "steak": $fa-var-steak,
     "location-crosshairs-slash": $fa-var-location-crosshairs-slash,
     "location-slash": $fa-var-location-slash,
     "person-dolly": $fa-var-person-dolly,
     "globe": $fa-var-globe,
     "synagogue": $fa-var-synagogue,
     "file-chart-column": $fa-var-file-chart-column,
     "file-chart-line": $fa-var-file-chart-line,
     "person-half-dress": $fa-var-person-half-dress,
     "folder-image": $fa-var-folder-image,
     "calendar-pen": $fa-var-calendar-pen,
     "calendar-edit": $fa-var-calendar-edit,
     "road-bridge": $fa-var-road-bridge,
     "face-smile-tear": $fa-var-face-smile-tear,
     "message-plus": $fa-var-message-plus,
     "comment-alt-plus": $fa-var-comment-alt-plus,
     "location-arrow": $fa-var-location-arrow,
     "c": $fa-var-c,
     "tablet-button": $fa-var-tablet-button,
     "rectangle-history-circle-user": $fa-var-rectangle-history-circle-user,
     "building-lock": $fa-var-building-lock,
     "chart-line-up": $fa-var-chart-line-up,
     "mailbox": $fa-var-mailbox,
     "truck-bolt": $fa-var-truck-bolt,
     "pizza-slice": $fa-var-pizza-slice,
     "money-bill-wave": $fa-var-money-bill-wave,
     "chart-area": $fa-var-chart-area,
     "area-chart": $fa-var-area-chart,
     "house-flag": $fa-var-house-flag,
     "person-circle-minus": $fa-var-person-circle-minus,
     "scalpel": $fa-var-scalpel,
     "ban": $fa-var-ban,
     "cancel": $fa-var-cancel,
     "bell-exclamation": $fa-var-bell-exclamation,
     "circle-bookmark": $fa-var-circle-bookmark,
     "bookmark-circle": $fa-var-bookmark-circle,
     "egg-fried": $fa-var-egg-fried,
     "face-weary": $fa-var-face-weary,
     "uniform-martial-arts": $fa-var-uniform-martial-arts,
     "camera-rotate": $fa-var-camera-rotate,
     "sun-dust": $fa-var-sun-dust,
     "comment-text": $fa-var-comment-text,
     "spray-can-sparkles": $fa-var-spray-can-sparkles,
     "air-freshener": $fa-var-air-freshener,
     "signal-bars": $fa-var-signal-bars,
     "signal-alt": $fa-var-signal-alt,
     "signal-alt-4": $fa-var-signal-alt-4,
     "signal-bars-strong": $fa-var-signal-bars-strong,
     "diamond-exclamation": $fa-var-diamond-exclamation,
     "star": $fa-var-star,
     "dial-min": $fa-var-dial-min,
     "repeat": $fa-var-repeat,
     "cross": $fa-var-cross,
     "page-caret-down": $fa-var-page-caret-down,
     "file-caret-down": $fa-var-file-caret-down,
     "box": $fa-var-box,
     "venus-mars": $fa-var-venus-mars,
     "clock-seven-thirty": $fa-var-clock-seven-thirty,
     "arrow-pointer": $fa-var-arrow-pointer,
     "mouse-pointer": $fa-var-mouse-pointer,
     "clock-four-thirty": $fa-var-clock-four-thirty,
     "signal-bars-good": $fa-var-signal-bars-good,
     "signal-alt-3": $fa-var-signal-alt-3,
     "cactus": $fa-var-cactus,
     "maximize": $fa-var-maximize,
     "expand-arrows-alt": $fa-var-expand-arrows-alt,
     "charging-station": $fa-var-charging-station,
     "shapes": $fa-var-shapes,
     "triangle-circle-square": $fa-var-triangle-circle-square,
     "plane-tail": $fa-var-plane-tail,
     "gauge-simple-max": $fa-var-gauge-simple-max,
     "tachometer-fastest": $fa-var-tachometer-fastest,
     "circle-u": $fa-var-circle-u,
     "shield-slash": $fa-var-shield-slash,
     "square-phone-hangup": $fa-var-square-phone-hangup,
     "phone-square-down": $fa-var-phone-square-down,
     "arrow-up-left": $fa-var-arrow-up-left,
     "transporter-1": $fa-var-transporter-1,
     "peanuts": $fa-var-peanuts,
     "shuffle": $fa-var-shuffle,
     "random": $fa-var-random,
     "person-running": $fa-var-person-running,
     "running": $fa-var-running,
     "mobile-retro": $fa-var-mobile-retro,
     "grip-lines-vertical": $fa-var-grip-lines-vertical,
     "arrow-up-from-square": $fa-var-arrow-up-from-square,
     "file-dashed-line": $fa-var-file-dashed-line,
     "page-break": $fa-var-page-break,
     "bracket-curly-right": $fa-var-bracket-curly-right,
     "spider": $fa-var-spider,
     "clock-three": $fa-var-clock-three,
     "hands-bound": $fa-var-hands-bound,
     "scalpel-line-dashed": $fa-var-scalpel-line-dashed,
     "scalpel-path": $fa-var-scalpel-path,
     "file-invoice-dollar": $fa-var-file-invoice-dollar,
     "pipe-smoking": $fa-var-pipe-smoking,
     "face-astonished": $fa-var-face-astonished,
     "window": $fa-var-window,
     "plane-circle-exclamation": $fa-var-plane-circle-exclamation,
     "ear": $fa-var-ear,
     "file-lock": $fa-var-file-lock,
     "diagram-venn": $fa-var-diagram-venn,
     "x-ray": $fa-var-x-ray,
     "goal-net": $fa-var-goal-net,
     "coffin-cross": $fa-var-coffin-cross,
     "spell-check": $fa-var-spell-check,
     "location-xmark": $fa-var-location-xmark,
     "map-marker-times": $fa-var-map-marker-times,
     "map-marker-xmark": $fa-var-map-marker-xmark,
     "lasso": $fa-var-lasso,
     "slash": $fa-var-slash,
     "person-to-portal": $fa-var-person-to-portal,
     "portal-enter": $fa-var-portal-enter,
     "calendar-star": $fa-var-calendar-star,
     "computer-mouse": $fa-var-computer-mouse,
     "mouse": $fa-var-mouse,
     "arrow-right-to-bracket": $fa-var-arrow-right-to-bracket,
     "sign-in": $fa-var-sign-in,
     "pegasus": $fa-var-pegasus,
     "files-medical": $fa-var-files-medical,
     "nfc-lock": $fa-var-nfc-lock,
     "person-ski-lift": $fa-var-person-ski-lift,
     "ski-lift": $fa-var-ski-lift,
     "square-6": $fa-var-square-6,
     "shop-slash": $fa-var-shop-slash,
     "store-alt-slash": $fa-var-store-alt-slash,
     "wind-turbine": $fa-var-wind-turbine,
     "sliders-simple": $fa-var-sliders-simple,
     "badge-sheriff": $fa-var-badge-sheriff,
     "server": $fa-var-server,
     "virus-covid-slash": $fa-var-virus-covid-slash,
     "intersection": $fa-var-intersection,
     "shop-lock": $fa-var-shop-lock,
     "family": $fa-var-family,
     "hourglass-start": $fa-var-hourglass-start,
     "hourglass-1": $fa-var-hourglass-1,
     "user-hair-buns": $fa-var-user-hair-buns,
     "blender-phone": $fa-var-blender-phone,
     "hourglass-clock": $fa-var-hourglass-clock,
     "person-seat-reclined": $fa-var-person-seat-reclined,
     "paper-plane-top": $fa-var-paper-plane-top,
     "paper-plane-alt": $fa-var-paper-plane-alt,
     "send": $fa-var-send,
     "message-arrow-up": $fa-var-message-arrow-up,
     "comment-alt-arrow-up": $fa-var-comment-alt-arrow-up,
     "lightbulb-exclamation": $fa-var-lightbulb-exclamation,
     "layer-minus": $fa-var-layer-minus,
     "layer-group-minus": $fa-var-layer-group-minus,
     "circle-e": $fa-var-circle-e,
     "building-wheat": $fa-var-building-wheat,
     "gauge-max": $fa-var-gauge-max,
     "tachometer-alt-fastest": $fa-var-tachometer-alt-fastest,
     "person-breastfeeding": $fa-var-person-breastfeeding,
     "apostrophe": $fa-var-apostrophe,
     "fire-hydrant": $fa-var-fire-hydrant,
     "right-to-bracket": $fa-var-right-to-bracket,
     "sign-in-alt": $fa-var-sign-in-alt,
     "video-plus": $fa-var-video-plus,
     "square-right": $fa-var-square-right,
     "arrow-alt-square-right": $fa-var-arrow-alt-square-right,
     "comment-smile": $fa-var-comment-smile,
     "venus": $fa-var-venus,
     "passport": $fa-var-passport,
     "inbox-in": $fa-var-inbox-in,
     "inbox-arrow-down": $fa-var-inbox-arrow-down,
     "heart-pulse": $fa-var-heart-pulse,
     "heartbeat": $fa-var-heartbeat,
     "circle-8": $fa-var-circle-8,
     "clouds-moon": $fa-var-clouds-moon,
     "clock-ten-thirty": $fa-var-clock-ten-thirty,
     "people-carry-box": $fa-var-people-carry-box,
     "people-carry": $fa-var-people-carry,
     "folder-user": $fa-var-folder-user,
     "trash-can-xmark": $fa-var-trash-can-xmark,
     "temperature-high": $fa-var-temperature-high,
     "microchip": $fa-var-microchip,
     "left-long-to-line": $fa-var-left-long-to-line,
     "crown": $fa-var-crown,
     "weight-hanging": $fa-var-weight-hanging,
     "xmarks-lines": $fa-var-xmarks-lines,
     "file-prescription": $fa-var-file-prescription,
     "calendar-range": $fa-var-calendar-range,
     "flower-daffodil": $fa-var-flower-daffodil,
     "hand-back-point-up": $fa-var-hand-back-point-up,
     "weight-scale": $fa-var-weight-scale,
     "weight": $fa-var-weight,
     "star-exclamation": $fa-var-star-exclamation,
     "books": $fa-var-books,
     "user-group": $fa-var-user-group,
     "user-friends": $fa-var-user-friends,
     "arrow-up-a-z": $fa-var-arrow-up-a-z,
     "sort-alpha-up": $fa-var-sort-alpha-up,
     "layer-plus": $fa-var-layer-plus,
     "layer-group-plus": $fa-var-layer-group-plus,
     "play-pause": $fa-var-play-pause,
     "block-question": $fa-var-block-question,
     "snooze": $fa-var-snooze,
     "zzz": $fa-var-zzz,
     "scanner-image": $fa-var-scanner-image,
     "tv-retro": $fa-var-tv-retro,
     "square-t": $fa-var-square-t,
     "farm": $fa-var-farm,
     "barn-silo": $fa-var-barn-silo,
     "chess-knight": $fa-var-chess-knight,
     "bars-sort": $fa-var-bars-sort,
     "pallet-boxes": $fa-var-pallet-boxes,
     "palette-boxes": $fa-var-palette-boxes,
     "pallet-alt": $fa-var-pallet-alt,
     "face-laugh-squint": $fa-var-face-laugh-squint,
     "laugh-squint": $fa-var-laugh-squint,
     "code-simple": $fa-var-code-simple,
     "bolt-slash": $fa-var-bolt-slash,
     "panel-fire": $fa-var-panel-fire,
     "binary-circle-check": $fa-var-binary-circle-check,
     "comment-minus": $fa-var-comment-minus,
     "burrito": $fa-var-burrito,
     "violin": $fa-var-violin,
     "objects-column": $fa-var-objects-column,
     "square-chevron-down": $fa-var-square-chevron-down,
     "chevron-square-down": $fa-var-chevron-square-down,
     "comment-plus": $fa-var-comment-plus,
     "triangle-instrument": $fa-var-triangle-instrument,
     "triangle-music": $fa-var-triangle-music,
     "wheelchair": $fa-var-wheelchair,
     "user-pilot-tie": $fa-var-user-pilot-tie,
     "piano-keyboard": $fa-var-piano-keyboard,
     "bed-empty": $fa-var-bed-empty,
     "circle-arrow-up": $fa-var-circle-arrow-up,
     "arrow-circle-up": $fa-var-arrow-circle-up,
     "toggle-on": $fa-var-toggle-on,
     "rectangle-vertical": $fa-var-rectangle-vertical,
     "rectangle-portrait": $fa-var-rectangle-portrait,
     "person-walking": $fa-var-person-walking,
     "walking": $fa-var-walking,
     "l": $fa-var-l,
     "signal-stream": $fa-var-signal-stream,
     "down-to-bracket": $fa-var-down-to-bracket,
     "circle-z": $fa-var-circle-z,
     "stars": $fa-var-stars,
     "fire": $fa-var-fire,
     "bed-pulse": $fa-var-bed-pulse,
     "procedures": $fa-var-procedures,
     "house-day": $fa-var-house-day,
     "shuttle-space": $fa-var-shuttle-space,
     "space-shuttle": $fa-var-space-shuttle,
     "shirt-long-sleeve": $fa-var-shirt-long-sleeve,
     "chart-pie-simple": $fa-var-chart-pie-simple,
     "chart-pie-alt": $fa-var-chart-pie-alt,
     "face-laugh": $fa-var-face-laugh,
     "laugh": $fa-var-laugh,
     "folder-open": $fa-var-folder-open,
     "album-collection-circle-user": $fa-var-album-collection-circle-user,
     "candy": $fa-var-candy,
     "bowl-hot": $fa-var-bowl-hot,
     "soup": $fa-var-soup,
     "flatbread": $fa-var-flatbread,
     "heart-circle-plus": $fa-var-heart-circle-plus,
     "code-fork": $fa-var-code-fork,
     "city": $fa-var-city,
     "signal-bars-weak": $fa-var-signal-bars-weak,
     "signal-alt-1": $fa-var-signal-alt-1,
     "microphone-lines": $fa-var-microphone-lines,
     "microphone-alt": $fa-var-microphone-alt,
     "clock-twelve": $fa-var-clock-twelve,
     "pepper-hot": $fa-var-pepper-hot,
     "citrus-slice": $fa-var-citrus-slice,
     "sheep": $fa-var-sheep,
     "unlock": $fa-var-unlock,
     "colon-sign": $fa-var-colon-sign,
     "headset": $fa-var-headset,
     "badger-honey": $fa-var-badger-honey,
     "h4": $fa-var-h4,
     "store-slash": $fa-var-store-slash,
     "road-circle-xmark": $fa-var-road-circle-xmark,
     "signal-slash": $fa-var-signal-slash,
     "user-minus": $fa-var-user-minus,
     "mars-stroke-up": $fa-var-mars-stroke-up,
     "mars-stroke-v": $fa-var-mars-stroke-v,
     "champagne-glasses": $fa-var-champagne-glasses,
     "glass-cheers": $fa-var-glass-cheers,
     "taco": $fa-var-taco,
     "hexagon-plus": $fa-var-hexagon-plus,
     "plus-hexagon": $fa-var-plus-hexagon,
     "clipboard": $fa-var-clipboard,
     "house-circle-exclamation": $fa-var-house-circle-exclamation,
     "file-arrow-up": $fa-var-file-arrow-up,
     "file-upload": $fa-var-file-upload,
     "wifi": $fa-var-wifi,
     "wifi-3": $fa-var-wifi-3,
     "wifi-strong": $fa-var-wifi-strong,
     "messages": $fa-var-messages,
     "comments-alt": $fa-var-comments-alt,
     "bath": $fa-var-bath,
     "bathtub": $fa-var-bathtub,
     "umbrella-simple": $fa-var-umbrella-simple,
     "umbrella-alt": $fa-var-umbrella-alt,
     "rectangle-history-circle-plus": $fa-var-rectangle-history-circle-plus,
     "underline": $fa-var-underline,
     "prescription-bottle-pill": $fa-var-prescription-bottle-pill,
     "user-pen": $fa-var-user-pen,
     "user-edit": $fa-var-user-edit,
     "binary-slash": $fa-var-binary-slash,
     "square-o": $fa-var-square-o,
     "signature": $fa-var-signature,
     "stroopwafel": $fa-var-stroopwafel,
     "bold": $fa-var-bold,
     "anchor-lock": $fa-var-anchor-lock,
     "building-ngo": $fa-var-building-ngo,
     "transporter-3": $fa-var-transporter-3,
     "engine-warning": $fa-var-engine-warning,
     "engine-exclamation": $fa-var-engine-exclamation,
     "circle-down-right": $fa-var-circle-down-right,
     "square-k": $fa-var-square-k,
     "manat-sign": $fa-var-manat-sign,
     "money-check-pen": $fa-var-money-check-pen,
     "money-check-edit": $fa-var-money-check-edit,
     "not-equal": $fa-var-not-equal,
     "border-top-left": $fa-var-border-top-left,
     "border-style": $fa-var-border-style,
     "map-location-dot": $fa-var-map-location-dot,
     "map-marked-alt": $fa-var-map-marked-alt,
     "tilde": $fa-var-tilde,
     "jedi": $fa-var-jedi,
     "square-poll-vertical": $fa-var-square-poll-vertical,
     "poll": $fa-var-poll,
     "arrow-down-square-triangle": $fa-var-arrow-down-square-triangle,
     "sort-shapes-down-alt": $fa-var-sort-shapes-down-alt,
     "mug-hot": $fa-var-mug-hot,
     "dog-leashed": $fa-var-dog-leashed,
     "car-battery": $fa-var-car-battery,
     "battery-car": $fa-var-battery-car,
     "face-downcast-sweat": $fa-var-face-downcast-sweat,
     "mailbox-flag-up": $fa-var-mailbox-flag-up,
     "memo-circle-info": $fa-var-memo-circle-info,
     "gift": $fa-var-gift,
     "dice-two": $fa-var-dice-two,
     "volume": $fa-var-volume,
     "volume-medium": $fa-var-volume-medium,
     "transporter-5": $fa-var-transporter-5,
     "gauge-circle-bolt": $fa-var-gauge-circle-bolt,
     "coin-front": $fa-var-coin-front,
     "file-slash": $fa-var-file-slash,
     "message-arrow-up-right": $fa-var-message-arrow-up-right,
     "treasure-chest": $fa-var-treasure-chest,
     "chess-queen": $fa-var-chess-queen,
     "paintbrush-fine": $fa-var-paintbrush-fine,
     "paint-brush-alt": $fa-var-paint-brush-alt,
     "paint-brush-fine": $fa-var-paint-brush-fine,
     "paintbrush-alt": $fa-var-paintbrush-alt,
     "glasses": $fa-var-glasses,
     "hood-cloak": $fa-var-hood-cloak,
     "square-quote": $fa-var-square-quote,
     "up-left": $fa-var-up-left,
     "bring-front": $fa-var-bring-front,
     "chess-board": $fa-var-chess-board,
     "burger-cheese": $fa-var-burger-cheese,
     "cheeseburger": $fa-var-cheeseburger,
     "building-circle-check": $fa-var-building-circle-check,
     "repeat-1": $fa-var-repeat-1,
     "arrow-down-to-line": $fa-var-arrow-down-to-line,
     "arrow-to-bottom": $fa-var-arrow-to-bottom,
     "grid-5": $fa-var-grid-5,
     "right-long-to-line": $fa-var-right-long-to-line,
     "person-chalkboard": $fa-var-person-chalkboard,
     "mars-stroke-right": $fa-var-mars-stroke-right,
     "mars-stroke-h": $fa-var-mars-stroke-h,
     "hand-back-fist": $fa-var-hand-back-fist,
     "hand-rock": $fa-var-hand-rock,
     "tally": $fa-var-tally,
     "tally-5": $fa-var-tally-5,
     "square-caret-up": $fa-var-square-caret-up,
     "caret-square-up": $fa-var-caret-square-up,
     "cloud-showers-water": $fa-var-cloud-showers-water,
     "chart-bar": $fa-var-chart-bar,
     "bar-chart": $fa-var-bar-chart,
     "hands-bubbles": $fa-var-hands-bubbles,
     "hands-wash": $fa-var-hands-wash,
     "less-than-equal": $fa-var-less-than-equal,
     "train": $fa-var-train,
     "up-from-dotted-line": $fa-var-up-from-dotted-line,
     "eye-low-vision": $fa-var-eye-low-vision,
     "low-vision": $fa-var-low-vision,
     "traffic-light-go": $fa-var-traffic-light-go,
     "face-exhaling": $fa-var-face-exhaling,
     "sensor-fire": $fa-var-sensor-fire,
     "user-unlock": $fa-var-user-unlock,
     "hexagon-divide": $fa-var-hexagon-divide,
     "00": $fa-var-00,
     "crow": $fa-var-crow,
     "cassette-betamax": $fa-var-cassette-betamax,
     "betamax": $fa-var-betamax,
     "sailboat": $fa-var-sailboat,
     "window-restore": $fa-var-window-restore,
     "nfc-magnifying-glass": $fa-var-nfc-magnifying-glass,
     "file-binary": $fa-var-file-binary,
     "circle-v": $fa-var-circle-v,
     "square-plus": $fa-var-square-plus,
     "plus-square": $fa-var-plus-square,
     "bowl-scoops": $fa-var-bowl-scoops,
     "mistletoe": $fa-var-mistletoe,
     "custard": $fa-var-custard,
     "lacrosse-stick": $fa-var-lacrosse-stick,
     "hockey-mask": $fa-var-hockey-mask,
     "sunrise": $fa-var-sunrise,
     "panel-ews": $fa-var-panel-ews,
     "torii-gate": $fa-var-torii-gate,
     "cloud-exclamation": $fa-var-cloud-exclamation,
     "message-lines": $fa-var-message-lines,
     "comment-alt-lines": $fa-var-comment-alt-lines,
     "frog": $fa-var-frog,
     "bucket": $fa-var-bucket,
     "floppy-disk-pen": $fa-var-floppy-disk-pen,
     "image": $fa-var-image,
     "window-frame": $fa-var-window-frame,
     "microphone": $fa-var-microphone,
     "cow": $fa-var-cow,
     "square-ring": $fa-var-square-ring,
     "down-from-line": $fa-var-down-from-line,
     "arrow-alt-from-top": $fa-var-arrow-alt-from-top,
     "caret-up": $fa-var-caret-up,
     "shield-xmark": $fa-var-shield-xmark,
     "shield-times": $fa-var-shield-times,
     "screwdriver": $fa-var-screwdriver,
     "circle-sort-down": $fa-var-circle-sort-down,
     "sort-circle-down": $fa-var-sort-circle-down,
     "folder-closed": $fa-var-folder-closed,
     "house-tsunami": $fa-var-house-tsunami,
     "square-nfi": $fa-var-square-nfi,
     "forklift": $fa-var-forklift,
     "arrow-up-from-ground-water": $fa-var-arrow-up-from-ground-water,
     "bracket-square-right": $fa-var-bracket-square-right,
     "martini-glass": $fa-var-martini-glass,
     "glass-martini-alt": $fa-var-glass-martini-alt,
     "rotate-left": $fa-var-rotate-left,
     "rotate-back": $fa-var-rotate-back,
     "rotate-backward": $fa-var-rotate-backward,
     "undo-alt": $fa-var-undo-alt,
     "table-columns": $fa-var-table-columns,
     "columns": $fa-var-columns,
     "square-a": $fa-var-square-a,
     "tick": $fa-var-tick,
     "lemon": $fa-var-lemon,
     "head-side-mask": $fa-var-head-side-mask,
     "handshake": $fa-var-handshake,
     "gem": $fa-var-gem,
     "dolly": $fa-var-dolly,
     "dolly-box": $fa-var-dolly-box,
     "smoking": $fa-var-smoking,
     "minimize": $fa-var-minimize,
     "compress-arrows-alt": $fa-var-compress-arrows-alt,
     "refrigerator": $fa-var-refrigerator,
     "monument": $fa-var-monument,
     "octagon-xmark": $fa-var-octagon-xmark,
     "times-octagon": $fa-var-times-octagon,
     "xmark-octagon": $fa-var-xmark-octagon,
     "align-slash": $fa-var-align-slash,
     "snowplow": $fa-var-snowplow,
     "angles-right": $fa-var-angles-right,
     "angle-double-right": $fa-var-angle-double-right,
     "truck-ramp-couch": $fa-var-truck-ramp-couch,
     "truck-couch": $fa-var-truck-couch,
     "cannabis": $fa-var-cannabis,
     "circle-play": $fa-var-circle-play,
     "play-circle": $fa-var-play-circle,
     "arrow-up-right-and-arrow-down-left-from-center": $fa-var-arrow-up-right-and-arrow-down-left-from-center,
     "tablets": $fa-var-tablets,
     "360-degrees": $fa-var-360-degrees,
     "ethernet": $fa-var-ethernet,
     "euro-sign": $fa-var-euro-sign,
     "eur": $fa-var-eur,
     "euro": $fa-var-euro,
     "chair": $fa-var-chair,
     "circle-check": $fa-var-circle-check,
     "check-circle": $fa-var-check-circle,
     "square-dashed-circle-plus": $fa-var-square-dashed-circle-plus,
     "money-simple-from-bracket": $fa-var-money-simple-from-bracket,
     "bat": $fa-var-bat,
     "circle-stop": $fa-var-circle-stop,
     "stop-circle": $fa-var-stop-circle,
     "head-side-headphones": $fa-var-head-side-headphones,
     "phone-rotary": $fa-var-phone-rotary,
     "compass-drafting": $fa-var-compass-drafting,
     "drafting-compass": $fa-var-drafting-compass,
     "plate-wheat": $fa-var-plate-wheat,
     "calendar-circle-minus": $fa-var-calendar-circle-minus,
     "chopsticks": $fa-var-chopsticks,
     "car-wrench": $fa-var-car-wrench,
     "car-mechanic": $fa-var-car-mechanic,
     "icicles": $fa-var-icicles,
     "person-shelter": $fa-var-person-shelter,
     "neuter": $fa-var-neuter,
     "id-badge": $fa-var-id-badge,
     "kazoo": $fa-var-kazoo,
     "marker": $fa-var-marker,
     "face-laugh-beam": $fa-var-face-laugh-beam,
     "laugh-beam": $fa-var-laugh-beam,
     "square-arrow-down-left": $fa-var-square-arrow-down-left,
     "battery-bolt": $fa-var-battery-bolt,
     "tree-large": $fa-var-tree-large,
     "helicopter-symbol": $fa-var-helicopter-symbol,
     "aperture": $fa-var-aperture,
     "universal-access": $fa-var-universal-access,
     "file-magnifying-glass": $fa-var-file-magnifying-glass,
     "file-search": $fa-var-file-search,
     "up-right": $fa-var-up-right,
     "circle-chevron-up": $fa-var-circle-chevron-up,
     "chevron-circle-up": $fa-var-chevron-circle-up,
     "user-police": $fa-var-user-police,
     "lari-sign": $fa-var-lari-sign,
     "volcano": $fa-var-volcano,
     "teddy-bear": $fa-var-teddy-bear,
     "stocking": $fa-var-stocking,
     "person-walking-dashed-line-arrow-right": $fa-var-person-walking-dashed-line-arrow-right,
     "image-slash": $fa-var-image-slash,
     "mask-snorkel": $fa-var-mask-snorkel,
     "smoke": $fa-var-smoke,
     "sterling-sign": $fa-var-sterling-sign,
     "gbp": $fa-var-gbp,
     "pound-sign": $fa-var-pound-sign,
     "battery-exclamation": $fa-var-battery-exclamation,
     "viruses": $fa-var-viruses,
     "square-person-confined": $fa-var-square-person-confined,
     "user-tie": $fa-var-user-tie,
     "arrow-down-long": $fa-var-arrow-down-long,
     "long-arrow-down": $fa-var-long-arrow-down,
     "tent-arrow-down-to-line": $fa-var-tent-arrow-down-to-line,
     "certificate": $fa-var-certificate,
     "crystal-ball": $fa-var-crystal-ball,
     "reply-all": $fa-var-reply-all,
     "mail-reply-all": $fa-var-mail-reply-all,
     "suitcase": $fa-var-suitcase,
     "person-skating": $fa-var-person-skating,
     "skating": $fa-var-skating,
     "star-shooting": $fa-var-star-shooting,
     "binary-lock": $fa-var-binary-lock,
     "filter-circle-dollar": $fa-var-filter-circle-dollar,
     "funnel-dollar": $fa-var-funnel-dollar,
     "camera-retro": $fa-var-camera-retro,
     "circle-arrow-down": $fa-var-circle-arrow-down,
     "arrow-circle-down": $fa-var-arrow-circle-down,
     "comment-pen": $fa-var-comment-pen,
     "comment-edit": $fa-var-comment-edit,
     "file-import": $fa-var-file-import,
     "arrow-right-to-file": $fa-var-arrow-right-to-file,
     "banjo": $fa-var-banjo,
     "square-arrow-up-right": $fa-var-square-arrow-up-right,
     "external-link-square": $fa-var-external-link-square,
     "light-emergency-on": $fa-var-light-emergency-on,
     "kerning": $fa-var-kerning,
     "box-open": $fa-var-box-open,
     "square-f": $fa-var-square-f,
     "scroll": $fa-var-scroll,
     "spa": $fa-var-spa,
     "arrow-left-from-line": $fa-var-arrow-left-from-line,
     "arrow-from-right": $fa-var-arrow-from-right,
     "strawberry": $fa-var-strawberry,
     "location-pin-lock": $fa-var-location-pin-lock,
     "pause": $fa-var-pause,
     "clock-eight-thirty": $fa-var-clock-eight-thirty,
     "plane-engines": $fa-var-plane-engines,
     "plane-alt": $fa-var-plane-alt,
     "hill-avalanche": $fa-var-hill-avalanche,
     "temperature-empty": $fa-var-temperature-empty,
     "temperature-0": $fa-var-temperature-0,
     "thermometer-0": $fa-var-thermometer-0,
     "thermometer-empty": $fa-var-thermometer-empty,
     "bomb": $fa-var-bomb,
     "gauge-low": $fa-var-gauge-low,
     "tachometer-alt-slow": $fa-var-tachometer-alt-slow,
     "registered": $fa-var-registered,
     "trash-can-plus": $fa-var-trash-can-plus,
     "address-card": $fa-var-address-card,
     "contact-card": $fa-var-contact-card,
     "vcard": $fa-var-vcard,
     "scale-unbalanced-flip": $fa-var-scale-unbalanced-flip,
     "balance-scale-right": $fa-var-balance-scale-right,
     "globe-snow": $fa-var-globe-snow,
     "subscript": $fa-var-subscript,
     "diamond-turn-right": $fa-var-diamond-turn-right,
     "directions": $fa-var-directions,
     "integral": $fa-var-integral,
     "burst": $fa-var-burst,
     "house-laptop": $fa-var-house-laptop,
     "laptop-house": $fa-var-laptop-house,
     "face-tired": $fa-var-face-tired,
     "tired": $fa-var-tired,
     "money-bills": $fa-var-money-bills,
     "blinds-raised": $fa-var-blinds-raised,
     "smog": $fa-var-smog,
     "ufo-beam": $fa-var-ufo-beam,
     "circle-caret-up": $fa-var-circle-caret-up,
     "caret-circle-up": $fa-var-caret-circle-up,
     "user-vneck-hair-long": $fa-var-user-vneck-hair-long,
     "square-a-lock": $fa-var-square-a-lock,
     "crutch": $fa-var-crutch,
     "gas-pump-slash": $fa-var-gas-pump-slash,
     "cloud-arrow-up": $fa-var-cloud-arrow-up,
     "cloud-upload": $fa-var-cloud-upload,
     "cloud-upload-alt": $fa-var-cloud-upload-alt,
     "palette": $fa-var-palette,
     "transporter-4": $fa-var-transporter-4,
     "objects-align-right": $fa-var-objects-align-right,
     "arrows-turn-right": $fa-var-arrows-turn-right,
     "vest": $fa-var-vest,
     "pig": $fa-var-pig,
     "inbox-full": $fa-var-inbox-full,
     "circle-envelope": $fa-var-circle-envelope,
     "envelope-circle": $fa-var-envelope-circle,
     "triangle-person-digging": $fa-var-triangle-person-digging,
     "construction": $fa-var-construction,
     "ferry": $fa-var-ferry,
     "bullseye-arrow": $fa-var-bullseye-arrow,
     "arrows-down-to-people": $fa-var-arrows-down-to-people,
     "seedling": $fa-var-seedling,
     "sprout": $fa-var-sprout,
     "clock-seven": $fa-var-clock-seven,
     "left-right": $fa-var-left-right,
     "arrows-alt-h": $fa-var-arrows-alt-h,
     "boxes-packing": $fa-var-boxes-packing,
     "circle-arrow-left": $fa-var-circle-arrow-left,
     "arrow-circle-left": $fa-var-arrow-circle-left,
     "flashlight": $fa-var-flashlight,
     "group-arrows-rotate": $fa-var-group-arrows-rotate,
     "bowl-food": $fa-var-bowl-food,
     "square-9": $fa-var-square-9,
     "candy-cane": $fa-var-candy-cane,
     "arrow-down-wide-short": $fa-var-arrow-down-wide-short,
     "sort-amount-asc": $fa-var-sort-amount-asc,
     "sort-amount-down": $fa-var-sort-amount-down,
     "square-dollar": $fa-var-square-dollar,
     "dollar-square": $fa-var-dollar-square,
     "usd-square": $fa-var-usd-square,
     "phone-arrow-right": $fa-var-phone-arrow-right,
     "hand-holding-seedling": $fa-var-hand-holding-seedling,
     "message-check": $fa-var-message-check,
     "comment-alt-check": $fa-var-comment-alt-check,
     "cloud-bolt": $fa-var-cloud-bolt,
     "thunderstorm": $fa-var-thunderstorm,
     "text-slash": $fa-var-text-slash,
     "remove-format": $fa-var-remove-format,
     "watch": $fa-var-watch,
     "circle-down-left": $fa-var-circle-down-left,
     "text": $fa-var-text,
     "projector": $fa-var-projector,
     "face-smile-wink": $fa-var-face-smile-wink,
     "smile-wink": $fa-var-smile-wink,
     "tombstone-blank": $fa-var-tombstone-blank,
     "tombstone-alt": $fa-var-tombstone-alt,
     "chess-king-piece": $fa-var-chess-king-piece,
     "chess-king-alt": $fa-var-chess-king-alt,
     "circle-6": $fa-var-circle-6,
     "left": $fa-var-left,
     "arrow-alt-left": $fa-var-arrow-alt-left,
     "file-word": $fa-var-file-word,
     "file-powerpoint": $fa-var-file-powerpoint,
     "square-down": $fa-var-square-down,
     "arrow-alt-square-down": $fa-var-arrow-alt-square-down,
     "objects-align-center-vertical": $fa-var-objects-align-center-vertical,
     "arrows-left-right": $fa-var-arrows-left-right,
     "arrows-h": $fa-var-arrows-h,
     "house-lock": $fa-var-house-lock,
     "cloud-arrow-down": $fa-var-cloud-arrow-down,
     "cloud-download": $fa-var-cloud-download,
     "cloud-download-alt": $fa-var-cloud-download-alt,
     "wreath": $fa-var-wreath,
     "children": $fa-var-children,
     "meter-droplet": $fa-var-meter-droplet,
     "chalkboard": $fa-var-chalkboard,
     "blackboard": $fa-var-blackboard,
     "user-large-slash": $fa-var-user-large-slash,
     "user-alt-slash": $fa-var-user-alt-slash,
     "signal-strong": $fa-var-signal-strong,
     "signal-4": $fa-var-signal-4,
     "lollipop": $fa-var-lollipop,
     "lollypop": $fa-var-lollypop,
     "list-tree": $fa-var-list-tree,
     "envelope-open": $fa-var-envelope-open,
     "draw-circle": $fa-var-draw-circle,
     "cat-space": $fa-var-cat-space,
     "handshake-simple-slash": $fa-var-handshake-simple-slash,
     "handshake-alt-slash": $fa-var-handshake-alt-slash,
     "rabbit-running": $fa-var-rabbit-running,
     "rabbit-fast": $fa-var-rabbit-fast,
     "memo-pad": $fa-var-memo-pad,
     "mattress-pillow": $fa-var-mattress-pillow,
     "alarm-plus": $fa-var-alarm-plus,
     "alicorn": $fa-var-alicorn,
     "comment-question": $fa-var-comment-question,
     "gingerbread-man": $fa-var-gingerbread-man,
     "guarani-sign": $fa-var-guarani-sign,
     "burger-fries": $fa-var-burger-fries,
     "mug-tea": $fa-var-mug-tea,
     "border-top": $fa-var-border-top,
     "arrows-rotate": $fa-var-arrows-rotate,
     "refresh": $fa-var-refresh,
     "sync": $fa-var-sync,
     "circle-book-open": $fa-var-circle-book-open,
     "book-circle": $fa-var-book-circle,
     "arrows-to-dotted-line": $fa-var-arrows-to-dotted-line,
     "fire-extinguisher": $fa-var-fire-extinguisher,
     "garage-open": $fa-var-garage-open,
     "shelves-empty": $fa-var-shelves-empty,
     "cruzeiro-sign": $fa-var-cruzeiro-sign,
     "watch-apple": $fa-var-watch-apple,
     "watch-calculator": $fa-var-watch-calculator,
     "list-dropdown": $fa-var-list-dropdown,
     "cabinet-filing": $fa-var-cabinet-filing,
     "burger-soda": $fa-var-burger-soda,
     "square-arrow-up": $fa-var-square-arrow-up,
     "arrow-square-up": $fa-var-arrow-square-up,
     "greater-than-equal": $fa-var-greater-than-equal,
     "pallet-box": $fa-var-pallet-box,
     "face-confounded": $fa-var-face-confounded,
     "shield-halved": $fa-var-shield-halved,
     "shield-alt": $fa-var-shield-alt,
     "truck-plow": $fa-var-truck-plow,
     "book-atlas": $fa-var-book-atlas,
     "atlas": $fa-var-atlas,
     "virus": $fa-var-virus,
     "comment-middle-top": $fa-var-comment-middle-top,
     "envelope-circle-check": $fa-var-envelope-circle-check,
     "layer-group": $fa-var-layer-group,
     "restroom-simple": $fa-var-restroom-simple,
     "arrows-to-dot": $fa-var-arrows-to-dot,
     "border-outer": $fa-var-border-outer,
     "hashtag-lock": $fa-var-hashtag-lock,
     "clock-two-thirty": $fa-var-clock-two-thirty,
     "archway": $fa-var-archway,
     "heart-circle-check": $fa-var-heart-circle-check,
     "house-chimney-crack": $fa-var-house-chimney-crack,
     "house-damage": $fa-var-house-damage,
     "file-zipper": $fa-var-file-zipper,
     "file-archive": $fa-var-file-archive,
     "heart-half": $fa-var-heart-half,
     "comment-check": $fa-var-comment-check,
     "square": $fa-var-square,
     "memo": $fa-var-memo,
     "martini-glass-empty": $fa-var-martini-glass-empty,
     "glass-martini": $fa-var-glass-martini,
     "couch": $fa-var-couch,
     "cedi-sign": $fa-var-cedi-sign,
     "italic": $fa-var-italic,
     "glass-citrus": $fa-var-glass-citrus,
     "calendar-lines-pen": $fa-var-calendar-lines-pen,
     "church": $fa-var-church,
     "person-snowmobiling": $fa-var-person-snowmobiling,
     "snowmobile": $fa-var-snowmobile,
     "face-hushed": $fa-var-face-hushed,
     "comments-dollar": $fa-var-comments-dollar,
     "pickaxe": $fa-var-pickaxe,
     "link-simple-slash": $fa-var-link-simple-slash,
     "democrat": $fa-var-democrat,
     "face-confused": $fa-var-face-confused,
     "pinball": $fa-var-pinball,
     "z": $fa-var-z,
     "person-skiing": $fa-var-person-skiing,
     "skiing": $fa-var-skiing,
     "deer": $fa-var-deer,
     "input-pipe": $fa-var-input-pipe,
     "road-lock": $fa-var-road-lock,
     "a": $fa-var-a,
     "bookmark-slash": $fa-var-bookmark-slash,
     "temperature-arrow-down": $fa-var-temperature-arrow-down,
     "temperature-down": $fa-var-temperature-down,
     "mace": $fa-var-mace,
     "feather-pointed": $fa-var-feather-pointed,
     "feather-alt": $fa-var-feather-alt,
     "sausage": $fa-var-sausage,
     "trash-can-clock": $fa-var-trash-can-clock,
     "p": $fa-var-p,
     "snowflake": $fa-var-snowflake,
     "stomach": $fa-var-stomach,
     "newspaper": $fa-var-newspaper,
     "rectangle-ad": $fa-var-rectangle-ad,
     "ad": $fa-var-ad,
     "guitar-electric": $fa-var-guitar-electric,
     "arrow-turn-down-right": $fa-var-arrow-turn-down-right,
     "moon-cloud": $fa-var-moon-cloud,
     "bread-slice-butter": $fa-var-bread-slice-butter,
     "circle-arrow-right": $fa-var-circle-arrow-right,
     "arrow-circle-right": $fa-var-arrow-circle-right,
     "user-group-crown": $fa-var-user-group-crown,
     "users-crown": $fa-var-users-crown,
     "circle-i": $fa-var-circle-i,
     "toilet-paper-check": $fa-var-toilet-paper-check,
     "filter-circle-xmark": $fa-var-filter-circle-xmark,
     "locust": $fa-var-locust,
     "sort": $fa-var-sort,
     "unsorted": $fa-var-unsorted,
     "list-ol": $fa-var-list-ol,
     "list-1-2": $fa-var-list-1-2,
     "list-numeric": $fa-var-list-numeric,
     "chart-waterfall": $fa-var-chart-waterfall,
     "face-party": $fa-var-face-party,
     "kidneys": $fa-var-kidneys,
     "wifi-exclamation": $fa-var-wifi-exclamation,
     "chart-network": $fa-var-chart-network,
     "person-dress-burst": $fa-var-person-dress-burst,
     "dice-d4": $fa-var-dice-d4,
     "money-check-dollar": $fa-var-money-check-dollar,
     "money-check-alt": $fa-var-money-check-alt,
     "vector-square": $fa-var-vector-square,
     "bread-slice": $fa-var-bread-slice,
     "language": $fa-var-language,
     "wheat-awn-slash": $fa-var-wheat-awn-slash,
     "face-kiss-wink-heart": $fa-var-face-kiss-wink-heart,
     "kiss-wink-heart": $fa-var-kiss-wink-heart,
     "dagger": $fa-var-dagger,
     "podium": $fa-var-podium,
     "memo-circle-check": $fa-var-memo-circle-check,
     "route-highway": $fa-var-route-highway,
     "down-to-line": $fa-var-down-to-line,
     "arrow-alt-to-bottom": $fa-var-arrow-alt-to-bottom,
     "filter": $fa-var-filter,
     "square-g": $fa-var-square-g,
     "circle-phone": $fa-var-circle-phone,
     "phone-circle": $fa-var-phone-circle,
     "clipboard-prescription": $fa-var-clipboard-prescription,
     "user-nurse-hair": $fa-var-user-nurse-hair,
     "question": $fa-var-question,
     "file-signature": $fa-var-file-signature,
     "toggle-large-on": $fa-var-toggle-large-on,
     "up-down-left-right": $fa-var-up-down-left-right,
     "arrows-alt": $fa-var-arrows-alt,
     "dryer-heat": $fa-var-dryer-heat,
     "dryer-alt": $fa-var-dryer-alt,
     "house-chimney-user": $fa-var-house-chimney-user,
     "hand-holding-heart": $fa-var-hand-holding-heart,
     "arrow-up-small-big": $fa-var-arrow-up-small-big,
     "sort-size-up-alt": $fa-var-sort-size-up-alt,
     "train-track": $fa-var-train-track,
     "puzzle-piece": $fa-var-puzzle-piece,
     "money-check": $fa-var-money-check,
     "star-half-stroke": $fa-var-star-half-stroke,
     "star-half-alt": $fa-var-star-half-alt,
     "file-exclamation": $fa-var-file-exclamation,
     "code": $fa-var-code,
     "whiskey-glass": $fa-var-whiskey-glass,
     "glass-whiskey": $fa-var-glass-whiskey,
     "moon-stars": $fa-var-moon-stars,
     "building-circle-exclamation": $fa-var-building-circle-exclamation,
     "clothes-hanger": $fa-var-clothes-hanger,
     "mobile-notch": $fa-var-mobile-notch,
     "mobile-iphone": $fa-var-mobile-iphone,
     "magnifying-glass-chart": $fa-var-magnifying-glass-chart,
     "arrow-up-right-from-square": $fa-var-arrow-up-right-from-square,
     "external-link": $fa-var-external-link,
     "cubes-stacked": $fa-var-cubes-stacked,
     "images-user": $fa-var-images-user,
     "won-sign": $fa-var-won-sign,
     "krw": $fa-var-krw,
     "won": $fa-var-won,
     "image-polaroid-user": $fa-var-image-polaroid-user,
     "virus-covid": $fa-var-virus-covid,
     "square-ellipsis": $fa-var-square-ellipsis,
     "pie": $fa-var-pie,
     "chess-knight-piece": $fa-var-chess-knight-piece,
     "chess-knight-alt": $fa-var-chess-knight-alt,
     "austral-sign": $fa-var-austral-sign,
     "cloud-plus": $fa-var-cloud-plus,
     "f": $fa-var-f,
     "leaf": $fa-var-leaf,
     "bed-bunk": $fa-var-bed-bunk,
     "road": $fa-var-road,
     "taxi": $fa-var-taxi,
     "cab": $fa-var-cab,
     "person-circle-plus": $fa-var-person-circle-plus,
     "chart-pie": $fa-var-chart-pie,
     "pie-chart": $fa-var-pie-chart,
     "bolt-lightning": $fa-var-bolt-lightning,
     "clock-eight": $fa-var-clock-eight,
     "sack-xmark": $fa-var-sack-xmark,
     "file-excel": $fa-var-file-excel,
     "file-contract": $fa-var-file-contract,
     "fish-fins": $fa-var-fish-fins,
     "circle-q": $fa-var-circle-q,
     "building-flag": $fa-var-building-flag,
     "face-grin-beam": $fa-var-face-grin-beam,
     "grin-beam": $fa-var-grin-beam,
     "object-ungroup": $fa-var-object-ungroup,
     "face-disguise": $fa-var-face-disguise,
     "circle-arrow-down-right": $fa-var-circle-arrow-down-right,
     "alien-8bit": $fa-var-alien-8bit,
     "alien-monster": $fa-var-alien-monster,
     "hand-point-ribbon": $fa-var-hand-point-ribbon,
     "poop": $fa-var-poop,
     "object-exclude": $fa-var-object-exclude,
     "telescope": $fa-var-telescope,
     "location-pin": $fa-var-location-pin,
     "map-marker": $fa-var-map-marker,
     "square-list": $fa-var-square-list,
     "kaaba": $fa-var-kaaba,
     "toilet-paper": $fa-var-toilet-paper,
     "helmet-safety": $fa-var-helmet-safety,
     "hard-hat": $fa-var-hard-hat,
     "hat-hard": $fa-var-hat-hard,
     "comment-code": $fa-var-comment-code,
     "sim-cards": $fa-var-sim-cards,
     "starship": $fa-var-starship,
     "eject": $fa-var-eject,
     "circle-right": $fa-var-circle-right,
     "arrow-alt-circle-right": $fa-var-arrow-alt-circle-right,
     "plane-circle-check": $fa-var-plane-circle-check,
     "seal": $fa-var-seal,
     "user-cowboy": $fa-var-user-cowboy,
     "hexagon-vertical-nft": $fa-var-hexagon-vertical-nft,
     "face-rolling-eyes": $fa-var-face-rolling-eyes,
     "meh-rolling-eyes": $fa-var-meh-rolling-eyes,
     "bread-loaf": $fa-var-bread-loaf,
     "rings-wedding": $fa-var-rings-wedding,
     "object-group": $fa-var-object-group,
     "french-fries": $fa-var-french-fries,
     "chart-line": $fa-var-chart-line,
     "line-chart": $fa-var-line-chart,
     "calendar-arrow-down": $fa-var-calendar-arrow-down,
     "calendar-download": $fa-var-calendar-download,
     "send-back": $fa-var-send-back,
     "mask-ventilator": $fa-var-mask-ventilator,
     "signature-lock": $fa-var-signature-lock,
     "arrow-right": $fa-var-arrow-right,
     "signs-post": $fa-var-signs-post,
     "map-signs": $fa-var-map-signs,
     "octagon-plus": $fa-var-octagon-plus,
     "plus-octagon": $fa-var-plus-octagon,
     "cash-register": $fa-var-cash-register,
     "person-circle-question": $fa-var-person-circle-question,
     "melon-slice": $fa-var-melon-slice,
     "space-station-moon": $fa-var-space-station-moon,
     "message-smile": $fa-var-message-smile,
     "comment-alt-smile": $fa-var-comment-alt-smile,
     "cup-straw": $fa-var-cup-straw,
     "left-from-line": $fa-var-left-from-line,
     "arrow-alt-from-right": $fa-var-arrow-alt-from-right,
     "h": $fa-var-h,
     "basket-shopping-simple": $fa-var-basket-shopping-simple,
     "shopping-basket-alt": $fa-var-shopping-basket-alt,
     "hands-holding-heart": $fa-var-hands-holding-heart,
     "hands-heart": $fa-var-hands-heart,
     "clock-nine": $fa-var-clock-nine,
     "tarp": $fa-var-tarp,
     "face-sleepy": $fa-var-face-sleepy,
     "hand-horns": $fa-var-hand-horns,
     "screwdriver-wrench": $fa-var-screwdriver-wrench,
     "tools": $fa-var-tools,
     "arrows-to-eye": $fa-var-arrows-to-eye,
     "circle-three-quarters": $fa-var-circle-three-quarters,
     "trophy-star": $fa-var-trophy-star,
     "trophy-alt": $fa-var-trophy-alt,
     "plug-circle-bolt": $fa-var-plug-circle-bolt,
     "face-thermometer": $fa-var-face-thermometer,
     "shirt-running": $fa-var-shirt-running,
     "book-circle-arrow-up": $fa-var-book-circle-arrow-up,
     "face-nauseated": $fa-var-face-nauseated,
     "heart": $fa-var-heart,
     "file-chart-pie": $fa-var-file-chart-pie,
     "mars-and-venus": $fa-var-mars-and-venus,
     "house-user": $fa-var-house-user,
     "home-user": $fa-var-home-user,
     "circle-arrow-down-left": $fa-var-circle-arrow-down-left,
     "dumpster-fire": $fa-var-dumpster-fire,
     "hexagon-minus": $fa-var-hexagon-minus,
     "minus-hexagon": $fa-var-minus-hexagon,
     "left-to-line": $fa-var-left-to-line,
     "arrow-alt-to-left": $fa-var-arrow-alt-to-left,
     "house-crack": $fa-var-house-crack,
     "paw-simple": $fa-var-paw-simple,
     "paw-alt": $fa-var-paw-alt,
     "arrow-left-long-to-line": $fa-var-arrow-left-long-to-line,
     "brackets-round": $fa-var-brackets-round,
     "parentheses": $fa-var-parentheses,
     "martini-glass-citrus": $fa-var-martini-glass-citrus,
     "cocktail": $fa-var-cocktail,
     "user-shakespeare": $fa-var-user-shakespeare,
     "arrow-right-to-arc": $fa-var-arrow-right-to-arc,
     "face-surprise": $fa-var-face-surprise,
     "surprise": $fa-var-surprise,
     "bottle-water": $fa-var-bottle-water,
     "circle-pause": $fa-var-circle-pause,
     "pause-circle": $fa-var-pause-circle,
     "gauge-circle-plus": $fa-var-gauge-circle-plus,
     "folders": $fa-var-folders,
     "angel": $fa-var-angel,
     "value-absolute": $fa-var-value-absolute,
     "rabbit": $fa-var-rabbit,
     "toilet-paper-slash": $fa-var-toilet-paper-slash,
     "apple-whole": $fa-var-apple-whole,
     "apple-alt": $fa-var-apple-alt,
     "kitchen-set": $fa-var-kitchen-set,
     "diamond-half": $fa-var-diamond-half,
     "lock-keyhole": $fa-var-lock-keyhole,
     "lock-alt": $fa-var-lock-alt,
     "r": $fa-var-r,
     "temperature-quarter": $fa-var-temperature-quarter,
     "temperature-1": $fa-var-temperature-1,
     "thermometer-1": $fa-var-thermometer-1,
     "thermometer-quarter": $fa-var-thermometer-quarter,
     "square-info": $fa-var-square-info,
     "info-square": $fa-var-info-square,
     "wifi-slash": $fa-var-wifi-slash,
     "toilet-paper-xmark": $fa-var-toilet-paper-xmark,
     "hands-holding-dollar": $fa-var-hands-holding-dollar,
     "hands-usd": $fa-var-hands-usd,
     "cube": $fa-var-cube,
     "arrow-down-triangle-square": $fa-var-arrow-down-triangle-square,
     "sort-shapes-down": $fa-var-sort-shapes-down,
     "bitcoin-sign": $fa-var-bitcoin-sign,
     "shutters": $fa-var-shutters,
     "shield-dog": $fa-var-shield-dog,
     "solar-panel": $fa-var-solar-panel,
     "lock-open": $fa-var-lock-open,
     "table-tree": $fa-var-table-tree,
     "house-chimney-heart": $fa-var-house-chimney-heart,
     "tally-3": $fa-var-tally-3,
     "elevator": $fa-var-elevator,
     "money-bill-transfer": $fa-var-money-bill-transfer,
     "money-bill-trend-up": $fa-var-money-bill-trend-up,
     "house-flood-water-circle-arrow-right": $fa-var-house-flood-water-circle-arrow-right,
     "square-poll-horizontal": $fa-var-square-poll-horizontal,
     "poll-h": $fa-var-poll-h,
     "circle": $fa-var-circle,
     "cart-circle-exclamation": $fa-var-cart-circle-exclamation,
     "sword": $fa-var-sword,
     "backward-fast": $fa-var-backward-fast,
     "fast-backward": $fa-var-fast-backward,
     "recycle": $fa-var-recycle,
     "user-astronaut": $fa-var-user-astronaut,
     "interrobang": $fa-var-interrobang,
     "plane-slash": $fa-var-plane-slash,
     "circle-dashed": $fa-var-circle-dashed,
     "trademark": $fa-var-trademark,
     "basketball": $fa-var-basketball,
     "basketball-ball": $fa-var-basketball-ball,
     "fork-knife": $fa-var-fork-knife,
     "utensils-alt": $fa-var-utensils-alt,
     "satellite-dish": $fa-var-satellite-dish,
     "badge-check": $fa-var-badge-check,
     "circle-up": $fa-var-circle-up,
     "arrow-alt-circle-up": $fa-var-arrow-alt-circle-up,
     "slider": $fa-var-slider,
     "mobile-screen-button": $fa-var-mobile-screen-button,
     "mobile-alt": $fa-var-mobile-alt,
     "clock-one-thirty": $fa-var-clock-one-thirty,
     "inbox-out": $fa-var-inbox-out,
     "inbox-arrow-up": $fa-var-inbox-arrow-up,
     "cloud-slash": $fa-var-cloud-slash,
     "volume-high": $fa-var-volume-high,
     "volume-up": $fa-var-volume-up,
     "users-rays": $fa-var-users-rays,
     "wallet": $fa-var-wallet,
     "octagon-check": $fa-var-octagon-check,
     "flatbread-stuffed": $fa-var-flatbread-stuffed,
     "clipboard-check": $fa-var-clipboard-check,
     "cart-circle-plus": $fa-var-cart-circle-plus,
     "truck-clock": $fa-var-truck-clock,
     "shipping-timed": $fa-var-shipping-timed,
     "pool-8-ball": $fa-var-pool-8-ball,
     "file-audio": $fa-var-file-audio,
     "turn-down-left": $fa-var-turn-down-left,
     "lock-hashtag": $fa-var-lock-hashtag,
     "chart-radar": $fa-var-chart-radar,
     "staff": $fa-var-staff,
     "burger": $fa-var-burger,
     "hamburger": $fa-var-hamburger,
     "utility-pole": $fa-var-utility-pole,
     "transporter-6": $fa-var-transporter-6,
     "wrench": $fa-var-wrench,
     "bugs": $fa-var-bugs,
     "vector-polygon": $fa-var-vector-polygon,
     "diagram-nested": $fa-var-diagram-nested,
     "rupee-sign": $fa-var-rupee-sign,
     "rupee": $fa-var-rupee,
     "file-image": $fa-var-file-image,
     "circle-question": $fa-var-circle-question,
     "question-circle": $fa-var-question-circle,
     "image-user": $fa-var-image-user,
     "buoy": $fa-var-buoy,
     "plane-departure": $fa-var-plane-departure,
     "handshake-slash": $fa-var-handshake-slash,
     "book-bookmark": $fa-var-book-bookmark,
     "border-center-h": $fa-var-border-center-h,
     "can-food": $fa-var-can-food,
     "typewriter": $fa-var-typewriter,
     "arrow-right-from-arc": $fa-var-arrow-right-from-arc,
     "circle-k": $fa-var-circle-k,
     "face-hand-over-mouth": $fa-var-face-hand-over-mouth,
     "popcorn": $fa-var-popcorn,
     "house-water": $fa-var-house-water,
     "house-flood": $fa-var-house-flood,
     "object-subtract": $fa-var-object-subtract,
     "code-branch": $fa-var-code-branch,
     "warehouse-full": $fa-var-warehouse-full,
     "warehouse-alt": $fa-var-warehouse-alt,
     "hat-cowboy": $fa-var-hat-cowboy,
     "bridge": $fa-var-bridge,
     "phone-flip": $fa-var-phone-flip,
     "phone-alt": $fa-var-phone-alt,
     "arrow-down-from-dotted-line": $fa-var-arrow-down-from-dotted-line,
     "square-quarters": $fa-var-square-quarters,
     "truck-front": $fa-var-truck-front,
     "cat": $fa-var-cat,
     "trash-xmark": $fa-var-trash-xmark,
     "circle-caret-left": $fa-var-circle-caret-left,
     "caret-circle-left": $fa-var-caret-circle-left,
     "files": $fa-var-files,
     "anchor-circle-exclamation": $fa-var-anchor-circle-exclamation,
     "face-clouds": $fa-var-face-clouds,
     "user-crown": $fa-var-user-crown,
     "truck-field": $fa-var-truck-field,
     "route": $fa-var-route,
     "cart-circle-check": $fa-var-cart-circle-check,
     "clipboard-question": $fa-var-clipboard-question,
     "panorama": $fa-var-panorama,
     "comment-medical": $fa-var-comment-medical,
     "teeth-open": $fa-var-teeth-open,
     "user-tie-hair-long": $fa-var-user-tie-hair-long,
     "file-circle-minus": $fa-var-file-circle-minus,
     "head-side-medical": $fa-var-head-side-medical,
     "tags": $fa-var-tags,
     "wine-glass": $fa-var-wine-glass,
     "forward-fast": $fa-var-forward-fast,
     "fast-forward": $fa-var-fast-forward,
     "face-meh-blank": $fa-var-face-meh-blank,
     "meh-blank": $fa-var-meh-blank,
     "user-robot": $fa-var-user-robot,
     "square-parking": $fa-var-square-parking,
     "parking": $fa-var-parking,
     "card-diamond": $fa-var-card-diamond,
     "face-zipper": $fa-var-face-zipper,
     "face-raised-eyebrow": $fa-var-face-raised-eyebrow,
     "house-signal": $fa-var-house-signal,
     "square-chevron-up": $fa-var-square-chevron-up,
     "chevron-square-up": $fa-var-chevron-square-up,
     "bars-progress": $fa-var-bars-progress,
     "tasks-alt": $fa-var-tasks-alt,
     "faucet-drip": $fa-var-faucet-drip,
     "arrows-to-line": $fa-var-arrows-to-line,
     "dolphin": $fa-var-dolphin,
     "arrow-up-right": $fa-var-arrow-up-right,
     "circle-r": $fa-var-circle-r,
     "cart-flatbed": $fa-var-cart-flatbed,
     "dolly-flatbed": $fa-var-dolly-flatbed,
     "ban-smoking": $fa-var-ban-smoking,
     "smoking-ban": $fa-var-smoking-ban,
     "circle-sort-up": $fa-var-circle-sort-up,
     "sort-circle-up": $fa-var-sort-circle-up,
     "terminal": $fa-var-terminal,
     "mobile-button": $fa-var-mobile-button,
     "house-medical-flag": $fa-var-house-medical-flag,
     "basket-shopping": $fa-var-basket-shopping,
     "shopping-basket": $fa-var-shopping-basket,
     "tape": $fa-var-tape,
     "chestnut": $fa-var-chestnut,
     "bus-simple": $fa-var-bus-simple,
     "bus-alt": $fa-var-bus-alt,
     "eye": $fa-var-eye,
     "face-sad-cry": $fa-var-face-sad-cry,
     "sad-cry": $fa-var-sad-cry,
     "heat": $fa-var-heat,
     "ticket-airline": $fa-var-ticket-airline,
     "boot-heeled": $fa-var-boot-heeled,
     "arrows-minimize": $fa-var-arrows-minimize,
     "compress-arrows": $fa-var-compress-arrows,
     "audio-description": $fa-var-audio-description,
     "person-military-to-person": $fa-var-person-military-to-person,
     "file-shield": $fa-var-file-shield,
     "hexagon": $fa-var-hexagon,
     "manhole": $fa-var-manhole,
     "user-slash": $fa-var-user-slash,
     "pen": $fa-var-pen,
     "tower-observation": $fa-var-tower-observation,
     "floppy-disks": $fa-var-floppy-disks,
     "toilet-paper-blank-under": $fa-var-toilet-paper-blank-under,
     "toilet-paper-reverse-alt": $fa-var-toilet-paper-reverse-alt,
     "file-code": $fa-var-file-code,
     "signal": $fa-var-signal,
     "signal-5": $fa-var-signal-5,
     "signal-perfect": $fa-var-signal-perfect,
     "pump": $fa-var-pump,
     "bus": $fa-var-bus,
     "heart-circle-xmark": $fa-var-heart-circle-xmark,
     "arrow-up-left-from-circle": $fa-var-arrow-up-left-from-circle,
     "house-chimney": $fa-var-house-chimney,
     "home-lg": $fa-var-home-lg,
     "window-maximize": $fa-var-window-maximize,
     "dryer": $fa-var-dryer,
     "face-frown": $fa-var-face-frown,
     "frown": $fa-var-frown,
     "chess-bishop-piece": $fa-var-chess-bishop-piece,
     "chess-bishop-alt": $fa-var-chess-bishop-alt,
     "shirt-tank-top": $fa-var-shirt-tank-top,
     "diploma": $fa-var-diploma,
     "scroll-ribbon": $fa-var-scroll-ribbon,
     "screencast": $fa-var-screencast,
     "walker": $fa-var-walker,
     "prescription": $fa-var-prescription,
     "shop": $fa-var-shop,
     "store-alt": $fa-var-store-alt,
     "floppy-disk": $fa-var-floppy-disk,
     "save": $fa-var-save,
     "vihara": $fa-var-vihara,
     "face-kiss-closed-eyes": $fa-var-face-kiss-closed-eyes,
     "scale-unbalanced": $fa-var-scale-unbalanced,
     "balance-scale-left": $fa-var-balance-scale-left,
     "file-user": $fa-var-file-user,
     "user-police-tie": $fa-var-user-police-tie,
     "face-tongue-money": $fa-var-face-tongue-money,
     "tennis-ball": $fa-var-tennis-ball,
     "square-l": $fa-var-square-l,
     "sort-up": $fa-var-sort-up,
     "sort-asc": $fa-var-sort-asc,
     "calendar-arrow-up": $fa-var-calendar-arrow-up,
     "calendar-upload": $fa-var-calendar-upload,
     "comment-dots": $fa-var-comment-dots,
     "commenting": $fa-var-commenting,
     "plant-wilt": $fa-var-plant-wilt,
     "scarf": $fa-var-scarf,
     "album-circle-plus": $fa-var-album-circle-plus,
     "user-nurse-hair-long": $fa-var-user-nurse-hair-long,
     "diamond": $fa-var-diamond,
     "square-left": $fa-var-square-left,
     "arrow-alt-square-left": $fa-var-arrow-alt-square-left,
     "face-grin-squint": $fa-var-face-grin-squint,
     "grin-squint": $fa-var-grin-squint,
     "circle-ellipsis-vertical": $fa-var-circle-ellipsis-vertical,
     "hand-holding-dollar": $fa-var-hand-holding-dollar,
     "hand-holding-usd": $fa-var-hand-holding-usd,
     "grid-dividers": $fa-var-grid-dividers,
     "bacterium": $fa-var-bacterium,
     "hand-pointer": $fa-var-hand-pointer,
     "drum-steelpan": $fa-var-drum-steelpan,
     "hand-scissors": $fa-var-hand-scissors,
     "hands-praying": $fa-var-hands-praying,
     "praying-hands": $fa-var-praying-hands,
     "face-pensive": $fa-var-face-pensive,
     "user-music": $fa-var-user-music,
     "arrow-rotate-right": $fa-var-arrow-rotate-right,
     "arrow-right-rotate": $fa-var-arrow-right-rotate,
     "arrow-rotate-forward": $fa-var-arrow-rotate-forward,
     "redo": $fa-var-redo,
     "messages-dollar": $fa-var-messages-dollar,
     "comments-alt-dollar": $fa-var-comments-alt-dollar,
     "sensor-on": $fa-var-sensor-on,
     "balloon": $fa-var-balloon,
     "biohazard": $fa-var-biohazard,
     "chess-queen-piece": $fa-var-chess-queen-piece,
     "chess-queen-alt": $fa-var-chess-queen-alt,
     "location-crosshairs": $fa-var-location-crosshairs,
     "location": $fa-var-location,
     "mars-double": $fa-var-mars-double,
     "house-person-leave": $fa-var-house-person-leave,
     "house-leave": $fa-var-house-leave,
     "house-person-depart": $fa-var-house-person-depart,
     "ruler-triangle": $fa-var-ruler-triangle,
     "card-club": $fa-var-card-club,
     "child-dress": $fa-var-child-dress,
     "users-between-lines": $fa-var-users-between-lines,
     "lungs-virus": $fa-var-lungs-virus,
     "spinner-third": $fa-var-spinner-third,
     "face-grin-tears": $fa-var-face-grin-tears,
     "grin-tears": $fa-var-grin-tears,
     "phone": $fa-var-phone,
     "computer-mouse-scrollwheel": $fa-var-computer-mouse-scrollwheel,
     "mouse-alt": $fa-var-mouse-alt,
     "calendar-xmark": $fa-var-calendar-xmark,
     "calendar-times": $fa-var-calendar-times,
     "child-reaching": $fa-var-child-reaching,
     "table-layout": $fa-var-table-layout,
     "narwhal": $fa-var-narwhal,
     "ramp-loading": $fa-var-ramp-loading,
     "calendar-circle-plus": $fa-var-calendar-circle-plus,
     "toothbrush": $fa-var-toothbrush,
     "border-inner": $fa-var-border-inner,
     "paw-claws": $fa-var-paw-claws,
     "kiwi-fruit": $fa-var-kiwi-fruit,
     "traffic-light-slow": $fa-var-traffic-light-slow,
     "rectangle-code": $fa-var-rectangle-code,
     "head-side-virus": $fa-var-head-side-virus,
     "keyboard-brightness": $fa-var-keyboard-brightness,
     "books-medical": $fa-var-books-medical,
     "lightbulb-slash": $fa-var-lightbulb-slash,
     "house-blank": $fa-var-house-blank,
     "home-blank": $fa-var-home-blank,
     "square-5": $fa-var-square-5,
     "square-heart": $fa-var-square-heart,
     "heart-square": $fa-var-heart-square,
     "puzzle": $fa-var-puzzle,
     "user-gear": $fa-var-user-gear,
     "user-cog": $fa-var-user-cog,
     "pipe-circle-check": $fa-var-pipe-circle-check,
     "arrow-up-1-9": $fa-var-arrow-up-1-9,
     "sort-numeric-up": $fa-var-sort-numeric-up,
     "octagon-exclamation": $fa-var-octagon-exclamation,
     "dial-low": $fa-var-dial-low,
     "door-closed": $fa-var-door-closed,
     "laptop-mobile": $fa-var-laptop-mobile,
     "phone-laptop": $fa-var-phone-laptop,
     "conveyor-belt-boxes": $fa-var-conveyor-belt-boxes,
     "conveyor-belt-alt": $fa-var-conveyor-belt-alt,
     "shield-virus": $fa-var-shield-virus,
     "starfighter-twin-ion-engine-advanced": $fa-var-starfighter-twin-ion-engine-advanced,
     "starfighter-alt-advanced": $fa-var-starfighter-alt-advanced,
     "dice-six": $fa-var-dice-six,
     "starfighter-twin-ion-engine": $fa-var-starfighter-twin-ion-engine,
     "starfighter-alt": $fa-var-starfighter-alt,
     "rocket-launch": $fa-var-rocket-launch,
     "mosquito-net": $fa-var-mosquito-net,
     "vent-damper": $fa-var-vent-damper,
     "bridge-water": $fa-var-bridge-water,
     "ban-bug": $fa-var-ban-bug,
     "debug": $fa-var-debug,
     "person-booth": $fa-var-person-booth,
     "text-width": $fa-var-text-width,
     "garage-car": $fa-var-garage-car,
     "square-kanban": $fa-var-square-kanban,
     "hat-wizard": $fa-var-hat-wizard,
     "pen-fancy": $fa-var-pen-fancy,
     "coffee-pot": $fa-var-coffee-pot,
     "mouse-field": $fa-var-mouse-field,
     "person-digging": $fa-var-person-digging,
     "digging": $fa-var-digging,
     "shower-down": $fa-var-shower-down,
     "shower-alt": $fa-var-shower-alt,
     "box-circle-check": $fa-var-box-circle-check,
     "brightness": $fa-var-brightness,
     "car-side-bolt": $fa-var-car-side-bolt,
     "ornament": $fa-var-ornament,
     "phone-arrow-down-left": $fa-var-phone-arrow-down-left,
     "phone-arrow-down": $fa-var-phone-arrow-down,
     "phone-incoming": $fa-var-phone-incoming,
     "cloud-word": $fa-var-cloud-word,
     "hand-fingers-crossed": $fa-var-hand-fingers-crossed,
     "trash": $fa-var-trash,
     "gauge-simple": $fa-var-gauge-simple,
     "gauge-simple-med": $fa-var-gauge-simple-med,
     "tachometer-average": $fa-var-tachometer-average,
     "arrow-down-small-big": $fa-var-arrow-down-small-big,
     "sort-size-down-alt": $fa-var-sort-size-down-alt,
     "book-medical": $fa-var-book-medical,
     "face-melting": $fa-var-face-melting,
     "poo": $fa-var-poo,
     "pen-clip-slash": $fa-var-pen-clip-slash,
     "pen-alt-slash": $fa-var-pen-alt-slash,
     "quote-right": $fa-var-quote-right,
     "quote-right-alt": $fa-var-quote-right-alt,
     "scroll-old": $fa-var-scroll-old,
     "guitars": $fa-var-guitars,
     "phone-xmark": $fa-var-phone-xmark,
     "hose": $fa-var-hose,
     "clock-six": $fa-var-clock-six,
     "shirt": $fa-var-shirt,
     "t-shirt": $fa-var-t-shirt,
     "tshirt": $fa-var-tshirt,
     "square-r": $fa-var-square-r,
     "cubes": $fa-var-cubes,
     "envelope-open-dollar": $fa-var-envelope-open-dollar,
     "divide": $fa-var-divide,
     "sun-cloud": $fa-var-sun-cloud,
     "lamp-floor": $fa-var-lamp-floor,
     "square-7": $fa-var-square-7,
     "tenge-sign": $fa-var-tenge-sign,
     "tenge": $fa-var-tenge,
     "headphones": $fa-var-headphones,
     "hands-holding": $fa-var-hands-holding,
     "campfire": $fa-var-campfire,
     "circle-ampersand": $fa-var-circle-ampersand,
     "snowflakes": $fa-var-snowflakes,
     "hands-clapping": $fa-var-hands-clapping,
     "republican": $fa-var-republican,
     "leaf-maple": $fa-var-leaf-maple,
     "arrow-left": $fa-var-arrow-left,
     "person-circle-xmark": $fa-var-person-circle-xmark,
     "ruler": $fa-var-ruler,
     "cup-straw-swoosh": $fa-var-cup-straw-swoosh,
     "temperature-sun": $fa-var-temperature-sun,
     "temperature-hot": $fa-var-temperature-hot,
     "align-left": $fa-var-align-left,
     "dice-d6": $fa-var-dice-d6,
     "restroom": $fa-var-restroom,
     "high-definition": $fa-var-high-definition,
     "rectangle-hd": $fa-var-rectangle-hd,
     "j": $fa-var-j,
     "galaxy": $fa-var-galaxy,
     "users-viewfinder": $fa-var-users-viewfinder,
     "file-video": $fa-var-file-video,
     "cherries": $fa-var-cherries,
     "up-right-from-square": $fa-var-up-right-from-square,
     "external-link-alt": $fa-var-external-link-alt,
     "circle-sort": $fa-var-circle-sort,
     "sort-circle": $fa-var-sort-circle,
     "table-cells": $fa-var-table-cells,
     "th": $fa-var-th,
     "file-pdf": $fa-var-file-pdf,
     "siren": $fa-var-siren,
     "arrow-up-to-dotted-line": $fa-var-arrow-up-to-dotted-line,
     "image-landscape": $fa-var-image-landscape,
     "landscape": $fa-var-landscape,
     "tank-water": $fa-var-tank-water,
     "curling-stone": $fa-var-curling-stone,
     "curling": $fa-var-curling,
     "gamepad-modern": $fa-var-gamepad-modern,
     "gamepad-alt": $fa-var-gamepad-alt,
     "messages-question": $fa-var-messages-question,
     "book-bible": $fa-var-book-bible,
     "bible": $fa-var-bible,
     "o": $fa-var-o,
     "suitcase-medical": $fa-var-suitcase-medical,
     "medkit": $fa-var-medkit,
     "briefcase-arrow-right": $fa-var-briefcase-arrow-right,
     "expand-wide": $fa-var-expand-wide,
     "clock-eleven-thirty": $fa-var-clock-eleven-thirty,
     "rv": $fa-var-rv,
     "user-secret": $fa-var-user-secret,
     "otter": $fa-var-otter,
     "dreidel": $fa-var-dreidel,
     "person-dress": $fa-var-person-dress,
     "female": $fa-var-female,
     "comment-dollar": $fa-var-comment-dollar,
     "business-time": $fa-var-business-time,
     "briefcase-clock": $fa-var-briefcase-clock,
     "flower-tulip": $fa-var-flower-tulip,
     "people-pants-simple": $fa-var-people-pants-simple,
     "cloud-drizzle": $fa-var-cloud-drizzle,
     "table-cells-large": $fa-var-table-cells-large,
     "th-large": $fa-var-th-large,
     "book-tanakh": $fa-var-book-tanakh,
     "tanakh": $fa-var-tanakh,
     "solar-system": $fa-var-solar-system,
     "seal-question": $fa-var-seal-question,
     "phone-volume": $fa-var-phone-volume,
     "volume-control-phone": $fa-var-volume-control-phone,
     "disc-drive": $fa-var-disc-drive,
     "hat-cowboy-side": $fa-var-hat-cowboy-side,
     "table-rows": $fa-var-table-rows,
     "rows": $fa-var-rows,
     "location-exclamation": $fa-var-location-exclamation,
     "map-marker-exclamation": $fa-var-map-marker-exclamation,
     "face-fearful": $fa-var-face-fearful,
     "clipboard-user": $fa-var-clipboard-user,
     "bus-school": $fa-var-bus-school,
     "film-slash": $fa-var-film-slash,
     "square-arrow-down-right": $fa-var-square-arrow-down-right,
     "book-sparkles": $fa-var-book-sparkles,
     "book-spells": $fa-var-book-spells,
     "washing-machine": $fa-var-washing-machine,
     "washer": $fa-var-washer,
     "child": $fa-var-child,
     "lira-sign": $fa-var-lira-sign,
     "user-visor": $fa-var-user-visor,
     "file-plus-minus": $fa-var-file-plus-minus,
     "chess-clock-flip": $fa-var-chess-clock-flip,
     "chess-clock-alt": $fa-var-chess-clock-alt,
     "satellite": $fa-var-satellite,
     "plane-lock": $fa-var-plane-lock,
     "steering-wheel": $fa-var-steering-wheel,
     "tag": $fa-var-tag,
     "stretcher": $fa-var-stretcher,
     "book-section": $fa-var-book-section,
     "book-law": $fa-var-book-law,
     "inboxes": $fa-var-inboxes,
     "coffee-bean": $fa-var-coffee-bean,
     "brackets-curly": $fa-var-brackets-curly,
     "ellipsis-stroke-vertical": $fa-var-ellipsis-stroke-vertical,
     "ellipsis-v-alt": $fa-var-ellipsis-v-alt,
     "comment": $fa-var-comment,
     "square-1": $fa-var-square-1,
     "cake-candles": $fa-var-cake-candles,
     "birthday-cake": $fa-var-birthday-cake,
     "cake": $fa-var-cake,
     "head-side": $fa-var-head-side,
     "envelope": $fa-var-envelope,
     "dolly-empty": $fa-var-dolly-empty,
     "face-tissue": $fa-var-face-tissue,
     "angles-up": $fa-var-angles-up,
     "angle-double-up": $fa-var-angle-double-up,
     "paperclip": $fa-var-paperclip,
     "chart-line-down": $fa-var-chart-line-down,
     "arrow-right-to-city": $fa-var-arrow-right-to-city,
     "lock-a": $fa-var-lock-a,
     "ribbon": $fa-var-ribbon,
     "lungs": $fa-var-lungs,
     "person-pinball": $fa-var-person-pinball,
     "arrow-up-9-1": $fa-var-arrow-up-9-1,
     "sort-numeric-up-alt": $fa-var-sort-numeric-up-alt,
     "apple-core": $fa-var-apple-core,
     "circle-y": $fa-var-circle-y,
     "h6": $fa-var-h6,
     "litecoin-sign": $fa-var-litecoin-sign,
     "circle-small": $fa-var-circle-small,
     "border-none": $fa-var-border-none,
     "arrow-turn-down-left": $fa-var-arrow-turn-down-left,
     "circle-nodes": $fa-var-circle-nodes,
     "parachute-box": $fa-var-parachute-box,
     "message-medical": $fa-var-message-medical,
     "comment-alt-medical": $fa-var-comment-alt-medical,
     "rugby-ball": $fa-var-rugby-ball,
     "comment-music": $fa-var-comment-music,
     "indent": $fa-var-indent,
     "tree-deciduous": $fa-var-tree-deciduous,
     "tree-alt": $fa-var-tree-alt,
     "puzzle-piece-simple": $fa-var-puzzle-piece-simple,
     "puzzle-piece-alt": $fa-var-puzzle-piece-alt,
     "truck-field-un": $fa-var-truck-field-un,
     "nfc-trash": $fa-var-nfc-trash,
     "hourglass": $fa-var-hourglass,
     "hourglass-empty": $fa-var-hourglass-empty,
     "mountain": $fa-var-mountain,
     "file-xmark": $fa-var-file-xmark,
     "file-times": $fa-var-file-times,
     "house-heart": $fa-var-house-heart,
     "home-heart": $fa-var-home-heart,
     "house-chimney-blank": $fa-var-house-chimney-blank,
     "meter-bolt": $fa-var-meter-bolt,
     "user-doctor": $fa-var-user-doctor,
     "user-md": $fa-var-user-md,
     "slash-back": $fa-var-slash-back,
     "circle-info": $fa-var-circle-info,
     "info-circle": $fa-var-info-circle,
     "fishing-rod": $fa-var-fishing-rod,
     "hammer-crash": $fa-var-hammer-crash,
     "message-heart": $fa-var-message-heart,
     "cloud-meatball": $fa-var-cloud-meatball,
     "camera-polaroid": $fa-var-camera-polaroid,
     "camera": $fa-var-camera,
     "camera-alt": $fa-var-camera-alt,
     "square-virus": $fa-var-square-virus,
     "cart-arrow-up": $fa-var-cart-arrow-up,
     "meteor": $fa-var-meteor,
     "car-on": $fa-var-car-on,
     "sleigh": $fa-var-sleigh,
     "arrow-down-1-9": $fa-var-arrow-down-1-9,
     "sort-numeric-asc": $fa-var-sort-numeric-asc,
     "sort-numeric-down": $fa-var-sort-numeric-down,
     "buoy-mooring": $fa-var-buoy-mooring,
     "square-4": $fa-var-square-4,
     "hand-holding-droplet": $fa-var-hand-holding-droplet,
     "hand-holding-water": $fa-var-hand-holding-water,
     "tricycle-adult": $fa-var-tricycle-adult,
     "waveform": $fa-var-waveform,
     "water": $fa-var-water,
     "star-sharp-half-stroke": $fa-var-star-sharp-half-stroke,
     "star-sharp-half-alt": $fa-var-star-sharp-half-alt,
     "nfc-signal": $fa-var-nfc-signal,
     "plane-prop": $fa-var-plane-prop,
     "calendar-check": $fa-var-calendar-check,
     "clock-desk": $fa-var-clock-desk,
     "calendar-clock": $fa-var-calendar-clock,
     "calendar-time": $fa-var-calendar-time,
     "braille": $fa-var-braille,
     "prescription-bottle-medical": $fa-var-prescription-bottle-medical,
     "prescription-bottle-alt": $fa-var-prescription-bottle-alt,
     "plate-utensils": $fa-var-plate-utensils,
     "family-pants": $fa-var-family-pants,
     "hose-reel": $fa-var-hose-reel,
     "house-window": $fa-var-house-window,
     "landmark": $fa-var-landmark,
     "truck": $fa-var-truck,
     "crosshairs": $fa-var-crosshairs,
     "cloud-rainbow": $fa-var-cloud-rainbow,
     "person-cane": $fa-var-person-cane,
     "alien": $fa-var-alien,
     "tent": $fa-var-tent,
     "vest-patches": $fa-var-vest-patches,
     "people-dress-simple": $fa-var-people-dress-simple,
     "check-double": $fa-var-check-double,
     "arrow-down-a-z": $fa-var-arrow-down-a-z,
     "sort-alpha-asc": $fa-var-sort-alpha-asc,
     "sort-alpha-down": $fa-var-sort-alpha-down,
     "bowling-ball-pin": $fa-var-bowling-ball-pin,
     "bell-school-slash": $fa-var-bell-school-slash,
     "plus-large": $fa-var-plus-large,
     "money-bill-wheat": $fa-var-money-bill-wheat,
     "camera-viewfinder": $fa-var-camera-viewfinder,
     "screenshot": $fa-var-screenshot,
     "message-music": $fa-var-message-music,
     "comment-alt-music": $fa-var-comment-alt-music,
     "car-building": $fa-var-car-building,
     "border-bottom-right": $fa-var-border-bottom-right,
     "border-style-alt": $fa-var-border-style-alt,
     "octagon": $fa-var-octagon,
     "comment-arrow-up-right": $fa-var-comment-arrow-up-right,
     "octagon-divide": $fa-var-octagon-divide,
     "cookie": $fa-var-cookie,
     "arrow-rotate-left": $fa-var-arrow-rotate-left,
     "arrow-left-rotate": $fa-var-arrow-left-rotate,
     "arrow-rotate-back": $fa-var-arrow-rotate-back,
     "arrow-rotate-backward": $fa-var-arrow-rotate-backward,
     "undo": $fa-var-undo,
     "tv-music": $fa-var-tv-music,
     "hard-drive": $fa-var-hard-drive,
     "hdd": $fa-var-hdd,
     "reel": $fa-var-reel,
     "face-grin-squint-tears": $fa-var-face-grin-squint-tears,
     "grin-squint-tears": $fa-var-grin-squint-tears,
     "dumbbell": $fa-var-dumbbell,
     "rectangle-list": $fa-var-rectangle-list,
     "list-alt": $fa-var-list-alt,
     "tarp-droplet": $fa-var-tarp-droplet,
     "alarm-exclamation": $fa-var-alarm-exclamation,
     "house-medical-circle-check": $fa-var-house-medical-circle-check,
     "traffic-cone": $fa-var-traffic-cone,
     "grate": $fa-var-grate,
     "arrow-down-right": $fa-var-arrow-down-right,
     "person-skiing-nordic": $fa-var-person-skiing-nordic,
     "skiing-nordic": $fa-var-skiing-nordic,
     "calendar-plus": $fa-var-calendar-plus,
     "person-from-portal": $fa-var-person-from-portal,
     "portal-exit": $fa-var-portal-exit,
     "plane-arrival": $fa-var-plane-arrival,
     "cowbell-circle-plus": $fa-var-cowbell-circle-plus,
     "cowbell-more": $fa-var-cowbell-more,
     "circle-left": $fa-var-circle-left,
     "arrow-alt-circle-left": $fa-var-arrow-alt-circle-left,
     "distribute-spacing-vertical": $fa-var-distribute-spacing-vertical,
     "signal-bars-fair": $fa-var-signal-bars-fair,
     "signal-alt-2": $fa-var-signal-alt-2,
     "sportsball": $fa-var-sportsball,
     "game-console-handheld-crank": $fa-var-game-console-handheld-crank,
     "train-subway": $fa-var-train-subway,
     "subway": $fa-var-subway,
     "chart-gantt": $fa-var-chart-gantt,
     "face-smile-upside-down": $fa-var-face-smile-upside-down,
     "ball-pile": $fa-var-ball-pile,
     "badge-dollar": $fa-var-badge-dollar,
     "money-bills-simple": $fa-var-money-bills-simple,
     "money-bills-alt": $fa-var-money-bills-alt,
     "list-timeline": $fa-var-list-timeline,
     "indian-rupee-sign": $fa-var-indian-rupee-sign,
     "indian-rupee": $fa-var-indian-rupee,
     "inr": $fa-var-inr,
     "crop-simple": $fa-var-crop-simple,
     "crop-alt": $fa-var-crop-alt,
     "money-bill-1": $fa-var-money-bill-1,
     "money-bill-alt": $fa-var-money-bill-alt,
     "left-long": $fa-var-left-long,
     "long-arrow-alt-left": $fa-var-long-arrow-alt-left,
     "keyboard-down": $fa-var-keyboard-down,
     "circle-up-right": $fa-var-circle-up-right,
     "cloud-bolt-moon": $fa-var-cloud-bolt-moon,
     "thunderstorm-moon": $fa-var-thunderstorm-moon,
     "dna": $fa-var-dna,
     "virus-slash": $fa-var-virus-slash,
     "bracket-round-right": $fa-var-bracket-round-right,
     "circle-5": $fa-var-circle-5,
     "minus": $fa-var-minus,
     "subtract": $fa-var-subtract,
     "fire-flame": $fa-var-fire-flame,
     "flame": $fa-var-flame,
     "right-to-line": $fa-var-right-to-line,
     "arrow-alt-to-right": $fa-var-arrow-alt-to-right,
     "gif": $fa-var-gif,
     "chess": $fa-var-chess,
     "trash-slash": $fa-var-trash-slash,
     "arrow-left-long": $fa-var-arrow-left-long,
     "long-arrow-left": $fa-var-long-arrow-left,
     "plug-circle-check": $fa-var-plug-circle-check,
     "font-case": $fa-var-font-case,
     "street-view": $fa-var-street-view,
     "arrow-down-left": $fa-var-arrow-down-left,
     "franc-sign": $fa-var-franc-sign,
     "flask-round-poison": $fa-var-flask-round-poison,
     "flask-poison": $fa-var-flask-poison,
     "volume-off": $fa-var-volume-off,
     "book-circle-arrow-right": $fa-var-book-circle-arrow-right,
     "chart-user": $fa-var-chart-user,
     "user-chart": $fa-var-user-chart,
     "hands-asl-interpreting": $fa-var-hands-asl-interpreting,
     "american-sign-language-interpreting": $fa-var-american-sign-language-interpreting,
     "asl-interpreting": $fa-var-asl-interpreting,
     "hands-american-sign-language-interpreting": $fa-var-hands-american-sign-language-interpreting,
     "presentation-screen": $fa-var-presentation-screen,
     "presentation": $fa-var-presentation,
     "circle-bolt": $fa-var-circle-bolt,
     "face-smile-halo": $fa-var-face-smile-halo,
     "cart-circle-arrow-down": $fa-var-cart-circle-arrow-down,
     "house-person-return": $fa-var-house-person-return,
     "house-person-arrive": $fa-var-house-person-arrive,
     "house-return": $fa-var-house-return,
     "message-xmark": $fa-var-message-xmark,
     "comment-alt-times": $fa-var-comment-alt-times,
     "message-times": $fa-var-message-times,
     "file-certificate": $fa-var-file-certificate,
     "file-award": $fa-var-file-award,
     "user-doctor-hair-long": $fa-var-user-doctor-hair-long,
     "camera-security": $fa-var-camera-security,
     "camera-home": $fa-var-camera-home,
     "gear": $fa-var-gear,
     "cog": $fa-var-cog,
     "droplet-slash": $fa-var-droplet-slash,
     "tint-slash": $fa-var-tint-slash,
     "book-heart": $fa-var-book-heart,
     "mosque": $fa-var-mosque,
     "duck": $fa-var-duck,
     "mosquito": $fa-var-mosquito,
     "star-of-david": $fa-var-star-of-david,
     "flag-swallowtail": $fa-var-flag-swallowtail,
     "flag-alt": $fa-var-flag-alt,
     "person-military-rifle": $fa-var-person-military-rifle,
     "car-garage": $fa-var-car-garage,
     "cart-shopping": $fa-var-cart-shopping,
     "shopping-cart": $fa-var-shopping-cart,
     "book-font": $fa-var-book-font,
     "shield-plus": $fa-var-shield-plus,
     "vials": $fa-var-vials,
     "eye-dropper-full": $fa-var-eye-dropper-full,
     "distribute-spacing-horizontal": $fa-var-distribute-spacing-horizontal,
     "tablet-rugged": $fa-var-tablet-rugged,
     "temperature-snow": $fa-var-temperature-snow,
     "temperature-frigid": $fa-var-temperature-frigid,
     "moped": $fa-var-moped,
     "face-smile-plus": $fa-var-face-smile-plus,
     "smile-plus": $fa-var-smile-plus,
     "radio-tuner": $fa-var-radio-tuner,
     "radio-alt": $fa-var-radio-alt,
     "face-swear": $fa-var-face-swear,
     "water-arrow-down": $fa-var-water-arrow-down,
     "water-lower": $fa-var-water-lower,
     "scanner-touchscreen": $fa-var-scanner-touchscreen,
     "circle-7": $fa-var-circle-7,
     "plug-circle-plus": $fa-var-plug-circle-plus,
     "person-ski-jumping": $fa-var-person-ski-jumping,
     "ski-jump": $fa-var-ski-jump,
     "place-of-worship": $fa-var-place-of-worship,
     "water-arrow-up": $fa-var-water-arrow-up,
     "water-rise": $fa-var-water-rise,
     "waveform-lines": $fa-var-waveform-lines,
     "waveform-path": $fa-var-waveform-path,
     "split": $fa-var-split,
     "film-canister": $fa-var-film-canister,
     "film-cannister": $fa-var-film-cannister,
     "folder-xmark": $fa-var-folder-xmark,
     "folder-times": $fa-var-folder-times,
     "toilet-paper-blank": $fa-var-toilet-paper-blank,
     "toilet-paper-alt": $fa-var-toilet-paper-alt,
     "tablet-screen": $fa-var-tablet-screen,
     "tablet-android-alt": $fa-var-tablet-android-alt,
     "hexagon-vertical-nft-slanted": $fa-var-hexagon-vertical-nft-slanted,
     "folder-music": $fa-var-folder-music,
     "display-medical": $fa-var-display-medical,
     "desktop-medical": $fa-var-desktop-medical,
     "share-all": $fa-var-share-all,
     "peapod": $fa-var-peapod,
     "chess-clock": $fa-var-chess-clock,
     "axe": $fa-var-axe,
     "square-d": $fa-var-square-d,
     "grip-vertical": $fa-var-grip-vertical,
     "mobile-signal-out": $fa-var-mobile-signal-out,
     "arrow-turn-up": $fa-var-arrow-turn-up,
     "level-up": $fa-var-level-up,
     "u": $fa-var-u,
     "arrow-up-from-dotted-line": $fa-var-arrow-up-from-dotted-line,
     "square-root-variable": $fa-var-square-root-variable,
     "square-root-alt": $fa-var-square-root-alt,
     "light-switch-on": $fa-var-light-switch-on,
     "arrow-down-arrow-up": $fa-var-arrow-down-arrow-up,
     "sort-alt": $fa-var-sort-alt,
     "raindrops": $fa-var-raindrops,
     "dash": $fa-var-dash,
     "minus-large": $fa-var-minus-large,
     "clock": $fa-var-clock,
     "clock-four": $fa-var-clock-four,
     "input-numeric": $fa-var-input-numeric,
     "truck-tow": $fa-var-truck-tow,
     "backward-step": $fa-var-backward-step,
     "step-backward": $fa-var-step-backward,
     "pallet": $fa-var-pallet,
     "car-bolt": $fa-var-car-bolt,
     "arrows-maximize": $fa-var-arrows-maximize,
     "expand-arrows": $fa-var-expand-arrows,
     "faucet": $fa-var-faucet,
     "cloud-sleet": $fa-var-cloud-sleet,
     "lamp-street": $fa-var-lamp-street,
     "list-radio": $fa-var-list-radio,
     "pen-nib-slash": $fa-var-pen-nib-slash,
     "baseball-bat-ball": $fa-var-baseball-bat-ball,
     "square-up-left": $fa-var-square-up-left,
     "overline": $fa-var-overline,
     "s": $fa-var-s,
     "timeline": $fa-var-timeline,
     "keyboard": $fa-var-keyboard,
     "arrows-from-dotted-line": $fa-var-arrows-from-dotted-line,
     "usb-drive": $fa-var-usb-drive,
     "ballot": $fa-var-ballot,
     "caret-down": $fa-var-caret-down,
     "location-dot-slash": $fa-var-location-dot-slash,
     "map-marker-alt-slash": $fa-var-map-marker-alt-slash,
     "cards": $fa-var-cards,
     "house-chimney-medical": $fa-var-house-chimney-medical,
     "clinic-medical": $fa-var-clinic-medical,
     "boxing-glove": $fa-var-boxing-glove,
     "glove-boxing": $fa-var-glove-boxing,
     "temperature-three-quarters": $fa-var-temperature-three-quarters,
     "temperature-3": $fa-var-temperature-3,
     "thermometer-3": $fa-var-thermometer-3,
     "thermometer-three-quarters": $fa-var-thermometer-three-quarters,
     "bell-school": $fa-var-bell-school,
     "mobile-screen": $fa-var-mobile-screen,
     "mobile-android-alt": $fa-var-mobile-android-alt,
     "plane-up": $fa-var-plane-up,
     "folder-heart": $fa-var-folder-heart,
     "circle-location-arrow": $fa-var-circle-location-arrow,
     "location-circle": $fa-var-location-circle,
     "face-head-bandage": $fa-var-face-head-bandage,
     "sushi-roll": $fa-var-sushi-roll,
     "maki-roll": $fa-var-maki-roll,
     "makizushi": $fa-var-makizushi,
     "car-bump": $fa-var-car-bump,
     "piggy-bank": $fa-var-piggy-bank,
     "racquet": $fa-var-racquet,
     "car-mirrors": $fa-var-car-mirrors,
     "industry-windows": $fa-var-industry-windows,
     "industry-alt": $fa-var-industry-alt,
     "bolt-auto": $fa-var-bolt-auto,
     "battery-half": $fa-var-battery-half,
     "battery-3": $fa-var-battery-3,
     "flux-capacitor": $fa-var-flux-capacitor,
     "mountain-city": $fa-var-mountain-city,
     "coins": $fa-var-coins,
     "honey-pot": $fa-var-honey-pot,
     "olive": $fa-var-olive,
     "khanda": $fa-var-khanda,
     "filter-list": $fa-var-filter-list,
     "outlet": $fa-var-outlet,
     "sliders": $fa-var-sliders,
     "sliders-h": $fa-var-sliders-h,
     "cauldron": $fa-var-cauldron,
     "people": $fa-var-people,
     "folder-tree": $fa-var-folder-tree,
     "network-wired": $fa-var-network-wired,
     "croissant": $fa-var-croissant,
     "map-pin": $fa-var-map-pin,
     "hamsa": $fa-var-hamsa,
     "cent-sign": $fa-var-cent-sign,
     "swords-laser": $fa-var-swords-laser,
     "flask": $fa-var-flask,
     "person-pregnant": $fa-var-person-pregnant,
     "square-u": $fa-var-square-u,
     "wand-sparkles": $fa-var-wand-sparkles,
     "router": $fa-var-router,
     "ellipsis-vertical": $fa-var-ellipsis-vertical,
     "ellipsis-v": $fa-var-ellipsis-v,
     "sword-laser-alt": $fa-var-sword-laser-alt,
     "ticket": $fa-var-ticket,
     "power-off": $fa-var-power-off,
     "coin": $fa-var-coin,
     "laptop-slash": $fa-var-laptop-slash,
     "right-long": $fa-var-right-long,
     "long-arrow-alt-right": $fa-var-long-arrow-alt-right,
     "circle-b": $fa-var-circle-b,
     "person-dress-simple": $fa-var-person-dress-simple,
     "pipe-collar": $fa-var-pipe-collar,
     "lights-holiday": $fa-var-lights-holiday,
     "citrus": $fa-var-citrus,
     "flag-usa": $fa-var-flag-usa,
     "laptop-file": $fa-var-laptop-file,
     "tty": $fa-var-tty,
     "teletype": $fa-var-teletype,
     "chart-tree-map": $fa-var-chart-tree-map,
     "diagram-next": $fa-var-diagram-next,
     "person-rifle": $fa-var-person-rifle,
     "clock-five-thirty": $fa-var-clock-five-thirty,
     "pipe-valve": $fa-var-pipe-valve,
     "arrow-up-from-arc": $fa-var-arrow-up-from-arc,
     "face-spiral-eyes": $fa-var-face-spiral-eyes,
     "compress-wide": $fa-var-compress-wide,
     "circle-phone-hangup": $fa-var-circle-phone-hangup,
     "phone-circle-down": $fa-var-phone-circle-down,
     "house-medical-circle-exclamation": $fa-var-house-medical-circle-exclamation,
     "badminton": $fa-var-badminton,
     "closed-captioning": $fa-var-closed-captioning,
     "person-hiking": $fa-var-person-hiking,
     "hiking": $fa-var-hiking,
     "right-from-line": $fa-var-right-from-line,
     "arrow-alt-from-left": $fa-var-arrow-alt-from-left,
     "venus-double": $fa-var-venus-double,
     "images": $fa-var-images,
     "calculator": $fa-var-calculator,
     "shuttlecock": $fa-var-shuttlecock,
     "user-hair": $fa-var-user-hair,
     "eye-evil": $fa-var-eye-evil,
     "people-pulling": $fa-var-people-pulling,
     "n": $fa-var-n,
     "garage": $fa-var-garage,
     "cable-car": $fa-var-cable-car,
     "tram": $fa-var-tram,
     "shovel-snow": $fa-var-shovel-snow,
     "cloud-rain": $fa-var-cloud-rain,
     "face-lying": $fa-var-face-lying,
     "sprinkler": $fa-var-sprinkler,
     "building-circle-xmark": $fa-var-building-circle-xmark,
     "person-sledding": $fa-var-person-sledding,
     "sledding": $fa-var-sledding,
     "game-console-handheld": $fa-var-game-console-handheld,
     "ship": $fa-var-ship,
     "clock-six-thirty": $fa-var-clock-six-thirty,
     "battery-slash": $fa-var-battery-slash,
     "tugrik-sign": $fa-var-tugrik-sign,
     "arrows-down-to-line": $fa-var-arrows-down-to-line,
     "download": $fa-var-download,
     "shelves": $fa-var-shelves,
     "inventory": $fa-var-inventory,
     "cloud-snow": $fa-var-cloud-snow,
     "face-grin": $fa-var-face-grin,
     "grin": $fa-var-grin,
     "delete-left": $fa-var-delete-left,
     "backspace": $fa-var-backspace,
     "oven": $fa-var-oven,
     "eye-dropper": $fa-var-eye-dropper,
     "eye-dropper-empty": $fa-var-eye-dropper-empty,
     "eyedropper": $fa-var-eyedropper,
     "comment-captions": $fa-var-comment-captions,
     "comments-question": $fa-var-comments-question,
     "scribble": $fa-var-scribble,
     "rotate-exclamation": $fa-var-rotate-exclamation,
     "file-circle-check": $fa-var-file-circle-check,
     "glass": $fa-var-glass,
     "loader": $fa-var-loader,
     "forward": $fa-var-forward,
     "user-pilot": $fa-var-user-pilot,
     "mobile": $fa-var-mobile,
     "mobile-android": $fa-var-mobile-android,
     "mobile-phone": $fa-var-mobile-phone,
     "code-pull-request-closed": $fa-var-code-pull-request-closed,
     "face-meh": $fa-var-face-meh,
     "meh": $fa-var-meh,
     "align-center": $fa-var-align-center,
     "book-skull": $fa-var-book-skull,
     "book-dead": $fa-var-book-dead,
     "id-card": $fa-var-id-card,
     "drivers-license": $fa-var-drivers-license,
     "face-dotted": $fa-var-face-dotted,
     "face-worried": $fa-var-face-worried,
     "outdent": $fa-var-outdent,
     "dedent": $fa-var-dedent,
     "heart-circle-exclamation": $fa-var-heart-circle-exclamation,
     "house": $fa-var-house,
     "home": $fa-var-home,
     "home-alt": $fa-var-home-alt,
     "home-lg-alt": $fa-var-home-lg-alt,
     "vector-circle": $fa-var-vector-circle,
     "car-circle-bolt": $fa-var-car-circle-bolt,
     "calendar-week": $fa-var-calendar-week,
     "flying-disc": $fa-var-flying-disc,
     "laptop-medical": $fa-var-laptop-medical,
     "square-down-right": $fa-var-square-down-right,
     "b": $fa-var-b,
     "seat-airline": $fa-var-seat-airline,
     "moon-over-sun": $fa-var-moon-over-sun,
     "eclipse-alt": $fa-var-eclipse-alt,
     "pipe": $fa-var-pipe,
     "file-medical": $fa-var-file-medical,
     "potato": $fa-var-potato,
     "dice-one": $fa-var-dice-one,
     "circle-a": $fa-var-circle-a,
     "helmet-battle": $fa-var-helmet-battle,
     "butter": $fa-var-butter,
     "blanket-fire": $fa-var-blanket-fire,
     "kiwi-bird": $fa-var-kiwi-bird,
     "castle": $fa-var-castle,
     "golf-club": $fa-var-golf-club,
     "arrow-right-arrow-left": $fa-var-arrow-right-arrow-left,
     "exchange": $fa-var-exchange,
     "rotate-right": $fa-var-rotate-right,
     "redo-alt": $fa-var-redo-alt,
     "rotate-forward": $fa-var-rotate-forward,
     "utensils": $fa-var-utensils,
     "cutlery": $fa-var-cutlery,
     "arrow-up-wide-short": $fa-var-arrow-up-wide-short,
     "sort-amount-up": $fa-var-sort-amount-up,
     "balloons": $fa-var-balloons,
     "mill-sign": $fa-var-mill-sign,
     "bowl-rice": $fa-var-bowl-rice,
     "timeline-arrow": $fa-var-timeline-arrow,
     "skull": $fa-var-skull,
     "game-board-simple": $fa-var-game-board-simple,
     "game-board-alt": $fa-var-game-board-alt,
     "circle-video": $fa-var-circle-video,
     "video-circle": $fa-var-video-circle,
     "chart-scatter-bubble": $fa-var-chart-scatter-bubble,
     "house-turret": $fa-var-house-turret,
     "banana": $fa-var-banana,
     "hand-holding-skull": $fa-var-hand-holding-skull,
     "people-dress": $fa-var-people-dress,
     "loveseat": $fa-var-loveseat,
     "couch-small": $fa-var-couch-small,
     "tower-broadcast": $fa-var-tower-broadcast,
     "broadcast-tower": $fa-var-broadcast-tower,
     "truck-pickup": $fa-var-truck-pickup,
     "block-quote": $fa-var-block-quote,
     "up-long": $fa-var-up-long,
     "long-arrow-alt-up": $fa-var-long-arrow-alt-up,
     "stop": $fa-var-stop,
     "code-merge": $fa-var-code-merge,
     "money-check-dollar-pen": $fa-var-money-check-dollar-pen,
     "money-check-edit-alt": $fa-var-money-check-edit-alt,
     "up-from-line": $fa-var-up-from-line,
     "arrow-alt-from-bottom": $fa-var-arrow-alt-from-bottom,
     "upload": $fa-var-upload,
     "hurricane": $fa-var-hurricane,
     "people-pants": $fa-var-people-pants,
     "mound": $fa-var-mound,
     "windsock": $fa-var-windsock,
     "circle-half": $fa-var-circle-half,
     "brake-warning": $fa-var-brake-warning,
     "toilet-portable": $fa-var-toilet-portable,
     "compact-disc": $fa-var-compact-disc,
     "file-arrow-down": $fa-var-file-arrow-down,
     "file-download": $fa-var-file-download,
     "saxophone-fire": $fa-var-saxophone-fire,
     "sax-hot": $fa-var-sax-hot,
     "camera-web-slash": $fa-var-camera-web-slash,
     "webcam-slash": $fa-var-webcam-slash,
     "folder-medical": $fa-var-folder-medical,
     "folder-gear": $fa-var-folder-gear,
     "folder-cog": $fa-var-folder-cog,
     "hand-wave": $fa-var-hand-wave,
     "arrow-up-arrow-down": $fa-var-arrow-up-arrow-down,
     "sort-up-down": $fa-var-sort-up-down,
     "caravan": $fa-var-caravan,
     "shield-cat": $fa-var-shield-cat,
     "message-slash": $fa-var-message-slash,
     "comment-alt-slash": $fa-var-comment-alt-slash,
     "bolt": $fa-var-bolt,
     "zap": $fa-var-zap,
     "trash-can-check": $fa-var-trash-can-check,
     "glass-water": $fa-var-glass-water,
     "oil-well": $fa-var-oil-well,
     "person-simple": $fa-var-person-simple,
     "vault": $fa-var-vault,
     "mars": $fa-var-mars,
     "toilet": $fa-var-toilet,
     "plane-circle-xmark": $fa-var-plane-circle-xmark,
     "yen-sign": $fa-var-yen-sign,
     "cny": $fa-var-cny,
     "jpy": $fa-var-jpy,
     "rmb": $fa-var-rmb,
     "yen": $fa-var-yen,
     "notes": $fa-var-notes,
     "ruble-sign": $fa-var-ruble-sign,
     "rouble": $fa-var-rouble,
     "rub": $fa-var-rub,
     "ruble": $fa-var-ruble,
     "trash-undo": $fa-var-trash-undo,
     "trash-arrow-turn-left": $fa-var-trash-arrow-turn-left,
     "champagne-glass": $fa-var-champagne-glass,
     "glass-champagne": $fa-var-glass-champagne,
     "objects-align-center-horizontal": $fa-var-objects-align-center-horizontal,
     "sun": $fa-var-sun,
     "trash-can-slash": $fa-var-trash-can-slash,
     "trash-alt-slash": $fa-var-trash-alt-slash,
     "screen-users": $fa-var-screen-users,
     "users-class": $fa-var-users-class,
     "guitar": $fa-var-guitar,
     "square-arrow-left": $fa-var-square-arrow-left,
     "arrow-square-left": $fa-var-arrow-square-left,
     "square-8": $fa-var-square-8,
     "face-smile-hearts": $fa-var-face-smile-hearts,
     "brackets-square": $fa-var-brackets-square,
     "brackets": $fa-var-brackets,
     "laptop-arrow-down": $fa-var-laptop-arrow-down,
     "hockey-stick-puck": $fa-var-hockey-stick-puck,
     "house-tree": $fa-var-house-tree,
     "signal-fair": $fa-var-signal-fair,
     "signal-2": $fa-var-signal-2,
     "face-laugh-wink": $fa-var-face-laugh-wink,
     "laugh-wink": $fa-var-laugh-wink,
     "circle-dollar": $fa-var-circle-dollar,
     "dollar-circle": $fa-var-dollar-circle,
     "usd-circle": $fa-var-usd-circle,
     "horse-head": $fa-var-horse-head,
     "arrows-repeat": $fa-var-arrows-repeat,
     "repeat-alt": $fa-var-repeat-alt,
     "bore-hole": $fa-var-bore-hole,
     "industry": $fa-var-industry,
     "image-polaroid": $fa-var-image-polaroid,
     "wave-triangle": $fa-var-wave-triangle,
     "circle-down": $fa-var-circle-down,
     "arrow-alt-circle-down": $fa-var-arrow-alt-circle-down,
     "grill": $fa-var-grill,
     "arrows-turn-to-dots": $fa-var-arrows-turn-to-dots,
     "chart-mixed": $fa-var-chart-mixed,
     "analytics": $fa-var-analytics,
     "florin-sign": $fa-var-florin-sign,
     "arrow-down-short-wide": $fa-var-arrow-down-short-wide,
     "sort-amount-desc": $fa-var-sort-amount-desc,
     "sort-amount-down-alt": $fa-var-sort-amount-down-alt,
     "less-than": $fa-var-less-than,
     "display-code": $fa-var-display-code,
     "desktop-code": $fa-var-desktop-code,
     "face-drooling": $fa-var-face-drooling,
     "oil-temperature": $fa-var-oil-temperature,
     "oil-temp": $fa-var-oil-temp,
     "square-question": $fa-var-square-question,
     "question-square": $fa-var-question-square,
     "air-conditioner": $fa-var-air-conditioner,
     "angle-down": $fa-var-angle-down,
     "mountains": $fa-var-mountains,
     "omega": $fa-var-omega,
     "car-tunnel": $fa-var-car-tunnel,
     "person-dolly-empty": $fa-var-person-dolly-empty,
     "pan-food": $fa-var-pan-food,
     "head-side-cough": $fa-var-head-side-cough,
     "grip-lines": $fa-var-grip-lines,
     "thumbs-down": $fa-var-thumbs-down,
     "user-lock": $fa-var-user-lock,
     "arrow-right-long": $fa-var-arrow-right-long,
     "long-arrow-right": $fa-var-long-arrow-right,
     "tickets-airline": $fa-var-tickets-airline,
     "anchor-circle-xmark": $fa-var-anchor-circle-xmark,
     "ellipsis": $fa-var-ellipsis,
     "ellipsis-h": $fa-var-ellipsis-h,
     "nfc-slash": $fa-var-nfc-slash,
     "chess-pawn": $fa-var-chess-pawn,
     "kit-medical": $fa-var-kit-medical,
     "first-aid": $fa-var-first-aid,
     "grid-2-plus": $fa-var-grid-2-plus,
     "bells": $fa-var-bells,
     "person-through-window": $fa-var-person-through-window,
     "toolbox": $fa-var-toolbox,
     "envelope-dot": $fa-var-envelope-dot,
     "envelope-badge": $fa-var-envelope-badge,
     "hands-holding-circle": $fa-var-hands-holding-circle,
     "bug": $fa-var-bug,
     "bowl-chopsticks": $fa-var-bowl-chopsticks,
     "credit-card": $fa-var-credit-card,
     "credit-card-alt": $fa-var-credit-card-alt,
     "circle-s": $fa-var-circle-s,
     "box-ballot": $fa-var-box-ballot,
     "car": $fa-var-car,
     "automobile": $fa-var-automobile,
     "hand-holding-hand": $fa-var-hand-holding-hand,
     "user-tie-hair": $fa-var-user-tie-hair,
     "podium-star": $fa-var-podium-star,
     "user-hair-mullet": $fa-var-user-hair-mullet,
     "business-front": $fa-var-business-front,
     "party-back": $fa-var-party-back,
     "trian-balbot": $fa-var-trian-balbot,
     "microphone-stand": $fa-var-microphone-stand,
     "book-open-reader": $fa-var-book-open-reader,
     "book-reader": $fa-var-book-reader,
     "family-dress": $fa-var-family-dress,
     "circle-x": $fa-var-circle-x,
     "cabin": $fa-var-cabin,
     "mountain-sun": $fa-var-mountain-sun,
     "chart-simple-horizontal": $fa-var-chart-simple-horizontal,
     "arrows-left-right-to-line": $fa-var-arrows-left-right-to-line,
     "hand-back-point-left": $fa-var-hand-back-point-left,
     "message-dots": $fa-var-message-dots,
     "comment-alt-dots": $fa-var-comment-alt-dots,
     "messaging": $fa-var-messaging,
     "file-heart": $fa-var-file-heart,
     "beer-mug": $fa-var-beer-mug,
     "beer-foam": $fa-var-beer-foam,
     "dice-d20": $fa-var-dice-d20,
     "drone": $fa-var-drone,
     "truck-droplet": $fa-var-truck-droplet,
     "file-circle-xmark": $fa-var-file-circle-xmark,
     "temperature-arrow-up": $fa-var-temperature-arrow-up,
     "temperature-up": $fa-var-temperature-up,
     "medal": $fa-var-medal,
     "bed": $fa-var-bed,
     "book-copy": $fa-var-book-copy,
     "square-h": $fa-var-square-h,
     "h-square": $fa-var-h-square,
     "square-c": $fa-var-square-c,
     "clock-two": $fa-var-clock-two,
     "square-ellipsis-vertical": $fa-var-square-ellipsis-vertical,
     "podcast": $fa-var-podcast,
     "bee": $fa-var-bee,
     "temperature-full": $fa-var-temperature-full,
     "temperature-4": $fa-var-temperature-4,
     "thermometer-4": $fa-var-thermometer-4,
     "thermometer-full": $fa-var-thermometer-full,
     "bell": $fa-var-bell,
     "candy-bar": $fa-var-candy-bar,
     "chocolate-bar": $fa-var-chocolate-bar,
     "xmark-large": $fa-var-xmark-large,
     "pinata": $fa-var-pinata,
     "arrows-from-line": $fa-var-arrows-from-line,
     "superscript": $fa-var-superscript,
     "bowl-spoon": $fa-var-bowl-spoon,
     "hexagon-check": $fa-var-hexagon-check,
     "plug-circle-xmark": $fa-var-plug-circle-xmark,
     "star-of-life": $fa-var-star-of-life,
     "phone-slash": $fa-var-phone-slash,
     "traffic-light-stop": $fa-var-traffic-light-stop,
     "paint-roller": $fa-var-paint-roller,
     "accent-grave": $fa-var-accent-grave,
     "handshake-angle": $fa-var-handshake-angle,
     "hands-helping": $fa-var-hands-helping,
     "circle-0": $fa-var-circle-0,
     "dial-med-low": $fa-var-dial-med-low,
     "location-dot": $fa-var-location-dot,
     "map-marker-alt": $fa-var-map-marker-alt,
     "crab": $fa-var-crab,
     "box-open-full": $fa-var-box-open-full,
     "box-full": $fa-var-box-full,
     "file": $fa-var-file,
     "greater-than": $fa-var-greater-than,
     "quotes": $fa-var-quotes,
     "pretzel": $fa-var-pretzel,
     "person-swimming": $fa-var-person-swimming,
     "swimmer": $fa-var-swimmer,
     "arrow-down": $fa-var-arrow-down,
     "user-robot-xmarks": $fa-var-user-robot-xmarks,
     "message-quote": $fa-var-message-quote,
     "comment-alt-quote": $fa-var-comment-alt-quote,
     "candy-corn": $fa-var-candy-corn,
     "folder-magnifying-glass": $fa-var-folder-magnifying-glass,
     "folder-search": $fa-var-folder-search,
     "notebook": $fa-var-notebook,
     "droplet": $fa-var-droplet,
     "tint": $fa-var-tint,
     "bullseye-pointer": $fa-var-bullseye-pointer,
     "eraser": $fa-var-eraser,
     "hexagon-image": $fa-var-hexagon-image,
     "earth-americas": $fa-var-earth-americas,
     "earth": $fa-var-earth,
     "earth-america": $fa-var-earth-america,
     "globe-americas": $fa-var-globe-americas,
     "crate-apple": $fa-var-crate-apple,
     "apple-crate": $fa-var-apple-crate,
     "person-burst": $fa-var-person-burst,
     "game-board": $fa-var-game-board,
     "hat-chef": $fa-var-hat-chef,
     "hand-back-point-right": $fa-var-hand-back-point-right,
     "dove": $fa-var-dove,
     "snowflake-droplets": $fa-var-snowflake-droplets,
     "battery-empty": $fa-var-battery-empty,
     "battery-0": $fa-var-battery-0,
     "grid-4": $fa-var-grid-4,
     "socks": $fa-var-socks,
     "face-sunglasses": $fa-var-face-sunglasses,
     "inbox": $fa-var-inbox,
     "square-0": $fa-var-square-0,
     "section": $fa-var-section,
     "square-this-way-up": $fa-var-square-this-way-up,
     "box-up": $fa-var-box-up,
     "gauge-high": $fa-var-gauge-high,
     "tachometer-alt": $fa-var-tachometer-alt,
     "tachometer-alt-fast": $fa-var-tachometer-alt-fast,
     "square-ampersand": $fa-var-square-ampersand,
     "envelope-open-text": $fa-var-envelope-open-text,
     "lamp-desk": $fa-var-lamp-desk,
     "hospital": $fa-var-hospital,
     "hospital-alt": $fa-var-hospital-alt,
     "hospital-wide": $fa-var-hospital-wide,
     "poll-people": $fa-var-poll-people,
     "whiskey-glass-ice": $fa-var-whiskey-glass-ice,
     "glass-whiskey-rocks": $fa-var-glass-whiskey-rocks,
     "wine-bottle": $fa-var-wine-bottle,
     "chess-rook": $fa-var-chess-rook,
     "user-bounty-hunter": $fa-var-user-bounty-hunter,
     "bars-staggered": $fa-var-bars-staggered,
     "reorder": $fa-var-reorder,
     "stream": $fa-var-stream,
     "diagram-sankey": $fa-var-diagram-sankey,
     "cloud-hail-mixed": $fa-var-cloud-hail-mixed,
     "circle-up-left": $fa-var-circle-up-left,
     "dharmachakra": $fa-var-dharmachakra,
     "objects-align-left": $fa-var-objects-align-left,
     "oil-can-drip": $fa-var-oil-can-drip,
     "face-smiling-hands": $fa-var-face-smiling-hands,
     "broccoli": $fa-var-broccoli,
     "route-interstate": $fa-var-route-interstate,
     "ear-muffs": $fa-var-ear-muffs,
     "hotdog": $fa-var-hotdog,
     "transporter-empty": $fa-var-transporter-empty,
     "person-walking-with-cane": $fa-var-person-walking-with-cane,
     "blind": $fa-var-blind,
     "angle-90": $fa-var-angle-90,
     "rectangle-terminal": $fa-var-rectangle-terminal,
     "kite": $fa-var-kite,
     "drum": $fa-var-drum,
     "scrubber": $fa-var-scrubber,
     "ice-cream": $fa-var-ice-cream,
     "heart-circle-bolt": $fa-var-heart-circle-bolt,
     "fish-bones": $fa-var-fish-bones,
     "deer-rudolph": $fa-var-deer-rudolph,
     "fax": $fa-var-fax,
     "paragraph": $fa-var-paragraph,
     "head-side-heart": $fa-var-head-side-heart,
     "square-e": $fa-var-square-e,
     "meter-fire": $fa-var-meter-fire,
     "cloud-hail": $fa-var-cloud-hail,
     "check-to-slot": $fa-var-check-to-slot,
     "vote-yea": $fa-var-vote-yea,
     "money-from-bracket": $fa-var-money-from-bracket,
     "star-half": $fa-var-star-half,
     "car-bus": $fa-var-car-bus,
     "speaker": $fa-var-speaker,
     "timer": $fa-var-timer,
     "boxes-stacked": $fa-var-boxes-stacked,
     "boxes": $fa-var-boxes,
     "boxes-alt": $fa-var-boxes-alt,
     "grill-hot": $fa-var-grill-hot,
     "ballot-check": $fa-var-ballot-check,
     "link": $fa-var-link,
     "chain": $fa-var-chain,
     "ear-listen": $fa-var-ear-listen,
     "assistive-listening-systems": $fa-var-assistive-listening-systems,
     "file-minus": $fa-var-file-minus,
     "tree-city": $fa-var-tree-city,
     "play": $fa-var-play,
     "font": $fa-var-font,
     "cup-togo": $fa-var-cup-togo,
     "coffee-togo": $fa-var-coffee-togo,
     "square-down-left": $fa-var-square-down-left,
     "burger-lettuce": $fa-var-burger-lettuce,
     "rupiah-sign": $fa-var-rupiah-sign,
     "magnifying-glass": $fa-var-magnifying-glass,
     "search": $fa-var-search,
     "table-tennis-paddle-ball": $fa-var-table-tennis-paddle-ball,
     "ping-pong-paddle-ball": $fa-var-ping-pong-paddle-ball,
     "table-tennis": $fa-var-table-tennis,
     "person-dots-from-line": $fa-var-person-dots-from-line,
     "diagnoses": $fa-var-diagnoses,
     "chevrons-down": $fa-var-chevrons-down,
     "chevron-double-down": $fa-var-chevron-double-down,
     "trash-can-arrow-up": $fa-var-trash-can-arrow-up,
     "trash-restore-alt": $fa-var-trash-restore-alt,
     "signal-good": $fa-var-signal-good,
     "signal-3": $fa-var-signal-3,
     "location-question": $fa-var-location-question,
     "map-marker-question": $fa-var-map-marker-question,
     "floppy-disk-circle-xmark": $fa-var-floppy-disk-circle-xmark,
     "floppy-disk-times": $fa-var-floppy-disk-times,
     "save-circle-xmark": $fa-var-save-circle-xmark,
     "save-times": $fa-var-save-times,
     "naira-sign": $fa-var-naira-sign,
     "peach": $fa-var-peach,
     "taxi-bus": $fa-var-taxi-bus,
     "bracket-curly": $fa-var-bracket-curly,
     "bracket-curly-left": $fa-var-bracket-curly-left,
     "lobster": $fa-var-lobster,
     "cart-flatbed-empty": $fa-var-cart-flatbed-empty,
     "dolly-flatbed-empty": $fa-var-dolly-flatbed-empty,
     "colon": $fa-var-colon,
     "cart-arrow-down": $fa-var-cart-arrow-down,
     "wand": $fa-var-wand,
     "walkie-talkie": $fa-var-walkie-talkie,
     "file-pen": $fa-var-file-pen,
     "file-edit": $fa-var-file-edit,
     "receipt": $fa-var-receipt,
     "table-picnic": $fa-var-table-picnic,
     "square-pen": $fa-var-square-pen,
     "pen-square": $fa-var-pen-square,
     "pencil-square": $fa-var-pencil-square,
     "circle-microphone-lines": $fa-var-circle-microphone-lines,
     "microphone-circle-alt": $fa-var-microphone-circle-alt,
     "display-slash": $fa-var-display-slash,
     "desktop-slash": $fa-var-desktop-slash,
     "suitcase-rolling": $fa-var-suitcase-rolling,
     "person-circle-exclamation": $fa-var-person-circle-exclamation,
     "transporter-2": $fa-var-transporter-2,
     "hands-holding-diamond": $fa-var-hands-holding-diamond,
     "hand-receiving": $fa-var-hand-receiving,
     "money-bill-simple-wave": $fa-var-money-bill-simple-wave,
     "chevron-down": $fa-var-chevron-down,
     "battery-full": $fa-var-battery-full,
     "battery": $fa-var-battery,
     "battery-5": $fa-var-battery-5,
     "bell-plus": $fa-var-bell-plus,
     "book-arrow-right": $fa-var-book-arrow-right,
     "hospitals": $fa-var-hospitals,
     "club": $fa-var-club,
     "skull-crossbones": $fa-var-skull-crossbones,
     "droplet-degree": $fa-var-droplet-degree,
     "dewpoint": $fa-var-dewpoint,
     "code-compare": $fa-var-code-compare,
     "list-ul": $fa-var-list-ul,
     "list-dots": $fa-var-list-dots,
     "hand-holding-magic": $fa-var-hand-holding-magic,
     "watermelon-slice": $fa-var-watermelon-slice,
     "circle-ellipsis": $fa-var-circle-ellipsis,
     "school-lock": $fa-var-school-lock,
     "tower-cell": $fa-var-tower-cell,
     "sd-cards": $fa-var-sd-cards,
     "down-long": $fa-var-down-long,
     "long-arrow-alt-down": $fa-var-long-arrow-alt-down,
     "envelopes": $fa-var-envelopes,
     "phone-office": $fa-var-phone-office,
     "ranking-star": $fa-var-ranking-star,
     "chess-king": $fa-var-chess-king,
     "nfc-pen": $fa-var-nfc-pen,
     "person-harassing": $fa-var-person-harassing,
     "hat-winter": $fa-var-hat-winter,
     "brazilian-real-sign": $fa-var-brazilian-real-sign,
     "landmark-dome": $fa-var-landmark-dome,
     "landmark-alt": $fa-var-landmark-alt,
     "bone-break": $fa-var-bone-break,
     "arrow-up": $fa-var-arrow-up,
     "down-from-dotted-line": $fa-var-down-from-dotted-line,
     "tv": $fa-var-tv,
     "television": $fa-var-television,
     "tv-alt": $fa-var-tv-alt,
     "border-left": $fa-var-border-left,
     "circle-divide": $fa-var-circle-divide,
     "shrimp": $fa-var-shrimp,
     "list-check": $fa-var-list-check,
     "tasks": $fa-var-tasks,
     "diagram-subtask": $fa-var-diagram-subtask,
     "jug-detergent": $fa-var-jug-detergent,
     "circle-user": $fa-var-circle-user,
     "user-circle": $fa-var-user-circle,
     "square-y": $fa-var-square-y,
     "user-doctor-hair": $fa-var-user-doctor-hair,
     "planet-ringed": $fa-var-planet-ringed,
     "mushroom": $fa-var-mushroom,
     "user-shield": $fa-var-user-shield,
     "megaphone": $fa-var-megaphone,
     "circle-exclamation-check": $fa-var-circle-exclamation-check,
     "wind": $fa-var-wind,
     "box-dollar": $fa-var-box-dollar,
     "box-usd": $fa-var-box-usd,
     "car-burst": $fa-var-car-burst,
     "car-crash": $fa-var-car-crash,
     "y": $fa-var-y,
     "user-headset": $fa-var-user-headset,
     "arrows-retweet": $fa-var-arrows-retweet,
     "retweet-alt": $fa-var-retweet-alt,
     "person-snowboarding": $fa-var-person-snowboarding,
     "snowboarding": $fa-var-snowboarding,
     "square-chevron-right": $fa-var-square-chevron-right,
     "chevron-square-right": $fa-var-chevron-square-right,
     "lacrosse-stick-ball": $fa-var-lacrosse-stick-ball,
     "truck-fast": $fa-var-truck-fast,
     "shipping-fast": $fa-var-shipping-fast,
     "user-magnifying-glass": $fa-var-user-magnifying-glass,
     "star-sharp": $fa-var-star-sharp,
     "comment-heart": $fa-var-comment-heart,
     "circle-1": $fa-var-circle-1,
     "circle-star": $fa-var-circle-star,
     "star-circle": $fa-var-star-circle,
     "fish": $fa-var-fish,
     "cloud-fog": $fa-var-cloud-fog,
     "fog": $fa-var-fog,
     "waffle": $fa-var-waffle,
     "music-note": $fa-var-music-note,
     "music-alt": $fa-var-music-alt,
     "hexagon-exclamation": $fa-var-hexagon-exclamation,
     "cart-shopping-fast": $fa-var-cart-shopping-fast,
     "object-union": $fa-var-object-union,
     "user-graduate": $fa-var-user-graduate,
     "starfighter": $fa-var-starfighter,
     "circle-half-stroke": $fa-var-circle-half-stroke,
     "adjust": $fa-var-adjust,
     "arrow-right-long-to-line": $fa-var-arrow-right-long-to-line,
     "square-arrow-down": $fa-var-square-arrow-down,
     "arrow-square-down": $fa-var-arrow-square-down,
     "diamond-half-stroke": $fa-var-diamond-half-stroke,
     "clapperboard": $fa-var-clapperboard,
     "square-chevron-left": $fa-var-square-chevron-left,
     "chevron-square-left": $fa-var-chevron-square-left,
     "phone-intercom": $fa-var-phone-intercom,
     "link-horizontal": $fa-var-link-horizontal,
     "chain-horizontal": $fa-var-chain-horizontal,
     "mango": $fa-var-mango,
     "music-note-slash": $fa-var-music-note-slash,
     "music-alt-slash": $fa-var-music-alt-slash,
     "circle-radiation": $fa-var-circle-radiation,
     "radiation-alt": $fa-var-radiation-alt,
     "face-tongue-sweat": $fa-var-face-tongue-sweat,
     "globe-stand": $fa-var-globe-stand,
     "baseball": $fa-var-baseball,
     "baseball-ball": $fa-var-baseball-ball,
     "circle-p": $fa-var-circle-p,
     "award-simple": $fa-var-award-simple,
     "jet-fighter-up": $fa-var-jet-fighter-up,
     "diagram-project": $fa-var-diagram-project,
     "project-diagram": $fa-var-project-diagram,
     "pedestal": $fa-var-pedestal,
     "chart-pyramid": $fa-var-chart-pyramid,
     "sidebar": $fa-var-sidebar,
     "snowman-head": $fa-var-snowman-head,
     "frosty-head": $fa-var-frosty-head,
     "copy": $fa-var-copy,
     "burger-glass": $fa-var-burger-glass,
     "volume-xmark": $fa-var-volume-xmark,
     "volume-mute": $fa-var-volume-mute,
     "volume-times": $fa-var-volume-times,
     "hand-sparkles": $fa-var-hand-sparkles,
     "bars-filter": $fa-var-bars-filter,
     "paintbrush-pencil": $fa-var-paintbrush-pencil,
     "party-bell": $fa-var-party-bell,
     "user-vneck-hair": $fa-var-user-vneck-hair,
     "jack-o-lantern": $fa-var-jack-o-lantern,
     "grip": $fa-var-grip,
     "grip-horizontal": $fa-var-grip-horizontal,
     "share-from-square": $fa-var-share-from-square,
     "share-square": $fa-var-share-square,
     "keynote": $fa-var-keynote,
     "child-combatant": $fa-var-child-combatant,
     "child-rifle": $fa-var-child-rifle,
     "gun": $fa-var-gun,
     "square-phone": $fa-var-square-phone,
     "phone-square": $fa-var-phone-square,
     "plus": $fa-var-plus,
     "add": $fa-var-add,
     "expand": $fa-var-expand,
     "computer": $fa-var-computer,
     "fort": $fa-var-fort,
     "cloud-check": $fa-var-cloud-check,
     "xmark": $fa-var-xmark,
     "close": $fa-var-close,
     "multiply": $fa-var-multiply,
     "remove": $fa-var-remove,
     "times": $fa-var-times,
     "face-smirking": $fa-var-face-smirking,
     "arrows-up-down-left-right": $fa-var-arrows-up-down-left-right,
     "arrows": $fa-var-arrows,
     "chalkboard-user": $fa-var-chalkboard-user,
     "chalkboard-teacher": $fa-var-chalkboard-teacher,
     "rhombus": $fa-var-rhombus,
     "claw-marks": $fa-var-claw-marks,
     "peso-sign": $fa-var-peso-sign,
     "face-smile-tongue": $fa-var-face-smile-tongue,
     "cart-circle-xmark": $fa-var-cart-circle-xmark,
     "building-shield": $fa-var-building-shield,
     "circle-phone-flip": $fa-var-circle-phone-flip,
     "phone-circle-alt": $fa-var-phone-circle-alt,
     "baby": $fa-var-baby,
     "users-line": $fa-var-users-line,
     "quote-left": $fa-var-quote-left,
     "quote-left-alt": $fa-var-quote-left-alt,
     "tractor": $fa-var-tractor,
     "key-skeleton": $fa-var-key-skeleton,
     "trash-arrow-up": $fa-var-trash-arrow-up,
     "trash-restore": $fa-var-trash-restore,
     "arrow-down-up-lock": $fa-var-arrow-down-up-lock,
     "arrow-down-to-bracket": $fa-var-arrow-down-to-bracket,
     "lines-leaning": $fa-var-lines-leaning,
     "square-q": $fa-var-square-q,
     "ruler-combined": $fa-var-ruler-combined,
     "symbols": $fa-var-symbols,
     "icons-alt": $fa-var-icons-alt,
     "copyright": $fa-var-copyright,
     "highlighter-line": $fa-var-highlighter-line,
     "bracket-square": $fa-var-bracket-square,
     "bracket": $fa-var-bracket,
     "bracket-left": $fa-var-bracket-left,
     "island-tropical": $fa-var-island-tropical,
     "island-tree-palm": $fa-var-island-tree-palm,
     "arrow-right-from-line": $fa-var-arrow-right-from-line,
     "arrow-from-left": $fa-var-arrow-from-left,
     "h2": $fa-var-h2,
     "equals": $fa-var-equals,
     "cake-slice": $fa-var-cake-slice,
     "shortcake": $fa-var-shortcake,
     "peanut": $fa-var-peanut,
     "wrench-simple": $fa-var-wrench-simple,
     "blender": $fa-var-blender,
     "teeth": $fa-var-teeth,
     "tally-2": $fa-var-tally-2,
     "shekel-sign": $fa-var-shekel-sign,
     "ils": $fa-var-ils,
     "shekel": $fa-var-shekel,
     "sheqel": $fa-var-sheqel,
     "sheqel-sign": $fa-var-sheqel-sign,
     "cars": $fa-var-cars,
     "axe-battle": $fa-var-axe-battle,
     "user-hair-long": $fa-var-user-hair-long,
     "map": $fa-var-map,
     "file-circle-info": $fa-var-file-circle-info,
     "face-disappointed": $fa-var-face-disappointed,
     "lasso-sparkles": $fa-var-lasso-sparkles,
     "clock-eleven": $fa-var-clock-eleven,
     "rocket": $fa-var-rocket,
     "siren-on": $fa-var-siren-on,
     "clock-ten": $fa-var-clock-ten,
     "candle-holder": $fa-var-candle-holder,
     "video-arrow-down-left": $fa-var-video-arrow-down-left,
     "photo-film": $fa-var-photo-film,
     "photo-video": $fa-var-photo-video,
     "floppy-disk-circle-arrow-right": $fa-var-floppy-disk-circle-arrow-right,
     "save-circle-arrow-right": $fa-var-save-circle-arrow-right,
     "folder-minus": $fa-var-folder-minus,
     "planet-moon": $fa-var-planet-moon,
     "face-eyes-xmarks": $fa-var-face-eyes-xmarks,
     "chart-scatter": $fa-var-chart-scatter,
     "display-arrow-down": $fa-var-display-arrow-down,
     "store": $fa-var-store,
     "arrow-trend-up": $fa-var-arrow-trend-up,
     "plug-circle-minus": $fa-var-plug-circle-minus,
     "olive-branch": $fa-var-olive-branch,
     "angle": $fa-var-angle,
     "vacuum-robot": $fa-var-vacuum-robot,
     "sign-hanging": $fa-var-sign-hanging,
     "sign": $fa-var-sign,
     "square-divide": $fa-var-square-divide,
     "signal-stream-slash": $fa-var-signal-stream-slash,
     "bezier-curve": $fa-var-bezier-curve,
     "eye-dropper-half": $fa-var-eye-dropper-half,
     "store-lock": $fa-var-store-lock,
     "bell-slash": $fa-var-bell-slash,
     "cloud-bolt-sun": $fa-var-cloud-bolt-sun,
     "thunderstorm-sun": $fa-var-thunderstorm-sun,
     "camera-slash": $fa-var-camera-slash,
     "comment-quote": $fa-var-comment-quote,
     "tablet": $fa-var-tablet,
     "tablet-android": $fa-var-tablet-android,
     "school-flag": $fa-var-school-flag,
     "message-code": $fa-var-message-code,
     "glass-half": $fa-var-glass-half,
     "glass-half-empty": $fa-var-glass-half-empty,
     "glass-half-full": $fa-var-glass-half-full,
     "fill": $fa-var-fill,
     "message-minus": $fa-var-message-minus,
     "comment-alt-minus": $fa-var-comment-alt-minus,
     "angle-up": $fa-var-angle-up,
     "drumstick-bite": $fa-var-drumstick-bite,
     "link-horizontal-slash": $fa-var-link-horizontal-slash,
     "chain-horizontal-slash": $fa-var-chain-horizontal-slash,
     "holly-berry": $fa-var-holly-berry,
     "nose": $fa-var-nose,
     "chevron-left": $fa-var-chevron-left,
     "bacteria": $fa-var-bacteria,
     "clouds": $fa-var-clouds,
     "money-bill-simple": $fa-var-money-bill-simple,
     "hand-lizard": $fa-var-hand-lizard,
     "table-pivot": $fa-var-table-pivot,
     "filter-slash": $fa-var-filter-slash,
     "trash-can-undo": $fa-var-trash-can-undo,
     "trash-can-arrow-turn-left": $fa-var-trash-can-arrow-turn-left,
     "trash-undo-alt": $fa-var-trash-undo-alt,
     "notdef": $fa-var-notdef,
     "disease": $fa-var-disease,
     "person-to-door": $fa-var-person-to-door,
     "turntable": $fa-var-turntable,
     "briefcase-medical": $fa-var-briefcase-medical,
     "genderless": $fa-var-genderless,
     "chevron-right": $fa-var-chevron-right,
     "signal-weak": $fa-var-signal-weak,
     "signal-1": $fa-var-signal-1,
     "clock-five": $fa-var-clock-five,
     "retweet": $fa-var-retweet,
     "car-rear": $fa-var-car-rear,
     "car-alt": $fa-var-car-alt,
     "pump-soap": $fa-var-pump-soap,
     "computer-classic": $fa-var-computer-classic,
     "frame": $fa-var-frame,
     "video-slash": $fa-var-video-slash,
     "battery-quarter": $fa-var-battery-quarter,
     "battery-2": $fa-var-battery-2,
     "ellipsis-stroke": $fa-var-ellipsis-stroke,
     "ellipsis-h-alt": $fa-var-ellipsis-h-alt,
     "radio": $fa-var-radio,
     "baby-carriage": $fa-var-baby-carriage,
     "carriage-baby": $fa-var-carriage-baby,
     "face-expressionless": $fa-var-face-expressionless,
     "down-to-dotted-line": $fa-var-down-to-dotted-line,
     "cloud-music": $fa-var-cloud-music,
     "traffic-light": $fa-var-traffic-light,
     "cloud-minus": $fa-var-cloud-minus,
     "thermometer": $fa-var-thermometer,
     "shield-minus": $fa-var-shield-minus,
     "vr-cardboard": $fa-var-vr-cardboard,
     "car-tilt": $fa-var-car-tilt,
     "gauge-circle-minus": $fa-var-gauge-circle-minus,
     "brightness-low": $fa-var-brightness-low,
     "hand-middle-finger": $fa-var-hand-middle-finger,
     "percent": $fa-var-percent,
     "percentage": $fa-var-percentage,
     "truck-moving": $fa-var-truck-moving,
     "glass-water-droplet": $fa-var-glass-water-droplet,
     "conveyor-belt": $fa-var-conveyor-belt,
     "location-check": $fa-var-location-check,
     "map-marker-check": $fa-var-map-marker-check,
     "coin-vertical": $fa-var-coin-vertical,
     "display": $fa-var-display,
     "person-sign": $fa-var-person-sign,
     "face-smile": $fa-var-face-smile,
     "smile": $fa-var-smile,
     "phone-hangup": $fa-var-phone-hangup,
     "signature-slash": $fa-var-signature-slash,
     "thumbtack": $fa-var-thumbtack,
     "thumb-tack": $fa-var-thumb-tack,
     "wheat-slash": $fa-var-wheat-slash,
     "trophy": $fa-var-trophy,
     "clouds-sun": $fa-var-clouds-sun,
     "person-praying": $fa-var-person-praying,
     "pray": $fa-var-pray,
     "hammer": $fa-var-hammer,
     "face-vomit": $fa-var-face-vomit,
     "speakers": $fa-var-speakers,
     "tty-answer": $fa-var-tty-answer,
     "teletype-answer": $fa-var-teletype-answer,
     "mug-tea-saucer": $fa-var-mug-tea-saucer,
     "diagram-lean-canvas": $fa-var-diagram-lean-canvas,
     "alt": $fa-var-alt,
     "dial": $fa-var-dial,
     "dial-med-high": $fa-var-dial-med-high,
     "hand-peace": $fa-var-hand-peace,
     "circle-trash": $fa-var-circle-trash,
     "trash-circle": $fa-var-trash-circle,
     "rotate": $fa-var-rotate,
     "sync-alt": $fa-var-sync-alt,
     "circle-quarters": $fa-var-circle-quarters,
     "spinner": $fa-var-spinner,
     "tower-control": $fa-var-tower-control,
     "arrow-up-triangle-square": $fa-var-arrow-up-triangle-square,
     "sort-shapes-up": $fa-var-sort-shapes-up,
     "whale": $fa-var-whale,
     "robot": $fa-var-robot,
     "peace": $fa-var-peace,
     "party-horn": $fa-var-party-horn,
     "gears": $fa-var-gears,
     "cogs": $fa-var-cogs,
     "sun-bright": $fa-var-sun-bright,
     "sun-alt": $fa-var-sun-alt,
     "warehouse": $fa-var-warehouse,
     "lock-keyhole-open": $fa-var-lock-keyhole-open,
     "lock-open-alt": $fa-var-lock-open-alt,
     "square-fragile": $fa-var-square-fragile,
     "box-fragile": $fa-var-box-fragile,
     "square-wine-glass-crack": $fa-var-square-wine-glass-crack,
     "arrow-up-right-dots": $fa-var-arrow-up-right-dots,
     "square-n": $fa-var-square-n,
     "splotch": $fa-var-splotch,
     "face-grin-hearts": $fa-var-face-grin-hearts,
     "grin-hearts": $fa-var-grin-hearts,
     "meter": $fa-var-meter,
     "mandolin": $fa-var-mandolin,
     "dice-four": $fa-var-dice-four,
     "sim-card": $fa-var-sim-card,
     "transgender": $fa-var-transgender,
     "transgender-alt": $fa-var-transgender-alt,
     "mercury": $fa-var-mercury,
     "up-from-bracket": $fa-var-up-from-bracket,
     "knife-kitchen": $fa-var-knife-kitchen,
     "border-right": $fa-var-border-right,
     "arrow-turn-down": $fa-var-arrow-turn-down,
     "level-down": $fa-var-level-down,
     "spade": $fa-var-spade,
     "card-spade": $fa-var-card-spade,
     "line-columns": $fa-var-line-columns,
     "arrow-right-to-line": $fa-var-arrow-right-to-line,
     "arrow-to-right": $fa-var-arrow-to-right,
     "person-falling-burst": $fa-var-person-falling-burst,
     "flag-pennant": $fa-var-flag-pennant,
     "pennant": $fa-var-pennant,
     "conveyor-belt-empty": $fa-var-conveyor-belt-empty,
     "award": $fa-var-award,
     "ticket-simple": $fa-var-ticket-simple,
     "ticket-alt": $fa-var-ticket-alt,
     "building": $fa-var-building,
     "angles-left": $fa-var-angles-left,
     "angle-double-left": $fa-var-angle-double-left,
     "camcorder": $fa-var-camcorder,
     "video-handheld": $fa-var-video-handheld,
     "pancakes": $fa-var-pancakes,
     "album-circle-user": $fa-var-album-circle-user,
     "qrcode": $fa-var-qrcode,
     "dice-d10": $fa-var-dice-d10,
     "fireplace": $fa-var-fireplace,
     "browser": $fa-var-browser,
     "pen-paintbrush": $fa-var-pen-paintbrush,
     "pencil-paintbrush": $fa-var-pencil-paintbrush,
     "fish-cooked": $fa-var-fish-cooked,
     "chair-office": $fa-var-chair-office,
     "nesting-dolls": $fa-var-nesting-dolls,
     "clock-rotate-left": $fa-var-clock-rotate-left,
     "history": $fa-var-history,
     "trumpet": $fa-var-trumpet,
     "face-grin-beam-sweat": $fa-var-face-grin-beam-sweat,
     "grin-beam-sweat": $fa-var-grin-beam-sweat,
     "fire-smoke": $fa-var-fire-smoke,
     "phone-missed": $fa-var-phone-missed,
     "file-export": $fa-var-file-export,
     "arrow-right-from-file": $fa-var-arrow-right-from-file,
     "shield": $fa-var-shield,
     "shield-blank": $fa-var-shield-blank,
     "arrow-up-short-wide": $fa-var-arrow-up-short-wide,
     "sort-amount-up-alt": $fa-var-sort-amount-up-alt,
     "arrows-repeat-1": $fa-var-arrows-repeat-1,
     "repeat-1-alt": $fa-var-repeat-1-alt,
     "gun-slash": $fa-var-gun-slash,
     "avocado": $fa-var-avocado,
     "binary": $fa-var-binary,
     "glasses-round": $fa-var-glasses-round,
     "glasses-alt": $fa-var-glasses-alt,
     "phone-plus": $fa-var-phone-plus,
     "ditto": $fa-var-ditto,
     "person-seat": $fa-var-person-seat,
     "house-medical": $fa-var-house-medical,
     "golf-ball-tee": $fa-var-golf-ball-tee,
     "golf-ball": $fa-var-golf-ball,
     "circle-chevron-left": $fa-var-circle-chevron-left,
     "chevron-circle-left": $fa-var-chevron-circle-left,
     "house-chimney-window": $fa-var-house-chimney-window,
     "scythe": $fa-var-scythe,
     "pen-nib": $fa-var-pen-nib,
     "ban-parking": $fa-var-ban-parking,
     "parking-circle-slash": $fa-var-parking-circle-slash,
     "tent-arrow-turn-left": $fa-var-tent-arrow-turn-left,
     "face-diagonal-mouth": $fa-var-face-diagonal-mouth,
     "diagram-cells": $fa-var-diagram-cells,
     "cricket-bat-ball": $fa-var-cricket-bat-ball,
     "cricket": $fa-var-cricket,
     "tents": $fa-var-tents,
     "wand-magic": $fa-var-wand-magic,
     "magic": $fa-var-magic,
     "dog": $fa-var-dog,
     "pen-line": $fa-var-pen-line,
     "atom-simple": $fa-var-atom-simple,
     "atom-alt": $fa-var-atom-alt,
     "ampersand": $fa-var-ampersand,
     "carrot": $fa-var-carrot,
     "arrow-up-from-line": $fa-var-arrow-up-from-line,
     "arrow-from-bottom": $fa-var-arrow-from-bottom,
     "moon": $fa-var-moon,
     "pen-slash": $fa-var-pen-slash,
     "wine-glass-empty": $fa-var-wine-glass-empty,
     "wine-glass-alt": $fa-var-wine-glass-alt,
     "square-star": $fa-var-square-star,
     "cheese": $fa-var-cheese,
     "send-backward": $fa-var-send-backward,
     "yin-yang": $fa-var-yin-yang,
     "music": $fa-var-music,
     "compass-slash": $fa-var-compass-slash,
     "clock-one": $fa-var-clock-one,
     "file-music": $fa-var-file-music,
     "code-commit": $fa-var-code-commit,
     "temperature-low": $fa-var-temperature-low,
     "person-biking": $fa-var-person-biking,
     "biking": $fa-var-biking,
     "skeleton": $fa-var-skeleton,
     "circle-g": $fa-var-circle-g,
     "circle-arrow-up-left": $fa-var-circle-arrow-up-left,
     "coin-blank": $fa-var-coin-blank,
     "broom": $fa-var-broom,
     "vacuum": $fa-var-vacuum,
     "shield-heart": $fa-var-shield-heart,
     "card-heart": $fa-var-card-heart,
     "lightbulb-cfl-on": $fa-var-lightbulb-cfl-on,
     "melon": $fa-var-melon,
     "gopuram": $fa-var-gopuram,
     "earth-oceania": $fa-var-earth-oceania,
     "globe-oceania": $fa-var-globe-oceania,
     "container-storage": $fa-var-container-storage,
     "face-pouting": $fa-var-face-pouting,
     "square-xmark": $fa-var-square-xmark,
     "times-square": $fa-var-times-square,
     "xmark-square": $fa-var-xmark-square,
     "face-explode": $fa-var-face-explode,
     "exploding-head": $fa-var-exploding-head,
     "hashtag": $fa-var-hashtag,
     "up-right-and-down-left-from-center": $fa-var-up-right-and-down-left-from-center,
     "expand-alt": $fa-var-expand-alt,
     "oil-can": $fa-var-oil-can,
     "t": $fa-var-t,
     "transformer-bolt": $fa-var-transformer-bolt,
     "hippo": $fa-var-hippo,
     "chart-column": $fa-var-chart-column,
     "cassette-vhs": $fa-var-cassette-vhs,
     "vhs": $fa-var-vhs,
     "infinity": $fa-var-infinity,
     "vial-circle-check": $fa-var-vial-circle-check,
     "chimney": $fa-var-chimney,
     "object-intersect": $fa-var-object-intersect,
     "person-arrow-down-to-line": $fa-var-person-arrow-down-to-line,
     "voicemail": $fa-var-voicemail,
     "block-brick": $fa-var-block-brick,
     "wall-brick": $fa-var-wall-brick,
     "fan": $fa-var-fan,
     "bags-shopping": $fa-var-bags-shopping,
     "paragraph-left": $fa-var-paragraph-left,
     "paragraph-rtl": $fa-var-paragraph-rtl,
     "person-walking-luggage": $fa-var-person-walking-luggage,
     "caravan-simple": $fa-var-caravan-simple,
     "caravan-alt": $fa-var-caravan-alt,
     "turtle": $fa-var-turtle,
     "up-down": $fa-var-up-down,
     "arrows-alt-v": $fa-var-arrows-alt-v,
     "cloud-moon-rain": $fa-var-cloud-moon-rain,
     "booth-curtain": $fa-var-booth-curtain,
     "calendar": $fa-var-calendar,
     "box-heart": $fa-var-box-heart,
     "trailer": $fa-var-trailer,
     "user-doctor-message": $fa-var-user-doctor-message,
     "user-md-chat": $fa-var-user-md-chat,
     "bahai": $fa-var-bahai,
     "haykal": $fa-var-haykal,
     "amp-guitar": $fa-var-amp-guitar,
     "sd-card": $fa-var-sd-card,
     "volume-slash": $fa-var-volume-slash,
     "border-bottom": $fa-var-border-bottom,
     "wifi-weak": $fa-var-wifi-weak,
     "wifi-1": $fa-var-wifi-1,
     "dragon": $fa-var-dragon,
     "shoe-prints": $fa-var-shoe-prints,
     "circle-plus": $fa-var-circle-plus,
     "plus-circle": $fa-var-plus-circle,
     "face-grin-tongue-wink": $fa-var-face-grin-tongue-wink,
     "grin-tongue-wink": $fa-var-grin-tongue-wink,
     "hand-holding": $fa-var-hand-holding,
     "plug-circle-exclamation": $fa-var-plug-circle-exclamation,
     "link-slash": $fa-var-link-slash,
     "chain-broken": $fa-var-chain-broken,
     "chain-slash": $fa-var-chain-slash,
     "unlink": $fa-var-unlink,
     "clone": $fa-var-clone,
     "person-walking-arrow-loop-left": $fa-var-person-walking-arrow-loop-left,
     "arrow-up-z-a": $fa-var-arrow-up-z-a,
     "sort-alpha-up-alt": $fa-var-sort-alpha-up-alt,
     "fire-flame-curved": $fa-var-fire-flame-curved,
     "fire-alt": $fa-var-fire-alt,
     "tornado": $fa-var-tornado,
     "file-circle-plus": $fa-var-file-circle-plus,
     "delete-right": $fa-var-delete-right,
     "book-quran": $fa-var-book-quran,
     "quran": $fa-var-quran,
     "circle-quarter": $fa-var-circle-quarter,
     "anchor": $fa-var-anchor,
     "border-all": $fa-var-border-all,
     "function": $fa-var-function,
     "face-angry": $fa-var-face-angry,
     "angry": $fa-var-angry,
     "people-simple": $fa-var-people-simple,
     "cookie-bite": $fa-var-cookie-bite,
     "arrow-trend-down": $fa-var-arrow-trend-down,
     "rss": $fa-var-rss,
     "feed": $fa-var-feed,
     "face-monocle": $fa-var-face-monocle,
     "draw-polygon": $fa-var-draw-polygon,
     "scale-balanced": $fa-var-scale-balanced,
     "balance-scale": $fa-var-balance-scale,
     "calendar-lines": $fa-var-calendar-lines,
     "calendar-note": $fa-var-calendar-note,
     "arrow-down-big-small": $fa-var-arrow-down-big-small,
     "sort-size-down": $fa-var-sort-size-down,
     "gauge-simple-high": $fa-var-gauge-simple-high,
     "tachometer": $fa-var-tachometer,
     "tachometer-fast": $fa-var-tachometer-fast,
     "do-not-enter": $fa-var-do-not-enter,
     "shower": $fa-var-shower,
     "dice-d8": $fa-var-dice-d8,
     "desktop": $fa-var-desktop,
     "desktop-alt": $fa-var-desktop-alt,
     "m": $fa-var-m,
     "grip-dots-vertical": $fa-var-grip-dots-vertical,
     "face-viewfinder": $fa-var-face-viewfinder,
     "soft-serve": $fa-var-soft-serve,
     "creemee": $fa-var-creemee,
     "h5": $fa-var-h5,
     "hand-back-point-down": $fa-var-hand-back-point-down,
     "table-list": $fa-var-table-list,
     "th-list": $fa-var-th-list,
     "comment-sms": $fa-var-comment-sms,
     "sms": $fa-var-sms,
     "rectangle": $fa-var-rectangle,
     "rectangle-landscape": $fa-var-rectangle-landscape,
     "clipboard-list-check": $fa-var-clipboard-list-check,
     "turkey": $fa-var-turkey,
     "book": $fa-var-book,
     "user-plus": $fa-var-user-plus,
     "ice-skate": $fa-var-ice-skate,
     "check": $fa-var-check,
     "battery-three-quarters": $fa-var-battery-three-quarters,
     "battery-4": $fa-var-battery-4,
     "tomato": $fa-var-tomato,
     "sword-laser": $fa-var-sword-laser,
     "house-circle-check": $fa-var-house-circle-check,
     "buildings": $fa-var-buildings,
     "angle-left": $fa-var-angle-left,
     "cart-flatbed-boxes": $fa-var-cart-flatbed-boxes,
     "dolly-flatbed-alt": $fa-var-dolly-flatbed-alt,
     "diagram-successor": $fa-var-diagram-successor,
     "truck-arrow-right": $fa-var-truck-arrow-right,
     "square-w": $fa-var-square-w,
     "arrows-split-up-and-left": $fa-var-arrows-split-up-and-left,
     "lamp": $fa-var-lamp,
     "airplay": $fa-var-airplay,
     "hand-fist": $fa-var-hand-fist,
     "fist-raised": $fa-var-fist-raised,
     "shield-quartered": $fa-var-shield-quartered,
     "slash-forward": $fa-var-slash-forward,
     "location-pen": $fa-var-location-pen,
     "map-marker-edit": $fa-var-map-marker-edit,
     "cloud-moon": $fa-var-cloud-moon,
     "pot-food": $fa-var-pot-food,
     "briefcase": $fa-var-briefcase,
     "person-falling": $fa-var-person-falling,
     "image-portrait": $fa-var-image-portrait,
     "portrait": $fa-var-portrait,
     "user-tag": $fa-var-user-tag,
     "rug": $fa-var-rug,
     "print-slash": $fa-var-print-slash,
     "earth-europe": $fa-var-earth-europe,
     "globe-europe": $fa-var-globe-europe,
     "cart-flatbed-suitcase": $fa-var-cart-flatbed-suitcase,
     "luggage-cart": $fa-var-luggage-cart,
     "hand-back-point-ribbon": $fa-var-hand-back-point-ribbon,
     "rectangle-xmark": $fa-var-rectangle-xmark,
     "rectangle-times": $fa-var-rectangle-times,
     "times-rectangle": $fa-var-times-rectangle,
     "window-close": $fa-var-window-close,
     "tire-rugged": $fa-var-tire-rugged,
     "lightbulb-dollar": $fa-var-lightbulb-dollar,
     "cowbell": $fa-var-cowbell,
     "baht-sign": $fa-var-baht-sign,
     "corner": $fa-var-corner,
     "chevrons-right": $fa-var-chevrons-right,
     "chevron-double-right": $fa-var-chevron-double-right,
     "book-open": $fa-var-book-open,
     "book-journal-whills": $fa-var-book-journal-whills,
     "journal-whills": $fa-var-journal-whills,
     "inhaler": $fa-var-inhaler,
     "handcuffs": $fa-var-handcuffs,
     "snake": $fa-var-snake,
     "triangle-exclamation": $fa-var-triangle-exclamation,
     "exclamation-triangle": $fa-var-exclamation-triangle,
     "warning": $fa-var-warning,
     "note-medical": $fa-var-note-medical,
     "database": $fa-var-database,
     "down-left": $fa-var-down-left,
     "share": $fa-var-share,
     "arrow-turn-right": $fa-var-arrow-turn-right,
     "mail-forward": $fa-var-mail-forward,
     "face-thinking": $fa-var-face-thinking,
     "turn-down-right": $fa-var-turn-down-right,
     "bottle-droplet": $fa-var-bottle-droplet,
     "mask-face": $fa-var-mask-face,
     "hill-rockslide": $fa-var-hill-rockslide,
     "scanner-keyboard": $fa-var-scanner-keyboard,
     "circle-o": $fa-var-circle-o,
     "grid-horizontal": $fa-var-grid-horizontal,
     "message-dollar": $fa-var-message-dollar,
     "comment-alt-dollar": $fa-var-comment-alt-dollar,
     "right-left": $fa-var-right-left,
     "exchange-alt": $fa-var-exchange-alt,
     "columns-3": $fa-var-columns-3,
     "paper-plane": $fa-var-paper-plane,
     "road-circle-exclamation": $fa-var-road-circle-exclamation,
     "dungeon": $fa-var-dungeon,
     "hand-holding-box": $fa-var-hand-holding-box,
     "input-text": $fa-var-input-text,
     "window-flip": $fa-var-window-flip,
     "window-alt": $fa-var-window-alt,
     "align-right": $fa-var-align-right,
     "scanner-gun": $fa-var-scanner-gun,
     "scanner": $fa-var-scanner,
     "tire": $fa-var-tire,
     "engine": $fa-var-engine,
     "money-bill-1-wave": $fa-var-money-bill-1-wave,
     "money-bill-wave-alt": $fa-var-money-bill-wave-alt,
     "life-ring": $fa-var-life-ring,
     "hands": $fa-var-hands,
     "sign-language": $fa-var-sign-language,
     "signing": $fa-var-signing,
     "circle-caret-right": $fa-var-circle-caret-right,
     "caret-circle-right": $fa-var-caret-circle-right,
     "wheat": $fa-var-wheat,
     "file-spreadsheet": $fa-var-file-spreadsheet,
     "audio-description-slash": $fa-var-audio-description-slash,
     "calendar-day": $fa-var-calendar-day,
     "water-ladder": $fa-var-water-ladder,
     "ladder-water": $fa-var-ladder-water,
     "swimming-pool": $fa-var-swimming-pool,
     "arrows-up-down": $fa-var-arrows-up-down,
     "arrows-v": $fa-var-arrows-v,
     "chess-pawn-piece": $fa-var-chess-pawn-piece,
     "chess-pawn-alt": $fa-var-chess-pawn-alt,
     "face-grimace": $fa-var-face-grimace,
     "grimace": $fa-var-grimace,
     "wheelchair-move": $fa-var-wheelchair-move,
     "wheelchair-alt": $fa-var-wheelchair-alt,
     "turn-down": $fa-var-turn-down,
     "level-down-alt": $fa-var-level-down-alt,
     "square-s": $fa-var-square-s,
     "rectangle-barcode": $fa-var-rectangle-barcode,
     "barcode-alt": $fa-var-barcode-alt,
     "person-walking-arrow-right": $fa-var-person-walking-arrow-right,
     "square-envelope": $fa-var-square-envelope,
     "envelope-square": $fa-var-envelope-square,
     "dice": $fa-var-dice,
     "unicorn": $fa-var-unicorn,
     "bowling-ball": $fa-var-bowling-ball,
     "pompebled": $fa-var-pompebled,
     "brain": $fa-var-brain,
     "watch-smart": $fa-var-watch-smart,
     "book-user": $fa-var-book-user,
     "sensor-cloud": $fa-var-sensor-cloud,
     "sensor-smoke": $fa-var-sensor-smoke,
     "clapperboard-play": $fa-var-clapperboard-play,
     "bandage": $fa-var-bandage,
     "band-aid": $fa-var-band-aid,
     "calendar-minus": $fa-var-calendar-minus,
     "circle-xmark": $fa-var-circle-xmark,
     "times-circle": $fa-var-times-circle,
     "xmark-circle": $fa-var-xmark-circle,
     "circle-4": $fa-var-circle-4,
     "gifts": $fa-var-gifts,
     "album-collection": $fa-var-album-collection,
     "hotel": $fa-var-hotel,
     "earth-asia": $fa-var-earth-asia,
     "globe-asia": $fa-var-globe-asia,
     "id-card-clip": $fa-var-id-card-clip,
     "id-card-alt": $fa-var-id-card-alt,
     "magnifying-glass-plus": $fa-var-magnifying-glass-plus,
     "search-plus": $fa-var-search-plus,
     "thumbs-up": $fa-var-thumbs-up,
     "cloud-showers": $fa-var-cloud-showers,
     "user-clock": $fa-var-user-clock,
     "onion": $fa-var-onion,
     "clock-twelve-thirty": $fa-var-clock-twelve-thirty,
     "arrow-down-to-dotted-line": $fa-var-arrow-down-to-dotted-line,
     "hand-dots": $fa-var-hand-dots,
     "allergies": $fa-var-allergies,
     "file-invoice": $fa-var-file-invoice,
     "window-minimize": $fa-var-window-minimize,
     "rectangle-wide": $fa-var-rectangle-wide,
     "comment-arrow-up": $fa-var-comment-arrow-up,
     "garlic": $fa-var-garlic,
     "mug-saucer": $fa-var-mug-saucer,
     "coffee": $fa-var-coffee,
     "brush": $fa-var-brush,
     "tree-decorated": $fa-var-tree-decorated,
     "mask": $fa-var-mask,
     "calendar-heart": $fa-var-calendar-heart,
     "magnifying-glass-minus": $fa-var-magnifying-glass-minus,
     "search-minus": $fa-var-search-minus,
     "flower": $fa-var-flower,
     "ruler-vertical": $fa-var-ruler-vertical,
     "user-large": $fa-var-user-large,
     "user-alt": $fa-var-user-alt,
     "starship-freighter": $fa-var-starship-freighter,
     "train-tram": $fa-var-train-tram,
     "bridge-suspension": $fa-var-bridge-suspension,
     "trash-check": $fa-var-trash-check,
     "user-nurse": $fa-var-user-nurse,
     "boombox": $fa-var-boombox,
     "syringe": $fa-var-syringe,
     "cloud-sun": $fa-var-cloud-sun,
     "shield-exclamation": $fa-var-shield-exclamation,
     "stopwatch-20": $fa-var-stopwatch-20,
     "square-full": $fa-var-square-full,
     "grip-dots": $fa-var-grip-dots,
     "comment-exclamation": $fa-var-comment-exclamation,
     "pen-swirl": $fa-var-pen-swirl,
     "falafel": $fa-var-falafel,
     "circle-2": $fa-var-circle-2,
     "magnet": $fa-var-magnet,
     "jar": $fa-var-jar,
     "gramophone": $fa-var-gramophone,
     "dice-d12": $fa-var-dice-d12,
     "note-sticky": $fa-var-note-sticky,
     "sticky-note": $fa-var-sticky-note,
     "down": $fa-var-down,
     "arrow-alt-down": $fa-var-arrow-alt-down,
     "hundred-points": $fa-var-hundred-points,
     "100": $fa-var-100,
     "paperclip-vertical": $fa-var-paperclip-vertical,
     "wind-warning": $fa-var-wind-warning,
     "wind-circle-exclamation": $fa-var-wind-circle-exclamation,
     "location-pin-slash": $fa-var-location-pin-slash,
     "map-marker-slash": $fa-var-map-marker-slash,
     "face-sad-sweat": $fa-var-face-sad-sweat,
     "bug-slash": $fa-var-bug-slash,
     "cupcake": $fa-var-cupcake,
     "light-switch-off": $fa-var-light-switch-off,
     "toggle-large-off": $fa-var-toggle-large-off,
     "pen-fancy-slash": $fa-var-pen-fancy-slash,
     "truck-container": $fa-var-truck-container,
     "boot": $fa-var-boot,
     "arrow-up-from-water-pump": $fa-var-arrow-up-from-water-pump,
     "file-check": $fa-var-file-check,
     "bone": $fa-var-bone,
     "cards-blank": $fa-var-cards-blank,
     "circle-3": $fa-var-circle-3,
     "bench-tree": $fa-var-bench-tree,
     "keyboard-brightness-low": $fa-var-keyboard-brightness-low,
     "ski-boot-ski": $fa-var-ski-boot-ski,
     "brain-circuit": $fa-var-brain-circuit,
     "user-injured": $fa-var-user-injured,
     "block-brick-fire": $fa-var-block-brick-fire,
     "firewall": $fa-var-firewall,
     "face-sad-tear": $fa-var-face-sad-tear,
     "sad-tear": $fa-var-sad-tear,
     "plane": $fa-var-plane,
     "tent-arrows-down": $fa-var-tent-arrows-down,
     "exclamation": $fa-var-exclamation,
     "arrows-spin": $fa-var-arrows-spin,
     "face-smile-relaxed": $fa-var-face-smile-relaxed,
     "comment-xmark": $fa-var-comment-xmark,
     "comment-times": $fa-var-comment-times,
     "print": $fa-var-print,
     "turkish-lira-sign": $fa-var-turkish-lira-sign,
     "try": $fa-var-try,
     "turkish-lira": $fa-var-turkish-lira,
     "face-nose-steam": $fa-var-face-nose-steam,
     "circle-waveform-lines": $fa-var-circle-waveform-lines,
     "waveform-circle": $fa-var-waveform-circle,
     "dollar-sign": $fa-var-dollar-sign,
     "dollar": $fa-var-dollar,
     "usd": $fa-var-usd,
     "ferris-wheel": $fa-var-ferris-wheel,
     "computer-speaker": $fa-var-computer-speaker,
     "skull-cow": $fa-var-skull-cow,
     "x": $fa-var-x,
     "magnifying-glass-dollar": $fa-var-magnifying-glass-dollar,
     "search-dollar": $fa-var-search-dollar,
     "users-gear": $fa-var-users-gear,
     "users-cog": $fa-var-users-cog,
     "person-military-pointing": $fa-var-person-military-pointing,
     "building-columns": $fa-var-building-columns,
     "bank": $fa-var-bank,
     "institution": $fa-var-institution,
     "museum": $fa-var-museum,
     "university": $fa-var-university,
     "circle-t": $fa-var-circle-t,
     "sack": $fa-var-sack,
     "grid-2": $fa-var-grid-2,
     "camera-cctv": $fa-var-camera-cctv,
     "cctv": $fa-var-cctv,
     "umbrella": $fa-var-umbrella,
     "trowel": $fa-var-trowel,
     "horizontal-rule": $fa-var-horizontal-rule,
     "bed-front": $fa-var-bed-front,
     "bed-alt": $fa-var-bed-alt,
     "d": $fa-var-d,
     "stapler": $fa-var-stapler,
     "masks-theater": $fa-var-masks-theater,
     "theater-masks": $fa-var-theater-masks,
     "kip-sign": $fa-var-kip-sign,
     "face-woozy": $fa-var-face-woozy,
     "cloud-question": $fa-var-cloud-question,
     "pineapple": $fa-var-pineapple,
     "hand-point-left": $fa-var-hand-point-left,
     "gallery-thumbnails": $fa-var-gallery-thumbnails,
     "circle-j": $fa-var-circle-j,
     "eyes": $fa-var-eyes,
     "handshake-simple": $fa-var-handshake-simple,
     "handshake-alt": $fa-var-handshake-alt,
     "page-caret-up": $fa-var-page-caret-up,
     "file-caret-up": $fa-var-file-caret-up,
     "jet-fighter": $fa-var-jet-fighter,
     "fighter-jet": $fa-var-fighter-jet,
     "comet": $fa-var-comet,
     "square-share-nodes": $fa-var-square-share-nodes,
     "share-alt-square": $fa-var-share-alt-square,
     "shield-keyhole": $fa-var-shield-keyhole,
     "barcode": $fa-var-barcode,
     "plus-minus": $fa-var-plus-minus,
     "square-sliders-vertical": $fa-var-square-sliders-vertical,
     "sliders-v-square": $fa-var-sliders-v-square,
     "video": $fa-var-video,
     "video-camera": $fa-var-video-camera,
     "message-middle": $fa-var-message-middle,
     "comment-middle-alt": $fa-var-comment-middle-alt,
     "graduation-cap": $fa-var-graduation-cap,
     "mortar-board": $fa-var-mortar-board,
     "hand-holding-medical": $fa-var-hand-holding-medical,
     "person-circle-check": $fa-var-person-circle-check,
     "square-z": $fa-var-square-z,
     "message-text": $fa-var-message-text,
     "comment-alt-text": $fa-var-comment-alt-text,
     "turn-up": $fa-var-turn-up,
     "level-up-alt": $fa-var-level-up-alt,
);

$fa-brand-icons: (
     "monero": $fa-var-monero,
     "hooli": $fa-var-hooli,
     "yelp": $fa-var-yelp,
     "cc-visa": $fa-var-cc-visa,
     "lastfm": $fa-var-lastfm,
     "shopware": $fa-var-shopware,
     "creative-commons-nc": $fa-var-creative-commons-nc,
     "aws": $fa-var-aws,
     "redhat": $fa-var-redhat,
     "yoast": $fa-var-yoast,
     "cloudflare": $fa-var-cloudflare,
     "ups": $fa-var-ups,
     "wpexplorer": $fa-var-wpexplorer,
     "dyalog": $fa-var-dyalog,
     "bity": $fa-var-bity,
     "stackpath": $fa-var-stackpath,
     "buysellads": $fa-var-buysellads,
     "first-order": $fa-var-first-order,
     "modx": $fa-var-modx,
     "guilded": $fa-var-guilded,
     "vnv": $fa-var-vnv,
     "square-js": $fa-var-square-js,
     "js-square": $fa-var-js-square,
     "microsoft": $fa-var-microsoft,
     "qq": $fa-var-qq,
     "orcid": $fa-var-orcid,
     "java": $fa-var-java,
     "invision": $fa-var-invision,
     "creative-commons-pd-alt": $fa-var-creative-commons-pd-alt,
     "centercode": $fa-var-centercode,
     "glide-g": $fa-var-glide-g,
     "drupal": $fa-var-drupal,
     "hire-a-helper": $fa-var-hire-a-helper,
     "creative-commons-by": $fa-var-creative-commons-by,
     "unity": $fa-var-unity,
     "whmcs": $fa-var-whmcs,
     "rocketchat": $fa-var-rocketchat,
     "vk": $fa-var-vk,
     "untappd": $fa-var-untappd,
     "mailchimp": $fa-var-mailchimp,
     "css3-alt": $fa-var-css3-alt,
     "square-reddit": $fa-var-square-reddit,
     "reddit-square": $fa-var-reddit-square,
     "vimeo-v": $fa-var-vimeo-v,
     "contao": $fa-var-contao,
     "square-font-awesome": $fa-var-square-font-awesome,
     "deskpro": $fa-var-deskpro,
     "sistrix": $fa-var-sistrix,
     "square-instagram": $fa-var-square-instagram,
     "instagram-square": $fa-var-instagram-square,
     "battle-net": $fa-var-battle-net,
     "the-red-yeti": $fa-var-the-red-yeti,
     "square-hacker-news": $fa-var-square-hacker-news,
     "hacker-news-square": $fa-var-hacker-news-square,
     "edge": $fa-var-edge,
     "napster": $fa-var-napster,
     "square-snapchat": $fa-var-square-snapchat,
     "snapchat-square": $fa-var-snapchat-square,
     "google-plus-g": $fa-var-google-plus-g,
     "artstation": $fa-var-artstation,
     "markdown": $fa-var-markdown,
     "sourcetree": $fa-var-sourcetree,
     "google-plus": $fa-var-google-plus,
     "diaspora": $fa-var-diaspora,
     "foursquare": $fa-var-foursquare,
     "stack-overflow": $fa-var-stack-overflow,
     "github-alt": $fa-var-github-alt,
     "phoenix-squadron": $fa-var-phoenix-squadron,
     "pagelines": $fa-var-pagelines,
     "algolia": $fa-var-algolia,
     "red-river": $fa-var-red-river,
     "creative-commons-sa": $fa-var-creative-commons-sa,
     "safari": $fa-var-safari,
     "google": $fa-var-google,
     "square-font-awesome-stroke": $fa-var-square-font-awesome-stroke,
     "font-awesome-alt": $fa-var-font-awesome-alt,
     "atlassian": $fa-var-atlassian,
     "linkedin-in": $fa-var-linkedin-in,
     "digital-ocean": $fa-var-digital-ocean,
     "nimblr": $fa-var-nimblr,
     "chromecast": $fa-var-chromecast,
     "evernote": $fa-var-evernote,
     "hacker-news": $fa-var-hacker-news,
     "creative-commons-sampling": $fa-var-creative-commons-sampling,
     "adversal": $fa-var-adversal,
     "creative-commons": $fa-var-creative-commons,
     "watchman-monitoring": $fa-var-watchman-monitoring,
     "fonticons": $fa-var-fonticons,
     "weixin": $fa-var-weixin,
     "shirtsinbulk": $fa-var-shirtsinbulk,
     "codepen": $fa-var-codepen,
     "git-alt": $fa-var-git-alt,
     "lyft": $fa-var-lyft,
     "rev": $fa-var-rev,
     "windows": $fa-var-windows,
     "wizards-of-the-coast": $fa-var-wizards-of-the-coast,
     "square-viadeo": $fa-var-square-viadeo,
     "viadeo-square": $fa-var-viadeo-square,
     "meetup": $fa-var-meetup,
     "centos": $fa-var-centos,
     "adn": $fa-var-adn,
     "cloudsmith": $fa-var-cloudsmith,
     "pied-piper-alt": $fa-var-pied-piper-alt,
     "square-dribbble": $fa-var-square-dribbble,
     "dribbble-square": $fa-var-dribbble-square,
     "codiepie": $fa-var-codiepie,
     "node": $fa-var-node,
     "mix": $fa-var-mix,
     "steam": $fa-var-steam,
     "cc-apple-pay": $fa-var-cc-apple-pay,
     "scribd": $fa-var-scribd,
     "openid": $fa-var-openid,
     "instalod": $fa-var-instalod,
     "expeditedssl": $fa-var-expeditedssl,
     "sellcast": $fa-var-sellcast,
     "square-twitter": $fa-var-square-twitter,
     "twitter-square": $fa-var-twitter-square,
     "r-project": $fa-var-r-project,
     "delicious": $fa-var-delicious,
     "freebsd": $fa-var-freebsd,
     "vuejs": $fa-var-vuejs,
     "accusoft": $fa-var-accusoft,
     "ioxhost": $fa-var-ioxhost,
     "fonticons-fi": $fa-var-fonticons-fi,
     "app-store": $fa-var-app-store,
     "cc-mastercard": $fa-var-cc-mastercard,
     "itunes-note": $fa-var-itunes-note,
     "golang": $fa-var-golang,
     "kickstarter": $fa-var-kickstarter,
     "grav": $fa-var-grav,
     "weibo": $fa-var-weibo,
     "uncharted": $fa-var-uncharted,
     "firstdraft": $fa-var-firstdraft,
     "square-youtube": $fa-var-square-youtube,
     "youtube-square": $fa-var-youtube-square,
     "wikipedia-w": $fa-var-wikipedia-w,
     "wpressr": $fa-var-wpressr,
     "rendact": $fa-var-rendact,
     "angellist": $fa-var-angellist,
     "galactic-republic": $fa-var-galactic-republic,
     "nfc-directional": $fa-var-nfc-directional,
     "skype": $fa-var-skype,
     "joget": $fa-var-joget,
     "fedora": $fa-var-fedora,
     "stripe-s": $fa-var-stripe-s,
     "meta": $fa-var-meta,
     "laravel": $fa-var-laravel,
     "hotjar": $fa-var-hotjar,
     "bluetooth-b": $fa-var-bluetooth-b,
     "sticker-mule": $fa-var-sticker-mule,
     "creative-commons-zero": $fa-var-creative-commons-zero,
     "hips": $fa-var-hips,
     "behance": $fa-var-behance,
     "reddit": $fa-var-reddit,
     "discord": $fa-var-discord,
     "chrome": $fa-var-chrome,
     "app-store-ios": $fa-var-app-store-ios,
     "cc-discover": $fa-var-cc-discover,
     "wpbeginner": $fa-var-wpbeginner,
     "confluence": $fa-var-confluence,
     "mdb": $fa-var-mdb,
     "dochub": $fa-var-dochub,
     "accessible-icon": $fa-var-accessible-icon,
     "ebay": $fa-var-ebay,
     "amazon": $fa-var-amazon,
     "unsplash": $fa-var-unsplash,
     "yarn": $fa-var-yarn,
     "square-steam": $fa-var-square-steam,
     "steam-square": $fa-var-steam-square,
     "500px": $fa-var-500px,
     "square-vimeo": $fa-var-square-vimeo,
     "vimeo-square": $fa-var-vimeo-square,
     "asymmetrik": $fa-var-asymmetrik,
     "font-awesome": $fa-var-font-awesome,
     "font-awesome-flag": $fa-var-font-awesome-flag,
     "font-awesome-logo-full": $fa-var-font-awesome-logo-full,
     "gratipay": $fa-var-gratipay,
     "apple": $fa-var-apple,
     "hive": $fa-var-hive,
     "gitkraken": $fa-var-gitkraken,
     "keybase": $fa-var-keybase,
     "apple-pay": $fa-var-apple-pay,
     "padlet": $fa-var-padlet,
     "amazon-pay": $fa-var-amazon-pay,
     "square-github": $fa-var-square-github,
     "github-square": $fa-var-github-square,
     "stumbleupon": $fa-var-stumbleupon,
     "fedex": $fa-var-fedex,
     "phoenix-framework": $fa-var-phoenix-framework,
     "shopify": $fa-var-shopify,
     "neos": $fa-var-neos,
     "hackerrank": $fa-var-hackerrank,
     "researchgate": $fa-var-researchgate,
     "swift": $fa-var-swift,
     "angular": $fa-var-angular,
     "speakap": $fa-var-speakap,
     "angrycreative": $fa-var-angrycreative,
     "y-combinator": $fa-var-y-combinator,
     "empire": $fa-var-empire,
     "envira": $fa-var-envira,
     "square-gitlab": $fa-var-square-gitlab,
     "gitlab-square": $fa-var-gitlab-square,
     "studiovinari": $fa-var-studiovinari,
     "pied-piper": $fa-var-pied-piper,
     "wordpress": $fa-var-wordpress,
     "product-hunt": $fa-var-product-hunt,
     "firefox": $fa-var-firefox,
     "linode": $fa-var-linode,
     "goodreads": $fa-var-goodreads,
     "square-odnoklassniki": $fa-var-square-odnoklassniki,
     "odnoklassniki-square": $fa-var-odnoklassniki-square,
     "jsfiddle": $fa-var-jsfiddle,
     "sith": $fa-var-sith,
     "themeisle": $fa-var-themeisle,
     "page4": $fa-var-page4,
     "hashnode": $fa-var-hashnode,
     "react": $fa-var-react,
     "cc-paypal": $fa-var-cc-paypal,
     "squarespace": $fa-var-squarespace,
     "cc-stripe": $fa-var-cc-stripe,
     "creative-commons-share": $fa-var-creative-commons-share,
     "bitcoin": $fa-var-bitcoin,
     "keycdn": $fa-var-keycdn,
     "opera": $fa-var-opera,
     "itch-io": $fa-var-itch-io,
     "umbraco": $fa-var-umbraco,
     "galactic-senate": $fa-var-galactic-senate,
     "ubuntu": $fa-var-ubuntu,
     "draft2digital": $fa-var-draft2digital,
     "stripe": $fa-var-stripe,
     "houzz": $fa-var-houzz,
     "gg": $fa-var-gg,
     "dhl": $fa-var-dhl,
     "square-pinterest": $fa-var-square-pinterest,
     "pinterest-square": $fa-var-pinterest-square,
     "xing": $fa-var-xing,
     "blackberry": $fa-var-blackberry,
     "creative-commons-pd": $fa-var-creative-commons-pd,
     "playstation": $fa-var-playstation,
     "quinscape": $fa-var-quinscape,
     "less": $fa-var-less,
     "blogger-b": $fa-var-blogger-b,
     "opencart": $fa-var-opencart,
     "vine": $fa-var-vine,
     "paypal": $fa-var-paypal,
     "gitlab": $fa-var-gitlab,
     "typo3": $fa-var-typo3,
     "reddit-alien": $fa-var-reddit-alien,
     "yahoo": $fa-var-yahoo,
     "dailymotion": $fa-var-dailymotion,
     "affiliatetheme": $fa-var-affiliatetheme,
     "pied-piper-pp": $fa-var-pied-piper-pp,
     "bootstrap": $fa-var-bootstrap,
     "odnoklassniki": $fa-var-odnoklassniki,
     "nfc-symbol": $fa-var-nfc-symbol,
     "ethereum": $fa-var-ethereum,
     "speaker-deck": $fa-var-speaker-deck,
     "creative-commons-nc-eu": $fa-var-creative-commons-nc-eu,
     "patreon": $fa-var-patreon,
     "avianex": $fa-var-avianex,
     "ello": $fa-var-ello,
     "gofore": $fa-var-gofore,
     "bimobject": $fa-var-bimobject,
     "facebook-f": $fa-var-facebook-f,
     "square-google-plus": $fa-var-square-google-plus,
     "google-plus-square": $fa-var-google-plus-square,
     "mandalorian": $fa-var-mandalorian,
     "first-order-alt": $fa-var-first-order-alt,
     "osi": $fa-var-osi,
     "google-wallet": $fa-var-google-wallet,
     "d-and-d-beyond": $fa-var-d-and-d-beyond,
     "periscope": $fa-var-periscope,
     "fulcrum": $fa-var-fulcrum,
     "cloudscale": $fa-var-cloudscale,
     "forumbee": $fa-var-forumbee,
     "mizuni": $fa-var-mizuni,
     "schlix": $fa-var-schlix,
     "square-xing": $fa-var-square-xing,
     "xing-square": $fa-var-xing-square,
     "bandcamp": $fa-var-bandcamp,
     "wpforms": $fa-var-wpforms,
     "cloudversify": $fa-var-cloudversify,
     "usps": $fa-var-usps,
     "megaport": $fa-var-megaport,
     "magento": $fa-var-magento,
     "spotify": $fa-var-spotify,
     "optin-monster": $fa-var-optin-monster,
     "fly": $fa-var-fly,
     "aviato": $fa-var-aviato,
     "itunes": $fa-var-itunes,
     "cuttlefish": $fa-var-cuttlefish,
     "blogger": $fa-var-blogger,
     "flickr": $fa-var-flickr,
     "viber": $fa-var-viber,
     "soundcloud": $fa-var-soundcloud,
     "digg": $fa-var-digg,
     "tencent-weibo": $fa-var-tencent-weibo,
     "symfony": $fa-var-symfony,
     "maxcdn": $fa-var-maxcdn,
     "etsy": $fa-var-etsy,
     "facebook-messenger": $fa-var-facebook-messenger,
     "audible": $fa-var-audible,
     "think-peaks": $fa-var-think-peaks,
     "bilibili": $fa-var-bilibili,
     "erlang": $fa-var-erlang,
     "cotton-bureau": $fa-var-cotton-bureau,
     "dashcube": $fa-var-dashcube,
     "42-group": $fa-var-42-group,
     "innosoft": $fa-var-innosoft,
     "stack-exchange": $fa-var-stack-exchange,
     "elementor": $fa-var-elementor,
     "square-pied-piper": $fa-var-square-pied-piper,
     "pied-piper-square": $fa-var-pied-piper-square,
     "creative-commons-nd": $fa-var-creative-commons-nd,
     "palfed": $fa-var-palfed,
     "superpowers": $fa-var-superpowers,
     "resolving": $fa-var-resolving,
     "xbox": $fa-var-xbox,
     "searchengin": $fa-var-searchengin,
     "tiktok": $fa-var-tiktok,
     "square-facebook": $fa-var-square-facebook,
     "facebook-square": $fa-var-facebook-square,
     "renren": $fa-var-renren,
     "linux": $fa-var-linux,
     "glide": $fa-var-glide,
     "linkedin": $fa-var-linkedin,
     "hubspot": $fa-var-hubspot,
     "deploydog": $fa-var-deploydog,
     "twitch": $fa-var-twitch,
     "ravelry": $fa-var-ravelry,
     "mixer": $fa-var-mixer,
     "square-lastfm": $fa-var-square-lastfm,
     "lastfm-square": $fa-var-lastfm-square,
     "vimeo": $fa-var-vimeo,
     "mendeley": $fa-var-mendeley,
     "uniregistry": $fa-var-uniregistry,
     "figma": $fa-var-figma,
     "creative-commons-remix": $fa-var-creative-commons-remix,
     "cc-amazon-pay": $fa-var-cc-amazon-pay,
     "dropbox": $fa-var-dropbox,
     "instagram": $fa-var-instagram,
     "cmplid": $fa-var-cmplid,
     "facebook": $fa-var-facebook,
     "gripfire": $fa-var-gripfire,
     "jedi-order": $fa-var-jedi-order,
     "uikit": $fa-var-uikit,
     "fort-awesome-alt": $fa-var-fort-awesome-alt,
     "phabricator": $fa-var-phabricator,
     "ussunnah": $fa-var-ussunnah,
     "earlybirds": $fa-var-earlybirds,
     "trade-federation": $fa-var-trade-federation,
     "autoprefixer": $fa-var-autoprefixer,
     "whatsapp": $fa-var-whatsapp,
     "slideshare": $fa-var-slideshare,
     "google-play": $fa-var-google-play,
     "viadeo": $fa-var-viadeo,
     "line": $fa-var-line,
     "google-drive": $fa-var-google-drive,
     "servicestack": $fa-var-servicestack,
     "simplybuilt": $fa-var-simplybuilt,
     "bitbucket": $fa-var-bitbucket,
     "imdb": $fa-var-imdb,
     "deezer": $fa-var-deezer,
     "raspberry-pi": $fa-var-raspberry-pi,
     "jira": $fa-var-jira,
     "docker": $fa-var-docker,
     "screenpal": $fa-var-screenpal,
     "bluetooth": $fa-var-bluetooth,
     "gitter": $fa-var-gitter,
     "d-and-d": $fa-var-d-and-d,
     "microblog": $fa-var-microblog,
     "cc-diners-club": $fa-var-cc-diners-club,
     "gg-circle": $fa-var-gg-circle,
     "pied-piper-hat": $fa-var-pied-piper-hat,
     "kickstarter-k": $fa-var-kickstarter-k,
     "yandex": $fa-var-yandex,
     "readme": $fa-var-readme,
     "html5": $fa-var-html5,
     "sellsy": $fa-var-sellsy,
     "sass": $fa-var-sass,
     "wirsindhandwerk": $fa-var-wirsindhandwerk,
     "wsh": $fa-var-wsh,
     "buromobelexperte": $fa-var-buromobelexperte,
     "salesforce": $fa-var-salesforce,
     "octopus-deploy": $fa-var-octopus-deploy,
     "medapps": $fa-var-medapps,
     "ns8": $fa-var-ns8,
     "pinterest-p": $fa-var-pinterest-p,
     "apper": $fa-var-apper,
     "fort-awesome": $fa-var-fort-awesome,
     "waze": $fa-var-waze,
     "cc-jcb": $fa-var-cc-jcb,
     "snapchat": $fa-var-snapchat,
     "snapchat-ghost": $fa-var-snapchat-ghost,
     "fantasy-flight-games": $fa-var-fantasy-flight-games,
     "rust": $fa-var-rust,
     "wix": $fa-var-wix,
     "square-behance": $fa-var-square-behance,
     "behance-square": $fa-var-behance-square,
     "supple": $fa-var-supple,
     "rebel": $fa-var-rebel,
     "css3": $fa-var-css3,
     "staylinked": $fa-var-staylinked,
     "kaggle": $fa-var-kaggle,
     "space-awesome": $fa-var-space-awesome,
     "deviantart": $fa-var-deviantart,
     "cpanel": $fa-var-cpanel,
     "goodreads-g": $fa-var-goodreads-g,
     "square-git": $fa-var-square-git,
     "git-square": $fa-var-git-square,
     "square-tumblr": $fa-var-square-tumblr,
     "tumblr-square": $fa-var-tumblr-square,
     "trello": $fa-var-trello,
     "creative-commons-nc-jp": $fa-var-creative-commons-nc-jp,
     "get-pocket": $fa-var-get-pocket,
     "perbyte": $fa-var-perbyte,
     "grunt": $fa-var-grunt,
     "weebly": $fa-var-weebly,
     "connectdevelop": $fa-var-connectdevelop,
     "leanpub": $fa-var-leanpub,
     "black-tie": $fa-var-black-tie,
     "themeco": $fa-var-themeco,
     "python": $fa-var-python,
     "android": $fa-var-android,
     "bots": $fa-var-bots,
     "free-code-camp": $fa-var-free-code-camp,
     "hornbill": $fa-var-hornbill,
     "js": $fa-var-js,
     "ideal": $fa-var-ideal,
     "git": $fa-var-git,
     "dev": $fa-var-dev,
     "sketch": $fa-var-sketch,
     "yandex-international": $fa-var-yandex-international,
     "cc-amex": $fa-var-cc-amex,
     "uber": $fa-var-uber,
     "github": $fa-var-github,
     "php": $fa-var-php,
     "alipay": $fa-var-alipay,
     "youtube": $fa-var-youtube,
     "skyatlas": $fa-var-skyatlas,
     "firefox-browser": $fa-var-firefox-browser,
     "replyd": $fa-var-replyd,
     "suse": $fa-var-suse,
     "jenkins": $fa-var-jenkins,
     "twitter": $fa-var-twitter,
     "rockrms": $fa-var-rockrms,
     "pinterest": $fa-var-pinterest,
     "buffer": $fa-var-buffer,
     "npm": $fa-var-npm,
     "yammer": $fa-var-yammer,
     "btc": $fa-var-btc,
     "dribbble": $fa-var-dribbble,
     "stumbleupon-circle": $fa-var-stumbleupon-circle,
     "internet-explorer": $fa-var-internet-explorer,
     "stubber": $fa-var-stubber,
     "telegram": $fa-var-telegram,
     "telegram-plane": $fa-var-telegram-plane,
     "old-republic": $fa-var-old-republic,
     "odysee": $fa-var-odysee,
     "square-whatsapp": $fa-var-square-whatsapp,
     "whatsapp-square": $fa-var-whatsapp-square,
     "node-js": $fa-var-node-js,
     "edge-legacy": $fa-var-edge-legacy,
     "slack": $fa-var-slack,
     "slack-hash": $fa-var-slack-hash,
     "medrt": $fa-var-medrt,
     "usb": $fa-var-usb,
     "tumblr": $fa-var-tumblr,
     "vaadin": $fa-var-vaadin,
     "quora": $fa-var-quora,
     "reacteurope": $fa-var-reacteurope,
     "medium": $fa-var-medium,
     "medium-m": $fa-var-medium-m,
     "amilia": $fa-var-amilia,
     "mixcloud": $fa-var-mixcloud,
     "flipboard": $fa-var-flipboard,
     "viacoin": $fa-var-viacoin,
     "critical-role": $fa-var-critical-role,
     "sitrox": $fa-var-sitrox,
     "discourse": $fa-var-discourse,
     "joomla": $fa-var-joomla,
     "mastodon": $fa-var-mastodon,
     "airbnb": $fa-var-airbnb,
     "wolf-pack-battalion": $fa-var-wolf-pack-battalion,
     "buy-n-large": $fa-var-buy-n-large,
     "gulp": $fa-var-gulp,
     "creative-commons-sampling-plus": $fa-var-creative-commons-sampling-plus,
     "strava": $fa-var-strava,
     "ember": $fa-var-ember,
     "canadian-maple-leaf": $fa-var-canadian-maple-leaf,
     "teamspeak": $fa-var-teamspeak,
     "pushed": $fa-var-pushed,
     "wordpress-simple": $fa-var-wordpress-simple,
     "nutritionix": $fa-var-nutritionix,
     "wodu": $fa-var-wodu,
     "google-pay": $fa-var-google-pay,
     "intercom": $fa-var-intercom,
     "zhihu": $fa-var-zhihu,
     "korvue": $fa-var-korvue,
     "pix": $fa-var-pix,
     "steam-symbol": $fa-var-steam-symbol,
);
