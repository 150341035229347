// base icon class definition
// -------------------------

.#{$fa-css-prefix} {
     font-family: var(--#{$fa-css-prefix}-style-family, '#{$fa-style-family}');
     font-weight: var(--#{$fa-css-prefix}-style, #{$fa-style});
}

.#{$fa-css-prefix},
.#{$fa-css-prefix}-classic,
.#{$fa-css-prefix}-sharp,
.fas,
.#{$fa-css-prefix}-solid,
.far,
.#{$fa-css-prefix}-regular,
.fal,
.#{$fa-css-prefix}-light,
.fat,
.#{$fa-css-prefix}-thin,
.fad,
.#{$fa-css-prefix}-duotone,
.fass,
.#{$fa-css-prefix}-sharp-solid,
.fab,
.#{$fa-css-prefix}-brands {
     -moz-osx-font-smoothing: grayscale;
     -webkit-font-smoothing: antialiased;
     display: var(--#{$fa-css-prefix}-display, #{$fa-display});
     font-style: normal;
     font-variant: normal;
     line-height: 1.6;
     text-rendering: auto;
}

.fas,
.#{$fa-css-prefix}-classic,
.#{$fa-css-prefix}-solid,
.far,
.#{$fa-css-prefix}-regular,
.fal,
.#{$fa-css-prefix}-light,
.fat,
.#{$fa-css-prefix}-thin {
     font-family: 'Font Awesome 6 Pro';
}

.fab,
.#{$fa-css-prefix}-brands {
     font-family: 'Font Awesome 6 Brands';
}

.fad,
.#{$fa-css-prefix}-classic.#{$fa-css-prefix}-duotone,
.#{$fa-css-prefix}-duotone {
     font-family: 'Font Awesome 6 Duotone';
}

.fass,
.#{$fa-css-prefix}-sharp {
     font-family: 'Font Awesome 6 Sharp';
     font-weight: 900;
}

%fa-icon {
     @include fa-icon;
}
