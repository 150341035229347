@charset "UTF-8";
/*
Template Name: Hyper - Responsive Bootstrap 5 Admin Dashboard
Version: 5.1.0
Author: CoderThemes
Email: support@coderthemes.com
File: Main Css File
*/
:root {
  --ct-logo-lg-height: 36px;
  --ct-logo-sm-height: 22px;
  --ct-leftbar-width: 260px;
  --ct-leftbar-width-md: 160px;
  --ct-leftbar-width-sm: 70px;
  --ct-leftbar-condensed-height: 2000px;
  --ct-topbar-height: 70px;
  --ct-menu-item-icon-size: 1.2rem;
  --ct-menu-item-font-size: 0.9375rem;
  --ct-menu-item-padding-x: 10px;
  --ct-menu-item-padding-y: 15px;
  --ct-menu-condensed-link-bg: #2c8ef8;
  --ct-footer-height: 60px;
  --ct-theme-card-border-width: 0px;
}

html[data-menu-color=light] {
  --ct-menu-bg: #ffffff;
  --ct-menu-item-color: #6c757d;
  --ct-menu-item-hover-color: #2c8ef8;
  --ct-menu-item-active-color: #2c8ef8;
  --ct-help-box-bg: #2c8ef8;
}

html[data-menu-color=dark] {
  --ct-menu-bg: #313a46;
  --ct-menu-item-color: #8391a2;
  --ct-menu-item-hover-color: #bccee4;
  --ct-menu-item-active-color: #ffffff;
  --ct-help-box-bg: rgba(255, 255, 255, 0.07);
}

html[data-menu-color=brand] {
  --ct-menu-bg: linear-gradient(135deg, #19376B 0%, #112a4c 60%);
  --ct-menu-item-color: #cedce4;
  --ct-menu-item-hover-color: #ffffff;
  --ct-menu-item-active-color: #ffffff;
  --ct-help-box-bg: rgba(255, 255, 255, 0.07);
}

html[data-bs-theme=dark][data-menu-color=light],
html[data-bs-theme=dark][data-menu-color=dark] {
  --ct-menu-bg: #3a444e;
  --ct-menu-item-color: #8391a2;
  --ct-menu-item-hover-color: #bccee4;
  --ct-menu-item-active-color: #ffffff;
  --ct-help-box-bg: rgba(255, 255, 255, 0.07);
}

html[data-topbar-color=light] {
  --ct-topbar-bg: #ffffff;
  --ct-topbar-item-color: #6c757d;
  --ct-topbar-item-hover-color: #2c8ef8;
  --ct-topbar-search-bg: #f0f3f8;
  --ct-topbar-user-bg: #fafafd;
  --ct-topbar-user-border: #eef2f7;
}

html[data-topbar-color=dark] {
  --ct-topbar-bg: #313a46;
  --ct-topbar-item-color: #8391a2;
  --ct-topbar-item-hover-color: #bccee4;
  --ct-topbar-search-bg: #464f5b;
  --ct-topbar-user-bg: #3c4655;
  --ct-topbar-user-border: #414d5d;
}

html[data-topbar-color=brand] {
  --ct-topbar-bg: linear-gradient(135deg, #19376B 0%, #112a4c 60%);
  --ct-topbar-item-color: rgba(255, 255, 255, 0.7);
  --ct-topbar-item-hover-color: #ffffff;
  --ct-topbar-search-bg: rgba(255, 255, 255, 0.1);
  --ct-topbar-user-bg: rgba(255, 255, 255, 0.1);
  --ct-topbar-user-border: rgba(255, 255, 255, 0.15);
}

html[data-bs-theme=dark][data-topbar-color=light],
html[data-bs-theme=dark][data-topbar-color=dark] {
  --ct-topbar-bg: #3a444e;
  --ct-topbar-item-color: #8391a2;
  --ct-topbar-item-hover-color: #bccee4;
  --ct-topbar-search-bg: #464f5b;
  --ct-topbar-user-bg: #3c4655;
  --ct-topbar-user-border: #414d5d;
}

/*!
 * Bootstrap  v5.3.3 (https://getbootstrap.com/)
 * Copyright 2011-2024 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme=light] {
  --ct-blue: #2c8ef8;
  --ct-indigo: #727cf5;
  --ct-purple: #6b5eae;
  --ct-pink: #ff679b;
  --ct-red: #fa5c7c;
  --ct-orange: #fd7e14;
  --ct-yellow: #ffc35a;
  --ct-green: #0acf97;
  --ct-teal: #02a8b5;
  --ct-cyan: #39afd1;
  --ct-black: #000;
  --ct-white: #fff;
  --ct-gray: #8a969c;
  --ct-gray-dark: #343a40;
  --ct-lwk-green-dark: #05A148;
  --ct-lwk-green-light: #097131;
  --ct-gray-100: #f6f7fb;
  --ct-gray-200: #eef2f7;
  --ct-gray-300: #dee2e6;
  --ct-gray-400: #ced4da;
  --ct-gray-500: #a1a9b1;
  --ct-gray-600: #8a969c;
  --ct-gray-700: #6c757d;
  --ct-gray-800: #343a40;
  --ct-gray-900: #313a46;
  --ct-primary: #2c8ef8;
  --ct-secondary: #6c757d;
  --ct-success: #0acf97;
  --ct-info: #39afd1;
  --ct-warning: #ffc35a;
  --ct-danger: #fa5c7c;
  --ct-light: #eef2f7;
  --ct-dark: #313a46;
  --ct-lwk-green-dark: #05A148;
  --ct-lwk-green-light: #097131;
  --ct-primary-rgb: 44, 142, 248;
  --ct-secondary-rgb: 108, 117, 125;
  --ct-success-rgb: 10, 207, 151;
  --ct-info-rgb: 57, 175, 209;
  --ct-warning-rgb: 255, 195, 90;
  --ct-danger-rgb: 250, 92, 124;
  --ct-light-rgb: 238, 242, 247;
  --ct-dark-rgb: 49, 58, 70;
  --ct-lwk-green-dark-rgb: 5, 161, 72;
  --ct-lwk-green-light-rgb: 9, 113, 49;
  --ct-primary-text-emphasis: #123963;
  --ct-secondary-text-emphasis: #8a969c;
  --ct-success-text-emphasis: #04533c;
  --ct-info-text-emphasis: #174654;
  --ct-warning-text-emphasis: #664e24;
  --ct-danger-text-emphasis: #642532;
  --ct-light-text-emphasis: #495057;
  --ct-dark-text-emphasis: #495057;
  --ct-primary-bg-subtle: #eaf4fe;
  --ct-secondary-bg-subtle: #f6f7fb;
  --ct-success-bg-subtle: #e7faf5;
  --ct-info-bg-subtle: #ebf7fa;
  --ct-warning-bg-subtle: #fff9ef;
  --ct-danger-bg-subtle: #ffeff2;
  --ct-light-bg-subtle: #fbfbfd;
  --ct-dark-bg-subtle: #ced4da;
  --ct-primary-border-subtle: #d5e8fe;
  --ct-secondary-border-subtle: #eef2f7;
  --ct-success-border-subtle: #cef5ea;
  --ct-info-border-subtle: #d7eff6;
  --ct-warning-border-subtle: #fff3de;
  --ct-danger-border-subtle: #fedee5;
  --ct-light-border-subtle: #eef2f7;
  --ct-dark-border-subtle: #a1a9b1;
  --ct-white-rgb: 255, 255, 255;
  --ct-black-rgb: 0, 0, 0;
  --ct-font-sans-serif: "Nunito", sans-serif;
  --ct-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --ct-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --ct-body-font-family: var(--ct-font-sans-serif);
  --ct-body-font-size: 0.9rem;
  --ct-body-font-weight: 400;
  --ct-body-line-height: 1.5;
  --ct-body-color: #6c757d;
  --ct-body-color-rgb: 108, 117, 125;
  --ct-body-bg: #fafbfe;
  --ct-body-bg-rgb: 250, 251, 254;
  --ct-emphasis-color: rgba(108, 117, 125, 0.75);
  --ct-emphasis-color-rgb: 108, 117, 125;
  --ct-secondary-color: rgba(108, 117, 125, 0.75);
  --ct-secondary-color-rgb: 108, 117, 125;
  --ct-secondary-bg: #fff;
  --ct-secondary-bg-rgb: 255, 255, 255;
  --ct-tertiary-color: #313539;
  --ct-tertiary-color-rgb: 49, 53, 57;
  --ct-tertiary-bg: #f6f7fb;
  --ct-tertiary-bg-rgb: 246, 247, 251;
  --ct-heading-color: inherit;
  --ct-link-color: #2c8ef8;
  --ct-link-color-rgb: 44, 142, 248;
  --ct-link-decoration: none;
  --ct-link-hover-color: #2579d3;
  --ct-link-hover-color-rgb: 37, 121, 211;
  --ct-code-color: #39afd1;
  --ct-highlight-color: #212529;
  --ct-highlight-bg: #fff3de;
  --ct-border-width: 1px;
  --ct-border-style: solid;
  --ct-border-color: #dee2e6;
  --ct-border-color-translucent: #dee2e6;
  --ct-border-radius: 0.25rem;
  --ct-border-radius-sm: 0.2rem;
  --ct-border-radius-lg: 0.3rem;
  --ct-border-radius-xl: 1rem;
  --ct-border-radius-xxl: 2rem;
  --ct-border-radius-2xl: var(--ct-border-radius-xxl);
  --ct-border-radius-pill: 50rem;
  --ct-box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  --ct-box-shadow-sm: 0 0.125rem 0.25rem rgba(var(--ct-body-color-rgb), 0.15);
  --ct-box-shadow-lg: 0 0 45px 0 rgba(var(--ct-body-color-rgb), 0.2);
  --ct-box-shadow-inset: inset 0 1px 2px rgba(var(--ct-body-color-rgb), 0.075);
  --ct-focus-ring-width: 0.15rem;
  --ct-focus-ring-opacity: 0.25;
  --ct-focus-ring-color: rgba(44, 142, 248, 0.25);
  --ct-form-valid-color: #0acf97;
  --ct-form-valid-border-color: #0acf97;
  --ct-form-invalid-color: #fa5c7c;
  --ct-form-invalid-border-color: #fa5c7c;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --ct-body-color: #aab8c5;
  --ct-body-color-rgb: 170, 184, 197;
  --ct-body-bg: #343a40;
  --ct-body-bg-rgb: 52, 58, 64;
  --ct-emphasis-color: #dee2e6;
  --ct-emphasis-color-rgb: 222, 226, 230;
  --ct-secondary-color: #8391a2;
  --ct-secondary-color-rgb: 131, 145, 162;
  --ct-secondary-bg: #37404a;
  --ct-secondary-bg-rgb: 55, 64, 74;
  --ct-tertiary-color: #f1f1f1;
  --ct-tertiary-color-rgb: 241, 241, 241;
  --ct-tertiary-bg: #404954;
  --ct-tertiary-bg-rgb: 64, 73, 84;
  --ct-primary-text-emphasis: #80bbfb;
  --ct-secondary-text-emphasis: #dee2e6;
  --ct-success-text-emphasis: #6ce2c1;
  --ct-info-text-emphasis: #88cfe3;
  --ct-warning-text-emphasis: #ffdb9c;
  --ct-danger-text-emphasis: #fc9db0;
  --ct-light-text-emphasis: #f6f7fb;
  --ct-dark-text-emphasis: #dee2e6;
  --ct-primary-bg-subtle: rgba(var(--ct-primary-rgb), 0.15);
  --ct-secondary-bg-subtle: rgba(108, 117, 125, 0.15);
  --ct-success-bg-subtle: rgba(var(--ct-success-rgb), 0.15);
  --ct-info-bg-subtle: rgba(var(--ct-info-rgb), 0.15);
  --ct-warning-bg-subtle: rgba(var(--ct-warning-rgb), 0.15);
  --ct-danger-bg-subtle: rgba(var(--ct-danger-rgb), 0.15);
  --ct-light-bg-subtle: rgba(var(--ct-light-rgb), 0.15);
  --ct-dark-bg-subtle: rgba(var(--ct-dark-rgb), 0.15);
  --ct-primary-border-subtle: #1a5595;
  --ct-secondary-border-subtle: #6c757d;
  --ct-success-border-subtle: #067c5b;
  --ct-info-border-subtle: #22697d;
  --ct-warning-border-subtle: #997536;
  --ct-danger-border-subtle: #96374a;
  --ct-light-border-subtle: #6c757d;
  --ct-dark-border-subtle: #343a40;
  --ct-heading-color: #aab8c5;
  --ct-link-color: #80bbfb;
  --ct-link-hover-color: #93c5fc;
  --ct-link-color-rgb: 128, 187, 251;
  --ct-link-hover-color-rgb: 147, 197, 252;
  --ct-code-color: #88cfe3;
  --ct-highlight-color: #dee2e6;
  --ct-highlight-bg: #664d03;
  --ct-border-color: #464f5b;
  --ct-border-color-translucent: #8391a2;
  --ct-form-valid-color: #6ce2c1;
  --ct-form-valid-border-color: #6ce2c1;
  --ct-form-invalid-color: #fc9db0;
  --ct-form-invalid-border-color: #fc9db0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--ct-body-font-family);
  font-size: var(--ct-body-font-size);
  font-weight: var(--ct-body-font-weight);
  line-height: var(--ct-body-line-height);
  color: var(--ct-body-color);
  text-align: var(--ct-body-text-align);
  background-color: var(--ct-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1.0005rem 0;
  color: inherit;
  border: 0;
  border-top: var(--ct-border-width) solid var(--ct-border-color);
  opacity: 1;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 700;
  line-height: 1.1;
  color: var(--ct-heading-color);
}

h1, .h1 {
  font-size: calc(1.35rem + 1.2vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.25rem;
  }
}

h2, .h2 {
  font-size: calc(1.3122rem + 0.7464vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.872rem;
  }
}

h3, .h3 {
  font-size: calc(1.2753rem + 0.3036vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.503rem;
  }
}

h4, .h4 {
  font-size: 1.125rem;
}

h5, .h5 {
  font-size: 0.936rem;
}

h6, .h6 {
  font-size: 0.765rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.75rem;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--ct-highlight-color);
  background-color: var(--ct-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--ct-link-color-rgb), var(--ct-link-opacity, 1));
  text-decoration: none;
}
a:hover {
  --ct-link-color-rgb: var(--ct-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--ct-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.75rem;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.75rem;
  color: var(--ct-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.75rem;
  color: var(--ct-body-bg);
  background-color: var(--ct-body-color);
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.95rem;
  padding-bottom: 0.95rem;
  color: var(--ct-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.125rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1.5rem;
  margin-bottom: 1.5rem;
  font-size: 0.75rem;
  color: var(--ct-secondary-color);
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--ct-body-bg);
  border: var(--ct-border-width) solid var(--ct-border-color);
  border-radius: var(--ct-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.75rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.75rem;
  color: var(--ct-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --ct-gutter-x: 1.5rem;
  --ct-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--ct-gutter-x) * 0.5);
  padding-left: calc(var(--ct-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
:root {
  --ct-breakpoint-xs: 0;
  --ct-breakpoint-sm: 576px;
  --ct-breakpoint-md: 768px;
  --ct-breakpoint-lg: 992px;
  --ct-breakpoint-xl: 1200px;
  --ct-breakpoint-xxl: 1400px;
}

.row {
  --ct-gutter-x: 1.5rem;
  --ct-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--ct-gutter-y));
  margin-right: calc(-0.5 * var(--ct-gutter-x));
  margin-left: calc(-0.5 * var(--ct-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--ct-gutter-x) * 0.5);
  padding-left: calc(var(--ct-gutter-x) * 0.5);
  margin-top: var(--ct-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --ct-gutter-x: 0;
}

.g-0,
.gy-0 {
  --ct-gutter-y: 0;
}

.g-1,
.gx-1 {
  --ct-gutter-x: 0.375rem;
}

.g-1,
.gy-1 {
  --ct-gutter-y: 0.375rem;
}

.g-2,
.gx-2 {
  --ct-gutter-x: 0.75rem;
}

.g-2,
.gy-2 {
  --ct-gutter-y: 0.75rem;
}

.g-3,
.gx-3 {
  --ct-gutter-x: 1.5rem;
}

.g-3,
.gy-3 {
  --ct-gutter-y: 1.5rem;
}

.g-4,
.gx-4 {
  --ct-gutter-x: 2.25rem;
}

.g-4,
.gy-4 {
  --ct-gutter-y: 2.25rem;
}

.g-5,
.gx-5 {
  --ct-gutter-x: 4.5rem;
}

.g-5,
.gy-5 {
  --ct-gutter-y: 4.5rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --ct-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --ct-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --ct-gutter-x: 0.375rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --ct-gutter-y: 0.375rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --ct-gutter-x: 0.75rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --ct-gutter-y: 0.75rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --ct-gutter-x: 1.5rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --ct-gutter-y: 1.5rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --ct-gutter-x: 2.25rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --ct-gutter-y: 2.25rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --ct-gutter-x: 4.5rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --ct-gutter-y: 4.5rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --ct-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --ct-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --ct-gutter-x: 0.375rem;
  }
  .g-md-1,
  .gy-md-1 {
    --ct-gutter-y: 0.375rem;
  }
  .g-md-2,
  .gx-md-2 {
    --ct-gutter-x: 0.75rem;
  }
  .g-md-2,
  .gy-md-2 {
    --ct-gutter-y: 0.75rem;
  }
  .g-md-3,
  .gx-md-3 {
    --ct-gutter-x: 1.5rem;
  }
  .g-md-3,
  .gy-md-3 {
    --ct-gutter-y: 1.5rem;
  }
  .g-md-4,
  .gx-md-4 {
    --ct-gutter-x: 2.25rem;
  }
  .g-md-4,
  .gy-md-4 {
    --ct-gutter-y: 2.25rem;
  }
  .g-md-5,
  .gx-md-5 {
    --ct-gutter-x: 4.5rem;
  }
  .g-md-5,
  .gy-md-5 {
    --ct-gutter-y: 4.5rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --ct-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --ct-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --ct-gutter-x: 0.375rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --ct-gutter-y: 0.375rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --ct-gutter-x: 0.75rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --ct-gutter-y: 0.75rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --ct-gutter-x: 1.5rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --ct-gutter-y: 1.5rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --ct-gutter-x: 2.25rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --ct-gutter-y: 2.25rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --ct-gutter-x: 4.5rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --ct-gutter-y: 4.5rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --ct-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --ct-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --ct-gutter-x: 0.375rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --ct-gutter-y: 0.375rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --ct-gutter-x: 0.75rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --ct-gutter-y: 0.75rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --ct-gutter-x: 1.5rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --ct-gutter-y: 1.5rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --ct-gutter-x: 2.25rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --ct-gutter-y: 2.25rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --ct-gutter-x: 4.5rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --ct-gutter-y: 4.5rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --ct-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --ct-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --ct-gutter-x: 0.375rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --ct-gutter-y: 0.375rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --ct-gutter-x: 0.75rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --ct-gutter-y: 0.75rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --ct-gutter-x: 1.5rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --ct-gutter-y: 1.5rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --ct-gutter-x: 2.25rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --ct-gutter-y: 2.25rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --ct-gutter-x: 4.5rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --ct-gutter-y: 4.5rem;
  }
}
.table {
  --ct-table-color-type: initial;
  --ct-table-bg-type: initial;
  --ct-table-color-state: initial;
  --ct-table-bg-state: initial;
  --ct-table-color: var(--ct-body-color);
  --ct-table-bg: transparent;
  --ct-table-border-color: var(--ct-border-color);
  --ct-table-accent-bg: transparent;
  --ct-table-striped-color: var(--ct-body-color);
  --ct-table-striped-bg: rgba(var(--ct-tertiary-bg-rgb), 1);
  --ct-table-active-color: var(--ct-body-color);
  --ct-table-active-bg: rgba(var(--ct-tertiary-bg-rgb), 1);
  --ct-table-hover-color: var(--ct-body-color);
  --ct-table-hover-bg: rgba(var(--ct-tertiary-bg-rgb), 1);
  width: 100%;
  margin-bottom: 1.5rem;
  vertical-align: top;
  border-color: var(--ct-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.95rem 0.95rem;
  color: var(--ct-table-color-state, var(--ct-table-color-type, var(--ct-table-color)));
  background-color: var(--ct-table-bg);
  border-bottom-width: var(--ct-border-width);
  box-shadow: inset 0 0 0 9999px var(--ct-table-bg-state, var(--ct-table-bg-type, var(--ct-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--ct-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--ct-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--ct-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --ct-table-color-type: var(--ct-table-striped-color);
  --ct-table-bg-type: var(--ct-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --ct-table-color-type: var(--ct-table-striped-color);
  --ct-table-bg-type: var(--ct-table-striped-bg);
}

.table-active {
  --ct-table-color-state: var(--ct-table-active-color);
  --ct-table-bg-state: var(--ct-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --ct-table-color-state: var(--ct-table-hover-color);
  --ct-table-bg-state: var(--ct-table-hover-bg);
}

.table-primary {
  --ct-table-color: #313a46;
  --ct-table-bg: #d5e8fe;
  --ct-table-border-color: #c5d7ec;
  --ct-table-striped-bg: #313a46;
  --ct-table-striped-color: #fff;
  --ct-table-active-bg: #313a46;
  --ct-table-active-color: #fff;
  --ct-table-hover-bg: #313a46;
  --ct-table-hover-color: #fff;
  color: var(--ct-table-color);
  border-color: var(--ct-table-border-color);
}

.table-secondary {
  --ct-table-color: #313a46;
  --ct-table-bg: #e2e3e5;
  --ct-table-border-color: #d0d2d5;
  --ct-table-striped-bg: #313a46;
  --ct-table-striped-color: #fff;
  --ct-table-active-bg: #313a46;
  --ct-table-active-color: #fff;
  --ct-table-hover-bg: #313a46;
  --ct-table-hover-color: #fff;
  color: var(--ct-table-color);
  border-color: var(--ct-table-border-color);
}

.table-success {
  --ct-table-color: #313a46;
  --ct-table-bg: #cef5ea;
  --ct-table-border-color: #bee2da;
  --ct-table-striped-bg: #313a46;
  --ct-table-striped-color: #fff;
  --ct-table-active-bg: #313a46;
  --ct-table-active-color: #fff;
  --ct-table-hover-bg: #313a46;
  --ct-table-hover-color: #fff;
  color: var(--ct-table-color);
  border-color: var(--ct-table-border-color);
}

.table-info {
  --ct-table-color: #313a46;
  --ct-table-bg: #d7eff6;
  --ct-table-border-color: #c6dde4;
  --ct-table-striped-bg: #313a46;
  --ct-table-striped-color: #fff;
  --ct-table-active-bg: #313a46;
  --ct-table-active-color: #fff;
  --ct-table-hover-bg: #313a46;
  --ct-table-hover-color: #fff;
  color: var(--ct-table-color);
  border-color: var(--ct-table-border-color);
}

.table-warning {
  --ct-table-color: #313a46;
  --ct-table-bg: #fff3de;
  --ct-table-border-color: #eae1cf;
  --ct-table-striped-bg: #313a46;
  --ct-table-striped-color: #fff;
  --ct-table-active-bg: #313a46;
  --ct-table-active-color: #fff;
  --ct-table-hover-bg: #313a46;
  --ct-table-hover-color: #fff;
  color: var(--ct-table-color);
  border-color: var(--ct-table-border-color);
}

.table-danger {
  --ct-table-color: #313a46;
  --ct-table-bg: #fedee5;
  --ct-table-border-color: #eaced5;
  --ct-table-striped-bg: #313a46;
  --ct-table-striped-color: #fff;
  --ct-table-active-bg: #313a46;
  --ct-table-active-color: #fff;
  --ct-table-hover-bg: #313a46;
  --ct-table-hover-color: #fff;
  color: var(--ct-table-color);
  border-color: var(--ct-table-border-color);
}

.table-light {
  --ct-table-color: #313a46;
  --ct-table-bg: #eef2f7;
  --ct-table-border-color: #dbe0e5;
  --ct-table-striped-bg: #313a46;
  --ct-table-striped-color: #fff;
  --ct-table-active-bg: #313a46;
  --ct-table-active-color: #fff;
  --ct-table-hover-bg: #313a46;
  --ct-table-hover-color: #fff;
  color: var(--ct-table-color);
  border-color: var(--ct-table-border-color);
}

.table-dark {
  --ct-table-color: #fff;
  --ct-table-bg: #313a46;
  --ct-table-border-color: #464e59;
  --ct-table-striped-bg: white;
  --ct-table-striped-color: #313a46;
  --ct-table-active-bg: white;
  --ct-table-active-color: #313a46;
  --ct-table-hover-bg: white;
  --ct-table-hover-color: #313a46;
  color: var(--ct-table-color);
  border-color: var(--ct-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.45rem + var(--ct-border-width));
  padding-bottom: calc(0.45rem + var(--ct-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--ct-border-width));
  padding-bottom: calc(0.5rem + var(--ct-border-width));
  font-size: calc(1.2501rem + 0.0012vw);
}
@media (min-width: 1200px) {
  .col-form-label-lg {
    font-size: 1.251rem;
  }
}

.col-form-label-sm {
  padding-top: calc(0.28rem + var(--ct-border-width));
  padding-bottom: calc(0.28rem + var(--ct-border-width));
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: var(--ct-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.45rem 0.9rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--ct-body-color);
  appearance: none;
  background-color: var(--ct-input-bg);
  background-clip: padding-box;
  border: var(--ct-border-width) solid var(--ct-border-color);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--ct-body-color);
  background-color: var(--ct-input-bg);
  border-color: var(--ct-border-color);
  outline: 0;
  box-shadow: none;
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: var(--ct-secondary-color);
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--ct-tertiary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.45rem 0.9rem;
  margin: -0.45rem -0.9rem;
  margin-inline-end: 0.9rem;
  color: var(--ct-body-color);
  background-color: var(--ct-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--ct-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--ct-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.45rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--ct-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--ct-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.56rem + calc(var(--ct-border-width) * 2));
  padding: 0.28rem 0.8rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.form-control-sm::file-selector-button {
  padding: 0.28rem 0.8rem;
  margin: -0.28rem -0.8rem;
  margin-inline-end: 0.8rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--ct-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: calc(1.2501rem + 0.0012vw);
  border-radius: 0.3rem;
}
@media (min-width: 1200px) {
  .form-control-lg {
    font-size: 1.251rem;
  }
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.9rem + calc(var(--ct-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.56rem + calc(var(--ct-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--ct-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.9rem + calc(var(--ct-border-width) * 2));
  padding: 0.45rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.25rem;
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: 0.25rem;
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.56rem + calc(var(--ct-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--ct-border-width) * 2));
}

.form-select {
  --ct-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.45rem 2.7rem 0.45rem 0.9rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--ct-body-color);
  appearance: none;
  background-color: var(--ct-input-bg);
  background-image: var(--ct-form-select-bg-img), var(--ct-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.9rem center;
  background-size: 14px 10px;
  border: var(--ct-border-width) solid var(--ct-border-color);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: var(--ct-border-color);
  outline: 0;
  box-shadow: 0 0 0 0 rgba(44, 142, 248, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.9rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--ct-tertiary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--ct-body-color);
}

.form-select-sm {
  padding-top: 0.28rem;
  padding-bottom: 0.28rem;
  padding-left: 0.8rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: calc(1.2501rem + 0.0012vw);
  border-radius: 0.3rem;
}
@media (min-width: 1200px) {
  .form-select-lg {
    font-size: 1.251rem;
  }
}

[data-bs-theme=dark] .form-select {
  --ct-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23aab8c5' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.35rem;
  padding-left: 1.612em;
  margin-bottom: 0;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.612em;
}

.form-check-reverse {
  padding-right: 1.612em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.612em;
  margin-left: 0;
}

.form-check-input {
  --ct-form-check-bg: var(--ct-secondary-bg);
  flex-shrink: 0;
  width: 1.112em;
  height: 1.112em;
  margin-top: 0.194em;
  vertical-align: top;
  appearance: none;
  background-color: var(--ct-form-check-bg);
  background-image: var(--ct-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--ct-border-width) solid var(--ct-border-color-translucent);
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: var(--ct-border-color);
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgba(44, 142, 248, 0.25);
}
.form-check-input:checked {
  background-color: #2c8ef8;
  border-color: #2c8ef8;
}
.form-check-input:checked[type=checkbox] {
  --ct-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --ct-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #2c8ef8;
  border-color: #2c8ef8;
  --ct-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --ct-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--ct-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --ct-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='var%28--ct-border-color%29'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --ct-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --ct-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.3rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fafbfe, none;
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fafbfe, none;
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #2c8ef8;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #c0ddfd;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--ct-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #2c8ef8;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #c0ddfd;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--ct-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--ct-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--ct-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--ct-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--ct-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.9rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--ct-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.9rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--ct-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.45rem;
  z-index: -1;
  height: 1.875em;
  content: "";
  background-color: var(--ct-input-bg);
  border-radius: 0.25rem;
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--ct-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--ct-border-width) 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #8a969c;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--ct-tertiary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.45rem 0.9rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--ct-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--ct-tertiary-bg);
  border: var(--ct-border-width) solid var(--ct-border-color);
  border-radius: 0.25rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: calc(1.2501rem + 0.0012vw);
  border-radius: 0.3rem;
}
@media (min-width: 1200px) {
  .input-group-lg > .form-control,
  .input-group-lg > .form-select,
  .input-group-lg > .input-group-text,
  .input-group-lg > .btn {
    font-size: 1.251rem;
  }
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.28rem 0.8rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3.6rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--ct-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: var(--ct-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.375rem 0.75rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--ct-success);
  border-radius: var(--ct-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--ct-form-valid-border-color);
  padding-right: calc(1.5em + 0.9rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%230acf97' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.225rem) center;
  background-size: calc(0.75em + 0.45rem) calc(0.75em + 0.45rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--ct-form-valid-border-color);
  box-shadow: 0 0 0 0.15rem rgba(var(--ct-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.9rem);
  background-position: top calc(0.375em + 0.225rem) right calc(0.375em + 0.225rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--ct-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --ct-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%230acf97' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.95rem;
  background-position: right 0.9rem center, center right 2.7rem;
  background-size: 14px 10px, calc(0.75em + 0.45rem) calc(0.75em + 0.45rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--ct-form-valid-border-color);
  box-shadow: 0 0 0 0.15rem rgba(var(--ct-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.9rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--ct-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--ct-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.15rem rgba(var(--ct-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--ct-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: var(--ct-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.375rem 0.75rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--ct-danger);
  border-radius: var(--ct-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--ct-form-invalid-border-color);
  padding-right: calc(1.5em + 0.9rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23fa5c7c'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fa5c7c' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.225rem) center;
  background-size: calc(0.75em + 0.45rem) calc(0.75em + 0.45rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--ct-form-invalid-border-color);
  box-shadow: 0 0 0 0.15rem rgba(var(--ct-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.9rem);
  background-position: top calc(0.375em + 0.225rem) right calc(0.375em + 0.225rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--ct-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --ct-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23fa5c7c'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fa5c7c' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.95rem;
  background-position: right 0.9rem center, center right 2.7rem;
  background-size: 14px 10px, calc(0.75em + 0.45rem) calc(0.75em + 0.45rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--ct-form-invalid-border-color);
  box-shadow: 0 0 0 0.15rem rgba(var(--ct-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.9rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--ct-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--ct-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.15rem rgba(var(--ct-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--ct-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --ct-btn-padding-x: 0.9rem;
  --ct-btn-padding-y: 0.45rem;
  --ct-btn-font-family: ;
  --ct-btn-font-size: 0.9rem;
  --ct-btn-font-weight: 400;
  --ct-btn-line-height: 1.5;
  --ct-btn-color: var(--ct-body-color);
  --ct-btn-bg: transparent;
  --ct-btn-border-width: var(--ct-border-width);
  --ct-btn-border-color: transparent;
  --ct-btn-border-radius: 0.15rem;
  --ct-btn-hover-border-color: transparent;
  --ct-btn-box-shadow: 0px 2px 6px 0px;
  --ct-btn-disabled-opacity: 0.65;
  --ct-btn-focus-box-shadow: 0 0 0 0.15rem rgba(var(--ct-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--ct-btn-padding-y) var(--ct-btn-padding-x);
  font-family: var(--ct-btn-font-family);
  font-size: var(--ct-btn-font-size);
  font-weight: var(--ct-btn-font-weight);
  line-height: var(--ct-btn-line-height);
  color: var(--ct-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--ct-btn-border-width) solid var(--ct-btn-border-color);
  border-radius: var(--ct-btn-border-radius);
  background-color: var(--ct-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--ct-btn-hover-color);
  background-color: var(--ct-btn-hover-bg);
  border-color: var(--ct-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--ct-btn-color);
  background-color: var(--ct-btn-bg);
  border-color: var(--ct-btn-border-color);
}
.btn:focus-visible {
  color: var(--ct-btn-hover-color);
  background-color: var(--ct-btn-hover-bg);
  border-color: var(--ct-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--ct-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--ct-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--ct-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--ct-btn-active-color);
  background-color: var(--ct-btn-active-bg);
  border-color: var(--ct-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--ct-btn-focus-box-shadow);
}
.btn-check:checked:focus-visible + .btn {
  box-shadow: var(--ct-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--ct-btn-disabled-color);
  pointer-events: none;
  background-color: var(--ct-btn-disabled-bg);
  border-color: var(--ct-btn-disabled-border-color);
  opacity: var(--ct-btn-disabled-opacity);
}

.btn-primary {
  --ct-btn-color: #fff;
  --ct-btn-bg: #2c8ef8;
  --ct-btn-border-color: #2c8ef8;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: #2983e5;
  --ct-btn-hover-border-color: #2983e5;
  --ct-btn-focus-shadow-rgb: 76, 159, 249;
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: #2983e5;
  --ct-btn-active-border-color: #2983e5;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #fff;
  --ct-btn-disabled-bg: #2c8ef8;
  --ct-btn-disabled-border-color: #2c8ef8;
}

.btn-secondary {
  --ct-btn-color: #fff;
  --ct-btn-bg: #6c757d;
  --ct-btn-border-color: #6c757d;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: #646c74;
  --ct-btn-hover-border-color: #646c74;
  --ct-btn-focus-shadow-rgb: 130, 138, 145;
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: #646c74;
  --ct-btn-active-border-color: #646c74;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #fff;
  --ct-btn-disabled-bg: #6c757d;
  --ct-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --ct-btn-color: #fff;
  --ct-btn-bg: #0acf97;
  --ct-btn-border-color: #0acf97;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: #09bf8c;
  --ct-btn-hover-border-color: #09bf8c;
  --ct-btn-focus-shadow-rgb: 47, 214, 167;
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: #09bf8c;
  --ct-btn-active-border-color: #09bf8c;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #fff;
  --ct-btn-disabled-bg: #0acf97;
  --ct-btn-disabled-border-color: #0acf97;
}

.btn-info {
  --ct-btn-color: #fff;
  --ct-btn-bg: #39afd1;
  --ct-btn-border-color: #39afd1;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: #35a2c1;
  --ct-btn-hover-border-color: #35a2c1;
  --ct-btn-focus-shadow-rgb: 87, 187, 216;
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: #35a2c1;
  --ct-btn-active-border-color: #35a2c1;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #fff;
  --ct-btn-disabled-bg: #39afd1;
  --ct-btn-disabled-border-color: #39afd1;
}

.btn-warning {
  --ct-btn-color: #fff;
  --ct-btn-bg: #ffc35a;
  --ct-btn-border-color: #ffc35a;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: #ecb453;
  --ct-btn-hover-border-color: #ecb453;
  --ct-btn-focus-shadow-rgb: 255, 204, 115;
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: #ecb453;
  --ct-btn-active-border-color: #ecb453;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #fff;
  --ct-btn-disabled-bg: #ffc35a;
  --ct-btn-disabled-border-color: #ffc35a;
}

.btn-danger {
  --ct-btn-color: #fff;
  --ct-btn-bg: #fa5c7c;
  --ct-btn-border-color: #fa5c7c;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: #e75573;
  --ct-btn-hover-border-color: #e75573;
  --ct-btn-focus-shadow-rgb: 251, 116, 144;
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: #e75573;
  --ct-btn-active-border-color: #e75573;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #fff;
  --ct-btn-disabled-bg: #fa5c7c;
  --ct-btn-disabled-border-color: #fa5c7c;
}

.btn-light {
  --ct-btn-color: #313a46;
  --ct-btn-bg: #eef2f7;
  --ct-btn-border-color: #eef2f7;
  --ct-btn-hover-color: #313a46;
  --ct-btn-hover-bg: #dce0e4;
  --ct-btn-hover-border-color: #dce0e4;
  --ct-btn-focus-shadow-rgb: 210, 214, 220;
  --ct-btn-active-color: #313a46;
  --ct-btn-active-bg: #dce0e4;
  --ct-btn-active-border-color: #dce0e4;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #313a46;
  --ct-btn-disabled-bg: #eef2f7;
  --ct-btn-disabled-border-color: #eef2f7;
}

.btn-dark {
  --ct-btn-color: #fff;
  --ct-btn-bg: #313a46;
  --ct-btn-border-color: #313a46;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: #505862;
  --ct-btn-hover-border-color: #464e59;
  --ct-btn-focus-shadow-rgb: 80, 88, 98;
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: #505862;
  --ct-btn-active-border-color: #464e59;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #fff;
  --ct-btn-disabled-bg: #313a46;
  --ct-btn-disabled-border-color: #313a46;
}

.btn-lwk-green-dark {
  --ct-btn-color: #fff;
  --ct-btn-bg: #05A148;
  --ct-btn-border-color: #05A148;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: #059543;
  --ct-btn-hover-border-color: #059543;
  --ct-btn-focus-shadow-rgb: 43, 175, 99;
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: #059543;
  --ct-btn-active-border-color: #059543;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #fff;
  --ct-btn-disabled-bg: #05A148;
  --ct-btn-disabled-border-color: #05A148;
}

.btn-lwk-green-light {
  --ct-btn-color: #fff;
  --ct-btn-bg: #097131;
  --ct-btn-border-color: #097131;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: #08692d;
  --ct-btn-hover-border-color: #08692d;
  --ct-btn-focus-shadow-rgb: 46, 134, 80;
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: #08692d;
  --ct-btn-active-border-color: #08692d;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #fff;
  --ct-btn-disabled-bg: #097131;
  --ct-btn-disabled-border-color: #097131;
}

.btn-outline-primary {
  --ct-btn-color: #2c8ef8;
  --ct-btn-border-color: #2c8ef8;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: #2c8ef8;
  --ct-btn-hover-border-color: #2c8ef8;
  --ct-btn-focus-shadow-rgb: 44, 142, 248;
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: #2c8ef8;
  --ct-btn-active-border-color: #2c8ef8;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #2c8ef8;
  --ct-btn-disabled-bg: transparent;
  --ct-btn-disabled-border-color: #2c8ef8;
  --ct-gradient: none;
}

.btn-outline-secondary {
  --ct-btn-color: #6c757d;
  --ct-btn-border-color: #6c757d;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: #6c757d;
  --ct-btn-hover-border-color: #6c757d;
  --ct-btn-focus-shadow-rgb: 108, 117, 125;
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: #6c757d;
  --ct-btn-active-border-color: #6c757d;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #6c757d;
  --ct-btn-disabled-bg: transparent;
  --ct-btn-disabled-border-color: #6c757d;
  --ct-gradient: none;
}

.btn-outline-success {
  --ct-btn-color: #0acf97;
  --ct-btn-border-color: #0acf97;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: #0acf97;
  --ct-btn-hover-border-color: #0acf97;
  --ct-btn-focus-shadow-rgb: 10, 207, 151;
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: #0acf97;
  --ct-btn-active-border-color: #0acf97;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #0acf97;
  --ct-btn-disabled-bg: transparent;
  --ct-btn-disabled-border-color: #0acf97;
  --ct-gradient: none;
}

.btn-outline-info {
  --ct-btn-color: #39afd1;
  --ct-btn-border-color: #39afd1;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: #39afd1;
  --ct-btn-hover-border-color: #39afd1;
  --ct-btn-focus-shadow-rgb: 57, 175, 209;
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: #39afd1;
  --ct-btn-active-border-color: #39afd1;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #39afd1;
  --ct-btn-disabled-bg: transparent;
  --ct-btn-disabled-border-color: #39afd1;
  --ct-gradient: none;
}

.btn-outline-warning {
  --ct-btn-color: #ffc35a;
  --ct-btn-border-color: #ffc35a;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: #ffc35a;
  --ct-btn-hover-border-color: #ffc35a;
  --ct-btn-focus-shadow-rgb: 255, 195, 90;
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: #ffc35a;
  --ct-btn-active-border-color: #ffc35a;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #ffc35a;
  --ct-btn-disabled-bg: transparent;
  --ct-btn-disabled-border-color: #ffc35a;
  --ct-gradient: none;
}

.btn-outline-danger {
  --ct-btn-color: #fa5c7c;
  --ct-btn-border-color: #fa5c7c;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: #fa5c7c;
  --ct-btn-hover-border-color: #fa5c7c;
  --ct-btn-focus-shadow-rgb: 250, 92, 124;
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: #fa5c7c;
  --ct-btn-active-border-color: #fa5c7c;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #fa5c7c;
  --ct-btn-disabled-bg: transparent;
  --ct-btn-disabled-border-color: #fa5c7c;
  --ct-gradient: none;
}

.btn-outline-light {
  --ct-btn-color: #eef2f7;
  --ct-btn-border-color: #eef2f7;
  --ct-btn-hover-color: #313a46;
  --ct-btn-hover-bg: #eef2f7;
  --ct-btn-hover-border-color: #eef2f7;
  --ct-btn-focus-shadow-rgb: 238, 242, 247;
  --ct-btn-active-color: #313a46;
  --ct-btn-active-bg: #eef2f7;
  --ct-btn-active-border-color: #eef2f7;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #eef2f7;
  --ct-btn-disabled-bg: transparent;
  --ct-btn-disabled-border-color: #eef2f7;
  --ct-gradient: none;
}

.btn-outline-dark {
  --ct-btn-color: #313a46;
  --ct-btn-border-color: #313a46;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: #313a46;
  --ct-btn-hover-border-color: #313a46;
  --ct-btn-focus-shadow-rgb: 49, 58, 70;
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: #313a46;
  --ct-btn-active-border-color: #313a46;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #313a46;
  --ct-btn-disabled-bg: transparent;
  --ct-btn-disabled-border-color: #313a46;
  --ct-gradient: none;
}

.btn-outline-lwk-green-dark {
  --ct-btn-color: #05A148;
  --ct-btn-border-color: #05A148;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: #05A148;
  --ct-btn-hover-border-color: #05A148;
  --ct-btn-focus-shadow-rgb: 5, 161, 72;
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: #05A148;
  --ct-btn-active-border-color: #05A148;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #05A148;
  --ct-btn-disabled-bg: transparent;
  --ct-btn-disabled-border-color: #05A148;
  --ct-gradient: none;
}

.btn-outline-lwk-green-light {
  --ct-btn-color: #097131;
  --ct-btn-border-color: #097131;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: #097131;
  --ct-btn-hover-border-color: #097131;
  --ct-btn-focus-shadow-rgb: 9, 113, 49;
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: #097131;
  --ct-btn-active-border-color: #097131;
  --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --ct-btn-disabled-color: #097131;
  --ct-btn-disabled-bg: transparent;
  --ct-btn-disabled-border-color: #097131;
  --ct-gradient: none;
}

.btn-link {
  --ct-btn-font-weight: 400;
  --ct-btn-color: var(--ct-link-color);
  --ct-btn-bg: transparent;
  --ct-btn-border-color: transparent;
  --ct-btn-hover-color: var(--ct-link-hover-color);
  --ct-btn-hover-border-color: transparent;
  --ct-btn-active-color: var(--ct-link-hover-color);
  --ct-btn-active-border-color: transparent;
  --ct-btn-disabled-color: #8a969c;
  --ct-btn-disabled-border-color: transparent;
  --ct-btn-box-shadow: 0 0 0 #000;
  --ct-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: none;
}
.btn-link:focus-visible {
  color: var(--ct-btn-color);
}
.btn-link:hover {
  color: var(--ct-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --ct-btn-padding-y: 0.5rem;
  --ct-btn-padding-x: 1rem;
  --ct-btn-font-size: calc(1.2501rem + 0.0012vw);
  --ct-btn-border-radius: 0.3rem;
}
@media (min-width: 1200px) {
  .btn-lg, .btn-group-lg > .btn {
    --ct-btn-font-size: 1.251rem;
  }
}

.btn-sm, .btn-group-sm > .btn {
  --ct-btn-padding-y: 0.28rem;
  --ct-btn-padding-x: 0.8rem;
  --ct-btn-font-size: 0.875rem;
  --ct-btn-border-radius: 0.2rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.15em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --ct-dropdown-zindex: 1000;
  --ct-dropdown-min-width: 10rem;
  --ct-dropdown-padding-x: 0;
  --ct-dropdown-padding-y: 0.25rem;
  --ct-dropdown-spacer: 0.125rem;
  --ct-dropdown-font-size: 0.9rem;
  --ct-dropdown-color: var(--ct-body-color);
  --ct-dropdown-bg: var(--ct-secondary-bg);
  --ct-dropdown-border-color: var(--ct-border-color);
  --ct-dropdown-border-radius: 0.25rem;
  --ct-dropdown-border-width: var(--ct-border-width);
  --ct-dropdown-inner-border-radius: calc(0.25rem - var(--ct-border-width));
  --ct-dropdown-divider-bg: var(--ct-border-color);
  --ct-dropdown-divider-margin-y: 0.75rem;
  --ct-dropdown-box-shadow: var(--ct-box-shadow);
  --ct-dropdown-link-color: var(--ct-body-color);
  --ct-dropdown-link-hover-color: var(--ct-tertiary-color);
  --ct-dropdown-link-hover-bg: var(--ct-tertiary-bg);
  --ct-dropdown-link-active-color: var(--ct-tertiary-color);
  --ct-dropdown-link-active-bg: var(--ct-tertiary-bg);
  --ct-dropdown-link-disabled-color: var(--ct-tertiary-color);
  --ct-dropdown-item-padding-x: 1.5rem;
  --ct-dropdown-item-padding-y: 0.375rem;
  --ct-dropdown-header-color: inherit;
  --ct-dropdown-header-padding-x: 1.125rem;
  --ct-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--ct-dropdown-zindex);
  display: none;
  min-width: var(--ct-dropdown-min-width);
  padding: var(--ct-dropdown-padding-y) var(--ct-dropdown-padding-x);
  margin: 0;
  font-size: var(--ct-dropdown-font-size);
  color: var(--ct-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--ct-dropdown-bg);
  background-clip: padding-box;
  border: var(--ct-dropdown-border-width) solid var(--ct-dropdown-border-color);
  border-radius: var(--ct-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--ct-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--ct-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.15em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--ct-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.15em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--ct-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.15em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.15em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--ct-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--ct-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--ct-dropdown-item-padding-y) var(--ct-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--ct-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--ct-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--ct-dropdown-link-hover-color);
  background-color: var(--ct-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--ct-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--ct-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--ct-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--ct-dropdown-header-padding-y) var(--ct-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--ct-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--ct-dropdown-item-padding-y) var(--ct-dropdown-item-padding-x);
  color: var(--ct-dropdown-link-color);
}

.dropdown-menu-dark {
  --ct-dropdown-color: #dee2e6;
  --ct-dropdown-bg: #343a40;
  --ct-dropdown-border-color: var(--ct-border-color);
  --ct-dropdown-box-shadow: ;
  --ct-dropdown-link-color: #dee2e6;
  --ct-dropdown-link-hover-color: #fff;
  --ct-dropdown-divider-bg: var(--ct-border-color);
  --ct-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --ct-dropdown-link-active-color: var(--ct-tertiary-color);
  --ct-dropdown-link-active-bg: var(--ct-tertiary-bg);
  --ct-dropdown-link-disabled-color: #a1a9b1;
  --ct-dropdown-header-color: #a1a9b1;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0.15rem;
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--ct-border-width) * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.675rem;
  padding-left: 0.675rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.6rem;
  padding-left: 0.6rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--ct-border-width) * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --ct-nav-link-padding-x: 1rem;
  --ct-nav-link-padding-y: 0.5rem;
  --ct-nav-link-font-weight: ;
  --ct-nav-link-color: var(--ct-link-color);
  --ct-nav-link-hover-color: var(--ct-link-hover-color);
  --ct-nav-link-disabled-color: var(--ct-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--ct-nav-link-padding-y) var(--ct-nav-link-padding-x);
  font-size: var(--ct-nav-link-font-size);
  font-weight: var(--ct-nav-link-font-weight);
  color: var(--ct-nav-link-color);
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--ct-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgba(44, 142, 248, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--ct-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --ct-nav-tabs-border-width: var(--ct-border-width);
  --ct-nav-tabs-border-color: var(--ct-border-color);
  --ct-nav-tabs-border-radius: var(--ct-border-radius);
  --ct-nav-tabs-link-hover-border-color: var(--ct-secondary-bg) var(--ct-secondary-bg) var(--ct-border-color);
  --ct-nav-tabs-link-active-color: var(--ct-emphasis-color);
  --ct-nav-tabs-link-active-bg: var(--ct-secondary-bg);
  --ct-nav-tabs-link-active-border-color: var(--ct-border-color) var(--ct-border-color) var(--ct-secondary-bg);
  border-bottom: var(--ct-nav-tabs-border-width) solid var(--ct-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--ct-nav-tabs-border-width));
  border: var(--ct-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--ct-nav-tabs-border-radius);
  border-top-right-radius: var(--ct-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--ct-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--ct-nav-tabs-link-active-color);
  background-color: var(--ct-nav-tabs-link-active-bg);
  border-color: var(--ct-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--ct-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --ct-nav-pills-border-radius: 0.25rem;
  --ct-nav-pills-link-active-color: #fff;
  --ct-nav-pills-link-active-bg: #2c8ef8;
}
.nav-pills .nav-link {
  border-radius: var(--ct-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--ct-nav-pills-link-active-color);
  background-color: var(--ct-nav-pills-link-active-bg);
}

.nav-underline {
  --ct-nav-underline-gap: 1rem;
  --ct-nav-underline-border-width: 0.125rem;
  --ct-nav-underline-link-active-color: var(--ct-emphasis-color);
  gap: var(--ct-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--ct-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--ct-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --ct-navbar-padding-x: 0;
  --ct-navbar-padding-y: 0.75rem;
  --ct-navbar-color: rgba(var(--ct-emphasis-color-rgb), 0.65);
  --ct-navbar-hover-color: rgba(var(--ct-emphasis-color-rgb), 0.8);
  --ct-navbar-disabled-color: rgba(var(--ct-emphasis-color-rgb), 0.3);
  --ct-navbar-active-color: rgba(var(--ct-emphasis-color-rgb), 1);
  --ct-navbar-brand-padding-y: 0.23675rem;
  --ct-navbar-brand-margin-end: 1rem;
  --ct-navbar-brand-font-size: 1.251rem;
  --ct-navbar-brand-color: rgba(var(--ct-emphasis-color-rgb), 1);
  --ct-navbar-brand-hover-color: rgba(var(--ct-emphasis-color-rgb), 1);
  --ct-navbar-nav-link-padding-x: 0.5rem;
  --ct-navbar-toggler-padding-y: 0.25rem;
  --ct-navbar-toggler-padding-x: 0.75rem;
  --ct-navbar-toggler-font-size: 1.251rem;
  --ct-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28108, 117, 125, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --ct-navbar-toggler-border-color: rgba(var(--ct-emphasis-color-rgb), 0.15);
  --ct-navbar-toggler-border-radius: 0.15rem;
  --ct-navbar-toggler-focus-width: 0.15rem;
  --ct-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--ct-navbar-padding-y) var(--ct-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--ct-navbar-brand-padding-y);
  padding-bottom: var(--ct-navbar-brand-padding-y);
  margin-right: var(--ct-navbar-brand-margin-end);
  font-size: var(--ct-navbar-brand-font-size);
  color: var(--ct-navbar-brand-color);
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--ct-navbar-brand-hover-color);
}

.navbar-nav {
  --ct-nav-link-padding-x: 0;
  --ct-nav-link-padding-y: 0.5rem;
  --ct-nav-link-font-weight: ;
  --ct-nav-link-color: var(--ct-navbar-color);
  --ct-nav-link-hover-color: var(--ct-navbar-hover-color);
  --ct-nav-link-disabled-color: var(--ct-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--ct-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--ct-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--ct-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--ct-navbar-toggler-padding-y) var(--ct-navbar-toggler-padding-x);
  font-size: var(--ct-navbar-toggler-font-size);
  line-height: 1;
  color: var(--ct-navbar-color);
  background-color: transparent;
  border: var(--ct-border-width) solid var(--ct-navbar-toggler-border-color);
  border-radius: var(--ct-navbar-toggler-border-radius);
  transition: var(--ct-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--ct-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--ct-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--ct-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--ct-navbar-nav-link-padding-x);
    padding-left: var(--ct-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--ct-navbar-nav-link-padding-x);
    padding-left: var(--ct-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--ct-navbar-nav-link-padding-x);
    padding-left: var(--ct-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--ct-navbar-nav-link-padding-x);
    padding-left: var(--ct-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--ct-navbar-nav-link-padding-x);
    padding-left: var(--ct-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--ct-navbar-nav-link-padding-x);
  padding-left: var(--ct-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --ct-navbar-color: rgba(255, 255, 255, 0.55);
  --ct-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --ct-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --ct-navbar-active-color: #fff;
  --ct-navbar-brand-color: #fff;
  --ct-navbar-brand-hover-color: #fff;
  --ct-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --ct-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --ct-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --ct-card-spacer-y: 1.5rem;
  --ct-card-spacer-x: 1.5rem;
  --ct-card-title-spacer-y: 0.75rem;
  --ct-card-title-color: ;
  --ct-card-subtitle-color: ;
  --ct-card-border-width: var(--ct-theme-card-border-width);
  --ct-card-border-color: var(--ct-border-color);
  --ct-card-border-radius: var(--ct-border-radius);
  --ct-card-box-shadow: var(--ct-box-shadow);
  --ct-card-inner-border-radius: calc(var(--ct-border-radius) - (var(--ct-theme-card-border-width)));
  --ct-card-cap-padding-y: 1.125rem;
  --ct-card-cap-padding-x: 1.5rem;
  --ct-card-cap-bg: transparent;
  --ct-card-cap-color: ;
  --ct-card-height: ;
  --ct-card-color: ;
  --ct-card-bg: var(--ct-secondary-bg);
  --ct-card-img-overlay-padding: 1.5rem;
  --ct-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--ct-card-height);
  color: var(--ct-body-color);
  word-wrap: break-word;
  background-color: var(--ct-card-bg);
  background-clip: border-box;
  border: var(--ct-card-border-width) solid var(--ct-card-border-color);
  border-radius: var(--ct-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--ct-card-inner-border-radius);
  border-top-right-radius: var(--ct-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--ct-card-inner-border-radius);
  border-bottom-left-radius: var(--ct-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--ct-card-spacer-y) var(--ct-card-spacer-x);
  color: var(--ct-card-color);
}

.card-title {
  margin-bottom: var(--ct-card-title-spacer-y);
  color: var(--ct-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--ct-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--ct-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--ct-card-spacer-x);
}

.card-header {
  padding: var(--ct-card-cap-padding-y) var(--ct-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--ct-card-cap-color);
  background-color: var(--ct-card-cap-bg);
  border-bottom: var(--ct-card-border-width) solid var(--ct-card-border-color);
}
.card-header:first-child {
  border-radius: var(--ct-card-inner-border-radius) var(--ct-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--ct-card-cap-padding-y) var(--ct-card-cap-padding-x);
  color: var(--ct-card-cap-color);
  background-color: var(--ct-card-cap-bg);
  border-top: var(--ct-card-border-width) solid var(--ct-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--ct-card-inner-border-radius) var(--ct-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--ct-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--ct-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--ct-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--ct-card-bg);
  border-bottom-color: var(--ct-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--ct-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--ct-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--ct-card-img-overlay-padding);
  border-radius: var(--ct-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--ct-card-inner-border-radius);
  border-top-right-radius: var(--ct-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--ct-card-inner-border-radius);
  border-bottom-left-radius: var(--ct-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--ct-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --ct-accordion-color: var(--ct-body-color);
  --ct-accordion-bg: var(--ct-secondary-bg);
  --ct-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --ct-accordion-border-color: var(--ct-border-color);
  --ct-accordion-border-width: var(--ct-border-width);
  --ct-accordion-border-radius: var(--ct-border-radius);
  --ct-accordion-inner-border-radius: calc(var(--ct-border-radius) - (var(--ct-border-width)));
  --ct-accordion-btn-padding-x: 1.25rem;
  --ct-accordion-btn-padding-y: 1rem;
  --ct-accordion-btn-color: var(--ct-body-color);
  --ct-accordion-btn-bg: var(--ct-accordion-bg);
  --ct-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236c757d'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --ct-accordion-btn-icon-width: 0.75rem;
  --ct-accordion-btn-icon-transform: rotate(-180deg);
  --ct-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --ct-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23123963'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --ct-accordion-btn-focus-box-shadow: none;
  --ct-accordion-body-padding-x: 1.25rem;
  --ct-accordion-body-padding-y: 1rem;
  --ct-accordion-active-color: var(--ct-primary);
  --ct-accordion-active-bg: var(--ct-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--ct-accordion-btn-padding-y) var(--ct-accordion-btn-padding-x);
  font-size: 0.9rem;
  color: var(--ct-accordion-btn-color);
  text-align: left;
  background-color: var(--ct-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--ct-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--ct-accordion-active-color);
  background-color: var(--ct-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--ct-accordion-border-width)) 0 var(--ct-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--ct-accordion-btn-active-icon);
  transform: var(--ct-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--ct-accordion-btn-icon-width);
  height: var(--ct-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--ct-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--ct-accordion-btn-icon-width);
  transition: var(--ct-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: var(--ct-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--ct-accordion-color);
  background-color: var(--ct-accordion-bg);
  border: var(--ct-accordion-border-width) solid var(--ct-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--ct-accordion-border-radius);
  border-top-right-radius: var(--ct-accordion-border-radius);
}
.accordion-item:first-of-type > .accordion-header .accordion-button {
  border-top-left-radius: var(--ct-accordion-inner-border-radius);
  border-top-right-radius: var(--ct-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--ct-accordion-border-radius);
  border-bottom-left-radius: var(--ct-accordion-border-radius);
}
.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-bottom-right-radius: var(--ct-accordion-inner-border-radius);
  border-bottom-left-radius: var(--ct-accordion-inner-border-radius);
}
.accordion-item:last-of-type > .accordion-collapse {
  border-bottom-right-radius: var(--ct-accordion-border-radius);
  border-bottom-left-radius: var(--ct-accordion-border-radius);
}

.accordion-body {
  padding: var(--ct-accordion-body-padding-y) var(--ct-accordion-body-padding-x);
}

.accordion-flush > .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush > .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush > .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush > .accordion-item > .accordion-header .accordion-button, .accordion-flush > .accordion-item > .accordion-header .accordion-button.collapsed {
  border-radius: 0;
}
.accordion-flush > .accordion-item > .accordion-collapse {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --ct-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --ct-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --ct-breadcrumb-padding-x: 0;
  --ct-breadcrumb-padding-y: 1.5rem;
  --ct-breadcrumb-margin-bottom: 1rem;
  --ct-breadcrumb-bg: ;
  --ct-breadcrumb-border-radius: ;
  --ct-breadcrumb-divider-color: var(--ct-secondary-color);
  --ct-breadcrumb-item-padding-x: 0.5rem;
  --ct-breadcrumb-item-active-color: var(--ct-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--ct-breadcrumb-padding-y) var(--ct-breadcrumb-padding-x);
  margin-bottom: var(--ct-breadcrumb-margin-bottom);
  font-size: var(--ct-breadcrumb-font-size);
  list-style: none;
  background-color: var(--ct-breadcrumb-bg);
  border-radius: var(--ct-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--ct-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--ct-breadcrumb-item-padding-x);
  color: var(--ct-breadcrumb-divider-color);
  content: var(--ct-breadcrumb-divider, "\f0142") /* rtl: var(--ct-breadcrumb-divider, "\f0142") */;
}
.breadcrumb-item.active {
  color: var(--ct-breadcrumb-item-active-color);
}

.pagination {
  --ct-pagination-padding-x: 0.75rem;
  --ct-pagination-padding-y: 0.375rem;
  --ct-pagination-font-size: 0.9rem;
  --ct-pagination-color: var(--ct-tertiary-color);
  --ct-pagination-bg: var(--ct-secondary-bg);
  --ct-pagination-border-width: var(--ct-border-width);
  --ct-pagination-border-color: var(--ct-border-color);
  --ct-pagination-border-radius: var(--ct-border-radius);
  --ct-pagination-hover-color: var(--ct-link-hover-color);
  --ct-pagination-hover-bg: var(--ct-tertiary-bg);
  --ct-pagination-hover-border-color: var(--ct-border-color);
  --ct-pagination-focus-color: var(--ct-link-hover-color);
  --ct-pagination-focus-bg: var(--ct-secondary-bg);
  --ct-pagination-focus-box-shadow: none;
  --ct-pagination-active-color: #fff;
  --ct-pagination-active-bg: #2c8ef8;
  --ct-pagination-active-border-color: #2c8ef8;
  --ct-pagination-disabled-color: var(--ct-secondary-color);
  --ct-pagination-disabled-bg: var(--ct-secondary-bg);
  --ct-pagination-disabled-border-color: var(--ct-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--ct-pagination-padding-y) var(--ct-pagination-padding-x);
  font-size: var(--ct-pagination-font-size);
  color: var(--ct-pagination-color);
  background-color: var(--ct-pagination-bg);
  border: var(--ct-pagination-border-width) solid var(--ct-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--ct-pagination-hover-color);
  background-color: var(--ct-pagination-hover-bg);
  border-color: var(--ct-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--ct-pagination-focus-color);
  background-color: var(--ct-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--ct-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--ct-pagination-active-color);
  background-color: var(--ct-pagination-active-bg);
  border-color: var(--ct-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--ct-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--ct-pagination-disabled-bg);
  border-color: var(--ct-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--ct-border-width) * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--ct-pagination-border-radius);
  border-bottom-left-radius: var(--ct-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--ct-pagination-border-radius);
  border-bottom-right-radius: var(--ct-pagination-border-radius);
}

.pagination-lg {
  --ct-pagination-padding-x: 1.5rem;
  --ct-pagination-padding-y: 0.75rem;
  --ct-pagination-font-size: calc(1.2501rem + 0.0012vw);
  --ct-pagination-border-radius: 0.3rem;
}
@media (min-width: 1200px) {
  .pagination-lg {
    --ct-pagination-font-size: 1.251rem;
  }
}

.pagination-sm {
  --ct-pagination-padding-x: 0.5rem;
  --ct-pagination-padding-y: 0.25rem;
  --ct-pagination-font-size: 0.875rem;
  --ct-pagination-border-radius: 0.2rem;
}

.badge {
  --ct-badge-padding-x: 0.4em;
  --ct-badge-padding-y: 0.25em;
  --ct-badge-font-size: 0.75em;
  --ct-badge-font-weight: 700;
  --ct-badge-color: #fff;
  --ct-badge-border-radius: 0.25rem;
  display: inline-block;
  padding: var(--ct-badge-padding-y) var(--ct-badge-padding-x);
  font-size: var(--ct-badge-font-size);
  font-weight: var(--ct-badge-font-weight);
  line-height: 1;
  color: var(--ct-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--ct-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --ct-alert-bg: transparent;
  --ct-alert-padding-x: 1.25rem;
  --ct-alert-padding-y: 0.8rem;
  --ct-alert-margin-bottom: 1rem;
  --ct-alert-color: inherit;
  --ct-alert-border-color: transparent;
  --ct-alert-border: var(--ct-border-width) solid var(--ct-alert-border-color);
  --ct-alert-border-radius: 0.25rem;
  --ct-alert-link-color: inherit;
  position: relative;
  padding: var(--ct-alert-padding-y) var(--ct-alert-padding-x);
  margin-bottom: var(--ct-alert-margin-bottom);
  color: var(--ct-alert-color);
  background-color: var(--ct-alert-bg);
  border: var(--ct-alert-border);
  border-radius: var(--ct-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--ct-alert-link-color);
}

.alert-dismissible {
  padding-right: 3.75rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1rem 1.25rem;
}

.alert-primary {
  --ct-alert-color: var(--ct-primary-text-emphasis);
  --ct-alert-bg: var(--ct-primary-bg-subtle);
  --ct-alert-border-color: var(--ct-primary-border-subtle);
  --ct-alert-link-color: var(--ct-primary-text-emphasis);
}

.alert-secondary {
  --ct-alert-color: var(--ct-secondary-text-emphasis);
  --ct-alert-bg: var(--ct-secondary-bg-subtle);
  --ct-alert-border-color: var(--ct-secondary-border-subtle);
  --ct-alert-link-color: var(--ct-secondary-text-emphasis);
}

.alert-success {
  --ct-alert-color: var(--ct-success-text-emphasis);
  --ct-alert-bg: var(--ct-success-bg-subtle);
  --ct-alert-border-color: var(--ct-success-border-subtle);
  --ct-alert-link-color: var(--ct-success-text-emphasis);
}

.alert-info {
  --ct-alert-color: var(--ct-info-text-emphasis);
  --ct-alert-bg: var(--ct-info-bg-subtle);
  --ct-alert-border-color: var(--ct-info-border-subtle);
  --ct-alert-link-color: var(--ct-info-text-emphasis);
}

.alert-warning {
  --ct-alert-color: var(--ct-warning-text-emphasis);
  --ct-alert-bg: var(--ct-warning-bg-subtle);
  --ct-alert-border-color: var(--ct-warning-border-subtle);
  --ct-alert-link-color: var(--ct-warning-text-emphasis);
}

.alert-danger {
  --ct-alert-color: var(--ct-danger-text-emphasis);
  --ct-alert-bg: var(--ct-danger-bg-subtle);
  --ct-alert-border-color: var(--ct-danger-border-subtle);
  --ct-alert-link-color: var(--ct-danger-text-emphasis);
}

.alert-light {
  --ct-alert-color: var(--ct-light-text-emphasis);
  --ct-alert-bg: var(--ct-light-bg-subtle);
  --ct-alert-border-color: var(--ct-light-border-subtle);
  --ct-alert-link-color: var(--ct-light-text-emphasis);
}

.alert-dark {
  --ct-alert-color: var(--ct-dark-text-emphasis);
  --ct-alert-bg: var(--ct-dark-bg-subtle);
  --ct-alert-border-color: var(--ct-dark-border-subtle);
  --ct-alert-link-color: var(--ct-dark-text-emphasis);
}

.alert-lwk-green-dark {
  --ct-alert-color: var(--ct-lwk-green-dark-text-emphasis);
  --ct-alert-bg: var(--ct-lwk-green-dark-bg-subtle);
  --ct-alert-border-color: var(--ct-lwk-green-dark-border-subtle);
  --ct-alert-link-color: var(--ct-lwk-green-dark-text-emphasis);
}

.alert-lwk-green-light {
  --ct-alert-color: var(--ct-lwk-green-light-text-emphasis);
  --ct-alert-bg: var(--ct-lwk-green-light-bg-subtle);
  --ct-alert-border-color: var(--ct-lwk-green-light-border-subtle);
  --ct-alert-link-color: var(--ct-lwk-green-light-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --ct-progress-height: 1rem;
  --ct-progress-font-size: 0.675rem;
  --ct-progress-bg: var(--ct-tertiary-bg);
  --ct-progress-border-radius: var(--ct-border-radius);
  --ct-progress-box-shadow: var(--ct-box-shadow-inset);
  --ct-progress-bar-color: #fff;
  --ct-progress-bar-bg: #2c8ef8;
  --ct-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--ct-progress-height);
  overflow: hidden;
  font-size: var(--ct-progress-font-size);
  background-color: var(--ct-progress-bg);
  border-radius: var(--ct-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--ct-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--ct-progress-bar-bg);
  transition: var(--ct-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--ct-progress-height) var(--ct-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --ct-list-group-color: var(--ct-tertiary-color);
  --ct-list-group-bg: var(--ct-secondary-bg);
  --ct-list-group-border-color: var(--ct-border-color);
  --ct-list-group-border-width: var(--ct-border-width);
  --ct-list-group-border-radius: var(--ct-border-radius);
  --ct-list-group-item-padding-x: 1.2rem;
  --ct-list-group-item-padding-y: 0.75rem;
  --ct-list-group-action-color: var(--ct-secondary-color);
  --ct-list-group-action-hover-color: var(--ct-tertiary-color);
  --ct-list-group-action-hover-bg: var(--ct-tertiary-bg);
  --ct-list-group-action-active-color: var(--ct-body-color);
  --ct-list-group-action-active-bg: var(--ct-secondary-bg);
  --ct-list-group-disabled-color: var(--ct-secondary-color);
  --ct-list-group-disabled-bg: var(--ct-tertiary-bg);
  --ct-list-group-active-color: #fff;
  --ct-list-group-active-bg: #2c8ef8;
  --ct-list-group-active-border-color: #2c8ef8;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--ct-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--ct-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--ct-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--ct-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--ct-list-group-action-active-color);
  background-color: var(--ct-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--ct-list-group-item-padding-y) var(--ct-list-group-item-padding-x);
  color: var(--ct-list-group-color);
  background-color: var(--ct-list-group-bg);
  border: var(--ct-list-group-border-width) solid var(--ct-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--ct-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--ct-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--ct-list-group-active-color);
  background-color: var(--ct-list-group-active-bg);
  border-color: var(--ct-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--ct-list-group-border-width));
  border-top-width: var(--ct-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--ct-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--ct-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--ct-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--ct-list-group-border-width));
  border-left-width: var(--ct-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--ct-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--ct-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--ct-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--ct-list-group-border-width));
    border-left-width: var(--ct-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--ct-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--ct-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--ct-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--ct-list-group-border-width));
    border-left-width: var(--ct-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--ct-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--ct-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--ct-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--ct-list-group-border-width));
    border-left-width: var(--ct-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--ct-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--ct-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--ct-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--ct-list-group-border-width));
    border-left-width: var(--ct-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--ct-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--ct-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--ct-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--ct-list-group-border-width));
    border-left-width: var(--ct-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--ct-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --ct-list-group-color: var(--ct-primary-text-emphasis);
  --ct-list-group-bg: var(--ct-primary-bg-subtle);
  --ct-list-group-border-color: var(--ct-primary-border-subtle);
  --ct-list-group-action-hover-color: var(--ct-emphasis-color);
  --ct-list-group-action-hover-bg: var(--ct-primary-border-subtle);
  --ct-list-group-action-active-color: var(--ct-emphasis-color);
  --ct-list-group-action-active-bg: var(--ct-primary-border-subtle);
  --ct-list-group-active-color: var(--ct-primary-bg-subtle);
  --ct-list-group-active-bg: var(--ct-primary-text-emphasis);
  --ct-list-group-active-border-color: var(--ct-primary-text-emphasis);
}

.list-group-item-secondary {
  --ct-list-group-color: var(--ct-secondary-text-emphasis);
  --ct-list-group-bg: var(--ct-secondary-bg-subtle);
  --ct-list-group-border-color: var(--ct-secondary-border-subtle);
  --ct-list-group-action-hover-color: var(--ct-emphasis-color);
  --ct-list-group-action-hover-bg: var(--ct-secondary-border-subtle);
  --ct-list-group-action-active-color: var(--ct-emphasis-color);
  --ct-list-group-action-active-bg: var(--ct-secondary-border-subtle);
  --ct-list-group-active-color: var(--ct-secondary-bg-subtle);
  --ct-list-group-active-bg: var(--ct-secondary-text-emphasis);
  --ct-list-group-active-border-color: var(--ct-secondary-text-emphasis);
}

.list-group-item-success {
  --ct-list-group-color: var(--ct-success-text-emphasis);
  --ct-list-group-bg: var(--ct-success-bg-subtle);
  --ct-list-group-border-color: var(--ct-success-border-subtle);
  --ct-list-group-action-hover-color: var(--ct-emphasis-color);
  --ct-list-group-action-hover-bg: var(--ct-success-border-subtle);
  --ct-list-group-action-active-color: var(--ct-emphasis-color);
  --ct-list-group-action-active-bg: var(--ct-success-border-subtle);
  --ct-list-group-active-color: var(--ct-success-bg-subtle);
  --ct-list-group-active-bg: var(--ct-success-text-emphasis);
  --ct-list-group-active-border-color: var(--ct-success-text-emphasis);
}

.list-group-item-info {
  --ct-list-group-color: var(--ct-info-text-emphasis);
  --ct-list-group-bg: var(--ct-info-bg-subtle);
  --ct-list-group-border-color: var(--ct-info-border-subtle);
  --ct-list-group-action-hover-color: var(--ct-emphasis-color);
  --ct-list-group-action-hover-bg: var(--ct-info-border-subtle);
  --ct-list-group-action-active-color: var(--ct-emphasis-color);
  --ct-list-group-action-active-bg: var(--ct-info-border-subtle);
  --ct-list-group-active-color: var(--ct-info-bg-subtle);
  --ct-list-group-active-bg: var(--ct-info-text-emphasis);
  --ct-list-group-active-border-color: var(--ct-info-text-emphasis);
}

.list-group-item-warning {
  --ct-list-group-color: var(--ct-warning-text-emphasis);
  --ct-list-group-bg: var(--ct-warning-bg-subtle);
  --ct-list-group-border-color: var(--ct-warning-border-subtle);
  --ct-list-group-action-hover-color: var(--ct-emphasis-color);
  --ct-list-group-action-hover-bg: var(--ct-warning-border-subtle);
  --ct-list-group-action-active-color: var(--ct-emphasis-color);
  --ct-list-group-action-active-bg: var(--ct-warning-border-subtle);
  --ct-list-group-active-color: var(--ct-warning-bg-subtle);
  --ct-list-group-active-bg: var(--ct-warning-text-emphasis);
  --ct-list-group-active-border-color: var(--ct-warning-text-emphasis);
}

.list-group-item-danger {
  --ct-list-group-color: var(--ct-danger-text-emphasis);
  --ct-list-group-bg: var(--ct-danger-bg-subtle);
  --ct-list-group-border-color: var(--ct-danger-border-subtle);
  --ct-list-group-action-hover-color: var(--ct-emphasis-color);
  --ct-list-group-action-hover-bg: var(--ct-danger-border-subtle);
  --ct-list-group-action-active-color: var(--ct-emphasis-color);
  --ct-list-group-action-active-bg: var(--ct-danger-border-subtle);
  --ct-list-group-active-color: var(--ct-danger-bg-subtle);
  --ct-list-group-active-bg: var(--ct-danger-text-emphasis);
  --ct-list-group-active-border-color: var(--ct-danger-text-emphasis);
}

.list-group-item-light {
  --ct-list-group-color: var(--ct-light-text-emphasis);
  --ct-list-group-bg: var(--ct-light-bg-subtle);
  --ct-list-group-border-color: var(--ct-light-border-subtle);
  --ct-list-group-action-hover-color: var(--ct-emphasis-color);
  --ct-list-group-action-hover-bg: var(--ct-light-border-subtle);
  --ct-list-group-action-active-color: var(--ct-emphasis-color);
  --ct-list-group-action-active-bg: var(--ct-light-border-subtle);
  --ct-list-group-active-color: var(--ct-light-bg-subtle);
  --ct-list-group-active-bg: var(--ct-light-text-emphasis);
  --ct-list-group-active-border-color: var(--ct-light-text-emphasis);
}

.list-group-item-dark {
  --ct-list-group-color: var(--ct-dark-text-emphasis);
  --ct-list-group-bg: var(--ct-dark-bg-subtle);
  --ct-list-group-border-color: var(--ct-dark-border-subtle);
  --ct-list-group-action-hover-color: var(--ct-emphasis-color);
  --ct-list-group-action-hover-bg: var(--ct-dark-border-subtle);
  --ct-list-group-action-active-color: var(--ct-emphasis-color);
  --ct-list-group-action-active-bg: var(--ct-dark-border-subtle);
  --ct-list-group-active-color: var(--ct-dark-bg-subtle);
  --ct-list-group-active-bg: var(--ct-dark-text-emphasis);
  --ct-list-group-active-border-color: var(--ct-dark-text-emphasis);
}

.list-group-item-lwk-green-dark {
  --ct-list-group-color: var(--ct-lwk-green-dark-text-emphasis);
  --ct-list-group-bg: var(--ct-lwk-green-dark-bg-subtle);
  --ct-list-group-border-color: var(--ct-lwk-green-dark-border-subtle);
  --ct-list-group-action-hover-color: var(--ct-emphasis-color);
  --ct-list-group-action-hover-bg: var(--ct-lwk-green-dark-border-subtle);
  --ct-list-group-action-active-color: var(--ct-emphasis-color);
  --ct-list-group-action-active-bg: var(--ct-lwk-green-dark-border-subtle);
  --ct-list-group-active-color: var(--ct-lwk-green-dark-bg-subtle);
  --ct-list-group-active-bg: var(--ct-lwk-green-dark-text-emphasis);
  --ct-list-group-active-border-color: var(--ct-lwk-green-dark-text-emphasis);
}

.list-group-item-lwk-green-light {
  --ct-list-group-color: var(--ct-lwk-green-light-text-emphasis);
  --ct-list-group-bg: var(--ct-lwk-green-light-bg-subtle);
  --ct-list-group-border-color: var(--ct-lwk-green-light-border-subtle);
  --ct-list-group-action-hover-color: var(--ct-emphasis-color);
  --ct-list-group-action-hover-bg: var(--ct-lwk-green-light-border-subtle);
  --ct-list-group-action-active-color: var(--ct-emphasis-color);
  --ct-list-group-action-active-bg: var(--ct-lwk-green-light-border-subtle);
  --ct-list-group-active-color: var(--ct-lwk-green-light-bg-subtle);
  --ct-list-group-active-bg: var(--ct-lwk-green-light-text-emphasis);
  --ct-list-group-active-border-color: var(--ct-lwk-green-light-text-emphasis);
}

.btn-close {
  --ct-btn-close-color: var(--ct-secondary-color);
  --ct-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--ct-secondary-color%29'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --ct-btn-close-opacity: 0.5;
  --ct-btn-close-hover-opacity: 0.8;
  --ct-btn-close-focus-shadow: none;
  --ct-btn-close-focus-opacity: 1;
  --ct-btn-close-disabled-opacity: 0.25;
  --ct-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--ct-btn-close-color);
  background: transparent var(--ct-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: var(--ct-btn-close-opacity);
}
.btn-close:hover {
  color: var(--ct-btn-close-color);
  text-decoration: none;
  opacity: var(--ct-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--ct-btn-close-focus-shadow);
  opacity: var(--ct-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--ct-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--ct-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--ct-btn-close-white-filter);
}

.toast {
  --ct-toast-zindex: 1090;
  --ct-toast-padding-x: 0.75rem;
  --ct-toast-padding-y: 0.5rem;
  --ct-toast-spacing: 1.5rem;
  --ct-toast-max-width: 350px;
  --ct-toast-font-size: 0.875rem;
  --ct-toast-color: ;
  --ct-toast-bg: rgba(var(--ct-body-bg-rgb), 0.85);
  --ct-toast-border-width: var(--ct-border-width);
  --ct-toast-border-color: var(--ct-border-color);
  --ct-toast-border-radius: var(--ct-border-radius);
  --ct-toast-box-shadow: var(--ct-box-shadow);
  --ct-toast-header-color: var(--ct-secondary-color);
  --ct-toast-header-bg: rgba(var(--ct-body-bg-rgb), 0.85);
  --ct-toast-header-border-color: var(--ct-border-color);
  width: var(--ct-toast-max-width);
  max-width: 100%;
  font-size: var(--ct-toast-font-size);
  color: var(--ct-toast-color);
  pointer-events: auto;
  background-color: var(--ct-toast-bg);
  background-clip: padding-box;
  border: var(--ct-toast-border-width) solid var(--ct-toast-border-color);
  box-shadow: var(--ct-toast-box-shadow);
  border-radius: var(--ct-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --ct-toast-zindex: 1090;
  position: absolute;
  z-index: var(--ct-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--ct-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--ct-toast-padding-y) var(--ct-toast-padding-x);
  color: var(--ct-toast-header-color);
  background-color: var(--ct-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--ct-toast-border-width) solid var(--ct-toast-header-border-color);
  border-top-left-radius: calc(var(--ct-toast-border-radius) - var(--ct-toast-border-width));
  border-top-right-radius: calc(var(--ct-toast-border-radius) - var(--ct-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--ct-toast-padding-x));
  margin-left: var(--ct-toast-padding-x);
}

.toast-body {
  padding: var(--ct-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --ct-modal-zindex: 1055;
  --ct-modal-width: 500px;
  --ct-modal-padding: 1rem;
  --ct-modal-margin: 0.5rem;
  --ct-modal-color: ;
  --ct-modal-bg: var(--ct-secondary-bg);
  --ct-modal-border-color: transparent;
  --ct-modal-border-width: var(--ct-border-width);
  --ct-modal-border-radius: var(--ct-border-radius-lg);
  --ct-modal-box-shadow: 0 0.125rem 0.25rem rgba(var(--ct-body-color-rgb), 0.15);
  --ct-modal-inner-border-radius: calc(var(--ct-border-radius-lg) - (var(--ct-border-width)));
  --ct-modal-header-padding-x: 1rem;
  --ct-modal-header-padding-y: 1rem;
  --ct-modal-header-padding: 1rem 1rem;
  --ct-modal-header-border-color: var(--ct-border-color);
  --ct-modal-header-border-width: var(--ct-border-width);
  --ct-modal-title-line-height: 1.5;
  --ct-modal-footer-gap: 0.5rem;
  --ct-modal-footer-bg: ;
  --ct-modal-footer-border-color: var(--ct-border-color);
  --ct-modal-footer-border-width: var(--ct-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--ct-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--ct-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--ct-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--ct-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--ct-modal-color);
  pointer-events: auto;
  background-color: var(--ct-modal-bg);
  background-clip: padding-box;
  border: var(--ct-modal-border-width) solid var(--ct-modal-border-color);
  border-radius: var(--ct-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --ct-backdrop-zindex: 1050;
  --ct-backdrop-bg: var(--ct-emphasis-color);
  --ct-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--ct-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--ct-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--ct-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--ct-modal-header-padding);
  border-bottom: var(--ct-modal-header-border-width) solid var(--ct-modal-header-border-color);
  border-top-left-radius: var(--ct-modal-inner-border-radius);
  border-top-right-radius: var(--ct-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--ct-modal-header-padding-y) * 0.5) calc(var(--ct-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--ct-modal-header-padding-y)) calc(-0.5 * var(--ct-modal-header-padding-x)) calc(-0.5 * var(--ct-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--ct-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--ct-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--ct-modal-padding) - var(--ct-modal-footer-gap) * 0.5);
  background-color: var(--ct-modal-footer-bg);
  border-top: var(--ct-modal-footer-border-width) solid var(--ct-modal-footer-border-color);
  border-bottom-right-radius: var(--ct-modal-inner-border-radius);
  border-bottom-left-radius: var(--ct-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--ct-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --ct-modal-margin: 1.75rem;
    --ct-modal-box-shadow: var(--ct-box-shadow);
  }
  .modal-dialog {
    max-width: var(--ct-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --ct-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --ct-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --ct-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --ct-tooltip-zindex: 1080;
  --ct-tooltip-max-width: 200px;
  --ct-tooltip-padding-x: 0.75rem;
  --ct-tooltip-padding-y: 0.375rem;
  --ct-tooltip-margin: ;
  --ct-tooltip-font-size: 0.875rem;
  --ct-tooltip-color: var(--ct-body-bg);
  --ct-tooltip-bg: var(--ct-body-color);
  --ct-tooltip-border-radius: var(--ct-border-radius);
  --ct-tooltip-opacity: 1;
  --ct-tooltip-arrow-width: 0.8rem;
  --ct-tooltip-arrow-height: 0.4rem;
  z-index: var(--ct-tooltip-zindex);
  display: block;
  margin: var(--ct-tooltip-margin);
  font-family: var(--ct-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--ct-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--ct-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--ct-tooltip-arrow-width);
  height: var(--ct-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--ct-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--ct-tooltip-arrow-height) calc(var(--ct-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--ct-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--ct-tooltip-arrow-height));
  width: var(--ct-tooltip-arrow-height);
  height: var(--ct-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--ct-tooltip-arrow-width) * 0.5) var(--ct-tooltip-arrow-height) calc(var(--ct-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--ct-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--ct-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--ct-tooltip-arrow-width) * 0.5) var(--ct-tooltip-arrow-height);
  border-bottom-color: var(--ct-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--ct-tooltip-arrow-height));
  width: var(--ct-tooltip-arrow-height);
  height: var(--ct-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--ct-tooltip-arrow-width) * 0.5) 0 calc(var(--ct-tooltip-arrow-width) * 0.5) var(--ct-tooltip-arrow-height);
  border-left-color: var(--ct-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--ct-tooltip-max-width);
  padding: var(--ct-tooltip-padding-y) var(--ct-tooltip-padding-x);
  color: var(--ct-tooltip-color);
  text-align: center;
  background-color: var(--ct-tooltip-bg);
  border-radius: var(--ct-tooltip-border-radius);
}

.popover {
  --ct-popover-zindex: 1070;
  --ct-popover-max-width: 276px;
  --ct-popover-font-size: 0.875rem;
  --ct-popover-bg: var(--ct-secondary-bg);
  --ct-popover-border-width: var(--ct-border-width);
  --ct-popover-border-color: var(--ct-border-color);
  --ct-popover-border-radius: var(--ct-border-radius-lg);
  --ct-popover-inner-border-radius: calc(var(--ct-border-radius-lg) - var(--ct-border-width));
  --ct-popover-box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  --ct-popover-header-padding-x: 0.8rem;
  --ct-popover-header-padding-y: 0.7rem;
  --ct-popover-header-font-size: 0.9rem;
  --ct-popover-header-color: inherit;
  --ct-popover-header-bg: var(--ct-tertiary-bg);
  --ct-popover-body-padding-x: 0.75rem;
  --ct-popover-body-padding-y: 0.5rem;
  --ct-popover-body-color: var(--ct-body-color);
  --ct-popover-arrow-width: 1rem;
  --ct-popover-arrow-height: 0.5rem;
  --ct-popover-arrow-border: var(--ct-popover-border-color);
  z-index: var(--ct-popover-zindex);
  display: block;
  max-width: var(--ct-popover-max-width);
  font-family: var(--ct-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--ct-popover-font-size);
  word-wrap: break-word;
  background-color: var(--ct-popover-bg);
  background-clip: padding-box;
  border: var(--ct-popover-border-width) solid var(--ct-popover-border-color);
  border-radius: var(--ct-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--ct-popover-arrow-width);
  height: var(--ct-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--ct-popover-arrow-height)) - var(--ct-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--ct-popover-arrow-height) calc(var(--ct-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--ct-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--ct-popover-border-width);
  border-top-color: var(--ct-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--ct-popover-arrow-height)) - var(--ct-popover-border-width));
  width: var(--ct-popover-arrow-height);
  height: var(--ct-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--ct-popover-arrow-width) * 0.5) var(--ct-popover-arrow-height) calc(var(--ct-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--ct-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--ct-popover-border-width);
  border-right-color: var(--ct-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--ct-popover-arrow-height)) - var(--ct-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--ct-popover-arrow-width) * 0.5) var(--ct-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--ct-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--ct-popover-border-width);
  border-bottom-color: var(--ct-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--ct-popover-arrow-width);
  margin-left: calc(-0.5 * var(--ct-popover-arrow-width));
  content: "";
  border-bottom: var(--ct-popover-border-width) solid var(--ct-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--ct-popover-arrow-height)) - var(--ct-popover-border-width));
  width: var(--ct-popover-arrow-height);
  height: var(--ct-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--ct-popover-arrow-width) * 0.5) 0 calc(var(--ct-popover-arrow-width) * 0.5) var(--ct-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--ct-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--ct-popover-border-width);
  border-left-color: var(--ct-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--ct-popover-header-padding-y) var(--ct-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--ct-popover-header-font-size);
  color: var(--ct-popover-header-color);
  background-color: var(--ct-popover-header-bg);
  border-bottom: var(--ct-popover-border-width) solid var(--ct-popover-border-color);
  border-top-left-radius: var(--ct-popover-inner-border-radius);
  border-top-right-radius: var(--ct-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--ct-popover-body-padding-y) var(--ct-popover-body-padding-x);
  color: var(--ct-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")*/;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")*/;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--ct-spinner-width);
  height: var(--ct-spinner-height);
  vertical-align: var(--ct-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--ct-spinner-animation-speed) linear infinite var(--ct-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --ct-spinner-width: 2rem;
  --ct-spinner-height: 2rem;
  --ct-spinner-vertical-align: -0.25em;
  --ct-spinner-border-width: 0.25em;
  --ct-spinner-animation-speed: 0.75s;
  --ct-spinner-animation-name: spinner-border;
  border: var(--ct-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --ct-spinner-width: 1rem;
  --ct-spinner-height: 1rem;
  --ct-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --ct-spinner-width: 2rem;
  --ct-spinner-height: 2rem;
  --ct-spinner-vertical-align: -0.25em;
  --ct-spinner-animation-speed: 0.75s;
  --ct-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --ct-spinner-width: 1rem;
  --ct-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --ct-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --ct-offcanvas-zindex: 1045;
  --ct-offcanvas-width: 400px;
  --ct-offcanvas-height: 30vh;
  --ct-offcanvas-padding-x: 1rem;
  --ct-offcanvas-padding-y: 1rem;
  --ct-offcanvas-color: var(--ct-body-color);
  --ct-offcanvas-bg: var(--ct-secondary-bg);
  --ct-offcanvas-border-width: var(--ct-border-width);
  --ct-offcanvas-border-color: transparent;
  --ct-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(var(--ct-body-color-rgb), 0.15);
  --ct-offcanvas-transition: transform 0.3s ease-in-out;
  --ct-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--ct-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--ct-offcanvas-color);
    visibility: hidden;
    background-color: var(--ct-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--ct-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--ct-offcanvas-width);
    border-right: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--ct-offcanvas-width);
    border-left: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--ct-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--ct-offcanvas-height);
    max-height: 100%;
    border-top: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --ct-offcanvas-height: auto;
    --ct-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--ct-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--ct-offcanvas-color);
    visibility: hidden;
    background-color: var(--ct-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--ct-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--ct-offcanvas-width);
    border-right: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--ct-offcanvas-width);
    border-left: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--ct-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--ct-offcanvas-height);
    max-height: 100%;
    border-top: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --ct-offcanvas-height: auto;
    --ct-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--ct-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--ct-offcanvas-color);
    visibility: hidden;
    background-color: var(--ct-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--ct-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--ct-offcanvas-width);
    border-right: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--ct-offcanvas-width);
    border-left: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--ct-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--ct-offcanvas-height);
    max-height: 100%;
    border-top: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --ct-offcanvas-height: auto;
    --ct-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--ct-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--ct-offcanvas-color);
    visibility: hidden;
    background-color: var(--ct-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--ct-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--ct-offcanvas-width);
    border-right: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--ct-offcanvas-width);
    border-left: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--ct-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--ct-offcanvas-height);
    max-height: 100%;
    border-top: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --ct-offcanvas-height: auto;
    --ct-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--ct-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--ct-offcanvas-color);
    visibility: hidden;
    background-color: var(--ct-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--ct-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--ct-offcanvas-width);
    border-right: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--ct-offcanvas-width);
    border-left: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--ct-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--ct-offcanvas-height);
    max-height: 100%;
    border-top: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --ct-offcanvas-height: auto;
    --ct-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--ct-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--ct-offcanvas-color);
  visibility: hidden;
  background-color: var(--ct-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--ct-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--ct-offcanvas-width);
  border-right: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--ct-offcanvas-width);
  border-left: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--ct-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--ct-offcanvas-height);
  max-height: 100%;
  border-top: var(--ct-offcanvas-border-width) solid var(--ct-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: var(--ct-emphasis-color);
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  padding: var(--ct-offcanvas-padding-y) var(--ct-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--ct-offcanvas-padding-y) * 0.5) calc(var(--ct-offcanvas-padding-x) * 0.5);
  margin: calc(-0.5 * var(--ct-offcanvas-padding-y)) calc(-0.5 * var(--ct-offcanvas-padding-x)) calc(-0.5 * var(--ct-offcanvas-padding-y)) auto;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--ct-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--ct-offcanvas-padding-y) var(--ct-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--ct-primary-rgb), var(--ct-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--ct-secondary-rgb), var(--ct-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--ct-success-rgb), var(--ct-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #fff !important;
  background-color: RGBA(var(--ct-info-rgb), var(--ct-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #fff !important;
  background-color: RGBA(var(--ct-warning-rgb), var(--ct-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--ct-danger-rgb), var(--ct-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #313a46 !important;
  background-color: RGBA(var(--ct-light-rgb), var(--ct-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--ct-dark-rgb), var(--ct-bg-opacity, 1)) !important;
}

.text-bg-lwk-green-dark {
  color: #fff !important;
  background-color: RGBA(var(--ct-lwk-green-dark-rgb), var(--ct-bg-opacity, 1)) !important;
}

.text-bg-lwk-green-light {
  color: #fff !important;
  background-color: RGBA(var(--ct-lwk-green-light-rgb), var(--ct-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--ct-primary-rgb), var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--ct-primary-rgb), var(--ct-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(37, 121, 211, var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(37, 121, 211, var(--ct-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--ct-secondary-rgb), var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--ct-secondary-rgb), var(--ct-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(92, 99, 106, var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(92, 99, 106, var(--ct-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--ct-success-rgb), var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--ct-success-rgb), var(--ct-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(9, 176, 128, var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(9, 176, 128, var(--ct-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--ct-info-rgb), var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--ct-info-rgb), var(--ct-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(48, 149, 178, var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(48, 149, 178, var(--ct-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--ct-warning-rgb), var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--ct-warning-rgb), var(--ct-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(217, 166, 77, var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(217, 166, 77, var(--ct-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--ct-danger-rgb), var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--ct-danger-rgb), var(--ct-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(213, 78, 105, var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(213, 78, 105, var(--ct-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--ct-light-rgb), var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--ct-light-rgb), var(--ct-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(241, 244, 248, var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(241, 244, 248, var(--ct-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--ct-dark-rgb), var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--ct-dark-rgb), var(--ct-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(42, 49, 60, var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(42, 49, 60, var(--ct-link-underline-opacity, 1)) !important;
}

.link-lwk-green-dark {
  color: RGBA(var(--ct-lwk-green-dark-rgb), var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--ct-lwk-green-dark-rgb), var(--ct-link-underline-opacity, 1)) !important;
}
.link-lwk-green-dark:hover, .link-lwk-green-dark:focus {
  color: RGBA(4, 137, 61, var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(4, 137, 61, var(--ct-link-underline-opacity, 1)) !important;
}

.link-lwk-green-light {
  color: RGBA(var(--ct-lwk-green-light-rgb), var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--ct-lwk-green-light-rgb), var(--ct-link-underline-opacity, 1)) !important;
}
.link-lwk-green-light:hover, .link-lwk-green-light:focus {
  color: RGBA(8, 96, 42, var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(8, 96, 42, var(--ct-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--ct-emphasis-color-rgb), var(--ct-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--ct-emphasis-color-rgb), var(--ct-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--ct-emphasis-color-rgb), var(--ct-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--ct-emphasis-color-rgb), var(--ct-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--ct-focus-ring-x, 0) var(--ct-focus-ring-y, 0) var(--ct-focus-ring-blur, 0) var(--ct-focus-ring-width) var(--ct-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--ct-link-color-rgb), var(--ct-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--ct-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--ct-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --ct-aspect-ratio: 100%;
}

.ratio-4x3 {
  --ct-aspect-ratio: 75%;
}

.ratio-16x9 {
  --ct-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --ct-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 1;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--ct-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--ct-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--ct-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --ct-focus-ring-color: rgba(var(--ct-primary-rgb), var(--ct-focus-ring-opacity));
}

.focus-ring-secondary {
  --ct-focus-ring-color: rgba(var(--ct-secondary-rgb), var(--ct-focus-ring-opacity));
}

.focus-ring-success {
  --ct-focus-ring-color: rgba(var(--ct-success-rgb), var(--ct-focus-ring-opacity));
}

.focus-ring-info {
  --ct-focus-ring-color: rgba(var(--ct-info-rgb), var(--ct-focus-ring-opacity));
}

.focus-ring-warning {
  --ct-focus-ring-color: rgba(var(--ct-warning-rgb), var(--ct-focus-ring-opacity));
}

.focus-ring-danger {
  --ct-focus-ring-color: rgba(var(--ct-danger-rgb), var(--ct-focus-ring-opacity));
}

.focus-ring-light {
  --ct-focus-ring-color: rgba(var(--ct-light-rgb), var(--ct-focus-ring-opacity));
}

.focus-ring-dark {
  --ct-focus-ring-color: rgba(var(--ct-dark-rgb), var(--ct-focus-ring-opacity));
}

.focus-ring-lwk-green-dark {
  --ct-focus-ring-color: rgba(var(--ct-lwk-green-dark-rgb), var(--ct-focus-ring-opacity));
}

.focus-ring-lwk-green-light {
  --ct-focus-ring-color: rgba(var(--ct-lwk-green-light-rgb), var(--ct-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--ct-border-width) var(--ct-border-style) var(--ct-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--ct-border-width) var(--ct-border-style) var(--ct-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--ct-border-width) var(--ct-border-style) var(--ct-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--ct-border-width) var(--ct-border-style) var(--ct-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--ct-border-width) var(--ct-border-style) var(--ct-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --ct-border-opacity: 1;
  border-color: rgba(var(--ct-primary-rgb), var(--ct-border-opacity)) !important;
}

.border-secondary {
  --ct-border-opacity: 1;
  border-color: rgba(var(--ct-secondary-rgb), var(--ct-border-opacity)) !important;
}

.border-success {
  --ct-border-opacity: 1;
  border-color: rgba(var(--ct-success-rgb), var(--ct-border-opacity)) !important;
}

.border-info {
  --ct-border-opacity: 1;
  border-color: rgba(var(--ct-info-rgb), var(--ct-border-opacity)) !important;
}

.border-warning {
  --ct-border-opacity: 1;
  border-color: rgba(var(--ct-warning-rgb), var(--ct-border-opacity)) !important;
}

.border-danger {
  --ct-border-opacity: 1;
  border-color: rgba(var(--ct-danger-rgb), var(--ct-border-opacity)) !important;
}

.border-light {
  --ct-border-opacity: 1;
  border-color: rgba(var(--ct-light-rgb), var(--ct-border-opacity)) !important;
}

.border-dark {
  --ct-border-opacity: 1;
  border-color: rgba(var(--ct-dark-rgb), var(--ct-border-opacity)) !important;
}

.border-lwk-green-dark {
  --ct-border-opacity: 1;
  border-color: rgba(var(--ct-lwk-green-dark-rgb), var(--ct-border-opacity)) !important;
}

.border-lwk-green-light {
  --ct-border-opacity: 1;
  border-color: rgba(var(--ct-lwk-green-light-rgb), var(--ct-border-opacity)) !important;
}

.border-black {
  --ct-border-opacity: 1;
  border-color: rgba(var(--ct-black-rgb), var(--ct-border-opacity)) !important;
}

.border-white {
  --ct-border-opacity: 1;
  border-color: rgba(var(--ct-white-rgb), var(--ct-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--ct-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--ct-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--ct-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--ct-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--ct-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--ct-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--ct-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--ct-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --ct-border-opacity: 0.1;
}

.border-opacity-25 {
  --ct-border-opacity: 0.25;
}

.border-opacity-50 {
  --ct-border-opacity: 0.5;
}

.border-opacity-75 {
  --ct-border-opacity: 0.75;
}

.border-opacity-100 {
  --ct-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.375rem !important;
}

.m-2 {
  margin: 0.75rem !important;
}

.m-3 {
  margin: 1.5rem !important;
}

.m-4 {
  margin: 2.25rem !important;
}

.m-5 {
  margin: 4.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.375rem !important;
  margin-left: 0.375rem !important;
}

.mx-2 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}

.mx-3 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-4 {
  margin-right: 2.25rem !important;
  margin-left: 2.25rem !important;
}

.mx-5 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.375rem !important;
  margin-bottom: 0.375rem !important;
}

.my-2 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.my-3 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-4 {
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important;
}

.my-5 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.375rem !important;
}

.mt-2 {
  margin-top: 0.75rem !important;
}

.mt-3 {
  margin-top: 1.5rem !important;
}

.mt-4 {
  margin-top: 2.25rem !important;
}

.mt-5 {
  margin-top: 4.5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.375rem !important;
}

.me-2 {
  margin-right: 0.75rem !important;
}

.me-3 {
  margin-right: 1.5rem !important;
}

.me-4 {
  margin-right: 2.25rem !important;
}

.me-5 {
  margin-right: 4.5rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.375rem !important;
}

.mb-2 {
  margin-bottom: 0.75rem !important;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 2.25rem !important;
}

.mb-5 {
  margin-bottom: 4.5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.375rem !important;
}

.ms-2 {
  margin-left: 0.75rem !important;
}

.ms-3 {
  margin-left: 1.5rem !important;
}

.ms-4 {
  margin-left: 2.25rem !important;
}

.ms-5 {
  margin-left: 4.5rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.375rem !important;
}

.m-n2 {
  margin: -0.75rem !important;
}

.m-n3 {
  margin: -1.5rem !important;
}

.m-n4 {
  margin: -2.25rem !important;
}

.m-n5 {
  margin: -4.5rem !important;
}

.mx-n1 {
  margin-right: -0.375rem !important;
  margin-left: -0.375rem !important;
}

.mx-n2 {
  margin-right: -0.75rem !important;
  margin-left: -0.75rem !important;
}

.mx-n3 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n4 {
  margin-right: -2.25rem !important;
  margin-left: -2.25rem !important;
}

.mx-n5 {
  margin-right: -4.5rem !important;
  margin-left: -4.5rem !important;
}

.my-n1 {
  margin-top: -0.375rem !important;
  margin-bottom: -0.375rem !important;
}

.my-n2 {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}

.my-n3 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n4 {
  margin-top: -2.25rem !important;
  margin-bottom: -2.25rem !important;
}

.my-n5 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}

.mt-n1 {
  margin-top: -0.375rem !important;
}

.mt-n2 {
  margin-top: -0.75rem !important;
}

.mt-n3 {
  margin-top: -1.5rem !important;
}

.mt-n4 {
  margin-top: -2.25rem !important;
}

.mt-n5 {
  margin-top: -4.5rem !important;
}

.me-n1 {
  margin-right: -0.375rem !important;
}

.me-n2 {
  margin-right: -0.75rem !important;
}

.me-n3 {
  margin-right: -1.5rem !important;
}

.me-n4 {
  margin-right: -2.25rem !important;
}

.me-n5 {
  margin-right: -4.5rem !important;
}

.mb-n1 {
  margin-bottom: -0.375rem !important;
}

.mb-n2 {
  margin-bottom: -0.75rem !important;
}

.mb-n3 {
  margin-bottom: -1.5rem !important;
}

.mb-n4 {
  margin-bottom: -2.25rem !important;
}

.mb-n5 {
  margin-bottom: -4.5rem !important;
}

.ms-n1 {
  margin-left: -0.375rem !important;
}

.ms-n2 {
  margin-left: -0.75rem !important;
}

.ms-n3 {
  margin-left: -1.5rem !important;
}

.ms-n4 {
  margin-left: -2.25rem !important;
}

.ms-n5 {
  margin-left: -4.5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.375rem !important;
}

.p-2 {
  padding: 0.75rem !important;
}

.p-3 {
  padding: 1.5rem !important;
}

.p-4 {
  padding: 2.25rem !important;
}

.p-5 {
  padding: 4.5rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.375rem !important;
  padding-left: 0.375rem !important;
}

.px-2 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}

.px-3 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-4 {
  padding-right: 2.25rem !important;
  padding-left: 2.25rem !important;
}

.px-5 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important;
}

.py-2 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.py-3 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-4 {
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
}

.py-5 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.375rem !important;
}

.pt-2 {
  padding-top: 0.75rem !important;
}

.pt-3 {
  padding-top: 1.5rem !important;
}

.pt-4 {
  padding-top: 2.25rem !important;
}

.pt-5 {
  padding-top: 4.5rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.375rem !important;
}

.pe-2 {
  padding-right: 0.75rem !important;
}

.pe-3 {
  padding-right: 1.5rem !important;
}

.pe-4 {
  padding-right: 2.25rem !important;
}

.pe-5 {
  padding-right: 4.5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.375rem !important;
}

.pb-2 {
  padding-bottom: 0.75rem !important;
}

.pb-3 {
  padding-bottom: 1.5rem !important;
}

.pb-4 {
  padding-bottom: 2.25rem !important;
}

.pb-5 {
  padding-bottom: 4.5rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.375rem !important;
}

.ps-2 {
  padding-left: 0.75rem !important;
}

.ps-3 {
  padding-left: 1.5rem !important;
}

.ps-4 {
  padding-left: 2.25rem !important;
}

.ps-5 {
  padding-left: 4.5rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.375rem !important;
}

.gap-2 {
  gap: 0.75rem !important;
}

.gap-3 {
  gap: 1.5rem !important;
}

.gap-4 {
  gap: 2.25rem !important;
}

.gap-5 {
  gap: 4.5rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.375rem !important;
}

.row-gap-2 {
  row-gap: 0.75rem !important;
}

.row-gap-3 {
  row-gap: 1.5rem !important;
}

.row-gap-4 {
  row-gap: 2.25rem !important;
}

.row-gap-5 {
  row-gap: 4.5rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.375rem !important;
}

.column-gap-2 {
  column-gap: 0.75rem !important;
}

.column-gap-3 {
  column-gap: 1.5rem !important;
}

.column-gap-4 {
  column-gap: 2.25rem !important;
}

.column-gap-5 {
  column-gap: 4.5rem !important;
}

.font-monospace {
  font-family: var(--ct-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.35rem + 1.2vw) !important;
}

.fs-2 {
  font-size: calc(1.3122rem + 0.7464vw) !important;
}

.fs-3 {
  font-size: calc(1.2753rem + 0.3036vw) !important;
}

.fs-4 {
  font-size: 1.125rem !important;
}

.fs-5 {
  font-size: 0.936rem !important;
}

.fs-6 {
  font-size: 0.765rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --ct-text-opacity: 1;
  color: rgba(var(--ct-primary-rgb), var(--ct-text-opacity)) !important;
}

.text-secondary {
  --ct-text-opacity: 1;
  color: rgba(var(--ct-secondary-rgb), var(--ct-text-opacity)) !important;
}

.text-success {
  --ct-text-opacity: 1;
  color: rgba(var(--ct-success-rgb), var(--ct-text-opacity)) !important;
}

.text-info {
  --ct-text-opacity: 1;
  color: rgba(var(--ct-info-rgb), var(--ct-text-opacity)) !important;
}

.text-warning {
  --ct-text-opacity: 1;
  color: rgba(var(--ct-warning-rgb), var(--ct-text-opacity)) !important;
}

.text-danger {
  --ct-text-opacity: 1;
  color: rgba(var(--ct-danger-rgb), var(--ct-text-opacity)) !important;
}

.text-light {
  --ct-text-opacity: 1;
  color: rgba(var(--ct-light-rgb), var(--ct-text-opacity)) !important;
}

.text-dark {
  --ct-text-opacity: 1;
  color: rgba(var(--ct-dark-rgb), var(--ct-text-opacity)) !important;
}

.text-lwk-green-dark {
  --ct-text-opacity: 1;
  color: rgba(var(--ct-lwk-green-dark-rgb), var(--ct-text-opacity)) !important;
}

.text-lwk-green-light {
  --ct-text-opacity: 1;
  color: rgba(var(--ct-lwk-green-light-rgb), var(--ct-text-opacity)) !important;
}

.text-black {
  --ct-text-opacity: 1;
  color: rgba(var(--ct-black-rgb), var(--ct-text-opacity)) !important;
}

.text-white {
  --ct-text-opacity: 1;
  color: rgba(var(--ct-white-rgb), var(--ct-text-opacity)) !important;
}

.text-body {
  --ct-text-opacity: 1;
  color: rgba(var(--ct-body-color-rgb), var(--ct-text-opacity)) !important;
}

.text-muted {
  --ct-text-opacity: 1;
  color: var(--ct-secondary-color) !important;
}

.text-black-50 {
  --ct-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --ct-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --ct-text-opacity: 1;
  color: var(--ct-secondary-color) !important;
}

.text-body-tertiary {
  --ct-text-opacity: 1;
  color: var(--ct-tertiary-color) !important;
}

.text-body-emphasis {
  --ct-text-opacity: 1;
  color: var(--ct-emphasis-color) !important;
}

.text-reset {
  --ct-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --ct-text-opacity: 0.25;
}

.text-opacity-50 {
  --ct-text-opacity: 0.5;
}

.text-opacity-75 {
  --ct-text-opacity: 0.75;
}

.text-opacity-100 {
  --ct-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--ct-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--ct-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--ct-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--ct-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--ct-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--ct-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--ct-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--ct-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --ct-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --ct-link-opacity: 0.1;
}

.link-opacity-25 {
  --ct-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --ct-link-opacity: 0.25;
}

.link-opacity-50 {
  --ct-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --ct-link-opacity: 0.5;
}

.link-opacity-75 {
  --ct-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --ct-link-opacity: 0.75;
}

.link-opacity-100 {
  --ct-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --ct-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --ct-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--ct-primary-rgb), var(--ct-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --ct-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--ct-secondary-rgb), var(--ct-link-underline-opacity)) !important;
}

.link-underline-success {
  --ct-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--ct-success-rgb), var(--ct-link-underline-opacity)) !important;
}

.link-underline-info {
  --ct-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--ct-info-rgb), var(--ct-link-underline-opacity)) !important;
}

.link-underline-warning {
  --ct-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--ct-warning-rgb), var(--ct-link-underline-opacity)) !important;
}

.link-underline-danger {
  --ct-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--ct-danger-rgb), var(--ct-link-underline-opacity)) !important;
}

.link-underline-light {
  --ct-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--ct-light-rgb), var(--ct-link-underline-opacity)) !important;
}

.link-underline-dark {
  --ct-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--ct-dark-rgb), var(--ct-link-underline-opacity)) !important;
}

.link-underline-lwk-green-dark {
  --ct-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--ct-lwk-green-dark-rgb), var(--ct-link-underline-opacity)) !important;
}

.link-underline-lwk-green-light {
  --ct-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--ct-lwk-green-light-rgb), var(--ct-link-underline-opacity)) !important;
}

.link-underline {
  --ct-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--ct-link-color-rgb), var(--ct-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --ct-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --ct-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --ct-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --ct-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --ct-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --ct-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --ct-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --ct-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --ct-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --ct-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --ct-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --ct-link-underline-opacity: 1;
}

.bg-primary {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-primary-rgb), var(--ct-bg-opacity)) !important;
}

.bg-secondary {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-secondary-rgb), var(--ct-bg-opacity)) !important;
}

.bg-success {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-success-rgb), var(--ct-bg-opacity)) !important;
}

.bg-info {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-info-rgb), var(--ct-bg-opacity)) !important;
}

.bg-warning {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-warning-rgb), var(--ct-bg-opacity)) !important;
}

.bg-danger {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-danger-rgb), var(--ct-bg-opacity)) !important;
}

.bg-light {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-light-rgb), var(--ct-bg-opacity)) !important;
}

.bg-dark {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-dark-rgb), var(--ct-bg-opacity)) !important;
}

.bg-lwk-green-dark {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-lwk-green-dark-rgb), var(--ct-bg-opacity)) !important;
}

.bg-lwk-green-light {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-lwk-green-light-rgb), var(--ct-bg-opacity)) !important;
}

.bg-black {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-black-rgb), var(--ct-bg-opacity)) !important;
}

.bg-white {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-white-rgb), var(--ct-bg-opacity)) !important;
}

.bg-body {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-body-bg-rgb), var(--ct-bg-opacity)) !important;
}

.bg-transparent {
  --ct-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-secondary-bg-rgb), var(--ct-bg-opacity)) !important;
}

.bg-body-tertiary {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-tertiary-bg-rgb), var(--ct-bg-opacity)) !important;
}

.bg-opacity-10 {
  --ct-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --ct-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --ct-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --ct-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --ct-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--ct-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--ct-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--ct-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--ct-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--ct-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--ct-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--ct-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--ct-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--ct-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--ct-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--ct-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--ct-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--ct-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--ct-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--ct-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--ct-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--ct-border-radius) !important;
  border-top-right-radius: var(--ct-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--ct-border-radius-sm) !important;
  border-top-right-radius: var(--ct-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--ct-border-radius) !important;
  border-top-right-radius: var(--ct-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--ct-border-radius-lg) !important;
  border-top-right-radius: var(--ct-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--ct-border-radius-xl) !important;
  border-top-right-radius: var(--ct-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--ct-border-radius-xxl) !important;
  border-top-right-radius: var(--ct-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--ct-border-radius-pill) !important;
  border-top-right-radius: var(--ct-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--ct-border-radius) !important;
  border-bottom-right-radius: var(--ct-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--ct-border-radius-sm) !important;
  border-bottom-right-radius: var(--ct-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--ct-border-radius) !important;
  border-bottom-right-radius: var(--ct-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--ct-border-radius-lg) !important;
  border-bottom-right-radius: var(--ct-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--ct-border-radius-xl) !important;
  border-bottom-right-radius: var(--ct-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--ct-border-radius-xxl) !important;
  border-bottom-right-radius: var(--ct-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--ct-border-radius-pill) !important;
  border-bottom-right-radius: var(--ct-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--ct-border-radius) !important;
  border-bottom-left-radius: var(--ct-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--ct-border-radius-sm) !important;
  border-bottom-left-radius: var(--ct-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--ct-border-radius) !important;
  border-bottom-left-radius: var(--ct-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--ct-border-radius-lg) !important;
  border-bottom-left-radius: var(--ct-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--ct-border-radius-xl) !important;
  border-bottom-left-radius: var(--ct-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--ct-border-radius-xxl) !important;
  border-bottom-left-radius: var(--ct-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--ct-border-radius-pill) !important;
  border-bottom-left-radius: var(--ct-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--ct-border-radius) !important;
  border-top-left-radius: var(--ct-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--ct-border-radius-sm) !important;
  border-top-left-radius: var(--ct-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--ct-border-radius) !important;
  border-top-left-radius: var(--ct-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--ct-border-radius-lg) !important;
  border-top-left-radius: var(--ct-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--ct-border-radius-xl) !important;
  border-top-left-radius: var(--ct-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--ct-border-radius-xxl) !important;
  border-top-left-radius: var(--ct-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--ct-border-radius-pill) !important;
  border-top-left-radius: var(--ct-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

.font-10 {
  font-size: 10px !important;
}

.font-11 {
  font-size: 11px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-17 {
  font-size: 17px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-19 {
  font-size: 19px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-21 {
  font-size: 21px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-23 {
  font-size: 23px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-36 {
  font-size: 36px !important;
}

.font-48 {
  font-size: 48px !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.375rem !important;
  }
  .m-sm-2 {
    margin: 0.75rem !important;
  }
  .m-sm-3 {
    margin: 1.5rem !important;
  }
  .m-sm-4 {
    margin: 2.25rem !important;
  }
  .m-sm-5 {
    margin: 4.5rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-sm-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-4 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-sm-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-sm-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-sm-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-sm-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.375rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.75rem !important;
  }
  .mt-sm-3 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-4 {
    margin-top: 2.25rem !important;
  }
  .mt-sm-5 {
    margin-top: 4.5rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.375rem !important;
  }
  .me-sm-2 {
    margin-right: 0.75rem !important;
  }
  .me-sm-3 {
    margin-right: 1.5rem !important;
  }
  .me-sm-4 {
    margin-right: 2.25rem !important;
  }
  .me-sm-5 {
    margin-right: 4.5rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.375rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.75rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 2.25rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 4.5rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.375rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.75rem !important;
  }
  .ms-sm-3 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-4 {
    margin-left: 2.25rem !important;
  }
  .ms-sm-5 {
    margin-left: 4.5rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.375rem !important;
  }
  .m-sm-n2 {
    margin: -0.75rem !important;
  }
  .m-sm-n3 {
    margin: -1.5rem !important;
  }
  .m-sm-n4 {
    margin: -2.25rem !important;
  }
  .m-sm-n5 {
    margin: -4.5rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n4 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }
  .mx-sm-n5 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-sm-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }
  .my-sm-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.375rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.75rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n4 {
    margin-top: -2.25rem !important;
  }
  .mt-sm-n5 {
    margin-top: -4.5rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.375rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.75rem !important;
  }
  .me-sm-n3 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n4 {
    margin-right: -2.25rem !important;
  }
  .me-sm-n5 {
    margin-right: -4.5rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.375rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.75rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -2.25rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -4.5rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.375rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.75rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n4 {
    margin-left: -2.25rem !important;
  }
  .ms-sm-n5 {
    margin-left: -4.5rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.375rem !important;
  }
  .p-sm-2 {
    padding: 0.75rem !important;
  }
  .p-sm-3 {
    padding: 1.5rem !important;
  }
  .p-sm-4 {
    padding: 2.25rem !important;
  }
  .p-sm-5 {
    padding: 4.5rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-sm-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-sm-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-4 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-sm-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-sm-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-sm-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-sm-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.375rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.75rem !important;
  }
  .pt-sm-3 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-4 {
    padding-top: 2.25rem !important;
  }
  .pt-sm-5 {
    padding-top: 4.5rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.375rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.75rem !important;
  }
  .pe-sm-3 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-4 {
    padding-right: 2.25rem !important;
  }
  .pe-sm-5 {
    padding-right: 4.5rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.375rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.75rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 2.25rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 4.5rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.375rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.75rem !important;
  }
  .ps-sm-3 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-4 {
    padding-left: 2.25rem !important;
  }
  .ps-sm-5 {
    padding-left: 4.5rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.375rem !important;
  }
  .gap-sm-2 {
    gap: 0.75rem !important;
  }
  .gap-sm-3 {
    gap: 1.5rem !important;
  }
  .gap-sm-4 {
    gap: 2.25rem !important;
  }
  .gap-sm-5 {
    gap: 4.5rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.375rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.75rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 2.25rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 4.5rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.375rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.75rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 2.25rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 4.5rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.375rem !important;
  }
  .m-md-2 {
    margin: 0.75rem !important;
  }
  .m-md-3 {
    margin: 1.5rem !important;
  }
  .m-md-4 {
    margin: 2.25rem !important;
  }
  .m-md-5 {
    margin: 4.5rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-md-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-md-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-4 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-md-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-md-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-md-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-md-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.375rem !important;
  }
  .mt-md-2 {
    margin-top: 0.75rem !important;
  }
  .mt-md-3 {
    margin-top: 1.5rem !important;
  }
  .mt-md-4 {
    margin-top: 2.25rem !important;
  }
  .mt-md-5 {
    margin-top: 4.5rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.375rem !important;
  }
  .me-md-2 {
    margin-right: 0.75rem !important;
  }
  .me-md-3 {
    margin-right: 1.5rem !important;
  }
  .me-md-4 {
    margin-right: 2.25rem !important;
  }
  .me-md-5 {
    margin-right: 4.5rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.375rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.75rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-4 {
    margin-bottom: 2.25rem !important;
  }
  .mb-md-5 {
    margin-bottom: 4.5rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.375rem !important;
  }
  .ms-md-2 {
    margin-left: 0.75rem !important;
  }
  .ms-md-3 {
    margin-left: 1.5rem !important;
  }
  .ms-md-4 {
    margin-left: 2.25rem !important;
  }
  .ms-md-5 {
    margin-left: 4.5rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.375rem !important;
  }
  .m-md-n2 {
    margin: -0.75rem !important;
  }
  .m-md-n3 {
    margin: -1.5rem !important;
  }
  .m-md-n4 {
    margin: -2.25rem !important;
  }
  .m-md-n5 {
    margin: -4.5rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-md-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n4 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }
  .mx-md-n5 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .my-md-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-md-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-md-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }
  .my-md-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.375rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.75rem !important;
  }
  .mt-md-n3 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n4 {
    margin-top: -2.25rem !important;
  }
  .mt-md-n5 {
    margin-top: -4.5rem !important;
  }
  .me-md-n1 {
    margin-right: -0.375rem !important;
  }
  .me-md-n2 {
    margin-right: -0.75rem !important;
  }
  .me-md-n3 {
    margin-right: -1.5rem !important;
  }
  .me-md-n4 {
    margin-right: -2.25rem !important;
  }
  .me-md-n5 {
    margin-right: -4.5rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.375rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.75rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -2.25rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -4.5rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.375rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.75rem !important;
  }
  .ms-md-n3 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n4 {
    margin-left: -2.25rem !important;
  }
  .ms-md-n5 {
    margin-left: -4.5rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.375rem !important;
  }
  .p-md-2 {
    padding: 0.75rem !important;
  }
  .p-md-3 {
    padding: 1.5rem !important;
  }
  .p-md-4 {
    padding: 2.25rem !important;
  }
  .p-md-5 {
    padding: 4.5rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-md-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-md-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-4 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-md-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-md-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-md-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-md-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.375rem !important;
  }
  .pt-md-2 {
    padding-top: 0.75rem !important;
  }
  .pt-md-3 {
    padding-top: 1.5rem !important;
  }
  .pt-md-4 {
    padding-top: 2.25rem !important;
  }
  .pt-md-5 {
    padding-top: 4.5rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.375rem !important;
  }
  .pe-md-2 {
    padding-right: 0.75rem !important;
  }
  .pe-md-3 {
    padding-right: 1.5rem !important;
  }
  .pe-md-4 {
    padding-right: 2.25rem !important;
  }
  .pe-md-5 {
    padding-right: 4.5rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.375rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.75rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-4 {
    padding-bottom: 2.25rem !important;
  }
  .pb-md-5 {
    padding-bottom: 4.5rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.375rem !important;
  }
  .ps-md-2 {
    padding-left: 0.75rem !important;
  }
  .ps-md-3 {
    padding-left: 1.5rem !important;
  }
  .ps-md-4 {
    padding-left: 2.25rem !important;
  }
  .ps-md-5 {
    padding-left: 4.5rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.375rem !important;
  }
  .gap-md-2 {
    gap: 0.75rem !important;
  }
  .gap-md-3 {
    gap: 1.5rem !important;
  }
  .gap-md-4 {
    gap: 2.25rem !important;
  }
  .gap-md-5 {
    gap: 4.5rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.375rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.75rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-4 {
    row-gap: 2.25rem !important;
  }
  .row-gap-md-5 {
    row-gap: 4.5rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.375rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.75rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-4 {
    column-gap: 2.25rem !important;
  }
  .column-gap-md-5 {
    column-gap: 4.5rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.375rem !important;
  }
  .m-lg-2 {
    margin: 0.75rem !important;
  }
  .m-lg-3 {
    margin: 1.5rem !important;
  }
  .m-lg-4 {
    margin: 2.25rem !important;
  }
  .m-lg-5 {
    margin: 4.5rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-lg-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-4 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-lg-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-lg-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-lg-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-lg-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.375rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.75rem !important;
  }
  .mt-lg-3 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-4 {
    margin-top: 2.25rem !important;
  }
  .mt-lg-5 {
    margin-top: 4.5rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.375rem !important;
  }
  .me-lg-2 {
    margin-right: 0.75rem !important;
  }
  .me-lg-3 {
    margin-right: 1.5rem !important;
  }
  .me-lg-4 {
    margin-right: 2.25rem !important;
  }
  .me-lg-5 {
    margin-right: 4.5rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.375rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.75rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 2.25rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 4.5rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.375rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.75rem !important;
  }
  .ms-lg-3 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-4 {
    margin-left: 2.25rem !important;
  }
  .ms-lg-5 {
    margin-left: 4.5rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.375rem !important;
  }
  .m-lg-n2 {
    margin: -0.75rem !important;
  }
  .m-lg-n3 {
    margin: -1.5rem !important;
  }
  .m-lg-n4 {
    margin: -2.25rem !important;
  }
  .m-lg-n5 {
    margin: -4.5rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n4 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }
  .mx-lg-n5 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-lg-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }
  .my-lg-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.375rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.75rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n4 {
    margin-top: -2.25rem !important;
  }
  .mt-lg-n5 {
    margin-top: -4.5rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.375rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.75rem !important;
  }
  .me-lg-n3 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n4 {
    margin-right: -2.25rem !important;
  }
  .me-lg-n5 {
    margin-right: -4.5rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.375rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.75rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -2.25rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -4.5rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.375rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.75rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n4 {
    margin-left: -2.25rem !important;
  }
  .ms-lg-n5 {
    margin-left: -4.5rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.375rem !important;
  }
  .p-lg-2 {
    padding: 0.75rem !important;
  }
  .p-lg-3 {
    padding: 1.5rem !important;
  }
  .p-lg-4 {
    padding: 2.25rem !important;
  }
  .p-lg-5 {
    padding: 4.5rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-lg-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-lg-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-4 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-lg-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-lg-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-lg-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-lg-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.375rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.75rem !important;
  }
  .pt-lg-3 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-4 {
    padding-top: 2.25rem !important;
  }
  .pt-lg-5 {
    padding-top: 4.5rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.375rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.75rem !important;
  }
  .pe-lg-3 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-4 {
    padding-right: 2.25rem !important;
  }
  .pe-lg-5 {
    padding-right: 4.5rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.375rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.75rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 2.25rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 4.5rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.375rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.75rem !important;
  }
  .ps-lg-3 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-4 {
    padding-left: 2.25rem !important;
  }
  .ps-lg-5 {
    padding-left: 4.5rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.375rem !important;
  }
  .gap-lg-2 {
    gap: 0.75rem !important;
  }
  .gap-lg-3 {
    gap: 1.5rem !important;
  }
  .gap-lg-4 {
    gap: 2.25rem !important;
  }
  .gap-lg-5 {
    gap: 4.5rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.375rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.75rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 2.25rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 4.5rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.375rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.75rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 2.25rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 4.5rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.375rem !important;
  }
  .m-xl-2 {
    margin: 0.75rem !important;
  }
  .m-xl-3 {
    margin: 1.5rem !important;
  }
  .m-xl-4 {
    margin: 2.25rem !important;
  }
  .m-xl-5 {
    margin: 4.5rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-xl-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-4 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-xl-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-xl-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-xl-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-xl-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.375rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.75rem !important;
  }
  .mt-xl-3 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-4 {
    margin-top: 2.25rem !important;
  }
  .mt-xl-5 {
    margin-top: 4.5rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.375rem !important;
  }
  .me-xl-2 {
    margin-right: 0.75rem !important;
  }
  .me-xl-3 {
    margin-right: 1.5rem !important;
  }
  .me-xl-4 {
    margin-right: 2.25rem !important;
  }
  .me-xl-5 {
    margin-right: 4.5rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.375rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.75rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 2.25rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.375rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.75rem !important;
  }
  .ms-xl-3 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-4 {
    margin-left: 2.25rem !important;
  }
  .ms-xl-5 {
    margin-left: 4.5rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.375rem !important;
  }
  .m-xl-n2 {
    margin: -0.75rem !important;
  }
  .m-xl-n3 {
    margin: -1.5rem !important;
  }
  .m-xl-n4 {
    margin: -2.25rem !important;
  }
  .m-xl-n5 {
    margin: -4.5rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n4 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }
  .mx-xl-n5 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-xl-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }
  .my-xl-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.375rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.75rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n4 {
    margin-top: -2.25rem !important;
  }
  .mt-xl-n5 {
    margin-top: -4.5rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.375rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.75rem !important;
  }
  .me-xl-n3 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n4 {
    margin-right: -2.25rem !important;
  }
  .me-xl-n5 {
    margin-right: -4.5rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.375rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.75rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -2.25rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -4.5rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.375rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.75rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n4 {
    margin-left: -2.25rem !important;
  }
  .ms-xl-n5 {
    margin-left: -4.5rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.375rem !important;
  }
  .p-xl-2 {
    padding: 0.75rem !important;
  }
  .p-xl-3 {
    padding: 1.5rem !important;
  }
  .p-xl-4 {
    padding: 2.25rem !important;
  }
  .p-xl-5 {
    padding: 4.5rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-xl-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-xl-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-4 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-xl-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-xl-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-xl-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-xl-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.375rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.75rem !important;
  }
  .pt-xl-3 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-4 {
    padding-top: 2.25rem !important;
  }
  .pt-xl-5 {
    padding-top: 4.5rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.375rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.75rem !important;
  }
  .pe-xl-3 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-4 {
    padding-right: 2.25rem !important;
  }
  .pe-xl-5 {
    padding-right: 4.5rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.375rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.75rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 2.25rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 4.5rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.375rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.75rem !important;
  }
  .ps-xl-3 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-4 {
    padding-left: 2.25rem !important;
  }
  .ps-xl-5 {
    padding-left: 4.5rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.375rem !important;
  }
  .gap-xl-2 {
    gap: 0.75rem !important;
  }
  .gap-xl-3 {
    gap: 1.5rem !important;
  }
  .gap-xl-4 {
    gap: 2.25rem !important;
  }
  .gap-xl-5 {
    gap: 4.5rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.375rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.75rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 2.25rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 4.5rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.375rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.75rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 2.25rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 4.5rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.375rem !important;
  }
  .m-xxl-2 {
    margin: 0.75rem !important;
  }
  .m-xxl-3 {
    margin: 1.5rem !important;
  }
  .m-xxl-4 {
    margin: 2.25rem !important;
  }
  .m-xxl-5 {
    margin: 4.5rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-4 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-xxl-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-xxl-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-xxl-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.375rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.75rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-4 {
    margin-top: 2.25rem !important;
  }
  .mt-xxl-5 {
    margin-top: 4.5rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.375rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.75rem !important;
  }
  .me-xxl-3 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-4 {
    margin-right: 2.25rem !important;
  }
  .me-xxl-5 {
    margin-right: 4.5rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.375rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.75rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 2.25rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.375rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.75rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-4 {
    margin-left: 2.25rem !important;
  }
  .ms-xxl-5 {
    margin-left: 4.5rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.375rem !important;
  }
  .m-xxl-n2 {
    margin: -0.75rem !important;
  }
  .m-xxl-n3 {
    margin: -1.5rem !important;
  }
  .m-xxl-n4 {
    margin: -2.25rem !important;
  }
  .m-xxl-n5 {
    margin: -4.5rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-xxl-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }
  .my-xxl-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.375rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.75rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -2.25rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -4.5rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.375rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.75rem !important;
  }
  .me-xxl-n3 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n4 {
    margin-right: -2.25rem !important;
  }
  .me-xxl-n5 {
    margin-right: -4.5rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.375rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.75rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -2.25rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -4.5rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.375rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.75rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -2.25rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -4.5rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.375rem !important;
  }
  .p-xxl-2 {
    padding: 0.75rem !important;
  }
  .p-xxl-3 {
    padding: 1.5rem !important;
  }
  .p-xxl-4 {
    padding: 2.25rem !important;
  }
  .p-xxl-5 {
    padding: 4.5rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-xxl-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-4 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-xxl-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-xxl-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-xxl-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.375rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.75rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-4 {
    padding-top: 2.25rem !important;
  }
  .pt-xxl-5 {
    padding-top: 4.5rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.375rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.75rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-4 {
    padding-right: 2.25rem !important;
  }
  .pe-xxl-5 {
    padding-right: 4.5rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.375rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.75rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 2.25rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 4.5rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.375rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.75rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-4 {
    padding-left: 2.25rem !important;
  }
  .ps-xxl-5 {
    padding-left: 4.5rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.375rem !important;
  }
  .gap-xxl-2 {
    gap: 0.75rem !important;
  }
  .gap-xxl-3 {
    gap: 1.5rem !important;
  }
  .gap-xxl-4 {
    gap: 2.25rem !important;
  }
  .gap-xxl-5 {
    gap: 4.5rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.375rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.75rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 2.25rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 4.5rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.375rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.75rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 2.25rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 4.5rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.25rem !important;
  }
  .fs-2 {
    font-size: 1.872rem !important;
  }
  .fs-3 {
    font-size: 1.503rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.logo-lg img {
  height: var(--ct-logo-lg-height);
}

.logo-sm img {
  height: var(--ct-logo-sm-height);
}

.logo {
  display: block;
  top: 0;
  position: sticky;
  text-align: center;
  line-height: var(--ct-topbar-height);
  padding: 0 calc(var(--ct-leftbar-width-sm) * 0.5 - var(--ct-logo-sm-height) * 0.7);
  transition: all 0.25s ease-in-out;
}
.logo span.logo-lg {
  display: block;
}
.logo span.logo-sm {
  display: none;
}
.logo.logo-light {
  display: none;
}
.logo.logo-dark {
  display: block;
}

html[data-menu-color=brand] .logo.logo-light,
html[data-menu-color=dark] .logo.logo-light,
html[data-bs-theme=dark] .logo.logo-light {
  display: block;
}
html[data-menu-color=brand] .logo.logo-dark,
html[data-menu-color=dark] .logo.logo-dark,
html[data-bs-theme=dark] .logo.logo-dark {
  display: none;
}

.help-box {
  border-radius: 5px;
  padding: 20px;
  margin: 65px 25px 25px;
  position: relative;
  background-color: var(--ct-help-box-bg);
}
.help-box .close-btn {
  right: 10px;
  top: 10px;
  position: absolute;
}

.wrapper {
  height: 100%;
  width: 100%;
}

.content-page {
  position: relative;
  margin-left: var(--ct-leftbar-width);
  min-height: calc(100vh - var(--ct-topbar-height) - 2px);
  padding: 0 0.75rem var(--ct-footer-height);
  transition: all 0.25s ease-in-out;
}

.leftside-menu {
  z-index: 1000;
  bottom: 0;
  top: 0;
  position: fixed;
  width: var(--ct-leftbar-width);
  min-width: var(--ct-leftbar-width);
  padding-bottom: calc(var(--ct-footer-height) + 0.75rem);
  background: var(--ct-menu-bg);
  box-shadow: var(--ct-box-shadow);
  border-right: var(--ct-theme-card-border-width) solid var(--ct-border-color);
  transition: all 0.25s ease-in-out;
}

.leftbar-user {
  padding: 20px;
  text-align: center;
  display: none;
}
.leftbar-user .leftbar-user-name {
  font-weight: 700;
  color: var(--ct-menu-item-color);
  white-space: nowrap;
  display: block;
}

html[data-sidenav-user=true] .leftbar-user {
  display: block;
}

.side-nav {
  padding-left: 0;
  list-style-type: none;
}
.side-nav ul {
  list-style-type: none;
}
.side-nav .side-nav-link {
  display: block;
  list-style: none;
  position: relative;
  white-space: nowrap;
  color: var(--ct-menu-item-color);
  transition: all 0.25s ease-in-out;
  font-size: var(--ct-menu-item-font-size);
  padding: var(--ct-menu-item-padding-y) var(--ct-menu-item-padding-x);
}
.side-nav .side-nav-link:hover, .side-nav .side-nav-link:focus, .side-nav .side-nav-link:active {
  color: var(--ct-menu-item-hover-color);
  text-decoration: none;
}
.side-nav .side-nav-link span {
  vertical-align: middle;
}
.side-nav .side-nav-link i {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-size: var(--ct-menu-item-icon-size);
  line-height: var(--ct-menu-item-icon-size);
  width: calc(var(--ct-leftbar-width-sm) - var(--ct-menu-item-padding-x) * 2);
  min-width: calc(var(--ct-leftbar-width-sm) - var(--ct-menu-item-padding-x) * 2);
}
.side-nav .menu-arrow {
  display: inline-block;
  text-rendering: auto;
  position: absolute;
  top: 50%;
  right: calc(var(--ct-menu-item-padding-x) * 1.5);
  transition: transform 0.15s;
  transform: translate(-50%, -50%);
  line-height: var(--ct-menu-item-icon-size);
  font-size: calc(var(--ct-menu-item-font-size) * 1.18);
}
.side-nav .menu-arrow:before {
  content: "\f0142";
  font-family: "Material Design Icons";
}
.side-nav .badge {
  margin-top: calc(var(--ct-menu-item-font-size) * 0.25);
  position: absolute;
  right: 0;
  margin-right: calc(var(--ct-menu-item-padding-x) * 3);
}
.side-nav .side-nav-title {
  letter-spacing: 0.05em;
  pointer-events: none;
  cursor: default;
  white-space: nowrap;
  text-transform: uppercase;
  color: var(--ct-menu-item-color);
  font-weight: 700;
  font-size: calc(var(--ct-menu-item-font-size) * 0.74);
  padding: var(--ct-menu-item-padding-y) calc(var(--ct-menu-item-padding-x) * 2);
}
.side-nav .side-nav-item > a[aria-expanded=true] > span.menu-arrow {
  transform: translate(-50%, -50%) rotate(90deg);
}
.side-nav .side-nav-item.menuitem-active > a:not(.collapsed) > span.menu-arrow {
  transform: translate(-50%, -50%) rotate(90deg);
}
.side-nav .menuitem-active > a {
  color: var(--ct-menu-item-active-color);
  font-weight: 500;
}
.side-nav .menuitem-active > a:hover, .side-nav .menuitem-active > a:focus, .side-nav .menuitem-active > a:active {
  color: var(--ct-menu-item-active-color);
}
.side-nav .menuitem-active .menuitem-active .active {
  color: var(--ct-menu-item-active-color);
  font-weight: 500;
}
.side-nav.bottom {
  bottom: 0;
  width: 100%;
  position: absolute;
}

*[dir=ltr] .side-nav .side-nav-item .menu-arrow:before {
  content: "\f0141" !important;
}

.side-nav-second-level li,
.side-nav-second-level .side-nav-item,
.side-nav-third-level li,
.side-nav-third-level .side-nav-item,
.side-nav-forth-level li,
.side-nav-forth-level .side-nav-item {
  padding: 0;
}
.side-nav-second-level li a,
.side-nav-second-level li .side-nav-link,
.side-nav-second-level .side-nav-item a,
.side-nav-second-level .side-nav-item .side-nav-link,
.side-nav-third-level li a,
.side-nav-third-level li .side-nav-link,
.side-nav-third-level .side-nav-item a,
.side-nav-third-level .side-nav-item .side-nav-link,
.side-nav-forth-level li a,
.side-nav-forth-level li .side-nav-link,
.side-nav-forth-level .side-nav-item a,
.side-nav-forth-level .side-nav-item .side-nav-link {
  color: var(--ct-menu-item-color);
  display: block;
  position: relative;
  transition: all 0.25s ease-in-out;
  font-size: calc(var(--ct-menu-item-font-size) * 0.98);
  padding: calc(var(--ct-menu-item-padding-y) * 0.6) calc(var(--ct-menu-item-padding-x) * 1.5);
}
.side-nav-second-level li a:hover, .side-nav-second-level li a:focus, .side-nav-second-level li a:active,
.side-nav-second-level li .side-nav-link:hover,
.side-nav-second-level li .side-nav-link:focus,
.side-nav-second-level li .side-nav-link:active,
.side-nav-second-level .side-nav-item a:hover,
.side-nav-second-level .side-nav-item a:focus,
.side-nav-second-level .side-nav-item a:active,
.side-nav-second-level .side-nav-item .side-nav-link:hover,
.side-nav-second-level .side-nav-item .side-nav-link:focus,
.side-nav-second-level .side-nav-item .side-nav-link:active,
.side-nav-third-level li a:hover,
.side-nav-third-level li a:focus,
.side-nav-third-level li a:active,
.side-nav-third-level li .side-nav-link:hover,
.side-nav-third-level li .side-nav-link:focus,
.side-nav-third-level li .side-nav-link:active,
.side-nav-third-level .side-nav-item a:hover,
.side-nav-third-level .side-nav-item a:focus,
.side-nav-third-level .side-nav-item a:active,
.side-nav-third-level .side-nav-item .side-nav-link:hover,
.side-nav-third-level .side-nav-item .side-nav-link:focus,
.side-nav-third-level .side-nav-item .side-nav-link:active,
.side-nav-forth-level li a:hover,
.side-nav-forth-level li a:focus,
.side-nav-forth-level li a:active,
.side-nav-forth-level li .side-nav-link:hover,
.side-nav-forth-level li .side-nav-link:focus,
.side-nav-forth-level li .side-nav-link:active,
.side-nav-forth-level .side-nav-item a:hover,
.side-nav-forth-level .side-nav-item a:focus,
.side-nav-forth-level .side-nav-item a:active,
.side-nav-forth-level .side-nav-item .side-nav-link:hover,
.side-nav-forth-level .side-nav-item .side-nav-link:focus,
.side-nav-forth-level .side-nav-item .side-nav-link:active {
  color: var(--ct-menu-item-hover-color);
}
.side-nav-second-level li.active > a,
.side-nav-second-level .side-nav-item.active > a,
.side-nav-third-level li.active > a,
.side-nav-third-level .side-nav-item.active > a,
.side-nav-forth-level li.active > a,
.side-nav-forth-level .side-nav-item.active > a {
  color: var(--ct-menu-item-active-color);
}
.side-nav-second-level li.active > a:hover, .side-nav-second-level li.active > a:focus, .side-nav-second-level li.active > a:active,
.side-nav-second-level .side-nav-item.active > a:hover,
.side-nav-second-level .side-nav-item.active > a:focus,
.side-nav-second-level .side-nav-item.active > a:active,
.side-nav-third-level li.active > a:hover,
.side-nav-third-level li.active > a:focus,
.side-nav-third-level li.active > a:active,
.side-nav-third-level .side-nav-item.active > a:hover,
.side-nav-third-level .side-nav-item.active > a:focus,
.side-nav-third-level .side-nav-item.active > a:active,
.side-nav-forth-level li.active > a:hover,
.side-nav-forth-level li.active > a:focus,
.side-nav-forth-level li.active > a:active,
.side-nav-forth-level .side-nav-item.active > a:hover,
.side-nav-forth-level .side-nav-item.active > a:focus,
.side-nav-forth-level .side-nav-item.active > a:active {
  color: var(--ct-menu-item-active-color);
}

.side-nav-second-level {
  margin-top: calc(var(--ct-menu-item-padding-y) * -0.5);
  padding-left: calc(var(--ct-leftbar-width-sm) - var(--ct-menu-item-padding-x) * 2);
}

.side-nav-third-level {
  padding-left: calc(var(--ct-menu-item-padding-x) * 2);
}

.side-nav-forth-level {
  padding-left: calc(var(--ct-menu-item-padding-x) * 2);
}

html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .navbar-custom {
  z-index: 1005;
  margin-left: var(--ct-leftbar-width-sm);
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu {
  position: absolute;
  width: var(--ct-leftbar-width-sm);
  min-width: var(--ct-leftbar-width-sm);
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .simplebar-mask,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .simplebar-content-wrapper {
  overflow: visible !important;
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .simplebar-scrollbar {
  display: none !important;
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .simplebar-offset {
  bottom: 0 !important;
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .logo {
  z-index: 1;
  background: var(--ct-menu-bg);
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .logo span.logo-lg {
  display: none;
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .logo span.logo-sm {
  display: block;
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapse,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapsing {
  display: none;
  height: inherit !important;
  transition: none !important;
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapse .side-nav-second-level,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapse .side-nav-third-level,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapse .side-nav-forth-level,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapsing .side-nav-second-level,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapsing .side-nav-third-level,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapsing .side-nav-forth-level {
  display: none !important;
  padding: 0;
  margin-top: 0;
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapse .side-nav-second-level li a::before,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapse .side-nav-second-level li .side-nav-link::before,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapse .side-nav-third-level li a::before,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapse .side-nav-third-level li .side-nav-link::before,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapse .side-nav-forth-level li a::before,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapse .side-nav-forth-level li .side-nav-link::before,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapsing .side-nav-second-level li a::before,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapsing .side-nav-second-level li .side-nav-link::before,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapsing .side-nav-third-level li a::before,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapsing .side-nav-third-level li .side-nav-link::before,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapsing .side-nav-forth-level li a::before,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapsing .side-nav-forth-level li .side-nav-link::before {
  display: none;
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapse .side-nav-second-level .menu-arrow,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapse .side-nav-third-level .menu-arrow,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapse .side-nav-forth-level .menu-arrow,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapsing .side-nav-second-level .menu-arrow,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapsing .side-nav-third-level .menu-arrow,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .collapsing .side-nav-forth-level .menu-arrow {
  right: var(--ct-menu-item-padding-x);
  transform: translate(-50%, -50%);
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item {
  position: relative;
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item .side-nav-link {
  transition: none;
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item .side-nav-link:hover, html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item .side-nav-link:active, html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item .side-nav-link:focus {
  color: var(--ct-menu-item-hover-color);
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item .side-nav-link span {
  visibility: hidden;
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item .side-nav-link .menu-arrow {
  transform: translate(-50%, -50%) rotate(90deg);
  padding-left: 0 !important;
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover .side-nav-link {
  position: relative;
  color: #fff;
  background: var(--ct-menu-condensed-link-bg);
  width: var(--ct-leftbar-width);
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover .side-nav-link span {
  visibility: visible;
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover .side-nav-link span:not(.badge) {
  margin-left: var(--ct-menu-item-padding-x);
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover > .collapse,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover > .collapsing {
  display: block !important;
  transition: none !important;
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover > .collapse > ul,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover > .collapsing > ul {
  display: block !important;
  left: var(--ct-leftbar-width-sm);
  position: absolute;
  background: var(--ct-menu-bg);
  box-shadow: 0 0 45px 0 rgba(var(--ct-body-color-rgb), 0.2);
  width: calc(var(--ct-leftbar-width) - var(--ct-leftbar-width-sm));
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover > .collapse > ul a,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover > .collapsing > ul a {
  box-shadow: none;
  position: relative;
  width: calc(var(--ct-leftbar-width) - var(--ct-leftbar-width-sm));
  padding: calc(var(--ct-menu-item-padding-y) * 0.6) calc(var(--ct-menu-item-padding-x) * 2);
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover > .collapse > ul a:hover,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover > .collapsing > ul a:hover {
  color: var(--ct-menu-item-hover-color);
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover > .collapse > ul li:hover > .collapse,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover > .collapse > ul li:hover > .collapsing,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover > .collapsing > ul li:hover > .collapse,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover > .collapsing > ul li:hover > .collapsing {
  display: block !important;
  height: auto !important;
  transition: none !important;
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover > .collapse > ul li:hover > .collapse > ul,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover > .collapse > ul li:hover > .collapsing > ul,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover > .collapsing > ul li:hover > .collapse > ul,
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item:hover > .collapsing > ul li:hover > .collapsing > ul {
  display: block !important;
  position: absolute !important;
  top: 0;
  left: calc(var(--ct-leftbar-width) - var(--ct-leftbar-width-sm));
  width: calc(var(--ct-leftbar-width) - var(--ct-leftbar-width-sm));
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper :is(.help-box,
.leftbar-user,
.side-nav-title) {
  display: none;
}
html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .content-page {
  margin-left: var(--ct-leftbar-width-sm);
  min-height: var(--ct-leftbar-condensed-height);
}

html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .navbar-custom {
  margin-left: var(--ct-leftbar-width-md);
}
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu {
  width: var(--ct-leftbar-width-md);
  min-width: var(--ct-leftbar-width-md);
}
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-title {
  text-align: center;
  padding: var(--ct-menu-item-padding-y) calc(var(--ct-menu-item-padding-x));
}
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item {
  text-align: center;
}
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-link {
  padding: var(--ct-menu-item-padding-y) var(--ct-menu-item-padding-x);
  text-align: center;
}
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-link i {
  display: block;
  text-align: center;
  margin: 0 0 calc(var(--ct-menu-item-padding-y) * 1 / 3);
  width: auto;
}
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-link span {
  margin-left: 0;
}
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-link .badge {
  display: none;
}
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-second-level,
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-third-level,
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-forth-level {
  padding: 0;
}
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-second-level .menu-arrow,
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-third-level .menu-arrow,
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-forth-level .menu-arrow {
  display: inline-block;
  right: var(--ct-menu-item-padding-x);
}
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-second-level li a,
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-second-level li .side-nav-link,
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-third-level li a,
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-third-level li .side-nav-link,
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-forth-level li a,
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-forth-level li .side-nav-link {
  padding: calc(var(--ct-menu-item-padding-y) * 0.8) calc(var(--ct-menu-item-padding-x));
  text-align: center;
}
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .help-box,
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .menu-arrow {
  display: none;
}
html[data-sidenav-size=compact]:not([data-layout=topnav]) .wrapper .content-page {
  margin-left: var(--ct-leftbar-width-md);
}

html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .content-page {
  margin-left: var(--ct-leftbar-width-sm);
}
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .navbar-custom {
  margin-left: var(--ct-leftbar-width-sm);
}
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .navbar-custom .button-toggle-menu {
  display: none;
}
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .leftside-menu:not(:hover) {
  width: var(--ct-leftbar-width-sm);
  min-width: var(--ct-leftbar-width-sm);
}
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .leftside-menu:not(:hover) .simplebar-scrollbar:before {
  background: transparent;
}
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .leftside-menu:not(:hover) .logo .logo-sm {
  display: block;
}
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .leftside-menu:not(:hover) .logo .logo-lg {
  display: none;
}
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .leftside-menu:not(:hover) .side-nav-item .side-nav-link span {
  visibility: hidden;
}
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .leftside-menu:not(:hover) .help-box,
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .leftside-menu:not(:hover) .button-sm-hover {
  visibility: hidden;
}
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .leftside-menu:not(:hover) .leftbar-user,
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .leftside-menu:not(:hover) .side-nav-title {
  display: none;
}
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .leftside-menu:not(:hover) .side-nav-second-level,
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .leftside-menu:not(:hover) .side-nav-third-level,
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .leftside-menu:not(:hover) .side-nav-forth-level {
  height: 0;
  opacity: 0;
}
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .leftside-menu .simplebar-horizontal .simplebar-scrollbar:before {
  background: transparent;
}
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .leftside-menu .logo {
  text-align: left;
}
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .leftside-menu:hover {
  z-index: 1045;
}
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .leftside-menu .button-sm-hover {
  display: block;
}
html[data-sidenav-size=sm-hover]:not([data-layout=topnav]) .wrapper .leftside-menu .help-box {
  display: none;
}

.button-sm-hover {
  cursor: pointer;
  display: none;
  right: calc(var(--ct-leftbar-width-sm) * 0.5 - var(--ct-logo-sm-height) * 0.7);
  top: 0;
  position: absolute;
  font-size: var(--ct-menu-item-icon-size);
  line-height: var(--ct-topbar-height);
  z-index: 1;
  color: var(--ct-menu-item-color);
}
.button-sm-hover:hover, .button-sm-hover:focus, .button-sm-hover:active {
  color: var(--ct-menu-item-hover-color);
}

html[data-sidenav-size=sm-hover-active]:not([data-layout=topnav]) .button-toggle-menu {
  display: none;
}
html[data-sidenav-size=sm-hover-active]:not([data-layout=topnav]) .leftside-menu .logo {
  text-align: left;
}
html[data-sidenav-size=sm-hover-active]:not([data-layout=topnav]) .leftside-menu .button-sm-hover {
  display: block;
}
html[data-sidenav-size=sm-hover-active]:not([data-layout=topnav]) .leftside-menu .button-sm-hover .ri-checkbox-blank-circle-line:before {
  content: "\f05a";
}

.button-close-fullsidebar {
  cursor: pointer;
  display: none;
  right: calc(var(--ct-leftbar-width-sm) * 0.5 - var(--ct-logo-sm-height) * 0.7);
  top: 0;
  position: absolute;
  font-size: var(--ct-menu-item-icon-size);
  line-height: var(--ct-topbar-height);
  z-index: 1;
  color: var(--ct-menu-item-color);
}
.button-close-fullsidebar:hover, .button-close-fullsidebar:focus, .button-close-fullsidebar:active {
  color: var(--ct-menu-item-hover-color);
}

html[data-sidenav-size=full]:not([data-layout=topnav]) .navbar-custom {
  margin-left: 0;
}
html[data-sidenav-size=full]:not([data-layout=topnav]) .navbar-custom .logo-topbar {
  display: inline-block;
}
html[data-sidenav-size=full]:not([data-layout=topnav]) .button-close-fullsidebar {
  display: block;
}
html[data-sidenav-size=full]:not([data-layout=topnav]) .leftside-menu {
  margin-left: calc(var(--ct-leftbar-width) * -1);
  border-color: transparent;
  opacity: 0;
}
html[data-sidenav-size=full]:not([data-layout=topnav]) .leftside-menu .logo {
  text-align: left;
}
html[data-sidenav-size=full]:not([data-layout=topnav]) .content-page {
  margin-left: 0;
}
html[data-sidenav-size=full]:not([data-layout=topnav]).sidebar-enable .leftside-menu {
  opacity: 1;
  z-index: 1055;
  margin-left: 0;
}

html[data-sidenav-size=fullscreen]:not([data-layout=topnav]) .navbar-custom {
  margin-left: 0;
}
html[data-sidenav-size=fullscreen]:not([data-layout=topnav]) .leftside-menu {
  margin-left: calc(var(--ct-leftbar-width) * -1);
  opacity: 0;
}
html[data-sidenav-size=fullscreen]:not([data-layout=topnav]) .content-page {
  margin-left: 0;
}

html[data-layout-mode=detached]:not([data-layout=topnav]) .wrapper {
  max-width: 95%;
  margin: 0 auto;
}
html[data-layout-mode=detached]:not([data-layout=topnav]) .wrapper .navbar-custom {
  z-index: 1005;
  margin: 0 -2.65%;
}
html[data-layout-mode=detached]:not([data-layout=topnav]) .wrapper .navbar-custom .topbar {
  max-width: 95%;
}
html[data-layout-mode=detached]:not([data-layout=topnav]) .wrapper .leftside-menu {
  bottom: 0.75rem;
  top: calc(var(--ct-topbar-height) + 1.5rem * 0.5);
  border-radius: 0.3rem;
  border: var(--ct-theme-card-border-width) solid var(--ct-border-color);
  padding-bottom: 0;
}
html[data-layout-mode=detached]:not([data-layout=topnav]) .wrapper .leftside-menu .button-sm-hover {
  top: var(--ct-menu-item-padding-y);
  line-height: 1;
}
html[data-layout-mode=detached]:not([data-layout=topnav]) .wrapper .footer {
  right: 0.75rem;
  left: 0.75rem;
  bottom: 0.75rem;
  border-radius: 0.3rem;
  border: none;
}
html[data-layout-mode=detached]:not([data-layout=topnav]) .logo {
  visibility: hidden;
  height: 0;
}
html[data-layout-mode=detached]:not([data-layout=topnav]) .logo-topbar {
  display: block;
}
html[data-layout-mode=detached]:not([data-layout=topnav]) .leftbar-user {
  background: url("../../../images/waves.png") no-repeat;
}
html[data-layout-mode=detached]:not([data-layout=topnav])[data-sidenav-size=full] .wrapper .leftside-menu {
  padding-bottom: calc(var(--ct-footer-height) + 0.75rem);
  top: 0.75rem;
  left: 0.75rem;
}
html[data-layout-mode=detached]:not([data-layout=topnav])[data-sidenav-size=full] .wrapper .leftside-menu .logo {
  top: 0.75rem;
  visibility: visible;
  height: auto;
}
html[data-layout-mode=detached]:not([data-layout=topnav])[data-sidenav-size=full] .wrapper .leftside-menu .logo span.logo-lg {
  display: block;
}

@media (min-width: 1400px) {
  html[data-layout-mode=boxed] body {
    background-color: var(--ct-tertiary-bg);
  }
  html[data-layout-mode=boxed] .wrapper {
    margin: 0 auto;
    box-shadow: var(--ct-box-shadow);
    max-width: 1300px;
    background-color: var(--ct-body-bg);
  }
}
@media (min-width: 992px) {
  html[data-layout-position=scrollable] .wrapper {
    display: block;
  }
  html[data-layout-position=scrollable] .wrapper .leftside-menu {
    position: absolute;
    padding-top: 0 !important;
  }
  html[data-layout-position=scrollable] .wrapper .leftside-menu .logo {
    position: relative;
  }
  html[data-layout-position=scrollable] .wrapper .leftside-menu #leftside-menu-container {
    height: calc(100% - var(--ct-topbar-height)) !important;
  }
  html[data-layout-position=scrollable] .navbar-custom {
    position: static !important;
  }
  html[data-layout-position=scrollable][data-layout=topnav] .topnav {
    position: static;
  }
}
@media (max-width: 767.98px) {
  html[data-layout-mode=detached]:not([data-layout=topnav]) .wrapper {
    max-width: 100% !important;
  }
  html[data-layout-mode=detached]:not([data-layout=topnav]) .wrapper .navbar-custom {
    margin: 0;
    padding: 0;
  }
}
.navbar-custom {
  background: var(--ct-topbar-bg);
  box-shadow: var(--ct-box-shadow);
  min-height: var(--ct-topbar-height);
  margin-left: var(--ct-leftbar-width);
  position: sticky;
  top: 0;
  transition: all 0.25s ease-in-out;
  z-index: 1000;
  border-bottom: var(--ct-theme-card-border-width) solid var(--ct-border-color);
}
.navbar-custom .topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}
.navbar-custom .topbar .app-search form {
  height: var(--ct-topbar-height);
  max-width: 320px;
}
.navbar-custom .topbar .app-search form .form-control {
  color: var(--ct-topbar-item-color);
  background-color: var(--ct-topbar-search-bg);
}
.navbar-custom .topbar .app-search form .form-control::placeholder {
  color: var(--ct-topbar-item-color);
  opacity: 0.5;
}
.navbar-custom .topbar .app-search form span.search-icon {
  color: var(--ct-topbar-item-color);
}
.navbar-custom .topbar-menu {
  position: relative;
  list-style-type: none;
  margin-bottom: 0;
}
.navbar-custom .topbar-menu li {
  max-height: var(--ct-topbar-height);
}
.navbar-custom .topbar-menu li .nav-link.show {
  color: var(--ct-topbar-item-hover-color);
}
.navbar-custom .topbar-menu .nav-link {
  padding: 0;
  position: relative;
  color: var(--ct-topbar-item-color);
  display: flex;
  align-items: center;
  height: var(--ct-topbar-height);
  cursor: pointer;
}
.navbar-custom .topbar-menu .nav-link:hover {
  color: var(--ct-topbar-item-hover-color);
}

.logo-topbar {
  float: left;
  display: none;
  line-height: var(--ct-topbar-height);
  padding: 0 0.75rem;
}
.logo-topbar .logo-sm {
  display: none;
}
.logo-topbar .logo-light {
  display: none;
}
.logo-topbar .logo-dark {
  display: block;
}

html[data-topbar-color=brand] .logo-topbar .logo-light,
html[data-topbar-color=dark] .logo-topbar .logo-light,
html[data-bs-theme=dark] .logo-topbar .logo-light {
  display: block;
}
html[data-topbar-color=brand] .logo-topbar .logo-dark,
html[data-topbar-color=dark] .logo-topbar .logo-dark,
html[data-bs-theme=dark] .logo-topbar .logo-dark {
  display: none;
}

html[data-bs-theme=dark] #light-dark-mode .fa-moon::before {
  content: "\e28f";
}

.button-toggle-menu {
  border: none;
  color: var(--ct-topbar-item-color);
  width: 60px;
  background-color: transparent;
  font-size: 24px;
  cursor: pointer;
  z-index: 1;
  position: relative;
}

.app-search {
  position: static;
  overflow-y: hidden;
}
.app-search form {
  display: flex;
  overflow: hidden;
  align-items: center;
}
.app-search form .form-control {
  border: none;
  height: calc(1.5em + 0.9rem + calc(var(--ct-border-width) * 2));
  padding-left: 40px;
  padding-right: 20px;
  background-color: var(--ct-light);
  box-shadow: none;
}
.app-search form span.search-icon {
  position: absolute;
  z-index: 4;
  font-size: 20px;
  line-height: 38px;
  left: 10px;
  top: 0;
  z-index: 5;
  color: var(--ct-secondary);
}
.app-search form .input-group-text {
  margin-left: 0;
  z-index: 4;
}
.app-search form .dropdown-menu {
  z-index: 1060;
}

.notification-list {
  margin-left: 0;
}
.notification-list .noti-title {
  background-color: transparent;
  padding: 15px 20px;
}
.notification-list .noti-icon-badge {
  display: inline-block;
  position: absolute;
  top: 22px;
  right: 1px;
  border-radius: 50%;
  height: 7px;
  width: 7px;
  background-color: #fa5c7c;
}
.notification-list .notify-item {
  padding: 10px 20px;
}
.notification-list .notify-item.unread-noti {
  background-color: var(--ct-tertiary-bg);
}
.notification-list .notify-item.read-noti {
  background-color: transparent;
  border: 1px solid var(--ct-border-color-custom);
  transition: all 0.2s ease-in-out;
}
.notification-list .notify-item.read-noti:hover {
  background-color: var(--ct-tertiary-bg);
}
.notification-list .notify-item .card-body {
  padding: 14px;
}
.notification-list .notify-item .card-body .noti-close-btn {
  position: absolute;
  top: 3px;
  right: 5px;
}
.notification-list .notify-item .card-body .noti-item-title {
  margin: 0 0 2px;
}
.notification-list .notify-item .card-body .noti-item-title,
.notification-list .notify-item .card-body .noti-item-subtitle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.notification-list .notify-item .notify-icon {
  float: left;
  height: 36px;
  width: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
}
.notification-list .notify-item .notify-details {
  margin-bottom: 0;
  overflow: hidden;
  margin-left: 45px;
}
.notification-list .notify-item .notify-details b {
  font-weight: 500;
}
.notification-list .notify-item .notify-details small, .notification-list .notify-item .notify-details .small {
  display: block;
}

.profile-dropdown {
  min-width: 170px;
}
.profile-dropdown i,
.profile-dropdown span {
  vertical-align: middle;
}

.nav-user {
  display: flex;
  align-items: center;
  gap: 10px;
  border-width: 0 1px;
  border-style: solid;
  border-color: var(--ct-topbar-user-border);
  background-color: var(--ct-topbar-user-bg);
  min-height: var(--ct-topbar-height);
  transition: none;
}

.fullscreen-enable .ri-fullscreen-line::before {
  content: "\ed9a";
}

@media (max-width: 375px) {
  .navbar-custom .button-toggle-menu {
    width: auto;
  }
  .logo-topbar {
    display: none !important;
  }
}
@media (max-width: 575.98px) {
  .navbar-custom .topbar-menu {
    position: initial;
  }
  .navbar-custom .dropdown {
    position: static;
  }
  .navbar-custom .dropdown .dropdown-menu {
    left: 10px !important;
    right: 10px !important;
  }
}
@media (max-width: 767.98px) {
  .navbar-custom {
    padding: 0 0.75rem;
  }
  .logo-topbar {
    display: block;
  }
  .logo-topbar span.logo-lg {
    display: none;
  }
  .logo-topbar span.logo-sm {
    display: block;
  }
}
.page-title-box .page-title {
  font-size: 18px;
  margin: 0;
  line-height: 75px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.page-title-box .page-title-right {
  float: right;
  margin-top: 20px;
}
.page-title-box .breadcrumb {
  padding-top: 8px;
}

.page-title-box-sm .page-title {
  line-height: 1 !important;
  margin-bottom: 25px;
}
.page-title-box-sm .page-title-right {
  float: right;
  margin-top: 0;
}
.page-title-box-sm .breadcrumb {
  padding-top: 0;
  margin-top: -3px !important;
}

.text-title {
  color: var(--ct-secondary-color);
}
.text-title:hover {
  color: var(--ct-tertiary-color);
}

@media (max-width: 767.98px) {
  .page-title-box .page-title {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 70px;
  }
  .page-title-box .breadcrumb {
    display: none;
  }
  .page-title-box .page-title-right {
    display: none;
  }
}
@media (max-width: 419px) {
  .page-title-box .breadcrumb {
    display: none;
  }
}
.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: var(--ct-footer-height);
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  color: var(--ct-emphasis-color);
  transition: all 0.25s ease-in-out;
  border-top: 1px solid var(--ct-border-color);
}
.footer .footer-links a {
  color: var(--ct-emphasis-color);
  margin-left: 1.5rem;
  transition: all 0.4s;
}
.footer .footer-links a:hover {
  color: var(--ct-emphasis-color);
}
.footer .footer-links a:first-of-type {
  margin-left: 0;
}

.footer-alt {
  border: none;
  text-align: center;
  justify-content: center;
}

html[data-layout-mode=detached]:not([data-layout=topnav]) .footer .container-fluid, html[data-layout-mode=detached]:not([data-layout=topnav]) .footer .container-sm, html[data-layout-mode=detached]:not([data-layout=topnav]) .footer .container-md, html[data-layout-mode=detached]:not([data-layout=topnav]) .footer .container-lg, html[data-layout-mode=detached]:not([data-layout=topnav]) .footer .container-xl, html[data-layout-mode=detached]:not([data-layout=topnav]) .footer .container-xxl {
  max-width: 100%;
  padding: 0;
}

@media (max-width: 767.98px) {
  .footer {
    text-align: center;
  }
}
html[data-layout=topnav] .navbar-custom {
  margin: 0;
  padding: 0;
  z-index: 1005;
  box-shadow: none;
}
html[data-layout=topnav] .navbar-custom .logo-topbar {
  display: block;
}
html[data-layout=topnav] .navbar-custom .button-toggle-menu {
  display: none;
}
html[data-layout=topnav] .content-page {
  margin-left: 0;
  padding: 0 0 var(--ct-topbar-height);
}
html[data-layout=topnav] .container-fluid, html[data-layout=topnav] .container-sm, html[data-layout=topnav] .container-md, html[data-layout=topnav] .container-lg, html[data-layout=topnav] .container-xl, html[data-layout=topnav] .container-xxl {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
html[data-layout=topnav] .topnav {
  width: 100%;
  z-index: 1000;
  position: sticky;
  top: var(--ct-topbar-height);
  background: var(--ct-menu-bg);
  box-shadow: var(--ct-box-shadow);
  border-top: 1px solid var(--ct-topbar-user-border);
}
html[data-layout=topnav] .topnav .navbar {
  margin: 0;
  padding: 0;
}
html[data-layout=topnav] .topnav .navbar-nav .nav-link {
  display: flex;
  align-items: center;
  position: relative;
  font-size: var(--ct-menu-item-font-size);
  padding: var(--ct-menu-item-padding-y) calc(var(--ct-menu-item-padding-x) * 2);
}
html[data-layout=topnav] .topnav .navbar-nav .nav-link i {
  font-size: var(--ct-menu-item-icon-size);
  margin-right: calc(var(--ct-menu-item-icon-size) * 0.75);
}
html[data-layout=topnav] .topnav .navbar-nav .dropdown .dropdown-menu {
  font-size: calc(0.9rem - 0.01rem);
}
html[data-layout=topnav] .topnav .navbar-nav .dropdown .dropdown-menu .dropdown-item {
  padding: calc(var(--ct-menu-item-padding-y) * 0.5) calc(var(--ct-menu-item-padding-x) * 2.4);
}
html[data-layout=topnav] .topnav .nav-item > a,
html[data-layout=topnav] .topnav .nav-item .nav-link {
  color: var(--ct-menu-item-color);
}
html[data-layout=topnav] .topnav .nav-item > a:hover,
html[data-layout=topnav] .topnav .nav-item .nav-link:hover {
  color: var(--ct-menu-item-hover-color);
}
html[data-layout=topnav] .topnav .nav-item.active > a {
  color: var(--ct-menu-item-active-color);
}
html[data-layout=topnav] .arrow-down {
  display: inline-block;
  display: inline-block;
  text-rendering: auto;
  margin-left: calc(var(--ct-menu-item-padding-x) * 1.5);
  font-size: calc(var(--ct-menu-item-font-size) * 1.18);
}
html[data-layout=topnav] .arrow-down:before {
  content: "\f0140";
  font-family: "Material Design Icons";
}
html[data-layout=topnav] #sidebar-size,
html[data-layout=topnav] #layout-detached,
html[data-layout=topnav] #sidebar-user {
  display: none;
}
html[data-layout=topnav] #topnav-color {
  display: block;
}

#topnav-color {
  display: none;
}

.navbar-toggle {
  display: none;
  position: relative;
  cursor: pointer;
  float: left;
  padding: 0;
  background-color: transparent;
  color: var(--ct-topbar-item-color);
  border: none;
}
.navbar-toggle .lines {
  width: 25px;
  display: block;
  position: relative;
  height: 16px;
  transition: all 0.5s ease;
}
.navbar-toggle span {
  height: 2px;
  width: 100%;
  background-color: var(--ct-topbar-item-color);
  display: block;
  margin-bottom: 5px;
  transition: transform 0.5s ease;
}
.navbar-toggle.open span {
  position: absolute;
}
.navbar-toggle.open span:first-child {
  top: 7px;
  transform: rotate(45deg);
}
.navbar-toggle.open span:nth-child(2) {
  visibility: hidden;
}
.navbar-toggle.open span:last-child {
  width: 100%;
  top: 7px;
  transform: rotate(-45deg);
}

@media (min-width: 992px) {
  html[data-layout=topnav] .container-fluid, html[data-layout=topnav] .container-sm, html[data-layout=topnav] .container-md, html[data-layout=topnav] .container-lg, html[data-layout=topnav] .container-xl, html[data-layout=topnav] .container-xxl {
    max-width: 95%;
  }
  html[data-layout=topnav][data-layout-mode=boxed] .container-fluid, html[data-layout=topnav][data-layout-mode=boxed] .container-sm, html[data-layout=topnav][data-layout-mode=boxed] .container-md, html[data-layout=topnav][data-layout-mode=boxed] .container-lg, html[data-layout=topnav][data-layout-mode=boxed] .container-xl, html[data-layout=topnav][data-layout-mode=boxed] .container-xxl {
    max-width: 97%;
  }
  .topnav .navbar-nav .nav-item:first-of-type .nav-link {
    padding-left: 0;
  }
  .topnav .dropdown .dropdown-menu {
    margin-top: 0;
    border-radius: 0 0 0.25rem 0.25rem;
    min-width: calc(var(--ct-dropdown-min-width) + var(--ct-menu-item-font-size) * 1.5);
    font-size: calc(0.9rem - 0.01rem);
  }
  .topnav .dropdown .dropdown-menu .arrow-down {
    font-size: var(--ct-menu-item-font-size);
    transform: rotate(270deg);
    position: absolute;
    right: var(--ct-menu-item-padding-x);
  }
  .topnav .dropdown .dropdown-menu .dropdown .dropdown-menu {
    position: absolute;
    top: 0;
    left: 100%;
    display: none;
  }
  .topnav .dropdown:hover > .dropdown-menu {
    display: block;
  }
  .topnav .dropdown:hover > .dropdown-menu > .dropdown:hover > .dropdown-menu {
    display: block;
  }
  .topnav .dropdown.active > a.dropdown-item {
    color: var(--ct-tertiary-color);
    background-color: var(--ct-tertiary-bg);
  }
}
@media (max-width: 991.98px) {
  html[data-layout=topnav] .topnav {
    max-height: 360px;
    overflow-y: auto;
  }
  html[data-layout=topnav] .topnav .navbar-nav .nav-link {
    padding: calc(var(--ct-menu-item-padding-y) * 0.8) calc(var(--ct-menu-item-padding-x) * 2);
  }
  html[data-layout=topnav] .topnav .dropdown .dropdown-menu {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding-left: calc(var(--ct-menu-item-padding-x) * 3);
    margin-top: calc(var(--ct-menu-item-padding-y) * -0.75);
  }
  html[data-layout=topnav] .topnav .dropdown .dropdown-menu .dropdown-menu {
    margin-top: calc(var(--ct-menu-item-padding-y) * -0.5);
    padding-left: calc(var(--ct-menu-item-padding-x) * 1.5);
  }
  html[data-layout=topnav] .topnav .dropdown .dropdown-item {
    position: relative;
    background-color: transparent;
  }
  html[data-layout=topnav] .topnav .dropdown .dropdown-item {
    color: var(--ct-menu-item-color);
  }
  html[data-layout=topnav] .topnav .dropdown .dropdown-item.hover {
    color: var(--ct-menu-item-hover-color);
  }
  html[data-layout=topnav] .topnav .dropdown .dropdown-item.active, html[data-layout=topnav] .topnav .dropdown .dropdown-item:active {
    color: var(--ct-menu-item-active-color);
  }
  html[data-layout=topnav] .topnav .dropdown.active > a.dropdown-item {
    color: var(--ct-menu-item-active-color);
  }
  html[data-layout=topnav] .topnav .arrow-down {
    font-size: var(--ct-menu-item-font-size);
    transform: rotate(270deg);
    position: absolute;
    right: var(--ct-menu-item-padding-x);
  }
  html[data-layout=topnav] .navbar-toggle {
    display: block;
  }
}
@media (min-width: 1400px) {
  html[data-layout=topnav] .container-fluid, html[data-layout=topnav] .container-sm, html[data-layout=topnav] .container-md, html[data-layout=topnav] .container-lg, html[data-layout=topnav] .container-xl, html[data-layout=topnav] .container-xxl {
    max-width: 85%;
  }
}
@font-face {
  font-family: "Nunito";
  src: url("../../../fonts/Nunito-Light.eot");
  src: local("Nunito Light"), local("Nunito-Light"), url("../../../fonts/Nunito-Light.eot?#iefix") format("embedded-opentype"), url("../../../fonts/Nunito-Light.woff") format("woff"), url("../../../fonts/Nunito-Light.ttf") format("truetype"), url("../../../fonts/Nunito-Light.svg#Roboto") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: url("../../../fonts/Nunito-Regular.eot");
  src: local("Nunito Regular"), local("Nunito-Regular"), url("../../../fonts/Nunito-Regular.eot?#iefix") format("embedded-opentype"), url("../../../fonts/Nunito-Regular.woff") format("woff"), url("../../../fonts/Nunito-Regular.ttf") format("truetype"), url("../../../fonts/Nunito-Regular.svg#Roboto") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: url("../../../fonts/Nunito-Medium.eot");
  src: local("Nunito Medium"), local("Nunito-Medium"), url("../../../fonts/Nunito-Medium.eot?#iefix") format("embedded-opentype"), url("../../../fonts/Nunito-Medium.woff") format("woff"), url("../../../fonts/Nunito-Medium.ttf") format("truetype"), url("../../../fonts/Nunito-Medium.svg#Roboto") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: url("../../../fonts/Nunito-SemiBold.eot");
  src: local("Nunito SemiBold"), local("Nunito-SemiBold"), url("../../../fonts/Nunito-SemiBold.eot?#iefix") format("embedded-opentype"), url("../../../fonts/Nunito-SemiBold.woff") format("woff"), url("../../../fonts/Nunito-SemiBold.ttf") format("truetype"), url("../../../fonts/Nunito-SemiBold.svg#Roboto") format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: url("../../../fonts/Nunito-Bold.eot");
  src: local("Nunito Bold"), local("Nunito-Bold"), url("../../../fonts/Nunito-Bold.eot?#iefix") format("embedded-opentype"), url("../../../fonts/Nunito-Bold.woff") format("woff"), url("../../../fonts/Nunito-Bold.ttf") format("truetype"), url("../../../fonts/Nunito-Bold.svg#Roboto") format("svg");
  font-weight: 700;
  font-style: normal;
}
:root,
[data-bs-theme=light] {
  --ct-input-bg: #fff;
  --ct-border-color-custom: #f6f7fb;
}

[data-bs-theme=dark] {
  --ct-light: #464f5b;
  --ct-light-rgb: 70, 79, 91;
  --ct-dark: #f1f1f1;
  --ct-dark-rgb: 241, 241, 241;
  --ct-secondary: #6c757d;
  --ct-secondary-rgb: 108, 117, 125;
  --ct-box-shadow: 0px 0px 35px 0px rgba(49, 57, 66, 0.5);
  --ct-input-bg: #404954;
  --ct-border-color-custom: #404954;
}

.bg-primary-lighten {
  background-color: var(--ct-primary-bg-subtle) !important;
}

.bg-secondary-lighten {
  background-color: var(--ct-secondary-bg-subtle) !important;
}

.bg-success-lighten {
  background-color: var(--ct-success-bg-subtle) !important;
}

.bg-info-lighten {
  background-color: var(--ct-info-bg-subtle) !important;
}

.bg-warning-lighten {
  background-color: var(--ct-warning-bg-subtle) !important;
}

.bg-danger-lighten {
  background-color: var(--ct-danger-bg-subtle) !important;
}

.bg-light-lighten {
  background-color: var(--ct-light-bg-subtle) !important;
}

.bg-dark-lighten {
  background-color: var(--ct-dark-bg-subtle) !important;
}

.bg-lwk-green-dark-lighten {
  background-color: var(--ct-lwk-green-dark-bg-subtle) !important;
}

.bg-lwk-green-light-lighten {
  background-color: var(--ct-lwk-green-light-bg-subtle) !important;
}

.accordion-header {
  margin: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.accordion-button {
  font-weight: 500;
}

.custom-accordion .card {
  box-shadow: none;
}
.custom-accordion .card-header {
  background-color: var(--ct-tertiary-bg);
}
.custom-accordion .accordion-arrow {
  font-size: 1.2rem;
  position: absolute;
  right: 0;
}
.custom-accordion a.collapsed i.accordion-arrow:before {
  content: "\f0142";
}

.custom-accordion-title {
  position: relative;
  color: var(--ct-secondary-color);
}
.custom-accordion-title:hover {
  color: var(--ct-tertiary-color);
}

.avatar-xs {
  height: 1.5rem;
  width: 1.5rem;
}

.avatar-sm {
  height: 3rem;
  width: 3rem;
}

.avatar-md {
  height: 4.5rem;
  width: 4.5rem;
}

.avatar-lg {
  height: 6rem;
  width: 6rem;
}

.avatar-xl {
  height: 7.5rem;
  width: 7.5rem;
}

.avatar-title {
  align-items: center;
  background-color: var(--ct-primary);
  color: #fff;
  display: flex;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.breadcrumb-item + .breadcrumb-item::before {
  font-family: "Material Design Icons";
  font-size: 16px;
  line-height: 1.3;
}

.btn .mdi:before {
  line-height: initial;
}

.btn-primary {
  box-shadow: var(--ct-btn-box-shadow) rgba(var(--ct-primary-rgb), 0.5);
}

.btn-soft-primary {
  --ct-btn-color: var(--ct-primary);
  --ct-btn-bg: var(--ct-primary-bg-subtle);
  --ct-btn-border-color: transparent;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: var(--ct-primary);
  --ct-btn-hover-border-color: var(--ct-primary);
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: var(--ct-primary);
  --ct-btn-active-border-color: var(--ct-primary);
  --ct-btn-disabled-color: var(--ct-primary);
  --ct-btn-disabled-bg: var(--ct-primary);
  --ct-btn-disabled-border-color: var(--ct-primary);
  --ct-btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--ct-primary)-rgb, 0.5);
}

.btn-secondary {
  box-shadow: var(--ct-btn-box-shadow) rgba(var(--ct-secondary-rgb), 0.5);
}

.btn-soft-secondary {
  --ct-btn-color: var(--ct-secondary);
  --ct-btn-bg: var(--ct-secondary-bg-subtle);
  --ct-btn-border-color: transparent;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: var(--ct-secondary);
  --ct-btn-hover-border-color: var(--ct-secondary);
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: var(--ct-secondary);
  --ct-btn-active-border-color: var(--ct-secondary);
  --ct-btn-disabled-color: var(--ct-secondary);
  --ct-btn-disabled-bg: var(--ct-secondary);
  --ct-btn-disabled-border-color: var(--ct-secondary);
  --ct-btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--ct-secondary)-rgb, 0.5);
}

.btn-success {
  box-shadow: var(--ct-btn-box-shadow) rgba(var(--ct-success-rgb), 0.5);
}

.btn-soft-success {
  --ct-btn-color: var(--ct-success);
  --ct-btn-bg: var(--ct-success-bg-subtle);
  --ct-btn-border-color: transparent;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: var(--ct-success);
  --ct-btn-hover-border-color: var(--ct-success);
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: var(--ct-success);
  --ct-btn-active-border-color: var(--ct-success);
  --ct-btn-disabled-color: var(--ct-success);
  --ct-btn-disabled-bg: var(--ct-success);
  --ct-btn-disabled-border-color: var(--ct-success);
  --ct-btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--ct-success)-rgb, 0.5);
}

.btn-info {
  box-shadow: var(--ct-btn-box-shadow) rgba(var(--ct-info-rgb), 0.5);
}

.btn-soft-info {
  --ct-btn-color: var(--ct-info);
  --ct-btn-bg: var(--ct-info-bg-subtle);
  --ct-btn-border-color: transparent;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: var(--ct-info);
  --ct-btn-hover-border-color: var(--ct-info);
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: var(--ct-info);
  --ct-btn-active-border-color: var(--ct-info);
  --ct-btn-disabled-color: var(--ct-info);
  --ct-btn-disabled-bg: var(--ct-info);
  --ct-btn-disabled-border-color: var(--ct-info);
  --ct-btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--ct-info)-rgb, 0.5);
}

.btn-warning {
  box-shadow: var(--ct-btn-box-shadow) rgba(var(--ct-warning-rgb), 0.5);
}

.btn-soft-warning {
  --ct-btn-color: var(--ct-warning);
  --ct-btn-bg: var(--ct-warning-bg-subtle);
  --ct-btn-border-color: transparent;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: var(--ct-warning);
  --ct-btn-hover-border-color: var(--ct-warning);
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: var(--ct-warning);
  --ct-btn-active-border-color: var(--ct-warning);
  --ct-btn-disabled-color: var(--ct-warning);
  --ct-btn-disabled-bg: var(--ct-warning);
  --ct-btn-disabled-border-color: var(--ct-warning);
  --ct-btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--ct-warning)-rgb, 0.5);
}

.btn-danger {
  box-shadow: var(--ct-btn-box-shadow) rgba(var(--ct-danger-rgb), 0.5);
}

.btn-soft-danger {
  --ct-btn-color: var(--ct-danger);
  --ct-btn-bg: var(--ct-danger-bg-subtle);
  --ct-btn-border-color: transparent;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: var(--ct-danger);
  --ct-btn-hover-border-color: var(--ct-danger);
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: var(--ct-danger);
  --ct-btn-active-border-color: var(--ct-danger);
  --ct-btn-disabled-color: var(--ct-danger);
  --ct-btn-disabled-bg: var(--ct-danger);
  --ct-btn-disabled-border-color: var(--ct-danger);
  --ct-btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--ct-danger)-rgb, 0.5);
}

.btn-light {
  box-shadow: var(--ct-btn-box-shadow) rgba(var(--ct-light-rgb), 0.5);
}

.btn-soft-light {
  --ct-btn-color: var(--ct-light);
  --ct-btn-bg: var(--ct-light-bg-subtle);
  --ct-btn-border-color: transparent;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: var(--ct-light);
  --ct-btn-hover-border-color: var(--ct-light);
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: var(--ct-light);
  --ct-btn-active-border-color: var(--ct-light);
  --ct-btn-disabled-color: var(--ct-light);
  --ct-btn-disabled-bg: var(--ct-light);
  --ct-btn-disabled-border-color: var(--ct-light);
  --ct-btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--ct-light)-rgb, 0.5);
}

.btn-dark {
  box-shadow: var(--ct-btn-box-shadow) rgba(var(--ct-dark-rgb), 0.5);
}

.btn-soft-dark {
  --ct-btn-color: var(--ct-dark);
  --ct-btn-bg: var(--ct-dark-bg-subtle);
  --ct-btn-border-color: transparent;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: var(--ct-dark);
  --ct-btn-hover-border-color: var(--ct-dark);
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: var(--ct-dark);
  --ct-btn-active-border-color: var(--ct-dark);
  --ct-btn-disabled-color: var(--ct-dark);
  --ct-btn-disabled-bg: var(--ct-dark);
  --ct-btn-disabled-border-color: var(--ct-dark);
  --ct-btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--ct-dark)-rgb, 0.5);
}

.btn-lwk-green-dark {
  box-shadow: var(--ct-btn-box-shadow) rgba(var(--ct-lwk-green-dark-rgb), 0.5);
}

.btn-soft-lwk-green-dark {
  --ct-btn-color: var(--ct-lwk-green-dark);
  --ct-btn-bg: var(--ct-lwk-green-dark-bg-subtle);
  --ct-btn-border-color: transparent;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: var(--ct-lwk-green-dark);
  --ct-btn-hover-border-color: var(--ct-lwk-green-dark);
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: var(--ct-lwk-green-dark);
  --ct-btn-active-border-color: var(--ct-lwk-green-dark);
  --ct-btn-disabled-color: var(--ct-lwk-green-dark);
  --ct-btn-disabled-bg: var(--ct-lwk-green-dark);
  --ct-btn-disabled-border-color: var(--ct-lwk-green-dark);
  --ct-btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--ct-lwk-green-dark)-rgb, 0.5);
}

.btn-lwk-green-light {
  box-shadow: var(--ct-btn-box-shadow) rgba(var(--ct-lwk-green-light-rgb), 0.5);
}

.btn-soft-lwk-green-light {
  --ct-btn-color: var(--ct-lwk-green-light);
  --ct-btn-bg: var(--ct-lwk-green-light-bg-subtle);
  --ct-btn-border-color: transparent;
  --ct-btn-hover-color: #fff;
  --ct-btn-hover-bg: var(--ct-lwk-green-light);
  --ct-btn-hover-border-color: var(--ct-lwk-green-light);
  --ct-btn-active-color: #fff;
  --ct-btn-active-bg: var(--ct-lwk-green-light);
  --ct-btn-active-border-color: var(--ct-lwk-green-light);
  --ct-btn-disabled-color: var(--ct-lwk-green-light);
  --ct-btn-disabled-bg: var(--ct-lwk-green-light);
  --ct-btn-disabled-border-color: var(--ct-lwk-green-light);
  --ct-btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--ct-lwk-green-light)-rgb, 0.5);
}

.btn-light {
  --ct-btn-color: var(--ct-dark);
  --ct-btn-bg: var(--ct-light);
  --ct-btn-border-color: transparent;
  --ct-btn-hover-color: var(--ct-tertiary-color);
  --ct-btn-hover-bg: var(--ct-tertiary-bg);
  --ct-btn-hover-border-color: var(--ct-tertiary-bg);
  --ct-btn-active-bg: var(--ct-tertiary-bg);
  --ct-btn-active-color: var(--ct-tertiary-color);
  --ct-btn-active-border-color: var(--ct-tertiary-bg);
  --ct-btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--ct-light-rgb), 0.5);
}

.btn-dark {
  --ct-btn-color: var(--ct-light);
  --ct-btn-bg: var(--ct-dark);
  --ct-btn-border-color: transparent;
  --ct-btn-hover-color: var(--ct-light);
  --ct-btn-hover-bg: var(--ct-dark);
  --ct-btn-hover-border-color: var(--ct-dark);
  --ct-btn-active-bg: var(--ct-dark);
  --ct-btn-active-color: var(--ct-light);
  --ct-btn-active-border-color: var(--ct-dark);
  --ct-btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--ct-dark-rgb), 0.5);
}

.btn-outline-light {
  --ct-btn-color: var(--ct-dark);
  --ct-btn-bg: transparent;
  --ct-btn-border-color: var(--ct-light);
  --ct-btn-hover-color: var(--ct-tertiary-color);
  --ct-btn-hover-bg: var(--ct-tertiary-bg);
  --ct-btn-hover-border-color: var(--ct-tertiary-bg);
  --ct-btn-active-bg: var(--ct-tertiary-bg);
  --ct-btn-active-color: var(--ct-tertiary-color);
  --ct-btn-active-border-color: var(--ct-tertiary-bg);
  --ct-btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--ct-light-rgb), 0.5);
}

.btn-outline-dark {
  --ct-btn-color: var(--ct-dark);
  --ct-btn-bg: transparent;
  --ct-btn-border-color: var(--ct-dark);
  --ct-btn-hover-color: var(--ct-light);
  --ct-btn-hover-bg: var(--ct-dark);
  --ct-btn-hover-border-color: var(--ct-dark);
  --ct-btn-active-bg: var(--ct-dark);
  --ct-btn-active-color: var(--ct-light);
  --ct-btn-active-border-color: var(--ct-dark);
  --ct-btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--ct-dark-rgb), 0.5);
}

.btn-soft-dark {
  --ct-btn-hover-color: var(--ct-light);
  --ct-btn-active-color: var(--ct-light);
}

[data-bs-theme=dark] .btn-secondary {
  box-shadow: var(--ct-btn-box-shadow) rgba(var(--ct-light-rgb), 0.5);
}

.badge-primary-lighten {
  --ct-badge-color: var(--ct-primary);
  background-color: var(--ct-primary-bg-subtle);
}
.badge-primary-lighten[href] {
  color: var(--ct-primary);
  text-decoration: none;
  background-color: var(--ct-primary-bg-subtle);
}
.badge-primary-lighten[href]:is(:hover, :focus) {
  color: var(--ct-primary);
  text-decoration: none;
  background-color: var(--ct-primary-bg-subtle);
}

.badge-secondary-lighten {
  --ct-badge-color: var(--ct-secondary);
  background-color: var(--ct-secondary-bg-subtle);
}
.badge-secondary-lighten[href] {
  color: var(--ct-secondary);
  text-decoration: none;
  background-color: var(--ct-secondary-bg-subtle);
}
.badge-secondary-lighten[href]:is(:hover, :focus) {
  color: var(--ct-secondary);
  text-decoration: none;
  background-color: var(--ct-secondary-bg-subtle);
}

.badge-success-lighten {
  --ct-badge-color: var(--ct-success);
  background-color: var(--ct-success-bg-subtle);
}
.badge-success-lighten[href] {
  color: var(--ct-success);
  text-decoration: none;
  background-color: var(--ct-success-bg-subtle);
}
.badge-success-lighten[href]:is(:hover, :focus) {
  color: var(--ct-success);
  text-decoration: none;
  background-color: var(--ct-success-bg-subtle);
}

.badge-info-lighten {
  --ct-badge-color: var(--ct-info);
  background-color: var(--ct-info-bg-subtle);
}
.badge-info-lighten[href] {
  color: var(--ct-info);
  text-decoration: none;
  background-color: var(--ct-info-bg-subtle);
}
.badge-info-lighten[href]:is(:hover, :focus) {
  color: var(--ct-info);
  text-decoration: none;
  background-color: var(--ct-info-bg-subtle);
}

.badge-warning-lighten {
  --ct-badge-color: var(--ct-warning);
  background-color: var(--ct-warning-bg-subtle);
}
.badge-warning-lighten[href] {
  color: var(--ct-warning);
  text-decoration: none;
  background-color: var(--ct-warning-bg-subtle);
}
.badge-warning-lighten[href]:is(:hover, :focus) {
  color: var(--ct-warning);
  text-decoration: none;
  background-color: var(--ct-warning-bg-subtle);
}

.badge-danger-lighten {
  --ct-badge-color: var(--ct-danger);
  background-color: var(--ct-danger-bg-subtle);
}
.badge-danger-lighten[href] {
  color: var(--ct-danger);
  text-decoration: none;
  background-color: var(--ct-danger-bg-subtle);
}
.badge-danger-lighten[href]:is(:hover, :focus) {
  color: var(--ct-danger);
  text-decoration: none;
  background-color: var(--ct-danger-bg-subtle);
}

.badge-light-lighten {
  --ct-badge-color: var(--ct-light);
  background-color: var(--ct-light-bg-subtle);
}
.badge-light-lighten[href] {
  color: var(--ct-light);
  text-decoration: none;
  background-color: var(--ct-light-bg-subtle);
}
.badge-light-lighten[href]:is(:hover, :focus) {
  color: var(--ct-light);
  text-decoration: none;
  background-color: var(--ct-light-bg-subtle);
}

.badge-dark-lighten {
  --ct-badge-color: var(--ct-dark);
  background-color: var(--ct-dark-bg-subtle);
}
.badge-dark-lighten[href] {
  color: var(--ct-dark);
  text-decoration: none;
  background-color: var(--ct-dark-bg-subtle);
}
.badge-dark-lighten[href]:is(:hover, :focus) {
  color: var(--ct-dark);
  text-decoration: none;
  background-color: var(--ct-dark-bg-subtle);
}

.badge-lwk-green-dark-lighten {
  --ct-badge-color: var(--ct-lwk-green-dark);
  background-color: var(--ct-lwk-green-dark-bg-subtle);
}
.badge-lwk-green-dark-lighten[href] {
  color: var(--ct-lwk-green-dark);
  text-decoration: none;
  background-color: var(--ct-lwk-green-dark-bg-subtle);
}
.badge-lwk-green-dark-lighten[href]:is(:hover, :focus) {
  color: var(--ct-lwk-green-dark);
  text-decoration: none;
  background-color: var(--ct-lwk-green-dark-bg-subtle);
}

.badge-lwk-green-light-lighten {
  --ct-badge-color: var(--ct-lwk-green-light);
  background-color: var(--ct-lwk-green-light-bg-subtle);
}
.badge-lwk-green-light-lighten[href] {
  color: var(--ct-lwk-green-light);
  text-decoration: none;
  background-color: var(--ct-lwk-green-light-bg-subtle);
}
.badge-lwk-green-light-lighten[href]:is(:hover, :focus) {
  color: var(--ct-lwk-green-light);
  text-decoration: none;
  background-color: var(--ct-lwk-green-light-bg-subtle);
}

.badge-outline-primary {
  --ct-badge-color: var(--ct-primary);
  border: 1px solid var(--ct-primary);
  background-color: transparent;
}
.badge-outline-primary[href] {
  color: var(--ct-primary);
  text-decoration: none;
  background-color: var(--ct-primary-bg-subtle);
}
.badge-outline-primary[href]:is(:hover, :focus) {
  color: var(--ct-primary);
  text-decoration: none;
  background-color: var(--ct-primary-bg-subtle);
}

.badge-outline-secondary {
  --ct-badge-color: var(--ct-secondary);
  border: 1px solid var(--ct-secondary);
  background-color: transparent;
}
.badge-outline-secondary[href] {
  color: var(--ct-secondary);
  text-decoration: none;
  background-color: var(--ct-secondary-bg-subtle);
}
.badge-outline-secondary[href]:is(:hover, :focus) {
  color: var(--ct-secondary);
  text-decoration: none;
  background-color: var(--ct-secondary-bg-subtle);
}

.badge-outline-success {
  --ct-badge-color: var(--ct-success);
  border: 1px solid var(--ct-success);
  background-color: transparent;
}
.badge-outline-success[href] {
  color: var(--ct-success);
  text-decoration: none;
  background-color: var(--ct-success-bg-subtle);
}
.badge-outline-success[href]:is(:hover, :focus) {
  color: var(--ct-success);
  text-decoration: none;
  background-color: var(--ct-success-bg-subtle);
}

.badge-outline-info {
  --ct-badge-color: var(--ct-info);
  border: 1px solid var(--ct-info);
  background-color: transparent;
}
.badge-outline-info[href] {
  color: var(--ct-info);
  text-decoration: none;
  background-color: var(--ct-info-bg-subtle);
}
.badge-outline-info[href]:is(:hover, :focus) {
  color: var(--ct-info);
  text-decoration: none;
  background-color: var(--ct-info-bg-subtle);
}

.badge-outline-warning {
  --ct-badge-color: var(--ct-warning);
  border: 1px solid var(--ct-warning);
  background-color: transparent;
}
.badge-outline-warning[href] {
  color: var(--ct-warning);
  text-decoration: none;
  background-color: var(--ct-warning-bg-subtle);
}
.badge-outline-warning[href]:is(:hover, :focus) {
  color: var(--ct-warning);
  text-decoration: none;
  background-color: var(--ct-warning-bg-subtle);
}

.badge-outline-danger {
  --ct-badge-color: var(--ct-danger);
  border: 1px solid var(--ct-danger);
  background-color: transparent;
}
.badge-outline-danger[href] {
  color: var(--ct-danger);
  text-decoration: none;
  background-color: var(--ct-danger-bg-subtle);
}
.badge-outline-danger[href]:is(:hover, :focus) {
  color: var(--ct-danger);
  text-decoration: none;
  background-color: var(--ct-danger-bg-subtle);
}

.badge-outline-light {
  --ct-badge-color: var(--ct-light);
  border: 1px solid var(--ct-light);
  background-color: transparent;
}
.badge-outline-light[href] {
  color: var(--ct-light);
  text-decoration: none;
  background-color: var(--ct-light-bg-subtle);
}
.badge-outline-light[href]:is(:hover, :focus) {
  color: var(--ct-light);
  text-decoration: none;
  background-color: var(--ct-light-bg-subtle);
}

.badge-outline-dark {
  --ct-badge-color: var(--ct-dark);
  border: 1px solid var(--ct-dark);
  background-color: transparent;
}
.badge-outline-dark[href] {
  color: var(--ct-dark);
  text-decoration: none;
  background-color: var(--ct-dark-bg-subtle);
}
.badge-outline-dark[href]:is(:hover, :focus) {
  color: var(--ct-dark);
  text-decoration: none;
  background-color: var(--ct-dark-bg-subtle);
}

.badge-outline-lwk-green-dark {
  --ct-badge-color: var(--ct-lwk-green-dark);
  border: 1px solid var(--ct-lwk-green-dark);
  background-color: transparent;
}
.badge-outline-lwk-green-dark[href] {
  color: var(--ct-lwk-green-dark);
  text-decoration: none;
  background-color: var(--ct-lwk-green-dark-bg-subtle);
}
.badge-outline-lwk-green-dark[href]:is(:hover, :focus) {
  color: var(--ct-lwk-green-dark);
  text-decoration: none;
  background-color: var(--ct-lwk-green-dark-bg-subtle);
}

.badge-outline-lwk-green-light {
  --ct-badge-color: var(--ct-lwk-green-light);
  border: 1px solid var(--ct-lwk-green-light);
  background-color: transparent;
}
.badge-outline-lwk-green-light[href] {
  color: var(--ct-lwk-green-light);
  text-decoration: none;
  background-color: var(--ct-lwk-green-light-bg-subtle);
}
.badge-outline-lwk-green-light[href]:is(:hover, :focus) {
  color: var(--ct-lwk-green-light);
  text-decoration: none;
  background-color: var(--ct-lwk-green-light-bg-subtle);
}

.badge {
  vertical-align: middle;
}
.badge.badge-lg {
  padding: 0.5em 1em;
  font-size: 0.85em;
}

.card {
  box-shadow: var(--ct-box-shadow);
  margin-bottom: 1.5rem;
}
.card .header-title {
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-size: 0.9rem;
  margin-top: 0;
}
.card .card-drop {
  font-size: 20px;
  color: inherit;
}
.card .card-widgets {
  float: right;
  height: 16px;
}
.card .card-widgets > a {
  color: inherit;
  font-size: 18px;
  display: inline-block;
  line-height: 1;
}
.card .card-widgets > a.collapsed i:before {
  content: "\f0415";
}

.card-title,
.card-header {
  margin-top: 0;
}
.card-title .header-title,
.card-header .header-title {
  margin-bottom: 0;
}

.card-disabled {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: var(--ct-border-radius);
  background: var(--ct-tertiary-bg);
  opacity: 0.8;
  cursor: progress;
}
.card-disabled .card-portlets-loader {
  background-color: var(--ct-dark);
  animation: rotatebox 1.2s infinite ease-in-out;
  height: 30px;
  width: 30px;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -0.75rem;
  margin-top: -0.75rem;
}

@keyframes rotatebox {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
.card-pricing {
  position: relative;
}
.card-pricing .card-pricing-plan-name {
  padding-bottom: 20px;
}
.card-pricing .card-pricing-icon {
  font-size: 22px;
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  height: 60px;
  display: inline-block;
  width: 60px;
  line-height: 60px;
  border-radius: 50%;
}
.card-pricing .card-pricing-price {
  padding: 30px 0 0;
}
.card-pricing .card-pricing-price span {
  font-size: 40%;
  color: var(--ct-gray-600);
  letter-spacing: 2px;
  text-transform: uppercase;
}
.card-pricing .card-pricing-features {
  color: var(--ct-gray-600);
  list-style: none;
  margin: 0;
  padding: 20px 0 0 0;
}
.card-pricing .card-pricing-features li {
  padding: 15px;
}

@media (min-width: 992px) {
  .card-pricing-recommended {
    margin-top: -1.9375rem;
  }
}
.card-pricing-recommended .card-pricing-plan-tag {
  background-color: rgba(250, 92, 124, 0.2);
  color: #fa5c7c;
  padding: 5px 0;
  font-weight: 700;
  border-radius: 0.25rem 0.25rem 0 0;
  margin: -1.5rem -1.5rem 1.5rem -1.5rem;
}

.card-h-100 {
  height: calc(100% - 1.5rem);
}

.form-checkbox-primary .form-check-input:checked, .form-radio-primary .form-check-input:checked {
  background-color: var(--ct-primary);
  border-color: var(--ct-primary);
}

.form-checkbox-secondary .form-check-input:checked, .form-radio-secondary .form-check-input:checked {
  background-color: var(--ct-secondary);
  border-color: var(--ct-secondary);
}

.form-checkbox-success .form-check-input:checked, .form-radio-success .form-check-input:checked {
  background-color: var(--ct-success);
  border-color: var(--ct-success);
}

.form-checkbox-info .form-check-input:checked, .form-radio-info .form-check-input:checked {
  background-color: var(--ct-info);
  border-color: var(--ct-info);
}

.form-checkbox-warning .form-check-input:checked, .form-radio-warning .form-check-input:checked {
  background-color: var(--ct-warning);
  border-color: var(--ct-warning);
}

.form-checkbox-danger .form-check-input:checked, .form-radio-danger .form-check-input:checked {
  background-color: var(--ct-danger);
  border-color: var(--ct-danger);
}

.form-checkbox-light .form-check-input:checked, .form-radio-light .form-check-input:checked {
  background-color: var(--ct-light);
  border-color: var(--ct-light);
}

.form-checkbox-dark .form-check-input:checked, .form-radio-dark .form-check-input:checked {
  background-color: var(--ct-dark);
  border-color: var(--ct-dark);
}

.form-checkbox-lwk-green-dark .form-check-input:checked, .form-radio-lwk-green-dark .form-check-input:checked {
  background-color: var(--ct-lwk-green-dark);
  border-color: var(--ct-lwk-green-dark);
}

.form-checkbox-lwk-green-light .form-check-input:checked, .form-radio-lwk-green-light .form-check-input:checked {
  background-color: var(--ct-lwk-green-light);
  border-color: var(--ct-lwk-green-light);
}

.card-radio {
  padding: 0;
}
.card-radio .form-check-label {
  background-color: var(--ct-secondary-bg);
  border: 1px solid var(--ct-border-color);
  border-radius: 0.25rem;
  padding: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  position: relative;
  padding-right: 32px;
}
.card-radio .form-check-label:hover {
  cursor: pointer;
}
.card-radio .form-check-input {
  display: none;
}
.card-radio .form-check-input:checked + .form-check-label:before {
  content: "\f05e0";
  font-family: "Material Design Icons";
  position: absolute;
  bottom: 2px;
  right: 6px;
  font-size: 16px;
  color: var(--ct-primary);
}
.card-radio.dark .form-check-input:checked + .form-check-label:before {
  color: #fff;
}

.dropdown-menu {
  box-shadow: var(--ct-dropdown-box-shadow);
}

.dropdown-header {
  margin-top: 0;
}

.dropdown-menu-animated.dropdown-menu-end[style] {
  left: auto;
  right: 0;
}

.dropdown-menu-animated {
  animation-name: DropDownSlide;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  position: absolute;
  margin: 0;
  z-index: 1000;
}
.dropdown-menu-animated.show {
  top: 100% !important;
}
.dropdown-menu-animated i {
  display: inline-block;
}
.dropdown-menu-animated.dropdown-menu[data-popper-placement^=right], .dropdown-menu-animated.dropdown-menu[data-popper-placement^=top], .dropdown-menu-animated.dropdown-menu[data-popper-placement^=left] {
  top: auto !important;
  animation: none !important;
}

@keyframes DropDownSlide {
  100% {
    transform: translateY(0);
  }
  0% {
    transform: translateY(10px);
  }
}
@media (min-width: 576px) {
  .dropdown-lg {
    width: 320px;
  }
}
.dropdown-icon-item {
  display: block;
  border-radius: 3px;
  line-height: 34px;
  text-align: center;
  padding: 15px 0 9px;
  display: block;
  border: 1px solid transparent;
  color: var(--ct-body-color);
}
.dropdown-icon-item img {
  height: 24px;
}
.dropdown-icon-item span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dropdown-icon-item:hover {
  background-color: var(--ct-tertiary-bg);
  color: var(--ct-tertiary-color);
}

.arrow-none:after {
  display: none;
}

.dropdown-toggle-split::before {
  content: "";
  position: absolute;
  background-color: rgba(49, 58, 70, 0.08);
  top: -1px;
  bottom: -1px;
  right: -1px;
  left: 0;
  border-radius: 0 0.15rem 0.15rem 0;
}

.dropstart .dropdown-toggle-split::before {
  inset: auto;
  border-radius: 0;
  position: relative;
  z-index: 1;
}
.dropstart .dropdown-toggle-split::after {
  content: "";
  display: inline-block;
  position: absolute;
  background-color: rgba(49, 58, 70, 0.1);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 0.15rem 0 0 0.15rem;
  border: none;
  margin-left: 0;
}

.hljs {
  display: block;
  padding: 0 1em;
  color: var(--ct-body-color);
  max-height: 420px;
  margin: -10px 0 -30px;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-subst {
  color: var(--ct-body-color);
  font-weight: bold;
}

.hljs-number,
.hljs-literal,
.hljs-variable,
.hljs-template-variable,
.hljs-tag .hljs-attr {
  color: #02a8b5;
}

.hljs-string,
.hljs-doctag {
  color: #fa5c7c;
}

.hljs-title,
.hljs-section,
.hljs-selector-id {
  color: #fa5c7c;
  font-weight: bold;
}

.hljs-subst {
  font-weight: normal;
}

.hljs-tag,
.hljs-name,
.hljs-attribute {
  color: var(--ct-primary);
  font-weight: normal;
}

.hljs-regexp,
.hljs-link {
  color: #0acf97;
}

.hljs-symbol,
.hljs-bullet {
  color: #ff679b;
}

.hljs-built_in,
.hljs-builtin-name {
  color: #39afd1;
}

.hljs-meta {
  color: var(--ct-gray-800);
  font-weight: bold;
}

.hljs-deletion {
  background: #fdd;
}

.hljs-addition {
  background: #dfd;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

.hljs-comment {
  color: var(--ct-gray-400);
}

.tab-pane.code {
  border: 1px solid var(--ct-border-color);
  border-radius: 0.25rem;
  position: relative;
}
.tab-pane.code pre::-webkit-scrollbar {
  -webkit-appearance: none;
}
.tab-pane.code pre::-webkit-scrollbar:vertical {
  width: 5px;
  margin-right: 5px;
}
.tab-pane.code pre::-webkit-scrollbar:horizontal {
  height: 5px;
}
.tab-pane.code pre::-webkit-scrollbar-thumb {
  background-color: rgba(var(--ct-dark-rgb), 0.2);
  border-radius: 10px;
  margin-right: 5px;
  border: none;
}
.tab-pane.code pre::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}
.tab-pane.code pre::-webkit-scrollbar-corner {
  background-color: transparent;
}
.tab-pane.code .btn-copy-clipboard {
  position: absolute;
  right: 8px;
  top: 10px;
  background-color: var(--ct-secondary-bg);
  border: 1px solid #0acf97;
  color: #0acf97;
  padding: 0.28rem 0.8rem;
  font-size: 0.875rem;
  border-radius: 0.15rem;
  font-weight: 400;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
.tab-pane.code .btn-copy-clipboard:hover, .tab-pane.code .btn-copy-clipboard:focus, .tab-pane.code .btn-copy-clipboard:active {
  background-color: #0acf97;
  color: #fff;
}

.form-control-light {
  background-color: var(--ct-light) !important;
  border-color: var(--ct-border-color) !important;
  border-width: var(--ct-theme-card-border-width);
  border-style: solid;
  border-color: var(--ct-border-color);
}

input.form-control[type=color],
input.form-control[type=range] {
  min-height: 39px;
}

.custom-select.is-invalid:focus,
.form-control.is-invalid:focus,
.custom-select:invalid:focus,
.form-control:invalid:focus,
.custom-select.is-valid:focus,
.form-control.is-valid:focus,
.custom-select:valid:focus,
.form-control:valid:focus {
  box-shadow: none !important;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(1.5em + 0.9rem + calc(var(--ct-border-width) * 2));
}

select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.5em + 0.56rem + calc(var(--ct-border-width) * 2));
}

.password-eye {
  cursor: pointer;
}
.password-eye:before {
  font-family: "Material Design Icons";
  content: "\f06d0";
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  vertical-align: middle;
  line-height: 1.2;
  font-size: 16px;
}

.show-password .password-eye:before {
  content: "\f06d1";
}

.modal-title {
  margin-top: 0;
  color: inherit;
}

.modal-full-width {
  width: 95%;
  max-width: none;
}

.modal-top {
  margin: 0 auto;
}

.modal-right {
  position: absolute;
  right: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  height: 100%;
  margin: 0;
  background-color: var(--ct-secondary-bg);
  align-content: center;
  transform: translate(25%, 0) !important;
}
.modal-right button.btn-close {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1;
}

.modal.show .modal-right,
.modal.show .modal-left {
  transform: translate(0, 0) !important;
}

.modal-bottom {
  display: flex;
  flex-flow: column nowrap;
  -ms-flex-pack: end;
  justify-content: flex-end;
  height: 100%;
  margin: 0 auto;
  align-content: center;
}

.modal-filled {
  color: #fff;
}
.modal-filled .modal-header {
  background-color: rgba(255, 255, 255, 0.07);
}
.modal-filled .modal-title {
  color: #fff;
}
.modal-filled .modal-header,
.modal-filled .modal-footer {
  border: none;
}

.nav-tabs > li > a,
.nav-pills > li > a {
  color: var(--ct-secondary-color);
  font-weight: 600;
}

.nav-pills > a {
  color: var(--ct-gray-700);
  font-weight: 600;
}

.bg-nav-pills {
  background-color: var(--ct-tertiary-bg);
}

.nav-tabs.nav-bordered {
  border-bottom: 2px solid var(--ct-border-color);
}
.nav-tabs.nav-bordered .nav-item {
  margin-bottom: -1px;
}
.nav-tabs.nav-bordered li a {
  border: 0;
  padding: 0.625rem 1.25rem;
}
.nav-tabs.nav-bordered li a.active {
  border-bottom: 2px solid var(--ct-primary);
}

.pagination-rounded .page-link {
  border-radius: 30px !important;
  margin: 0 3px !important;
  border: none;
}

.popover-header {
  margin-top: 0;
}

.primary-popover {
  --ct-popover-max-width: 200px !important;
  --ct-popover-border-color: var(--ct-primary) !important;
  --ct-popover-header-bg: var(--ct-primary) !important;
  --ct-popover-header-color: var(--ct-white) !important;
  --ct-popover-body-padding-x: 1rem !important;
  --ct-popover-body-padding-y: .5rem !important;
}

.secondary-popover {
  --ct-popover-max-width: 200px !important;
  --ct-popover-border-color: var(--ct-secondary) !important;
  --ct-popover-header-bg: var(--ct-secondary) !important;
  --ct-popover-header-color: var(--ct-white) !important;
  --ct-popover-body-padding-x: 1rem !important;
  --ct-popover-body-padding-y: .5rem !important;
}

.success-popover {
  --ct-popover-max-width: 200px !important;
  --ct-popover-border-color: var(--ct-success) !important;
  --ct-popover-header-bg: var(--ct-success) !important;
  --ct-popover-header-color: var(--ct-white) !important;
  --ct-popover-body-padding-x: 1rem !important;
  --ct-popover-body-padding-y: .5rem !important;
}

.info-popover {
  --ct-popover-max-width: 200px !important;
  --ct-popover-border-color: var(--ct-info) !important;
  --ct-popover-header-bg: var(--ct-info) !important;
  --ct-popover-header-color: var(--ct-white) !important;
  --ct-popover-body-padding-x: 1rem !important;
  --ct-popover-body-padding-y: .5rem !important;
}

.warning-popover {
  --ct-popover-max-width: 200px !important;
  --ct-popover-border-color: var(--ct-warning) !important;
  --ct-popover-header-bg: var(--ct-warning) !important;
  --ct-popover-header-color: var(--ct-white) !important;
  --ct-popover-body-padding-x: 1rem !important;
  --ct-popover-body-padding-y: .5rem !important;
}

.danger-popover {
  --ct-popover-max-width: 200px !important;
  --ct-popover-border-color: var(--ct-danger) !important;
  --ct-popover-header-bg: var(--ct-danger) !important;
  --ct-popover-header-color: var(--ct-white) !important;
  --ct-popover-body-padding-x: 1rem !important;
  --ct-popover-body-padding-y: .5rem !important;
}

.light-popover {
  --ct-popover-max-width: 200px !important;
  --ct-popover-border-color: var(--ct-light) !important;
  --ct-popover-header-bg: var(--ct-light) !important;
  --ct-popover-header-color: var(--ct-white) !important;
  --ct-popover-body-padding-x: 1rem !important;
  --ct-popover-body-padding-y: .5rem !important;
}

.dark-popover {
  --ct-popover-max-width: 200px !important;
  --ct-popover-border-color: var(--ct-dark) !important;
  --ct-popover-header-bg: var(--ct-dark) !important;
  --ct-popover-header-color: var(--ct-white) !important;
  --ct-popover-body-padding-x: 1rem !important;
  --ct-popover-body-padding-y: .5rem !important;
}

.lwk-green-dark-popover {
  --ct-popover-max-width: 200px !important;
  --ct-popover-border-color: var(--ct-lwk-green-dark) !important;
  --ct-popover-header-bg: var(--ct-lwk-green-dark) !important;
  --ct-popover-header-color: var(--ct-white) !important;
  --ct-popover-body-padding-x: 1rem !important;
  --ct-popover-body-padding-y: .5rem !important;
}

.lwk-green-light-popover {
  --ct-popover-max-width: 200px !important;
  --ct-popover-border-color: var(--ct-lwk-green-light) !important;
  --ct-popover-header-bg: var(--ct-lwk-green-light) !important;
  --ct-popover-header-color: var(--ct-white) !important;
  --ct-popover-body-padding-x: 1rem !important;
  --ct-popover-body-padding-y: .5rem !important;
}

@media print {
  .leftside-menu,
  .end-bar,
  .page-title-box,
  .navbar-custom,
  .footer {
    display: none;
  }
  .card-body,
  .content-page,
  .end-bar,
  .content,
  body {
    padding: 0;
    margin: 0;
  }
  .card {
    box-shadow: none;
  }
}
.progress-sm {
  height: 5px;
}

.progress-md {
  height: 8px;
}

.progress-lg {
  height: 12px;
}

.progress-xl {
  height: 15px;
}

html {
  position: relative;
  min-height: 100%;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 10px;
}

@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer;
  }
}
body {
  overflow-x: hidden;
}

button,
a {
  outline: none !important;
}

label {
  font-weight: 600;
}

address.address-lg {
  line-height: 24px;
}

b,
strong {
  font-weight: 700;
}

.ribbon-box {
  position: relative;
  /* Ribbon two */
}
.ribbon-box .ribbon {
  position: relative;
  clear: both;
  padding: 5px 12px;
  margin-bottom: 15px;
  box-shadow: 2px 5px 10px rgba(49, 58, 70, 0.15);
  color: #fff;
  font-size: 13px;
  font-weight: 600;
}
.ribbon-box .ribbon:before {
  content: " ";
  border-style: solid;
  border-width: 10px;
  display: block;
  position: absolute;
  bottom: -10px;
  left: 0;
  margin-bottom: -10px;
  z-index: -1;
}
.ribbon-box .ribbon.float-start {
  margin-left: -30px;
  border-radius: 0 3px 3px 0;
}
.ribbon-box .ribbon.float-end {
  margin-right: -30px;
  border-radius: 3px 0 0 3px;
}
.ribbon-box .ribbon.float-end:before {
  right: 0;
}
.ribbon-box .ribbon.float-center span {
  margin: 0 auto 20px auto;
}
.ribbon-box .ribbon-content {
  clear: both;
}
.ribbon-box .ribbon-primary {
  background: var(--ct-primary);
}
.ribbon-box .ribbon-primary:before {
  border-color: var(--ct-primary-text) transparent transparent;
}
.ribbon-box .ribbon-secondary {
  background: var(--ct-secondary);
}
.ribbon-box .ribbon-secondary:before {
  border-color: var(--ct-secondary-text) transparent transparent;
}
.ribbon-box .ribbon-success {
  background: var(--ct-success);
}
.ribbon-box .ribbon-success:before {
  border-color: var(--ct-success-text) transparent transparent;
}
.ribbon-box .ribbon-info {
  background: var(--ct-info);
}
.ribbon-box .ribbon-info:before {
  border-color: var(--ct-info-text) transparent transparent;
}
.ribbon-box .ribbon-warning {
  background: var(--ct-warning);
}
.ribbon-box .ribbon-warning:before {
  border-color: var(--ct-warning-text) transparent transparent;
}
.ribbon-box .ribbon-danger {
  background: var(--ct-danger);
}
.ribbon-box .ribbon-danger:before {
  border-color: var(--ct-danger-text) transparent transparent;
}
.ribbon-box .ribbon-light {
  background: var(--ct-light);
}
.ribbon-box .ribbon-light:before {
  border-color: var(--ct-light-text) transparent transparent;
}
.ribbon-box .ribbon-dark {
  background: var(--ct-dark);
}
.ribbon-box .ribbon-dark:before {
  border-color: var(--ct-dark-text) transparent transparent;
}
.ribbon-box .ribbon-lwk-green-dark {
  background: var(--ct-lwk-green-dark);
}
.ribbon-box .ribbon-lwk-green-dark:before {
  border-color: var(--ct-lwk-green-dark-text) transparent transparent;
}
.ribbon-box .ribbon-lwk-green-light {
  background: var(--ct-lwk-green-light);
}
.ribbon-box .ribbon-lwk-green-light:before {
  border-color: var(--ct-lwk-green-light-text) transparent transparent;
}
.ribbon-box .ribbon-two {
  position: absolute;
  left: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}
.ribbon-box .ribbon-two span {
  font-size: 13px;
  color: #fff;
  text-align: center;
  line-height: 20px;
  transform: rotate(-45deg);
  width: 100px;
  display: block;
  box-shadow: 0 0 8px 0 rgba(var(--ct-dark-rgb), 0.08), 0 1px 0 0 rgba(var(--ct-dark-rgb), 0.03);
  position: absolute;
  top: 19px;
  left: -21px;
  font-weight: 600;
}
.ribbon-box .ribbon-two span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  z-index: -1;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
}
.ribbon-box .ribbon-two span:after {
  content: "";
  position: absolute;
  right: 0;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent;
}
.ribbon-box .ribbon-two-primary span {
  background: var(--ct-primary);
}
.ribbon-box .ribbon-two-primary span:before {
  border-left: 3px solid var(--ct-primary-text);
  border-top: 3px solid var(--ct-primary-text);
}
.ribbon-box .ribbon-two-primary span:after {
  border-right: 3px solid var(--ct-primary-text);
  border-top: 3px solid var(--ct-primary-text);
}
.ribbon-box .ribbon-two-secondary span {
  background: var(--ct-secondary);
}
.ribbon-box .ribbon-two-secondary span:before {
  border-left: 3px solid var(--ct-secondary-text);
  border-top: 3px solid var(--ct-secondary-text);
}
.ribbon-box .ribbon-two-secondary span:after {
  border-right: 3px solid var(--ct-secondary-text);
  border-top: 3px solid var(--ct-secondary-text);
}
.ribbon-box .ribbon-two-success span {
  background: var(--ct-success);
}
.ribbon-box .ribbon-two-success span:before {
  border-left: 3px solid var(--ct-success-text);
  border-top: 3px solid var(--ct-success-text);
}
.ribbon-box .ribbon-two-success span:after {
  border-right: 3px solid var(--ct-success-text);
  border-top: 3px solid var(--ct-success-text);
}
.ribbon-box .ribbon-two-info span {
  background: var(--ct-info);
}
.ribbon-box .ribbon-two-info span:before {
  border-left: 3px solid var(--ct-info-text);
  border-top: 3px solid var(--ct-info-text);
}
.ribbon-box .ribbon-two-info span:after {
  border-right: 3px solid var(--ct-info-text);
  border-top: 3px solid var(--ct-info-text);
}
.ribbon-box .ribbon-two-warning span {
  background: var(--ct-warning);
}
.ribbon-box .ribbon-two-warning span:before {
  border-left: 3px solid var(--ct-warning-text);
  border-top: 3px solid var(--ct-warning-text);
}
.ribbon-box .ribbon-two-warning span:after {
  border-right: 3px solid var(--ct-warning-text);
  border-top: 3px solid var(--ct-warning-text);
}
.ribbon-box .ribbon-two-danger span {
  background: var(--ct-danger);
}
.ribbon-box .ribbon-two-danger span:before {
  border-left: 3px solid var(--ct-danger-text);
  border-top: 3px solid var(--ct-danger-text);
}
.ribbon-box .ribbon-two-danger span:after {
  border-right: 3px solid var(--ct-danger-text);
  border-top: 3px solid var(--ct-danger-text);
}
.ribbon-box .ribbon-two-light span {
  background: var(--ct-light);
}
.ribbon-box .ribbon-two-light span:before {
  border-left: 3px solid var(--ct-light-text);
  border-top: 3px solid var(--ct-light-text);
}
.ribbon-box .ribbon-two-light span:after {
  border-right: 3px solid var(--ct-light-text);
  border-top: 3px solid var(--ct-light-text);
}
.ribbon-box .ribbon-two-dark span {
  background: var(--ct-dark);
}
.ribbon-box .ribbon-two-dark span:before {
  border-left: 3px solid var(--ct-dark-text);
  border-top: 3px solid var(--ct-dark-text);
}
.ribbon-box .ribbon-two-dark span:after {
  border-right: 3px solid var(--ct-dark-text);
  border-top: 3px solid var(--ct-dark-text);
}
.ribbon-box .ribbon-two-lwk-green-dark span {
  background: var(--ct-lwk-green-dark);
}
.ribbon-box .ribbon-two-lwk-green-dark span:before {
  border-left: 3px solid var(--ct-lwk-green-dark-text);
  border-top: 3px solid var(--ct-lwk-green-dark-text);
}
.ribbon-box .ribbon-two-lwk-green-dark span:after {
  border-right: 3px solid var(--ct-lwk-green-dark-text);
  border-top: 3px solid var(--ct-lwk-green-dark-text);
}
.ribbon-box .ribbon-two-lwk-green-light span {
  background: var(--ct-lwk-green-light);
}
.ribbon-box .ribbon-two-lwk-green-light span:before {
  border-left: 3px solid var(--ct-lwk-green-light-text);
  border-top: 3px solid var(--ct-lwk-green-light-text);
}
.ribbon-box .ribbon-two-lwk-green-light span:after {
  border-right: 3px solid var(--ct-lwk-green-light-text);
  border-top: 3px solid var(--ct-lwk-green-light-text);
}

input[data-switch] {
  display: none;
}
input[data-switch] + label {
  width: 56px;
  height: 24px;
  background-color: var(--ct-tertiary-bg);
  background-image: none;
  border-radius: 2rem;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  position: relative;
  transition: all 0.1s ease-in-out;
}
input[data-switch] + label:before {
  color: var(--ct-emphasis-color);
  content: attr(data-off-label);
  display: block;
  font-family: inherit;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 24px;
  position: absolute;
  right: 3px;
  margin: 0 0.21667rem;
  top: 0;
  text-align: center;
  min-width: 1.66667rem;
  overflow: hidden;
  transition: all 0.1s ease-in-out;
}
input[data-switch] + label:after {
  content: "";
  position: absolute;
  left: 4px;
  background-color: var(--ct-gray-500);
  box-shadow: none;
  border-radius: 2rem;
  height: 18px;
  width: 18px;
  top: 3px;
  transition: all 0.1s ease-in-out;
}
input[data-switch]:checked + label {
  background-color: var(--ct-primary);
}
input[data-switch]:checked + label:before {
  color: #fff;
  content: attr(data-on-label);
  right: auto;
  left: 4px;
}
input[data-switch]:checked + label:after {
  left: 34px;
  background-color: var(--ct-tertiary-bg);
}

input[data-switch=bool] + label {
  background-color: #fa5c7c;
}

input:disabled + label {
  opacity: 0.5;
  cursor: default;
}

input[data-switch=bool] + label:before,
input[data-switch=bool]:checked + label:before {
  color: #fff !important;
}

input[data-switch=bool] + label:after {
  background-color: var(--ct-tertiary-bg);
}

input[data-switch=primary]:checked + label {
  background-color: #2c8ef8;
}

input[data-switch=secondary]:checked + label {
  background-color: #6c757d;
}

input[data-switch=success]:checked + label {
  background-color: #0acf97;
}

input[data-switch=info]:checked + label {
  background-color: #39afd1;
}

input[data-switch=warning]:checked + label {
  background-color: #ffc35a;
}

input[data-switch=danger]:checked + label {
  background-color: #fa5c7c;
}

input[data-switch=light]:checked + label {
  background-color: #eef2f7;
}

input[data-switch=dark]:checked + label {
  background-color: #313a46;
}

input[data-switch=lwk-green-dark]:checked + label {
  background-color: #05A148;
}

input[data-switch=lwk-green-light]:checked + label {
  background-color: #097131;
}

.table-centered th,
.table-centered td {
  vertical-align: middle !important;
}

.table .table-user img {
  height: 30px;
  width: 30px;
}
.table .action-icon {
  color: var(--ct-secondary-color);
  font-size: 1.2rem;
  display: inline-block;
  padding: 0 3px;
}
.table .action-icon:hover {
  color: var(--ct-emphasis-color);
}
.table > :not(caption) > * > * {
  background-color: var(--ct-table-bg) !important;
}
.table.table-bordered tbody {
  border-top: 1px solid;
  border-top-color: inherit;
}

.table-sm .action-icon {
  font-size: 1rem;
}

.table-nowrap th,
.table-nowrap td {
  white-space: nowrap;
}

[data-bs-theme=dark] .table-light {
  --ct-table-color: var(--ct-body-color);
  --ct-table-bg: var(--ct-light);
  --ct-table-border-color: var(--ct-border-color);
}

.border-dashed {
  border-style: dashed !important;
}

.primary-tooltip {
  --ct-tooltip-bg: var(--ct-primary) !important;
}

.secondary-tooltip {
  --ct-tooltip-bg: var(--ct-secondary) !important;
}

.success-tooltip {
  --ct-tooltip-bg: var(--ct-success) !important;
}

.info-tooltip {
  --ct-tooltip-bg: var(--ct-info) !important;
}

.warning-tooltip {
  --ct-tooltip-bg: var(--ct-warning) !important;
}

.danger-tooltip {
  --ct-tooltip-bg: var(--ct-danger) !important;
}

.light-tooltip {
  --ct-tooltip-bg: var(--ct-light) !important;
}

.dark-tooltip {
  --ct-tooltip-bg: var(--ct-dark) !important;
}

.lwk-green-dark-tooltip {
  --ct-tooltip-bg: var(--ct-lwk-green-dark) !important;
}

.lwk-green-light-tooltip {
  --ct-tooltip-bg: var(--ct-lwk-green-light) !important;
}

.progress-w-percent {
  min-height: 20px;
  margin-bottom: 20px;
}
.progress-w-percent .progress {
  width: calc(100% - 50px);
  float: left;
  margin-top: 8px;
}
.progress-w-percent .progress-value {
  width: 40px;
  float: right;
  text-align: right;
  line-height: 20px;
}

.widget-flat {
  position: relative;
  overflow: hidden;
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .widget-flat i.widget-icon {
    display: none;
  }
}

.widget-icon {
  color: var(--ct-primary);
  font-size: 20px;
  background-color: rgba(var(--ct-primary-rgb), 0.25);
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 3px;
  display: inline-block;
}

.dash-item-overlay {
  position: absolute;
  /*rtl:ignore*/
  text-align: left;
  /*rtl:ignore*/
  left: 8%;
  max-width: 350px;
  padding: 20px;
  z-index: 1;
}

.chart-content-bg {
  background-color: var(--ct-tertiary-bg);
}

.chart-content-border {
  border: 1px solid var(--ct-gray-200);
}

.chart-widget-list p {
  border-bottom: 1px solid var(--ct-border-color-custom);
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
}

.timeline-alt {
  padding: 20px 0;
  position: relative;
}
.timeline-alt .timeline-item {
  position: relative;
}
.timeline-alt .timeline-item:before {
  background-color: var(--ct-tertiary-bg);
  bottom: 0;
  content: "";
  left: 9px;
  position: absolute;
  top: 20px;
  width: 2px;
  z-index: 0;
}
.timeline-alt .timeline-item .timeline-icon {
  float: left;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid transparent;
  font-size: 12px;
  text-align: center;
  line-height: 16px;
  background-color: #fff;
}
.timeline-alt .timeline-item .timeline-item-info {
  margin-left: 30px;
}
.timeline-alt .timeline-item:last-of-type::before {
  display: none;
}

.inbox-widget .inbox-item {
  border-bottom: 1px solid var(--ct-border-color);
  overflow: hidden;
  padding: 0.625rem 0;
  position: relative;
}
.inbox-widget .inbox-item:last-of-type {
  border-bottom: none;
}
.inbox-widget .inbox-item .inbox-item-img {
  display: block;
  float: left;
  margin-right: 15px;
  width: 40px;
}
.inbox-widget .inbox-item .inbox-item-img img {
  width: 40px;
}
.inbox-widget .inbox-item .inbox-item-author {
  color: var(--ct-tertiary-color);
  display: block;
  margin-bottom: 3px;
}
.inbox-widget .inbox-item .inbox-item-text {
  color: var(--ct-gray-500);
  display: block;
  font-size: 0.8125rem;
  margin: 0;
}
.inbox-widget .inbox-item .inbox-item-date {
  color: var(--ct-gray-600);
  font-size: 0.6875rem;
  position: absolute;
  right: 5px;
  top: 10px;
}

.tilebox-one i {
  position: absolute;
  right: 1.5rem;
  font-size: 2rem;
  opacity: 0.3;
}

.toll-free-box i {
  position: absolute;
  left: 0;
  bottom: -15px;
  font-size: 4rem;
  opacity: 0.4;
  transform: rotate(30deg);
}

.cta-box {
  background-image: url(../../../images/bg-pattern.png);
  background-size: cover;
}
.cta-box .cta-box-title {
  font-size: 20px;
  line-height: 30px;
}

.conversation-list {
  list-style: none;
  padding: 0 15px;
}
.conversation-list li {
  margin-bottom: 20px;
}
.conversation-list li .conversation-actions {
  float: right;
  display: none;
}
.conversation-list li:hover .conversation-actions {
  display: block;
}
.conversation-list .chat-avatar {
  float: left;
  text-align: center;
  width: 42px;
}
.conversation-list .chat-avatar img {
  border-radius: 100%;
  width: 100%;
}
.conversation-list .chat-avatar i {
  font-size: 12px;
  font-style: normal;
}
.conversation-list .ctext-wrap {
  background: var(--ct-light);
  border-radius: 3px;
  display: inline-block;
  padding: 12px;
  position: relative;
}
.conversation-list .ctext-wrap i {
  display: block;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  position: relative;
}
.conversation-list .ctext-wrap p {
  margin: 0;
  padding-top: 3px;
}
.conversation-list .ctext-wrap:after {
  left: -11px;
  top: 0;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-top-color: var(--ct-light);
  border-width: 6px;
  margin-right: -1px;
  border-right-color: var(--ct-light);
}
.conversation-list .conversation-text {
  float: left;
  font-size: 13px;
  margin-left: 12px;
  width: 70%;
}
.conversation-list .odd .chat-avatar {
  float: right !important;
}
.conversation-list .odd .conversation-text {
  float: right !important;
  margin-right: 12px;
  text-align: right;
  width: 70% !important;
}
.conversation-list .odd .ctext-wrap {
  background-color: var(--ct-light);
}
.conversation-list .odd .ctext-wrap:after {
  border-color: transparent;
  border-left-color: var(--ct-light);
  border-top-color: var(--ct-light);
  right: -10px;
  left: auto;
}
.conversation-list .odd .conversation-actions {
  float: left;
}

.calendar-widget .datepicker-inline,
.calendar-widget table {
  width: 100%;
}
.calendar-widget .datepicker-inline tr td,
.calendar-widget .datepicker-inline tr th,
.calendar-widget .datepicker-inline tr td.active.day,
.calendar-widget .datepicker-inline tr td.today.day,
.calendar-widget table tr td,
.calendar-widget table tr th,
.calendar-widget table tr td.active.day,
.calendar-widget table tr td.today.day {
  background-color: transparent !important;
}
.calendar-widget .datepicker-inline tr td.active.day,
.calendar-widget .datepicker-inline tr td.today.day,
.calendar-widget table tr td.active.day,
.calendar-widget table tr td.today.day {
  color: #fa5c7c !important;
  text-shadow: none;
  font-weight: 700;
}
.calendar-widget .datepicker-inline tr td.active.day:hover,
.calendar-widget .datepicker-inline tr td.today.day:hover,
.calendar-widget table tr td.active.day:hover,
.calendar-widget table tr td.today.day:hover {
  background-color: transparent !important;
}
.calendar-widget .datepicker-inline td,
.calendar-widget .datepicker-inline th,
.calendar-widget table td,
.calendar-widget table th {
  height: 45px;
}
.calendar-widget .datepicker-inline .datepicker-switch,
.calendar-widget .datepicker-inline .prev,
.calendar-widget .datepicker-inline .next,
.calendar-widget table .datepicker-switch,
.calendar-widget table .prev,
.calendar-widget table .next {
  font-size: 1.1rem;
  background-color: rgba(var(--ct-primary-rgb), 0.1) !important;
  border-radius: 0;
  color: var(--ct-primary);
}

.multi-user a {
  margin-left: -15px;
  border: 3px solid var(--ct-border-color);
  border-radius: 50px;
}
.multi-user a:first-child {
  margin-left: 0px;
}

.card-bg-img {
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat;
}

.social-list-item {
  height: 2rem;
  width: 2rem;
  line-height: calc(2rem - 2px);
  display: block;
  border: 2px solid var(--ct-gray-500);
  border-radius: 50%;
  color: var(--ct-gray-500);
}
.social-list-item:hover {
  color: var(--ct-gray-600);
  border-color: var(--ct-gray-600);
}

.horizontal-steps {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.horizontal-steps:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 0.2em;
  background-color: var(--ct-border-color);
}
.horizontal-steps .process-line {
  display: block;
  position: absolute;
  width: 50%;
  height: 0.2em;
  background-color: var(--ct-primary);
}
.horizontal-steps .horizontal-steps-content {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.horizontal-steps .horizontal-steps-content .step-item {
  display: block;
  position: relative;
  bottom: calc(100% + 1em);
  height: 8px;
  width: 8px;
  margin: 0 2em;
  box-sizing: content-box;
  color: var(--ct-primary);
  background-color: currentColor;
  border: 0.25em solid var(--ct-body-bg);
  border-radius: 50%;
  z-index: 5;
}
.horizontal-steps .horizontal-steps-content .step-item:first-child {
  margin-left: 0;
}
.horizontal-steps .horizontal-steps-content .step-item:last-child {
  margin-right: 0;
  color: #0acf97;
}
.horizontal-steps .horizontal-steps-content .step-item span {
  position: absolute;
  top: calc(100% + 1em);
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  color: var(--ct-gray-500);
}
.horizontal-steps .horizontal-steps-content .step-item.current:before {
  content: "";
  display: block;
  position: absolute;
  top: 48%;
  left: 48%;
  padding: 1em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  z-index: -1;
  animation-name: animation-steps-current;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
}
.horizontal-steps .horizontal-steps-content .step-item.current span {
  color: var(--ct-primary) !important;
}

@keyframes animation-steps-current {
  from {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  to {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}
@media (max-width: 767.98px) {
  .horizontal-steps .horizontal-steps-content .step-item span {
    white-space: inherit;
  }
}
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--ct-tertiary-bg);
  z-index: 9999;
}

#status {
  width: 80px;
  height: 80px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -40px 0 0 -40px;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translate3d(0, -16px, 0);
  }
}
.bouncing-loader {
  display: flex;
  justify-content: center;
}
.bouncing-loader > div {
  width: 13px;
  height: 13px;
  margin: 32px 3px;
  background: var(--ct-primary);
  border-radius: 50%;
  animation: bouncing-loader 0.6s infinite alternate;
}
.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
  background: #fa5c7c;
}
.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
  background: #0acf97;
}

.hero-section {
  position: relative;
  padding: 80px 0 120px 0;
}
.hero-section:after {
  content: " ";
  background-image: linear-gradient(to bottom, #6379c3, #546ee5);
  position: absolute;
  top: -400px;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  border-radius: 0;
  transform: skewY(-3deg);
}

.auth-brand {
  margin-bottom: 2rem;
}
.auth-brand .logo-dark {
  display: block;
}
.auth-brand .logo-light {
  display: none;
}

html[data-theme=dark] .auth-brand .logo-dark {
  display: none;
}
html[data-theme=dark] .auth-brand .logo-light {
  display: block;
}

body.authentication-bg .account-pages {
  align-items: center;
  display: flex;
  min-height: 100vh;
}

.authentication-bg.enlarged,
.auth-fluid-pages.enlarged,
body.auth-fluid-pages[data-sidenav-size=condensed] {
  min-height: 100px;
}

.logout-icon {
  width: 140px;
}

.auth-fluid {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 100vh;
  flex-direction: row;
  align-items: stretch;
  background: url("../../../images/bg-auth.jpg") center;
  background-size: cover;
}
.auth-fluid .auth-fluid-form-box {
  max-width: 480px;
  border-radius: 0;
  z-index: 2;
  padding: 3rem;
  background-color: var(--ct-secondary-bg);
  position: relative;
  width: 100%;
}
.auth-fluid .auth-fluid-right,
.auth-fluid .auth-fluid-left {
  padding: 6rem 3rem;
  flex: 1;
  position: relative;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
}

.auth-user-testimonial {
  position: absolute;
  margin: 0 auto;
  padding: 0 1.75rem;
  bottom: 3rem;
  left: 0;
  right: 0;
}
.auth-user-testimonial p.lead {
  font-size: 1.125rem;
  margin: 0 auto 20px auto;
  max-width: 700px;
}

@media (max-width: 991.98px) {
  .auth-fluid {
    display: block;
  }
  .auth-fluid .auth-fluid-form-box {
    max-width: 100%;
    min-height: 100vh;
  }
  .auth-fluid .auth-fluid-right {
    display: none;
  }
}
html[data-theme=dark] .auth-brand .logo-light {
  display: block;
}
html[data-theme=dark] .auth-brand .logo-dark {
  display: none;
}

.scrollspy-example {
  position: relative;
  height: 200px;
  margin-top: 0.5rem;
  overflow: auto;
}

.grid-structure .grid-container {
  background-color: var(--ct-tertiary-bg);
  margin-bottom: 10px;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 10px 20px;
}

.icons-list-demo div {
  cursor: pointer;
  line-height: 45px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}
.icons-list-demo div p {
  margin-bottom: 0;
  line-height: inherit;
}
.icons-list-demo div:hover, .icons-list-demo div:hover i {
  color: var(--ct-primary);
}
.icons-list-demo i {
  text-align: center;
  vertical-align: middle;
  font-size: 24px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  margin-right: 12px;
  border-radius: 3px;
  display: inline-block;
  transition: all 0.2s;
}

.text-error {
  color: var(--ct-primary);
  text-shadow: rgba(var(--ct-primary-rgb), 0.3) 5px 1px, rgba(var(--ct-primary-rgb), 0.2) 10px 3px;
  font-size: 5.25rem;
  line-height: 5.625rem;
}

.faq-question-q-box {
  height: 30px;
  width: 30px;
  color: var(--ct-primary);
  background-color: rgba(var(--ct-primary-rgb), 0.25);
  box-shadow: 0 0 45px 0 rgba(var(--ct-body-color-rgb), 0.2);
  text-align: center;
  border-radius: 50%;
  float: left;
  font-weight: 700;
  line-height: 30px;
}

.faq-question {
  margin-top: 0;
  margin-left: 50px;
  font-weight: 600;
  font-size: 16px;
  color: var(--ct-tertiary-color);
}

.faq-answer {
  margin-left: 50px;
}

.maintenance-icon {
  font-size: 22px;
  box-shadow: 0 0 45px 0 rgba(var(--ct-body-color-rgb), 0.2);
  height: 60px;
  display: inline-block;
  width: 60px;
  line-height: 58px;
  border-radius: 50%;
}

.board {
  display: block;
  white-space: nowrap;
  overflow-x: auto;
}

.tasks {
  display: inline-block;
  width: 22rem;
  padding: 0 1rem 1rem 1rem;
  border: 1px solid var(--ct-border-color);
  vertical-align: top;
  margin-bottom: 1.5rem;
  border-radius: var(--ct-border-radius);
}
.tasks.tasks:not(:last-child) {
  margin-right: 1.25rem;
}
.tasks .card {
  white-space: normal;
  margin-top: 1rem;
}
.tasks .task-header {
  background-color: var(--ct-tertiary-bg);
  padding: 1rem;
  margin: 0 -1rem;
}

.task-list-items {
  min-height: 100px;
  position: relative;
}
.task-list-items:before {
  content: "No Tasks";
  position: absolute;
  line-height: 110px;
  width: 100%;
  text-align: center;
  font-weight: 600;
}

.task-modal-content .form-control-light {
  background-color: var(--ct-light) !important;
  border-color: var(--ct-border-color) !important;
}

.gantt-task-details {
  min-width: 220px;
}

.page-aside-left {
  width: 240px;
  float: left;
  padding: 0 20px 20px 10px;
  position: relative;
}
.page-aside-left:before {
  content: "";
  background-color: var(--ct-body-bg);
  width: 5px;
  position: absolute;
  right: -15px;
  height: 100%;
  bottom: -1.5rem;
}

.page-aside-right {
  margin: -1.5rem 0 -1.5rem 250px;
  border-left: 5px solid var(--ct-body-bg);
  padding: 1.5rem 0 1.5rem 25px;
}

.email-list {
  display: block;
  padding-left: 0;
  overflow: hidden;
}
.email-list > li {
  position: relative;
  display: block;
  height: 51px;
  line-height: 50px;
  cursor: default;
  transition-duration: 0.3s;
}
.email-list > li a {
  color: var(--ct-body-color);
}
.email-list > li a:hover {
  color: var(--ct-tertiary-color);
}
.email-list > li .col-mail {
  float: left;
  position: relative;
}
.email-list > li .email-sender-info {
  width: 320px;
}
.email-list > li .email-sender-info .star-toggle,
.email-list > li .email-sender-info .checkbox-wrapper-mail {
  display: block;
  float: left;
}
.email-list > li .email-sender-info .checkbox-wrapper-mail {
  margin: 15px 10px 0 20px;
  cursor: pointer;
  height: 20px;
  width: 20px;
}
.email-list > li .email-sender-info .star-toggle {
  color: var(--ct-gray-500);
  margin-left: 10px;
  font-size: 18px;
}
.email-list > li .email-sender-info .email-title {
  position: absolute;
  top: 0;
  left: 100px;
  right: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0;
  line-height: 50px;
}
.email-list > li .email-content {
  position: absolute;
  top: 0;
  left: 320px;
  right: 0;
  bottom: 0;
}
.email-list > li .email-content .email-subject,
.email-list > li .email-content .email-date {
  position: absolute;
  top: 0;
}
.email-list > li .email-content .email-subject {
  left: 0;
  right: 110px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.email-list > li .email-content .email-date {
  right: 0;
  width: 100px;
  text-align: right;
  padding-left: 10px;
}
.email-list > li.active, .email-list > li.mail-selected {
  background: var(--ct-tertiary-bg);
  transition-duration: 0.05s;
}
.email-list > li.unread a {
  font-weight: 600;
  color: var(--ct-dark);
}
.email-list > li .email-action-icons {
  opacity: 0;
}
.email-list > li .email-action-icons ul {
  display: flex;
  position: absolute;
  transition: all 0.5s;
  right: -180px;
}
.email-list > li .email-action-icons ul li {
  margin: 0 10px;
}
.email-list > li .email-action-icons ul .email-action-icons-item {
  font-size: 20px;
  transition: all 0.5s;
}
.email-list > li .email-action-icons ul .email-action-icons-item:hover {
  color: #fa5c7c;
}
.email-list > li:hover {
  background: var(--ct-tertiary-bg);
  transition-duration: 0.05s;
}
.email-list > li:hover .email-action-icons {
  opacity: 1;
}
.email-list > li:hover .email-action-icons ul {
  transition: all 0.5s;
  right: 10px;
}
.email-list > li:hover .email-content .email-date {
  opacity: 0;
}
.email-list > li:hover .email-content .email-subject {
  right: 180px;
  transition: all 0.5s;
}

.email-menu-list a {
  color: var(--ct-secondary-color);
  padding: 12px 5px;
  display: block;
  font-size: 15px;
}
.email-menu-list a:hover {
  color: var(--ct-tertiary-color);
}
.email-menu-list a .badge {
  margin-top: 3px;
}

.labels-list a {
  padding: 7px 5px;
}

.write-mdg-box .CodeMirror {
  height: 150px;
}

@media (max-width: 648px) {
  .page-aside-left {
    width: 100%;
    float: none;
    padding: 0 10px 20px 10px;
  }
  .page-aside-left:before {
    width: 0;
  }
  .page-aside-right {
    margin-left: 0;
    border: 0;
    padding-left: 0;
  }
  .email-list li .email-sender-info .checkbox-wrapper-mail {
    margin-left: 0;
  }
}
@media (max-width: 520px) {
  .page-aside-right > .btn-group {
    margin-bottom: 10px;
  }
  .email-list li .email-sender-info {
    width: 150px;
  }
  .email-list li .email-sender-info .email-title {
    left: 80px;
  }
  .email-list li .email-content {
    display: none;
  }
}
.timeline {
  margin-bottom: 50px;
  position: relative;
}
.timeline:before {
  background-color: var(--ct-border-color);
  bottom: 0;
  content: "";
  left: 50%;
  position: absolute;
  top: 30px;
  width: 2px;
  z-index: 0;
}

.timeline-show {
  position: relative;
}
.timeline-show .time-show-name {
  display: inline-block;
  border-radius: 4px;
  background-color: var(--ct-border-color);
  padding: 7px 15px;
}

.timeline-box {
  background-color: var(--ct-secondary-bg);
  box-shadow: var(--ct-box-shadow);
  display: block;
  margin: 15px 0;
  padding: 1.5rem;
  border-radius: var(--ct-border-radius);
}

.timeline-album {
  margin-top: 12px;
}
.timeline-album a {
  display: inline-block;
  margin-right: 5px;
}
.timeline-album img {
  height: 36px;
  width: auto;
  border-radius: 3px;
}

@media (min-width: 768px) {
  .timeline .timeline-box {
    margin-left: 65px;
  }
  .timeline .timeline-icon {
    background: var(--ct-border-color);
    border-radius: 50%;
    display: block;
    height: 24px;
    left: -11px;
    margin-top: -12px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 24px;
  }
  .timeline .timeline-icon i {
    color: var(--ct-secondary-color);
    font-size: 1rem;
    vertical-align: middle;
  }
  .timeline .timeline-desk {
    position: relative;
    display: table-cell;
    vertical-align: top;
    width: 50%;
  }
  .timeline-lg-item {
    display: flex;
  }
  .timeline-lg-item:before {
    content: "";
    display: block;
    width: 50%;
  }
  .timeline-lg-item .timeline-desk .arrow {
    border-bottom: 12px solid transparent;
    border-right: 12px solid var(--ct-secondary-bg) !important;
    border-top: 12px solid transparent;
    display: block;
    height: 0;
    left: 53px;
    margin-top: -12px;
    position: absolute;
    top: 50%;
    width: 0;
    box-shadow: var(--ct-box-shadow);
  }
  .timeline-lg-item.timeline-item-left:after {
    content: "";
    display: block;
    width: 50%;
  }
  .timeline-lg-item.timeline-item-left .timeline-desk .arrow-alt {
    border-bottom: 12px solid transparent;
    border-left: 12px solid var(--ct-secondary-bg) !important;
    border-top: 12px solid transparent;
    display: block;
    height: 0;
    left: auto;
    margin-top: -12px;
    position: absolute;
    right: 53px;
    top: 50%;
    width: 0;
    box-shadow: var(--ct-box-shadow);
  }
  .timeline-lg-item.timeline-item-left .timeline-desk .album {
    float: right;
    margin-top: 20px;
  }
  .timeline-lg-item.timeline-item-left .timeline-desk .album a {
    float: right;
    margin-left: 5px;
  }
  .timeline-lg-item.timeline-item-left .timeline-icon {
    left: auto;
    right: -13px;
  }
  .timeline-lg-item.timeline-item-left:before {
    display: none;
  }
  .timeline-lg-item.timeline-item-left .timeline-box {
    margin-right: 65px;
    margin-left: 0;
  }
}
@media (max-width: 767.98px) {
  .timeline .timeline-icon {
    display: none;
  }
}
.apex-charts {
  min-height: 10px !important;
}
.apex-charts text {
  font-family: var(--ct-font-sans-serif) !important;
}
.apex-charts .apexcharts-canvas {
  margin: 0 auto;
}

.apexcharts-tooltip-title,
.apexcharts-tooltip-text,
.apexcharts-xaxistooltip-text,
.apexcharts-datalabels text {
  font-family: var(--ct-font-sans-serif) !important;
}

.apexcharts-tooltip {
  border: 1px solid var(--ct-border-color) !important;
  background-color: var(--ct-secondary-bg) !important;
  box-shadow: var(--ct-box-shadow) !important;
}
.apexcharts-tooltip * {
  font-family: var(--ct-font-sans-serif) !important;
  color: var(--ct-tertiary-color) !important;
}
.apexcharts-tooltip .apexcharts-tooltip-title {
  background-color: rgba(var(--ct-light-rgb), 0.75) !important;
  border-bottom: 1px solid var(--ct-border-color) !important;
}

.apexcharts-tooltip.apexcharts-theme-dark * {
  color: #fff !important;
}

.apexcharts-legend-series {
  font-weight: 600;
}

.apexcharts-gridline {
  pointer-events: none;
  stroke: var(--ct-border-color-custom);
}

.apexcharts-xaxis-tick {
  stroke: var(--ct-border-color);
}

.apexcharts-legend-text {
  color: var(--ct-tertiary-color) !important;
  font-family: var(--ct-font-sans-serif) !important;
  font-size: 13px !important;
  vertical-align: middle;
}

.apexcharts-legend-marker {
  vertical-align: middle;
  margin-right: 5px !important;
  top: -1px !important;
}

.apexcharts-pie-label {
  fill: #fff !important;
}

.apexcharts-title-text,
.apexcharts-subtitle-text {
  fill: var(--ct-secondary-color);
  font-family: var(--ct-font-sans-serif) !important;
}

.apexcharts-yaxis text,
.apexcharts-xaxis text {
  font-family: var(--ct-font-sans-serif) !important;
  fill: var(--ct-secondary-color);
}

.apexcharts-yaxis-title {
  font-weight: 500;
}

.apexcharts-annotation-rect {
  fill: var(--ct-tertiary-bg) !important;
}

.apexcharts-xaxis-annotations rect,
.apexcharts-yaxis-annotations rect {
  stroke: none;
}

.apexcharts-grid-borders .apexcharts-gridline + line {
  stroke: var(--ct-border-color);
}

.apex-toolbar .btn-light.active {
  background-color: var(--ct-primary);
  border-color: var(--ct-primary);
  color: #fff;
  box-shadow: 0px 2px 6px 0px rgba(var(--ct-primary-rgb), 0.5);
}

.apexcharts-xaxistooltip {
  background-color: var(--ct-secondary-bg) !important;
  border: var(--ct-border-width) solid var(--ct-border-color) !important;
  box-shadow: var(--ct-box-shadow) !important;
  color: var(--ct-body-color) !important;
}

.apexcharts-xaxistooltip-bottom:before {
  border-bottom-color: var(--ct-border-color) !important;
}

.apexcharts-datalabel-value {
  fill: var(--ct-body-color) !important;
}

.apexcharts-menu {
  background: var(--ct-secondary-bg) !important;
  padding: 0.25rem 0 !important;
  box-shadow: var(--ct-box-shadow) !important;
  border: var(--ct-border-width) solid var(--ct-border-color) !important;
  text-align: center !important;
}
.apexcharts-menu .apexcharts-menu-item:hover {
  color: var(--ct-tertiary-color) !important;
  background-color: var(--ct-tertiary-bg) !important;
}

.apexcharts-radar-series polygon {
  fill: transparent;
  stroke: var(--ct-border-color);
}
.apexcharts-radar-series line {
  stroke: var(--ct-border-color);
}

.apexcharts-track path {
  stroke: var(--ct-tertiary-bg);
}

.apexcharts-marker {
  stroke: var(--ct-body-bg);
}

.apexcharts-grid rect {
  fill: none;
}

.apex-charts .apexcharts-heatmap-series rect,
.apex-charts .apexcharts-treemap rect,
.apex-charts .apexcharts-bar-series path,
.apex-charts .apexcharts-pie-series path {
  stroke: var(--ct-body-bg);
}

.apexcharts-pie circle, .apexcharts-pie line {
  stroke: var(--ct-border-color);
}

.britechart,
.tick text {
  font-family: var(--ct-font-sans-serif);
  font-size: 0.75rem;
}

.horizontal-grid-line,
.vertical-grid-line,
.extended-x-line,
.extended-y-line {
  stroke: var(--ct-border-color);
}

.tick text,
.bar-chart .percentage-label,
.donut-text,
.legend-entry-name,
.legend-entry-value {
  fill: var(--ct-secondary-color);
}

.calendar {
  float: left;
  margin-bottom: 0;
}

.fc-view {
  margin-top: 30px;
}

.none-border .modal-footer {
  border-top: none;
}

.fc-toolbar {
  margin: 6px 0 5px 0 !important;
}
.fc-toolbar h2, .fc-toolbar .h2 {
  font-size: 1.25rem !important;
  line-height: 1.875rem;
  text-transform: uppercase;
}

.fc-day-grid-event .fc-time {
  font-weight: 600;
}

th.fc-day-header {
  padding: 0.5rem 0;
}

.fc-day {
  background: transparent;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
  z-index: 0;
}

.fc th.fc-widget-header {
  background: var(--ct-tertiary-bg);
  font-size: 13px;
  line-height: 20px;
  padding: 10px 0;
  text-transform: uppercase;
  font-weight: 600;
}
.fc .fc-daygrid-body,
.fc .fc-scrollgrid-section-body table,
.fc .fc-scrollgrid-section-footer table {
  width: 100% !important;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
  border-color: var(--ct-border-color);
}
.fc-unthemed td.fc-today,
.fc-unthemed .fc-divider {
  background: var(--ct-tertiary-bg);
}

.fc-button {
  background: var(--ct-tertiary-bg);
  border: none;
  color: var(--ct-tertiary-color);
  text-transform: capitalize;
  box-shadow: none;
  border-radius: 3px;
  margin: 0 3px;
  padding: 6px 12px;
  height: auto;
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 1rem;
}

.fc-state-hover,
.fc-state-highlight,
.fc-cell-overlay {
  background: var(--ct-tertiary-bg);
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  background-color: var(--ct-primary);
  color: #fff;
  text-shadow: none;
}

.fc-unthemed .fc-today {
  background: #fff;
}

.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 0.8125rem;
  margin: 5px 7px;
  padding: 5px 5px;
  text-align: center;
  color: #fff;
}
.fc-event.bg-dark .fc-event-time,
.fc-event.bg-dark .fc-event-title {
  color: var(--ct-emphasis-color);
}
.fc-event.bg-dark .fc-daygrid-event-dot {
  border-color: var(--ct-border-color);
}

.external-event {
  cursor: move;
  margin: 10px 0;
  padding: 8px 10px;
  color: #fff;
  border-radius: 4px;
}

.fc-basic-view td.fc-week-number span {
  padding-right: 8px;
}
.fc-basic-view td.fc-day-number {
  padding-right: 8px;
}
.fc-basic-view .fc-content {
  color: #fff;
}

.fc-time-grid-event .fc-content {
  color: #fff;
}

.fc-daygrid-day-number {
  float: right;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 20px;
  background-color: var(--ct-tertiary-bg);
  border-radius: 50%;
  margin: 5px;
  font-size: 11px;
  padding: 0 !important;
}

.fc-daygrid-event-dot {
  border-color: #fff;
}

.fc-event-time,
.fc-event-title {
  color: #fff;
}

.table-active,
.table-active > td,
.table-active > th,
.fc .fc-list-sticky .fc-list-day > * {
  background-color: transparent;
}

.fc .fc-list-event:hover td {
  background-color: inherit;
}

@media (max-width: 767.98px) {
  .fc-toolbar {
    display: block !important;
  }
  .fc-toolbar .fc-toolbar-chunk {
    margin: 6px 0;
  }
  .fc-toolbar .fc-left,
  .fc-toolbar .fc-right,
  .fc-toolbar .fc-center {
    float: none;
    display: block;
    clear: both;
    margin: 10px 0;
  }
  .fc .fc-toolbar > * > * {
    float: none;
  }
  .fc-today-button {
    display: none;
  }
}
.fc-toolbar .btn {
  --ct-btn-padding-y: 0.28rem;
  --ct-btn-padding-x: 0.8rem;
  --ct-btn-font-size: 0.875rem;
  --ct-btn-border-radius: 0.2rem;
}

.fc-list-item-title,
.fc-list-item-time {
  color: #fff;
}

#calendar .table-bordered td,
#calendar .table-bordered th {
  border: var(--ct-border-width) solid var(--ct-border-color);
}

[dir=rtl] .fc-toolbar-chunk .btn-group .btn:first-child {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-radius: 0.2rem;
}
[dir=rtl] .fc-toolbar-chunk .btn-group .btn:last-child {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-radius: 0.2rem;
}

.chartjs-chart {
  margin: auto;
  position: relative;
  width: 100%;
}

.dataTables_wrapper.container-fluid, .dataTables_wrapper.container-sm, .dataTables_wrapper.container-md, .dataTables_wrapper.container-lg, .dataTables_wrapper.container-xl, .dataTables_wrapper.container-xxl {
  padding: 0;
}

table.dataTable {
  border-collapse: collapse !important;
  margin-bottom: 15px !important;
}
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control:before,
table.dataTable.dtr-inline.collapsed > tbody > tr th.dtr-control:before {
  background-color: var(--ct-primary);
  box-shadow: 0px 0px 2px 0px var(--ct-primary);
}
table.dataTable thead:not.table-light th {
  background-color: transparent;
  border-bottom-color: var(--ct-gray-300);
}
table.dataTable tbody tr.even td,
table.dataTable tbody tr.odd td {
  background-color: transparent;
}
table.dataTable tbody > tr.selected td {
  background-color: var(--ct-primary);
}
table.dataTable.table-striped > tbody > tr.selected:nth-of-type(odd) > * {
  --ct-table-accent-bg: $primary !important;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  right: 0.5rem;
  left: auto;
  content: "\f0360";
  font-family: "Material Design Icons";
  font-size: 1rem;
  top: 12px;
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  right: 0.5em;
  left: auto;
  content: "\f035d";
  font-family: "Material Design Icons";
  top: 18px;
  font-size: 1rem;
}
table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting {
  padding-right: 30px;
  padding-left: 0.95rem;
}
table.dataTable tbody > tr.selected,
table.dataTable tbody > tr > .selected {
  background-color: var(--ct-primary);
}
table.dataTable tbody > tr.selected td,
table.dataTable tbody > tr > .selected td {
  border-color: var(--ct-primary);
}
table.dataTable tbody td:focus {
  outline: none !important;
}
table.dataTable tbody th.focus,
table.dataTable tbody td.focus {
  outline: 2px solid #2c8ef8 !important;
  outline-offset: -1px;
  background-color: rgba(var(--ct-primary-rgb), 0.15);
}

.dataTables_info {
  font-weight: 600;
}

.dtfh-floatingparent {
  top: var(--ct-topbar-height) !important;
}

table.dataTable.dtr-inline.collapsed > tbody > tr[role=row] > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr[role=row] > th:first-child:before {
  box-shadow: 0 0 45px 0 rgba(var(--ct-body-color-rgb), 0.2);
  background-color: #0acf97;
  top: auto;
  bottom: auto;
}
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
  background-color: #fa5c7c;
}

div.dt-button-info {
  background-color: var(--ct-primary);
  border: none;
  color: #fff;
  box-shadow: none;
  border-radius: 3px;
  text-align: center;
  z-index: 21;
}
div.dt-button-info h2, div.dt-button-info .h2 {
  border-bottom: none;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

@media (max-width: 767.98px) {
  li.paginate_button.previous,
  li.paginate_button.next {
    display: inline-block;
    font-size: 1.5rem;
  }
  li.paginate_button {
    display: none;
  }
  .dataTables_paginate ul {
    text-align: center;
    display: block;
    margin: 1.5rem 0 0 !important;
  }
  div.dt-buttons {
    display: inline-table;
    margin-bottom: 1.5rem;
  }
}
.activate-select .sorting_1 {
  background-color: var(--ct-tertiary-bg) !important;
}

div.dataTables_wrapper div.dataTables_filter {
  text-align: right;
}
@media (max-width: 576px) {
  div.dataTables_wrapper div.dataTables_filter {
    text-align: center;
  }
}
div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.5em;
  margin-right: 0;
}
div.dataTables_wrapper div.dataTables_length {
  text-align: left;
}
@media (max-width: 576px) {
  div.dataTables_wrapper div.dataTables_length {
    text-align: center;
  }
}

div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:last-child {
  padding-right: 0;
}
div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:first-child {
  padding-left: 0;
}

div.dataTables_scrollBody {
  border-left: none !important;
}
div.dataTables_scrollBody > table {
  margin-bottom: 15px !important;
}
div.dataTables_scrollBody > table > :not(:first-child) {
  border-top: none !important;
}

.daterangepicker {
  font-family: var(--ct-font-sans-serif);
  border: var(--ct-border-width) solid var(--ct-border-color);
  box-shadow: var(--ct-box-shadow);
  background-color: var(--ct-secondary-bg);
}
.daterangepicker .calendar-table {
  border: 1px solid var(--ct-secondary-bg);
  background-color: var(--ct-secondary-bg);
}
.daterangepicker .calendar-table td,
.daterangepicker .calendar-table th {
  color: var(--ct-gray-600);
}
.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  border-color: var(--ct-border-color);
}
.daterangepicker .ranges li:hover {
  background-color: var(--ct-tertiary-bg);
}
.daterangepicker .ranges li.active {
  background-color: var(--ct-primary);
}
.daterangepicker td.in-range {
  background: var(--ct-tertiary-bg);
  color: var(--ct-tertiary-color);
}
.daterangepicker td.off, .daterangepicker td.off.end-date, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date {
  background-color: var(--ct-secondary-bg);
  color: var(--ct-body-color);
  opacity: 0.5;
}
.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: var(--ct-primary);
  color: #fff;
}
.daterangepicker td.available:hover,
.daterangepicker th.available:hover,
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: var(--ct-primary);
  color: #fff;
}
.daterangepicker td.available:hover span,
.daterangepicker th.available:hover span,
.daterangepicker td.active span,
.daterangepicker td.active:hover span {
  border-color: #fff;
}
.daterangepicker:after {
  border-bottom: 6px solid var(--ct-secondary-bg);
}
.daterangepicker:before {
  border-bottom: 7px solid var(--ct-border-color);
}
.daterangepicker .drp-buttons {
  border-top: 1px solid var(--ct-border-color);
}
.daterangepicker select.ampmselect, .daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect {
  background: var(--ct-tertiary-bg);
  border: 1px solid var(--ct-border-color);
  color: var(--ct-body-color);
}
.daterangepicker.show-ranges .drp-calendar.left {
  border-left: 1px solid var(--ct-border-color) !important;
}

.datepicker {
  padding: 10px !important;
}
.datepicker td,
.datepicker th {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 13px;
  line-height: 28px;
}
.datepicker table tr td.active.active, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled.active, .datepicker table tr td.active.disabled.disabled, .datepicker table tr td.active.disabled:active, .datepicker table tr td.active.disabled:hover, .datepicker table tr td.active.disabled:hover.active, .datepicker table tr td.active.disabled:hover.disabled, .datepicker table tr td.active.disabled:hover:active, .datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td .active.disabled:hover[disabled],
.datepicker table tr td .active.disabled[disabled],
.datepicker table tr td .active:active,
.datepicker table tr td .active:hover,
.datepicker table tr td .active:hover.active,
.datepicker table tr td .active:hover.disabled,
.datepicker table tr td .active:hover:active,
.datepicker table tr td .active:hover:hover,
.datepicker table tr td .active:hover[disabled],
.datepicker table tr td .active[disabled],
.datepicker table tr td span.active.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active.disabled:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active[disabled], .datepicker table tr td.today, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover, .datepicker table tr td.today:hover {
  background-color: #2c8ef8 !important;
  background-image: none !important;
  color: #fff;
}
.datepicker table tr td.day.focused, .datepicker table tr td.day:hover,
.datepicker table tr td span.focused,
.datepicker table tr td span:hover {
  background: var(--ct-tertiary-bg);
}
.datepicker table tr td.new, .datepicker table tr td.old,
.datepicker table tr td span.new,
.datepicker table tr td span.old {
  color: var(--ct-tertiary-color);
  opacity: 0.4;
}
.datepicker .datepicker-switch:hover,
.datepicker .next:hover,
.datepicker .prev:hover,
.datepicker tfoot tr th:hover {
  background: var(--ct-gray-200);
}
.datepicker .datepicker-switch:hover {
  background: none;
}

.datepicker-dropdown:after {
  border-bottom: 6px solid var(--ct-secondary-bg);
}
.datepicker-dropdown:before {
  border-bottom-color: var(--ct-border-color);
}
.datepicker-dropdown.datepicker-orient-top:before {
  border-top: 7px solid var(--ct-border-color);
}
.datepicker-dropdown.datepicker-orient-top:after {
  border-top: 6px solid var(--ct-secondary-bg);
}

.daterangepicker {
  z-index: 1000;
}

[dir=rtl] .daterangepicker.opensright:after {
  right: 10px;
  left: auto;
}
[dir=rtl] .daterangepicker.opensright:before {
  right: 9px;
  left: auto;
}

.flatpickr-calendar {
  background: var(--ct-secondary-bg);
  box-shadow: var(--ct-box-shadow);
  border: 1px solid var(--ct-border-color);
}
.flatpickr-calendar.arrowTop:before {
  border-bottom-color: var(--ct-secondary-bg);
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: var(--ct-secondary-bg);
}
.flatpickr-calendar.arrowBottom:before, .flatpickr-calendar.arrowBottom:after {
  border-top-color: var(--ct-secondary-bg);
}

.flatpickr-current-month {
  font-size: 110%;
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: var(--ct-primary);
  border-color: var(--ct-primary);
}
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 0 var(--ct-primary);
}

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: var(--ct-input-bg);
  color: var(--ct-tertiary-color);
}

.flatpickr-months .flatpickr-month {
  height: 36px;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month,
.flatpickr-months .flatpickr-month {
  color: var(--ct-gray-500);
  fill: var(--ct-gray-500);
}

.flatpickr-weekdays {
  background-color: var(--ct-tertiary-bg);
}

span.flatpickr-weekday,
.flatpickr-day,
.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover,
.flatpickr-time input,
.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  color: var(--ct-body-color);
  fill: var(--ct-body-color);
}

.flatpickr-day.disabled, .flatpickr-day.disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: var(--ct-tertiary-color);
}
.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  background: var(--ct-tertiary-bg);
  border-color: var(--ct-tertiary-bg);
}

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  border-top: 1px solid var(--ct-secondary-bg);
}

.numInputWrapper:hover,
.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background-color: transparent;
  color: var(--ct-tertiary-color);
}

.flatpickr-day.inRange {
  box-shadow: -5px 0 0 var(--ct-tertiary-bg), 5px 0 0 var(--ct-tertiary-bg);
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  color: var(--ct-tertiary-color);
}

.flatpickr-calendar.hasTime .flatpickr-time {
  border-top: var(--ct-border-color);
}

.bg-dragula {
  background-color: var(--ct-tertiary-bg);
}

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}

.gu-hide {
  display: none !important;
}

.gu-unselectable {
  user-select: none !important;
}

.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.dragula-handle {
  position: relative;
  width: 36px;
  height: 36px;
  font-size: 24px;
  text-align: center;
  cursor: move;
}
.dragula-handle:before {
  content: "\f01db";
  font-family: "Material Design Icons";
  position: absolute;
}

.dropzone {
  border: 2px dashed var(--ct-border-color);
  background: var(--ct-input-bg);
  border-radius: 6px;
  cursor: pointer;
  min-height: 150px;
  padding: 20px;
}
.dropzone .dz-message {
  text-align: center;
  margin: 2rem 0;
}
.dropzone.dz-started .dz-message {
  display: none;
}

.form-wizard-header {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  background-color: var(--ct-tertiary-bg);
}
.modal-body .form-wizard-header {
  margin-left: calc(var(--ct-modal-padding) * -1);
  margin-right: calc(var(--ct-modal-padding) * -1);
}

.gmaps,
.gmaps-panaroma {
  height: 300px;
  background: var(--ct-tertiary-bg);
  border-radius: 3px;
}

.jvectormap-label {
  border: none;
  background: var(--ct-tertiary-bg);
  color: var(--ct-tertiary-color);
  font-family: var(--ct-font-sans-serif);
  font-size: 0.9rem;
  padding: 5px 8px;
}

.jvectormap-container .jvectormap-zoomin,
.jvectormap-container .jvectormap-zoomout {
  background-color: var(--ct-tertiary-bg);
  color: var(--ct-tertiary-color);
}

.ql-editor {
  text-align: left;
}
.ql-editor ol,
.ql-editor ul {
  padding-left: 1.5em;
  padding-right: 0;
}
.ql-editor li:not(.ql-direction-rtl)::before {
  margin-left: -1.5em;
  margin-right: 0.3em;
  text-align: right;
}

.ql-container {
  font-family: var(--ct-font-sans-serif);
}
.ql-container.ql-snow {
  border-color: var(--ct-border-color);
  border-radius: 0 0 0.25rem 0.25rem;
}

.ql-bubble {
  border: var(--ct-border-width) solid var(--ct-border-color);
  border-radius: 0.25rem;
}

.ql-toolbar {
  font-family: var(--ct-font-sans-serif) !important;
}
.ql-toolbar span {
  outline: none !important;
  color: var(--ct-body-color);
}
.ql-toolbar span:hover {
  color: #2c8ef8 !important;
}
.ql-toolbar.ql-snow {
  border-radius: 0.25rem 0.25rem 0 0;
  border-color: var(--ct-border-color);
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border-color: transparent;
}
.ql-toolbar.ql-snow .ql-picker-options {
  box-shadow: var(--ct-box-shadow);
  border-radius: 0.25rem;
}

.ql-snow .ql-stroke,
.ql-snow .ql-script,
.ql-snow .ql-strike svg {
  stroke: var(--ct-body-color);
}
.ql-snow .ql-fill {
  fill: var(--ct-body-color);
}
.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  right: 0;
  left: auto;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: var(--ct-body-color);
}

.ql-snow .ql-picker-options {
  background-color: var(--ct-secondary-bg);
  border-color: var(--ct-border-color) !important;
}

.select2-container {
  width: 100% !important;
}
.select2-container .select2-selection--single {
  border: var(--ct-border-width) solid var(--ct-border-color);
  height: calc(1.5em + 0.9rem + calc(var(--ct-border-width) * 2));
  background-color: var(--ct-input-bg);
  outline: none;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  line-height: 36px;
  padding-left: 12px;
  color: var(--ct-body-color);
}
.select2-container .select2-selection--single .select2-selection__arrow {
  height: 34px;
  width: 34px;
  /*rtl:ignore*/
  right: 3px;
}
.select2-container .select2-selection--single .select2-selection__arrow b {
  border-color: var(--ct-gray-500) transparent transparent transparent;
  border-width: 5px 5px 0 5px;
}

.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent var(--ct-gray-500) transparent !important;
  border-width: 0 5px 5px 5px !important;
}

.select2-results__option {
  padding: 6px 12px;
}

.select2-dropdown {
  border: var(--ct-border-width) solid var(--ct-border-color);
  box-shadow: var(--ct-box-shadow);
  background-color: var(--ct-secondary-bg);
  z-index: 1056;
}

.select2-results__options::-webkit-scrollbar {
  -webkit-appearance: none;
}
.select2-results__options::-webkit-scrollbar:vertical {
  width: 5px;
  margin-right: 5px;
}
.select2-results__options::-webkit-scrollbar:horizontal {
  height: 5px;
}
.select2-results__options::-webkit-scrollbar-thumb {
  background-color: rgba(var(--ct-dark-rgb), 0.2);
  border-radius: 10px;
  margin-right: 5px;
  border: none;
}
.select2-results__options::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}
.select2-results__options::-webkit-scrollbar-corner {
  background-color: transparent;
}

.select2-container--default .select2-results__option--selected {
  background-color: var(--ct-tertiary-bg);
}
.select2-container--default .select2-search--dropdown {
  padding: 10px;
  background-color: var(--ct-secondary-bg);
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  outline: none;
  border: 1px solid var(--ct-border-color);
  background-color: var(--ct-input-bg);
  color: var(--ct-body-color);
  border-radius: 0.25rem;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: var(--ct-primary);
}
.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: var(--ct-secondary-bg);
  color: var(--ct-tertiary-color);
}
.select2-container--default .select2-results__option[aria-selected=true]:hover {
  background-color: var(--ct-primary);
  color: #fff;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 36px;
  padding-right: 5px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  /*rtl:ignore*/
  right: 1px;
  /*rtl:ignore*/
  left: auto;
}
select.is-invalid ~ .select2-container--default .select2-selection, .was-validated select:invalid ~ .select2-container--default .select2-selection {
  border-color: #fa5c7c;
  padding-right: calc(1.5em + 0.9rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23fa5c7c'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fa5c7c' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.225rem) center;
  background-size: calc(0.75em + 0.45rem) calc(0.75em + 0.45rem);
}
select.is-invalid ~ .select2-container--default .select2-selection:focus, .was-validated select:invalid ~ .select2-container--default .select2-selection:focus {
  border-color: #fa5c7c;
  box-shadow: 0 0 0 0.15rem rgba(250, 92, 124, 0.25);
}
select.is-valid ~ .select2-container--default .select2-selection, .was-validated select:valid ~ .select2-container--default .select2-selection {
  border-color: var(--ct-form-valid-color);
  /* @if $enable-validation-icons {
       padding-right: $input-height-inner;
       background-image: escape-svg($form-feedback-icon-valid);
       background-repeat: no-repeat;
       background-position: right $input-height-inner-quarter center;
       background-size: $input-height-inner-half $input-height-inner-half;
  } */
}
select.is-valid ~ .select2-container--default .select2-selection:not([multiple]):not([size]), select.is-valid ~ .select2-container--default .select2-selection:not([multiple])[size="1"], .was-validated select:valid ~ .select2-container--default .select2-selection:not([multiple]):not([size]), .was-validated select:valid ~ .select2-container--default .select2-selection:not([multiple])[size="1"] {
  --ct-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%230acf97' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-image: var(--ct-form-select-bg-icon, none);
  padding-right: 4.95rem;
  background-position: center right 2.7rem;
  background-size: calc(0.75em + 0.45rem) calc(0.75em + 0.45rem);
  background-repeat: no-repeat;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  select.is-valid ~ .select2-container--default .select2-selection:not([multiple]):not([size]), select.is-valid ~ .select2-container--default .select2-selection:not([multiple])[size="1"], .was-validated select:valid ~ .select2-container--default .select2-selection:not([multiple]):not([size]), .was-validated select:valid ~ .select2-container--default .select2-selection:not([multiple])[size="1"] {
    transition: none;
  }
}
select.is-valid ~ .select2-container--default .select2-selection:focus, .was-validated select:valid ~ .select2-container--default .select2-selection:focus {
  border-color: var(--ct-form-valid-color);
  box-shadow: 0 0 0 0.15rem rgba(var(--ct-form-valid-color), 0.25);
}

.select2-container .select2-selection--multiple {
  min-height: calc(1.5em + 0.9rem + calc(var(--ct-border-width) * 2));
  border: var(--ct-border-width) solid var(--ct-border-color) !important;
  background-color: var(--ct-input-bg);
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
  padding: 1px 4px;
}
.select2-container .select2-selection--multiple .select2-search__field {
  border: 0;
  color: var(--ct-body-color);
}
.select2-container .select2-selection--multiple .select2-selection__choice {
  background-color: var(--ct-primary);
  border: none;
  color: #fff;
  border-radius: 3px;
  padding: 0px 7px 0 0;
  margin-top: 6px;
}
.select2-container .select2-selection--multiple .select2-selection__choice__remove {
  color: #fff;
  margin-right: 7px;
  border-color: #4199f9;
  padding: 0 8px;
}
.select2-container .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #fff;
  background-color: var(--ct-primary);
}
.select2-container .select2-search--inline .select2-search__field {
  margin-top: 7px;
  font-family: var(--ct-font-sans-serif);
}
.select2-container .select2-search textarea::placeholder {
  color: var(--ct-secondary-color);
}

[dir=rtl] .select2-container--open .select2-dropdown {
  left: auto;
  right: 0;
}

[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  overflow: auto;
  /* Scroll on this element otherwise element can't have a padding applied properly */
  max-width: 100%;
  /* Not required for horizontal scroll to trigger */
  max-height: 100%;
  /* Needed for vertical scroll to trigger */
  scrollbar-width: none;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.simplebar-content:before,
.simplebar-content:after {
  content: " ";
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  right: 2px;
  width: 5px;
  min-height: 10px;
}
.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: #a2adb7;
  border-radius: 7px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

/* Rtl support */
[data-simplebar-direction=rtl] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
}

.custom-scroll {
  height: 100%;
}

[data-simplebar-lg] .simplebar-scrollbar {
  right: 1px;
  width: 10px;
}

[data-simplebar-primary] .simplebar-scrollbar:before {
  background: var(--ct-primary);
}

.jq-toast-single {
  text-align: left !important;
  padding: 15px;
  font-family: var(--ct-font-sans-serif);
  background-color: var(--ct-primary);
  font-size: 13px;
  line-height: 22px;
}
.jq-toast-single h2, .jq-toast-single .h2 {
  font-family: var(--ct-font-sans-serif);
}
.jq-toast-single a {
  font-size: 0.9rem;
}
.jq-toast-single a:hover {
  color: #fff;
}

.jq-has-icon {
  padding: 10px;
}

.close-jq-toast-single {
  position: absolute;
  top: -12px;
  right: -12px;
  font-size: 20px;
  cursor: pointer;
  height: 32px;
  width: 32px;
  background: var(--ct-gray-800);
  color: var(--ct-gray-300);
  border-radius: 50%;
  text-align: center;
  line-height: 32px;
}

.jq-toast-loader {
  height: 3px;
  top: 0;
  border-radius: 0;
}

.jq-icon-primary {
  background-color: #2c8ef8;
  color: #fff;
  border-color: #2c8ef8;
}

.jq-icon-secondary {
  background-color: #6c757d;
  color: #fff;
  border-color: #6c757d;
}

.jq-icon-success {
  background-color: #0acf97;
  color: #fff;
  border-color: #0acf97;
}

.jq-icon-info {
  background-color: #39afd1;
  color: #fff;
  border-color: #39afd1;
}

.jq-icon-warning {
  background-color: #ffc35a;
  color: #fff;
  border-color: #ffc35a;
}

.jq-icon-danger {
  background-color: #fa5c7c;
  color: #fff;
  border-color: #fa5c7c;
}

.jq-icon-light {
  background-color: #eef2f7;
  color: #fff;
  border-color: #eef2f7;
}

.jq-icon-dark {
  background-color: #313a46;
  color: #fff;
  border-color: #313a46;
}

.jq-icon-lwk-green-dark {
  background-color: #05A148;
  color: #fff;
  border-color: #05A148;
}

.jq-icon-lwk-green-light {
  background-color: #097131;
  color: #fff;
  border-color: #097131;
}

.jq-icon-error {
  background-color: #fa5c7c;
  color: #fff;
  border-color: #fa5c7c;
}

.jq-icon-info,
.jq-icon-warning,
.jq-icon-error,
.jq-icon-success {
  background-image: none;
}

.bootstrap-touchspin .btn .input-group-text {
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit;
}

.bootstrap-timepicker-widget table td input {
  height: 32px;
  width: 32px;
  color: #fff;
  background-color: var(--ct-primary);
  border-radius: 50%;
  border: 0;
  outline: none !important;
}
.bootstrap-timepicker-widget table td a {
  color: var(--ct-gray-700);
}
.bootstrap-timepicker-widget table td a:hover {
  background-color: transparent;
  border: 1px solid transparent;
  color: var(--ct-primary);
}
.bootstrap-timepicker-widget.dropdown-menu:before {
  border-bottom: 7px solid var(--ct-border-color);
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}
.bootstrap-timepicker-widget.dropdown-menu:after {
  border-bottom: 6px solid var(--ct-secondary-bg);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}
.bootstrap-timepicker-widget.timepicker-orient-bottom:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid var(--ct-secondary-bg);
}
.bootstrap-timepicker-widget.timepicker-orient-bottom:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid var(--ct-border-color);
}

.editor-toolbar.fullscreen,
.CodeMirror-fullscreen {
  z-index: 1000;
}

.editor-preview,
.editor-preview-side {
  background: var(--ct-gray-300);
}

.editor-toolbar {
  border: 1px solid var(--ct-border-color);
  border-bottom: 0;
  opacity: 1;
}
.editor-toolbar a {
  color: var(--ct-gray-700) !important;
}
.editor-toolbar a:hover, .editor-toolbar a.active {
  background-color: transparent;
  color: var(--ct-primary) !important;
  border-color: transparent;
}
.editor-toolbar i.separator {
  display: none;
}
.editor-toolbar.disabled-for-preview a:not(.no-disable) {
  background: transparent;
}

.CodeMirror {
  border: 1px solid var(--ct-border-color);
  background: var(--ct-input-bg);
  color: var(--ct-body-color);
  min-height: 100px;
}

.CodeMirror-cursor {
  border-left: 1px solid var(--ct-gray-600);
}

.editor-statusbar {
  color: var(--ct-gray-800);
}

.twitter-typeahead {
  display: inherit !important;
}

.tt-query,
.tt-hint {
  outline: none;
}

.tt-query {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
  color: var(--ct-body-color);
  background: var(--ct-input-bg) !important;
}

.tt-menu {
  width: 100%;
  padding: 8px 0;
  max-height: 200px;
  overflow-y: auto;
  background-color: var(--ct-secondary-bg);
  box-shadow: var(--ct-box-shadow);
  border: 1px solid var(--ct-border-color);
}

.tt-suggestion {
  padding: 3px 20px;
  line-height: 24px;
}
.tt-suggestion:hover, .tt-suggestion.tt-cursor {
  cursor: pointer;
  color: #fff;
  background-color: var(--ct-primary);
}
.tt-suggestion p {
  margin: 0;
}

.tt-highlight {
  font-family: var(--ct-font-sans-serif);
}

.typeahead-empty-message {
  padding: 5px 10px;
  color: #fa5c7c;
}

.league-name {
  padding: 3px 20px;
}

.jqstooltip {
  box-sizing: content-box;
  width: auto !important;
  height: auto !important;
  background-color: #fff !important;
  box-shadow: 0 0 45px 0 rgba(var(--ct-body-color-rgb), 0.2);
  padding: 5px 10px !important;
  border-radius: 3px;
  border-color: #fff !important;
}

.jqsfield {
  color: #000 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  font-family: var(--ct-font-sans-serif) !important;
  font-weight: 700 !important;
}

/* Timepicker */
.timepicker-orient-bottom {
  top: auto !important;
  bottom: calc(1.5em + 0.9rem + calc(var(--ct-border-width) * 2)) !important;
}

.bootstrap-timepicker-widget {
  left: 0 !important;
  right: auto !important;
}

.bootstrap-timepicker-widget.timepicker-orient-left:before {
  left: 6px;
  right: auto;
}
.bootstrap-timepicker-widget.timepicker-orient-left::after {
  left: 7px;
  right: auto;
}

.rateit {
  display: -moz-inline-box;
  display: inline-block;
  position: relative;
  user-select: none;
  -webkit-touch-callout: none;
}
.rateit .rateit-range * {
  display: block;
}
.rateit .rateit-hover,
.rateit .rateit-selected {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
}
.rateit .rateit-hover-rtl,
.rateit .rateit-selected-rtl {
  left: auto;
  right: 0;
}
.rateit .rateit-hover {
  color: #ffc35a;
}
.rateit .rateit-hover-rtl {
  background-position: right -32px;
}
.rateit .rateit-selected {
  color: #fa5c7c;
}
.rateit .rateit-selected-rtl {
  background-position: right -16px;
}
.rateit .rateit-preset {
  color: #fd7e14;
}
.rateit button.rateit-reset {
  width: 16px;
  height: 16px;
  float: left;
  outline: none;
  border: none;
  padding: 0;
}
.rateit .rateit-reset span {
  display: none;
}
.rateit .rateit-range {
  position: relative;
  display: -moz-inline-box;
  display: inline-block;
  height: 16px;
  outline: none;
}
.rateit.rateit-font .rateit-reset {
  width: 0.6em;
  height: 0.6em;
  margin-right: 5px;
  margin-top: 5px;
  background: var(--ct-tertiary-bg);
  border-radius: 50%;
  position: relative;
}
.rateit.rateit-font .rateit-reset span {
  display: block;
  height: calc(50% - 0.045em);
  top: 2px;
  position: absolute;
  border-bottom: 2px solid var(--ct-emphasis-color);
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
}
.rateit.rateit-font .rateit-reset:hover,
.rateit.rateit-font button.rateit-reset:focus {
  background: #fa5c7c;
}
.rateit.rateit-font .rateit-reset:hover span,
.rateit.rateit-font button.rateit-reset:focus span {
  border-color: #fff;
}

.rateit-mdi {
  font-family: "Material Design Icons";
}

.rateit-font {
  font-size: 24px;
  line-height: 1em;
}
.rateit-font .rateit-range {
  background: none;
  height: auto;
}
.rateit-font .rateit-range > div {
  background: none;
  overflow: hidden;
  cursor: default;
  white-space: nowrap;
}
.rateit-font .rateit-empty {
  color: var(--ct-border-color);
}

.rateit .rateit-hover-rtl,
.rateit .rateit-selected-rtl {
  right: auto;
}

.irs {
  position: relative;
  display: block;
  -webkit-touch-callout: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 12px;
}

.irs-line {
  position: relative;
  display: block;
  overflow: hidden;
  outline: none !important;
}

.irs-bar {
  position: absolute;
  display: block;
  left: 0;
  width: 0;
}

.irs-shadow {
  position: absolute;
  display: none;
  left: 0;
  width: 0;
}

.irs-handle {
  position: absolute;
  display: block;
  box-sizing: border-box;
  cursor: default;
  z-index: 1;
}
.irs-handle.type_last {
  z-index: 2;
}

.irs-min,
.irs-max {
  position: absolute;
  display: block;
  cursor: default;
}

.irs-min {
  left: 0;
  right: auto;
}

.irs-max {
  right: 0;
  left: auto;
}

[dir=rtl] .irs-min {
  right: 0;
  left: auto;
}
[dir=rtl] .irs-max {
  left: 0;
  right: auto;
}

.irs-from,
.irs-to,
.irs-single {
  position: absolute;
  display: block;
  top: 0;
  cursor: default;
  white-space: nowrap;
}

.irs-grid {
  position: absolute;
  display: none;
  bottom: 0;
  /*rtl:ignore*/
  left: 0;
  width: 100%;
  height: 20px;
}

.irs-with-grid .irs-grid {
  display: block;
}

.irs-grid-pol {
  position: absolute;
  top: 0;
  /*rtl:ignore*/
  left: 0;
  width: 1px;
  height: 8px;
  background: #6c757d;
}
.irs-grid-pol.small {
  height: 4px;
}

.irs-grid-text {
  position: absolute;
  bottom: 0;
  /*rtl:ignore*/
  left: 0;
  white-space: nowrap;
  text-align: center;
  font-size: 9px;
  line-height: 9px;
  padding: 0 3px;
  color: #6c757d;
}

.irs-disable-mask {
  position: absolute;
  display: block;
  top: 0;
  left: -1%;
  width: 102%;
  height: 100%;
  cursor: default;
  z-index: 2;
}

.lt-ie9 .irs-disable-mask {
  background: #6c757d;
  filter: alpha(opacity=0);
  cursor: not-allowed;
}

.irs-disabled {
  opacity: 0.4;
}

.irs-hidden-input {
  position: absolute !important;
  display: block !important;
  top: 0 !important;
  left: 0 !important;
  width: 0 !important;
  height: 0 !important;
  font-size: 0 !important;
  line-height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden;
  outline: none !important;
  z-index: -9999 !important;
  background: none !important;
  border-style: solid !important;
  border-color: transparent !important;
}

.irs--flat {
  height: 40px;
}
.irs--flat.irs-with-grid {
  height: 60px;
}
.irs--flat .irs-line {
  top: 25px;
  height: 12px;
  background-color: var(--ct-tertiary-bg);
  border-radius: 4px;
}
.irs--flat .irs-bar {
  top: 25px;
  height: 12px;
  background-color: var(--ct-primary);
}
.irs--flat .irs-bar--single {
  border-radius: 4px 0 0 4px;
}
.irs--flat .irs-shadow {
  height: 1px;
  bottom: 16px;
  background-color: var(--ct-tertiary-bg);
}
.irs--flat .irs-handle {
  top: 22px;
  width: 16px;
  height: 18px;
  background-color: transparent;
}
.irs--flat .irs-handle > i:first-child {
  position: absolute;
  display: block;
  top: 0;
  left: 50%;
  width: 2px;
  height: 100%;
  margin-left: -1px;
  background-color: #097bf7;
}
.irs--flat .irs-min,
.irs--flat .irs-max {
  top: 0;
  padding: 1px 3px;
  color: var(--ct-secondary-color);
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  background-color: var(--ct-tertiary-bg);
  border-radius: 4px;
}
.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single {
  color: #fff;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  background-color: var(--ct-primary);
  border-radius: 4px;
}
.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: var(--ct-primary);
}
.irs--flat .irs-grid-pol {
  background-color: var(--ct-tertiary-bg);
}
.irs--flat .irs-grid-text {
  color: var(--ct-secondary-color);
}

[dir=rtl] .irs-bar--single {
  border-radius: 0 4px 4px 0 !important;
}

.jstree-default {
  padding: 2px 6px;
  height: auto;
}
.jstree-default .jstree-clicked,
.jstree-default .jstree-hovered {
  background: var(--ct-tertiary-bg);
  box-shadow: none;
}
.jstree-default .jstree-anchor,
.jstree-default .jstree-icon,
.jstree-default .jstree-icon:empty {
  line-height: 20px;
}
.jstree-default .jstree-node,
.jstree-default .jstree-icon {
  background-image: url(../../../images/treeview.png);
}
.jstree-default .jstree-last {
  background: transparent;
}
.jstree-default .jstree-themeicon-custom {
  background-color: transparent;
  background-image: none;
  background-position: 0 0;
}
.jstree-default .jstree-disabled {
  color: var(--ct-secondary-color);
}

.jstree-wholerow.jstree-wholerow-clicked, .jstree-wholerow.jstree-wholerow-hovered {
  background: var(--ct-tertiary-bg);
}

.gantt .bar-progress {
  fill: var(--ct-primary);
}
.gantt .bar-wrapper:hover .bar-progress {
  fill: #459bf9;
}
.gantt .bar,
.gantt .bar-wrapper:hover .bar {
  fill: var(--ct-light);
}
.gantt .bar-label,
.gantt .bar-label.big {
  fill: var(--ct-tertiary-color);
}
.gantt .grid-header {
  fill: rgba(var(--ct-tertiary-bg-rgb), 1);
  stroke: rgba(var(--ct-tertiary-bg-rgb), 1);
}
.gantt .grid-row {
  fill: transparent;
}
.gantt .grid-row:nth-child(even) {
  fill: rgba(var(--ct-tertiary-bg-rgb), 1);
}
.gantt .lower-text,
.gantt .upper-text {
  fill: var(--ct-body-color);
}
.gantt .row-line {
  stroke: var(--ct-border-color);
}
.gantt .today-highlight {
  fill: var(--ct-gray-200);
}

.gantt-container::-webkit-scrollbar {
  -webkit-appearance: none;
}
.gantt-container::-webkit-scrollbar:vertical {
  width: 5px;
  margin-right: 5px;
}
.gantt-container::-webkit-scrollbar:horizontal {
  height: 5px;
}
.gantt-container::-webkit-scrollbar-thumb {
  background-color: rgba(var(--ct-dark-rgb), 0.2);
  border-radius: 10px;
  margin-right: 5px;
  border: none;
}
.gantt-container::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}
.gantt-container::-webkit-scrollbar-corner {
  background-color: transparent;
}
.gantt-container .popup-wrapper {
  background: transparent;
}
.gantt-container .popup-wrapper .pointer {
  display: none;
}

/*
Template Name: Hyper - Responsive Bootstrap 5 Admin Dashboard
Version: 1.6.0
Author: CoderThemes
Email: support@coderthemes.com
File: Icons Css File
*/
@font-face {
  font-family: "unicons";
  src: url("../../../fonts/unicons.eot?34404611");
  src: url("../../../fonts/unicons.eot?34404611#iefix") format("embedded-opentype"), url("../../../fonts/unicons.woff2?34404611") format("woff2"), url("../../../fonts/unicons.woff?34404611") format("woff"), url("../../../fonts/unicons.ttf?34404611") format("truetype"), url("../../../fonts/unicons.svg?34404611#unicons") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'unicons';
    src: url('../../../font/unicons.svg?34404611#unicons') format('svg');
  }
}
*/
[class^=uil-]:before, [class*=" uil-"]:before {
  font-family: "unicons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.uil-0-plus:before {
  content: "\e800";
} /* '' */
.uil-10-plus:before {
  content: "\e801";
} /* '' */
.uil-12-plus:before {
  content: "\e802";
} /* '' */
.uil-13-plus:before {
  content: "\e803";
} /* '' */
.uil-16-plus:before {
  content: "\e804";
} /* '' */
.uil-17-plus:before {
  content: "\e805";
} /* '' */
.uil-18-plus:before {
  content: "\e806";
} /* '' */
.uil-21-plus:before {
  content: "\e807";
} /* '' */
.uil-3-plus:before {
  content: "\e808";
} /* '' */
.uil-500px:before {
  content: "\e809";
} /* '' */
.uil-6-plus:before {
  content: "\e80a";
} /* '' */
.uil-abacus:before {
  content: "\e80b";
} /* '' */
.uil-accessible-icon-alt:before {
  content: "\e80c";
} /* '' */
.uil-adjust-alt:before {
  content: "\e80d";
} /* '' */
.uil-adjust-circle:before {
  content: "\e80e";
} /* '' */
.uil-adjust-half:before {
  content: "\e80f";
} /* '' */
.uil-adjust:before {
  content: "\e810";
} /* '' */
.uil-adobe:before {
  content: "\e811";
} /* '' */
.uil-airplay:before {
  content: "\e812";
} /* '' */
.uil-align-alt:before {
  content: "\e813";
} /* '' */
.uil-align-center-alt:before {
  content: "\e814";
} /* '' */
.uil-align-center-h:before {
  content: "\e815";
} /* '' */
.uil-align-center-justify:before {
  content: "\e816";
} /* '' */
.uil-align-center-v:before {
  content: "\e817";
} /* '' */
.uil-align-center:before {
  content: "\e818";
} /* '' */
.uil-align-justify:before {
  content: "\e819";
} /* '' */
.uil-align-left-justify:before {
  content: "\e81a";
} /* '' */
.uil-align-left:before {
  content: "\e81b";
} /* '' */
.uil-align-letter-right:before {
  content: "\e81c";
} /* '' */
.uil-align-right-justify:before {
  content: "\e81d";
} /* '' */
.uil-align-right:before {
  content: "\e81e";
} /* '' */
.uil-align:before {
  content: "\e81f";
} /* '' */
.uil-ambulance:before {
  content: "\e820";
} /* '' */
.uil-analysis:before {
  content: "\e821";
} /* '' */
.uil-analytics:before {
  content: "\e822";
} /* '' */
.uil-anchor:before {
  content: "\e823";
} /* '' */
.uil-android-phone-slash:before {
  content: "\e824";
} /* '' */
.uil-angle-double-down:before {
  content: "\e825";
} /* '' */
.uil-angle-double-left:before {
  content: "\e826";
} /* '' */
.uil-angle-double-right:before {
  content: "\e827";
} /* '' */
.uil-angle-double-up:before {
  content: "\e828";
} /* '' */
.uil-angle-down:before {
  content: "\e829";
} /* '' */
.uil-angle-left:before {
  content: "\e82a";
} /* '' */
.uil-angle-right-b:before {
  content: "\e82b";
} /* '' */
.uil-angle-right:before {
  content: "\e82c";
} /* '' */
.uil-angle-up:before {
  content: "\e82d";
} /* '' */
.uil-angry:before {
  content: "\e82e";
} /* '' */
.uil-ankh:before {
  content: "\e82f";
} /* '' */
.uil-annoyed-alt:before {
  content: "\e830";
} /* '' */
.uil-annoyed:before {
  content: "\e831";
} /* '' */
.uil-apple:before {
  content: "\e832";
} /* '' */
.uil-apps:before {
  content: "\e833";
} /* '' */
.uil-archive-alt:before {
  content: "\e834";
} /* '' */
.uil-archive:before {
  content: "\e835";
} /* '' */
.uil-archway:before {
  content: "\e836";
} /* '' */
.uil-arrow-break:before {
  content: "\e837";
} /* '' */
.uil-arrow-circle-down:before {
  content: "\e838";
} /* '' */
.uil-arrow-circle-left:before {
  content: "\e839";
} /* '' */
.uil-arrow-circle-right:before {
  content: "\e83a";
} /* '' */
.uil-arrow-circle-up:before {
  content: "\e83b";
} /* '' */
.uil-arrow-compress-h:before {
  content: "\e83c";
} /* '' */
.uil-arrow-down-left:before {
  content: "\e83d";
} /* '' */
.uil-arrow-down-right:before {
  content: "\e83e";
} /* '' */
.uil-arrow-down:before {
  content: "\e83f";
} /* '' */
.uil-arrow-from-right:before {
  content: "\e840";
} /* '' */
.uil-arrow-from-top:before {
  content: "\e841";
} /* '' */
.uil-arrow-growth:before {
  content: "\e842";
} /* '' */
.uil-arrow-left:before {
  content: "\e843";
} /* '' */
.uil-arrow-random:before {
  content: "\e844";
} /* '' */
.uil-arrow-resize-diagonal:before {
  content: "\e845";
} /* '' */
.uil-arrow-right:before {
  content: "\e846";
} /* '' */
.uil-arrow-to-bottom:before {
  content: "\e847";
} /* '' */
.uil-arrow-to-right:before {
  content: "\e848";
} /* '' */
.uil-arrow-up-left:before {
  content: "\e849";
} /* '' */
.uil-arrow-up-right:before {
  content: "\e84a";
} /* '' */
.uil-arrow-up:before {
  content: "\e84b";
} /* '' */
.uil-arrows-h-alt:before {
  content: "\e84c";
} /* '' */
.uil-arrows-h:before {
  content: "\e84d";
} /* '' */
.uil-arrows-left-down:before {
  content: "\e84e";
} /* '' */
.uil-arrows-maximize:before {
  content: "\e84f";
} /* '' */
.uil-arrows-merge:before {
  content: "\e850";
} /* '' */
.uil-arrows-resize-h:before {
  content: "\e851";
} /* '' */
.uil-arrows-resize-v:before {
  content: "\e852";
} /* '' */
.uil-arrows-resize:before {
  content: "\e853";
} /* '' */
.uil-arrows-right-down:before {
  content: "\e854";
} /* '' */
.uil-arrows-shrink-h:before {
  content: "\e855";
} /* '' */
.uil-arrows-shrink-v:before {
  content: "\e856";
} /* '' */
.uil-arrows-up-right:before {
  content: "\e857";
} /* '' */
.uil-arrows-v-alt:before {
  content: "\e858";
} /* '' */
.uil-arrows-v:before {
  content: "\e859";
} /* '' */
.uil-assistive-listening-systems:before {
  content: "\e85a";
} /* '' */
.uil-asterisk:before {
  content: "\e85b";
} /* '' */
.uil-at:before {
  content: "\e85c";
} /* '' */
.uil-atm-card:before {
  content: "\e85d";
} /* '' */
.uil-atom:before {
  content: "\e85e";
} /* '' */
.uil-auto-flash:before {
  content: "\e85f";
} /* '' */
.uil-award-alt:before {
  content: "\e860";
} /* '' */
.uil-award:before {
  content: "\e861";
} /* '' */
.uil-baby-carriage:before {
  content: "\e862";
} /* '' */
.uil-backpack:before {
  content: "\e863";
} /* '' */
.uil-backspace:before {
  content: "\e864";
} /* '' */
.uil-backward:before {
  content: "\e865";
} /* '' */
.uil-bag-alt:before {
  content: "\e866";
} /* '' */
.uil-bag-slash:before {
  content: "\e867";
} /* '' */
.uil-bag:before {
  content: "\e868";
} /* '' */
.uil-balance-scale:before {
  content: "\e869";
} /* '' */
.uil-ball:before {
  content: "\e86a";
} /* '' */
.uil-ban:before {
  content: "\e86b";
} /* '' */
.uil-bars:before {
  content: "\e86c";
} /* '' */
.uil-basketball-hoop:before {
  content: "\e86d";
} /* '' */
.uil-basketball:before {
  content: "\e86e";
} /* '' */
.uil-battery-bolt:before {
  content: "\e86f";
} /* '' */
.uil-battery-empty:before {
  content: "\e870";
} /* '' */
.uil-bed-double:before {
  content: "\e871";
} /* '' */
.uil-bed:before {
  content: "\e872";
} /* '' */
.uil-behance:before {
  content: "\e873";
} /* '' */
.uil-bell-school:before {
  content: "\e874";
} /* '' */
.uil-bell-slash:before {
  content: "\e875";
} /* '' */
.uil-bell:before {
  content: "\e876";
} /* '' */
.uil-bill:before {
  content: "\e877";
} /* '' */
.uil-bitcoin-circle:before {
  content: "\e878";
} /* '' */
.uil-bitcoin:before {
  content: "\e879";
} /* '' */
.uil-black-berry:before {
  content: "\e87a";
} /* '' */
.uil-bluetooth-b:before {
  content: "\e87b";
} /* '' */
.uil-bold:before {
  content: "\e87c";
} /* '' */
.uil-bolt-alt:before {
  content: "\e87d";
} /* '' */
.uil-bolt-slash:before {
  content: "\e87e";
} /* '' */
.uil-bolt:before {
  content: "\e87f";
} /* '' */
.uil-book-alt:before {
  content: "\e880";
} /* '' */
.uil-book-medical:before {
  content: "\e881";
} /* '' */
.uil-book-open:before {
  content: "\e882";
} /* '' */
.uil-book-reader:before {
  content: "\e883";
} /* '' */
.uil-book:before {
  content: "\e884";
} /* '' */
.uil-bookmark-full:before {
  content: "\e885";
} /* '' */
.uil-bookmark:before {
  content: "\e886";
} /* '' */
.uil-books:before {
  content: "\e887";
} /* '' */
.uil-boombox:before {
  content: "\e888";
} /* '' */
.uil-border-alt:before {
  content: "\e889";
} /* '' */
.uil-border-bottom:before {
  content: "\e88a";
} /* '' */
.uil-border-clear:before {
  content: "\e88b";
} /* '' */
.uil-border-horizontal:before {
  content: "\e88c";
} /* '' */
.uil-border-inner:before {
  content: "\e88d";
} /* '' */
.uil-border-left:before {
  content: "\e88e";
} /* '' */
.uil-border-out:before {
  content: "\e88f";
} /* '' */
.uil-border-right:before {
  content: "\e890";
} /* '' */
.uil-border-top:before {
  content: "\e891";
} /* '' */
.uil-border-vertical:before {
  content: "\e892";
} /* '' */
.uil-box:before {
  content: "\e893";
} /* '' */
.uil-briefcase-alt:before {
  content: "\e894";
} /* '' */
.uil-briefcase:before {
  content: "\e895";
} /* '' */
.uil-bright:before {
  content: "\e896";
} /* '' */
.uil-brightness-empty:before {
  content: "\e897";
} /* '' */
.uil-brightness-half:before {
  content: "\e898";
} /* '' */
.uil-brightness-low:before {
  content: "\e899";
} /* '' */
.uil-brightness-minus:before {
  content: "\e89a";
} /* '' */
.uil-brightness-plus:before {
  content: "\e89b";
} /* '' */
.uil-brightness:before {
  content: "\e89c";
} /* '' */
.uil-bring-bottom:before {
  content: "\e89d";
} /* '' */
.uil-bring-front:before {
  content: "\e89e";
} /* '' */
.uil-brush-alt:before {
  content: "\e89f";
} /* '' */
.uil-bug:before {
  content: "\e8a0";
} /* '' */
.uil-building:before {
  content: "\e8a1";
} /* '' */
.uil-bullseye:before {
  content: "\e8a2";
} /* '' */
.uil-bus-alt:before {
  content: "\e8a3";
} /* '' */
.uil-bus-school:before {
  content: "\e8a4";
} /* '' */
.uil-bus:before {
  content: "\e8a5";
} /* '' */
.uil-calcualtor:before {
  content: "\e8a6";
} /* '' */
.uil-calculator-alt:before {
  content: "\e8a7";
} /* '' */
.uil-calendar-alt:before {
  content: "\e8a8";
} /* '' */
.uil-calendar-slash:before {
  content: "\e8a9";
} /* '' */
.uil-calender:before {
  content: "\e8aa";
} /* '' */
.uil-calling:before {
  content: "\e8ab";
} /* '' */
.uil-camera-change:before {
  content: "\e8ac";
} /* '' */
.uil-camera-plus:before {
  content: "\e8ad";
} /* '' */
.uil-camera-slash:before {
  content: "\e8ae";
} /* '' */
.uil-camera:before {
  content: "\e8af";
} /* '' */
.uil-cancel:before {
  content: "\e8b0";
} /* '' */
.uil-capsule:before {
  content: "\e8b1";
} /* '' */
.uil-capture:before {
  content: "\e8b2";
} /* '' */
.uil-car-sideview:before {
  content: "\e8b3";
} /* '' */
.uil-car-slash:before {
  content: "\e8b4";
} /* '' */
.uil-car-wash:before {
  content: "\e8b5";
} /* '' */
.uil-car:before {
  content: "\e8b6";
} /* '' */
.uil-card-atm:before {
  content: "\e8b7";
} /* '' */
.uil-caret-right:before {
  content: "\e8b8";
} /* '' */
.uil-cart:before {
  content: "\e8b9";
} /* '' */
.uil-cell:before {
  content: "\e8ba";
} /* '' */
.uil-celsius:before {
  content: "\e8bb";
} /* '' */
.uil-chart-bar-alt:before {
  content: "\e8bc";
} /* '' */
.uil-chart-bar:before {
  content: "\e8bd";
} /* '' */
.uil-chart-down:before {
  content: "\e8be";
} /* '' */
.uil-chart-growth-alt:before {
  content: "\e8bf";
} /* '' */
.uil-chart-growth:before {
  content: "\e8c0";
} /* '' */
.uil-chart-line:before {
  content: "\e8c1";
} /* '' */
.uil-chart-pie-alt:before {
  content: "\e8c2";
} /* '' */
.uil-chart-pie:before {
  content: "\e8c3";
} /* '' */
.uil-chart:before {
  content: "\e8c4";
} /* '' */
.uil-chat-bubble-user:before {
  content: "\e8c5";
} /* '' */
.uil-chat-info:before {
  content: "\e8c6";
} /* '' */
.uil-chat:before {
  content: "\e8c7";
} /* '' */
.uil-check-circle:before {
  content: "\e8c8";
} /* '' */
.uil-check-square:before {
  content: "\e8c9";
} /* '' */
.uil-check:before {
  content: "\e8ca";
} /* '' */
.uil-circle-layer:before {
  content: "\e8cb";
} /* '' */
.uil-circle:before {
  content: "\e8cc";
} /* '' */
.uil-circuit:before {
  content: "\e8cd";
} /* '' */
.uil-clapper-board:before {
  content: "\e8ce";
} /* '' */
.uil-clipboard-alt:before {
  content: "\e8cf";
} /* '' */
.uil-clipboard-blank:before {
  content: "\e8d0";
} /* '' */
.uil-clipboard-notes:before {
  content: "\e8d1";
} /* '' */
.uil-clipboard:before {
  content: "\e8d2";
} /* '' */
.uil-clock-eight:before {
  content: "\e8d3";
} /* '' */
.uil-clock-five:before {
  content: "\e8d4";
} /* '' */
.uil-clock-nine:before {
  content: "\e8d5";
} /* '' */
.uil-clock-seven:before {
  content: "\e8d6";
} /* '' */
.uil-clock-ten:before {
  content: "\e8d7";
} /* '' */
.uil-clock-three:before {
  content: "\e8d8";
} /* '' */
.uil-clock-two:before {
  content: "\e8d9";
} /* '' */
.uil-clock:before {
  content: "\e8da";
} /* '' */
.uil-closed-captioning:before {
  content: "\e8db";
} /* '' */
.uil-cloud-block:before {
  content: "\e8dc";
} /* '' */
.uil-cloud-bookmark:before {
  content: "\e8dd";
} /* '' */
.uil-cloud-check:before {
  content: "\e8de";
} /* '' */
.uil-cloud-computing:before {
  content: "\e8df";
} /* '' */
.uil-cloud-data-connection:before {
  content: "\e8e0";
} /* '' */
.uil-cloud-database-tree:before {
  content: "\e8e1";
} /* '' */
.uil-cloud-download:before {
  content: "\e8e2";
} /* '' */
.uil-cloud-drizzle:before {
  content: "\e8e3";
} /* '' */
.uil-cloud-exclamation:before {
  content: "\e8e4";
} /* '' */
.uil-cloud-hail:before {
  content: "\e8e5";
} /* '' */
.uil-cloud-heart:before {
  content: "\e8e6";
} /* '' */
.uil-cloud-info:before {
  content: "\e8e7";
} /* '' */
.uil-cloud-lock:before {
  content: "\e8e8";
} /* '' */
.uil-cloud-meatball:before {
  content: "\e8e9";
} /* '' */
.uil-cloud-moon-hail:before {
  content: "\e8ea";
} /* '' */
.uil-cloud-moon-meatball:before {
  content: "\e8eb";
} /* '' */
.uil-cloud-moon-rain:before {
  content: "\e8ec";
} /* '' */
.uil-cloud-moon-showers:before {
  content: "\e8ed";
} /* '' */
.uil-cloud-moon:before {
  content: "\e8ee";
} /* '' */
.uil-cloud-question:before {
  content: "\e8ef";
} /* '' */
.uil-cloud-rain-sun:before {
  content: "\e8f0";
} /* '' */
.uil-cloud-rain:before {
  content: "\e8f1";
} /* '' */
.uil-cloud-redo:before {
  content: "\e8f2";
} /* '' */
.uil-cloud-set:before {
  content: "\e8f3";
} /* '' */
.uil-cloud-share:before {
  content: "\e8f4";
} /* '' */
.uil-cloud-shield:before {
  content: "\e8f5";
} /* '' */
.uil-cloud-showers-alt:before {
  content: "\e8f6";
} /* '' */
.uil-cloud-showers-heavy:before {
  content: "\e8f7";
} /* '' */
.uil-cloud-showers:before {
  content: "\e8f8";
} /* '' */
.uil-cloud-slash:before {
  content: "\e8f9";
} /* '' */
.uil-cloud-sun-hail:before {
  content: "\e8fa";
} /* '' */
.uil-cloud-sun-meatball:before {
  content: "\e8fb";
} /* '' */
.uil-cloud-sun-rain-alt:before {
  content: "\e8fc";
} /* '' */
.uil-cloud-sun-rain:before {
  content: "\e8fd";
} /* '' */
.uil-cloud-sun-tear:before {
  content: "\e8fe";
} /* '' */
.uil-cloud-sun:before {
  content: "\e8ff";
} /* '' */
.uil-cloud-times:before {
  content: "\e900";
} /* '' */
.uil-cloud-unlock:before {
  content: "\e901";
} /* '' */
.uil-cloud-upload:before {
  content: "\e902";
} /* '' */
.uil-cloud-wifi:before {
  content: "\e903";
} /* '' */
.uil-cloud-wind:before {
  content: "\e904";
} /* '' */
.uil-cloud:before {
  content: "\e905";
} /* '' */
.uil-clouds:before {
  content: "\e906";
} /* '' */
.uil-club:before {
  content: "\e907";
} /* '' */
.uil-code:before {
  content: "\e908";
} /* '' */
.uil-coffee:before {
  content: "\e909";
} /* '' */
.uil-cog:before {
  content: "\e90a";
} /* '' */
.uil-coins:before {
  content: "\e90b";
} /* '' */
.uil-columns:before {
  content: "\e90c";
} /* '' */
.uil-comment-alt-block:before {
  content: "\e90d";
} /* '' */
.uil-comment-alt-chart-lines:before {
  content: "\e90e";
} /* '' */
.uil-comment-alt-check:before {
  content: "\e90f";
} /* '' */
.uil-comment-alt-dots:before {
  content: "\e910";
} /* '' */
.uil-comment-alt-download:before {
  content: "\e911";
} /* '' */
.uil-comment-alt-edit:before {
  content: "\e912";
} /* '' */
.uil-comment-alt-exclamation:before {
  content: "\e913";
} /* '' */
.uil-comment-alt-heart:before {
  content: "\e914";
} /* '' */
.uil-comment-alt-image:before {
  content: "\e915";
} /* '' */
.uil-comment-alt-info:before {
  content: "\e916";
} /* '' */
.uil-comment-alt-lines:before {
  content: "\e917";
} /* '' */
.uil-comment-alt-lock:before {
  content: "\e918";
} /* '' */
.uil-comment-alt-medical:before {
  content: "\e919";
} /* '' */
.uil-comment-alt-message:before {
  content: "\e91a";
} /* '' */
.uil-comment-alt-notes:before {
  content: "\e91b";
} /* '' */
.uil-comment-alt-plus:before {
  content: "\e91c";
} /* '' */
.uil-comment-alt-question:before {
  content: "\e91d";
} /* '' */
.uil-comment-alt-redo:before {
  content: "\e91e";
} /* '' */
.uil-comment-alt-search:before {
  content: "\e91f";
} /* '' */
.uil-comment-alt-share:before {
  content: "\e920";
} /* '' */
.uil-comment-alt-shield:before {
  content: "\e921";
} /* '' */
.uil-comment-alt-slash:before {
  content: "\e922";
} /* '' */
.uil-comment-alt-upload:before {
  content: "\e923";
} /* '' */
.uil-comment-alt-verify:before {
  content: "\e924";
} /* '' */
.uil-comment-alt:before {
  content: "\e925";
} /* '' */
.uil-comment-block:before {
  content: "\e926";
} /* '' */
.uil-comment-chart-line:before {
  content: "\e927";
} /* '' */
.uil-comment-check:before {
  content: "\e928";
} /* '' */
.uil-comment-dots:before {
  content: "\e929";
} /* '' */
.uil-comment-download:before {
  content: "\e92a";
} /* '' */
.uil-comment-edit:before {
  content: "\e92b";
} /* '' */
.uil-comment-exclamation:before {
  content: "\e92c";
} /* '' */
.uil-comment-heart:before {
  content: "\e92d";
} /* '' */
.uil-comment-image:before {
  content: "\e92e";
} /* '' */
.uil-comment-info-alt:before {
  content: "\e92f";
} /* '' */
.uil-comment-info:before {
  content: "\e930";
} /* '' */
.uil-comment-lines:before {
  content: "\e931";
} /* '' */
.uil-comment-lock:before {
  content: "\e932";
} /* '' */
.uil-comment-medical:before {
  content: "\e933";
} /* '' */
.uil-comment-message:before {
  content: "\e934";
} /* '' */
.uil-comment-notes:before {
  content: "\e935";
} /* '' */
.uil-comment-plus:before {
  content: "\e936";
} /* '' */
.uil-comment-question:before {
  content: "\e937";
} /* '' */
.uil-comment-redo:before {
  content: "\e938";
} /* '' */
.uil-comment-search:before {
  content: "\e939";
} /* '' */
.uil-comment-share:before {
  content: "\e93a";
} /* '' */
.uil-comment-shield:before {
  content: "\e93b";
} /* '' */
.uil-comment-slash:before {
  content: "\e93c";
} /* '' */
.uil-comment-upload:before {
  content: "\e93d";
} /* '' */
.uil-comment-verify:before {
  content: "\e93e";
} /* '' */
.uil-comment:before {
  content: "\e93f";
} /* '' */
.uil-comments-alt:before {
  content: "\e940";
} /* '' */
.uil-comments:before {
  content: "\e941";
} /* '' */
.uil-commnet-alt-slash:before {
  content: "\e942";
} /* '' */
.uil-compact-disc:before {
  content: "\e943";
} /* '' */
.uil-compass:before {
  content: "\e944";
} /* '' */
.uil-compress-alt-left:before {
  content: "\e945";
} /* '' */
.uil-compress-alt:before {
  content: "\e946";
} /* '' */
.uil-compress-arrows:before {
  content: "\e947";
} /* '' */
.uil-compress-lines:before {
  content: "\e948";
} /* '' */
.uil-compress-point:before {
  content: "\e949";
} /* '' */
.uil-compress-v:before {
  content: "\e94a";
} /* '' */
.uil-compress:before {
  content: "\e94b";
} /* '' */
.uil-computer-mouse:before {
  content: "\e94c";
} /* '' */
.uil-confused:before {
  content: "\e94d";
} /* '' */
.uil-constructor:before {
  content: "\e94e";
} /* '' */
.uil-copy-alt:before {
  content: "\e94f";
} /* '' */
.uil-copy-landscape:before {
  content: "\e950";
} /* '' */
.uil-copy:before {
  content: "\e951";
} /* '' */
.uil-copyright:before {
  content: "\e952";
} /* '' */
.uil-corner-down-left:before {
  content: "\e953";
} /* '' */
.uil-corner-down-right-alt:before {
  content: "\e954";
} /* '' */
.uil-corner-down-right:before {
  content: "\e955";
} /* '' */
.uil-corner-left-down:before {
  content: "\e956";
} /* '' */
.uil-corner-right-down:before {
  content: "\e957";
} /* '' */
.uil-corner-up-left-alt:before {
  content: "\e958";
} /* '' */
.uil-corner-up-left:before {
  content: "\e959";
} /* '' */
.uil-corner-up-right-alt:before {
  content: "\e95a";
} /* '' */
.uil-corner-up-right:before {
  content: "\e95b";
} /* '' */
.uil-creative-commons-pd-alt:before {
  content: "\e95c";
} /* '' */
.uil-creative-commons-pd:before {
  content: "\e95d";
} /* '' */
.uil-crockery:before {
  content: "\e95e";
} /* '' */
.uil-crop-alt-rotate-left:before {
  content: "\e95f";
} /* '' */
.uil-crop-alt-rotate-right:before {
  content: "\e960";
} /* '' */
.uil-crop-alt:before {
  content: "\e961";
} /* '' */
.uil-crosshair-alt:before {
  content: "\e962";
} /* '' */
.uil-crosshair:before {
  content: "\e963";
} /* '' */
.uil-crosshairs:before {
  content: "\e964";
} /* '' */
.uil-cube:before {
  content: "\e965";
} /* '' */
.uil-dashboard:before {
  content: "\e966";
} /* '' */
.uil-data-sharing:before {
  content: "\e967";
} /* '' */
.uil-database-alt:before {
  content: "\e968";
} /* '' */
.uil-database:before {
  content: "\e969";
} /* '' */
.uil-desert:before {
  content: "\e96a";
} /* '' */
.uil-desktop-alt-slash:before {
  content: "\e96b";
} /* '' */
.uil-desktop-alt:before {
  content: "\e96c";
} /* '' */
.uil-desktop-cloud-alt:before {
  content: "\e96d";
} /* '' */
.uil-desktop-slash:before {
  content: "\e96e";
} /* '' */
.uil-desktop:before {
  content: "\e96f";
} /* '' */
.uil-dialpad-alt:before {
  content: "\e970";
} /* '' */
.uil-dialpad:before {
  content: "\e971";
} /* '' */
.uil-diamond:before {
  content: "\e972";
} /* '' */
.uil-diary-alt:before {
  content: "\e973";
} /* '' */
.uil-diary:before {
  content: "\e974";
} /* '' */
.uil-dice-five:before {
  content: "\e975";
} /* '' */
.uil-dice-four:before {
  content: "\e976";
} /* '' */
.uil-dice-one:before {
  content: "\e977";
} /* '' */
.uil-dice-six:before {
  content: "\e978";
} /* '' */
.uil-dice-three:before {
  content: "\e979";
} /* '' */
.uil-dice-two:before {
  content: "\e97a";
} /* '' */
.uil-direction:before {
  content: "\e97b";
} /* '' */
.uil-directions:before {
  content: "\e97c";
} /* '' */
.uil-dizzy-meh:before {
  content: "\e97d";
} /* '' */
.uil-dna:before {
  content: "\e97e";
} /* '' */
.uil-document-layout-center:before {
  content: "\e97f";
} /* '' */
.uil-document-layout-left:before {
  content: "\e980";
} /* '' */
.uil-document-layout-right:before {
  content: "\e981";
} /* '' */
.uil-document:before {
  content: "\e982";
} /* '' */
.uil-dollar-alt:before {
  content: "\e983";
} /* '' */
.uil-dollar-sign-alt:before {
  content: "\e984";
} /* '' */
.uil-dollar-sign:before {
  content: "\e985";
} /* '' */
.uil-down-arrow:before {
  content: "\e986";
} /* '' */
.uil-download-alt:before {
  content: "\e987";
} /* '' */
.uil-dribbble:before {
  content: "\e988";
} /* '' */
.uil-drill:before {
  content: "\e989";
} /* '' */
.uil-dropbox:before {
  content: "\e98a";
} /* '' */
.uil-dumbbell:before {
  content: "\e98b";
} /* '' */
.uil-ear:before {
  content: "\e98c";
} /* '' */
.uil-edit-alt:before {
  content: "\e98d";
} /* '' */
.uil-edit:before {
  content: "\e98e";
} /* '' */
.uil-ellipsis-h:before {
  content: "\e98f";
} /* '' */
.uil-ellipsis-v:before {
  content: "\e990";
} /* '' */
.uil-emoji:before {
  content: "\e991";
} /* '' */
.uil-enter:before {
  content: "\e992";
} /* '' */
.uil-entry:before {
  content: "\e993";
} /* '' */
.uil-envelope-add:before {
  content: "\e994";
} /* '' */
.uil-envelope-alt:before {
  content: "\e995";
} /* '' */
.uil-envelope-block:before {
  content: "\e996";
} /* '' */
.uil-envelope-bookmark:before {
  content: "\e997";
} /* '' */
.uil-envelope-check:before {
  content: "\e998";
} /* '' */
.uil-envelope-download-alt:before {
  content: "\e999";
} /* '' */
.uil-envelope-download:before {
  content: "\e99a";
} /* '' */
.uil-envelope-edit:before {
  content: "\e99b";
} /* '' */
.uil-envelope-exclamation:before {
  content: "\e99c";
} /* '' */
.uil-envelope-heart:before {
  content: "\e99d";
} /* '' */
.uil-envelope-info:before {
  content: "\e99e";
} /* '' */
.uil-envelope-lock:before {
  content: "\e99f";
} /* '' */
.uil-envelope-minus:before {
  content: "\e9a0";
} /* '' */
.uil-envelope-open:before {
  content: "\e9a1";
} /* '' */
.uil-envelope-question:before {
  content: "\e9a2";
} /* '' */
.uil-envelope-receive:before {
  content: "\e9a3";
} /* '' */
.uil-envelope-redo:before {
  content: "\e9a4";
} /* '' */
.uil-envelope-search:before {
  content: "\e9a5";
} /* '' */
.uil-envelope-send:before {
  content: "\e9a6";
} /* '' */
.uil-envelope-share:before {
  content: "\e9a7";
} /* '' */
.uil-envelope-shield:before {
  content: "\e9a8";
} /* '' */
.uil-envelope-star:before {
  content: "\e9a9";
} /* '' */
.uil-envelope-times:before {
  content: "\e9aa";
} /* '' */
.uil-envelope-upload-alt:before {
  content: "\e9ab";
} /* '' */
.uil-envelope-upload:before {
  content: "\e9ac";
} /* '' */
.uil-envelope:before {
  content: "\e9ad";
} /* '' */
.uil-envelopes:before {
  content: "\e9ae";
} /* '' */
.uil-equal-circle:before {
  content: "\e9af";
} /* '' */
.uil-euro-circle:before {
  content: "\e9b0";
} /* '' */
.uil-euro:before {
  content: "\e9b1";
} /* '' */
.uil-exchange-alt:before {
  content: "\e9b2";
} /* '' */
.uil-exchange:before {
  content: "\e9b3";
} /* '' */
.uil-exclamation-circle:before {
  content: "\e9b4";
} /* '' */
.uil-exclamation-octagon:before {
  content: "\e9b5";
} /* '' */
.uil-exclamation-triangle:before {
  content: "\e9b6";
} /* '' */
.uil-exclude:before {
  content: "\e9b7";
} /* '' */
.uil-exit:before {
  content: "\e9b8";
} /* '' */
.uil-expand-alt:before {
  content: "\e9b9";
} /* '' */
.uil-expand-arrows-alt:before {
  content: "\e9ba";
} /* '' */
.uil-expand-arrows:before {
  content: "\e9bb";
} /* '' */
.uil-expand-from-corner:before {
  content: "\e9bc";
} /* '' */
.uil-expand-left:before {
  content: "\e9bd";
} /* '' */
.uil-expand-right:before {
  content: "\e9be";
} /* '' */
.uil-export:before {
  content: "\e9bf";
} /* '' */
.uil-exposure-alt:before {
  content: "\e9c0";
} /* '' */
.uil-exposure-increase:before {
  content: "\e9c1";
} /* '' */
.uil-external-link-alt:before {
  content: "\e9c2";
} /* '' */
.uil-eye-slash:before {
  content: "\e9c3";
} /* '' */
.uil-eye:before {
  content: "\e9c4";
} /* '' */
.uil-facebook-f:before {
  content: "\e9c5";
} /* '' */
.uil-facebook-messenger:before {
  content: "\e9c6";
} /* '' */
.uil-facebook:before {
  content: "\e9c7";
} /* '' */
.uil-fahrenheit:before {
  content: "\e9c8";
} /* '' */
.uil-fast-mail-alt:before {
  content: "\e9c9";
} /* '' */
.uil-fast-mail:before {
  content: "\e9ca";
} /* '' */
.uil-favorite:before {
  content: "\e9cb";
} /* '' */
.uil-feedback:before {
  content: "\e9cc";
} /* '' */
.uil-file-alt:before {
  content: "\e9cd";
} /* '' */
.uil-file-blank:before {
  content: "\e9ce";
} /* '' */
.uil-file-block-alt:before {
  content: "\e9cf";
} /* '' */
.uil-file-bookmark-alt:before {
  content: "\e9d0";
} /* '' */
.uil-file-check-alt:before {
  content: "\e9d1";
} /* '' */
.uil-file-check:before {
  content: "\e9d2";
} /* '' */
.uil-file-contract-dollar:before {
  content: "\e9d3";
} /* '' */
.uil-file-copy-alt:before {
  content: "\e9d4";
} /* '' */
.uil-file-download-alt:before {
  content: "\e9d5";
} /* '' */
.uil-file-download:before {
  content: "\e9d6";
} /* '' */
.uil-file-edit-alt:before {
  content: "\e9d7";
} /* '' */
.uil-file-exclamation-alt:before {
  content: "\e9d8";
} /* '' */
.uil-file-exclamation:before {
  content: "\e9d9";
} /* '' */
.uil-file-heart:before {
  content: "\e9da";
} /* '' */
.uil-file-info-alt:before {
  content: "\e9db";
} /* '' */
.uil-file-landscape-alt:before {
  content: "\e9dc";
} /* '' */
.uil-file-landscape:before {
  content: "\e9dd";
} /* '' */
.uil-file-lanscape-slash:before {
  content: "\e9de";
} /* '' */
.uil-file-lock-alt:before {
  content: "\e9df";
} /* '' */
.uil-file-medical-alt:before {
  content: "\e9e0";
} /* '' */
.uil-file-medical:before {
  content: "\e9e1";
} /* '' */
.uil-file-minus-alt:before {
  content: "\e9e2";
} /* '' */
.uil-file-minus:before {
  content: "\e9e3";
} /* '' */
.uil-file-network:before {
  content: "\e9e4";
} /* '' */
.uil-file-plus-alt:before {
  content: "\e9e5";
} /* '' */
.uil-file-plus:before {
  content: "\e9e6";
} /* '' */
.uil-file-question-alt:before {
  content: "\e9e7";
} /* '' */
.uil-file-question:before {
  content: "\e9e8";
} /* '' */
.uil-file-redo-alt:before {
  content: "\e9e9";
} /* '' */
.uil-file-search-alt:before {
  content: "\e9ea";
} /* '' */
.uil-file-share-alt:before {
  content: "\e9eb";
} /* '' */
.uil-file-shield-alt:before {
  content: "\e9ec";
} /* '' */
.uil-file-slash:before {
  content: "\e9ed";
} /* '' */
.uil-file-times-alt:before {
  content: "\e9ee";
} /* '' */
.uil-file-times:before {
  content: "\e9ef";
} /* '' */
.uil-file-upload-alt:before {
  content: "\e9f0";
} /* '' */
.uil-file-upload:before {
  content: "\e9f1";
} /* '' */
.uil-file:before {
  content: "\e9f2";
} /* '' */
.uil-files-landscapes-alt:before {
  content: "\e9f3";
} /* '' */
.uil-files-landscapes:before {
  content: "\e9f4";
} /* '' */
.uil-film:before {
  content: "\e9f5";
} /* '' */
.uil-filter-slash:before {
  content: "\e9f6";
} /* '' */
.uil-filter:before {
  content: "\e9f7";
} /* '' */
.uil-flask-potion:before {
  content: "\e9f8";
} /* '' */
.uil-flask:before {
  content: "\e9f9";
} /* '' */
.uil-flip-h-alt:before {
  content: "\e9fa";
} /* '' */
.uil-flip-h:before {
  content: "\e9fb";
} /* '' */
.uil-flip-v-alt:before {
  content: "\e9fc";
} /* '' */
.uil-flip-v:before {
  content: "\e9fd";
} /* '' */
.uil-flower:before {
  content: "\e9fe";
} /* '' */
.uil-focus-add:before {
  content: "\e9ff";
} /* '' */
.uil-focus-target:before {
  content: "\ea00";
} /* '' */
.uil-focus:before {
  content: "\ea01";
} /* '' */
.uil-folder-check:before {
  content: "\ea02";
} /* '' */
.uil-folder-download:before {
  content: "\ea03";
} /* '' */
.uil-folder-exclamation:before {
  content: "\ea04";
} /* '' */
.uil-folder-heart:before {
  content: "\ea05";
} /* '' */
.uil-folder-info:before {
  content: "\ea06";
} /* '' */
.uil-folder-lock:before {
  content: "\ea07";
} /* '' */
.uil-folder-medical:before {
  content: "\ea08";
} /* '' */
.uil-folder-minus:before {
  content: "\ea09";
} /* '' */
.uil-folder-network:before {
  content: "\ea0a";
} /* '' */
.uil-folder-plus:before {
  content: "\ea0b";
} /* '' */
.uil-folder-question:before {
  content: "\ea0c";
} /* '' */
.uil-folder-slash:before {
  content: "\ea0d";
} /* '' */
.uil-folder-times:before {
  content: "\ea0e";
} /* '' */
.uil-folder-upload:before {
  content: "\ea0f";
} /* '' */
.uil-folder:before {
  content: "\ea10";
} /* '' */
.uil-food:before {
  content: "\ea11";
} /* '' */
.uil-football-american:before {
  content: "\ea12";
} /* '' */
.uil-football-ball:before {
  content: "\ea13";
} /* '' */
.uil-football:before {
  content: "\ea14";
} /* '' */
.uil-forecastcloud-moon-tear:before {
  content: "\ea15";
} /* '' */
.uil-forwaded-call:before {
  content: "\ea16";
} /* '' */
.uil-forward:before {
  content: "\ea17";
} /* '' */
.uil-frown:before {
  content: "\ea18";
} /* '' */
.uil-game-structure:before {
  content: "\ea19";
} /* '' */
.uil-game:before {
  content: "\ea1a";
} /* '' */
.uil-gift:before {
  content: "\ea1b";
} /* '' */
.uil-github-alt:before {
  content: "\ea1c";
} /* '' */
.uil-github:before {
  content: "\ea1d";
} /* '' */
.uil-glass-martini-alt-slash:before {
  content: "\ea1e";
} /* '' */
.uil-glass-martini-alt:before {
  content: "\ea1f";
} /* '' */
.uil-glass-martini:before {
  content: "\ea20";
} /* '' */
.uil-glass-tea:before {
  content: "\ea21";
} /* '' */
.uil-glass:before {
  content: "\ea22";
} /* '' */
.uil-globe:before {
  content: "\ea23";
} /* '' */
.uil-gold:before {
  content: "\ea24";
} /* '' */
.uil-google-drive-alt:before {
  content: "\ea25";
} /* '' */
.uil-google-drive:before {
  content: "\ea26";
} /* '' */
.uil-google-hangouts:before {
  content: "\ea27";
} /* '' */
.uil-google-play:before {
  content: "\ea28";
} /* '' */
.uil-google:before {
  content: "\ea29";
} /* '' */
.uil-graduation-hat:before {
  content: "\ea2a";
} /* '' */
.uil-graph-bar:before {
  content: "\ea2b";
} /* '' */
.uil-grid:before {
  content: "\ea2c";
} /* '' */
.uil-grids:before {
  content: "\ea2d";
} /* '' */
.uil-grin-tongue-wink-alt:before {
  content: "\ea2e";
} /* '' */
.uil-grin-tongue-wink:before {
  content: "\ea2f";
} /* '' */
.uil-grin:before {
  content: "\ea30";
} /* '' */
.uil-grip-horizontal-line:before {
  content: "\ea31";
} /* '' */
.uil-hdd:before {
  content: "\ea32";
} /* '' */
.uil-headphones-alt:before {
  content: "\ea33";
} /* '' */
.uil-headphones:before {
  content: "\ea34";
} /* '' */
.uil-heart-alt:before {
  content: "\ea35";
} /* '' */
.uil-heart-medical:before {
  content: "\ea36";
} /* '' */
.uil-heart-rate:before {
  content: "\ea37";
} /* '' */
.uil-heart-sign:before {
  content: "\ea38";
} /* '' */
.uil-heart:before {
  content: "\ea39";
} /* '' */
.uil-heartbeat:before {
  content: "\ea3a";
} /* '' */
.uil-history-alt:before {
  content: "\ea3b";
} /* '' */
.uil-history:before {
  content: "\ea3c";
} /* '' */
.uil-home-alt:before {
  content: "\ea3d";
} /* '' */
.uil-home:before {
  content: "\ea3e";
} /* '' */
.uil-horizontal-align-center:before {
  content: "\ea3f";
} /* '' */
.uil-horizontal-align-left:before {
  content: "\ea40";
} /* '' */
.uil-horizontal-align-right:before {
  content: "\ea41";
} /* '' */
.uil-horizontal-distribution-center:before {
  content: "\ea42";
} /* '' */
.uil-horizontal-distribution-left:before {
  content: "\ea43";
} /* '' */
.uil-horizontal-distribution-right:before {
  content: "\ea44";
} /* '' */
.uil-hunting:before {
  content: "\ea45";
} /* '' */
.uil-image-alt-slash:before {
  content: "\ea46";
} /* '' */
.uil-image-block:before {
  content: "\ea47";
} /* '' */
.uil-image-broken:before {
  content: "\ea48";
} /* '' */
.uil-image-check:before {
  content: "\ea49";
} /* '' */
.uil-image-download:before {
  content: "\ea4a";
} /* '' */
.uil-image-edit:before {
  content: "\ea4b";
} /* '' */
.uil-image-lock:before {
  content: "\ea4c";
} /* '' */
.uil-image-minus:before {
  content: "\ea4d";
} /* '' */
.uil-image-plus:before {
  content: "\ea4e";
} /* '' */
.uil-image-question:before {
  content: "\ea4f";
} /* '' */
.uil-image-redo:before {
  content: "\ea50";
} /* '' */
.uil-image-resize-landscape:before {
  content: "\ea51";
} /* '' */
.uil-image-resize-square:before {
  content: "\ea52";
} /* '' */
.uil-image-search:before {
  content: "\ea53";
} /* '' */
.uil-image-share:before {
  content: "\ea54";
} /* '' */
.uil-image-shield:before {
  content: "\ea55";
} /* '' */
.uil-image-slash:before {
  content: "\ea56";
} /* '' */
.uil-image-times:before {
  content: "\ea57";
} /* '' */
.uil-image-upload:before {
  content: "\ea58";
} /* '' */
.uil-image-v:before {
  content: "\ea59";
} /* '' */
.uil-image:before {
  content: "\ea5a";
} /* '' */
.uil-images:before {
  content: "\ea5b";
} /* '' */
.uil-incoming-call:before {
  content: "\ea5c";
} /* '' */
.uil-info-circle:before {
  content: "\ea5d";
} /* '' */
.uil-instagram-alt:before {
  content: "\ea5e";
} /* '' */
.uil-instagram:before {
  content: "\ea5f";
} /* '' */
.uil-intercom:before {
  content: "\ea60";
} /* '' */
.uil-invoice:before {
  content: "\ea61";
} /* '' */
.uil-italic:before {
  content: "\ea62";
} /* '' */
.uil-jackhammer:before {
  content: "\ea63";
} /* '' */
.uil-java-script:before {
  content: "\ea64";
} /* '' */
.uil-kayak:before {
  content: "\ea65";
} /* '' */
.uil-key-skeleton-alt:before {
  content: "\ea66";
} /* '' */
.uil-key-skeleton:before {
  content: "\ea67";
} /* '' */
.uil-keyboard-alt:before {
  content: "\ea68";
} /* '' */
.uil-keyboard-hide:before {
  content: "\ea69";
} /* '' */
.uil-keyboard-show:before {
  content: "\ea6a";
} /* '' */
.uil-keyboard:before {
  content: "\ea6b";
} /* '' */
.uil-keyhole-circle:before {
  content: "\ea6c";
} /* '' */
.uil-keyhole-square-full:before {
  content: "\ea6d";
} /* '' */
.uil-keyhole-square:before {
  content: "\ea6e";
} /* '' */
.uil-kid:before {
  content: "\ea6f";
} /* '' */
.uil-label-alt:before {
  content: "\ea70";
} /* '' */
.uil-label:before {
  content: "\ea71";
} /* '' */
.uil-lamp:before {
  content: "\ea72";
} /* '' */
.uil-laptop-cloud:before {
  content: "\ea73";
} /* '' */
.uil-laptop:before {
  content: "\ea74";
} /* '' */
.uil-laughing:before {
  content: "\ea75";
} /* '' */
.uil-layer-group-slash:before {
  content: "\ea76";
} /* '' */
.uil-layer-group:before {
  content: "\ea77";
} /* '' */
.uil-layers-alt:before {
  content: "\ea78";
} /* '' */
.uil-layers-slash:before {
  content: "\ea79";
} /* '' */
.uil-layers:before {
  content: "\ea7a";
} /* '' */
.uil-left-arrow-from-left:before {
  content: "\ea7b";
} /* '' */
.uil-left-arrow-to-left:before {
  content: "\ea7c";
} /* '' */
.uil-left-indent-alt:before {
  content: "\ea7d";
} /* '' */
.uil-left-indent:before {
  content: "\ea7e";
} /* '' */
.uil-left-to-right-text-direction:before {
  content: "\ea7f";
} /* '' */
.uil-life-ring:before {
  content: "\ea80";
} /* '' */
.uil-lightbulb-alt:before {
  content: "\ea81";
} /* '' */
.uil-lightbulb:before {
  content: "\ea82";
} /* '' */
.uil-line-alt:before {
  content: "\ea83";
} /* '' */
.uil-line-spacing:before {
  content: "\ea84";
} /* '' */
.uil-line:before {
  content: "\ea85";
} /* '' */
.uil-link-alt:before {
  content: "\ea86";
} /* '' */
.uil-link-broken:before {
  content: "\ea87";
} /* '' */
.uil-link-h:before {
  content: "\ea88";
} /* '' */
.uil-link:before {
  content: "\ea89";
} /* '' */
.uil-linkedin-alt:before {
  content: "\ea8a";
} /* '' */
.uil-linkedin:before {
  content: "\ea8b";
} /* '' */
.uil-list-ui-alt:before {
  content: "\ea8c";
} /* '' */
.uil-list-ul:before {
  content: "\ea8d";
} /* '' */
.uil-location-arrow-alt:before {
  content: "\ea8e";
} /* '' */
.uil-location-arrow:before {
  content: "\ea8f";
} /* '' */
.uil-location-pin-alt:before {
  content: "\ea90";
} /* '' */
.uil-location-point:before {
  content: "\ea91";
} /* '' */
.uil-location:before {
  content: "\ea92";
} /* '' */
.uil-lock-access:before {
  content: "\ea93";
} /* '' */
.uil-lock-alt:before {
  content: "\ea94";
} /* '' */
.uil-lock-open-alt:before {
  content: "\ea95";
} /* '' */
.uil-lock-slash:before {
  content: "\ea96";
} /* '' */
.uil-lock:before {
  content: "\ea97";
} /* '' */
.uil-mailbox-alt:before {
  content: "\ea98";
} /* '' */
.uil-mailbox:before {
  content: "\ea99";
} /* '' */
.uil-map-marker-alt:before {
  content: "\ea9a";
} /* '' */
.uil-map-marker-edit:before {
  content: "\ea9b";
} /* '' */
.uil-map-marker-info:before {
  content: "\ea9c";
} /* '' */
.uil-map-marker-minus:before {
  content: "\ea9d";
} /* '' */
.uil-map-marker-plus:before {
  content: "\ea9e";
} /* '' */
.uil-map-marker-question:before {
  content: "\ea9f";
} /* '' */
.uil-map-marker-shield:before {
  content: "\eaa0";
} /* '' */
.uil-map-marker-slash:before {
  content: "\eaa1";
} /* '' */
.uil-map-marker:before {
  content: "\eaa2";
} /* '' */
.uil-map-pin-alt:before {
  content: "\eaa3";
} /* '' */
.uil-map-pin:before {
  content: "\eaa4";
} /* '' */
.uil-map:before {
  content: "\eaa5";
} /* '' */
.uil-mars:before {
  content: "\eaa6";
} /* '' */
.uil-maximize-left:before {
  content: "\eaa7";
} /* '' */
.uil-medal:before {
  content: "\eaa8";
} /* '' */
.uil-medical-drip:before {
  content: "\eaa9";
} /* '' */
.uil-medical-square-full:before {
  content: "\eaaa";
} /* '' */
.uil-medical-square:before {
  content: "\eaab";
} /* '' */
.uil-medical:before {
  content: "\eaac";
} /* '' */
.uil-medium-m:before {
  content: "\eaad";
} /* '' */
.uil-medkit:before {
  content: "\eaae";
} /* '' */
.uil-meeting-board:before {
  content: "\eaaf";
} /* '' */
.uil-meh-alt:before {
  content: "\eab0";
} /* '' */
.uil-meh-closed-eye:before {
  content: "\eab1";
} /* '' */
.uil-meh:before {
  content: "\eab2";
} /* '' */
.uil-message:before {
  content: "\eab3";
} /* '' */
.uil-microphone-slash:before {
  content: "\eab4";
} /* '' */
.uil-microphone:before {
  content: "\eab5";
} /* '' */
.uil-minus-circle:before {
  content: "\eab6";
} /* '' */
.uil-minus-path:before {
  content: "\eab7";
} /* '' */
.uil-minus-square-full:before {
  content: "\eab8";
} /* '' */
.uil-minus-square:before {
  content: "\eab9";
} /* '' */
.uil-minus:before {
  content: "\eaba";
} /* '' */
.uil-missed-call:before {
  content: "\eabb";
} /* '' */
.uil-mobey-bill-slash:before {
  content: "\eabc";
} /* '' */
.uil-mobile-android-alt:before {
  content: "\eabd";
} /* '' */
.uil-mobile-android:before {
  content: "\eabe";
} /* '' */
.uil-mobile-vibrate:before {
  content: "\eabf";
} /* '' */
.uil-modem:before {
  content: "\eac0";
} /* '' */
.uil-money-bill-stack:before {
  content: "\eac1";
} /* '' */
.uil-money-bill:before {
  content: "\eac2";
} /* '' */
.uil-money-insert:before {
  content: "\eac3";
} /* '' */
.uil-money-stack:before {
  content: "\eac4";
} /* '' */
.uil-money-withdraw:before {
  content: "\eac5";
} /* '' */
.uil-money-withdrawal:before {
  content: "\eac6";
} /* '' */
.uil-moneybag-alt:before {
  content: "\eac7";
} /* '' */
.uil-moneybag:before {
  content: "\eac8";
} /* '' */
.uil-monitor-heart-rate:before {
  content: "\eac9";
} /* '' */
.uil-monitor:before {
  content: "\eaca";
} /* '' */
.uil-moon-eclipse:before {
  content: "\eacb";
} /* '' */
.uil-moon:before {
  content: "\eacc";
} /* '' */
.uil-moonset:before {
  content: "\eacd";
} /* '' */
.uil-mountains-sun:before {
  content: "\eace";
} /* '' */
.uil-mountains:before {
  content: "\eacf";
} /* '' */
.uil-mouse-alt:before {
  content: "\ead0";
} /* '' */
.uil-mouse:before {
  content: "\ead1";
} /* '' */
.uil-multiply:before {
  content: "\ead2";
} /* '' */
.uil-music-note:before {
  content: "\ead3";
} /* '' */
.uil-music-tune-slash:before {
  content: "\ead4";
} /* '' */
.uil-music:before {
  content: "\ead5";
} /* '' */
.uil-n-a:before {
  content: "\ead6";
} /* '' */
.uil-navigator:before {
  content: "\ead7";
} /* '' */
.uil-nerd:before {
  content: "\ead8";
} /* '' */
.uil-newspaper:before {
  content: "\ead9";
} /* '' */
.uil-ninja:before {
  content: "\eada";
} /* '' */
.uil-no-entry:before {
  content: "\eadb";
} /* '' */
.uil-notebooks:before {
  content: "\eadc";
} /* '' */
.uil-notes:before {
  content: "\eadd";
} /* '' */
.uil-object-group:before {
  content: "\eade";
} /* '' */
.uil-object-ungroup:before {
  content: "\eadf";
} /* '' */
.uil-octagon:before {
  content: "\eae0";
} /* '' */
.uil-outgoing-call:before {
  content: "\eae1";
} /* '' */
.uil-package:before {
  content: "\eae2";
} /* '' */
.uil-padlock:before {
  content: "\eae3";
} /* '' */
.uil-paint-tool:before {
  content: "\eae4";
} /* '' */
.uil-palette:before {
  content: "\eae5";
} /* '' */
.uil-panorama-h-alt:before {
  content: "\eae6";
} /* '' */
.uil-panorama-h:before {
  content: "\eae7";
} /* '' */
.uil-panorama-v:before {
  content: "\eae8";
} /* '' */
.uil-paperclip:before {
  content: "\eae9";
} /* '' */
.uil-paragraph:before {
  content: "\eaea";
} /* '' */
.uil-parcel:before {
  content: "\eaeb";
} /* '' */
.uil-parking-square:before {
  content: "\eaec";
} /* '' */
.uil-pathfinder-unite:before {
  content: "\eaed";
} /* '' */
.uil-pathfinder:before {
  content: "\eaee";
} /* '' */
.uil-pause-circle:before {
  content: "\eaef";
} /* '' */
.uil-pause:before {
  content: "\eaf0";
} /* '' */
.uil-paypal:before {
  content: "\eaf1";
} /* '' */
.uil-pen:before {
  content: "\eaf2";
} /* '' */
.uil-pentagon:before {
  content: "\eaf3";
} /* '' */
.uil-phone-alt:before {
  content: "\eaf4";
} /* '' */
.uil-phone-pause:before {
  content: "\eaf5";
} /* '' */
.uil-phone-slash:before {
  content: "\eaf6";
} /* '' */
.uil-phone-times:before {
  content: "\eaf7";
} /* '' */
.uil-phone-volume:before {
  content: "\eaf8";
} /* '' */
.uil-phone:before {
  content: "\eaf9";
} /* '' */
.uil-picture:before {
  content: "\eafa";
} /* '' */
.uil-plane-arrival:before {
  content: "\eafb";
} /* '' */
.uil-plane-departure:before {
  content: "\eafc";
} /* '' */
.uil-plane-fly:before {
  content: "\eafd";
} /* '' */
.uil-plane:before {
  content: "\eafe";
} /* '' */
.uil-play-circle:before {
  content: "\eaff";
} /* '' */
.uil-play:before {
  content: "\eb00";
} /* '' */
.uil-plug:before {
  content: "\eb01";
} /* '' */
.uil-plus-circle:before {
  content: "\eb02";
} /* '' */
.uil-plus-square:before {
  content: "\eb03";
} /* '' */
.uil-plus:before {
  content: "\eb04";
} /* '' */
.uil-podium:before {
  content: "\eb05";
} /* '' */
.uil-polygon:before {
  content: "\eb06";
} /* '' */
.uil-post-stamp:before {
  content: "\eb07";
} /* '' */
.uil-postcard:before {
  content: "\eb08";
} /* '' */
.uil-pound-circle:before {
  content: "\eb09";
} /* '' */
.uil-pound:before {
  content: "\eb0a";
} /* '' */
.uil-power:before {
  content: "\eb0b";
} /* '' */
.uil-prescription-bottle:before {
  content: "\eb0c";
} /* '' */
.uil-presentation-check:before {
  content: "\eb0d";
} /* '' */
.uil-presentation-edit:before {
  content: "\eb0e";
} /* '' */
.uil-presentation-line:before {
  content: "\eb0f";
} /* '' */
.uil-presentation-lines-alt:before {
  content: "\eb10";
} /* '' */
.uil-presentation-minus:before {
  content: "\eb11";
} /* '' */
.uil-presentation-play:before {
  content: "\eb12";
} /* '' */
.uil-presentation-plus:before {
  content: "\eb13";
} /* '' */
.uil-presentation-times:before {
  content: "\eb14";
} /* '' */
.uil-presentation:before {
  content: "\eb15";
} /* '' */
.uil-previous:before {
  content: "\eb16";
} /* '' */
.uil-pricetag-alt:before {
  content: "\eb17";
} /* '' */
.uil-print-slash:before {
  content: "\eb18";
} /* '' */
.uil-print:before {
  content: "\eb19";
} /* '' */
.uil-processor:before {
  content: "\eb1a";
} /* '' */
.uil-pump:before {
  content: "\eb1b";
} /* '' */
.uil-puzzle-piece:before {
  content: "\eb1c";
} /* '' */
.uil-question-circle:before {
  content: "\eb1d";
} /* '' */
.uil-raddit-alien-alt:before {
  content: "\eb1e";
} /* '' */
.uil-rainbow:before {
  content: "\eb1f";
} /* '' */
.uil-raindrops-alt:before {
  content: "\eb20";
} /* '' */
.uil-raindrops:before {
  content: "\eb21";
} /* '' */
.uil-receipt-alt:before {
  content: "\eb22";
} /* '' */
.uil-receipt:before {
  content: "\eb23";
} /* '' */
.uil-record-audio:before {
  content: "\eb24";
} /* '' */
.uil-redo:before {
  content: "\eb25";
} /* '' */
.uil-refresh:before {
  content: "\eb26";
} /* '' */
.uil-registered:before {
  content: "\eb27";
} /* '' */
.uil-repeat:before {
  content: "\eb28";
} /* '' */
.uil-restaurant:before {
  content: "\eb29";
} /* '' */
.uil-right-indent-alt:before {
  content: "\eb2a";
} /* '' */
.uil-right-to-left-text-direction:before {
  content: "\eb2b";
} /* '' */
.uil-robot:before {
  content: "\eb2c";
} /* '' */
.uil-rope-way:before {
  content: "\eb2d";
} /* '' */
.uil-rotate-360:before {
  content: "\eb2e";
} /* '' */
.uil-rss-alt:before {
  content: "\eb2f";
} /* '' */
.uil-rss-interface:before {
  content: "\eb30";
} /* '' */
.uil-rss:before {
  content: "\eb31";
} /* '' */
.uil-ruler-combined:before {
  content: "\eb32";
} /* '' */
.uil-ruler:before {
  content: "\eb33";
} /* '' */
.uil-sad-cry:before {
  content: "\eb34";
} /* '' */
.uil-sad-crying:before {
  content: "\eb35";
} /* '' */
.uil-sad-dizzy:before {
  content: "\eb36";
} /* '' */
.uil-sad-squint:before {
  content: "\eb37";
} /* '' */
.uil-sad:before {
  content: "\eb38";
} /* '' */
.uil-scaling-left:before {
  content: "\eb39";
} /* '' */
.uil-scaling-right:before {
  content: "\eb3a";
} /* '' */
.uil-scenery:before {
  content: "\eb3b";
} /* '' */
.uil-schedule:before {
  content: "\eb3c";
} /* '' */
.uil-science:before {
  content: "\eb3d";
} /* '' */
.uil-screw:before {
  content: "\eb3e";
} /* '' */
.uil-scroll-h:before {
  content: "\eb3f";
} /* '' */
.uil-scroll:before {
  content: "\eb40";
} /* '' */
.uil-search-alt:before {
  content: "\eb41";
} /* '' */
.uil-search-minus:before {
  content: "\eb42";
} /* '' */
.uil-search-plus:before {
  content: "\eb43";
} /* '' */
.uil-search:before {
  content: "\eb44";
} /* '' */
.uil-selfie:before {
  content: "\eb45";
} /* '' */
.uil-server-alt:before {
  content: "\eb46";
} /* '' */
.uil-server-connection:before {
  content: "\eb47";
} /* '' */
.uil-server-network-alt:before {
  content: "\eb48";
} /* '' */
.uil-server-network:before {
  content: "\eb49";
} /* '' */
.uil-server:before {
  content: "\eb4a";
} /* '' */
.uil-servers:before {
  content: "\eb4b";
} /* '' */
.uil-servicemark:before {
  content: "\eb4c";
} /* '' */
.uil-share-alt:before {
  content: "\eb4d";
} /* '' */
.uil-shield-check:before {
  content: "\eb4e";
} /* '' */
.uil-shield-exclamation:before {
  content: "\eb4f";
} /* '' */
.uil-shield-question:before {
  content: "\eb50";
} /* '' */
.uil-shield-slash:before {
  content: "\eb51";
} /* '' */
.uil-shield:before {
  content: "\eb52";
} /* '' */
.uil-ship:before {
  content: "\eb53";
} /* '' */
.uil-shop:before {
  content: "\eb54";
} /* '' */
.uil-shopping-basket:before {
  content: "\eb55";
} /* '' */
.uil-shopping-cart-alt:before {
  content: "\eb56";
} /* '' */
.uil-shopping-trolley:before {
  content: "\eb57";
} /* '' */
.uil-shovel:before {
  content: "\eb58";
} /* '' */
.uil-shrink:before {
  content: "\eb59";
} /* '' */
.uil-shuffle:before {
  content: "\eb5a";
} /* '' */
.uil-shutter-alt:before {
  content: "\eb5b";
} /* '' */
.uil-shutter:before {
  content: "\eb5c";
} /* '' */
.uil-sick:before {
  content: "\eb5d";
} /* '' */
.uil-sigma:before {
  content: "\eb5e";
} /* '' */
.uil-sign-alt:before {
  content: "\eb5f";
} /* '' */
.uil-sign-in-alt:before {
  content: "\eb60";
} /* '' */
.uil-sign-left:before {
  content: "\eb61";
} /* '' */
.uil-sign-out-alt:before {
  content: "\eb62";
} /* '' */
.uil-sign-right:before {
  content: "\eb63";
} /* '' */
.uil-signal-alt-3:before {
  content: "\eb64";
} /* '' */
.uil-signal-alt:before {
  content: "\eb65";
} /* '' */
.uil-signal:before {
  content: "\eb66";
} /* '' */
.uil-silence:before {
  content: "\eb67";
} /* '' */
.uil-silent-squint:before {
  content: "\eb68";
} /* '' */
.uil-sim-card:before {
  content: "\eb69";
} /* '' */
.uil-sitemap:before {
  content: "\eb6a";
} /* '' */
.uil-skip-forward-alt:before {
  content: "\eb6b";
} /* '' */
.uil-skip-forward-circle:before {
  content: "\eb6c";
} /* '' */
.uil-skip-forward:before {
  content: "\eb6d";
} /* '' */
.uil-slack-alt:before {
  content: "\eb6e";
} /* '' */
.uil-slack:before {
  content: "\eb6f";
} /* '' */
.uil-sliders-v-alt:before {
  content: "\eb70";
} /* '' */
.uil-sliders-v:before {
  content: "\eb71";
} /* '' */
.uil-smile-beam:before {
  content: "\eb72";
} /* '' */
.uil-smile-dizzy:before {
  content: "\eb73";
} /* '' */
.uil-smile-squint-wink-alt:before {
  content: "\eb74";
} /* '' */
.uil-smile-squint-wink:before {
  content: "\eb75";
} /* '' */
.uil-smile-wink-alt:before {
  content: "\eb76";
} /* '' */
.uil-smile-wink:before {
  content: "\eb77";
} /* '' */
.uil-smile:before {
  content: "\eb78";
} /* '' */
.uil-snapchat-ghost:before {
  content: "\eb79";
} /* '' */
.uil-snapchat-square:before {
  content: "\eb7a";
} /* '' */
.uil-snow-flake:before {
  content: "\eb7b";
} /* '' */
.uil-snowflake-alt:before {
  content: "\eb7c";
} /* '' */
.uil-snowflake:before {
  content: "\eb7d";
} /* '' */
.uil-sort-amount-down:before {
  content: "\eb7e";
} /* '' */
.uil-sort-amount-up:before {
  content: "\eb7f";
} /* '' */
.uil-sort:before {
  content: "\eb80";
} /* '' */
.uil-sorting:before {
  content: "\eb81";
} /* '' */
.uil-space-key:before {
  content: "\eb82";
} /* '' */
.uil-spade:before {
  content: "\eb83";
} /* '' */
.uil-sperms:before {
  content: "\eb84";
} /* '' */
.uil-spin:before {
  content: "\eb85";
} /* '' */
.uil-sport:before {
  content: "\eb86";
} /* '' */
.uil-square-full:before {
  content: "\eb87";
} /* '' */
.uil-square-shape:before {
  content: "\eb88";
} /* '' */
.uil-square:before {
  content: "\eb89";
} /* '' */
.uil-squint:before {
  content: "\eb8a";
} /* '' */
.uil-star-half-alt:before {
  content: "\eb8b";
} /* '' */
.uil-star:before {
  content: "\eb8c";
} /* '' */
.uil-step-backward-alt:before {
  content: "\eb8d";
} /* '' */
.uil-step-backward-circle:before {
  content: "\eb8e";
} /* '' */
.uil-step-backward:before {
  content: "\eb8f";
} /* '' */
.uil-step-forward:before {
  content: "\eb90";
} /* '' */
.uil-stop-circle:before {
  content: "\eb91";
} /* '' */
.uil-stopwatch-slash:before {
  content: "\eb92";
} /* '' */
.uil-stopwatch:before {
  content: "\eb93";
} /* '' */
.uil-store-alt:before {
  content: "\eb94";
} /* '' */
.uil-store:before {
  content: "\eb95";
} /* '' */
.uil-streering:before {
  content: "\eb96";
} /* '' */
.uil-stretcher:before {
  content: "\eb97";
} /* '' */
.uil-subject:before {
  content: "\eb98";
} /* '' */
.uil-subway-alt:before {
  content: "\eb99";
} /* '' */
.uil-subway:before {
  content: "\eb9a";
} /* '' */
.uil-suitcase-alt:before {
  content: "\eb9b";
} /* '' */
.uil-suitcase:before {
  content: "\eb9c";
} /* '' */
.uil-sun:before {
  content: "\eb9d";
} /* '' */
.uil-sunset:before {
  content: "\eb9e";
} /* '' */
.uil-surprise:before {
  content: "\eb9f";
} /* '' */
.uil-swatchbook:before {
  content: "\eba0";
} /* '' */
.uil-swimmer:before {
  content: "\eba1";
} /* '' */
.uil-symbol:before {
  content: "\eba2";
} /* '' */
.uil-sync-exclamation:before {
  content: "\eba3";
} /* '' */
.uil-sync-slash:before {
  content: "\eba4";
} /* '' */
.uil-sync:before {
  content: "\eba5";
} /* '' */
.uil-syringe:before {
  content: "\eba6";
} /* '' */
.uil-table:before {
  content: "\eba7";
} /* '' */
.uil-tablet:before {
  content: "\eba8";
} /* '' */
.uil-tablets:before {
  content: "\eba9";
} /* '' */
.uil-tachometer-fast:before {
  content: "\ebaa";
} /* '' */
.uil-tag-alt:before {
  content: "\ebab";
} /* '' */
.uil-tag:before {
  content: "\ebac";
} /* '' */
.uil-tape:before {
  content: "\ebad";
} /* '' */
.uil-taxi:before {
  content: "\ebae";
} /* '' */
.uil-tear:before {
  content: "\ebaf";
} /* '' */
.uil-technology:before {
  content: "\ebb0";
} /* '' */
.uil-telescope:before {
  content: "\ebb1";
} /* '' */
.uil-temperature-empty:before {
  content: "\ebb2";
} /* '' */
.uil-temperature-half:before {
  content: "\ebb3";
} /* '' */
.uil-temperature-minus:before {
  content: "\ebb4";
} /* '' */
.uil-temperature-plus:before {
  content: "\ebb5";
} /* '' */
.uil-temperature-quarter:before {
  content: "\ebb6";
} /* '' */
.uil-temperature-three-quarter:before {
  content: "\ebb7";
} /* '' */
.uil-temperature:before {
  content: "\ebb8";
} /* '' */
.uil-text-fields:before {
  content: "\ebb9";
} /* '' */
.uil-text-size:before {
  content: "\ebba";
} /* '' */
.uil-text-strike-through:before {
  content: "\ebbb";
} /* '' */
.uil-text:before {
  content: "\ebbc";
} /* '' */
.uil-th-large:before {
  content: "\ebbd";
} /* '' */
.uil-th-slash:before {
  content: "\ebbe";
} /* '' */
.uil-th:before {
  content: "\ebbf";
} /* '' */
.uil-thermometer:before {
  content: "\ebc0";
} /* '' */
.uil-thumbs-down:before {
  content: "\ebc1";
} /* '' */
.uil-thumbs-up:before {
  content: "\ebc2";
} /* '' */
.uil-thunderstorm-moon:before {
  content: "\ebc3";
} /* '' */
.uil-thunderstorm-sun:before {
  content: "\ebc4";
} /* '' */
.uil-thunderstorm:before {
  content: "\ebc5";
} /* '' */
.uil-ticket:before {
  content: "\ebc6";
} /* '' */
.uil-times-circle:before {
  content: "\ebc7";
} /* '' */
.uil-times-square:before {
  content: "\ebc8";
} /* '' */
.uil-times:before {
  content: "\ebc9";
} /* '' */
.uil-toggle-off:before {
  content: "\ebca";
} /* '' */
.uil-toggle-on:before {
  content: "\ebcb";
} /* '' */
.uil-top-arrow-from-top:before {
  content: "\ebcc";
} /* '' */
.uil-top-arrow-to-top:before {
  content: "\ebcd";
} /* '' */
.uil-tornado:before {
  content: "\ebce";
} /* '' */
.uil-trademark-circle:before {
  content: "\ebcf";
} /* '' */
.uil-trademark:before {
  content: "\ebd0";
} /* '' */
.uil-traffic-barrier:before {
  content: "\ebd1";
} /* '' */
.uil-trash-alt:before {
  content: "\ebd2";
} /* '' */
.uil-trash:before {
  content: "\ebd3";
} /* '' */
.uil-trees:before {
  content: "\ebd4";
} /* '' */
.uil-triangle:before {
  content: "\ebd5";
} /* '' */
.uil-trophy:before {
  content: "\ebd6";
} /* '' */
.uil-trowel:before {
  content: "\ebd7";
} /* '' */
.uil-truck-case:before {
  content: "\ebd8";
} /* '' */
.uil-truck-loading:before {
  content: "\ebd9";
} /* '' */
.uil-truck:before {
  content: "\ebda";
} /* '' */
.uil-tumblr-square:before {
  content: "\ebdb";
} /* '' */
.uil-tumblr:before {
  content: "\ebdc";
} /* '' */
.uil-tv-retro-slash:before {
  content: "\ebdd";
} /* '' */
.uil-tv-retro:before {
  content: "\ebde";
} /* '' */
.uil-twitter:before {
  content: "\ebdf";
} /* '' */
.uil-umbrella:before {
  content: "\ebe0";
} /* '' */
.uil-unamused:before {
  content: "\ebe1";
} /* '' */
.uil-underline:before {
  content: "\ebe2";
} /* '' */
.uil-unlock-alt:before {
  content: "\ebe3";
} /* '' */
.uil-unlock:before {
  content: "\ebe4";
} /* '' */
.uil-upload-alt:before {
  content: "\ebe5";
} /* '' */
.uil-upload:before {
  content: "\ebe6";
} /* '' */
.uil-usd-circle:before {
  content: "\ebe7";
} /* '' */
.uil-usd-square:before {
  content: "\ebe8";
} /* '' */
.uil-user-check:before {
  content: "\ebe9";
} /* '' */
.uil-user-circle:before {
  content: "\ebea";
} /* '' */
.uil-user-exclamation:before {
  content: "\ebeb";
} /* '' */
.uil-user-hard-hat:before {
  content: "\ebec";
} /* '' */
.uil-user-minus:before {
  content: "\ebed";
} /* '' */
.uil-user-plus:before {
  content: "\ebee";
} /* '' */
.uil-user-square:before {
  content: "\ebef";
} /* '' */
.uil-user-times:before {
  content: "\ebf0";
} /* '' */
.uil-user:before {
  content: "\ebf1";
} /* '' */
.uil-users-alt:before {
  content: "\ebf2";
} /* '' */
.uil-utensils-alt:before {
  content: "\ebf3";
} /* '' */
.uil-utensils:before {
  content: "\ebf4";
} /* '' */
.uil-vector-square-alt:before {
  content: "\ebf5";
} /* '' */
.uil-vector-square:before {
  content: "\ebf6";
} /* '' */
.uil-venus:before {
  content: "\ebf7";
} /* '' */
.uil-vertical-align-bottom:before {
  content: "\ebf8";
} /* '' */
.uil-vertical-align-center:before {
  content: "\ebf9";
} /* '' */
.uil-vertical-align-top:before {
  content: "\ebfa";
} /* '' */
.uil-vertical-distribute-bottom:before {
  content: "\ebfb";
} /* '' */
.uil-vertical-distribution-center:before {
  content: "\ebfc";
} /* '' */
.uil-vertical-distribution-top:before {
  content: "\ebfd";
} /* '' */
.uil-video-slash:before {
  content: "\ebfe";
} /* '' */
.uil-video:before {
  content: "\ebff";
} /* '' */
.uil-visual-studio:before {
  content: "\ec00";
} /* '' */
.uil-voicemail-rectangle:before {
  content: "\ec01";
} /* '' */
.uil-voicemail:before {
  content: "\ec02";
} /* '' */
.uil-volleyball:before {
  content: "\ec03";
} /* '' */
.uil-volume-down:before {
  content: "\ec04";
} /* '' */
.uil-volume-mute:before {
  content: "\ec05";
} /* '' */
.uil-volume-off:before {
  content: "\ec06";
} /* '' */
.uil-volume-up:before {
  content: "\ec07";
} /* '' */
.uil-volume:before {
  content: "\ec08";
} /* '' */
.uil-vuejs-alt:before {
  content: "\ec09";
} /* '' */
.uil-vuejs:before {
  content: "\ec0a";
} /* '' */
.uil-wall:before {
  content: "\ec0b";
} /* '' */
.uil-wallet:before {
  content: "\ec0c";
} /* '' */
.uil-watch-alt:before {
  content: "\ec0d";
} /* '' */
.uil-watch:before {
  content: "\ec0e";
} /* '' */
.uil-water-drop-slash:before {
  content: "\ec0f";
} /* '' */
.uil-water-glass:before {
  content: "\ec10";
} /* '' */
.uil-water:before {
  content: "\ec11";
} /* '' */
.uil-web-grid-alt:before {
  content: "\ec12";
} /* '' */
.uil-web-grid:before {
  content: "\ec13";
} /* '' */
.uil-web-section-alt:before {
  content: "\ec14";
} /* '' */
.uil-web-section:before {
  content: "\ec15";
} /* '' */
.uil-webcam:before {
  content: "\ec16";
} /* '' */
.uil-weight:before {
  content: "\ec17";
} /* '' */
.uil-whatsapp:before {
  content: "\ec18";
} /* '' */
.uil-wheel-barrow:before {
  content: "\ec19";
} /* '' */
.uil-wheelchair-alt:before {
  content: "\ec1a";
} /* '' */
.uil-wheelchair:before {
  content: "\ec1b";
} /* '' */
.uil-wifi-router:before {
  content: "\ec1c";
} /* '' */
.uil-wifi-slash:before {
  content: "\ec1d";
} /* '' */
.uil-wifi:before {
  content: "\ec1e";
} /* '' */
.uil-wind:before {
  content: "\ec1f";
} /* '' */
.uil-window-grid:before {
  content: "\ec20";
} /* '' */
.uil-window-maximize:before {
  content: "\ec21";
} /* '' */
.uil-window-restore:before {
  content: "\ec22";
} /* '' */
.uil-window-section:before {
  content: "\ec23";
} /* '' */
.uil-window:before {
  content: "\ec24";
} /* '' */
.uil-windsock:before {
  content: "\ec25";
} /* '' */
.uil-wrap-text:before {
  content: "\ec26";
} /* '' */
.uil-wrench:before {
  content: "\ec27";
} /* '' */
.uil-yellow:before {
  content: "\ec28";
} /* '' */
.uil-yen-circle:before {
  content: "\ec29";
} /* '' */
.uil-yen:before {
  content: "\ec2a";
} /* '' */
.uil-youtube-alt:before {
  content: "\ec2b";
} /* '' */
.uil-youtube:before {
  content: "\ec2c";
} /* '' */
/*
* Remix Icon v2.5.0
* https://remixicon.com
* https://github.com/Remix-Design/RemixIcon
*
* Copyright RemixIcon.com
* Released under the Apache License Version 2.0
*
* Date: 2020-05-23
*/
@font-face {
  font-family: "remixicon";
  src: url("../../../fonts/remixicon.eot?t=1590207869815"); /* IE9*/
  src: url("../../../fonts/remixicon.eot?t=1590207869815#iefix") format("embedded-opentype"), url("../../../fonts/remixicon.woff2?t=1590207869815") format("woff2"), url("../../../fonts/remixicon.woff?t=1590207869815") format("woff"), url("../../../fonts/remixicon.ttf?t=1590207869815") format("truetype"), url("../../../fonts/remixicon.svg?t=1590207869815#remixicon") format("svg"); /* iOS 4.1- */
  font-display: swap;
}
[class^=ri-], [class*=" ri-"] {
  font-family: "remixicon" !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ri-lg {
  font-size: 1.3333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}

.ri-xl {
  font-size: 1.5em;
  line-height: 0.6666em;
  vertical-align: -0.075em;
}

.ri-xxs {
  font-size: 0.5em;
}

.ri-xs {
  font-size: 0.75em;
}

.ri-sm {
  font-size: 0.875em;
}

.ri-1x {
  font-size: 1em;
}

.ri-2x {
  font-size: 2em;
}

.ri-3x {
  font-size: 3em;
}

.ri-4x {
  font-size: 4em;
}

.ri-5x {
  font-size: 5em;
}

.ri-6x {
  font-size: 6em;
}

.ri-7x {
  font-size: 7em;
}

.ri-8x {
  font-size: 8em;
}

.ri-9x {
  font-size: 9em;
}

.ri-10x {
  font-size: 10em;
}

.ri-fw {
  text-align: center;
  width: 1.25em;
}

.ri-24-hours-fill:before {
  content: "\ea01";
}

.ri-24-hours-line:before {
  content: "\ea02";
}

.ri-4k-fill:before {
  content: "\ea03";
}

.ri-4k-line:before {
  content: "\ea04";
}

.ri-a-b:before {
  content: "\ea05";
}

.ri-account-box-fill:before {
  content: "\ea06";
}

.ri-account-box-line:before {
  content: "\ea07";
}

.ri-account-circle-fill:before {
  content: "\ea08";
}

.ri-account-circle-line:before {
  content: "\ea09";
}

.ri-account-pin-box-fill:before {
  content: "\ea0a";
}

.ri-account-pin-box-line:before {
  content: "\ea0b";
}

.ri-account-pin-circle-fill:before {
  content: "\ea0c";
}

.ri-account-pin-circle-line:before {
  content: "\ea0d";
}

.ri-add-box-fill:before {
  content: "\ea0e";
}

.ri-add-box-line:before {
  content: "\ea0f";
}

.ri-add-circle-fill:before {
  content: "\ea10";
}

.ri-add-circle-line:before {
  content: "\ea11";
}

.ri-add-fill:before {
  content: "\ea12";
}

.ri-add-line:before {
  content: "\ea13";
}

.ri-admin-fill:before {
  content: "\ea14";
}

.ri-admin-line:before {
  content: "\ea15";
}

.ri-advertisement-fill:before {
  content: "\ea16";
}

.ri-advertisement-line:before {
  content: "\ea17";
}

.ri-airplay-fill:before {
  content: "\ea18";
}

.ri-airplay-line:before {
  content: "\ea19";
}

.ri-alarm-fill:before {
  content: "\ea1a";
}

.ri-alarm-line:before {
  content: "\ea1b";
}

.ri-alarm-warning-fill:before {
  content: "\ea1c";
}

.ri-alarm-warning-line:before {
  content: "\ea1d";
}

.ri-album-fill:before {
  content: "\ea1e";
}

.ri-album-line:before {
  content: "\ea1f";
}

.ri-alert-fill:before {
  content: "\ea20";
}

.ri-alert-line:before {
  content: "\ea21";
}

.ri-aliens-fill:before {
  content: "\ea22";
}

.ri-aliens-line:before {
  content: "\ea23";
}

.ri-align-bottom:before {
  content: "\ea24";
}

.ri-align-center:before {
  content: "\ea25";
}

.ri-align-justify:before {
  content: "\ea26";
}

.ri-align-left:before {
  content: "\ea27";
}

.ri-align-right:before {
  content: "\ea28";
}

.ri-align-top:before {
  content: "\ea29";
}

.ri-align-vertically:before {
  content: "\ea2a";
}

.ri-alipay-fill:before {
  content: "\ea2b";
}

.ri-alipay-line:before {
  content: "\ea2c";
}

.ri-amazon-fill:before {
  content: "\ea2d";
}

.ri-amazon-line:before {
  content: "\ea2e";
}

.ri-anchor-fill:before {
  content: "\ea2f";
}

.ri-anchor-line:before {
  content: "\ea30";
}

.ri-ancient-gate-fill:before {
  content: "\ea31";
}

.ri-ancient-gate-line:before {
  content: "\ea32";
}

.ri-ancient-pavilion-fill:before {
  content: "\ea33";
}

.ri-ancient-pavilion-line:before {
  content: "\ea34";
}

.ri-android-fill:before {
  content: "\ea35";
}

.ri-android-line:before {
  content: "\ea36";
}

.ri-angularjs-fill:before {
  content: "\ea37";
}

.ri-angularjs-line:before {
  content: "\ea38";
}

.ri-anticlockwise-2-fill:before {
  content: "\ea39";
}

.ri-anticlockwise-2-line:before {
  content: "\ea3a";
}

.ri-anticlockwise-fill:before {
  content: "\ea3b";
}

.ri-anticlockwise-line:before {
  content: "\ea3c";
}

.ri-app-store-fill:before {
  content: "\ea3d";
}

.ri-app-store-line:before {
  content: "\ea3e";
}

.ri-apple-fill:before {
  content: "\ea3f";
}

.ri-apple-line:before {
  content: "\ea40";
}

.ri-apps-2-fill:before {
  content: "\ea41";
}

.ri-apps-2-line:before {
  content: "\ea42";
}

.ri-apps-fill:before {
  content: "\ea43";
}

.ri-apps-line:before {
  content: "\ea44";
}

.ri-archive-drawer-fill:before {
  content: "\ea45";
}

.ri-archive-drawer-line:before {
  content: "\ea46";
}

.ri-archive-fill:before {
  content: "\ea47";
}

.ri-archive-line:before {
  content: "\ea48";
}

.ri-arrow-down-circle-fill:before {
  content: "\ea49";
}

.ri-arrow-down-circle-line:before {
  content: "\ea4a";
}

.ri-arrow-down-fill:before {
  content: "\ea4b";
}

.ri-arrow-down-line:before {
  content: "\ea4c";
}

.ri-arrow-down-s-fill:before {
  content: "\ea4d";
}

.ri-arrow-down-s-line:before {
  content: "\ea4e";
}

.ri-arrow-drop-down-fill:before {
  content: "\ea4f";
}

.ri-arrow-drop-down-line:before {
  content: "\ea50";
}

.ri-arrow-drop-left-fill:before {
  content: "\ea51";
}

.ri-arrow-drop-left-line:before {
  content: "\ea52";
}

.ri-arrow-drop-right-fill:before {
  content: "\ea53";
}

.ri-arrow-drop-right-line:before {
  content: "\ea54";
}

.ri-arrow-drop-up-fill:before {
  content: "\ea55";
}

.ri-arrow-drop-up-line:before {
  content: "\ea56";
}

.ri-arrow-go-back-fill:before {
  content: "\ea57";
}

.ri-arrow-go-back-line:before {
  content: "\ea58";
}

.ri-arrow-go-forward-fill:before {
  content: "\ea59";
}

.ri-arrow-go-forward-line:before {
  content: "\ea5a";
}

.ri-arrow-left-circle-fill:before {
  content: "\ea5b";
}

.ri-arrow-left-circle-line:before {
  content: "\ea5c";
}

.ri-arrow-left-down-fill:before {
  content: "\ea5d";
}

.ri-arrow-left-down-line:before {
  content: "\ea5e";
}

.ri-arrow-left-fill:before {
  content: "\ea5f";
}

.ri-arrow-left-line:before {
  content: "\ea60";
}

.ri-arrow-left-right-fill:before {
  content: "\ea61";
}

.ri-arrow-left-right-line:before {
  content: "\ea62";
}

.ri-arrow-left-s-fill:before {
  content: "\ea63";
}

.ri-arrow-left-s-line:before {
  content: "\ea64";
}

.ri-arrow-left-up-fill:before {
  content: "\ea65";
}

.ri-arrow-left-up-line:before {
  content: "\ea66";
}

.ri-arrow-right-circle-fill:before {
  content: "\ea67";
}

.ri-arrow-right-circle-line:before {
  content: "\ea68";
}

.ri-arrow-right-down-fill:before {
  content: "\ea69";
}

.ri-arrow-right-down-line:before {
  content: "\ea6a";
}

.ri-arrow-right-fill:before {
  content: "\ea6b";
}

.ri-arrow-right-line:before {
  content: "\ea6c";
}

.ri-arrow-right-s-fill:before {
  content: "\ea6d";
}

.ri-arrow-right-s-line:before {
  content: "\ea6e";
}

.ri-arrow-right-up-fill:before {
  content: "\ea6f";
}

.ri-arrow-right-up-line:before {
  content: "\ea70";
}

.ri-arrow-up-circle-fill:before {
  content: "\ea71";
}

.ri-arrow-up-circle-line:before {
  content: "\ea72";
}

.ri-arrow-up-down-fill:before {
  content: "\ea73";
}

.ri-arrow-up-down-line:before {
  content: "\ea74";
}

.ri-arrow-up-fill:before {
  content: "\ea75";
}

.ri-arrow-up-line:before {
  content: "\ea76";
}

.ri-arrow-up-s-fill:before {
  content: "\ea77";
}

.ri-arrow-up-s-line:before {
  content: "\ea78";
}

.ri-artboard-2-fill:before {
  content: "\ea79";
}

.ri-artboard-2-line:before {
  content: "\ea7a";
}

.ri-artboard-fill:before {
  content: "\ea7b";
}

.ri-artboard-line:before {
  content: "\ea7c";
}

.ri-article-fill:before {
  content: "\ea7d";
}

.ri-article-line:before {
  content: "\ea7e";
}

.ri-aspect-ratio-fill:before {
  content: "\ea7f";
}

.ri-aspect-ratio-line:before {
  content: "\ea80";
}

.ri-asterisk:before {
  content: "\ea81";
}

.ri-at-fill:before {
  content: "\ea82";
}

.ri-at-line:before {
  content: "\ea83";
}

.ri-attachment-2:before {
  content: "\ea84";
}

.ri-attachment-fill:before {
  content: "\ea85";
}

.ri-attachment-line:before {
  content: "\ea86";
}

.ri-auction-fill:before {
  content: "\ea87";
}

.ri-auction-line:before {
  content: "\ea88";
}

.ri-award-fill:before {
  content: "\ea89";
}

.ri-award-line:before {
  content: "\ea8a";
}

.ri-baidu-fill:before {
  content: "\ea8b";
}

.ri-baidu-line:before {
  content: "\ea8c";
}

.ri-ball-pen-fill:before {
  content: "\ea8d";
}

.ri-ball-pen-line:before {
  content: "\ea8e";
}

.ri-bank-card-2-fill:before {
  content: "\ea8f";
}

.ri-bank-card-2-line:before {
  content: "\ea90";
}

.ri-bank-card-fill:before {
  content: "\ea91";
}

.ri-bank-card-line:before {
  content: "\ea92";
}

.ri-bank-fill:before {
  content: "\ea93";
}

.ri-bank-line:before {
  content: "\ea94";
}

.ri-bar-chart-2-fill:before {
  content: "\ea95";
}

.ri-bar-chart-2-line:before {
  content: "\ea96";
}

.ri-bar-chart-box-fill:before {
  content: "\ea97";
}

.ri-bar-chart-box-line:before {
  content: "\ea98";
}

.ri-bar-chart-fill:before {
  content: "\ea99";
}

.ri-bar-chart-grouped-fill:before {
  content: "\ea9a";
}

.ri-bar-chart-grouped-line:before {
  content: "\ea9b";
}

.ri-bar-chart-horizontal-fill:before {
  content: "\ea9c";
}

.ri-bar-chart-horizontal-line:before {
  content: "\ea9d";
}

.ri-bar-chart-line:before {
  content: "\ea9e";
}

.ri-barcode-box-fill:before {
  content: "\ea9f";
}

.ri-barcode-box-line:before {
  content: "\eaa0";
}

.ri-barcode-fill:before {
  content: "\eaa1";
}

.ri-barcode-line:before {
  content: "\eaa2";
}

.ri-barricade-fill:before {
  content: "\eaa3";
}

.ri-barricade-line:before {
  content: "\eaa4";
}

.ri-base-station-fill:before {
  content: "\eaa5";
}

.ri-base-station-line:before {
  content: "\eaa6";
}

.ri-basketball-fill:before {
  content: "\eaa7";
}

.ri-basketball-line:before {
  content: "\eaa8";
}

.ri-battery-2-charge-fill:before {
  content: "\eaa9";
}

.ri-battery-2-charge-line:before {
  content: "\eaaa";
}

.ri-battery-2-fill:before {
  content: "\eaab";
}

.ri-battery-2-line:before {
  content: "\eaac";
}

.ri-battery-charge-fill:before {
  content: "\eaad";
}

.ri-battery-charge-line:before {
  content: "\eaae";
}

.ri-battery-fill:before {
  content: "\eaaf";
}

.ri-battery-line:before {
  content: "\eab0";
}

.ri-battery-low-fill:before {
  content: "\eab1";
}

.ri-battery-low-line:before {
  content: "\eab2";
}

.ri-battery-saver-fill:before {
  content: "\eab3";
}

.ri-battery-saver-line:before {
  content: "\eab4";
}

.ri-battery-share-fill:before {
  content: "\eab5";
}

.ri-battery-share-line:before {
  content: "\eab6";
}

.ri-bear-smile-fill:before {
  content: "\eab7";
}

.ri-bear-smile-line:before {
  content: "\eab8";
}

.ri-behance-fill:before {
  content: "\eab9";
}

.ri-behance-line:before {
  content: "\eaba";
}

.ri-bell-fill:before {
  content: "\eabb";
}

.ri-bell-line:before {
  content: "\eabc";
}

.ri-bike-fill:before {
  content: "\eabd";
}

.ri-bike-line:before {
  content: "\eabe";
}

.ri-bilibili-fill:before {
  content: "\eabf";
}

.ri-bilibili-line:before {
  content: "\eac0";
}

.ri-bill-fill:before {
  content: "\eac1";
}

.ri-bill-line:before {
  content: "\eac2";
}

.ri-billiards-fill:before {
  content: "\eac3";
}

.ri-billiards-line:before {
  content: "\eac4";
}

.ri-bit-coin-fill:before {
  content: "\eac5";
}

.ri-bit-coin-line:before {
  content: "\eac6";
}

.ri-blaze-fill:before {
  content: "\eac7";
}

.ri-blaze-line:before {
  content: "\eac8";
}

.ri-bluetooth-connect-fill:before {
  content: "\eac9";
}

.ri-bluetooth-connect-line:before {
  content: "\eaca";
}

.ri-bluetooth-fill:before {
  content: "\eacb";
}

.ri-bluetooth-line:before {
  content: "\eacc";
}

.ri-blur-off-fill:before {
  content: "\eacd";
}

.ri-blur-off-line:before {
  content: "\eace";
}

.ri-body-scan-fill:before {
  content: "\eacf";
}

.ri-body-scan-line:before {
  content: "\ead0";
}

.ri-bold:before {
  content: "\ead1";
}

.ri-book-2-fill:before {
  content: "\ead2";
}

.ri-book-2-line:before {
  content: "\ead3";
}

.ri-book-3-fill:before {
  content: "\ead4";
}

.ri-book-3-line:before {
  content: "\ead5";
}

.ri-book-fill:before {
  content: "\ead6";
}

.ri-book-line:before {
  content: "\ead7";
}

.ri-book-mark-fill:before {
  content: "\ead8";
}

.ri-book-mark-line:before {
  content: "\ead9";
}

.ri-book-open-fill:before {
  content: "\eada";
}

.ri-book-open-line:before {
  content: "\eadb";
}

.ri-book-read-fill:before {
  content: "\eadc";
}

.ri-book-read-line:before {
  content: "\eadd";
}

.ri-booklet-fill:before {
  content: "\eade";
}

.ri-booklet-line:before {
  content: "\eadf";
}

.ri-bookmark-2-fill:before {
  content: "\eae0";
}

.ri-bookmark-2-line:before {
  content: "\eae1";
}

.ri-bookmark-3-fill:before {
  content: "\eae2";
}

.ri-bookmark-3-line:before {
  content: "\eae3";
}

.ri-bookmark-fill:before {
  content: "\eae4";
}

.ri-bookmark-line:before {
  content: "\eae5";
}

.ri-boxing-fill:before {
  content: "\eae6";
}

.ri-boxing-line:before {
  content: "\eae7";
}

.ri-braces-fill:before {
  content: "\eae8";
}

.ri-braces-line:before {
  content: "\eae9";
}

.ri-brackets-fill:before {
  content: "\eaea";
}

.ri-brackets-line:before {
  content: "\eaeb";
}

.ri-briefcase-2-fill:before {
  content: "\eaec";
}

.ri-briefcase-2-line:before {
  content: "\eaed";
}

.ri-briefcase-3-fill:before {
  content: "\eaee";
}

.ri-briefcase-3-line:before {
  content: "\eaef";
}

.ri-briefcase-4-fill:before {
  content: "\eaf0";
}

.ri-briefcase-4-line:before {
  content: "\eaf1";
}

.ri-briefcase-5-fill:before {
  content: "\eaf2";
}

.ri-briefcase-5-line:before {
  content: "\eaf3";
}

.ri-briefcase-fill:before {
  content: "\eaf4";
}

.ri-briefcase-line:before {
  content: "\eaf5";
}

.ri-bring-forward:before {
  content: "\eaf6";
}

.ri-bring-to-front:before {
  content: "\eaf7";
}

.ri-broadcast-fill:before {
  content: "\eaf8";
}

.ri-broadcast-line:before {
  content: "\eaf9";
}

.ri-brush-2-fill:before {
  content: "\eafa";
}

.ri-brush-2-line:before {
  content: "\eafb";
}

.ri-brush-3-fill:before {
  content: "\eafc";
}

.ri-brush-3-line:before {
  content: "\eafd";
}

.ri-brush-4-fill:before {
  content: "\eafe";
}

.ri-brush-4-line:before {
  content: "\eaff";
}

.ri-brush-fill:before {
  content: "\eb00";
}

.ri-brush-line:before {
  content: "\eb01";
}

.ri-bubble-chart-fill:before {
  content: "\eb02";
}

.ri-bubble-chart-line:before {
  content: "\eb03";
}

.ri-bug-2-fill:before {
  content: "\eb04";
}

.ri-bug-2-line:before {
  content: "\eb05";
}

.ri-bug-fill:before {
  content: "\eb06";
}

.ri-bug-line:before {
  content: "\eb07";
}

.ri-building-2-fill:before {
  content: "\eb08";
}

.ri-building-2-line:before {
  content: "\eb09";
}

.ri-building-3-fill:before {
  content: "\eb0a";
}

.ri-building-3-line:before {
  content: "\eb0b";
}

.ri-building-4-fill:before {
  content: "\eb0c";
}

.ri-building-4-line:before {
  content: "\eb0d";
}

.ri-building-fill:before {
  content: "\eb0e";
}

.ri-building-line:before {
  content: "\eb0f";
}

.ri-bus-2-fill:before {
  content: "\eb10";
}

.ri-bus-2-line:before {
  content: "\eb11";
}

.ri-bus-fill:before {
  content: "\eb12";
}

.ri-bus-line:before {
  content: "\eb13";
}

.ri-bus-wifi-fill:before {
  content: "\eb14";
}

.ri-bus-wifi-line:before {
  content: "\eb15";
}

.ri-cactus-fill:before {
  content: "\eb16";
}

.ri-cactus-line:before {
  content: "\eb17";
}

.ri-cake-2-fill:before {
  content: "\eb18";
}

.ri-cake-2-line:before {
  content: "\eb19";
}

.ri-cake-3-fill:before {
  content: "\eb1a";
}

.ri-cake-3-line:before {
  content: "\eb1b";
}

.ri-cake-fill:before {
  content: "\eb1c";
}

.ri-cake-line:before {
  content: "\eb1d";
}

.ri-calculator-fill:before {
  content: "\eb1e";
}

.ri-calculator-line:before {
  content: "\eb1f";
}

.ri-calendar-2-fill:before {
  content: "\eb20";
}

.ri-calendar-2-line:before {
  content: "\eb21";
}

.ri-calendar-check-fill:before {
  content: "\eb22";
}

.ri-calendar-check-line:before {
  content: "\eb23";
}

.ri-calendar-event-fill:before {
  content: "\eb24";
}

.ri-calendar-event-line:before {
  content: "\eb25";
}

.ri-calendar-fill:before {
  content: "\eb26";
}

.ri-calendar-line:before {
  content: "\eb27";
}

.ri-calendar-todo-fill:before {
  content: "\eb28";
}

.ri-calendar-todo-line:before {
  content: "\eb29";
}

.ri-camera-2-fill:before {
  content: "\eb2a";
}

.ri-camera-2-line:before {
  content: "\eb2b";
}

.ri-camera-3-fill:before {
  content: "\eb2c";
}

.ri-camera-3-line:before {
  content: "\eb2d";
}

.ri-camera-fill:before {
  content: "\eb2e";
}

.ri-camera-lens-fill:before {
  content: "\eb2f";
}

.ri-camera-lens-line:before {
  content: "\eb30";
}

.ri-camera-line:before {
  content: "\eb31";
}

.ri-camera-off-fill:before {
  content: "\eb32";
}

.ri-camera-off-line:before {
  content: "\eb33";
}

.ri-camera-switch-fill:before {
  content: "\eb34";
}

.ri-camera-switch-line:before {
  content: "\eb35";
}

.ri-capsule-fill:before {
  content: "\eb36";
}

.ri-capsule-line:before {
  content: "\eb37";
}

.ri-car-fill:before {
  content: "\eb38";
}

.ri-car-line:before {
  content: "\eb39";
}

.ri-car-washing-fill:before {
  content: "\eb3a";
}

.ri-car-washing-line:before {
  content: "\eb3b";
}

.ri-caravan-fill:before {
  content: "\eb3c";
}

.ri-caravan-line:before {
  content: "\eb3d";
}

.ri-cast-fill:before {
  content: "\eb3e";
}

.ri-cast-line:before {
  content: "\eb3f";
}

.ri-cellphone-fill:before {
  content: "\eb40";
}

.ri-cellphone-line:before {
  content: "\eb41";
}

.ri-celsius-fill:before {
  content: "\eb42";
}

.ri-celsius-line:before {
  content: "\eb43";
}

.ri-centos-fill:before {
  content: "\eb44";
}

.ri-centos-line:before {
  content: "\eb45";
}

.ri-character-recognition-fill:before {
  content: "\eb46";
}

.ri-character-recognition-line:before {
  content: "\eb47";
}

.ri-charging-pile-2-fill:before {
  content: "\eb48";
}

.ri-charging-pile-2-line:before {
  content: "\eb49";
}

.ri-charging-pile-fill:before {
  content: "\eb4a";
}

.ri-charging-pile-line:before {
  content: "\eb4b";
}

.ri-chat-1-fill:before {
  content: "\eb4c";
}

.ri-chat-1-line:before {
  content: "\eb4d";
}

.ri-chat-2-fill:before {
  content: "\eb4e";
}

.ri-chat-2-line:before {
  content: "\eb4f";
}

.ri-chat-3-fill:before {
  content: "\eb50";
}

.ri-chat-3-line:before {
  content: "\eb51";
}

.ri-chat-4-fill:before {
  content: "\eb52";
}

.ri-chat-4-line:before {
  content: "\eb53";
}

.ri-chat-check-fill:before {
  content: "\eb54";
}

.ri-chat-check-line:before {
  content: "\eb55";
}

.ri-chat-delete-fill:before {
  content: "\eb56";
}

.ri-chat-delete-line:before {
  content: "\eb57";
}

.ri-chat-download-fill:before {
  content: "\eb58";
}

.ri-chat-download-line:before {
  content: "\eb59";
}

.ri-chat-follow-up-fill:before {
  content: "\eb5a";
}

.ri-chat-follow-up-line:before {
  content: "\eb5b";
}

.ri-chat-forward-fill:before {
  content: "\eb5c";
}

.ri-chat-forward-line:before {
  content: "\eb5d";
}

.ri-chat-heart-fill:before {
  content: "\eb5e";
}

.ri-chat-heart-line:before {
  content: "\eb5f";
}

.ri-chat-history-fill:before {
  content: "\eb60";
}

.ri-chat-history-line:before {
  content: "\eb61";
}

.ri-chat-new-fill:before {
  content: "\eb62";
}

.ri-chat-new-line:before {
  content: "\eb63";
}

.ri-chat-off-fill:before {
  content: "\eb64";
}

.ri-chat-off-line:before {
  content: "\eb65";
}

.ri-chat-poll-fill:before {
  content: "\eb66";
}

.ri-chat-poll-line:before {
  content: "\eb67";
}

.ri-chat-private-fill:before {
  content: "\eb68";
}

.ri-chat-private-line:before {
  content: "\eb69";
}

.ri-chat-quote-fill:before {
  content: "\eb6a";
}

.ri-chat-quote-line:before {
  content: "\eb6b";
}

.ri-chat-settings-fill:before {
  content: "\eb6c";
}

.ri-chat-settings-line:before {
  content: "\eb6d";
}

.ri-chat-smile-2-fill:before {
  content: "\eb6e";
}

.ri-chat-smile-2-line:before {
  content: "\eb6f";
}

.ri-chat-smile-3-fill:before {
  content: "\eb70";
}

.ri-chat-smile-3-line:before {
  content: "\eb71";
}

.ri-chat-smile-fill:before {
  content: "\eb72";
}

.ri-chat-smile-line:before {
  content: "\eb73";
}

.ri-chat-upload-fill:before {
  content: "\eb74";
}

.ri-chat-upload-line:before {
  content: "\eb75";
}

.ri-chat-voice-fill:before {
  content: "\eb76";
}

.ri-chat-voice-line:before {
  content: "\eb77";
}

.ri-check-double-fill:before {
  content: "\eb78";
}

.ri-check-double-line:before {
  content: "\eb79";
}

.ri-check-fill:before {
  content: "\eb7a";
}

.ri-check-line:before {
  content: "\eb7b";
}

.ri-checkbox-blank-circle-fill:before {
  content: "\eb7c";
}

.ri-checkbox-blank-circle-line:before {
  content: "\eb7d";
}

.ri-checkbox-blank-fill:before {
  content: "\eb7e";
}

.ri-checkbox-blank-line:before {
  content: "\eb7f";
}

.ri-checkbox-circle-fill:before {
  content: "\eb80";
}

.ri-checkbox-circle-line:before {
  content: "\eb81";
}

.ri-checkbox-fill:before {
  content: "\eb82";
}

.ri-checkbox-indeterminate-fill:before {
  content: "\eb83";
}

.ri-checkbox-indeterminate-line:before {
  content: "\eb84";
}

.ri-checkbox-line:before {
  content: "\eb85";
}

.ri-checkbox-multiple-blank-fill:before {
  content: "\eb86";
}

.ri-checkbox-multiple-blank-line:before {
  content: "\eb87";
}

.ri-checkbox-multiple-fill:before {
  content: "\eb88";
}

.ri-checkbox-multiple-line:before {
  content: "\eb89";
}

.ri-china-railway-fill:before {
  content: "\eb8a";
}

.ri-china-railway-line:before {
  content: "\eb8b";
}

.ri-chrome-fill:before {
  content: "\eb8c";
}

.ri-chrome-line:before {
  content: "\eb8d";
}

.ri-clapperboard-fill:before {
  content: "\eb8e";
}

.ri-clapperboard-line:before {
  content: "\eb8f";
}

.ri-clipboard-fill:before {
  content: "\eb90";
}

.ri-clipboard-line:before {
  content: "\eb91";
}

.ri-clockwise-2-fill:before {
  content: "\eb92";
}

.ri-clockwise-2-line:before {
  content: "\eb93";
}

.ri-clockwise-fill:before {
  content: "\eb94";
}

.ri-clockwise-line:before {
  content: "\eb95";
}

.ri-close-circle-fill:before {
  content: "\eb96";
}

.ri-close-circle-line:before {
  content: "\eb97";
}

.ri-close-fill:before {
  content: "\eb98";
}

.ri-close-line:before {
  content: "\eb99";
}

.ri-closed-captioning-fill:before {
  content: "\eb9a";
}

.ri-closed-captioning-line:before {
  content: "\eb9b";
}

.ri-cloud-fill:before {
  content: "\eb9c";
}

.ri-cloud-line:before {
  content: "\eb9d";
}

.ri-cloud-off-fill:before {
  content: "\eb9e";
}

.ri-cloud-off-line:before {
  content: "\eb9f";
}

.ri-cloud-windy-fill:before {
  content: "\eba0";
}

.ri-cloud-windy-line:before {
  content: "\eba1";
}

.ri-cloudy-2-fill:before {
  content: "\eba2";
}

.ri-cloudy-2-line:before {
  content: "\eba3";
}

.ri-cloudy-fill:before {
  content: "\eba4";
}

.ri-cloudy-line:before {
  content: "\eba5";
}

.ri-code-box-fill:before {
  content: "\eba6";
}

.ri-code-box-line:before {
  content: "\eba7";
}

.ri-code-fill:before {
  content: "\eba8";
}

.ri-code-line:before {
  content: "\eba9";
}

.ri-code-s-fill:before {
  content: "\ebaa";
}

.ri-code-s-line:before {
  content: "\ebab";
}

.ri-code-s-slash-fill:before {
  content: "\ebac";
}

.ri-code-s-slash-line:before {
  content: "\ebad";
}

.ri-code-view:before {
  content: "\ebae";
}

.ri-codepen-fill:before {
  content: "\ebaf";
}

.ri-codepen-line:before {
  content: "\ebb0";
}

.ri-coin-fill:before {
  content: "\ebb1";
}

.ri-coin-line:before {
  content: "\ebb2";
}

.ri-coins-fill:before {
  content: "\ebb3";
}

.ri-coins-line:before {
  content: "\ebb4";
}

.ri-collage-fill:before {
  content: "\ebb5";
}

.ri-collage-line:before {
  content: "\ebb6";
}

.ri-command-fill:before {
  content: "\ebb7";
}

.ri-command-line:before {
  content: "\ebb8";
}

.ri-community-fill:before {
  content: "\ebb9";
}

.ri-community-line:before {
  content: "\ebba";
}

.ri-compass-2-fill:before {
  content: "\ebbb";
}

.ri-compass-2-line:before {
  content: "\ebbc";
}

.ri-compass-3-fill:before {
  content: "\ebbd";
}

.ri-compass-3-line:before {
  content: "\ebbe";
}

.ri-compass-4-fill:before {
  content: "\ebbf";
}

.ri-compass-4-line:before {
  content: "\ebc0";
}

.ri-compass-discover-fill:before {
  content: "\ebc1";
}

.ri-compass-discover-line:before {
  content: "\ebc2";
}

.ri-compass-fill:before {
  content: "\ebc3";
}

.ri-compass-line:before {
  content: "\ebc4";
}

.ri-compasses-2-fill:before {
  content: "\ebc5";
}

.ri-compasses-2-line:before {
  content: "\ebc6";
}

.ri-compasses-fill:before {
  content: "\ebc7";
}

.ri-compasses-line:before {
  content: "\ebc8";
}

.ri-computer-fill:before {
  content: "\ebc9";
}

.ri-computer-line:before {
  content: "\ebca";
}

.ri-contacts-book-2-fill:before {
  content: "\ebcb";
}

.ri-contacts-book-2-line:before {
  content: "\ebcc";
}

.ri-contacts-book-fill:before {
  content: "\ebcd";
}

.ri-contacts-book-line:before {
  content: "\ebce";
}

.ri-contacts-book-upload-fill:before {
  content: "\ebcf";
}

.ri-contacts-book-upload-line:before {
  content: "\ebd0";
}

.ri-contacts-fill:before {
  content: "\ebd1";
}

.ri-contacts-line:before {
  content: "\ebd2";
}

.ri-contrast-2-fill:before {
  content: "\ebd3";
}

.ri-contrast-2-line:before {
  content: "\ebd4";
}

.ri-contrast-drop-2-fill:before {
  content: "\ebd5";
}

.ri-contrast-drop-2-line:before {
  content: "\ebd6";
}

.ri-contrast-drop-fill:before {
  content: "\ebd7";
}

.ri-contrast-drop-line:before {
  content: "\ebd8";
}

.ri-contrast-fill:before {
  content: "\ebd9";
}

.ri-contrast-line:before {
  content: "\ebda";
}

.ri-copper-coin-fill:before {
  content: "\ebdb";
}

.ri-copper-coin-line:before {
  content: "\ebdc";
}

.ri-copper-diamond-fill:before {
  content: "\ebdd";
}

.ri-copper-diamond-line:before {
  content: "\ebde";
}

.ri-copyleft-fill:before {
  content: "\ebdf";
}

.ri-copyleft-line:before {
  content: "\ebe0";
}

.ri-copyright-fill:before {
  content: "\ebe1";
}

.ri-copyright-line:before {
  content: "\ebe2";
}

.ri-coreos-fill:before {
  content: "\ebe3";
}

.ri-coreos-line:before {
  content: "\ebe4";
}

.ri-coupon-2-fill:before {
  content: "\ebe5";
}

.ri-coupon-2-line:before {
  content: "\ebe6";
}

.ri-coupon-3-fill:before {
  content: "\ebe7";
}

.ri-coupon-3-line:before {
  content: "\ebe8";
}

.ri-coupon-4-fill:before {
  content: "\ebe9";
}

.ri-coupon-4-line:before {
  content: "\ebea";
}

.ri-coupon-5-fill:before {
  content: "\ebeb";
}

.ri-coupon-5-line:before {
  content: "\ebec";
}

.ri-coupon-fill:before {
  content: "\ebed";
}

.ri-coupon-line:before {
  content: "\ebee";
}

.ri-cpu-fill:before {
  content: "\ebef";
}

.ri-cpu-line:before {
  content: "\ebf0";
}

.ri-creative-commons-by-fill:before {
  content: "\ebf1";
}

.ri-creative-commons-by-line:before {
  content: "\ebf2";
}

.ri-creative-commons-fill:before {
  content: "\ebf3";
}

.ri-creative-commons-line:before {
  content: "\ebf4";
}

.ri-creative-commons-nc-fill:before {
  content: "\ebf5";
}

.ri-creative-commons-nc-line:before {
  content: "\ebf6";
}

.ri-creative-commons-nd-fill:before {
  content: "\ebf7";
}

.ri-creative-commons-nd-line:before {
  content: "\ebf8";
}

.ri-creative-commons-sa-fill:before {
  content: "\ebf9";
}

.ri-creative-commons-sa-line:before {
  content: "\ebfa";
}

.ri-creative-commons-zero-fill:before {
  content: "\ebfb";
}

.ri-creative-commons-zero-line:before {
  content: "\ebfc";
}

.ri-criminal-fill:before {
  content: "\ebfd";
}

.ri-criminal-line:before {
  content: "\ebfe";
}

.ri-crop-2-fill:before {
  content: "\ebff";
}

.ri-crop-2-line:before {
  content: "\ec00";
}

.ri-crop-fill:before {
  content: "\ec01";
}

.ri-crop-line:before {
  content: "\ec02";
}

.ri-css3-fill:before {
  content: "\ec03";
}

.ri-css3-line:before {
  content: "\ec04";
}

.ri-cup-fill:before {
  content: "\ec05";
}

.ri-cup-line:before {
  content: "\ec06";
}

.ri-currency-fill:before {
  content: "\ec07";
}

.ri-currency-line:before {
  content: "\ec08";
}

.ri-cursor-fill:before {
  content: "\ec09";
}

.ri-cursor-line:before {
  content: "\ec0a";
}

.ri-customer-service-2-fill:before {
  content: "\ec0b";
}

.ri-customer-service-2-line:before {
  content: "\ec0c";
}

.ri-customer-service-fill:before {
  content: "\ec0d";
}

.ri-customer-service-line:before {
  content: "\ec0e";
}

.ri-dashboard-2-fill:before {
  content: "\ec0f";
}

.ri-dashboard-2-line:before {
  content: "\ec10";
}

.ri-dashboard-3-fill:before {
  content: "\ec11";
}

.ri-dashboard-3-line:before {
  content: "\ec12";
}

.ri-dashboard-fill:before {
  content: "\ec13";
}

.ri-dashboard-line:before {
  content: "\ec14";
}

.ri-database-2-fill:before {
  content: "\ec15";
}

.ri-database-2-line:before {
  content: "\ec16";
}

.ri-database-fill:before {
  content: "\ec17";
}

.ri-database-line:before {
  content: "\ec18";
}

.ri-delete-back-2-fill:before {
  content: "\ec19";
}

.ri-delete-back-2-line:before {
  content: "\ec1a";
}

.ri-delete-back-fill:before {
  content: "\ec1b";
}

.ri-delete-back-line:before {
  content: "\ec1c";
}

.ri-delete-bin-2-fill:before {
  content: "\ec1d";
}

.ri-delete-bin-2-line:before {
  content: "\ec1e";
}

.ri-delete-bin-3-fill:before {
  content: "\ec1f";
}

.ri-delete-bin-3-line:before {
  content: "\ec20";
}

.ri-delete-bin-4-fill:before {
  content: "\ec21";
}

.ri-delete-bin-4-line:before {
  content: "\ec22";
}

.ri-delete-bin-5-fill:before {
  content: "\ec23";
}

.ri-delete-bin-5-line:before {
  content: "\ec24";
}

.ri-delete-bin-6-fill:before {
  content: "\ec25";
}

.ri-delete-bin-6-line:before {
  content: "\ec26";
}

.ri-delete-bin-7-fill:before {
  content: "\ec27";
}

.ri-delete-bin-7-line:before {
  content: "\ec28";
}

.ri-delete-bin-fill:before {
  content: "\ec29";
}

.ri-delete-bin-line:before {
  content: "\ec2a";
}

.ri-delete-column:before {
  content: "\ec2b";
}

.ri-delete-row:before {
  content: "\ec2c";
}

.ri-device-fill:before {
  content: "\ec2d";
}

.ri-device-line:before {
  content: "\ec2e";
}

.ri-device-recover-fill:before {
  content: "\ec2f";
}

.ri-device-recover-line:before {
  content: "\ec30";
}

.ri-dingding-fill:before {
  content: "\ec31";
}

.ri-dingding-line:before {
  content: "\ec32";
}

.ri-direction-fill:before {
  content: "\ec33";
}

.ri-direction-line:before {
  content: "\ec34";
}

.ri-disc-fill:before {
  content: "\ec35";
}

.ri-disc-line:before {
  content: "\ec36";
}

.ri-discord-fill:before {
  content: "\ec37";
}

.ri-discord-line:before {
  content: "\ec38";
}

.ri-discuss-fill:before {
  content: "\ec39";
}

.ri-discuss-line:before {
  content: "\ec3a";
}

.ri-dislike-fill:before {
  content: "\ec3b";
}

.ri-dislike-line:before {
  content: "\ec3c";
}

.ri-disqus-fill:before {
  content: "\ec3d";
}

.ri-disqus-line:before {
  content: "\ec3e";
}

.ri-divide-fill:before {
  content: "\ec3f";
}

.ri-divide-line:before {
  content: "\ec40";
}

.ri-donut-chart-fill:before {
  content: "\ec41";
}

.ri-donut-chart-line:before {
  content: "\ec42";
}

.ri-door-closed-fill:before {
  content: "\ec43";
}

.ri-door-closed-line:before {
  content: "\ec44";
}

.ri-door-fill:before {
  content: "\ec45";
}

.ri-door-line:before {
  content: "\ec46";
}

.ri-door-lock-box-fill:before {
  content: "\ec47";
}

.ri-door-lock-box-line:before {
  content: "\ec48";
}

.ri-door-lock-fill:before {
  content: "\ec49";
}

.ri-door-lock-line:before {
  content: "\ec4a";
}

.ri-door-open-fill:before {
  content: "\ec4b";
}

.ri-door-open-line:before {
  content: "\ec4c";
}

.ri-dossier-fill:before {
  content: "\ec4d";
}

.ri-dossier-line:before {
  content: "\ec4e";
}

.ri-douban-fill:before {
  content: "\ec4f";
}

.ri-douban-line:before {
  content: "\ec50";
}

.ri-double-quotes-l:before {
  content: "\ec51";
}

.ri-double-quotes-r:before {
  content: "\ec52";
}

.ri-download-2-line:before {
  content: "\ec53";
}

.ri-download-2-line:before {
  content: "\ec54";
}

.ri-download-cloud-2-fill:before {
  content: "\ec55";
}

.ri-download-cloud-2-line:before {
  content: "\ec56";
}

.ri-download-cloud-fill:before {
  content: "\ec57";
}

.ri-download-cloud-line:before {
  content: "\ec58";
}

.ri-download-fill:before {
  content: "\ec59";
}

.ri-download-line:before {
  content: "\ec5a";
}

.ri-draft-fill:before {
  content: "\ec5b";
}

.ri-draft-line:before {
  content: "\ec5c";
}

.ri-drag-drop-fill:before {
  content: "\ec5d";
}

.ri-drag-drop-line:before {
  content: "\ec5e";
}

.ri-drag-move-2-fill:before {
  content: "\ec5f";
}

.ri-drag-move-2-line:before {
  content: "\ec60";
}

.ri-drag-move-fill:before {
  content: "\ec61";
}

.ri-drag-move-line:before {
  content: "\ec62";
}

.ri-dribbble-fill:before {
  content: "\ec63";
}

.ri-dribbble-line:before {
  content: "\ec64";
}

.ri-drive-fill:before {
  content: "\ec65";
}

.ri-drive-line:before {
  content: "\ec66";
}

.ri-drizzle-fill:before {
  content: "\ec67";
}

.ri-drizzle-line:before {
  content: "\ec68";
}

.ri-drop-fill:before {
  content: "\ec69";
}

.ri-drop-line:before {
  content: "\ec6a";
}

.ri-dropbox-fill:before {
  content: "\ec6b";
}

.ri-dropbox-line:before {
  content: "\ec6c";
}

.ri-dual-sim-1-fill:before {
  content: "\ec6d";
}

.ri-dual-sim-1-line:before {
  content: "\ec6e";
}

.ri-dual-sim-2-fill:before {
  content: "\ec6f";
}

.ri-dual-sim-2-line:before {
  content: "\ec70";
}

.ri-dv-fill:before {
  content: "\ec71";
}

.ri-dv-line:before {
  content: "\ec72";
}

.ri-dvd-fill:before {
  content: "\ec73";
}

.ri-dvd-line:before {
  content: "\ec74";
}

.ri-e-bike-2-fill:before {
  content: "\ec75";
}

.ri-e-bike-2-line:before {
  content: "\ec76";
}

.ri-e-bike-fill:before {
  content: "\ec77";
}

.ri-e-bike-line:before {
  content: "\ec78";
}

.ri-earth-fill:before {
  content: "\ec79";
}

.ri-earth-line:before {
  content: "\ec7a";
}

.ri-earthquake-fill:before {
  content: "\ec7b";
}

.ri-earthquake-line:before {
  content: "\ec7c";
}

.ri-edge-fill:before {
  content: "\ec7d";
}

.ri-edge-line:before {
  content: "\ec7e";
}

.ri-edit-2-fill:before {
  content: "\ec7f";
}

.ri-edit-2-line:before {
  content: "\ec80";
}

.ri-edit-box-fill:before {
  content: "\ec81";
}

.ri-edit-box-line:before {
  content: "\ec82";
}

.ri-edit-circle-fill:before {
  content: "\ec83";
}

.ri-edit-circle-line:before {
  content: "\ec84";
}

.ri-edit-fill:before {
  content: "\ec85";
}

.ri-edit-line:before {
  content: "\ec86";
}

.ri-eject-fill:before {
  content: "\ec87";
}

.ri-eject-line:before {
  content: "\ec88";
}

.ri-emotion-2-fill:before {
  content: "\ec89";
}

.ri-emotion-2-line:before {
  content: "\ec8a";
}

.ri-emotion-fill:before {
  content: "\ec8b";
}

.ri-emotion-happy-fill:before {
  content: "\ec8c";
}

.ri-emotion-happy-line:before {
  content: "\ec8d";
}

.ri-emotion-laugh-fill:before {
  content: "\ec8e";
}

.ri-emotion-laugh-line:before {
  content: "\ec8f";
}

.ri-emotion-line:before {
  content: "\ec90";
}

.ri-emotion-normal-fill:before {
  content: "\ec91";
}

.ri-emotion-normal-line:before {
  content: "\ec92";
}

.ri-emotion-sad-fill:before {
  content: "\ec93";
}

.ri-emotion-sad-line:before {
  content: "\ec94";
}

.ri-emotion-unhappy-fill:before {
  content: "\ec95";
}

.ri-emotion-unhappy-line:before {
  content: "\ec96";
}

.ri-empathize-fill:before {
  content: "\ec97";
}

.ri-empathize-line:before {
  content: "\ec98";
}

.ri-emphasis-cn:before {
  content: "\ec99";
}

.ri-emphasis:before {
  content: "\ec9a";
}

.ri-english-input:before {
  content: "\ec9b";
}

.ri-equalizer-fill:before {
  content: "\ec9c";
}

.ri-equalizer-line:before {
  content: "\ec9d";
}

.ri-eraser-fill:before {
  content: "\ec9e";
}

.ri-eraser-line:before {
  content: "\ec9f";
}

.ri-error-warning-fill:before {
  content: "\eca0";
}

.ri-error-warning-line:before {
  content: "\eca1";
}

.ri-evernote-fill:before {
  content: "\eca2";
}

.ri-evernote-line:before {
  content: "\eca3";
}

.ri-exchange-box-fill:before {
  content: "\eca4";
}

.ri-exchange-box-line:before {
  content: "\eca5";
}

.ri-exchange-cny-fill:before {
  content: "\eca6";
}

.ri-exchange-cny-line:before {
  content: "\eca7";
}

.ri-exchange-dollar-fill:before {
  content: "\eca8";
}

.ri-exchange-dollar-line:before {
  content: "\eca9";
}

.ri-exchange-fill:before {
  content: "\ecaa";
}

.ri-exchange-funds-fill:before {
  content: "\ecab";
}

.ri-exchange-funds-line:before {
  content: "\ecac";
}

.ri-exchange-line:before {
  content: "\ecad";
}

.ri-external-link-fill:before {
  content: "\ecae";
}

.ri-external-link-line:before {
  content: "\ecaf";
}

.ri-eye-2-fill:before {
  content: "\ecb0";
}

.ri-eye-2-line:before {
  content: "\ecb1";
}

.ri-eye-close-fill:before {
  content: "\ecb2";
}

.ri-eye-close-line:before {
  content: "\ecb3";
}

.ri-eye-fill:before {
  content: "\ecb4";
}

.ri-eye-line:before {
  content: "\ecb5";
}

.ri-eye-off-fill:before {
  content: "\ecb6";
}

.ri-eye-off-line:before {
  content: "\ecb7";
}

.ri-facebook-box-fill:before {
  content: "\ecb8";
}

.ri-facebook-box-line:before {
  content: "\ecb9";
}

.ri-facebook-circle-fill:before {
  content: "\ecba";
}

.ri-facebook-circle-line:before {
  content: "\ecbb";
}

.ri-facebook-fill:before {
  content: "\ecbc";
}

.ri-facebook-line:before {
  content: "\ecbd";
}

.ri-fahrenheit-fill:before {
  content: "\ecbe";
}

.ri-fahrenheit-line:before {
  content: "\ecbf";
}

.ri-feedback-fill:before {
  content: "\ecc0";
}

.ri-feedback-line:before {
  content: "\ecc1";
}

.ri-file-2-fill:before {
  content: "\ecc2";
}

.ri-file-2-line:before {
  content: "\ecc3";
}

.ri-file-3-fill:before {
  content: "\ecc4";
}

.ri-file-3-line:before {
  content: "\ecc5";
}

.ri-file-4-fill:before {
  content: "\ecc6";
}

.ri-file-4-line:before {
  content: "\ecc7";
}

.ri-file-add-fill:before {
  content: "\ecc8";
}

.ri-file-add-line:before {
  content: "\ecc9";
}

.ri-file-chart-2-fill:before {
  content: "\ecca";
}

.ri-file-chart-2-line:before {
  content: "\eccb";
}

.ri-file-chart-fill:before {
  content: "\eccc";
}

.ri-file-chart-line:before {
  content: "\eccd";
}

.ri-file-cloud-fill:before {
  content: "\ecce";
}

.ri-file-cloud-line:before {
  content: "\eccf";
}

.ri-file-code-fill:before {
  content: "\ecd0";
}

.ri-file-code-line:before {
  content: "\ecd1";
}

.ri-file-copy-2-fill:before {
  content: "\ecd2";
}

.ri-file-copy-2-line:before {
  content: "\ecd3";
}

.ri-file-copy-fill:before {
  content: "\ecd4";
}

.ri-file-copy-line:before {
  content: "\ecd5";
}

.ri-file-damage-fill:before {
  content: "\ecd6";
}

.ri-file-damage-line:before {
  content: "\ecd7";
}

.ri-file-download-fill:before {
  content: "\ecd8";
}

.ri-file-download-line:before {
  content: "\ecd9";
}

.ri-file-edit-fill:before {
  content: "\ecda";
}

.ri-file-edit-line:before {
  content: "\ecdb";
}

.ri-file-excel-2-fill:before {
  content: "\ecdc";
}

.ri-file-excel-2-line:before {
  content: "\ecdd";
}

.ri-file-excel-fill:before {
  content: "\ecde";
}

.ri-file-excel-line:before {
  content: "\ecdf";
}

.ri-file-fill:before {
  content: "\ece0";
}

.ri-file-forbid-fill:before {
  content: "\ece1";
}

.ri-file-forbid-line:before {
  content: "\ece2";
}

.ri-file-gif-fill:before {
  content: "\ece3";
}

.ri-file-gif-line:before {
  content: "\ece4";
}

.ri-file-history-fill:before {
  content: "\ece5";
}

.ri-file-history-line:before {
  content: "\ece6";
}

.ri-file-hwp-fill:before {
  content: "\ece7";
}

.ri-file-hwp-line:before {
  content: "\ece8";
}

.ri-file-info-fill:before {
  content: "\ece9";
}

.ri-file-info-line:before {
  content: "\ecea";
}

.ri-file-line:before {
  content: "\eceb";
}

.ri-file-list-2-fill:before {
  content: "\ecec";
}

.ri-file-list-2-line:before {
  content: "\eced";
}

.ri-file-list-3-fill:before {
  content: "\ecee";
}

.ri-file-list-3-line:before {
  content: "\ecef";
}

.ri-file-list-fill:before {
  content: "\ecf0";
}

.ri-file-list-line:before {
  content: "\ecf1";
}

.ri-file-lock-fill:before {
  content: "\ecf2";
}

.ri-file-lock-line:before {
  content: "\ecf3";
}

.ri-file-mark-fill:before {
  content: "\ecf4";
}

.ri-file-mark-line:before {
  content: "\ecf5";
}

.ri-file-music-fill:before {
  content: "\ecf6";
}

.ri-file-music-line:before {
  content: "\ecf7";
}

.ri-file-paper-2-fill:before {
  content: "\ecf8";
}

.ri-file-paper-2-line:before {
  content: "\ecf9";
}

.ri-file-paper-fill:before {
  content: "\ecfa";
}

.ri-file-paper-line:before {
  content: "\ecfb";
}

.ri-file-pdf-fill:before {
  content: "\ecfc";
}

.ri-file-pdf-line:before {
  content: "\ecfd";
}

.ri-file-ppt-2-fill:before {
  content: "\ecfe";
}

.ri-file-ppt-2-line:before {
  content: "\ecff";
}

.ri-file-ppt-fill:before {
  content: "\ed00";
}

.ri-file-ppt-line:before {
  content: "\ed01";
}

.ri-file-reduce-fill:before {
  content: "\ed02";
}

.ri-file-reduce-line:before {
  content: "\ed03";
}

.ri-file-search-fill:before {
  content: "\ed04";
}

.ri-file-search-line:before {
  content: "\ed05";
}

.ri-file-settings-fill:before {
  content: "\ed06";
}

.ri-file-settings-line:before {
  content: "\ed07";
}

.ri-file-shield-2-fill:before {
  content: "\ed08";
}

.ri-file-shield-2-line:before {
  content: "\ed09";
}

.ri-file-shield-fill:before {
  content: "\ed0a";
}

.ri-file-shield-line:before {
  content: "\ed0b";
}

.ri-file-shred-fill:before {
  content: "\ed0c";
}

.ri-file-shred-line:before {
  content: "\ed0d";
}

.ri-file-text-fill:before {
  content: "\ed0e";
}

.ri-file-text-line:before {
  content: "\ed0f";
}

.ri-file-transfer-fill:before {
  content: "\ed10";
}

.ri-file-transfer-line:before {
  content: "\ed11";
}

.ri-file-unknow-fill:before {
  content: "\ed12";
}

.ri-file-unknow-line:before {
  content: "\ed13";
}

.ri-file-upload-fill:before {
  content: "\ed14";
}

.ri-file-upload-line:before {
  content: "\ed15";
}

.ri-file-user-fill:before {
  content: "\ed16";
}

.ri-file-user-line:before {
  content: "\ed17";
}

.ri-file-warning-fill:before {
  content: "\ed18";
}

.ri-file-warning-line:before {
  content: "\ed19";
}

.ri-file-word-2-fill:before {
  content: "\ed1a";
}

.ri-file-word-2-line:before {
  content: "\ed1b";
}

.ri-file-word-fill:before {
  content: "\ed1c";
}

.ri-file-word-line:before {
  content: "\ed1d";
}

.ri-file-zip-fill:before {
  content: "\ed1e";
}

.ri-file-zip-line:before {
  content: "\ed1f";
}

.ri-film-fill:before {
  content: "\ed20";
}

.ri-film-line:before {
  content: "\ed21";
}

.ri-filter-2-fill:before {
  content: "\ed22";
}

.ri-filter-2-line:before {
  content: "\ed23";
}

.ri-filter-3-fill:before {
  content: "\ed24";
}

.ri-filter-3-line:before {
  content: "\ed25";
}

.ri-filter-fill:before {
  content: "\ed26";
}

.ri-filter-line:before {
  content: "\ed27";
}

.ri-filter-off-fill:before {
  content: "\ed28";
}

.ri-filter-off-line:before {
  content: "\ed29";
}

.ri-find-replace-fill:before {
  content: "\ed2a";
}

.ri-find-replace-line:before {
  content: "\ed2b";
}

.ri-finder-fill:before {
  content: "\ed2c";
}

.ri-finder-line:before {
  content: "\ed2d";
}

.ri-fingerprint-2-fill:before {
  content: "\ed2e";
}

.ri-fingerprint-2-line:before {
  content: "\ed2f";
}

.ri-fingerprint-fill:before {
  content: "\ed30";
}

.ri-fingerprint-line:before {
  content: "\ed31";
}

.ri-fire-fill:before {
  content: "\ed32";
}

.ri-fire-line:before {
  content: "\ed33";
}

.ri-firefox-fill:before {
  content: "\ed34";
}

.ri-firefox-line:before {
  content: "\ed35";
}

.ri-first-aid-kit-fill:before {
  content: "\ed36";
}

.ri-first-aid-kit-line:before {
  content: "\ed37";
}

.ri-flag-2-fill:before {
  content: "\ed38";
}

.ri-flag-2-line:before {
  content: "\ed39";
}

.ri-flag-fill:before {
  content: "\ed3a";
}

.ri-flag-line:before {
  content: "\ed3b";
}

.ri-flashlight-fill:before {
  content: "\ed3c";
}

.ri-flashlight-line:before {
  content: "\ed3d";
}

.ri-flask-fill:before {
  content: "\ed3e";
}

.ri-flask-line:before {
  content: "\ed3f";
}

.ri-flight-land-fill:before {
  content: "\ed40";
}

.ri-flight-land-line:before {
  content: "\ed41";
}

.ri-flight-takeoff-fill:before {
  content: "\ed42";
}

.ri-flight-takeoff-line:before {
  content: "\ed43";
}

.ri-flood-fill:before {
  content: "\ed44";
}

.ri-flood-line:before {
  content: "\ed45";
}

.ri-flow-chart:before {
  content: "\ed46";
}

.ri-flutter-fill:before {
  content: "\ed47";
}

.ri-flutter-line:before {
  content: "\ed48";
}

.ri-focus-2-fill:before {
  content: "\ed49";
}

.ri-focus-2-line:before {
  content: "\ed4a";
}

.ri-focus-3-fill:before {
  content: "\ed4b";
}

.ri-focus-3-line:before {
  content: "\ed4c";
}

.ri-focus-fill:before {
  content: "\ed4d";
}

.ri-focus-line:before {
  content: "\ed4e";
}

.ri-foggy-fill:before {
  content: "\ed4f";
}

.ri-foggy-line:before {
  content: "\ed50";
}

.ri-folder-2-fill:before {
  content: "\ed51";
}

.ri-folder-2-line:before {
  content: "\ed52";
}

.ri-folder-3-fill:before {
  content: "\ed53";
}

.ri-folder-3-line:before {
  content: "\ed54";
}

.ri-folder-4-fill:before {
  content: "\ed55";
}

.ri-folder-4-line:before {
  content: "\ed56";
}

.ri-folder-5-fill:before {
  content: "\ed57";
}

.ri-folder-5-line:before {
  content: "\ed58";
}

.ri-folder-add-fill:before {
  content: "\ed59";
}

.ri-folder-add-line:before {
  content: "\ed5a";
}

.ri-folder-chart-2-fill:before {
  content: "\ed5b";
}

.ri-folder-chart-2-line:before {
  content: "\ed5c";
}

.ri-folder-chart-fill:before {
  content: "\ed5d";
}

.ri-folder-chart-line:before {
  content: "\ed5e";
}

.ri-folder-download-fill:before {
  content: "\ed5f";
}

.ri-folder-download-line:before {
  content: "\ed60";
}

.ri-folder-fill:before {
  content: "\ed61";
}

.ri-folder-forbid-fill:before {
  content: "\ed62";
}

.ri-folder-forbid-line:before {
  content: "\ed63";
}

.ri-folder-history-fill:before {
  content: "\ed64";
}

.ri-folder-history-line:before {
  content: "\ed65";
}

.ri-folder-info-fill:before {
  content: "\ed66";
}

.ri-folder-info-line:before {
  content: "\ed67";
}

.ri-folder-keyhole-fill:before {
  content: "\ed68";
}

.ri-folder-keyhole-line:before {
  content: "\ed69";
}

.ri-folder-line:before {
  content: "\ed6a";
}

.ri-folder-lock-fill:before {
  content: "\ed6b";
}

.ri-folder-lock-line:before {
  content: "\ed6c";
}

.ri-folder-music-fill:before {
  content: "\ed6d";
}

.ri-folder-music-line:before {
  content: "\ed6e";
}

.ri-folder-open-fill:before {
  content: "\ed6f";
}

.ri-folder-open-line:before {
  content: "\ed70";
}

.ri-folder-received-fill:before {
  content: "\ed71";
}

.ri-folder-received-line:before {
  content: "\ed72";
}

.ri-folder-reduce-fill:before {
  content: "\ed73";
}

.ri-folder-reduce-line:before {
  content: "\ed74";
}

.ri-folder-settings-fill:before {
  content: "\ed75";
}

.ri-folder-settings-line:before {
  content: "\ed76";
}

.ri-folder-shared-fill:before {
  content: "\ed77";
}

.ri-folder-shared-line:before {
  content: "\ed78";
}

.ri-folder-shield-2-fill:before {
  content: "\ed79";
}

.ri-folder-shield-2-line:before {
  content: "\ed7a";
}

.ri-folder-shield-fill:before {
  content: "\ed7b";
}

.ri-folder-shield-line:before {
  content: "\ed7c";
}

.ri-folder-transfer-fill:before {
  content: "\ed7d";
}

.ri-folder-transfer-line:before {
  content: "\ed7e";
}

.ri-folder-unknow-fill:before {
  content: "\ed7f";
}

.ri-folder-unknow-line:before {
  content: "\ed80";
}

.ri-folder-upload-fill:before {
  content: "\ed81";
}

.ri-folder-upload-line:before {
  content: "\ed82";
}

.ri-folder-user-fill:before {
  content: "\ed83";
}

.ri-folder-user-line:before {
  content: "\ed84";
}

.ri-folder-warning-fill:before {
  content: "\ed85";
}

.ri-folder-warning-line:before {
  content: "\ed86";
}

.ri-folder-zip-fill:before {
  content: "\ed87";
}

.ri-folder-zip-line:before {
  content: "\ed88";
}

.ri-folders-fill:before {
  content: "\ed89";
}

.ri-folders-line:before {
  content: "\ed8a";
}

.ri-font-color:before {
  content: "\ed8b";
}

.ri-font-size-2:before {
  content: "\ed8c";
}

.ri-font-size:before {
  content: "\ed8d";
}

.ri-football-fill:before {
  content: "\ed8e";
}

.ri-football-line:before {
  content: "\ed8f";
}

.ri-footprint-fill:before {
  content: "\ed90";
}

.ri-footprint-line:before {
  content: "\ed91";
}

.ri-forbid-2-fill:before {
  content: "\ed92";
}

.ri-forbid-2-line:before {
  content: "\ed93";
}

.ri-forbid-fill:before {
  content: "\ed94";
}

.ri-forbid-line:before {
  content: "\ed95";
}

.ri-format-clear:before {
  content: "\ed96";
}

.ri-fridge-fill:before {
  content: "\ed97";
}

.ri-fridge-line:before {
  content: "\ed98";
}

.ri-fullscreen-exit-fill:before {
  content: "\ed99";
}

.ri-fullscreen-exit-line:before {
  content: "\ed9a";
}

.ri-fullscreen-fill:before {
  content: "\ed9b";
}

.ri-fullscreen-line:before {
  content: "\ed9c";
}

.ri-function-fill:before {
  content: "\ed9d";
}

.ri-function-line:before {
  content: "\ed9e";
}

.ri-functions:before {
  content: "\ed9f";
}

.ri-funds-box-fill:before {
  content: "\eda0";
}

.ri-funds-box-line:before {
  content: "\eda1";
}

.ri-funds-fill:before {
  content: "\eda2";
}

.ri-funds-line:before {
  content: "\eda3";
}

.ri-gallery-fill:before {
  content: "\eda4";
}

.ri-gallery-line:before {
  content: "\eda5";
}

.ri-gallery-upload-fill:before {
  content: "\eda6";
}

.ri-gallery-upload-line:before {
  content: "\eda7";
}

.ri-game-fill:before {
  content: "\eda8";
}

.ri-game-line:before {
  content: "\eda9";
}

.ri-gamepad-fill:before {
  content: "\edaa";
}

.ri-gamepad-line:before {
  content: "\edab";
}

.ri-gas-station-fill:before {
  content: "\edac";
}

.ri-gas-station-line:before {
  content: "\edad";
}

.ri-gatsby-fill:before {
  content: "\edae";
}

.ri-gatsby-line:before {
  content: "\edaf";
}

.ri-genderless-fill:before {
  content: "\edb0";
}

.ri-genderless-line:before {
  content: "\edb1";
}

.ri-ghost-2-fill:before {
  content: "\edb2";
}

.ri-ghost-2-line:before {
  content: "\edb3";
}

.ri-ghost-fill:before {
  content: "\edb4";
}

.ri-ghost-line:before {
  content: "\edb5";
}

.ri-ghost-smile-fill:before {
  content: "\edb6";
}

.ri-ghost-smile-line:before {
  content: "\edb7";
}

.ri-gift-2-fill:before {
  content: "\edb8";
}

.ri-gift-2-line:before {
  content: "\edb9";
}

.ri-gift-fill:before {
  content: "\edba";
}

.ri-gift-line:before {
  content: "\edbb";
}

.ri-git-branch-fill:before {
  content: "\edbc";
}

.ri-git-branch-line:before {
  content: "\edbd";
}

.ri-git-commit-fill:before {
  content: "\edbe";
}

.ri-git-commit-line:before {
  content: "\edbf";
}

.ri-git-merge-fill:before {
  content: "\edc0";
}

.ri-git-merge-line:before {
  content: "\edc1";
}

.ri-git-pull-request-fill:before {
  content: "\edc2";
}

.ri-git-pull-request-line:before {
  content: "\edc3";
}

.ri-git-repository-commits-fill:before {
  content: "\edc4";
}

.ri-git-repository-commits-line:before {
  content: "\edc5";
}

.ri-git-repository-fill:before {
  content: "\edc6";
}

.ri-git-repository-line:before {
  content: "\edc7";
}

.ri-git-repository-private-fill:before {
  content: "\edc8";
}

.ri-git-repository-private-line:before {
  content: "\edc9";
}

.ri-github-fill:before {
  content: "\edca";
}

.ri-github-line:before {
  content: "\edcb";
}

.ri-gitlab-fill:before {
  content: "\edcc";
}

.ri-gitlab-line:before {
  content: "\edcd";
}

.ri-global-fill:before {
  content: "\edce";
}

.ri-global-line:before {
  content: "\edcf";
}

.ri-globe-fill:before {
  content: "\edd0";
}

.ri-globe-line:before {
  content: "\edd1";
}

.ri-goblet-fill:before {
  content: "\edd2";
}

.ri-goblet-line:before {
  content: "\edd3";
}

.ri-google-fill:before {
  content: "\edd4";
}

.ri-google-line:before {
  content: "\edd5";
}

.ri-google-play-fill:before {
  content: "\edd6";
}

.ri-google-play-line:before {
  content: "\edd7";
}

.ri-government-fill:before {
  content: "\edd8";
}

.ri-government-line:before {
  content: "\edd9";
}

.ri-gps-fill:before {
  content: "\edda";
}

.ri-gps-line:before {
  content: "\eddb";
}

.ri-gradienter-fill:before {
  content: "\eddc";
}

.ri-gradienter-line:before {
  content: "\eddd";
}

.ri-grid-fill:before {
  content: "\edde";
}

.ri-grid-line:before {
  content: "\eddf";
}

.ri-group-2-fill:before {
  content: "\ede0";
}

.ri-group-2-line:before {
  content: "\ede1";
}

.ri-group-fill:before {
  content: "\ede2";
}

.ri-group-line:before {
  content: "\ede3";
}

.ri-guide-fill:before {
  content: "\ede4";
}

.ri-guide-line:before {
  content: "\ede5";
}

.ri-h-1:before {
  content: "\ede6";
}

.ri-h-2:before {
  content: "\ede7";
}

.ri-h-3:before {
  content: "\ede8";
}

.ri-h-4:before {
  content: "\ede9";
}

.ri-h-5:before {
  content: "\edea";
}

.ri-h-6:before {
  content: "\edeb";
}

.ri-hail-fill:before {
  content: "\edec";
}

.ri-hail-line:before {
  content: "\eded";
}

.ri-hammer-fill:before {
  content: "\edee";
}

.ri-hammer-line:before {
  content: "\edef";
}

.ri-hand-coin-fill:before {
  content: "\edf0";
}

.ri-hand-coin-line:before {
  content: "\edf1";
}

.ri-hand-heart-fill:before {
  content: "\edf2";
}

.ri-hand-heart-line:before {
  content: "\edf3";
}

.ri-hand-sanitizer-fill:before {
  content: "\edf4";
}

.ri-hand-sanitizer-line:before {
  content: "\edf5";
}

.ri-handbag-fill:before {
  content: "\edf6";
}

.ri-handbag-line:before {
  content: "\edf7";
}

.ri-hard-drive-2-fill:before {
  content: "\edf8";
}

.ri-hard-drive-2-line:before {
  content: "\edf9";
}

.ri-hard-drive-fill:before {
  content: "\edfa";
}

.ri-hard-drive-line:before {
  content: "\edfb";
}

.ri-hashtag:before {
  content: "\edfc";
}

.ri-haze-2-fill:before {
  content: "\edfd";
}

.ri-haze-2-line:before {
  content: "\edfe";
}

.ri-haze-fill:before {
  content: "\edff";
}

.ri-haze-line:before {
  content: "\ee00";
}

.ri-hd-fill:before {
  content: "\ee01";
}

.ri-hd-line:before {
  content: "\ee02";
}

.ri-heading:before {
  content: "\ee03";
}

.ri-headphone-fill:before {
  content: "\ee04";
}

.ri-headphone-line:before {
  content: "\ee05";
}

.ri-health-book-fill:before {
  content: "\ee06";
}

.ri-health-book-line:before {
  content: "\ee07";
}

.ri-heart-2-fill:before {
  content: "\ee08";
}

.ri-heart-2-line:before {
  content: "\ee09";
}

.ri-heart-3-fill:before {
  content: "\ee0a";
}

.ri-heart-3-line:before {
  content: "\ee0b";
}

.ri-heart-add-fill:before {
  content: "\ee0c";
}

.ri-heart-add-line:before {
  content: "\ee0d";
}

.ri-heart-fill:before {
  content: "\ee0e";
}

.ri-heart-line:before {
  content: "\ee0f";
}

.ri-heart-pulse-fill:before {
  content: "\ee10";
}

.ri-heart-pulse-line:before {
  content: "\ee11";
}

.ri-hearts-fill:before {
  content: "\ee12";
}

.ri-hearts-line:before {
  content: "\ee13";
}

.ri-heavy-showers-fill:before {
  content: "\ee14";
}

.ri-heavy-showers-line:before {
  content: "\ee15";
}

.ri-history-fill:before {
  content: "\ee16";
}

.ri-history-line:before {
  content: "\ee17";
}

.ri-home-2-fill:before {
  content: "\ee18";
}

.ri-home-2-line:before {
  content: "\ee19";
}

.ri-home-3-fill:before {
  content: "\ee1a";
}

.ri-home-3-line:before {
  content: "\ee1b";
}

.ri-home-4-fill:before {
  content: "\ee1c";
}

.ri-home-4-line:before {
  content: "\ee1d";
}

.ri-home-5-fill:before {
  content: "\ee1e";
}

.ri-home-5-line:before {
  content: "\ee1f";
}

.ri-home-6-fill:before {
  content: "\ee20";
}

.ri-home-6-line:before {
  content: "\ee21";
}

.ri-home-7-fill:before {
  content: "\ee22";
}

.ri-home-7-line:before {
  content: "\ee23";
}

.ri-home-8-fill:before {
  content: "\ee24";
}

.ri-home-8-line:before {
  content: "\ee25";
}

.ri-home-fill:before {
  content: "\ee26";
}

.ri-home-gear-fill:before {
  content: "\ee27";
}

.ri-home-gear-line:before {
  content: "\ee28";
}

.ri-home-heart-fill:before {
  content: "\ee29";
}

.ri-home-heart-line:before {
  content: "\ee2a";
}

.ri-home-line:before {
  content: "\ee2b";
}

.ri-home-smile-2-fill:before {
  content: "\ee2c";
}

.ri-home-smile-2-line:before {
  content: "\ee2d";
}

.ri-home-smile-fill:before {
  content: "\ee2e";
}

.ri-home-smile-line:before {
  content: "\ee2f";
}

.ri-home-wifi-fill:before {
  content: "\ee30";
}

.ri-home-wifi-line:before {
  content: "\ee31";
}

.ri-honor-of-kings-fill:before {
  content: "\ee32";
}

.ri-honor-of-kings-line:before {
  content: "\ee33";
}

.ri-honour-fill:before {
  content: "\ee34";
}

.ri-honour-line:before {
  content: "\ee35";
}

.ri-hospital-fill:before {
  content: "\ee36";
}

.ri-hospital-line:before {
  content: "\ee37";
}

.ri-hotel-bed-fill:before {
  content: "\ee38";
}

.ri-hotel-bed-line:before {
  content: "\ee39";
}

.ri-hotel-fill:before {
  content: "\ee3a";
}

.ri-hotel-line:before {
  content: "\ee3b";
}

.ri-hotspot-fill:before {
  content: "\ee3c";
}

.ri-hotspot-line:before {
  content: "\ee3d";
}

.ri-hq-fill:before {
  content: "\ee3e";
}

.ri-hq-line:before {
  content: "\ee3f";
}

.ri-html5-fill:before {
  content: "\ee40";
}

.ri-html5-line:before {
  content: "\ee41";
}

.ri-ie-fill:before {
  content: "\ee42";
}

.ri-ie-line:before {
  content: "\ee43";
}

.ri-image-2-fill:before {
  content: "\ee44";
}

.ri-image-2-line:before {
  content: "\ee45";
}

.ri-image-add-fill:before {
  content: "\ee46";
}

.ri-image-add-line:before {
  content: "\ee47";
}

.ri-image-edit-fill:before {
  content: "\ee48";
}

.ri-image-edit-line:before {
  content: "\ee49";
}

.ri-image-fill:before {
  content: "\ee4a";
}

.ri-image-line:before {
  content: "\ee4b";
}

.ri-inbox-archive-fill:before {
  content: "\ee4c";
}

.ri-inbox-archive-line:before {
  content: "\ee4d";
}

.ri-inbox-fill:before {
  content: "\ee4e";
}

.ri-inbox-line:before {
  content: "\ee4f";
}

.ri-inbox-unarchive-fill:before {
  content: "\ee50";
}

.ri-inbox-unarchive-line:before {
  content: "\ee51";
}

.ri-increase-decrease-fill:before {
  content: "\ee52";
}

.ri-increase-decrease-line:before {
  content: "\ee53";
}

.ri-indent-decrease:before {
  content: "\ee54";
}

.ri-indent-increase:before {
  content: "\ee55";
}

.ri-indeterminate-circle-fill:before {
  content: "\ee56";
}

.ri-indeterminate-circle-line:before {
  content: "\ee57";
}

.ri-information-fill:before {
  content: "\ee58";
}

.ri-information-line:before {
  content: "\ee59";
}

.ri-infrared-thermometer-fill:before {
  content: "\ee5a";
}

.ri-infrared-thermometer-line:before {
  content: "\ee5b";
}

.ri-ink-bottle-fill:before {
  content: "\ee5c";
}

.ri-ink-bottle-line:before {
  content: "\ee5d";
}

.ri-input-cursor-move:before {
  content: "\ee5e";
}

.ri-input-method-fill:before {
  content: "\ee5f";
}

.ri-input-method-line:before {
  content: "\ee60";
}

.ri-insert-column-left:before {
  content: "\ee61";
}

.ri-insert-column-right:before {
  content: "\ee62";
}

.ri-insert-row-bottom:before {
  content: "\ee63";
}

.ri-insert-row-top:before {
  content: "\ee64";
}

.ri-instagram-fill:before {
  content: "\ee65";
}

.ri-instagram-line:before {
  content: "\ee66";
}

.ri-install-fill:before {
  content: "\ee67";
}

.ri-install-line:before {
  content: "\ee68";
}

.ri-invision-fill:before {
  content: "\ee69";
}

.ri-invision-line:before {
  content: "\ee6a";
}

.ri-italic:before {
  content: "\ee6b";
}

.ri-kakao-talk-fill:before {
  content: "\ee6c";
}

.ri-kakao-talk-line:before {
  content: "\ee6d";
}

.ri-key-2-fill:before {
  content: "\ee6e";
}

.ri-key-2-line:before {
  content: "\ee6f";
}

.ri-key-fill:before {
  content: "\ee70";
}

.ri-key-line:before {
  content: "\ee71";
}

.ri-keyboard-box-fill:before {
  content: "\ee72";
}

.ri-keyboard-box-line:before {
  content: "\ee73";
}

.ri-keyboard-fill:before {
  content: "\ee74";
}

.ri-keyboard-line:before {
  content: "\ee75";
}

.ri-keynote-fill:before {
  content: "\ee76";
}

.ri-keynote-line:before {
  content: "\ee77";
}

.ri-knife-blood-fill:before {
  content: "\ee78";
}

.ri-knife-blood-line:before {
  content: "\ee79";
}

.ri-knife-fill:before {
  content: "\ee7a";
}

.ri-knife-line:before {
  content: "\ee7b";
}

.ri-landscape-fill:before {
  content: "\ee7c";
}

.ri-landscape-line:before {
  content: "\ee7d";
}

.ri-layout-2-fill:before {
  content: "\ee7e";
}

.ri-layout-2-line:before {
  content: "\ee7f";
}

.ri-layout-3-fill:before {
  content: "\ee80";
}

.ri-layout-3-line:before {
  content: "\ee81";
}

.ri-layout-4-fill:before {
  content: "\ee82";
}

.ri-layout-4-line:before {
  content: "\ee83";
}

.ri-layout-5-fill:before {
  content: "\ee84";
}

.ri-layout-5-line:before {
  content: "\ee85";
}

.ri-layout-6-fill:before {
  content: "\ee86";
}

.ri-layout-6-line:before {
  content: "\ee87";
}

.ri-layout-bottom-2-fill:before {
  content: "\ee88";
}

.ri-layout-bottom-2-line:before {
  content: "\ee89";
}

.ri-layout-bottom-fill:before {
  content: "\ee8a";
}

.ri-layout-bottom-line:before {
  content: "\ee8b";
}

.ri-layout-column-fill:before {
  content: "\ee8c";
}

.ri-layout-column-line:before {
  content: "\ee8d";
}

.ri-layout-fill:before {
  content: "\ee8e";
}

.ri-layout-grid-fill:before {
  content: "\ee8f";
}

.ri-layout-grid-line:before {
  content: "\ee90";
}

.ri-layout-left-2-fill:before {
  content: "\ee91";
}

.ri-layout-left-2-line:before {
  content: "\ee92";
}

.ri-layout-left-fill:before {
  content: "\ee93";
}

.ri-layout-left-line:before {
  content: "\ee94";
}

.ri-layout-line:before {
  content: "\ee95";
}

.ri-layout-masonry-fill:before {
  content: "\ee96";
}

.ri-layout-masonry-line:before {
  content: "\ee97";
}

.ri-layout-right-2-fill:before {
  content: "\ee98";
}

.ri-layout-right-2-line:before {
  content: "\ee99";
}

.ri-layout-right-fill:before {
  content: "\ee9a";
}

.ri-layout-right-line:before {
  content: "\ee9b";
}

.ri-layout-row-fill:before {
  content: "\ee9c";
}

.ri-layout-row-line:before {
  content: "\ee9d";
}

.ri-layout-top-2-fill:before {
  content: "\ee9e";
}

.ri-layout-top-2-line:before {
  content: "\ee9f";
}

.ri-layout-top-fill:before {
  content: "\eea0";
}

.ri-layout-top-line:before {
  content: "\eea1";
}

.ri-leaf-fill:before {
  content: "\eea2";
}

.ri-leaf-line:before {
  content: "\eea3";
}

.ri-lifebuoy-fill:before {
  content: "\eea4";
}

.ri-lifebuoy-line:before {
  content: "\eea5";
}

.ri-lightbulb-fill:before {
  content: "\eea6";
}

.ri-lightbulb-flash-fill:before {
  content: "\eea7";
}

.ri-lightbulb-flash-line:before {
  content: "\eea8";
}

.ri-lightbulb-line:before {
  content: "\eea9";
}

.ri-line-chart-fill:before {
  content: "\eeaa";
}

.ri-line-chart-line:before {
  content: "\eeab";
}

.ri-line-fill:before {
  content: "\eeac";
}

.ri-line-height:before {
  content: "\eead";
}

.ri-line-line:before {
  content: "\eeae";
}

.ri-link-m:before {
  content: "\eeaf";
}

.ri-link-unlink-m:before {
  content: "\eeb0";
}

.ri-link-unlink:before {
  content: "\eeb1";
}

.ri-link:before {
  content: "\eeb2";
}

.ri-linkedin-box-fill:before {
  content: "\eeb3";
}

.ri-linkedin-box-line:before {
  content: "\eeb4";
}

.ri-linkedin-fill:before {
  content: "\eeb5";
}

.ri-linkedin-line:before {
  content: "\eeb6";
}

.ri-links-fill:before {
  content: "\eeb7";
}

.ri-links-line:before {
  content: "\eeb8";
}

.ri-list-check-2:before {
  content: "\eeb9";
}

.ri-list-check:before {
  content: "\eeba";
}

.ri-list-ordered:before {
  content: "\eebb";
}

.ri-list-settings-fill:before {
  content: "\eebc";
}

.ri-list-settings-line:before {
  content: "\eebd";
}

.ri-list-unordered:before {
  content: "\eebe";
}

.ri-live-fill:before {
  content: "\eebf";
}

.ri-live-line:before {
  content: "\eec0";
}

.ri-loader-2-fill:before {
  content: "\eec1";
}

.ri-loader-2-line:before {
  content: "\eec2";
}

.ri-loader-3-fill:before {
  content: "\eec3";
}

.ri-loader-3-line:before {
  content: "\eec4";
}

.ri-loader-4-fill:before {
  content: "\eec5";
}

.ri-loader-4-line:before {
  content: "\eec6";
}

.ri-loader-5-fill:before {
  content: "\eec7";
}

.ri-loader-5-line:before {
  content: "\eec8";
}

.ri-loader-fill:before {
  content: "\eec9";
}

.ri-loader-line:before {
  content: "\eeca";
}

.ri-lock-2-fill:before {
  content: "\eecb";
}

.ri-lock-2-line:before {
  content: "\eecc";
}

.ri-lock-fill:before {
  content: "\eecd";
}

.ri-lock-line:before {
  content: "\eece";
}

.ri-lock-password-fill:before {
  content: "\eecf";
}

.ri-lock-password-line:before {
  content: "\eed0";
}

.ri-lock-unlock-fill:before {
  content: "\eed1";
}

.ri-lock-unlock-line:before {
  content: "\eed2";
}

.ri-login-box-fill:before {
  content: "\eed3";
}

.ri-login-box-line:before {
  content: "\eed4";
}

.ri-login-circle-fill:before {
  content: "\eed5";
}

.ri-login-circle-line:before {
  content: "\eed6";
}

.ri-logout-box-fill:before {
  content: "\eed7";
}

.ri-logout-box-line:before {
  content: "\eed8";
}

.ri-logout-box-r-fill:before {
  content: "\eed9";
}

.ri-logout-box-r-line:before {
  content: "\eeda";
}

.ri-logout-circle-fill:before {
  content: "\eedb";
}

.ri-logout-circle-line:before {
  content: "\eedc";
}

.ri-logout-circle-r-fill:before {
  content: "\eedd";
}

.ri-logout-circle-r-line:before {
  content: "\eede";
}

.ri-luggage-cart-fill:before {
  content: "\eedf";
}

.ri-luggage-cart-line:before {
  content: "\eee0";
}

.ri-luggage-deposit-fill:before {
  content: "\eee1";
}

.ri-luggage-deposit-line:before {
  content: "\eee2";
}

.ri-lungs-fill:before {
  content: "\eee3";
}

.ri-lungs-line:before {
  content: "\eee4";
}

.ri-mac-fill:before {
  content: "\eee5";
}

.ri-mac-line:before {
  content: "\eee6";
}

.ri-macbook-fill:before {
  content: "\eee7";
}

.ri-macbook-line:before {
  content: "\eee8";
}

.ri-magic-fill:before {
  content: "\eee9";
}

.ri-magic-line:before {
  content: "\eeea";
}

.ri-mail-add-fill:before {
  content: "\eeeb";
}

.ri-mail-add-line:before {
  content: "\eeec";
}

.ri-mail-check-fill:before {
  content: "\eeed";
}

.ri-mail-check-line:before {
  content: "\eeee";
}

.ri-mail-close-fill:before {
  content: "\eeef";
}

.ri-mail-close-line:before {
  content: "\eef0";
}

.ri-mail-download-fill:before {
  content: "\eef1";
}

.ri-mail-download-line:before {
  content: "\eef2";
}

.ri-mail-fill:before {
  content: "\eef3";
}

.ri-mail-forbid-fill:before {
  content: "\eef4";
}

.ri-mail-forbid-line:before {
  content: "\eef5";
}

.ri-mail-line:before {
  content: "\eef6";
}

.ri-mail-lock-fill:before {
  content: "\eef7";
}

.ri-mail-lock-line:before {
  content: "\eef8";
}

.ri-mail-open-fill:before {
  content: "\eef9";
}

.ri-mail-open-line:before {
  content: "\eefa";
}

.ri-mail-send-fill:before {
  content: "\eefb";
}

.ri-mail-send-line:before {
  content: "\eefc";
}

.ri-mail-settings-fill:before {
  content: "\eefd";
}

.ri-mail-settings-line:before {
  content: "\eefe";
}

.ri-mail-star-fill:before {
  content: "\eeff";
}

.ri-mail-star-line:before {
  content: "\ef00";
}

.ri-mail-unread-fill:before {
  content: "\ef01";
}

.ri-mail-unread-line:before {
  content: "\ef02";
}

.ri-mail-volume-fill:before {
  content: "\ef03";
}

.ri-mail-volume-line:before {
  content: "\ef04";
}

.ri-map-2-fill:before {
  content: "\ef05";
}

.ri-map-2-line:before {
  content: "\ef06";
}

.ri-map-fill:before {
  content: "\ef07";
}

.ri-map-line:before {
  content: "\ef08";
}

.ri-map-pin-2-fill:before {
  content: "\ef09";
}

.ri-map-pin-2-line:before {
  content: "\ef0a";
}

.ri-map-pin-3-fill:before {
  content: "\ef0b";
}

.ri-map-pin-3-line:before {
  content: "\ef0c";
}

.ri-map-pin-4-fill:before {
  content: "\ef0d";
}

.ri-map-pin-4-line:before {
  content: "\ef0e";
}

.ri-map-pin-5-fill:before {
  content: "\ef0f";
}

.ri-map-pin-5-line:before {
  content: "\ef10";
}

.ri-map-pin-add-fill:before {
  content: "\ef11";
}

.ri-map-pin-add-line:before {
  content: "\ef12";
}

.ri-map-pin-fill:before {
  content: "\ef13";
}

.ri-map-pin-line:before {
  content: "\ef14";
}

.ri-map-pin-range-fill:before {
  content: "\ef15";
}

.ri-map-pin-range-line:before {
  content: "\ef16";
}

.ri-map-pin-time-fill:before {
  content: "\ef17";
}

.ri-map-pin-time-line:before {
  content: "\ef18";
}

.ri-map-pin-user-fill:before {
  content: "\ef19";
}

.ri-map-pin-user-line:before {
  content: "\ef1a";
}

.ri-mark-pen-fill:before {
  content: "\ef1b";
}

.ri-mark-pen-line:before {
  content: "\ef1c";
}

.ri-markdown-fill:before {
  content: "\ef1d";
}

.ri-markdown-line:before {
  content: "\ef1e";
}

.ri-markup-fill:before {
  content: "\ef1f";
}

.ri-markup-line:before {
  content: "\ef20";
}

.ri-mastercard-fill:before {
  content: "\ef21";
}

.ri-mastercard-line:before {
  content: "\ef22";
}

.ri-mastodon-fill:before {
  content: "\ef23";
}

.ri-mastodon-line:before {
  content: "\ef24";
}

.ri-medal-2-fill:before {
  content: "\ef25";
}

.ri-medal-2-line:before {
  content: "\ef26";
}

.ri-medal-fill:before {
  content: "\ef27";
}

.ri-medal-line:before {
  content: "\ef28";
}

.ri-medicine-bottle-fill:before {
  content: "\ef29";
}

.ri-medicine-bottle-line:before {
  content: "\ef2a";
}

.ri-medium-fill:before {
  content: "\ef2b";
}

.ri-medium-line:before {
  content: "\ef2c";
}

.ri-men-fill:before {
  content: "\ef2d";
}

.ri-men-line:before {
  content: "\ef2e";
}

.ri-mental-health-fill:before {
  content: "\ef2f";
}

.ri-mental-health-line:before {
  content: "\ef30";
}

.ri-menu-2-fill:before {
  content: "\ef31";
}

.ri-menu-2-line:before {
  content: "\ef32";
}

.ri-menu-3-fill:before {
  content: "\ef33";
}

.ri-menu-3-line:before {
  content: "\ef34";
}

.ri-menu-4-fill:before {
  content: "\ef35";
}

.ri-menu-4-line:before {
  content: "\ef36";
}

.ri-menu-5-fill:before {
  content: "\ef37";
}

.ri-menu-5-line:before {
  content: "\ef38";
}

.ri-menu-add-fill:before {
  content: "\ef39";
}

.ri-menu-add-line:before {
  content: "\ef3a";
}

.ri-menu-fill:before {
  content: "\ef3b";
}

.ri-menu-fold-fill:before {
  content: "\ef3c";
}

.ri-menu-fold-line:before {
  content: "\ef3d";
}

.ri-menu-line:before {
  content: "\ef3e";
}

.ri-menu-unfold-fill:before {
  content: "\ef3f";
}

.ri-menu-unfold-line:before {
  content: "\ef40";
}

.ri-merge-cells-horizontal:before {
  content: "\ef41";
}

.ri-merge-cells-vertical:before {
  content: "\ef42";
}

.ri-message-2-fill:before {
  content: "\ef43";
}

.ri-message-2-line:before {
  content: "\ef44";
}

.ri-message-3-fill:before {
  content: "\ef45";
}

.ri-message-3-line:before {
  content: "\ef46";
}

.ri-message-fill:before {
  content: "\ef47";
}

.ri-message-line:before {
  content: "\ef48";
}

.ri-messenger-fill:before {
  content: "\ef49";
}

.ri-messenger-line:before {
  content: "\ef4a";
}

.ri-meteor-fill:before {
  content: "\ef4b";
}

.ri-meteor-line:before {
  content: "\ef4c";
}

.ri-mic-2-fill:before {
  content: "\ef4d";
}

.ri-mic-2-line:before {
  content: "\ef4e";
}

.ri-mic-fill:before {
  content: "\ef4f";
}

.ri-mic-line:before {
  content: "\ef50";
}

.ri-mic-off-fill:before {
  content: "\ef51";
}

.ri-mic-off-line:before {
  content: "\ef52";
}

.ri-mickey-fill:before {
  content: "\ef53";
}

.ri-mickey-line:before {
  content: "\ef54";
}

.ri-microscope-fill:before {
  content: "\ef55";
}

.ri-microscope-line:before {
  content: "\ef56";
}

.ri-microsoft-fill:before {
  content: "\ef57";
}

.ri-microsoft-line:before {
  content: "\ef58";
}

.ri-mind-map:before {
  content: "\ef59";
}

.ri-mini-program-fill:before {
  content: "\ef5a";
}

.ri-mini-program-line:before {
  content: "\ef5b";
}

.ri-mist-fill:before {
  content: "\ef5c";
}

.ri-mist-line:before {
  content: "\ef5d";
}

.ri-money-cny-box-fill:before {
  content: "\ef5e";
}

.ri-money-cny-box-line:before {
  content: "\ef5f";
}

.ri-money-cny-circle-fill:before {
  content: "\ef60";
}

.ri-money-cny-circle-line:before {
  content: "\ef61";
}

.ri-money-dollar-box-fill:before {
  content: "\ef62";
}

.ri-money-dollar-box-line:before {
  content: "\ef63";
}

.ri-money-dollar-circle-fill:before {
  content: "\ef64";
}

.ri-money-dollar-circle-line:before {
  content: "\ef65";
}

.ri-money-euro-box-fill:before {
  content: "\ef66";
}

.ri-money-euro-box-line:before {
  content: "\ef67";
}

.ri-money-euro-circle-fill:before {
  content: "\ef68";
}

.ri-money-euro-circle-line:before {
  content: "\ef69";
}

.ri-money-pound-box-fill:before {
  content: "\ef6a";
}

.ri-money-pound-box-line:before {
  content: "\ef6b";
}

.ri-money-pound-circle-fill:before {
  content: "\ef6c";
}

.ri-money-pound-circle-line:before {
  content: "\ef6d";
}

.ri-moon-clear-fill:before {
  content: "\ef6e";
}

.ri-moon-clear-line:before {
  content: "\ef6f";
}

.ri-moon-cloudy-fill:before {
  content: "\ef70";
}

.ri-moon-cloudy-line:before {
  content: "\ef71";
}

.ri-moon-fill:before {
  content: "\ef72";
}

.ri-moon-foggy-fill:before {
  content: "\ef73";
}

.ri-moon-foggy-line:before {
  content: "\ef74";
}

.ri-moon-line:before {
  content: "\ef75";
}

.ri-more-2-fill:before {
  content: "\ef76";
}

.ri-more-2-line:before {
  content: "\ef77";
}

.ri-more-fill:before {
  content: "\ef78";
}

.ri-more-fill:before {
  content: "\ef79";
}

.ri-motorbike-fill:before {
  content: "\ef7a";
}

.ri-motorbike-line:before {
  content: "\ef7b";
}

.ri-mouse-fill:before {
  content: "\ef7c";
}

.ri-mouse-line:before {
  content: "\ef7d";
}

.ri-movie-2-fill:before {
  content: "\ef7e";
}

.ri-movie-2-line:before {
  content: "\ef7f";
}

.ri-movie-fill:before {
  content: "\ef80";
}

.ri-movie-line:before {
  content: "\ef81";
}

.ri-music-2-fill:before {
  content: "\ef82";
}

.ri-music-2-line:before {
  content: "\ef83";
}

.ri-music-fill:before {
  content: "\ef84";
}

.ri-music-line:before {
  content: "\ef85";
}

.ri-mv-fill:before {
  content: "\ef86";
}

.ri-mv-line:before {
  content: "\ef87";
}

.ri-navigation-fill:before {
  content: "\ef88";
}

.ri-navigation-line:before {
  content: "\ef89";
}

.ri-netease-cloud-music-fill:before {
  content: "\ef8a";
}

.ri-netease-cloud-music-line:before {
  content: "\ef8b";
}

.ri-netflix-fill:before {
  content: "\ef8c";
}

.ri-netflix-line:before {
  content: "\ef8d";
}

.ri-newspaper-fill:before {
  content: "\ef8e";
}

.ri-newspaper-line:before {
  content: "\ef8f";
}

.ri-node-tree:before {
  content: "\ef90";
}

.ri-notification-2-fill:before {
  content: "\ef91";
}

.ri-notification-2-line:before {
  content: "\ef92";
}

.ri-notification-3-fill:before {
  content: "\ef93";
}

.ri-notification-3-line:before {
  content: "\ef94";
}

.ri-notification-4-fill:before {
  content: "\ef95";
}

.ri-notification-4-line:before {
  content: "\ef96";
}

.ri-notification-badge-fill:before {
  content: "\ef97";
}

.ri-notification-badge-line:before {
  content: "\ef98";
}

.ri-notification-fill:before {
  content: "\ef99";
}

.ri-notification-line:before {
  content: "\ef9a";
}

.ri-notification-off-fill:before {
  content: "\ef9b";
}

.ri-notification-off-line:before {
  content: "\ef9c";
}

.ri-npmjs-fill:before {
  content: "\ef9d";
}

.ri-npmjs-line:before {
  content: "\ef9e";
}

.ri-number-0:before {
  content: "\ef9f";
}

.ri-number-1:before {
  content: "\efa0";
}

.ri-number-2:before {
  content: "\efa1";
}

.ri-number-3:before {
  content: "\efa2";
}

.ri-number-4:before {
  content: "\efa3";
}

.ri-number-5:before {
  content: "\efa4";
}

.ri-number-6:before {
  content: "\efa5";
}

.ri-number-7:before {
  content: "\efa6";
}

.ri-number-8:before {
  content: "\efa7";
}

.ri-number-9:before {
  content: "\efa8";
}

.ri-numbers-fill:before {
  content: "\efa9";
}

.ri-numbers-line:before {
  content: "\efaa";
}

.ri-nurse-fill:before {
  content: "\efab";
}

.ri-nurse-line:before {
  content: "\efac";
}

.ri-oil-fill:before {
  content: "\efad";
}

.ri-oil-line:before {
  content: "\efae";
}

.ri-omega:before {
  content: "\efaf";
}

.ri-open-arm-fill:before {
  content: "\efb0";
}

.ri-open-arm-line:before {
  content: "\efb1";
}

.ri-open-source-fill:before {
  content: "\efb2";
}

.ri-open-source-line:before {
  content: "\efb3";
}

.ri-opera-fill:before {
  content: "\efb4";
}

.ri-opera-line:before {
  content: "\efb5";
}

.ri-order-play-fill:before {
  content: "\efb6";
}

.ri-order-play-line:before {
  content: "\efb7";
}

.ri-organization-chart:before {
  content: "\efb8";
}

.ri-outlet-2-fill:before {
  content: "\efb9";
}

.ri-outlet-2-line:before {
  content: "\efba";
}

.ri-outlet-fill:before {
  content: "\efbb";
}

.ri-outlet-line:before {
  content: "\efbc";
}

.ri-page-separator:before {
  content: "\efbd";
}

.ri-pages-fill:before {
  content: "\efbe";
}

.ri-pages-line:before {
  content: "\efbf";
}

.ri-paint-brush-fill:before {
  content: "\efc0";
}

.ri-paint-brush-line:before {
  content: "\efc1";
}

.ri-paint-fill:before {
  content: "\efc2";
}

.ri-paint-line:before {
  content: "\efc3";
}

.ri-palette-fill:before {
  content: "\efc4";
}

.ri-palette-line:before {
  content: "\efc5";
}

.ri-pantone-fill:before {
  content: "\efc6";
}

.ri-pantone-line:before {
  content: "\efc7";
}

.ri-paragraph:before {
  content: "\efc8";
}

.ri-parent-fill:before {
  content: "\efc9";
}

.ri-parent-line:before {
  content: "\efca";
}

.ri-parentheses-fill:before {
  content: "\efcb";
}

.ri-parentheses-line:before {
  content: "\efcc";
}

.ri-parking-box-fill:before {
  content: "\efcd";
}

.ri-parking-box-line:before {
  content: "\efce";
}

.ri-parking-fill:before {
  content: "\efcf";
}

.ri-parking-line:before {
  content: "\efd0";
}

.ri-passport-fill:before {
  content: "\efd1";
}

.ri-passport-line:before {
  content: "\efd2";
}

.ri-patreon-fill:before {
  content: "\efd3";
}

.ri-patreon-line:before {
  content: "\efd4";
}

.ri-pause-circle-fill:before {
  content: "\efd5";
}

.ri-pause-circle-line:before {
  content: "\efd6";
}

.ri-pause-fill:before {
  content: "\efd7";
}

.ri-pause-line:before {
  content: "\efd8";
}

.ri-pause-mini-fill:before {
  content: "\efd9";
}

.ri-pause-mini-line:before {
  content: "\efda";
}

.ri-paypal-fill:before {
  content: "\efdb";
}

.ri-paypal-line:before {
  content: "\efdc";
}

.ri-pen-nib-fill:before {
  content: "\efdd";
}

.ri-pen-nib-line:before {
  content: "\efde";
}

.ri-pencil-fill:before {
  content: "\efdf";
}

.ri-pencil-line:before {
  content: "\efe0";
}

.ri-pencil-ruler-2-fill:before {
  content: "\efe1";
}

.ri-pencil-ruler-2-line:before {
  content: "\efe2";
}

.ri-pencil-ruler-fill:before {
  content: "\efe3";
}

.ri-pencil-ruler-line:before {
  content: "\efe4";
}

.ri-percent-fill:before {
  content: "\efe5";
}

.ri-percent-line:before {
  content: "\efe6";
}

.ri-phone-camera-fill:before {
  content: "\efe7";
}

.ri-phone-camera-line:before {
  content: "\efe8";
}

.ri-phone-fill:before {
  content: "\efe9";
}

.ri-phone-find-fill:before {
  content: "\efea";
}

.ri-phone-find-line:before {
  content: "\efeb";
}

.ri-phone-line:before {
  content: "\efec";
}

.ri-phone-lock-fill:before {
  content: "\efed";
}

.ri-phone-lock-line:before {
  content: "\efee";
}

.ri-picture-in-picture-2-fill:before {
  content: "\efef";
}

.ri-picture-in-picture-2-line:before {
  content: "\eff0";
}

.ri-picture-in-picture-exit-fill:before {
  content: "\eff1";
}

.ri-picture-in-picture-exit-line:before {
  content: "\eff2";
}

.ri-picture-in-picture-fill:before {
  content: "\eff3";
}

.ri-picture-in-picture-line:before {
  content: "\eff4";
}

.ri-pie-chart-2-fill:before {
  content: "\eff5";
}

.ri-pie-chart-2-line:before {
  content: "\eff6";
}

.ri-pie-chart-box-fill:before {
  content: "\eff7";
}

.ri-pie-chart-box-line:before {
  content: "\eff8";
}

.ri-pie-chart-fill:before {
  content: "\eff9";
}

.ri-pie-chart-line:before {
  content: "\effa";
}

.ri-pin-distance-fill:before {
  content: "\effb";
}

.ri-pin-distance-line:before {
  content: "\effc";
}

.ri-ping-pong-fill:before {
  content: "\effd";
}

.ri-ping-pong-line:before {
  content: "\effe";
}

.ri-pinterest-fill:before {
  content: "\efff";
}

.ri-pinterest-line:before {
  content: "\f000";
}

.ri-pinyin-input:before {
  content: "\f001";
}

.ri-pixelfed-fill:before {
  content: "\f002";
}

.ri-pixelfed-line:before {
  content: "\f003";
}

.ri-plane-fill:before {
  content: "\f004";
}

.ri-plane-line:before {
  content: "\f005";
}

.ri-plant-fill:before {
  content: "\f006";
}

.ri-plant-line:before {
  content: "\f007";
}

.ri-play-circle-fill:before {
  content: "\f008";
}

.ri-play-circle-line:before {
  content: "\f009";
}

.ri-play-fill:before {
  content: "\f00a";
}

.ri-play-line:before {
  content: "\f00b";
}

.ri-play-list-2-fill:before {
  content: "\f00c";
}

.ri-play-list-2-line:before {
  content: "\f00d";
}

.ri-play-list-add-fill:before {
  content: "\f00e";
}

.ri-play-list-add-line:before {
  content: "\f00f";
}

.ri-play-list-fill:before {
  content: "\f010";
}

.ri-play-list-line:before {
  content: "\f011";
}

.ri-play-mini-fill:before {
  content: "\f012";
}

.ri-play-mini-line:before {
  content: "\f013";
}

.ri-playstation-fill:before {
  content: "\f014";
}

.ri-playstation-line:before {
  content: "\f015";
}

.ri-plug-2-fill:before {
  content: "\f016";
}

.ri-plug-2-line:before {
  content: "\f017";
}

.ri-plug-fill:before {
  content: "\f018";
}

.ri-plug-line:before {
  content: "\f019";
}

.ri-polaroid-2-fill:before {
  content: "\f01a";
}

.ri-polaroid-2-line:before {
  content: "\f01b";
}

.ri-polaroid-fill:before {
  content: "\f01c";
}

.ri-polaroid-line:before {
  content: "\f01d";
}

.ri-police-car-fill:before {
  content: "\f01e";
}

.ri-police-car-line:before {
  content: "\f01f";
}

.ri-price-tag-2-fill:before {
  content: "\f020";
}

.ri-price-tag-2-line:before {
  content: "\f021";
}

.ri-price-tag-3-fill:before {
  content: "\f022";
}

.ri-price-tag-3-line:before {
  content: "\f023";
}

.ri-price-tag-fill:before {
  content: "\f024";
}

.ri-price-tag-line:before {
  content: "\f025";
}

.ri-printer-cloud-fill:before {
  content: "\f026";
}

.ri-printer-cloud-line:before {
  content: "\f027";
}

.ri-printer-fill:before {
  content: "\f028";
}

.ri-printer-line:before {
  content: "\f029";
}

.ri-product-hunt-fill:before {
  content: "\f02a";
}

.ri-product-hunt-line:before {
  content: "\f02b";
}

.ri-profile-fill:before {
  content: "\f02c";
}

.ri-profile-line:before {
  content: "\f02d";
}

.ri-projector-2-fill:before {
  content: "\f02e";
}

.ri-projector-2-line:before {
  content: "\f02f";
}

.ri-projector-fill:before {
  content: "\f030";
}

.ri-projector-line:before {
  content: "\f031";
}

.ri-psychotherapy-fill:before {
  content: "\f032";
}

.ri-psychotherapy-line:before {
  content: "\f033";
}

.ri-pulse-fill:before {
  content: "\f034";
}

.ri-pulse-line:before {
  content: "\f035";
}

.ri-pushpin-2-fill:before {
  content: "\f036";
}

.ri-pushpin-2-line:before {
  content: "\f037";
}

.ri-pushpin-fill:before {
  content: "\f038";
}

.ri-pushpin-line:before {
  content: "\f039";
}

.ri-qq-fill:before {
  content: "\f03a";
}

.ri-qq-line:before {
  content: "\f03b";
}

.ri-qr-code-fill:before {
  content: "\f03c";
}

.ri-qr-code-line:before {
  content: "\f03d";
}

.ri-qr-scan-2-fill:before {
  content: "\f03e";
}

.ri-qr-scan-2-line:before {
  content: "\f03f";
}

.ri-qr-scan-fill:before {
  content: "\f040";
}

.ri-qr-scan-line:before {
  content: "\f041";
}

.ri-question-answer-fill:before {
  content: "\f042";
}

.ri-question-answer-line:before {
  content: "\f043";
}

.ri-question-fill:before {
  content: "\f044";
}

.ri-question-line:before {
  content: "\f045";
}

.ri-question-mark:before {
  content: "\f046";
}

.ri-questionnaire-fill:before {
  content: "\f047";
}

.ri-questionnaire-line:before {
  content: "\f048";
}

.ri-quill-pen-fill:before {
  content: "\f049";
}

.ri-quill-pen-line:before {
  content: "\f04a";
}

.ri-radar-fill:before {
  content: "\f04b";
}

.ri-radar-line:before {
  content: "\f04c";
}

.ri-radio-2-fill:before {
  content: "\f04d";
}

.ri-radio-2-line:before {
  content: "\f04e";
}

.ri-radio-button-fill:before {
  content: "\f04f";
}

.ri-radio-button-line:before {
  content: "\f050";
}

.ri-radio-fill:before {
  content: "\f051";
}

.ri-radio-line:before {
  content: "\f052";
}

.ri-rainbow-fill:before {
  content: "\f053";
}

.ri-rainbow-line:before {
  content: "\f054";
}

.ri-rainy-fill:before {
  content: "\f055";
}

.ri-rainy-line:before {
  content: "\f056";
}

.ri-reactjs-fill:before {
  content: "\f057";
}

.ri-reactjs-line:before {
  content: "\f058";
}

.ri-record-circle-fill:before {
  content: "\f059";
}

.ri-record-circle-line:before {
  content: "\f05a";
}

.ri-record-mail-fill:before {
  content: "\f05b";
}

.ri-record-mail-line:before {
  content: "\f05c";
}

.ri-recycle-fill:before {
  content: "\f05d";
}

.ri-recycle-line:before {
  content: "\f05e";
}

.ri-red-packet-fill:before {
  content: "\f05f";
}

.ri-red-packet-line:before {
  content: "\f060";
}

.ri-reddit-fill:before {
  content: "\f061";
}

.ri-reddit-line:before {
  content: "\f062";
}

.ri-refresh-fill:before {
  content: "\f063";
}

.ri-refresh-line:before {
  content: "\f064";
}

.ri-refund-2-fill:before {
  content: "\f065";
}

.ri-refund-2-line:before {
  content: "\f066";
}

.ri-refund-fill:before {
  content: "\f067";
}

.ri-refund-line:before {
  content: "\f068";
}

.ri-registered-fill:before {
  content: "\f069";
}

.ri-registered-line:before {
  content: "\f06a";
}

.ri-remixicon-fill:before {
  content: "\f06b";
}

.ri-remixicon-line:before {
  content: "\f06c";
}

.ri-remote-control-2-fill:before {
  content: "\f06d";
}

.ri-remote-control-2-line:before {
  content: "\f06e";
}

.ri-remote-control-fill:before {
  content: "\f06f";
}

.ri-remote-control-line:before {
  content: "\f070";
}

.ri-repeat-2-fill:before {
  content: "\f071";
}

.ri-repeat-2-line:before {
  content: "\f072";
}

.ri-repeat-fill:before {
  content: "\f073";
}

.ri-repeat-line:before {
  content: "\f074";
}

.ri-repeat-one-fill:before {
  content: "\f075";
}

.ri-repeat-one-line:before {
  content: "\f076";
}

.ri-reply-all-fill:before {
  content: "\f077";
}

.ri-reply-all-line:before {
  content: "\f078";
}

.ri-reply-fill:before {
  content: "\f079";
}

.ri-reply-line:before {
  content: "\f07a";
}

.ri-reserved-fill:before {
  content: "\f07b";
}

.ri-reserved-line:before {
  content: "\f07c";
}

.ri-rest-time-fill:before {
  content: "\f07d";
}

.ri-rest-time-line:before {
  content: "\f07e";
}

.ri-restart-fill:before {
  content: "\f07f";
}

.ri-restart-line:before {
  content: "\f080";
}

.ri-restaurant-2-fill:before {
  content: "\f081";
}

.ri-restaurant-2-line:before {
  content: "\f082";
}

.ri-restaurant-fill:before {
  content: "\f083";
}

.ri-restaurant-line:before {
  content: "\f084";
}

.ri-rewind-fill:before {
  content: "\f085";
}

.ri-rewind-line:before {
  content: "\f086";
}

.ri-rewind-mini-fill:before {
  content: "\f087";
}

.ri-rewind-mini-line:before {
  content: "\f088";
}

.ri-rhythm-fill:before {
  content: "\f089";
}

.ri-rhythm-line:before {
  content: "\f08a";
}

.ri-riding-fill:before {
  content: "\f08b";
}

.ri-riding-line:before {
  content: "\f08c";
}

.ri-road-map-fill:before {
  content: "\f08d";
}

.ri-road-map-line:before {
  content: "\f08e";
}

.ri-roadster-fill:before {
  content: "\f08f";
}

.ri-roadster-line:before {
  content: "\f090";
}

.ri-robot-fill:before {
  content: "\f091";
}

.ri-robot-line:before {
  content: "\f092";
}

.ri-rocket-2-fill:before {
  content: "\f093";
}

.ri-rocket-2-line:before {
  content: "\f094";
}

.ri-rocket-fill:before {
  content: "\f095";
}

.ri-rocket-line:before {
  content: "\f096";
}

.ri-rotate-lock-fill:before {
  content: "\f097";
}

.ri-rotate-lock-line:before {
  content: "\f098";
}

.ri-rounded-corner:before {
  content: "\f099";
}

.ri-route-fill:before {
  content: "\f09a";
}

.ri-route-line:before {
  content: "\f09b";
}

.ri-router-fill:before {
  content: "\f09c";
}

.ri-router-line:before {
  content: "\f09d";
}

.ri-rss-fill:before {
  content: "\f09e";
}

.ri-rss-line:before {
  content: "\f09f";
}

.ri-ruler-2-fill:before {
  content: "\f0a0";
}

.ri-ruler-2-line:before {
  content: "\f0a1";
}

.ri-ruler-fill:before {
  content: "\f0a2";
}

.ri-ruler-line:before {
  content: "\f0a3";
}

.ri-run-fill:before {
  content: "\f0a4";
}

.ri-run-line:before {
  content: "\f0a5";
}

.ri-safari-fill:before {
  content: "\f0a6";
}

.ri-safari-line:before {
  content: "\f0a7";
}

.ri-safe-2-fill:before {
  content: "\f0a8";
}

.ri-safe-2-line:before {
  content: "\f0a9";
}

.ri-safe-fill:before {
  content: "\f0aa";
}

.ri-safe-line:before {
  content: "\f0ab";
}

.ri-sailboat-fill:before {
  content: "\f0ac";
}

.ri-sailboat-line:before {
  content: "\f0ad";
}

.ri-save-2-fill:before {
  content: "\f0ae";
}

.ri-save-2-line:before {
  content: "\f0af";
}

.ri-save-3-fill:before {
  content: "\f0b0";
}

.ri-save-3-line:before {
  content: "\f0b1";
}

.ri-save-fill:before {
  content: "\f0b2";
}

.ri-save-line:before {
  content: "\f0b3";
}

.ri-scales-2-fill:before {
  content: "\f0b4";
}

.ri-scales-2-line:before {
  content: "\f0b5";
}

.ri-scales-3-fill:before {
  content: "\f0b6";
}

.ri-scales-3-line:before {
  content: "\f0b7";
}

.ri-scales-fill:before {
  content: "\f0b8";
}

.ri-scales-line:before {
  content: "\f0b9";
}

.ri-scan-2-fill:before {
  content: "\f0ba";
}

.ri-scan-2-line:before {
  content: "\f0bb";
}

.ri-scan-fill:before {
  content: "\f0bc";
}

.ri-scan-line:before {
  content: "\f0bd";
}

.ri-scissors-2-fill:before {
  content: "\f0be";
}

.ri-scissors-2-line:before {
  content: "\f0bf";
}

.ri-scissors-cut-fill:before {
  content: "\f0c0";
}

.ri-scissors-cut-line:before {
  content: "\f0c1";
}

.ri-scissors-fill:before {
  content: "\f0c2";
}

.ri-scissors-line:before {
  content: "\f0c3";
}

.ri-screenshot-2-fill:before {
  content: "\f0c4";
}

.ri-screenshot-2-line:before {
  content: "\f0c5";
}

.ri-screenshot-fill:before {
  content: "\f0c6";
}

.ri-screenshot-line:before {
  content: "\f0c7";
}

.ri-sd-card-fill:before {
  content: "\f0c8";
}

.ri-sd-card-line:before {
  content: "\f0c9";
}

.ri-sd-card-mini-fill:before {
  content: "\f0ca";
}

.ri-sd-card-mini-line:before {
  content: "\f0cb";
}

.ri-search-2-fill:before {
  content: "\f0cc";
}

.ri-search-2-line:before {
  content: "\f0cd";
}

.ri-search-eye-fill:before {
  content: "\f0ce";
}

.ri-search-eye-line:before {
  content: "\f0cf";
}

.ri-search-fill:before {
  content: "\f0d0";
}

.ri-search-line:before {
  content: "\f0d1";
}

.ri-secure-payment-fill:before {
  content: "\f0d2";
}

.ri-secure-payment-line:before {
  content: "\f0d3";
}

.ri-seedling-fill:before {
  content: "\f0d4";
}

.ri-seedling-line:before {
  content: "\f0d5";
}

.ri-send-backward:before {
  content: "\f0d6";
}

.ri-send-plane-2-fill:before {
  content: "\f0d7";
}

.ri-send-plane-2-line:before {
  content: "\f0d8";
}

.ri-send-plane-fill:before {
  content: "\f0d9";
}

.ri-send-plane-line:before {
  content: "\f0da";
}

.ri-send-to-back:before {
  content: "\f0db";
}

.ri-sensor-fill:before {
  content: "\f0dc";
}

.ri-sensor-line:before {
  content: "\f0dd";
}

.ri-separator:before {
  content: "\f0de";
}

.ri-server-fill:before {
  content: "\f0df";
}

.ri-server-line:before {
  content: "\f0e0";
}

.ri-service-fill:before {
  content: "\f0e1";
}

.ri-service-line:before {
  content: "\f0e2";
}

.ri-settings-2-fill:before {
  content: "\f0e3";
}

.ri-settings-2-line:before {
  content: "\f0e4";
}

.ri-settings-3-fill:before {
  content: "\f0e5";
}

.ri-settings-3-line:before {
  content: "\f0e6";
}

.ri-settings-4-fill:before {
  content: "\f0e7";
}

.ri-settings-4-line:before {
  content: "\f0e8";
}

.ri-settings-5-fill:before {
  content: "\f0e9";
}

.ri-settings-5-line:before {
  content: "\f0ea";
}

.ri-settings-6-fill:before {
  content: "\f0eb";
}

.ri-settings-6-line:before {
  content: "\f0ec";
}

.ri-settings-fill:before {
  content: "\f0ed";
}

.ri-settings-line:before {
  content: "\f0ee";
}

.ri-shape-2-fill:before {
  content: "\f0ef";
}

.ri-shape-2-line:before {
  content: "\f0f0";
}

.ri-shape-fill:before {
  content: "\f0f1";
}

.ri-shape-line:before {
  content: "\f0f2";
}

.ri-share-box-fill:before {
  content: "\f0f3";
}

.ri-share-box-line:before {
  content: "\f0f4";
}

.ri-share-circle-fill:before {
  content: "\f0f5";
}

.ri-share-circle-line:before {
  content: "\f0f6";
}

.ri-share-fill:before {
  content: "\f0f7";
}

.ri-share-forward-2-fill:before {
  content: "\f0f8";
}

.ri-share-forward-2-line:before {
  content: "\f0f9";
}

.ri-share-forward-box-fill:before {
  content: "\f0fa";
}

.ri-share-forward-box-line:before {
  content: "\f0fb";
}

.ri-share-forward-fill:before {
  content: "\f0fc";
}

.ri-share-forward-line:before {
  content: "\f0fd";
}

.ri-share-line:before {
  content: "\f0fe";
}

.ri-shield-check-fill:before {
  content: "\f0ff";
}

.ri-shield-check-line:before {
  content: "\f100";
}

.ri-shield-cross-fill:before {
  content: "\f101";
}

.ri-shield-cross-line:before {
  content: "\f102";
}

.ri-shield-fill:before {
  content: "\f103";
}

.ri-shield-flash-fill:before {
  content: "\f104";
}

.ri-shield-flash-line:before {
  content: "\f105";
}

.ri-shield-keyhole-fill:before {
  content: "\f106";
}

.ri-shield-keyhole-line:before {
  content: "\f107";
}

.ri-shield-line:before {
  content: "\f108";
}

.ri-shield-star-fill:before {
  content: "\f109";
}

.ri-shield-star-line:before {
  content: "\f10a";
}

.ri-shield-user-fill:before {
  content: "\f10b";
}

.ri-shield-user-line:before {
  content: "\f10c";
}

.ri-ship-2-fill:before {
  content: "\f10d";
}

.ri-ship-2-line:before {
  content: "\f10e";
}

.ri-ship-fill:before {
  content: "\f10f";
}

.ri-ship-line:before {
  content: "\f110";
}

.ri-shirt-fill:before {
  content: "\f111";
}

.ri-shirt-line:before {
  content: "\f112";
}

.ri-shopping-bag-2-fill:before {
  content: "\f113";
}

.ri-shopping-bag-2-line:before {
  content: "\f114";
}

.ri-shopping-bag-3-fill:before {
  content: "\f115";
}

.ri-shopping-bag-3-line:before {
  content: "\f116";
}

.ri-shopping-bag-fill:before {
  content: "\f117";
}

.ri-shopping-bag-line:before {
  content: "\f118";
}

.ri-shopping-basket-2-fill:before {
  content: "\f119";
}

.ri-shopping-basket-2-line:before {
  content: "\f11a";
}

.ri-shopping-basket-fill:before {
  content: "\f11b";
}

.ri-shopping-basket-line:before {
  content: "\f11c";
}

.ri-shopping-cart-2-fill:before {
  content: "\f11d";
}

.ri-shopping-cart-2-line:before {
  content: "\f11e";
}

.ri-shopping-cart-fill:before {
  content: "\f11f";
}

.ri-shopping-cart-line:before {
  content: "\f120";
}

.ri-showers-fill:before {
  content: "\f121";
}

.ri-showers-line:before {
  content: "\f122";
}

.ri-shuffle-fill:before {
  content: "\f123";
}

.ri-shuffle-line:before {
  content: "\f124";
}

.ri-shut-down-fill:before {
  content: "\f125";
}

.ri-shut-down-line:before {
  content: "\f126";
}

.ri-side-bar-fill:before {
  content: "\f127";
}

.ri-side-bar-line:before {
  content: "\f128";
}

.ri-signal-tower-fill:before {
  content: "\f129";
}

.ri-signal-tower-line:before {
  content: "\f12a";
}

.ri-signal-wifi-1-fill:before {
  content: "\f12b";
}

.ri-signal-wifi-1-line:before {
  content: "\f12c";
}

.ri-signal-wifi-2-fill:before {
  content: "\f12d";
}

.ri-signal-wifi-2-line:before {
  content: "\f12e";
}

.ri-signal-wifi-3-fill:before {
  content: "\f12f";
}

.ri-signal-wifi-3-line:before {
  content: "\f130";
}

.ri-signal-wifi-error-fill:before {
  content: "\f131";
}

.ri-signal-wifi-error-line:before {
  content: "\f132";
}

.ri-signal-wifi-fill:before {
  content: "\f133";
}

.ri-signal-wifi-line:before {
  content: "\f134";
}

.ri-signal-wifi-off-fill:before {
  content: "\f135";
}

.ri-signal-wifi-off-line:before {
  content: "\f136";
}

.ri-sim-card-2-fill:before {
  content: "\f137";
}

.ri-sim-card-2-line:before {
  content: "\f138";
}

.ri-sim-card-fill:before {
  content: "\f139";
}

.ri-sim-card-line:before {
  content: "\f13a";
}

.ri-single-quotes-l:before {
  content: "\f13b";
}

.ri-single-quotes-r:before {
  content: "\f13c";
}

.ri-sip-fill:before {
  content: "\f13d";
}

.ri-sip-line:before {
  content: "\f13e";
}

.ri-skip-back-fill:before {
  content: "\f13f";
}

.ri-skip-back-line:before {
  content: "\f140";
}

.ri-skip-back-mini-fill:before {
  content: "\f141";
}

.ri-skip-back-mini-line:before {
  content: "\f142";
}

.ri-skip-forward-fill:before {
  content: "\f143";
}

.ri-skip-forward-line:before {
  content: "\f144";
}

.ri-skip-forward-mini-fill:before {
  content: "\f145";
}

.ri-skip-forward-mini-line:before {
  content: "\f146";
}

.ri-skull-2-fill:before {
  content: "\f147";
}

.ri-skull-2-line:before {
  content: "\f148";
}

.ri-skull-fill:before {
  content: "\f149";
}

.ri-skull-line:before {
  content: "\f14a";
}

.ri-skype-fill:before {
  content: "\f14b";
}

.ri-skype-line:before {
  content: "\f14c";
}

.ri-slack-fill:before {
  content: "\f14d";
}

.ri-slack-line:before {
  content: "\f14e";
}

.ri-slice-fill:before {
  content: "\f14f";
}

.ri-slice-line:before {
  content: "\f150";
}

.ri-slideshow-2-fill:before {
  content: "\f151";
}

.ri-slideshow-2-line:before {
  content: "\f152";
}

.ri-slideshow-3-fill:before {
  content: "\f153";
}

.ri-slideshow-3-line:before {
  content: "\f154";
}

.ri-slideshow-4-fill:before {
  content: "\f155";
}

.ri-slideshow-4-line:before {
  content: "\f156";
}

.ri-slideshow-fill:before {
  content: "\f157";
}

.ri-slideshow-line:before {
  content: "\f158";
}

.ri-smartphone-fill:before {
  content: "\f159";
}

.ri-smartphone-line:before {
  content: "\f15a";
}

.ri-snapchat-fill:before {
  content: "\f15b";
}

.ri-snapchat-line:before {
  content: "\f15c";
}

.ri-snowy-fill:before {
  content: "\f15d";
}

.ri-snowy-line:before {
  content: "\f15e";
}

.ri-sort-asc:before {
  content: "\f15f";
}

.ri-sort-desc:before {
  content: "\f160";
}

.ri-sound-module-fill:before {
  content: "\f161";
}

.ri-sound-module-line:before {
  content: "\f162";
}

.ri-soundcloud-fill:before {
  content: "\f163";
}

.ri-soundcloud-line:before {
  content: "\f164";
}

.ri-space-ship-fill:before {
  content: "\f165";
}

.ri-space-ship-line:before {
  content: "\f166";
}

.ri-space:before {
  content: "\f167";
}

.ri-spam-2-fill:before {
  content: "\f168";
}

.ri-spam-2-line:before {
  content: "\f169";
}

.ri-spam-3-fill:before {
  content: "\f16a";
}

.ri-spam-3-line:before {
  content: "\f16b";
}

.ri-spam-fill:before {
  content: "\f16c";
}

.ri-spam-line:before {
  content: "\f16d";
}

.ri-speaker-2-fill:before {
  content: "\f16e";
}

.ri-speaker-2-line:before {
  content: "\f16f";
}

.ri-speaker-3-fill:before {
  content: "\f170";
}

.ri-speaker-3-line:before {
  content: "\f171";
}

.ri-speaker-fill:before {
  content: "\f172";
}

.ri-speaker-line:before {
  content: "\f173";
}

.ri-spectrum-fill:before {
  content: "\f174";
}

.ri-spectrum-line:before {
  content: "\f175";
}

.ri-speed-fill:before {
  content: "\f176";
}

.ri-speed-line:before {
  content: "\f177";
}

.ri-speed-mini-fill:before {
  content: "\f178";
}

.ri-speed-mini-line:before {
  content: "\f179";
}

.ri-split-cells-horizontal:before {
  content: "\f17a";
}

.ri-split-cells-vertical:before {
  content: "\f17b";
}

.ri-spotify-fill:before {
  content: "\f17c";
}

.ri-spotify-line:before {
  content: "\f17d";
}

.ri-spy-fill:before {
  content: "\f17e";
}

.ri-spy-line:before {
  content: "\f17f";
}

.ri-stack-fill:before {
  content: "\f180";
}

.ri-stack-line:before {
  content: "\f181";
}

.ri-stack-overflow-fill:before {
  content: "\f182";
}

.ri-stack-overflow-line:before {
  content: "\f183";
}

.ri-stackshare-fill:before {
  content: "\f184";
}

.ri-stackshare-line:before {
  content: "\f185";
}

.ri-star-fill:before {
  content: "\f186";
}

.ri-star-half-fill:before {
  content: "\f187";
}

.ri-star-half-line:before {
  content: "\f188";
}

.ri-star-half-s-fill:before {
  content: "\f189";
}

.ri-star-half-s-line:before {
  content: "\f18a";
}

.ri-star-line:before {
  content: "\f18b";
}

.ri-star-s-fill:before {
  content: "\f18c";
}

.ri-star-s-line:before {
  content: "\f18d";
}

.ri-star-smile-fill:before {
  content: "\f18e";
}

.ri-star-smile-line:before {
  content: "\f18f";
}

.ri-steam-fill:before {
  content: "\f190";
}

.ri-steam-line:before {
  content: "\f191";
}

.ri-steering-2-fill:before {
  content: "\f192";
}

.ri-steering-2-line:before {
  content: "\f193";
}

.ri-steering-fill:before {
  content: "\f194";
}

.ri-steering-line:before {
  content: "\f195";
}

.ri-stethoscope-fill:before {
  content: "\f196";
}

.ri-stethoscope-line:before {
  content: "\f197";
}

.ri-sticky-note-2-fill:before {
  content: "\f198";
}

.ri-sticky-note-2-line:before {
  content: "\f199";
}

.ri-sticky-note-fill:before {
  content: "\f19a";
}

.ri-sticky-note-line:before {
  content: "\f19b";
}

.ri-stock-fill:before {
  content: "\f19c";
}

.ri-stock-line:before {
  content: "\f19d";
}

.ri-stop-circle-fill:before {
  content: "\f19e";
}

.ri-stop-circle-line:before {
  content: "\f19f";
}

.ri-stop-fill:before {
  content: "\f1a0";
}

.ri-stop-line:before {
  content: "\f1a1";
}

.ri-stop-mini-fill:before {
  content: "\f1a2";
}

.ri-stop-mini-line:before {
  content: "\f1a3";
}

.ri-store-2-fill:before {
  content: "\f1a4";
}

.ri-store-2-line:before {
  content: "\f1a5";
}

.ri-store-3-fill:before {
  content: "\f1a6";
}

.ri-store-3-line:before {
  content: "\f1a7";
}

.ri-store-fill:before {
  content: "\f1a8";
}

.ri-store-line:before {
  content: "\f1a9";
}

.ri-strikethrough-2:before {
  content: "\f1aa";
}

.ri-strikethrough:before {
  content: "\f1ab";
}

.ri-subscript-2:before {
  content: "\f1ac";
}

.ri-subscript:before {
  content: "\f1ad";
}

.ri-subtract-fill:before {
  content: "\f1ae";
}

.ri-subtract-line:before {
  content: "\f1af";
}

.ri-subway-fill:before {
  content: "\f1b0";
}

.ri-subway-line:before {
  content: "\f1b1";
}

.ri-subway-wifi-fill:before {
  content: "\f1b2";
}

.ri-subway-wifi-line:before {
  content: "\f1b3";
}

.ri-suitcase-2-fill:before {
  content: "\f1b4";
}

.ri-suitcase-2-line:before {
  content: "\f1b5";
}

.ri-suitcase-3-fill:before {
  content: "\f1b6";
}

.ri-suitcase-3-line:before {
  content: "\f1b7";
}

.ri-suitcase-fill:before {
  content: "\f1b8";
}

.ri-suitcase-line:before {
  content: "\f1b9";
}

.ri-sun-cloudy-fill:before {
  content: "\f1ba";
}

.ri-sun-cloudy-line:before {
  content: "\f1bb";
}

.ri-sun-fill:before {
  content: "\f1bc";
}

.ri-sun-foggy-fill:before {
  content: "\f1bd";
}

.ri-sun-foggy-line:before {
  content: "\f1be";
}

.ri-sun-line:before {
  content: "\f1bf";
}

.ri-superscript-2:before {
  content: "\f1c0";
}

.ri-superscript:before {
  content: "\f1c1";
}

.ri-surgical-mask-fill:before {
  content: "\f1c2";
}

.ri-surgical-mask-line:before {
  content: "\f1c3";
}

.ri-surround-sound-fill:before {
  content: "\f1c4";
}

.ri-surround-sound-line:before {
  content: "\f1c5";
}

.ri-survey-fill:before {
  content: "\f1c6";
}

.ri-survey-line:before {
  content: "\f1c7";
}

.ri-swap-box-fill:before {
  content: "\f1c8";
}

.ri-swap-box-line:before {
  content: "\f1c9";
}

.ri-swap-fill:before {
  content: "\f1ca";
}

.ri-swap-line:before {
  content: "\f1cb";
}

.ri-switch-fill:before {
  content: "\f1cc";
}

.ri-switch-line:before {
  content: "\f1cd";
}

.ri-sword-fill:before {
  content: "\f1ce";
}

.ri-sword-line:before {
  content: "\f1cf";
}

.ri-syringe-fill:before {
  content: "\f1d0";
}

.ri-syringe-line:before {
  content: "\f1d1";
}

.ri-t-box-fill:before {
  content: "\f1d2";
}

.ri-t-box-line:before {
  content: "\f1d3";
}

.ri-t-shirt-2-fill:before {
  content: "\f1d4";
}

.ri-t-shirt-2-line:before {
  content: "\f1d5";
}

.ri-t-shirt-air-fill:before {
  content: "\f1d6";
}

.ri-t-shirt-air-line:before {
  content: "\f1d7";
}

.ri-t-shirt-fill:before {
  content: "\f1d8";
}

.ri-t-shirt-line:before {
  content: "\f1d9";
}

.ri-table-2:before {
  content: "\f1da";
}

.ri-table-alt-fill:before {
  content: "\f1db";
}

.ri-table-alt-line:before {
  content: "\f1dc";
}

.ri-table-fill:before {
  content: "\f1dd";
}

.ri-table-line:before {
  content: "\f1de";
}

.ri-tablet-fill:before {
  content: "\f1df";
}

.ri-tablet-line:before {
  content: "\f1e0";
}

.ri-takeaway-fill:before {
  content: "\f1e1";
}

.ri-takeaway-line:before {
  content: "\f1e2";
}

.ri-taobao-fill:before {
  content: "\f1e3";
}

.ri-taobao-line:before {
  content: "\f1e4";
}

.ri-tape-fill:before {
  content: "\f1e5";
}

.ri-tape-line:before {
  content: "\f1e6";
}

.ri-task-fill:before {
  content: "\f1e7";
}

.ri-task-line:before {
  content: "\f1e8";
}

.ri-taxi-fill:before {
  content: "\f1e9";
}

.ri-taxi-line:before {
  content: "\f1ea";
}

.ri-taxi-wifi-fill:before {
  content: "\f1eb";
}

.ri-taxi-wifi-line:before {
  content: "\f1ec";
}

.ri-team-fill:before {
  content: "\f1ed";
}

.ri-team-line:before {
  content: "\f1ee";
}

.ri-telegram-fill:before {
  content: "\f1ef";
}

.ri-telegram-line:before {
  content: "\f1f0";
}

.ri-temp-cold-fill:before {
  content: "\f1f1";
}

.ri-temp-cold-line:before {
  content: "\f1f2";
}

.ri-temp-hot-fill:before {
  content: "\f1f3";
}

.ri-temp-hot-line:before {
  content: "\f1f4";
}

.ri-terminal-box-fill:before {
  content: "\f1f5";
}

.ri-terminal-box-line:before {
  content: "\f1f6";
}

.ri-terminal-fill:before {
  content: "\f1f7";
}

.ri-terminal-line:before {
  content: "\f1f8";
}

.ri-terminal-window-fill:before {
  content: "\f1f9";
}

.ri-terminal-window-line:before {
  content: "\f1fa";
}

.ri-test-tube-fill:before {
  content: "\f1fb";
}

.ri-test-tube-line:before {
  content: "\f1fc";
}

.ri-text-direction-l:before {
  content: "\f1fd";
}

.ri-text-direction-r:before {
  content: "\f1fe";
}

.ri-text-spacing:before {
  content: "\f1ff";
}

.ri-text-wrap:before {
  content: "\f200";
}

.ri-text:before {
  content: "\f201";
}

.ri-thermometer-fill:before {
  content: "\f202";
}

.ri-thermometer-line:before {
  content: "\f203";
}

.ri-thumb-down-fill:before {
  content: "\f204";
}

.ri-thumb-down-line:before {
  content: "\f205";
}

.ri-thumb-up-fill:before {
  content: "\f206";
}

.ri-thumb-up-line:before {
  content: "\f207";
}

.ri-thunderstorms-fill:before {
  content: "\f208";
}

.ri-thunderstorms-line:before {
  content: "\f209";
}

.ri-ticket-2-fill:before {
  content: "\f20a";
}

.ri-ticket-2-line:before {
  content: "\f20b";
}

.ri-ticket-fill:before {
  content: "\f20c";
}

.ri-ticket-line:before {
  content: "\f20d";
}

.ri-time-fill:before {
  content: "\f20e";
}

.ri-time-line:before {
  content: "\f20f";
}

.ri-timer-2-fill:before {
  content: "\f210";
}

.ri-timer-2-line:before {
  content: "\f211";
}

.ri-timer-fill:before {
  content: "\f212";
}

.ri-timer-flash-fill:before {
  content: "\f213";
}

.ri-timer-flash-line:before {
  content: "\f214";
}

.ri-timer-line:before {
  content: "\f215";
}

.ri-todo-fill:before {
  content: "\f216";
}

.ri-todo-line:before {
  content: "\f217";
}

.ri-toggle-fill:before {
  content: "\f218";
}

.ri-toggle-line:before {
  content: "\f219";
}

.ri-tools-fill:before {
  content: "\f21a";
}

.ri-tools-line:before {
  content: "\f21b";
}

.ri-tornado-fill:before {
  content: "\f21c";
}

.ri-tornado-line:before {
  content: "\f21d";
}

.ri-trademark-fill:before {
  content: "\f21e";
}

.ri-trademark-line:before {
  content: "\f21f";
}

.ri-traffic-light-fill:before {
  content: "\f220";
}

.ri-traffic-light-line:before {
  content: "\f221";
}

.ri-train-fill:before {
  content: "\f222";
}

.ri-train-line:before {
  content: "\f223";
}

.ri-train-wifi-fill:before {
  content: "\f224";
}

.ri-train-wifi-line:before {
  content: "\f225";
}

.ri-translate-2:before {
  content: "\f226";
}

.ri-translate:before {
  content: "\f227";
}

.ri-travesti-fill:before {
  content: "\f228";
}

.ri-travesti-line:before {
  content: "\f229";
}

.ri-treasure-map-fill:before {
  content: "\f22a";
}

.ri-treasure-map-line:before {
  content: "\f22b";
}

.ri-trello-fill:before {
  content: "\f22c";
}

.ri-trello-line:before {
  content: "\f22d";
}

.ri-trophy-fill:before {
  content: "\f22e";
}

.ri-trophy-line:before {
  content: "\f22f";
}

.ri-truck-fill:before {
  content: "\f230";
}

.ri-truck-line:before {
  content: "\f231";
}

.ri-tumblr-fill:before {
  content: "\f232";
}

.ri-tumblr-line:before {
  content: "\f233";
}

.ri-tv-2-fill:before {
  content: "\f234";
}

.ri-tv-2-line:before {
  content: "\f235";
}

.ri-tv-fill:before {
  content: "\f236";
}

.ri-tv-line:before {
  content: "\f237";
}

.ri-twitch-fill:before {
  content: "\f238";
}

.ri-twitch-line:before {
  content: "\f239";
}

.ri-twitter-fill:before {
  content: "\f23a";
}

.ri-twitter-line:before {
  content: "\f23b";
}

.ri-typhoon-fill:before {
  content: "\f23c";
}

.ri-typhoon-line:before {
  content: "\f23d";
}

.ri-u-disk-fill:before {
  content: "\f23e";
}

.ri-u-disk-line:before {
  content: "\f23f";
}

.ri-ubuntu-fill:before {
  content: "\f240";
}

.ri-ubuntu-line:before {
  content: "\f241";
}

.ri-umbrella-fill:before {
  content: "\f242";
}

.ri-umbrella-line:before {
  content: "\f243";
}

.ri-underline:before {
  content: "\f244";
}

.ri-uninstall-fill:before {
  content: "\f245";
}

.ri-uninstall-line:before {
  content: "\f246";
}

.ri-unsplash-fill:before {
  content: "\f247";
}

.ri-unsplash-line:before {
  content: "\f248";
}

.ri-upload-2-fill:before {
  content: "\f249";
}

.ri-upload-2-line:before {
  content: "\f24a";
}

.ri-upload-cloud-2-fill:before {
  content: "\f24b";
}

.ri-upload-cloud-2-line:before {
  content: "\f24c";
}

.ri-upload-cloud-fill:before {
  content: "\f24d";
}

.ri-upload-cloud-line:before {
  content: "\f24e";
}

.ri-upload-fill:before {
  content: "\f24f";
}

.ri-upload-line:before {
  content: "\f250";
}

.ri-usb-fill:before {
  content: "\f251";
}

.ri-usb-line:before {
  content: "\f252";
}

.ri-user-2-fill:before {
  content: "\f253";
}

.ri-user-2-line:before {
  content: "\f254";
}

.ri-user-3-fill:before {
  content: "\f255";
}

.ri-user-3-line:before {
  content: "\f256";
}

.ri-user-4-fill:before {
  content: "\f257";
}

.ri-user-4-line:before {
  content: "\f258";
}

.ri-user-5-fill:before {
  content: "\f259";
}

.ri-user-5-line:before {
  content: "\f25a";
}

.ri-user-6-fill:before {
  content: "\f25b";
}

.ri-user-6-line:before {
  content: "\f25c";
}

.ri-user-add-fill:before {
  content: "\f25d";
}

.ri-user-add-line:before {
  content: "\f25e";
}

.ri-user-fill:before {
  content: "\f25f";
}

.ri-user-follow-fill:before {
  content: "\f260";
}

.ri-user-follow-line:before {
  content: "\f261";
}

.ri-user-heart-fill:before {
  content: "\f262";
}

.ri-user-heart-line:before {
  content: "\f263";
}

.ri-user-line:before {
  content: "\f264";
}

.ri-user-location-fill:before {
  content: "\f265";
}

.ri-user-location-line:before {
  content: "\f266";
}

.ri-user-received-2-fill:before {
  content: "\f267";
}

.ri-user-received-2-line:before {
  content: "\f268";
}

.ri-user-received-fill:before {
  content: "\f269";
}

.ri-user-received-line:before {
  content: "\f26a";
}

.ri-user-search-fill:before {
  content: "\f26b";
}

.ri-user-search-line:before {
  content: "\f26c";
}

.ri-user-settings-fill:before {
  content: "\f26d";
}

.ri-user-settings-line:before {
  content: "\f26e";
}

.ri-user-shared-2-fill:before {
  content: "\f26f";
}

.ri-user-shared-2-line:before {
  content: "\f270";
}

.ri-user-shared-fill:before {
  content: "\f271";
}

.ri-user-shared-line:before {
  content: "\f272";
}

.ri-user-smile-fill:before {
  content: "\f273";
}

.ri-user-smile-line:before {
  content: "\f274";
}

.ri-user-star-fill:before {
  content: "\f275";
}

.ri-user-star-line:before {
  content: "\f276";
}

.ri-user-unfollow-fill:before {
  content: "\f277";
}

.ri-user-unfollow-line:before {
  content: "\f278";
}

.ri-user-voice-fill:before {
  content: "\f279";
}

.ri-user-voice-line:before {
  content: "\f27a";
}

.ri-video-add-fill:before {
  content: "\f27b";
}

.ri-video-add-line:before {
  content: "\f27c";
}

.ri-video-chat-fill:before {
  content: "\f27d";
}

.ri-video-chat-line:before {
  content: "\f27e";
}

.ri-video-download-fill:before {
  content: "\f27f";
}

.ri-video-download-line:before {
  content: "\f280";
}

.ri-video-fill:before {
  content: "\f281";
}

.ri-video-line:before {
  content: "\f282";
}

.ri-video-upload-fill:before {
  content: "\f283";
}

.ri-video-upload-line:before {
  content: "\f284";
}

.ri-vidicon-2-fill:before {
  content: "\f285";
}

.ri-vidicon-2-line:before {
  content: "\f286";
}

.ri-vidicon-fill:before {
  content: "\f287";
}

.ri-vidicon-line:before {
  content: "\f288";
}

.ri-vimeo-fill:before {
  content: "\f289";
}

.ri-vimeo-line:before {
  content: "\f28a";
}

.ri-vip-crown-2-fill:before {
  content: "\f28b";
}

.ri-vip-crown-2-line:before {
  content: "\f28c";
}

.ri-vip-crown-fill:before {
  content: "\f28d";
}

.ri-vip-crown-line:before {
  content: "\f28e";
}

.ri-vip-diamond-fill:before {
  content: "\f28f";
}

.ri-vip-diamond-line:before {
  content: "\f290";
}

.ri-vip-fill:before {
  content: "\f291";
}

.ri-vip-line:before {
  content: "\f292";
}

.ri-virus-fill:before {
  content: "\f293";
}

.ri-virus-line:before {
  content: "\f294";
}

.ri-visa-fill:before {
  content: "\f295";
}

.ri-visa-line:before {
  content: "\f296";
}

.ri-voice-recognition-fill:before {
  content: "\f297";
}

.ri-voice-recognition-line:before {
  content: "\f298";
}

.ri-voiceprint-fill:before {
  content: "\f299";
}

.ri-voiceprint-line:before {
  content: "\f29a";
}

.ri-volume-down-fill:before {
  content: "\f29b";
}

.ri-volume-down-line:before {
  content: "\f29c";
}

.ri-volume-mute-fill:before {
  content: "\f29d";
}

.ri-volume-mute-line:before {
  content: "\f29e";
}

.ri-volume-off-vibrate-fill:before {
  content: "\f29f";
}

.ri-volume-off-vibrate-line:before {
  content: "\f2a0";
}

.ri-volume-up-fill:before {
  content: "\f2a1";
}

.ri-volume-up-line:before {
  content: "\f2a2";
}

.ri-volume-vibrate-fill:before {
  content: "\f2a3";
}

.ri-volume-vibrate-line:before {
  content: "\f2a4";
}

.ri-vuejs-fill:before {
  content: "\f2a5";
}

.ri-vuejs-line:before {
  content: "\f2a6";
}

.ri-walk-fill:before {
  content: "\f2a7";
}

.ri-walk-line:before {
  content: "\f2a8";
}

.ri-wallet-2-fill:before {
  content: "\f2a9";
}

.ri-wallet-2-line:before {
  content: "\f2aa";
}

.ri-wallet-3-fill:before {
  content: "\f2ab";
}

.ri-wallet-3-line:before {
  content: "\f2ac";
}

.ri-wallet-fill:before {
  content: "\f2ad";
}

.ri-wallet-line:before {
  content: "\f2ae";
}

.ri-water-flash-fill:before {
  content: "\f2af";
}

.ri-water-flash-line:before {
  content: "\f2b0";
}

.ri-webcam-fill:before {
  content: "\f2b1";
}

.ri-webcam-line:before {
  content: "\f2b2";
}

.ri-wechat-2-fill:before {
  content: "\f2b3";
}

.ri-wechat-2-line:before {
  content: "\f2b4";
}

.ri-wechat-fill:before {
  content: "\f2b5";
}

.ri-wechat-line:before {
  content: "\f2b6";
}

.ri-wechat-pay-fill:before {
  content: "\f2b7";
}

.ri-wechat-pay-line:before {
  content: "\f2b8";
}

.ri-weibo-fill:before {
  content: "\f2b9";
}

.ri-weibo-line:before {
  content: "\f2ba";
}

.ri-whatsapp-fill:before {
  content: "\f2bb";
}

.ri-whatsapp-line:before {
  content: "\f2bc";
}

.ri-wheelchair-fill:before {
  content: "\f2bd";
}

.ri-wheelchair-line:before {
  content: "\f2be";
}

.ri-wifi-fill:before {
  content: "\f2bf";
}

.ri-wifi-line:before {
  content: "\f2c0";
}

.ri-wifi-off-fill:before {
  content: "\f2c1";
}

.ri-wifi-off-line:before {
  content: "\f2c2";
}

.ri-window-2-fill:before {
  content: "\f2c3";
}

.ri-window-2-line:before {
  content: "\f2c4";
}

.ri-window-fill:before {
  content: "\f2c5";
}

.ri-window-line:before {
  content: "\f2c6";
}

.ri-windows-fill:before {
  content: "\f2c7";
}

.ri-windows-line:before {
  content: "\f2c8";
}

.ri-windy-fill:before {
  content: "\f2c9";
}

.ri-windy-line:before {
  content: "\f2ca";
}

.ri-wireless-charging-fill:before {
  content: "\f2cb";
}

.ri-wireless-charging-line:before {
  content: "\f2cc";
}

.ri-women-fill:before {
  content: "\f2cd";
}

.ri-women-line:before {
  content: "\f2ce";
}

.ri-wubi-input:before {
  content: "\f2cf";
}

.ri-xbox-fill:before {
  content: "\f2d0";
}

.ri-xbox-line:before {
  content: "\f2d1";
}

.ri-xing-fill:before {
  content: "\f2d2";
}

.ri-xing-line:before {
  content: "\f2d3";
}

.ri-youtube-fill:before {
  content: "\f2d4";
}

.ri-youtube-line:before {
  content: "\f2d5";
}

.ri-zcool-fill:before {
  content: "\f2d6";
}

.ri-zcool-line:before {
  content: "\f2d7";
}

.ri-zhihu-fill:before {
  content: "\f2d8";
}

.ri-zhihu-line:before {
  content: "\f2d9";
}

.ri-zoom-in-fill:before {
  content: "\f2da";
}

.ri-zoom-in-line:before {
  content: "\f2db";
}

.ri-zoom-out-fill:before {
  content: "\f2dc";
}

.ri-zoom-out-line:before {
  content: "\f2dd";
}

.ri-zzz-fill:before {
  content: "\f2de";
}

.ri-zzz-line:before {
  content: "\f2df";
}

/* MaterialDesignIcons.com */
@font-face {
  font-family: "Material Design Icons";
  src: url("../../../fonts/materialdesignicons-webfont.eot?v=6.5.95");
  src: url("../../../fonts/materialdesignicons-webfont.eot?#iefix&v=6.5.95") format("embedded-opentype"), url("../../../fonts/materialdesignicons-webfont.woff2?v=6.5.95") format("woff2"), url("../../../fonts/materialdesignicons-webfont.woff?v=6.5.95") format("woff"), url("../../../fonts/materialdesignicons-webfont.ttf?v=6.5.95") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.mdi:before,
.mdi-set {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mdi-ab-testing::before {
  content: "\f01c9";
}

.mdi-abacus::before {
  content: "\f16e0";
}

.mdi-abjad-arabic::before {
  content: "\f1328";
}

.mdi-abjad-hebrew::before {
  content: "\f1329";
}

.mdi-abugida-devanagari::before {
  content: "\f132a";
}

.mdi-abugida-thai::before {
  content: "\f132b";
}

.mdi-access-point::before {
  content: "\f0003";
}

.mdi-access-point-check::before {
  content: "\f1538";
}

.mdi-access-point-minus::before {
  content: "\f1539";
}

.mdi-access-point-network::before {
  content: "\f0002";
}

.mdi-access-point-network-off::before {
  content: "\f0be1";
}

.mdi-access-point-off::before {
  content: "\f1511";
}

.mdi-access-point-plus::before {
  content: "\f153a";
}

.mdi-access-point-remove::before {
  content: "\f153b";
}

.mdi-account::before {
  content: "\f0004";
}

.mdi-account-alert::before {
  content: "\f0005";
}

.mdi-account-alert-outline::before {
  content: "\f0b50";
}

.mdi-account-arrow-down::before {
  content: "\f1868";
}

.mdi-account-arrow-down-outline::before {
  content: "\f1869";
}

.mdi-account-arrow-left::before {
  content: "\f0b51";
}

.mdi-account-arrow-left-outline::before {
  content: "\f0b52";
}

.mdi-account-arrow-right::before {
  content: "\f0b53";
}

.mdi-account-arrow-right-outline::before {
  content: "\f0b54";
}

.mdi-account-arrow-up::before {
  content: "\f1867";
}

.mdi-account-arrow-up-outline::before {
  content: "\f186a";
}

.mdi-account-box::before {
  content: "\f0006";
}

.mdi-account-box-multiple::before {
  content: "\f0934";
}

.mdi-account-box-multiple-outline::before {
  content: "\f100a";
}

.mdi-account-box-outline::before {
  content: "\f0007";
}

.mdi-account-cancel::before {
  content: "\f12df";
}

.mdi-account-cancel-outline::before {
  content: "\f12e0";
}

.mdi-account-cash::before {
  content: "\f1097";
}

.mdi-account-cash-outline::before {
  content: "\f1098";
}

.mdi-account-check::before {
  content: "\f0008";
}

.mdi-account-check-outline::before {
  content: "\f0be2";
}

.mdi-account-child::before {
  content: "\f0a89";
}

.mdi-account-child-circle::before {
  content: "\f0a8a";
}

.mdi-account-child-outline::before {
  content: "\f10c8";
}

.mdi-account-circle::before {
  content: "\f0009";
}

.mdi-account-circle-outline::before {
  content: "\f0b55";
}

.mdi-account-clock::before {
  content: "\f0b56";
}

.mdi-account-clock-outline::before {
  content: "\f0b57";
}

.mdi-account-cog::before {
  content: "\f1370";
}

.mdi-account-cog-outline::before {
  content: "\f1371";
}

.mdi-account-convert::before {
  content: "\f000a";
}

.mdi-account-convert-outline::before {
  content: "\f1301";
}

.mdi-account-cowboy-hat::before {
  content: "\f0e9b";
}

.mdi-account-cowboy-hat-outline::before {
  content: "\f17f3";
}

.mdi-account-details::before {
  content: "\f0631";
}

.mdi-account-details-outline::before {
  content: "\f1372";
}

.mdi-account-edit::before {
  content: "\f06bc";
}

.mdi-account-edit-outline::before {
  content: "\f0ffb";
}

.mdi-account-eye::before {
  content: "\f0420";
}

.mdi-account-eye-outline::before {
  content: "\f127b";
}

.mdi-account-filter::before {
  content: "\f0936";
}

.mdi-account-filter-outline::before {
  content: "\f0f9d";
}

.mdi-account-group::before {
  content: "\f0849";
}

.mdi-account-group-outline::before {
  content: "\f0b58";
}

.mdi-account-hard-hat::before {
  content: "\f05b5";
}

.mdi-account-heart::before {
  content: "\f0899";
}

.mdi-account-heart-outline::before {
  content: "\f0be3";
}

.mdi-account-injury::before {
  content: "\f1815";
}

.mdi-account-injury-outline::before {
  content: "\f1816";
}

.mdi-account-key::before {
  content: "\f000b";
}

.mdi-account-key-outline::before {
  content: "\f0be4";
}

.mdi-account-lock::before {
  content: "\f115e";
}

.mdi-account-lock-open::before {
  content: "\f1960";
}

.mdi-account-lock-open-outline::before {
  content: "\f1961";
}

.mdi-account-lock-outline::before {
  content: "\f115f";
}

.mdi-account-minus::before {
  content: "\f000d";
}

.mdi-account-minus-outline::before {
  content: "\f0aec";
}

.mdi-account-multiple::before {
  content: "\f000e";
}

.mdi-account-multiple-check::before {
  content: "\f08c5";
}

.mdi-account-multiple-check-outline::before {
  content: "\f11fe";
}

.mdi-account-multiple-minus::before {
  content: "\f05d3";
}

.mdi-account-multiple-minus-outline::before {
  content: "\f0be5";
}

.mdi-account-multiple-outline::before {
  content: "\f000f";
}

.mdi-account-multiple-plus::before {
  content: "\f0010";
}

.mdi-account-multiple-plus-outline::before {
  content: "\f0800";
}

.mdi-account-multiple-remove::before {
  content: "\f120a";
}

.mdi-account-multiple-remove-outline::before {
  content: "\f120b";
}

.mdi-account-music::before {
  content: "\f0803";
}

.mdi-account-music-outline::before {
  content: "\f0ce9";
}

.mdi-account-network::before {
  content: "\f0011";
}

.mdi-account-network-outline::before {
  content: "\f0be6";
}

.mdi-account-off::before {
  content: "\f0012";
}

.mdi-account-off-outline::before {
  content: "\f0be7";
}

.mdi-account-outline::before {
  content: "\f0013";
}

.mdi-account-plus::before {
  content: "\f0014";
}

.mdi-account-plus-outline::before {
  content: "\f0801";
}

.mdi-account-question::before {
  content: "\f0b59";
}

.mdi-account-question-outline::before {
  content: "\f0b5a";
}

.mdi-account-reactivate::before {
  content: "\f152b";
}

.mdi-account-reactivate-outline::before {
  content: "\f152c";
}

.mdi-account-remove::before {
  content: "\f0015";
}

.mdi-account-remove-outline::before {
  content: "\f0aed";
}

.mdi-account-search::before {
  content: "\f0016";
}

.mdi-account-search-outline::before {
  content: "\f0935";
}

.mdi-account-settings::before {
  content: "\f0630";
}

.mdi-account-settings-outline::before {
  content: "\f10c9";
}

.mdi-account-star::before {
  content: "\f0017";
}

.mdi-account-star-outline::before {
  content: "\f0be8";
}

.mdi-account-supervisor::before {
  content: "\f0a8b";
}

.mdi-account-supervisor-circle::before {
  content: "\f0a8c";
}

.mdi-account-supervisor-circle-outline::before {
  content: "\f14ec";
}

.mdi-account-supervisor-outline::before {
  content: "\f112d";
}

.mdi-account-switch::before {
  content: "\f0019";
}

.mdi-account-switch-outline::before {
  content: "\f04cb";
}

.mdi-account-sync::before {
  content: "\f191b";
}

.mdi-account-sync-outline::before {
  content: "\f191c";
}

.mdi-account-tie::before {
  content: "\f0ce3";
}

.mdi-account-tie-hat::before {
  content: "\f1898";
}

.mdi-account-tie-hat-outline::before {
  content: "\f1899";
}

.mdi-account-tie-outline::before {
  content: "\f10ca";
}

.mdi-account-tie-voice::before {
  content: "\f1308";
}

.mdi-account-tie-voice-off::before {
  content: "\f130a";
}

.mdi-account-tie-voice-off-outline::before {
  content: "\f130b";
}

.mdi-account-tie-voice-outline::before {
  content: "\f1309";
}

.mdi-account-voice::before {
  content: "\f05cb";
}

.mdi-account-voice-off::before {
  content: "\f0ed4";
}

.mdi-account-wrench::before {
  content: "\f189a";
}

.mdi-account-wrench-outline::before {
  content: "\f189b";
}

.mdi-adjust::before {
  content: "\f001a";
}

.mdi-advertisements::before {
  content: "\f192a";
}

.mdi-advertisements-off::before {
  content: "\f192b";
}

.mdi-air-conditioner::before {
  content: "\f001b";
}

.mdi-air-filter::before {
  content: "\f0d43";
}

.mdi-air-horn::before {
  content: "\f0dac";
}

.mdi-air-humidifier::before {
  content: "\f1099";
}

.mdi-air-humidifier-off::before {
  content: "\f1466";
}

.mdi-air-purifier::before {
  content: "\f0d44";
}

.mdi-airbag::before {
  content: "\f0be9";
}

.mdi-airballoon::before {
  content: "\f001c";
}

.mdi-airballoon-outline::before {
  content: "\f100b";
}

.mdi-airplane::before {
  content: "\f001d";
}

.mdi-airplane-alert::before {
  content: "\f187a";
}

.mdi-airplane-check::before {
  content: "\f187b";
}

.mdi-airplane-clock::before {
  content: "\f187c";
}

.mdi-airplane-cog::before {
  content: "\f187d";
}

.mdi-airplane-edit::before {
  content: "\f187e";
}

.mdi-airplane-landing::before {
  content: "\f05d4";
}

.mdi-airplane-marker::before {
  content: "\f187f";
}

.mdi-airplane-minus::before {
  content: "\f1880";
}

.mdi-airplane-off::before {
  content: "\f001e";
}

.mdi-airplane-plus::before {
  content: "\f1881";
}

.mdi-airplane-remove::before {
  content: "\f1882";
}

.mdi-airplane-search::before {
  content: "\f1883";
}

.mdi-airplane-settings::before {
  content: "\f1884";
}

.mdi-airplane-takeoff::before {
  content: "\f05d5";
}

.mdi-airport::before {
  content: "\f084b";
}

.mdi-alarm::before {
  content: "\f0020";
}

.mdi-alarm-bell::before {
  content: "\f078e";
}

.mdi-alarm-check::before {
  content: "\f0021";
}

.mdi-alarm-light::before {
  content: "\f078f";
}

.mdi-alarm-light-off::before {
  content: "\f171e";
}

.mdi-alarm-light-off-outline::before {
  content: "\f171f";
}

.mdi-alarm-light-outline::before {
  content: "\f0bea";
}

.mdi-alarm-multiple::before {
  content: "\f0022";
}

.mdi-alarm-note::before {
  content: "\f0e71";
}

.mdi-alarm-note-off::before {
  content: "\f0e72";
}

.mdi-alarm-off::before {
  content: "\f0023";
}

.mdi-alarm-panel::before {
  content: "\f15c4";
}

.mdi-alarm-panel-outline::before {
  content: "\f15c5";
}

.mdi-alarm-plus::before {
  content: "\f0024";
}

.mdi-alarm-snooze::before {
  content: "\f068e";
}

.mdi-album::before {
  content: "\f0025";
}

.mdi-alert::before {
  content: "\f0026";
}

.mdi-alert-box::before {
  content: "\f0027";
}

.mdi-alert-box-outline::before {
  content: "\f0ce4";
}

.mdi-alert-circle::before {
  content: "\f0028";
}

.mdi-alert-circle-check::before {
  content: "\f11ed";
}

.mdi-alert-circle-check-outline::before {
  content: "\f11ee";
}

.mdi-alert-circle-outline::before {
  content: "\f05d6";
}

.mdi-alert-decagram::before {
  content: "\f06bd";
}

.mdi-alert-decagram-outline::before {
  content: "\f0ce5";
}

.mdi-alert-minus::before {
  content: "\f14bb";
}

.mdi-alert-minus-outline::before {
  content: "\f14be";
}

.mdi-alert-octagon::before {
  content: "\f0029";
}

.mdi-alert-octagon-outline::before {
  content: "\f0ce6";
}

.mdi-alert-octagram::before {
  content: "\f0767";
}

.mdi-alert-octagram-outline::before {
  content: "\f0ce7";
}

.mdi-alert-outline::before {
  content: "\f002a";
}

.mdi-alert-plus::before {
  content: "\f14ba";
}

.mdi-alert-plus-outline::before {
  content: "\f14bd";
}

.mdi-alert-remove::before {
  content: "\f14bc";
}

.mdi-alert-remove-outline::before {
  content: "\f14bf";
}

.mdi-alert-rhombus::before {
  content: "\f11ce";
}

.mdi-alert-rhombus-outline::before {
  content: "\f11cf";
}

.mdi-alien::before {
  content: "\f089a";
}

.mdi-alien-outline::before {
  content: "\f10cb";
}

.mdi-align-horizontal-center::before {
  content: "\f11c3";
}

.mdi-align-horizontal-distribute::before {
  content: "\f1962";
}

.mdi-align-horizontal-left::before {
  content: "\f11c2";
}

.mdi-align-horizontal-right::before {
  content: "\f11c4";
}

.mdi-align-vertical-bottom::before {
  content: "\f11c5";
}

.mdi-align-vertical-center::before {
  content: "\f11c6";
}

.mdi-align-vertical-distribute::before {
  content: "\f1963";
}

.mdi-align-vertical-top::before {
  content: "\f11c7";
}

.mdi-all-inclusive::before {
  content: "\f06be";
}

.mdi-all-inclusive-box::before {
  content: "\f188d";
}

.mdi-all-inclusive-box-outline::before {
  content: "\f188e";
}

.mdi-allergy::before {
  content: "\f1258";
}

.mdi-alpha::before {
  content: "\f002b";
}

.mdi-alpha-a::before {
  content: "\f0aee";
}

.mdi-alpha-a-box::before {
  content: "\f0b08";
}

.mdi-alpha-a-box-outline::before {
  content: "\f0beb";
}

.mdi-alpha-a-circle::before {
  content: "\f0bec";
}

.mdi-alpha-a-circle-outline::before {
  content: "\f0bed";
}

.mdi-alpha-b::before {
  content: "\f0aef";
}

.mdi-alpha-b-box::before {
  content: "\f0b09";
}

.mdi-alpha-b-box-outline::before {
  content: "\f0bee";
}

.mdi-alpha-b-circle::before {
  content: "\f0bef";
}

.mdi-alpha-b-circle-outline::before {
  content: "\f0bf0";
}

.mdi-alpha-c::before {
  content: "\f0af0";
}

.mdi-alpha-c-box::before {
  content: "\f0b0a";
}

.mdi-alpha-c-box-outline::before {
  content: "\f0bf1";
}

.mdi-alpha-c-circle::before {
  content: "\f0bf2";
}

.mdi-alpha-c-circle-outline::before {
  content: "\f0bf3";
}

.mdi-alpha-d::before {
  content: "\f0af1";
}

.mdi-alpha-d-box::before {
  content: "\f0b0b";
}

.mdi-alpha-d-box-outline::before {
  content: "\f0bf4";
}

.mdi-alpha-d-circle::before {
  content: "\f0bf5";
}

.mdi-alpha-d-circle-outline::before {
  content: "\f0bf6";
}

.mdi-alpha-e::before {
  content: "\f0af2";
}

.mdi-alpha-e-box::before {
  content: "\f0b0c";
}

.mdi-alpha-e-box-outline::before {
  content: "\f0bf7";
}

.mdi-alpha-e-circle::before {
  content: "\f0bf8";
}

.mdi-alpha-e-circle-outline::before {
  content: "\f0bf9";
}

.mdi-alpha-f::before {
  content: "\f0af3";
}

.mdi-alpha-f-box::before {
  content: "\f0b0d";
}

.mdi-alpha-f-box-outline::before {
  content: "\f0bfa";
}

.mdi-alpha-f-circle::before {
  content: "\f0bfb";
}

.mdi-alpha-f-circle-outline::before {
  content: "\f0bfc";
}

.mdi-alpha-g::before {
  content: "\f0af4";
}

.mdi-alpha-g-box::before {
  content: "\f0b0e";
}

.mdi-alpha-g-box-outline::before {
  content: "\f0bfd";
}

.mdi-alpha-g-circle::before {
  content: "\f0bfe";
}

.mdi-alpha-g-circle-outline::before {
  content: "\f0bff";
}

.mdi-alpha-h::before {
  content: "\f0af5";
}

.mdi-alpha-h-box::before {
  content: "\f0b0f";
}

.mdi-alpha-h-box-outline::before {
  content: "\f0c00";
}

.mdi-alpha-h-circle::before {
  content: "\f0c01";
}

.mdi-alpha-h-circle-outline::before {
  content: "\f0c02";
}

.mdi-alpha-i::before {
  content: "\f0af6";
}

.mdi-alpha-i-box::before {
  content: "\f0b10";
}

.mdi-alpha-i-box-outline::before {
  content: "\f0c03";
}

.mdi-alpha-i-circle::before {
  content: "\f0c04";
}

.mdi-alpha-i-circle-outline::before {
  content: "\f0c05";
}

.mdi-alpha-j::before {
  content: "\f0af7";
}

.mdi-alpha-j-box::before {
  content: "\f0b11";
}

.mdi-alpha-j-box-outline::before {
  content: "\f0c06";
}

.mdi-alpha-j-circle::before {
  content: "\f0c07";
}

.mdi-alpha-j-circle-outline::before {
  content: "\f0c08";
}

.mdi-alpha-k::before {
  content: "\f0af8";
}

.mdi-alpha-k-box::before {
  content: "\f0b12";
}

.mdi-alpha-k-box-outline::before {
  content: "\f0c09";
}

.mdi-alpha-k-circle::before {
  content: "\f0c0a";
}

.mdi-alpha-k-circle-outline::before {
  content: "\f0c0b";
}

.mdi-alpha-l::before {
  content: "\f0af9";
}

.mdi-alpha-l-box::before {
  content: "\f0b13";
}

.mdi-alpha-l-box-outline::before {
  content: "\f0c0c";
}

.mdi-alpha-l-circle::before {
  content: "\f0c0d";
}

.mdi-alpha-l-circle-outline::before {
  content: "\f0c0e";
}

.mdi-alpha-m::before {
  content: "\f0afa";
}

.mdi-alpha-m-box::before {
  content: "\f0b14";
}

.mdi-alpha-m-box-outline::before {
  content: "\f0c0f";
}

.mdi-alpha-m-circle::before {
  content: "\f0c10";
}

.mdi-alpha-m-circle-outline::before {
  content: "\f0c11";
}

.mdi-alpha-n::before {
  content: "\f0afb";
}

.mdi-alpha-n-box::before {
  content: "\f0b15";
}

.mdi-alpha-n-box-outline::before {
  content: "\f0c12";
}

.mdi-alpha-n-circle::before {
  content: "\f0c13";
}

.mdi-alpha-n-circle-outline::before {
  content: "\f0c14";
}

.mdi-alpha-o::before {
  content: "\f0afc";
}

.mdi-alpha-o-box::before {
  content: "\f0b16";
}

.mdi-alpha-o-box-outline::before {
  content: "\f0c15";
}

.mdi-alpha-o-circle::before {
  content: "\f0c16";
}

.mdi-alpha-o-circle-outline::before {
  content: "\f0c17";
}

.mdi-alpha-p::before {
  content: "\f0afd";
}

.mdi-alpha-p-box::before {
  content: "\f0b17";
}

.mdi-alpha-p-box-outline::before {
  content: "\f0c18";
}

.mdi-alpha-p-circle::before {
  content: "\f0c19";
}

.mdi-alpha-p-circle-outline::before {
  content: "\f0c1a";
}

.mdi-alpha-q::before {
  content: "\f0afe";
}

.mdi-alpha-q-box::before {
  content: "\f0b18";
}

.mdi-alpha-q-box-outline::before {
  content: "\f0c1b";
}

.mdi-alpha-q-circle::before {
  content: "\f0c1c";
}

.mdi-alpha-q-circle-outline::before {
  content: "\f0c1d";
}

.mdi-alpha-r::before {
  content: "\f0aff";
}

.mdi-alpha-r-box::before {
  content: "\f0b19";
}

.mdi-alpha-r-box-outline::before {
  content: "\f0c1e";
}

.mdi-alpha-r-circle::before {
  content: "\f0c1f";
}

.mdi-alpha-r-circle-outline::before {
  content: "\f0c20";
}

.mdi-alpha-s::before {
  content: "\f0b00";
}

.mdi-alpha-s-box::before {
  content: "\f0b1a";
}

.mdi-alpha-s-box-outline::before {
  content: "\f0c21";
}

.mdi-alpha-s-circle::before {
  content: "\f0c22";
}

.mdi-alpha-s-circle-outline::before {
  content: "\f0c23";
}

.mdi-alpha-t::before {
  content: "\f0b01";
}

.mdi-alpha-t-box::before {
  content: "\f0b1b";
}

.mdi-alpha-t-box-outline::before {
  content: "\f0c24";
}

.mdi-alpha-t-circle::before {
  content: "\f0c25";
}

.mdi-alpha-t-circle-outline::before {
  content: "\f0c26";
}

.mdi-alpha-u::before {
  content: "\f0b02";
}

.mdi-alpha-u-box::before {
  content: "\f0b1c";
}

.mdi-alpha-u-box-outline::before {
  content: "\f0c27";
}

.mdi-alpha-u-circle::before {
  content: "\f0c28";
}

.mdi-alpha-u-circle-outline::before {
  content: "\f0c29";
}

.mdi-alpha-v::before {
  content: "\f0b03";
}

.mdi-alpha-v-box::before {
  content: "\f0b1d";
}

.mdi-alpha-v-box-outline::before {
  content: "\f0c2a";
}

.mdi-alpha-v-circle::before {
  content: "\f0c2b";
}

.mdi-alpha-v-circle-outline::before {
  content: "\f0c2c";
}

.mdi-alpha-w::before {
  content: "\f0b04";
}

.mdi-alpha-w-box::before {
  content: "\f0b1e";
}

.mdi-alpha-w-box-outline::before {
  content: "\f0c2d";
}

.mdi-alpha-w-circle::before {
  content: "\f0c2e";
}

.mdi-alpha-w-circle-outline::before {
  content: "\f0c2f";
}

.mdi-alpha-x::before {
  content: "\f0b05";
}

.mdi-alpha-x-box::before {
  content: "\f0b1f";
}

.mdi-alpha-x-box-outline::before {
  content: "\f0c30";
}

.mdi-alpha-x-circle::before {
  content: "\f0c31";
}

.mdi-alpha-x-circle-outline::before {
  content: "\f0c32";
}

.mdi-alpha-y::before {
  content: "\f0b06";
}

.mdi-alpha-y-box::before {
  content: "\f0b20";
}

.mdi-alpha-y-box-outline::before {
  content: "\f0c33";
}

.mdi-alpha-y-circle::before {
  content: "\f0c34";
}

.mdi-alpha-y-circle-outline::before {
  content: "\f0c35";
}

.mdi-alpha-z::before {
  content: "\f0b07";
}

.mdi-alpha-z-box::before {
  content: "\f0b21";
}

.mdi-alpha-z-box-outline::before {
  content: "\f0c36";
}

.mdi-alpha-z-circle::before {
  content: "\f0c37";
}

.mdi-alpha-z-circle-outline::before {
  content: "\f0c38";
}

.mdi-alphabet-aurebesh::before {
  content: "\f132c";
}

.mdi-alphabet-cyrillic::before {
  content: "\f132d";
}

.mdi-alphabet-greek::before {
  content: "\f132e";
}

.mdi-alphabet-latin::before {
  content: "\f132f";
}

.mdi-alphabet-piqad::before {
  content: "\f1330";
}

.mdi-alphabet-tengwar::before {
  content: "\f1337";
}

.mdi-alphabetical::before {
  content: "\f002c";
}

.mdi-alphabetical-off::before {
  content: "\f100c";
}

.mdi-alphabetical-variant::before {
  content: "\f100d";
}

.mdi-alphabetical-variant-off::before {
  content: "\f100e";
}

.mdi-altimeter::before {
  content: "\f05d7";
}

.mdi-ambulance::before {
  content: "\f002f";
}

.mdi-ammunition::before {
  content: "\f0ce8";
}

.mdi-ampersand::before {
  content: "\f0a8d";
}

.mdi-amplifier::before {
  content: "\f0030";
}

.mdi-amplifier-off::before {
  content: "\f11b5";
}

.mdi-anchor::before {
  content: "\f0031";
}

.mdi-android::before {
  content: "\f0032";
}

.mdi-android-messages::before {
  content: "\f0d45";
}

.mdi-android-studio::before {
  content: "\f0034";
}

.mdi-angle-acute::before {
  content: "\f0937";
}

.mdi-angle-obtuse::before {
  content: "\f0938";
}

.mdi-angle-right::before {
  content: "\f0939";
}

.mdi-angular::before {
  content: "\f06b2";
}

.mdi-angularjs::before {
  content: "\f06bf";
}

.mdi-animation::before {
  content: "\f05d8";
}

.mdi-animation-outline::before {
  content: "\f0a8f";
}

.mdi-animation-play::before {
  content: "\f093a";
}

.mdi-animation-play-outline::before {
  content: "\f0a90";
}

.mdi-ansible::before {
  content: "\f109a";
}

.mdi-antenna::before {
  content: "\f1119";
}

.mdi-anvil::before {
  content: "\f089b";
}

.mdi-apache-kafka::before {
  content: "\f100f";
}

.mdi-api::before {
  content: "\f109b";
}

.mdi-api-off::before {
  content: "\f1257";
}

.mdi-apple::before {
  content: "\f0035";
}

.mdi-apple-finder::before {
  content: "\f0036";
}

.mdi-apple-icloud::before {
  content: "\f0038";
}

.mdi-apple-ios::before {
  content: "\f0037";
}

.mdi-apple-keyboard-caps::before {
  content: "\f0632";
}

.mdi-apple-keyboard-command::before {
  content: "\f0633";
}

.mdi-apple-keyboard-control::before {
  content: "\f0634";
}

.mdi-apple-keyboard-option::before {
  content: "\f0635";
}

.mdi-apple-keyboard-shift::before {
  content: "\f0636";
}

.mdi-apple-safari::before {
  content: "\f0039";
}

.mdi-application::before {
  content: "\f08c6";
}

.mdi-application-array::before {
  content: "\f10f5";
}

.mdi-application-array-outline::before {
  content: "\f10f6";
}

.mdi-application-braces::before {
  content: "\f10f7";
}

.mdi-application-braces-outline::before {
  content: "\f10f8";
}

.mdi-application-brackets::before {
  content: "\f0c8b";
}

.mdi-application-brackets-outline::before {
  content: "\f0c8c";
}

.mdi-application-cog::before {
  content: "\f0675";
}

.mdi-application-cog-outline::before {
  content: "\f1577";
}

.mdi-application-edit::before {
  content: "\f00ae";
}

.mdi-application-edit-outline::before {
  content: "\f0619";
}

.mdi-application-export::before {
  content: "\f0dad";
}

.mdi-application-import::before {
  content: "\f0dae";
}

.mdi-application-outline::before {
  content: "\f0614";
}

.mdi-application-parentheses::before {
  content: "\f10f9";
}

.mdi-application-parentheses-outline::before {
  content: "\f10fa";
}

.mdi-application-settings::before {
  content: "\f0b60";
}

.mdi-application-settings-outline::before {
  content: "\f1555";
}

.mdi-application-variable::before {
  content: "\f10fb";
}

.mdi-application-variable-outline::before {
  content: "\f10fc";
}

.mdi-approximately-equal::before {
  content: "\f0f9e";
}

.mdi-approximately-equal-box::before {
  content: "\f0f9f";
}

.mdi-apps::before {
  content: "\f003b";
}

.mdi-apps-box::before {
  content: "\f0d46";
}

.mdi-arch::before {
  content: "\f08c7";
}

.mdi-archive::before {
  content: "\f003c";
}

.mdi-archive-alert::before {
  content: "\f14fd";
}

.mdi-archive-alert-outline::before {
  content: "\f14fe";
}

.mdi-archive-arrow-down::before {
  content: "\f1259";
}

.mdi-archive-arrow-down-outline::before {
  content: "\f125a";
}

.mdi-archive-arrow-up::before {
  content: "\f125b";
}

.mdi-archive-arrow-up-outline::before {
  content: "\f125c";
}

.mdi-archive-cancel::before {
  content: "\f174b";
}

.mdi-archive-cancel-outline::before {
  content: "\f174c";
}

.mdi-archive-check::before {
  content: "\f174d";
}

.mdi-archive-check-outline::before {
  content: "\f174e";
}

.mdi-archive-clock::before {
  content: "\f174f";
}

.mdi-archive-clock-outline::before {
  content: "\f1750";
}

.mdi-archive-cog::before {
  content: "\f1751";
}

.mdi-archive-cog-outline::before {
  content: "\f1752";
}

.mdi-archive-edit::before {
  content: "\f1753";
}

.mdi-archive-edit-outline::before {
  content: "\f1754";
}

.mdi-archive-eye::before {
  content: "\f1755";
}

.mdi-archive-eye-outline::before {
  content: "\f1756";
}

.mdi-archive-lock::before {
  content: "\f1757";
}

.mdi-archive-lock-open::before {
  content: "\f1758";
}

.mdi-archive-lock-open-outline::before {
  content: "\f1759";
}

.mdi-archive-lock-outline::before {
  content: "\f175a";
}

.mdi-archive-marker::before {
  content: "\f175b";
}

.mdi-archive-marker-outline::before {
  content: "\f175c";
}

.mdi-archive-minus::before {
  content: "\f175d";
}

.mdi-archive-minus-outline::before {
  content: "\f175e";
}

.mdi-archive-music::before {
  content: "\f175f";
}

.mdi-archive-music-outline::before {
  content: "\f1760";
}

.mdi-archive-off::before {
  content: "\f1761";
}

.mdi-archive-off-outline::before {
  content: "\f1762";
}

.mdi-archive-outline::before {
  content: "\f120e";
}

.mdi-archive-plus::before {
  content: "\f1763";
}

.mdi-archive-plus-outline::before {
  content: "\f1764";
}

.mdi-archive-refresh::before {
  content: "\f1765";
}

.mdi-archive-refresh-outline::before {
  content: "\f1766";
}

.mdi-archive-remove::before {
  content: "\f1767";
}

.mdi-archive-remove-outline::before {
  content: "\f1768";
}

.mdi-archive-search::before {
  content: "\f1769";
}

.mdi-archive-search-outline::before {
  content: "\f176a";
}

.mdi-archive-settings::before {
  content: "\f176b";
}

.mdi-archive-settings-outline::before {
  content: "\f176c";
}

.mdi-archive-star::before {
  content: "\f176d";
}

.mdi-archive-star-outline::before {
  content: "\f176e";
}

.mdi-archive-sync::before {
  content: "\f176f";
}

.mdi-archive-sync-outline::before {
  content: "\f1770";
}

.mdi-arm-flex::before {
  content: "\f0fd7";
}

.mdi-arm-flex-outline::before {
  content: "\f0fd6";
}

.mdi-arrange-bring-forward::before {
  content: "\f003d";
}

.mdi-arrange-bring-to-front::before {
  content: "\f003e";
}

.mdi-arrange-send-backward::before {
  content: "\f003f";
}

.mdi-arrange-send-to-back::before {
  content: "\f0040";
}

.mdi-arrow-all::before {
  content: "\f0041";
}

.mdi-arrow-bottom-left::before {
  content: "\f0042";
}

.mdi-arrow-bottom-left-bold-box::before {
  content: "\f1964";
}

.mdi-arrow-bottom-left-bold-box-outline::before {
  content: "\f1965";
}

.mdi-arrow-bottom-left-bold-outline::before {
  content: "\f09b7";
}

.mdi-arrow-bottom-left-thick::before {
  content: "\f09b8";
}

.mdi-arrow-bottom-left-thin::before {
  content: "\f19b6";
}

.mdi-arrow-bottom-left-thin-circle-outline::before {
  content: "\f1596";
}

.mdi-arrow-bottom-right::before {
  content: "\f0043";
}

.mdi-arrow-bottom-right-bold-box::before {
  content: "\f1966";
}

.mdi-arrow-bottom-right-bold-box-outline::before {
  content: "\f1967";
}

.mdi-arrow-bottom-right-bold-outline::before {
  content: "\f09b9";
}

.mdi-arrow-bottom-right-thick::before {
  content: "\f09ba";
}

.mdi-arrow-bottom-right-thin::before {
  content: "\f19b7";
}

.mdi-arrow-bottom-right-thin-circle-outline::before {
  content: "\f1595";
}

.mdi-arrow-collapse::before {
  content: "\f0615";
}

.mdi-arrow-collapse-all::before {
  content: "\f0044";
}

.mdi-arrow-collapse-down::before {
  content: "\f0792";
}

.mdi-arrow-collapse-horizontal::before {
  content: "\f084c";
}

.mdi-arrow-collapse-left::before {
  content: "\f0793";
}

.mdi-arrow-collapse-right::before {
  content: "\f0794";
}

.mdi-arrow-collapse-up::before {
  content: "\f0795";
}

.mdi-arrow-collapse-vertical::before {
  content: "\f084d";
}

.mdi-arrow-decision::before {
  content: "\f09bb";
}

.mdi-arrow-decision-auto::before {
  content: "\f09bc";
}

.mdi-arrow-decision-auto-outline::before {
  content: "\f09bd";
}

.mdi-arrow-decision-outline::before {
  content: "\f09be";
}

.mdi-arrow-down::before {
  content: "\f0045";
}

.mdi-arrow-down-bold::before {
  content: "\f072e";
}

.mdi-arrow-down-bold-box::before {
  content: "\f072f";
}

.mdi-arrow-down-bold-box-outline::before {
  content: "\f0730";
}

.mdi-arrow-down-bold-circle::before {
  content: "\f0047";
}

.mdi-arrow-down-bold-circle-outline::before {
  content: "\f0048";
}

.mdi-arrow-down-bold-hexagon-outline::before {
  content: "\f0049";
}

.mdi-arrow-down-bold-outline::before {
  content: "\f09bf";
}

.mdi-arrow-down-box::before {
  content: "\f06c0";
}

.mdi-arrow-down-circle::before {
  content: "\f0cdb";
}

.mdi-arrow-down-circle-outline::before {
  content: "\f0cdc";
}

.mdi-arrow-down-drop-circle::before {
  content: "\f004a";
}

.mdi-arrow-down-drop-circle-outline::before {
  content: "\f004b";
}

.mdi-arrow-down-left::before {
  content: "\f17a1";
}

.mdi-arrow-down-left-bold::before {
  content: "\f17a2";
}

.mdi-arrow-down-right::before {
  content: "\f17a3";
}

.mdi-arrow-down-right-bold::before {
  content: "\f17a4";
}

.mdi-arrow-down-thick::before {
  content: "\f0046";
}

.mdi-arrow-down-thin::before {
  content: "\f19b3";
}

.mdi-arrow-down-thin-circle-outline::before {
  content: "\f1599";
}

.mdi-arrow-expand::before {
  content: "\f0616";
}

.mdi-arrow-expand-all::before {
  content: "\f004c";
}

.mdi-arrow-expand-down::before {
  content: "\f0796";
}

.mdi-arrow-expand-horizontal::before {
  content: "\f084e";
}

.mdi-arrow-expand-left::before {
  content: "\f0797";
}

.mdi-arrow-expand-right::before {
  content: "\f0798";
}

.mdi-arrow-expand-up::before {
  content: "\f0799";
}

.mdi-arrow-expand-vertical::before {
  content: "\f084f";
}

.mdi-arrow-horizontal-lock::before {
  content: "\f115b";
}

.mdi-arrow-left::before {
  content: "\f004d";
}

.mdi-arrow-left-bold::before {
  content: "\f0731";
}

.mdi-arrow-left-bold-box::before {
  content: "\f0732";
}

.mdi-arrow-left-bold-box-outline::before {
  content: "\f0733";
}

.mdi-arrow-left-bold-circle::before {
  content: "\f004f";
}

.mdi-arrow-left-bold-circle-outline::before {
  content: "\f0050";
}

.mdi-arrow-left-bold-hexagon-outline::before {
  content: "\f0051";
}

.mdi-arrow-left-bold-outline::before {
  content: "\f09c0";
}

.mdi-arrow-left-bottom::before {
  content: "\f17a5";
}

.mdi-arrow-left-bottom-bold::before {
  content: "\f17a6";
}

.mdi-arrow-left-box::before {
  content: "\f06c1";
}

.mdi-arrow-left-circle::before {
  content: "\f0cdd";
}

.mdi-arrow-left-circle-outline::before {
  content: "\f0cde";
}

.mdi-arrow-left-drop-circle::before {
  content: "\f0052";
}

.mdi-arrow-left-drop-circle-outline::before {
  content: "\f0053";
}

.mdi-arrow-left-right::before {
  content: "\f0e73";
}

.mdi-arrow-left-right-bold::before {
  content: "\f0e74";
}

.mdi-arrow-left-right-bold-outline::before {
  content: "\f09c1";
}

.mdi-arrow-left-thick::before {
  content: "\f004e";
}

.mdi-arrow-left-thin::before {
  content: "\f19b1";
}

.mdi-arrow-left-thin-circle-outline::before {
  content: "\f159a";
}

.mdi-arrow-left-top::before {
  content: "\f17a7";
}

.mdi-arrow-left-top-bold::before {
  content: "\f17a8";
}

.mdi-arrow-projectile::before {
  content: "\f1840";
}

.mdi-arrow-projectile-multiple::before {
  content: "\f183f";
}

.mdi-arrow-right::before {
  content: "\f0054";
}

.mdi-arrow-right-bold::before {
  content: "\f0734";
}

.mdi-arrow-right-bold-box::before {
  content: "\f0735";
}

.mdi-arrow-right-bold-box-outline::before {
  content: "\f0736";
}

.mdi-arrow-right-bold-circle::before {
  content: "\f0056";
}

.mdi-arrow-right-bold-circle-outline::before {
  content: "\f0057";
}

.mdi-arrow-right-bold-hexagon-outline::before {
  content: "\f0058";
}

.mdi-arrow-right-bold-outline::before {
  content: "\f09c2";
}

.mdi-arrow-right-bottom::before {
  content: "\f17a9";
}

.mdi-arrow-right-bottom-bold::before {
  content: "\f17aa";
}

.mdi-arrow-right-box::before {
  content: "\f06c2";
}

.mdi-arrow-right-circle::before {
  content: "\f0cdf";
}

.mdi-arrow-right-circle-outline::before {
  content: "\f0ce0";
}

.mdi-arrow-right-drop-circle::before {
  content: "\f0059";
}

.mdi-arrow-right-drop-circle-outline::before {
  content: "\f005a";
}

.mdi-arrow-right-thick::before {
  content: "\f0055";
}

.mdi-arrow-right-thin::before {
  content: "\f19b0";
}

.mdi-arrow-right-thin-circle-outline::before {
  content: "\f1598";
}

.mdi-arrow-right-top::before {
  content: "\f17ab";
}

.mdi-arrow-right-top-bold::before {
  content: "\f17ac";
}

.mdi-arrow-split-horizontal::before {
  content: "\f093b";
}

.mdi-arrow-split-vertical::before {
  content: "\f093c";
}

.mdi-arrow-top-left::before {
  content: "\f005b";
}

.mdi-arrow-top-left-bold-box::before {
  content: "\f1968";
}

.mdi-arrow-top-left-bold-box-outline::before {
  content: "\f1969";
}

.mdi-arrow-top-left-bold-outline::before {
  content: "\f09c3";
}

.mdi-arrow-top-left-bottom-right::before {
  content: "\f0e75";
}

.mdi-arrow-top-left-bottom-right-bold::before {
  content: "\f0e76";
}

.mdi-arrow-top-left-thick::before {
  content: "\f09c4";
}

.mdi-arrow-top-left-thin::before {
  content: "\f19b5";
}

.mdi-arrow-top-left-thin-circle-outline::before {
  content: "\f1593";
}

.mdi-arrow-top-right::before {
  content: "\f005c";
}

.mdi-arrow-top-right-bold-box::before {
  content: "\f196a";
}

.mdi-arrow-top-right-bold-box-outline::before {
  content: "\f196b";
}

.mdi-arrow-top-right-bold-outline::before {
  content: "\f09c5";
}

.mdi-arrow-top-right-bottom-left::before {
  content: "\f0e77";
}

.mdi-arrow-top-right-bottom-left-bold::before {
  content: "\f0e78";
}

.mdi-arrow-top-right-thick::before {
  content: "\f09c6";
}

.mdi-arrow-top-right-thin::before {
  content: "\f19b4";
}

.mdi-arrow-top-right-thin-circle-outline::before {
  content: "\f1594";
}

.mdi-arrow-u-down-left::before {
  content: "\f17ad";
}

.mdi-arrow-u-down-left-bold::before {
  content: "\f17ae";
}

.mdi-arrow-u-down-right::before {
  content: "\f17af";
}

.mdi-arrow-u-down-right-bold::before {
  content: "\f17b0";
}

.mdi-arrow-u-left-bottom::before {
  content: "\f17b1";
}

.mdi-arrow-u-left-bottom-bold::before {
  content: "\f17b2";
}

.mdi-arrow-u-left-top::before {
  content: "\f17b3";
}

.mdi-arrow-u-left-top-bold::before {
  content: "\f17b4";
}

.mdi-arrow-u-right-bottom::before {
  content: "\f17b5";
}

.mdi-arrow-u-right-bottom-bold::before {
  content: "\f17b6";
}

.mdi-arrow-u-right-top::before {
  content: "\f17b7";
}

.mdi-arrow-u-right-top-bold::before {
  content: "\f17b8";
}

.mdi-arrow-u-up-left::before {
  content: "\f17b9";
}

.mdi-arrow-u-up-left-bold::before {
  content: "\f17ba";
}

.mdi-arrow-u-up-right::before {
  content: "\f17bb";
}

.mdi-arrow-u-up-right-bold::before {
  content: "\f17bc";
}

.mdi-arrow-up::before {
  content: "\f005d";
}

.mdi-arrow-up-bold::before {
  content: "\f0737";
}

.mdi-arrow-up-bold-box::before {
  content: "\f0738";
}

.mdi-arrow-up-bold-box-outline::before {
  content: "\f0739";
}

.mdi-arrow-up-bold-circle::before {
  content: "\f005f";
}

.mdi-arrow-up-bold-circle-outline::before {
  content: "\f0060";
}

.mdi-arrow-up-bold-hexagon-outline::before {
  content: "\f0061";
}

.mdi-arrow-up-bold-outline::before {
  content: "\f09c7";
}

.mdi-arrow-up-box::before {
  content: "\f06c3";
}

.mdi-arrow-up-circle::before {
  content: "\f0ce1";
}

.mdi-arrow-up-circle-outline::before {
  content: "\f0ce2";
}

.mdi-arrow-up-down::before {
  content: "\f0e79";
}

.mdi-arrow-up-down-bold::before {
  content: "\f0e7a";
}

.mdi-arrow-up-down-bold-outline::before {
  content: "\f09c8";
}

.mdi-arrow-up-drop-circle::before {
  content: "\f0062";
}

.mdi-arrow-up-drop-circle-outline::before {
  content: "\f0063";
}

.mdi-arrow-up-left::before {
  content: "\f17bd";
}

.mdi-arrow-up-left-bold::before {
  content: "\f17be";
}

.mdi-arrow-up-right::before {
  content: "\f17bf";
}

.mdi-arrow-up-right-bold::before {
  content: "\f17c0";
}

.mdi-arrow-up-thick::before {
  content: "\f005e";
}

.mdi-arrow-up-thin::before {
  content: "\f19b2";
}

.mdi-arrow-up-thin-circle-outline::before {
  content: "\f1597";
}

.mdi-arrow-vertical-lock::before {
  content: "\f115c";
}

.mdi-artstation::before {
  content: "\f0b5b";
}

.mdi-aspect-ratio::before {
  content: "\f0a24";
}

.mdi-assistant::before {
  content: "\f0064";
}

.mdi-asterisk::before {
  content: "\f06c4";
}

.mdi-at::before {
  content: "\f0065";
}

.mdi-atlassian::before {
  content: "\f0804";
}

.mdi-atm::before {
  content: "\f0d47";
}

.mdi-atom::before {
  content: "\f0768";
}

.mdi-atom-variant::before {
  content: "\f0e7b";
}

.mdi-attachment::before {
  content: "\f0066";
}

.mdi-audio-input-rca::before {
  content: "\f186b";
}

.mdi-audio-input-stereo-minijack::before {
  content: "\f186c";
}

.mdi-audio-input-xlr::before {
  content: "\f186d";
}

.mdi-audio-video::before {
  content: "\f093d";
}

.mdi-audio-video-off::before {
  content: "\f11b6";
}

.mdi-augmented-reality::before {
  content: "\f0850";
}

.mdi-auto-download::before {
  content: "\f137e";
}

.mdi-auto-fix::before {
  content: "\f0068";
}

.mdi-auto-upload::before {
  content: "\f0069";
}

.mdi-autorenew::before {
  content: "\f006a";
}

.mdi-av-timer::before {
  content: "\f006b";
}

.mdi-aws::before {
  content: "\f0e0f";
}

.mdi-axe::before {
  content: "\f08c8";
}

.mdi-axe-battle::before {
  content: "\f1842";
}

.mdi-axis::before {
  content: "\f0d48";
}

.mdi-axis-arrow::before {
  content: "\f0d49";
}

.mdi-axis-arrow-info::before {
  content: "\f140e";
}

.mdi-axis-arrow-lock::before {
  content: "\f0d4a";
}

.mdi-axis-lock::before {
  content: "\f0d4b";
}

.mdi-axis-x-arrow::before {
  content: "\f0d4c";
}

.mdi-axis-x-arrow-lock::before {
  content: "\f0d4d";
}

.mdi-axis-x-rotate-clockwise::before {
  content: "\f0d4e";
}

.mdi-axis-x-rotate-counterclockwise::before {
  content: "\f0d4f";
}

.mdi-axis-x-y-arrow-lock::before {
  content: "\f0d50";
}

.mdi-axis-y-arrow::before {
  content: "\f0d51";
}

.mdi-axis-y-arrow-lock::before {
  content: "\f0d52";
}

.mdi-axis-y-rotate-clockwise::before {
  content: "\f0d53";
}

.mdi-axis-y-rotate-counterclockwise::before {
  content: "\f0d54";
}

.mdi-axis-z-arrow::before {
  content: "\f0d55";
}

.mdi-axis-z-arrow-lock::before {
  content: "\f0d56";
}

.mdi-axis-z-rotate-clockwise::before {
  content: "\f0d57";
}

.mdi-axis-z-rotate-counterclockwise::before {
  content: "\f0d58";
}

.mdi-babel::before {
  content: "\f0a25";
}

.mdi-baby::before {
  content: "\f006c";
}

.mdi-baby-bottle::before {
  content: "\f0f39";
}

.mdi-baby-bottle-outline::before {
  content: "\f0f3a";
}

.mdi-baby-buggy::before {
  content: "\f13e0";
}

.mdi-baby-carriage::before {
  content: "\f068f";
}

.mdi-baby-carriage-off::before {
  content: "\f0fa0";
}

.mdi-baby-face::before {
  content: "\f0e7c";
}

.mdi-baby-face-outline::before {
  content: "\f0e7d";
}

.mdi-backburger::before {
  content: "\f006d";
}

.mdi-backspace::before {
  content: "\f006e";
}

.mdi-backspace-outline::before {
  content: "\f0b5c";
}

.mdi-backspace-reverse::before {
  content: "\f0e7e";
}

.mdi-backspace-reverse-outline::before {
  content: "\f0e7f";
}

.mdi-backup-restore::before {
  content: "\f006f";
}

.mdi-bacteria::before {
  content: "\f0ed5";
}

.mdi-bacteria-outline::before {
  content: "\f0ed6";
}

.mdi-badge-account::before {
  content: "\f0da7";
}

.mdi-badge-account-alert::before {
  content: "\f0da8";
}

.mdi-badge-account-alert-outline::before {
  content: "\f0da9";
}

.mdi-badge-account-horizontal::before {
  content: "\f0e0d";
}

.mdi-badge-account-horizontal-outline::before {
  content: "\f0e0e";
}

.mdi-badge-account-outline::before {
  content: "\f0daa";
}

.mdi-badminton::before {
  content: "\f0851";
}

.mdi-bag-carry-on::before {
  content: "\f0f3b";
}

.mdi-bag-carry-on-check::before {
  content: "\f0d65";
}

.mdi-bag-carry-on-off::before {
  content: "\f0f3c";
}

.mdi-bag-checked::before {
  content: "\f0f3d";
}

.mdi-bag-personal::before {
  content: "\f0e10";
}

.mdi-bag-personal-off::before {
  content: "\f0e11";
}

.mdi-bag-personal-off-outline::before {
  content: "\f0e12";
}

.mdi-bag-personal-outline::before {
  content: "\f0e13";
}

.mdi-bag-suitcase::before {
  content: "\f158b";
}

.mdi-bag-suitcase-off::before {
  content: "\f158d";
}

.mdi-bag-suitcase-off-outline::before {
  content: "\f158e";
}

.mdi-bag-suitcase-outline::before {
  content: "\f158c";
}

.mdi-baguette::before {
  content: "\f0f3e";
}

.mdi-balcony::before {
  content: "\f1817";
}

.mdi-balloon::before {
  content: "\f0a26";
}

.mdi-ballot::before {
  content: "\f09c9";
}

.mdi-ballot-outline::before {
  content: "\f09ca";
}

.mdi-ballot-recount::before {
  content: "\f0c39";
}

.mdi-ballot-recount-outline::before {
  content: "\f0c3a";
}

.mdi-bandage::before {
  content: "\f0daf";
}

.mdi-bank::before {
  content: "\f0070";
}

.mdi-bank-check::before {
  content: "\f1655";
}

.mdi-bank-minus::before {
  content: "\f0db0";
}

.mdi-bank-off::before {
  content: "\f1656";
}

.mdi-bank-off-outline::before {
  content: "\f1657";
}

.mdi-bank-outline::before {
  content: "\f0e80";
}

.mdi-bank-plus::before {
  content: "\f0db1";
}

.mdi-bank-remove::before {
  content: "\f0db2";
}

.mdi-bank-transfer::before {
  content: "\f0a27";
}

.mdi-bank-transfer-in::before {
  content: "\f0a28";
}

.mdi-bank-transfer-out::before {
  content: "\f0a29";
}

.mdi-barcode::before {
  content: "\f0071";
}

.mdi-barcode-off::before {
  content: "\f1236";
}

.mdi-barcode-scan::before {
  content: "\f0072";
}

.mdi-barley::before {
  content: "\f0073";
}

.mdi-barley-off::before {
  content: "\f0b5d";
}

.mdi-barn::before {
  content: "\f0b5e";
}

.mdi-barrel::before {
  content: "\f0074";
}

.mdi-baseball::before {
  content: "\f0852";
}

.mdi-baseball-bat::before {
  content: "\f0853";
}

.mdi-baseball-diamond::before {
  content: "\f15ec";
}

.mdi-baseball-diamond-outline::before {
  content: "\f15ed";
}

.mdi-bash::before {
  content: "\f1183";
}

.mdi-basket::before {
  content: "\f0076";
}

.mdi-basket-check::before {
  content: "\f18e5";
}

.mdi-basket-check-outline::before {
  content: "\f18e6";
}

.mdi-basket-fill::before {
  content: "\f0077";
}

.mdi-basket-minus::before {
  content: "\f1523";
}

.mdi-basket-minus-outline::before {
  content: "\f1524";
}

.mdi-basket-off::before {
  content: "\f1525";
}

.mdi-basket-off-outline::before {
  content: "\f1526";
}

.mdi-basket-outline::before {
  content: "\f1181";
}

.mdi-basket-plus::before {
  content: "\f1527";
}

.mdi-basket-plus-outline::before {
  content: "\f1528";
}

.mdi-basket-remove::before {
  content: "\f1529";
}

.mdi-basket-remove-outline::before {
  content: "\f152a";
}

.mdi-basket-unfill::before {
  content: "\f0078";
}

.mdi-basketball::before {
  content: "\f0806";
}

.mdi-basketball-hoop::before {
  content: "\f0c3b";
}

.mdi-basketball-hoop-outline::before {
  content: "\f0c3c";
}

.mdi-bat::before {
  content: "\f0b5f";
}

.mdi-bathtub::before {
  content: "\f1818";
}

.mdi-bathtub-outline::before {
  content: "\f1819";
}

.mdi-battery::before {
  content: "\f0079";
}

.mdi-battery-10::before {
  content: "\f007a";
}

.mdi-battery-10-bluetooth::before {
  content: "\f093e";
}

.mdi-battery-20::before {
  content: "\f007b";
}

.mdi-battery-20-bluetooth::before {
  content: "\f093f";
}

.mdi-battery-30::before {
  content: "\f007c";
}

.mdi-battery-30-bluetooth::before {
  content: "\f0940";
}

.mdi-battery-40::before {
  content: "\f007d";
}

.mdi-battery-40-bluetooth::before {
  content: "\f0941";
}

.mdi-battery-50::before {
  content: "\f007e";
}

.mdi-battery-50-bluetooth::before {
  content: "\f0942";
}

.mdi-battery-60::before {
  content: "\f007f";
}

.mdi-battery-60-bluetooth::before {
  content: "\f0943";
}

.mdi-battery-70::before {
  content: "\f0080";
}

.mdi-battery-70-bluetooth::before {
  content: "\f0944";
}

.mdi-battery-80::before {
  content: "\f0081";
}

.mdi-battery-80-bluetooth::before {
  content: "\f0945";
}

.mdi-battery-90::before {
  content: "\f0082";
}

.mdi-battery-90-bluetooth::before {
  content: "\f0946";
}

.mdi-battery-alert::before {
  content: "\f0083";
}

.mdi-battery-alert-bluetooth::before {
  content: "\f0947";
}

.mdi-battery-alert-variant::before {
  content: "\f10cc";
}

.mdi-battery-alert-variant-outline::before {
  content: "\f10cd";
}

.mdi-battery-arrow-down::before {
  content: "\f17de";
}

.mdi-battery-arrow-down-outline::before {
  content: "\f17df";
}

.mdi-battery-arrow-up::before {
  content: "\f17e0";
}

.mdi-battery-arrow-up-outline::before {
  content: "\f17e1";
}

.mdi-battery-bluetooth::before {
  content: "\f0948";
}

.mdi-battery-bluetooth-variant::before {
  content: "\f0949";
}

.mdi-battery-charging::before {
  content: "\f0084";
}

.mdi-battery-charging-10::before {
  content: "\f089c";
}

.mdi-battery-charging-100::before {
  content: "\f0085";
}

.mdi-battery-charging-20::before {
  content: "\f0086";
}

.mdi-battery-charging-30::before {
  content: "\f0087";
}

.mdi-battery-charging-40::before {
  content: "\f0088";
}

.mdi-battery-charging-50::before {
  content: "\f089d";
}

.mdi-battery-charging-60::before {
  content: "\f0089";
}

.mdi-battery-charging-70::before {
  content: "\f089e";
}

.mdi-battery-charging-80::before {
  content: "\f008a";
}

.mdi-battery-charging-90::before {
  content: "\f008b";
}

.mdi-battery-charging-high::before {
  content: "\f12a6";
}

.mdi-battery-charging-low::before {
  content: "\f12a4";
}

.mdi-battery-charging-medium::before {
  content: "\f12a5";
}

.mdi-battery-charging-outline::before {
  content: "\f089f";
}

.mdi-battery-charging-wireless::before {
  content: "\f0807";
}

.mdi-battery-charging-wireless-10::before {
  content: "\f0808";
}

.mdi-battery-charging-wireless-20::before {
  content: "\f0809";
}

.mdi-battery-charging-wireless-30::before {
  content: "\f080a";
}

.mdi-battery-charging-wireless-40::before {
  content: "\f080b";
}

.mdi-battery-charging-wireless-50::before {
  content: "\f080c";
}

.mdi-battery-charging-wireless-60::before {
  content: "\f080d";
}

.mdi-battery-charging-wireless-70::before {
  content: "\f080e";
}

.mdi-battery-charging-wireless-80::before {
  content: "\f080f";
}

.mdi-battery-charging-wireless-90::before {
  content: "\f0810";
}

.mdi-battery-charging-wireless-alert::before {
  content: "\f0811";
}

.mdi-battery-charging-wireless-outline::before {
  content: "\f0812";
}

.mdi-battery-check::before {
  content: "\f17e2";
}

.mdi-battery-check-outline::before {
  content: "\f17e3";
}

.mdi-battery-heart::before {
  content: "\f120f";
}

.mdi-battery-heart-outline::before {
  content: "\f1210";
}

.mdi-battery-heart-variant::before {
  content: "\f1211";
}

.mdi-battery-high::before {
  content: "\f12a3";
}

.mdi-battery-lock::before {
  content: "\f179c";
}

.mdi-battery-lock-open::before {
  content: "\f179d";
}

.mdi-battery-low::before {
  content: "\f12a1";
}

.mdi-battery-medium::before {
  content: "\f12a2";
}

.mdi-battery-minus::before {
  content: "\f17e4";
}

.mdi-battery-minus-outline::before {
  content: "\f17e5";
}

.mdi-battery-minus-variant::before {
  content: "\f008c";
}

.mdi-battery-negative::before {
  content: "\f008d";
}

.mdi-battery-off::before {
  content: "\f125d";
}

.mdi-battery-off-outline::before {
  content: "\f125e";
}

.mdi-battery-outline::before {
  content: "\f008e";
}

.mdi-battery-plus::before {
  content: "\f17e6";
}

.mdi-battery-plus-outline::before {
  content: "\f17e7";
}

.mdi-battery-plus-variant::before {
  content: "\f008f";
}

.mdi-battery-positive::before {
  content: "\f0090";
}

.mdi-battery-remove::before {
  content: "\f17e8";
}

.mdi-battery-remove-outline::before {
  content: "\f17e9";
}

.mdi-battery-sync::before {
  content: "\f1834";
}

.mdi-battery-sync-outline::before {
  content: "\f1835";
}

.mdi-battery-unknown::before {
  content: "\f0091";
}

.mdi-battery-unknown-bluetooth::before {
  content: "\f094a";
}

.mdi-beach::before {
  content: "\f0092";
}

.mdi-beaker::before {
  content: "\f0cea";
}

.mdi-beaker-alert::before {
  content: "\f1229";
}

.mdi-beaker-alert-outline::before {
  content: "\f122a";
}

.mdi-beaker-check::before {
  content: "\f122b";
}

.mdi-beaker-check-outline::before {
  content: "\f122c";
}

.mdi-beaker-minus::before {
  content: "\f122d";
}

.mdi-beaker-minus-outline::before {
  content: "\f122e";
}

.mdi-beaker-outline::before {
  content: "\f0690";
}

.mdi-beaker-plus::before {
  content: "\f122f";
}

.mdi-beaker-plus-outline::before {
  content: "\f1230";
}

.mdi-beaker-question::before {
  content: "\f1231";
}

.mdi-beaker-question-outline::before {
  content: "\f1232";
}

.mdi-beaker-remove::before {
  content: "\f1233";
}

.mdi-beaker-remove-outline::before {
  content: "\f1234";
}

.mdi-bed::before {
  content: "\f02e3";
}

.mdi-bed-double::before {
  content: "\f0fd4";
}

.mdi-bed-double-outline::before {
  content: "\f0fd3";
}

.mdi-bed-empty::before {
  content: "\f08a0";
}

.mdi-bed-king::before {
  content: "\f0fd2";
}

.mdi-bed-king-outline::before {
  content: "\f0fd1";
}

.mdi-bed-outline::before {
  content: "\f0099";
}

.mdi-bed-queen::before {
  content: "\f0fd0";
}

.mdi-bed-queen-outline::before {
  content: "\f0fdb";
}

.mdi-bed-single::before {
  content: "\f106d";
}

.mdi-bed-single-outline::before {
  content: "\f106e";
}

.mdi-bee::before {
  content: "\f0fa1";
}

.mdi-bee-flower::before {
  content: "\f0fa2";
}

.mdi-beehive-off-outline::before {
  content: "\f13ed";
}

.mdi-beehive-outline::before {
  content: "\f10ce";
}

.mdi-beekeeper::before {
  content: "\f14e2";
}

.mdi-beer::before {
  content: "\f0098";
}

.mdi-beer-outline::before {
  content: "\f130c";
}

.mdi-bell::before {
  content: "\f009a";
}

.mdi-bell-alert::before {
  content: "\f0d59";
}

.mdi-bell-alert-outline::before {
  content: "\f0e81";
}

.mdi-bell-badge::before {
  content: "\f116b";
}

.mdi-bell-badge-outline::before {
  content: "\f0178";
}

.mdi-bell-cancel::before {
  content: "\f13e7";
}

.mdi-bell-cancel-outline::before {
  content: "\f13e8";
}

.mdi-bell-check::before {
  content: "\f11e5";
}

.mdi-bell-check-outline::before {
  content: "\f11e6";
}

.mdi-bell-circle::before {
  content: "\f0d5a";
}

.mdi-bell-circle-outline::before {
  content: "\f0d5b";
}

.mdi-bell-minus::before {
  content: "\f13e9";
}

.mdi-bell-minus-outline::before {
  content: "\f13ea";
}

.mdi-bell-off::before {
  content: "\f009b";
}

.mdi-bell-off-outline::before {
  content: "\f0a91";
}

.mdi-bell-outline::before {
  content: "\f009c";
}

.mdi-bell-plus::before {
  content: "\f009d";
}

.mdi-bell-plus-outline::before {
  content: "\f0a92";
}

.mdi-bell-remove::before {
  content: "\f13eb";
}

.mdi-bell-remove-outline::before {
  content: "\f13ec";
}

.mdi-bell-ring::before {
  content: "\f009e";
}

.mdi-bell-ring-outline::before {
  content: "\f009f";
}

.mdi-bell-sleep::before {
  content: "\f00a0";
}

.mdi-bell-sleep-outline::before {
  content: "\f0a93";
}

.mdi-beta::before {
  content: "\f00a1";
}

.mdi-betamax::before {
  content: "\f09cb";
}

.mdi-biathlon::before {
  content: "\f0e14";
}

.mdi-bicycle::before {
  content: "\f109c";
}

.mdi-bicycle-basket::before {
  content: "\f1235";
}

.mdi-bicycle-cargo::before {
  content: "\f189c";
}

.mdi-bicycle-electric::before {
  content: "\f15b4";
}

.mdi-bicycle-penny-farthing::before {
  content: "\f15e9";
}

.mdi-bike::before {
  content: "\f00a3";
}

.mdi-bike-fast::before {
  content: "\f111f";
}

.mdi-billboard::before {
  content: "\f1010";
}

.mdi-billiards::before {
  content: "\f0b61";
}

.mdi-billiards-rack::before {
  content: "\f0b62";
}

.mdi-binoculars::before {
  content: "\f00a5";
}

.mdi-bio::before {
  content: "\f00a6";
}

.mdi-biohazard::before {
  content: "\f00a7";
}

.mdi-bird::before {
  content: "\f15c6";
}

.mdi-bitbucket::before {
  content: "\f00a8";
}

.mdi-bitcoin::before {
  content: "\f0813";
}

.mdi-black-mesa::before {
  content: "\f00a9";
}

.mdi-blender::before {
  content: "\f0ceb";
}

.mdi-blender-outline::before {
  content: "\f181a";
}

.mdi-blender-software::before {
  content: "\f00ab";
}

.mdi-blinds::before {
  content: "\f00ac";
}

.mdi-blinds-open::before {
  content: "\f1011";
}

.mdi-block-helper::before {
  content: "\f00ad";
}

.mdi-blood-bag::before {
  content: "\f0cec";
}

.mdi-bluetooth::before {
  content: "\f00af";
}

.mdi-bluetooth-audio::before {
  content: "\f00b0";
}

.mdi-bluetooth-connect::before {
  content: "\f00b1";
}

.mdi-bluetooth-off::before {
  content: "\f00b2";
}

.mdi-bluetooth-settings::before {
  content: "\f00b3";
}

.mdi-bluetooth-transfer::before {
  content: "\f00b4";
}

.mdi-blur::before {
  content: "\f00b5";
}

.mdi-blur-linear::before {
  content: "\f00b6";
}

.mdi-blur-off::before {
  content: "\f00b7";
}

.mdi-blur-radial::before {
  content: "\f00b8";
}

.mdi-bolt::before {
  content: "\f0db3";
}

.mdi-bomb::before {
  content: "\f0691";
}

.mdi-bomb-off::before {
  content: "\f06c5";
}

.mdi-bone::before {
  content: "\f00b9";
}

.mdi-book::before {
  content: "\f00ba";
}

.mdi-book-account::before {
  content: "\f13ad";
}

.mdi-book-account-outline::before {
  content: "\f13ae";
}

.mdi-book-alert::before {
  content: "\f167c";
}

.mdi-book-alert-outline::before {
  content: "\f167d";
}

.mdi-book-alphabet::before {
  content: "\f061d";
}

.mdi-book-arrow-down::before {
  content: "\f167e";
}

.mdi-book-arrow-down-outline::before {
  content: "\f167f";
}

.mdi-book-arrow-left::before {
  content: "\f1680";
}

.mdi-book-arrow-left-outline::before {
  content: "\f1681";
}

.mdi-book-arrow-right::before {
  content: "\f1682";
}

.mdi-book-arrow-right-outline::before {
  content: "\f1683";
}

.mdi-book-arrow-up::before {
  content: "\f1684";
}

.mdi-book-arrow-up-outline::before {
  content: "\f1685";
}

.mdi-book-cancel::before {
  content: "\f1686";
}

.mdi-book-cancel-outline::before {
  content: "\f1687";
}

.mdi-book-check::before {
  content: "\f14f3";
}

.mdi-book-check-outline::before {
  content: "\f14f4";
}

.mdi-book-clock::before {
  content: "\f1688";
}

.mdi-book-clock-outline::before {
  content: "\f1689";
}

.mdi-book-cog::before {
  content: "\f168a";
}

.mdi-book-cog-outline::before {
  content: "\f168b";
}

.mdi-book-cross::before {
  content: "\f00a2";
}

.mdi-book-edit::before {
  content: "\f168c";
}

.mdi-book-edit-outline::before {
  content: "\f168d";
}

.mdi-book-education::before {
  content: "\f16c9";
}

.mdi-book-education-outline::before {
  content: "\f16ca";
}

.mdi-book-information-variant::before {
  content: "\f106f";
}

.mdi-book-lock::before {
  content: "\f079a";
}

.mdi-book-lock-open::before {
  content: "\f079b";
}

.mdi-book-lock-open-outline::before {
  content: "\f168e";
}

.mdi-book-lock-outline::before {
  content: "\f168f";
}

.mdi-book-marker::before {
  content: "\f1690";
}

.mdi-book-marker-outline::before {
  content: "\f1691";
}

.mdi-book-minus::before {
  content: "\f05d9";
}

.mdi-book-minus-multiple::before {
  content: "\f0a94";
}

.mdi-book-minus-multiple-outline::before {
  content: "\f090b";
}

.mdi-book-minus-outline::before {
  content: "\f1692";
}

.mdi-book-multiple::before {
  content: "\f00bb";
}

.mdi-book-multiple-outline::before {
  content: "\f0436";
}

.mdi-book-music::before {
  content: "\f0067";
}

.mdi-book-music-outline::before {
  content: "\f1693";
}

.mdi-book-off::before {
  content: "\f1694";
}

.mdi-book-off-outline::before {
  content: "\f1695";
}

.mdi-book-open::before {
  content: "\f00bd";
}

.mdi-book-open-blank-variant::before {
  content: "\f00be";
}

.mdi-book-open-outline::before {
  content: "\f0b63";
}

.mdi-book-open-page-variant::before {
  content: "\f05da";
}

.mdi-book-open-page-variant-outline::before {
  content: "\f15d6";
}

.mdi-book-open-variant::before {
  content: "\f14f7";
}

.mdi-book-outline::before {
  content: "\f0b64";
}

.mdi-book-play::before {
  content: "\f0e82";
}

.mdi-book-play-outline::before {
  content: "\f0e83";
}

.mdi-book-plus::before {
  content: "\f05db";
}

.mdi-book-plus-multiple::before {
  content: "\f0a95";
}

.mdi-book-plus-multiple-outline::before {
  content: "\f0ade";
}

.mdi-book-plus-outline::before {
  content: "\f1696";
}

.mdi-book-refresh::before {
  content: "\f1697";
}

.mdi-book-refresh-outline::before {
  content: "\f1698";
}

.mdi-book-remove::before {
  content: "\f0a97";
}

.mdi-book-remove-multiple::before {
  content: "\f0a96";
}

.mdi-book-remove-multiple-outline::before {
  content: "\f04ca";
}

.mdi-book-remove-outline::before {
  content: "\f1699";
}

.mdi-book-search::before {
  content: "\f0e84";
}

.mdi-book-search-outline::before {
  content: "\f0e85";
}

.mdi-book-settings::before {
  content: "\f169a";
}

.mdi-book-settings-outline::before {
  content: "\f169b";
}

.mdi-book-sync::before {
  content: "\f169c";
}

.mdi-book-sync-outline::before {
  content: "\f16c8";
}

.mdi-book-variant::before {
  content: "\f00bf";
}

.mdi-book-variant-multiple::before {
  content: "\f00bc";
}

.mdi-bookmark::before {
  content: "\f00c0";
}

.mdi-bookmark-box-multiple::before {
  content: "\f196c";
}

.mdi-bookmark-box-multiple-outline::before {
  content: "\f196d";
}

.mdi-bookmark-check::before {
  content: "\f00c1";
}

.mdi-bookmark-check-outline::before {
  content: "\f137b";
}

.mdi-bookmark-minus::before {
  content: "\f09cc";
}

.mdi-bookmark-minus-outline::before {
  content: "\f09cd";
}

.mdi-bookmark-multiple::before {
  content: "\f0e15";
}

.mdi-bookmark-multiple-outline::before {
  content: "\f0e16";
}

.mdi-bookmark-music::before {
  content: "\f00c2";
}

.mdi-bookmark-music-outline::before {
  content: "\f1379";
}

.mdi-bookmark-off::before {
  content: "\f09ce";
}

.mdi-bookmark-off-outline::before {
  content: "\f09cf";
}

.mdi-bookmark-outline::before {
  content: "\f00c3";
}

.mdi-bookmark-plus::before {
  content: "\f00c5";
}

.mdi-bookmark-plus-outline::before {
  content: "\f00c4";
}

.mdi-bookmark-remove::before {
  content: "\f00c6";
}

.mdi-bookmark-remove-outline::before {
  content: "\f137a";
}

.mdi-bookshelf::before {
  content: "\f125f";
}

.mdi-boom-gate::before {
  content: "\f0e86";
}

.mdi-boom-gate-alert::before {
  content: "\f0e87";
}

.mdi-boom-gate-alert-outline::before {
  content: "\f0e88";
}

.mdi-boom-gate-arrow-down::before {
  content: "\f0e89";
}

.mdi-boom-gate-arrow-down-outline::before {
  content: "\f0e8a";
}

.mdi-boom-gate-arrow-up::before {
  content: "\f0e8c";
}

.mdi-boom-gate-arrow-up-outline::before {
  content: "\f0e8d";
}

.mdi-boom-gate-outline::before {
  content: "\f0e8b";
}

.mdi-boom-gate-up::before {
  content: "\f17f9";
}

.mdi-boom-gate-up-outline::before {
  content: "\f17fa";
}

.mdi-boombox::before {
  content: "\f05dc";
}

.mdi-boomerang::before {
  content: "\f10cf";
}

.mdi-bootstrap::before {
  content: "\f06c6";
}

.mdi-border-all::before {
  content: "\f00c7";
}

.mdi-border-all-variant::before {
  content: "\f08a1";
}

.mdi-border-bottom::before {
  content: "\f00c8";
}

.mdi-border-bottom-variant::before {
  content: "\f08a2";
}

.mdi-border-color::before {
  content: "\f00c9";
}

.mdi-border-horizontal::before {
  content: "\f00ca";
}

.mdi-border-inside::before {
  content: "\f00cb";
}

.mdi-border-left::before {
  content: "\f00cc";
}

.mdi-border-left-variant::before {
  content: "\f08a3";
}

.mdi-border-none::before {
  content: "\f00cd";
}

.mdi-border-none-variant::before {
  content: "\f08a4";
}

.mdi-border-outside::before {
  content: "\f00ce";
}

.mdi-border-right::before {
  content: "\f00cf";
}

.mdi-border-right-variant::before {
  content: "\f08a5";
}

.mdi-border-style::before {
  content: "\f00d0";
}

.mdi-border-top::before {
  content: "\f00d1";
}

.mdi-border-top-variant::before {
  content: "\f08a6";
}

.mdi-border-vertical::before {
  content: "\f00d2";
}

.mdi-bottle-soda::before {
  content: "\f1070";
}

.mdi-bottle-soda-classic::before {
  content: "\f1071";
}

.mdi-bottle-soda-classic-outline::before {
  content: "\f1363";
}

.mdi-bottle-soda-outline::before {
  content: "\f1072";
}

.mdi-bottle-tonic::before {
  content: "\f112e";
}

.mdi-bottle-tonic-outline::before {
  content: "\f112f";
}

.mdi-bottle-tonic-plus::before {
  content: "\f1130";
}

.mdi-bottle-tonic-plus-outline::before {
  content: "\f1131";
}

.mdi-bottle-tonic-skull::before {
  content: "\f1132";
}

.mdi-bottle-tonic-skull-outline::before {
  content: "\f1133";
}

.mdi-bottle-wine::before {
  content: "\f0854";
}

.mdi-bottle-wine-outline::before {
  content: "\f1310";
}

.mdi-bow-arrow::before {
  content: "\f1841";
}

.mdi-bow-tie::before {
  content: "\f0678";
}

.mdi-bowl::before {
  content: "\f028e";
}

.mdi-bowl-mix::before {
  content: "\f0617";
}

.mdi-bowl-mix-outline::before {
  content: "\f02e4";
}

.mdi-bowl-outline::before {
  content: "\f02a9";
}

.mdi-bowling::before {
  content: "\f00d3";
}

.mdi-box::before {
  content: "\f00d4";
}

.mdi-box-cutter::before {
  content: "\f00d5";
}

.mdi-box-cutter-off::before {
  content: "\f0b4a";
}

.mdi-box-shadow::before {
  content: "\f0637";
}

.mdi-boxing-glove::before {
  content: "\f0b65";
}

.mdi-braille::before {
  content: "\f09d0";
}

.mdi-brain::before {
  content: "\f09d1";
}

.mdi-bread-slice::before {
  content: "\f0cee";
}

.mdi-bread-slice-outline::before {
  content: "\f0cef";
}

.mdi-bridge::before {
  content: "\f0618";
}

.mdi-briefcase::before {
  content: "\f00d6";
}

.mdi-briefcase-account::before {
  content: "\f0cf0";
}

.mdi-briefcase-account-outline::before {
  content: "\f0cf1";
}

.mdi-briefcase-check::before {
  content: "\f00d7";
}

.mdi-briefcase-check-outline::before {
  content: "\f131e";
}

.mdi-briefcase-clock::before {
  content: "\f10d0";
}

.mdi-briefcase-clock-outline::before {
  content: "\f10d1";
}

.mdi-briefcase-download::before {
  content: "\f00d8";
}

.mdi-briefcase-download-outline::before {
  content: "\f0c3d";
}

.mdi-briefcase-edit::before {
  content: "\f0a98";
}

.mdi-briefcase-edit-outline::before {
  content: "\f0c3e";
}

.mdi-briefcase-eye::before {
  content: "\f17d9";
}

.mdi-briefcase-eye-outline::before {
  content: "\f17da";
}

.mdi-briefcase-minus::before {
  content: "\f0a2a";
}

.mdi-briefcase-minus-outline::before {
  content: "\f0c3f";
}

.mdi-briefcase-off::before {
  content: "\f1658";
}

.mdi-briefcase-off-outline::before {
  content: "\f1659";
}

.mdi-briefcase-outline::before {
  content: "\f0814";
}

.mdi-briefcase-plus::before {
  content: "\f0a2b";
}

.mdi-briefcase-plus-outline::before {
  content: "\f0c40";
}

.mdi-briefcase-remove::before {
  content: "\f0a2c";
}

.mdi-briefcase-remove-outline::before {
  content: "\f0c41";
}

.mdi-briefcase-search::before {
  content: "\f0a2d";
}

.mdi-briefcase-search-outline::before {
  content: "\f0c42";
}

.mdi-briefcase-upload::before {
  content: "\f00d9";
}

.mdi-briefcase-upload-outline::before {
  content: "\f0c43";
}

.mdi-briefcase-variant::before {
  content: "\f1494";
}

.mdi-briefcase-variant-off::before {
  content: "\f165a";
}

.mdi-briefcase-variant-off-outline::before {
  content: "\f165b";
}

.mdi-briefcase-variant-outline::before {
  content: "\f1495";
}

.mdi-brightness-1::before {
  content: "\f00da";
}

.mdi-brightness-2::before {
  content: "\f00db";
}

.mdi-brightness-3::before {
  content: "\f00dc";
}

.mdi-brightness-4::before {
  content: "\f00dd";
}

.mdi-brightness-5::before {
  content: "\f00de";
}

.mdi-brightness-6::before {
  content: "\f00df";
}

.mdi-brightness-7::before {
  content: "\f00e0";
}

.mdi-brightness-auto::before {
  content: "\f00e1";
}

.mdi-brightness-percent::before {
  content: "\f0cf2";
}

.mdi-broadcast::before {
  content: "\f1720";
}

.mdi-broadcast-off::before {
  content: "\f1721";
}

.mdi-broom::before {
  content: "\f00e2";
}

.mdi-brush::before {
  content: "\f00e3";
}

.mdi-brush-off::before {
  content: "\f1771";
}

.mdi-brush-variant::before {
  content: "\f1813";
}

.mdi-bucket::before {
  content: "\f1415";
}

.mdi-bucket-outline::before {
  content: "\f1416";
}

.mdi-buffet::before {
  content: "\f0578";
}

.mdi-bug::before {
  content: "\f00e4";
}

.mdi-bug-check::before {
  content: "\f0a2e";
}

.mdi-bug-check-outline::before {
  content: "\f0a2f";
}

.mdi-bug-outline::before {
  content: "\f0a30";
}

.mdi-bugle::before {
  content: "\f0db4";
}

.mdi-bulldozer::before {
  content: "\f0b22";
}

.mdi-bullet::before {
  content: "\f0cf3";
}

.mdi-bulletin-board::before {
  content: "\f00e5";
}

.mdi-bullhorn::before {
  content: "\f00e6";
}

.mdi-bullhorn-outline::before {
  content: "\f0b23";
}

.mdi-bullhorn-variant::before {
  content: "\f196e";
}

.mdi-bullhorn-variant-outline::before {
  content: "\f196f";
}

.mdi-bullseye::before {
  content: "\f05dd";
}

.mdi-bullseye-arrow::before {
  content: "\f08c9";
}

.mdi-bulma::before {
  content: "\f12e7";
}

.mdi-bunk-bed::before {
  content: "\f1302";
}

.mdi-bunk-bed-outline::before {
  content: "\f0097";
}

.mdi-bus::before {
  content: "\f00e7";
}

.mdi-bus-alert::before {
  content: "\f0a99";
}

.mdi-bus-articulated-end::before {
  content: "\f079c";
}

.mdi-bus-articulated-front::before {
  content: "\f079d";
}

.mdi-bus-clock::before {
  content: "\f08ca";
}

.mdi-bus-double-decker::before {
  content: "\f079e";
}

.mdi-bus-electric::before {
  content: "\f191d";
}

.mdi-bus-marker::before {
  content: "\f1212";
}

.mdi-bus-multiple::before {
  content: "\f0f3f";
}

.mdi-bus-school::before {
  content: "\f079f";
}

.mdi-bus-side::before {
  content: "\f07a0";
}

.mdi-bus-stop::before {
  content: "\f1012";
}

.mdi-bus-stop-covered::before {
  content: "\f1013";
}

.mdi-bus-stop-uncovered::before {
  content: "\f1014";
}

.mdi-butterfly::before {
  content: "\f1589";
}

.mdi-butterfly-outline::before {
  content: "\f158a";
}

.mdi-cabin-a-frame::before {
  content: "\f188c";
}

.mdi-cable-data::before {
  content: "\f1394";
}

.mdi-cached::before {
  content: "\f00e8";
}

.mdi-cactus::before {
  content: "\f0db5";
}

.mdi-cake::before {
  content: "\f00e9";
}

.mdi-cake-layered::before {
  content: "\f00ea";
}

.mdi-cake-variant::before {
  content: "\f00eb";
}

.mdi-cake-variant-outline::before {
  content: "\f17f0";
}

.mdi-calculator::before {
  content: "\f00ec";
}

.mdi-calculator-variant::before {
  content: "\f0a9a";
}

.mdi-calculator-variant-outline::before {
  content: "\f15a6";
}

.mdi-calendar::before {
  content: "\f00ed";
}

.mdi-calendar-account::before {
  content: "\f0ed7";
}

.mdi-calendar-account-outline::before {
  content: "\f0ed8";
}

.mdi-calendar-alert::before {
  content: "\f0a31";
}

.mdi-calendar-arrow-left::before {
  content: "\f1134";
}

.mdi-calendar-arrow-right::before {
  content: "\f1135";
}

.mdi-calendar-blank::before {
  content: "\f00ee";
}

.mdi-calendar-blank-multiple::before {
  content: "\f1073";
}

.mdi-calendar-blank-outline::before {
  content: "\f0b66";
}

.mdi-calendar-check::before {
  content: "\f00ef";
}

.mdi-calendar-check-outline::before {
  content: "\f0c44";
}

.mdi-calendar-clock::before {
  content: "\f00f0";
}

.mdi-calendar-clock-outline::before {
  content: "\f16e1";
}

.mdi-calendar-collapse-horizontal::before {
  content: "\f189d";
}

.mdi-calendar-cursor::before {
  content: "\f157b";
}

.mdi-calendar-edit::before {
  content: "\f08a7";
}

.mdi-calendar-end::before {
  content: "\f166c";
}

.mdi-calendar-expand-horizontal::before {
  content: "\f189e";
}

.mdi-calendar-export::before {
  content: "\f0b24";
}

.mdi-calendar-heart::before {
  content: "\f09d2";
}

.mdi-calendar-import::before {
  content: "\f0b25";
}

.mdi-calendar-lock::before {
  content: "\f1641";
}

.mdi-calendar-lock-outline::before {
  content: "\f1642";
}

.mdi-calendar-minus::before {
  content: "\f0d5c";
}

.mdi-calendar-month::before {
  content: "\f0e17";
}

.mdi-calendar-month-outline::before {
  content: "\f0e18";
}

.mdi-calendar-multiple::before {
  content: "\f00f1";
}

.mdi-calendar-multiple-check::before {
  content: "\f00f2";
}

.mdi-calendar-multiselect::before {
  content: "\f0a32";
}

.mdi-calendar-outline::before {
  content: "\f0b67";
}

.mdi-calendar-plus::before {
  content: "\f00f3";
}

.mdi-calendar-question::before {
  content: "\f0692";
}

.mdi-calendar-range::before {
  content: "\f0679";
}

.mdi-calendar-range-outline::before {
  content: "\f0b68";
}

.mdi-calendar-refresh::before {
  content: "\f01e1";
}

.mdi-calendar-refresh-outline::before {
  content: "\f0203";
}

.mdi-calendar-remove::before {
  content: "\f00f4";
}

.mdi-calendar-remove-outline::before {
  content: "\f0c45";
}

.mdi-calendar-search::before {
  content: "\f094c";
}

.mdi-calendar-star::before {
  content: "\f09d3";
}

.mdi-calendar-start::before {
  content: "\f166d";
}

.mdi-calendar-sync::before {
  content: "\f0e8e";
}

.mdi-calendar-sync-outline::before {
  content: "\f0e8f";
}

.mdi-calendar-text::before {
  content: "\f00f5";
}

.mdi-calendar-text-outline::before {
  content: "\f0c46";
}

.mdi-calendar-today::before {
  content: "\f00f6";
}

.mdi-calendar-week::before {
  content: "\f0a33";
}

.mdi-calendar-week-begin::before {
  content: "\f0a34";
}

.mdi-calendar-weekend::before {
  content: "\f0ed9";
}

.mdi-calendar-weekend-outline::before {
  content: "\f0eda";
}

.mdi-call-made::before {
  content: "\f00f7";
}

.mdi-call-merge::before {
  content: "\f00f8";
}

.mdi-call-missed::before {
  content: "\f00f9";
}

.mdi-call-received::before {
  content: "\f00fa";
}

.mdi-call-split::before {
  content: "\f00fb";
}

.mdi-camcorder::before {
  content: "\f00fc";
}

.mdi-camcorder-off::before {
  content: "\f00ff";
}

.mdi-camera::before {
  content: "\f0100";
}

.mdi-camera-account::before {
  content: "\f08cb";
}

.mdi-camera-burst::before {
  content: "\f0693";
}

.mdi-camera-control::before {
  content: "\f0b69";
}

.mdi-camera-document::before {
  content: "\f1871";
}

.mdi-camera-document-off::before {
  content: "\f1872";
}

.mdi-camera-enhance::before {
  content: "\f0101";
}

.mdi-camera-enhance-outline::before {
  content: "\f0b6a";
}

.mdi-camera-flip::before {
  content: "\f15d9";
}

.mdi-camera-flip-outline::before {
  content: "\f15da";
}

.mdi-camera-front::before {
  content: "\f0102";
}

.mdi-camera-front-variant::before {
  content: "\f0103";
}

.mdi-camera-gopro::before {
  content: "\f07a1";
}

.mdi-camera-image::before {
  content: "\f08cc";
}

.mdi-camera-iris::before {
  content: "\f0104";
}

.mdi-camera-marker::before {
  content: "\f19a7";
}

.mdi-camera-marker-outline::before {
  content: "\f19a8";
}

.mdi-camera-metering-center::before {
  content: "\f07a2";
}

.mdi-camera-metering-matrix::before {
  content: "\f07a3";
}

.mdi-camera-metering-partial::before {
  content: "\f07a4";
}

.mdi-camera-metering-spot::before {
  content: "\f07a5";
}

.mdi-camera-off::before {
  content: "\f05df";
}

.mdi-camera-off-outline::before {
  content: "\f19bf";
}

.mdi-camera-outline::before {
  content: "\f0d5d";
}

.mdi-camera-party-mode::before {
  content: "\f0105";
}

.mdi-camera-plus::before {
  content: "\f0edb";
}

.mdi-camera-plus-outline::before {
  content: "\f0edc";
}

.mdi-camera-rear::before {
  content: "\f0106";
}

.mdi-camera-rear-variant::before {
  content: "\f0107";
}

.mdi-camera-retake::before {
  content: "\f0e19";
}

.mdi-camera-retake-outline::before {
  content: "\f0e1a";
}

.mdi-camera-switch::before {
  content: "\f0108";
}

.mdi-camera-switch-outline::before {
  content: "\f084a";
}

.mdi-camera-timer::before {
  content: "\f0109";
}

.mdi-camera-wireless::before {
  content: "\f0db6";
}

.mdi-camera-wireless-outline::before {
  content: "\f0db7";
}

.mdi-campfire::before {
  content: "\f0edd";
}

.mdi-cancel::before {
  content: "\f073a";
}

.mdi-candelabra::before {
  content: "\f17d2";
}

.mdi-candelabra-fire::before {
  content: "\f17d3";
}

.mdi-candle::before {
  content: "\f05e2";
}

.mdi-candy::before {
  content: "\f1970";
}

.mdi-candy-off::before {
  content: "\f1971";
}

.mdi-candy-off-outline::before {
  content: "\f1972";
}

.mdi-candy-outline::before {
  content: "\f1973";
}

.mdi-candycane::before {
  content: "\f010a";
}

.mdi-cannabis::before {
  content: "\f07a6";
}

.mdi-cannabis-off::before {
  content: "\f166e";
}

.mdi-caps-lock::before {
  content: "\f0a9b";
}

.mdi-car::before {
  content: "\f010b";
}

.mdi-car-2-plus::before {
  content: "\f1015";
}

.mdi-car-3-plus::before {
  content: "\f1016";
}

.mdi-car-arrow-left::before {
  content: "\f13b2";
}

.mdi-car-arrow-right::before {
  content: "\f13b3";
}

.mdi-car-back::before {
  content: "\f0e1b";
}

.mdi-car-battery::before {
  content: "\f010c";
}

.mdi-car-brake-abs::before {
  content: "\f0c47";
}

.mdi-car-brake-alert::before {
  content: "\f0c48";
}

.mdi-car-brake-fluid-level::before {
  content: "\f1909";
}

.mdi-car-brake-hold::before {
  content: "\f0d5e";
}

.mdi-car-brake-low-pressure::before {
  content: "\f190a";
}

.mdi-car-brake-parking::before {
  content: "\f0d5f";
}

.mdi-car-brake-retarder::before {
  content: "\f1017";
}

.mdi-car-brake-temperature::before {
  content: "\f190b";
}

.mdi-car-brake-worn-linings::before {
  content: "\f190c";
}

.mdi-car-child-seat::before {
  content: "\f0fa3";
}

.mdi-car-clock::before {
  content: "\f1974";
}

.mdi-car-clutch::before {
  content: "\f1018";
}

.mdi-car-cog::before {
  content: "\f13cc";
}

.mdi-car-connected::before {
  content: "\f010d";
}

.mdi-car-convertible::before {
  content: "\f07a7";
}

.mdi-car-coolant-level::before {
  content: "\f1019";
}

.mdi-car-cruise-control::before {
  content: "\f0d60";
}

.mdi-car-defrost-front::before {
  content: "\f0d61";
}

.mdi-car-defrost-rear::before {
  content: "\f0d62";
}

.mdi-car-door::before {
  content: "\f0b6b";
}

.mdi-car-door-lock::before {
  content: "\f109d";
}

.mdi-car-electric::before {
  content: "\f0b6c";
}

.mdi-car-electric-outline::before {
  content: "\f15b5";
}

.mdi-car-emergency::before {
  content: "\f160f";
}

.mdi-car-esp::before {
  content: "\f0c49";
}

.mdi-car-estate::before {
  content: "\f07a8";
}

.mdi-car-hatchback::before {
  content: "\f07a9";
}

.mdi-car-info::before {
  content: "\f11be";
}

.mdi-car-key::before {
  content: "\f0b6d";
}

.mdi-car-lifted-pickup::before {
  content: "\f152d";
}

.mdi-car-light-alert::before {
  content: "\f190d";
}

.mdi-car-light-dimmed::before {
  content: "\f0c4a";
}

.mdi-car-light-fog::before {
  content: "\f0c4b";
}

.mdi-car-light-high::before {
  content: "\f0c4c";
}

.mdi-car-limousine::before {
  content: "\f08cd";
}

.mdi-car-multiple::before {
  content: "\f0b6e";
}

.mdi-car-off::before {
  content: "\f0e1c";
}

.mdi-car-outline::before {
  content: "\f14ed";
}

.mdi-car-parking-lights::before {
  content: "\f0d63";
}

.mdi-car-pickup::before {
  content: "\f07aa";
}

.mdi-car-seat::before {
  content: "\f0fa4";
}

.mdi-car-seat-cooler::before {
  content: "\f0fa5";
}

.mdi-car-seat-heater::before {
  content: "\f0fa6";
}

.mdi-car-select::before {
  content: "\f1879";
}

.mdi-car-settings::before {
  content: "\f13cd";
}

.mdi-car-shift-pattern::before {
  content: "\f0f40";
}

.mdi-car-side::before {
  content: "\f07ab";
}

.mdi-car-speed-limiter::before {
  content: "\f190e";
}

.mdi-car-sports::before {
  content: "\f07ac";
}

.mdi-car-tire-alert::before {
  content: "\f0c4d";
}

.mdi-car-traction-control::before {
  content: "\f0d64";
}

.mdi-car-turbocharger::before {
  content: "\f101a";
}

.mdi-car-wash::before {
  content: "\f010e";
}

.mdi-car-windshield::before {
  content: "\f101b";
}

.mdi-car-windshield-outline::before {
  content: "\f101c";
}

.mdi-car-wireless::before {
  content: "\f1878";
}

.mdi-car-wrench::before {
  content: "\f1814";
}

.mdi-carabiner::before {
  content: "\f14c0";
}

.mdi-caravan::before {
  content: "\f07ad";
}

.mdi-card::before {
  content: "\f0b6f";
}

.mdi-card-account-details::before {
  content: "\f05d2";
}

.mdi-card-account-details-outline::before {
  content: "\f0dab";
}

.mdi-card-account-details-star::before {
  content: "\f02a3";
}

.mdi-card-account-details-star-outline::before {
  content: "\f06db";
}

.mdi-card-account-mail::before {
  content: "\f018e";
}

.mdi-card-account-mail-outline::before {
  content: "\f0e98";
}

.mdi-card-account-phone::before {
  content: "\f0e99";
}

.mdi-card-account-phone-outline::before {
  content: "\f0e9a";
}

.mdi-card-bulleted::before {
  content: "\f0b70";
}

.mdi-card-bulleted-off::before {
  content: "\f0b71";
}

.mdi-card-bulleted-off-outline::before {
  content: "\f0b72";
}

.mdi-card-bulleted-outline::before {
  content: "\f0b73";
}

.mdi-card-bulleted-settings::before {
  content: "\f0b74";
}

.mdi-card-bulleted-settings-outline::before {
  content: "\f0b75";
}

.mdi-card-minus::before {
  content: "\f1600";
}

.mdi-card-minus-outline::before {
  content: "\f1601";
}

.mdi-card-multiple::before {
  content: "\f17f1";
}

.mdi-card-multiple-outline::before {
  content: "\f17f2";
}

.mdi-card-off::before {
  content: "\f1602";
}

.mdi-card-off-outline::before {
  content: "\f1603";
}

.mdi-card-outline::before {
  content: "\f0b76";
}

.mdi-card-plus::before {
  content: "\f11ff";
}

.mdi-card-plus-outline::before {
  content: "\f1200";
}

.mdi-card-remove::before {
  content: "\f1604";
}

.mdi-card-remove-outline::before {
  content: "\f1605";
}

.mdi-card-search::before {
  content: "\f1074";
}

.mdi-card-search-outline::before {
  content: "\f1075";
}

.mdi-card-text::before {
  content: "\f0b77";
}

.mdi-card-text-outline::before {
  content: "\f0b78";
}

.mdi-cards::before {
  content: "\f0638";
}

.mdi-cards-club::before {
  content: "\f08ce";
}

.mdi-cards-club-outline::before {
  content: "\f189f";
}

.mdi-cards-diamond::before {
  content: "\f08cf";
}

.mdi-cards-diamond-outline::before {
  content: "\f101d";
}

.mdi-cards-heart::before {
  content: "\f08d0";
}

.mdi-cards-heart-outline::before {
  content: "\f18a0";
}

.mdi-cards-outline::before {
  content: "\f0639";
}

.mdi-cards-playing::before {
  content: "\f18a1";
}

.mdi-cards-playing-club::before {
  content: "\f18a2";
}

.mdi-cards-playing-club-multiple::before {
  content: "\f18a3";
}

.mdi-cards-playing-club-multiple-outline::before {
  content: "\f18a4";
}

.mdi-cards-playing-club-outline::before {
  content: "\f18a5";
}

.mdi-cards-playing-diamond::before {
  content: "\f18a6";
}

.mdi-cards-playing-diamond-multiple::before {
  content: "\f18a7";
}

.mdi-cards-playing-diamond-multiple-outline::before {
  content: "\f18a8";
}

.mdi-cards-playing-diamond-outline::before {
  content: "\f18a9";
}

.mdi-cards-playing-heart::before {
  content: "\f18aa";
}

.mdi-cards-playing-heart-multiple::before {
  content: "\f18ab";
}

.mdi-cards-playing-heart-multiple-outline::before {
  content: "\f18ac";
}

.mdi-cards-playing-heart-outline::before {
  content: "\f18ad";
}

.mdi-cards-playing-outline::before {
  content: "\f063a";
}

.mdi-cards-playing-spade::before {
  content: "\f18ae";
}

.mdi-cards-playing-spade-multiple::before {
  content: "\f18af";
}

.mdi-cards-playing-spade-multiple-outline::before {
  content: "\f18b0";
}

.mdi-cards-playing-spade-outline::before {
  content: "\f18b1";
}

.mdi-cards-spade::before {
  content: "\f08d1";
}

.mdi-cards-spade-outline::before {
  content: "\f18b2";
}

.mdi-cards-variant::before {
  content: "\f06c7";
}

.mdi-carrot::before {
  content: "\f010f";
}

.mdi-cart::before {
  content: "\f0110";
}

.mdi-cart-arrow-down::before {
  content: "\f0d66";
}

.mdi-cart-arrow-right::before {
  content: "\f0c4e";
}

.mdi-cart-arrow-up::before {
  content: "\f0d67";
}

.mdi-cart-check::before {
  content: "\f15ea";
}

.mdi-cart-heart::before {
  content: "\f18e0";
}

.mdi-cart-minus::before {
  content: "\f0d68";
}

.mdi-cart-off::before {
  content: "\f066b";
}

.mdi-cart-outline::before {
  content: "\f0111";
}

.mdi-cart-plus::before {
  content: "\f0112";
}

.mdi-cart-remove::before {
  content: "\f0d69";
}

.mdi-cart-variant::before {
  content: "\f15eb";
}

.mdi-case-sensitive-alt::before {
  content: "\f0113";
}

.mdi-cash::before {
  content: "\f0114";
}

.mdi-cash-100::before {
  content: "\f0115";
}

.mdi-cash-check::before {
  content: "\f14ee";
}

.mdi-cash-fast::before {
  content: "\f185c";
}

.mdi-cash-lock::before {
  content: "\f14ea";
}

.mdi-cash-lock-open::before {
  content: "\f14eb";
}

.mdi-cash-marker::before {
  content: "\f0db8";
}

.mdi-cash-minus::before {
  content: "\f1260";
}

.mdi-cash-multiple::before {
  content: "\f0116";
}

.mdi-cash-plus::before {
  content: "\f1261";
}

.mdi-cash-refund::before {
  content: "\f0a9c";
}

.mdi-cash-register::before {
  content: "\f0cf4";
}

.mdi-cash-remove::before {
  content: "\f1262";
}

.mdi-cassette::before {
  content: "\f09d4";
}

.mdi-cast::before {
  content: "\f0118";
}

.mdi-cast-audio::before {
  content: "\f101e";
}

.mdi-cast-audio-variant::before {
  content: "\f1749";
}

.mdi-cast-connected::before {
  content: "\f0119";
}

.mdi-cast-education::before {
  content: "\f0e1d";
}

.mdi-cast-off::before {
  content: "\f078a";
}

.mdi-cast-variant::before {
  content: "\f001f";
}

.mdi-castle::before {
  content: "\f011a";
}

.mdi-cat::before {
  content: "\f011b";
}

.mdi-cctv::before {
  content: "\f07ae";
}

.mdi-cctv-off::before {
  content: "\f185f";
}

.mdi-ceiling-fan::before {
  content: "\f1797";
}

.mdi-ceiling-fan-light::before {
  content: "\f1798";
}

.mdi-ceiling-light::before {
  content: "\f0769";
}

.mdi-ceiling-light-multiple::before {
  content: "\f18dd";
}

.mdi-ceiling-light-multiple-outline::before {
  content: "\f18de";
}

.mdi-ceiling-light-outline::before {
  content: "\f17c7";
}

.mdi-cellphone::before {
  content: "\f011c";
}

.mdi-cellphone-arrow-down::before {
  content: "\f09d5";
}

.mdi-cellphone-basic::before {
  content: "\f011e";
}

.mdi-cellphone-charging::before {
  content: "\f1397";
}

.mdi-cellphone-check::before {
  content: "\f17fd";
}

.mdi-cellphone-cog::before {
  content: "\f0951";
}

.mdi-cellphone-dock::before {
  content: "\f011f";
}

.mdi-cellphone-information::before {
  content: "\f0f41";
}

.mdi-cellphone-key::before {
  content: "\f094e";
}

.mdi-cellphone-link::before {
  content: "\f0121";
}

.mdi-cellphone-link-off::before {
  content: "\f0122";
}

.mdi-cellphone-lock::before {
  content: "\f094f";
}

.mdi-cellphone-marker::before {
  content: "\f183a";
}

.mdi-cellphone-message::before {
  content: "\f08d3";
}

.mdi-cellphone-message-off::before {
  content: "\f10d2";
}

.mdi-cellphone-nfc::before {
  content: "\f0e90";
}

.mdi-cellphone-nfc-off::before {
  content: "\f12d8";
}

.mdi-cellphone-off::before {
  content: "\f0950";
}

.mdi-cellphone-play::before {
  content: "\f101f";
}

.mdi-cellphone-remove::before {
  content: "\f094d";
}

.mdi-cellphone-screenshot::before {
  content: "\f0a35";
}

.mdi-cellphone-settings::before {
  content: "\f0123";
}

.mdi-cellphone-sound::before {
  content: "\f0952";
}

.mdi-cellphone-text::before {
  content: "\f08d2";
}

.mdi-cellphone-wireless::before {
  content: "\f0815";
}

.mdi-centos::before {
  content: "\f111a";
}

.mdi-certificate::before {
  content: "\f0124";
}

.mdi-certificate-outline::before {
  content: "\f1188";
}

.mdi-chair-rolling::before {
  content: "\f0f48";
}

.mdi-chair-school::before {
  content: "\f0125";
}

.mdi-chandelier::before {
  content: "\f1793";
}

.mdi-charity::before {
  content: "\f0c4f";
}

.mdi-chart-arc::before {
  content: "\f0126";
}

.mdi-chart-areaspline::before {
  content: "\f0127";
}

.mdi-chart-areaspline-variant::before {
  content: "\f0e91";
}

.mdi-chart-bar::before {
  content: "\f0128";
}

.mdi-chart-bar-stacked::before {
  content: "\f076a";
}

.mdi-chart-bell-curve::before {
  content: "\f0c50";
}

.mdi-chart-bell-curve-cumulative::before {
  content: "\f0fa7";
}

.mdi-chart-box::before {
  content: "\f154d";
}

.mdi-chart-box-outline::before {
  content: "\f154e";
}

.mdi-chart-box-plus-outline::before {
  content: "\f154f";
}

.mdi-chart-bubble::before {
  content: "\f05e3";
}

.mdi-chart-donut::before {
  content: "\f07af";
}

.mdi-chart-donut-variant::before {
  content: "\f07b0";
}

.mdi-chart-gantt::before {
  content: "\f066c";
}

.mdi-chart-histogram::before {
  content: "\f0129";
}

.mdi-chart-line::before {
  content: "\f012a";
}

.mdi-chart-line-stacked::before {
  content: "\f076b";
}

.mdi-chart-line-variant::before {
  content: "\f07b1";
}

.mdi-chart-multiline::before {
  content: "\f08d4";
}

.mdi-chart-multiple::before {
  content: "\f1213";
}

.mdi-chart-pie::before {
  content: "\f012b";
}

.mdi-chart-ppf::before {
  content: "\f1380";
}

.mdi-chart-sankey::before {
  content: "\f11df";
}

.mdi-chart-sankey-variant::before {
  content: "\f11e0";
}

.mdi-chart-scatter-plot::before {
  content: "\f0e92";
}

.mdi-chart-scatter-plot-hexbin::before {
  content: "\f066d";
}

.mdi-chart-timeline::before {
  content: "\f066e";
}

.mdi-chart-timeline-variant::before {
  content: "\f0e93";
}

.mdi-chart-timeline-variant-shimmer::before {
  content: "\f15b6";
}

.mdi-chart-tree::before {
  content: "\f0e94";
}

.mdi-chart-waterfall::before {
  content: "\f1918";
}

.mdi-chat::before {
  content: "\f0b79";
}

.mdi-chat-alert::before {
  content: "\f0b7a";
}

.mdi-chat-alert-outline::before {
  content: "\f12c9";
}

.mdi-chat-minus::before {
  content: "\f1410";
}

.mdi-chat-minus-outline::before {
  content: "\f1413";
}

.mdi-chat-outline::before {
  content: "\f0ede";
}

.mdi-chat-plus::before {
  content: "\f140f";
}

.mdi-chat-plus-outline::before {
  content: "\f1412";
}

.mdi-chat-processing::before {
  content: "\f0b7b";
}

.mdi-chat-processing-outline::before {
  content: "\f12ca";
}

.mdi-chat-question::before {
  content: "\f1738";
}

.mdi-chat-question-outline::before {
  content: "\f1739";
}

.mdi-chat-remove::before {
  content: "\f1411";
}

.mdi-chat-remove-outline::before {
  content: "\f1414";
}

.mdi-chat-sleep::before {
  content: "\f12d1";
}

.mdi-chat-sleep-outline::before {
  content: "\f12d2";
}

.mdi-check::before {
  content: "\f012c";
}

.mdi-check-all::before {
  content: "\f012d";
}

.mdi-check-bold::before {
  content: "\f0e1e";
}

.mdi-check-circle::before {
  content: "\f05e0";
}

.mdi-check-circle-outline::before {
  content: "\f05e1";
}

.mdi-check-decagram::before {
  content: "\f0791";
}

.mdi-check-decagram-outline::before {
  content: "\f1740";
}

.mdi-check-network::before {
  content: "\f0c53";
}

.mdi-check-network-outline::before {
  content: "\f0c54";
}

.mdi-check-outline::before {
  content: "\f0855";
}

.mdi-check-underline::before {
  content: "\f0e1f";
}

.mdi-check-underline-circle::before {
  content: "\f0e20";
}

.mdi-check-underline-circle-outline::before {
  content: "\f0e21";
}

.mdi-checkbook::before {
  content: "\f0a9d";
}

.mdi-checkbox-blank::before {
  content: "\f012e";
}

.mdi-checkbox-blank-badge::before {
  content: "\f1176";
}

.mdi-checkbox-blank-badge-outline::before {
  content: "\f0117";
}

.mdi-checkbox-blank-circle::before {
  content: "\f012f";
}

.mdi-checkbox-blank-circle-outline::before {
  content: "\f0130";
}

.mdi-checkbox-blank-off::before {
  content: "\f12ec";
}

.mdi-checkbox-blank-off-outline::before {
  content: "\f12ed";
}

.mdi-checkbox-blank-outline::before {
  content: "\f0131";
}

.mdi-checkbox-intermediate::before {
  content: "\f0856";
}

.mdi-checkbox-marked::before {
  content: "\f0132";
}

.mdi-checkbox-marked-circle::before {
  content: "\f0133";
}

.mdi-checkbox-marked-circle-outline::before {
  content: "\f0134";
}

.mdi-checkbox-marked-circle-plus-outline::before {
  content: "\f1927";
}

.mdi-checkbox-marked-outline::before {
  content: "\f0135";
}

.mdi-checkbox-multiple-blank::before {
  content: "\f0136";
}

.mdi-checkbox-multiple-blank-circle::before {
  content: "\f063b";
}

.mdi-checkbox-multiple-blank-circle-outline::before {
  content: "\f063c";
}

.mdi-checkbox-multiple-blank-outline::before {
  content: "\f0137";
}

.mdi-checkbox-multiple-marked::before {
  content: "\f0138";
}

.mdi-checkbox-multiple-marked-circle::before {
  content: "\f063d";
}

.mdi-checkbox-multiple-marked-circle-outline::before {
  content: "\f063e";
}

.mdi-checkbox-multiple-marked-outline::before {
  content: "\f0139";
}

.mdi-checkbox-multiple-outline::before {
  content: "\f0c51";
}

.mdi-checkbox-outline::before {
  content: "\f0c52";
}

.mdi-checkerboard::before {
  content: "\f013a";
}

.mdi-checkerboard-minus::before {
  content: "\f1202";
}

.mdi-checkerboard-plus::before {
  content: "\f1201";
}

.mdi-checkerboard-remove::before {
  content: "\f1203";
}

.mdi-cheese::before {
  content: "\f12b9";
}

.mdi-cheese-off::before {
  content: "\f13ee";
}

.mdi-chef-hat::before {
  content: "\f0b7c";
}

.mdi-chemical-weapon::before {
  content: "\f013b";
}

.mdi-chess-bishop::before {
  content: "\f085c";
}

.mdi-chess-king::before {
  content: "\f0857";
}

.mdi-chess-knight::before {
  content: "\f0858";
}

.mdi-chess-pawn::before {
  content: "\f0859";
}

.mdi-chess-queen::before {
  content: "\f085a";
}

.mdi-chess-rook::before {
  content: "\f085b";
}

.mdi-chevron-double-down::before {
  content: "\f013c";
}

.mdi-chevron-double-left::before {
  content: "\f013d";
}

.mdi-chevron-double-right::before {
  content: "\f013e";
}

.mdi-chevron-double-up::before {
  content: "\f013f";
}

.mdi-chevron-down::before {
  content: "\f0140";
}

.mdi-chevron-down-box::before {
  content: "\f09d6";
}

.mdi-chevron-down-box-outline::before {
  content: "\f09d7";
}

.mdi-chevron-down-circle::before {
  content: "\f0b26";
}

.mdi-chevron-down-circle-outline::before {
  content: "\f0b27";
}

.mdi-chevron-left::before {
  content: "\f0141";
}

.mdi-chevron-left-box::before {
  content: "\f09d8";
}

.mdi-chevron-left-box-outline::before {
  content: "\f09d9";
}

.mdi-chevron-left-circle::before {
  content: "\f0b28";
}

.mdi-chevron-left-circle-outline::before {
  content: "\f0b29";
}

.mdi-chevron-right::before {
  content: "\f0142";
}

.mdi-chevron-right-box::before {
  content: "\f09da";
}

.mdi-chevron-right-box-outline::before {
  content: "\f09db";
}

.mdi-chevron-right-circle::before {
  content: "\f0b2a";
}

.mdi-chevron-right-circle-outline::before {
  content: "\f0b2b";
}

.mdi-chevron-triple-down::before {
  content: "\f0db9";
}

.mdi-chevron-triple-left::before {
  content: "\f0dba";
}

.mdi-chevron-triple-right::before {
  content: "\f0dbb";
}

.mdi-chevron-triple-up::before {
  content: "\f0dbc";
}

.mdi-chevron-up::before {
  content: "\f0143";
}

.mdi-chevron-up-box::before {
  content: "\f09dc";
}

.mdi-chevron-up-box-outline::before {
  content: "\f09dd";
}

.mdi-chevron-up-circle::before {
  content: "\f0b2c";
}

.mdi-chevron-up-circle-outline::before {
  content: "\f0b2d";
}

.mdi-chili-alert::before {
  content: "\f17ea";
}

.mdi-chili-alert-outline::before {
  content: "\f17eb";
}

.mdi-chili-hot::before {
  content: "\f07b2";
}

.mdi-chili-hot-outline::before {
  content: "\f17ec";
}

.mdi-chili-medium::before {
  content: "\f07b3";
}

.mdi-chili-medium-outline::before {
  content: "\f17ed";
}

.mdi-chili-mild::before {
  content: "\f07b4";
}

.mdi-chili-mild-outline::before {
  content: "\f17ee";
}

.mdi-chili-off::before {
  content: "\f1467";
}

.mdi-chili-off-outline::before {
  content: "\f17ef";
}

.mdi-chip::before {
  content: "\f061a";
}

.mdi-church::before {
  content: "\f0144";
}

.mdi-cigar::before {
  content: "\f1189";
}

.mdi-cigar-off::before {
  content: "\f141b";
}

.mdi-circle::before {
  content: "\f0765";
}

.mdi-circle-box::before {
  content: "\f15dc";
}

.mdi-circle-box-outline::before {
  content: "\f15dd";
}

.mdi-circle-double::before {
  content: "\f0e95";
}

.mdi-circle-edit-outline::before {
  content: "\f08d5";
}

.mdi-circle-expand::before {
  content: "\f0e96";
}

.mdi-circle-half::before {
  content: "\f1395";
}

.mdi-circle-half-full::before {
  content: "\f1396";
}

.mdi-circle-medium::before {
  content: "\f09de";
}

.mdi-circle-multiple::before {
  content: "\f0b38";
}

.mdi-circle-multiple-outline::before {
  content: "\f0695";
}

.mdi-circle-off-outline::before {
  content: "\f10d3";
}

.mdi-circle-opacity::before {
  content: "\f1853";
}

.mdi-circle-outline::before {
  content: "\f0766";
}

.mdi-circle-slice-1::before {
  content: "\f0a9e";
}

.mdi-circle-slice-2::before {
  content: "\f0a9f";
}

.mdi-circle-slice-3::before {
  content: "\f0aa0";
}

.mdi-circle-slice-4::before {
  content: "\f0aa1";
}

.mdi-circle-slice-5::before {
  content: "\f0aa2";
}

.mdi-circle-slice-6::before {
  content: "\f0aa3";
}

.mdi-circle-slice-7::before {
  content: "\f0aa4";
}

.mdi-circle-slice-8::before {
  content: "\f0aa5";
}

.mdi-circle-small::before {
  content: "\f09df";
}

.mdi-circular-saw::before {
  content: "\f0e22";
}

.mdi-city::before {
  content: "\f0146";
}

.mdi-city-variant::before {
  content: "\f0a36";
}

.mdi-city-variant-outline::before {
  content: "\f0a37";
}

.mdi-clipboard::before {
  content: "\f0147";
}

.mdi-clipboard-account::before {
  content: "\f0148";
}

.mdi-clipboard-account-outline::before {
  content: "\f0c55";
}

.mdi-clipboard-alert::before {
  content: "\f0149";
}

.mdi-clipboard-alert-outline::before {
  content: "\f0cf7";
}

.mdi-clipboard-arrow-down::before {
  content: "\f014a";
}

.mdi-clipboard-arrow-down-outline::before {
  content: "\f0c56";
}

.mdi-clipboard-arrow-left::before {
  content: "\f014b";
}

.mdi-clipboard-arrow-left-outline::before {
  content: "\f0cf8";
}

.mdi-clipboard-arrow-right::before {
  content: "\f0cf9";
}

.mdi-clipboard-arrow-right-outline::before {
  content: "\f0cfa";
}

.mdi-clipboard-arrow-up::before {
  content: "\f0c57";
}

.mdi-clipboard-arrow-up-outline::before {
  content: "\f0c58";
}

.mdi-clipboard-check::before {
  content: "\f014e";
}

.mdi-clipboard-check-multiple::before {
  content: "\f1263";
}

.mdi-clipboard-check-multiple-outline::before {
  content: "\f1264";
}

.mdi-clipboard-check-outline::before {
  content: "\f08a8";
}

.mdi-clipboard-clock::before {
  content: "\f16e2";
}

.mdi-clipboard-clock-outline::before {
  content: "\f16e3";
}

.mdi-clipboard-edit::before {
  content: "\f14e5";
}

.mdi-clipboard-edit-outline::before {
  content: "\f14e6";
}

.mdi-clipboard-file::before {
  content: "\f1265";
}

.mdi-clipboard-file-outline::before {
  content: "\f1266";
}

.mdi-clipboard-flow::before {
  content: "\f06c8";
}

.mdi-clipboard-flow-outline::before {
  content: "\f1117";
}

.mdi-clipboard-list::before {
  content: "\f10d4";
}

.mdi-clipboard-list-outline::before {
  content: "\f10d5";
}

.mdi-clipboard-minus::before {
  content: "\f1618";
}

.mdi-clipboard-minus-outline::before {
  content: "\f1619";
}

.mdi-clipboard-multiple::before {
  content: "\f1267";
}

.mdi-clipboard-multiple-outline::before {
  content: "\f1268";
}

.mdi-clipboard-off::before {
  content: "\f161a";
}

.mdi-clipboard-off-outline::before {
  content: "\f161b";
}

.mdi-clipboard-outline::before {
  content: "\f014c";
}

.mdi-clipboard-play::before {
  content: "\f0c59";
}

.mdi-clipboard-play-multiple::before {
  content: "\f1269";
}

.mdi-clipboard-play-multiple-outline::before {
  content: "\f126a";
}

.mdi-clipboard-play-outline::before {
  content: "\f0c5a";
}

.mdi-clipboard-plus::before {
  content: "\f0751";
}

.mdi-clipboard-plus-outline::before {
  content: "\f131f";
}

.mdi-clipboard-pulse::before {
  content: "\f085d";
}

.mdi-clipboard-pulse-outline::before {
  content: "\f085e";
}

.mdi-clipboard-remove::before {
  content: "\f161c";
}

.mdi-clipboard-remove-outline::before {
  content: "\f161d";
}

.mdi-clipboard-search::before {
  content: "\f161e";
}

.mdi-clipboard-search-outline::before {
  content: "\f161f";
}

.mdi-clipboard-text::before {
  content: "\f014d";
}

.mdi-clipboard-text-clock::before {
  content: "\f18f9";
}

.mdi-clipboard-text-clock-outline::before {
  content: "\f18fa";
}

.mdi-clipboard-text-multiple::before {
  content: "\f126b";
}

.mdi-clipboard-text-multiple-outline::before {
  content: "\f126c";
}

.mdi-clipboard-text-off::before {
  content: "\f1620";
}

.mdi-clipboard-text-off-outline::before {
  content: "\f1621";
}

.mdi-clipboard-text-outline::before {
  content: "\f0a38";
}

.mdi-clipboard-text-play::before {
  content: "\f0c5b";
}

.mdi-clipboard-text-play-outline::before {
  content: "\f0c5c";
}

.mdi-clipboard-text-search::before {
  content: "\f1622";
}

.mdi-clipboard-text-search-outline::before {
  content: "\f1623";
}

.mdi-clippy::before {
  content: "\f014f";
}

.mdi-clock::before {
  content: "\f0954";
}

.mdi-clock-alert::before {
  content: "\f0955";
}

.mdi-clock-alert-outline::before {
  content: "\f05ce";
}

.mdi-clock-check::before {
  content: "\f0fa8";
}

.mdi-clock-check-outline::before {
  content: "\f0fa9";
}

.mdi-clock-digital::before {
  content: "\f0e97";
}

.mdi-clock-edit::before {
  content: "\f19ba";
}

.mdi-clock-edit-outline::before {
  content: "\f19bb";
}

.mdi-clock-end::before {
  content: "\f0151";
}

.mdi-clock-fast::before {
  content: "\f0152";
}

.mdi-clock-in::before {
  content: "\f0153";
}

.mdi-clock-minus::before {
  content: "\f1863";
}

.mdi-clock-minus-outline::before {
  content: "\f1864";
}

.mdi-clock-out::before {
  content: "\f0154";
}

.mdi-clock-outline::before {
  content: "\f0150";
}

.mdi-clock-plus::before {
  content: "\f1861";
}

.mdi-clock-plus-outline::before {
  content: "\f1862";
}

.mdi-clock-remove::before {
  content: "\f1865";
}

.mdi-clock-remove-outline::before {
  content: "\f1866";
}

.mdi-clock-start::before {
  content: "\f0155";
}

.mdi-clock-time-eight::before {
  content: "\f1446";
}

.mdi-clock-time-eight-outline::before {
  content: "\f1452";
}

.mdi-clock-time-eleven::before {
  content: "\f1449";
}

.mdi-clock-time-eleven-outline::before {
  content: "\f1455";
}

.mdi-clock-time-five::before {
  content: "\f1443";
}

.mdi-clock-time-five-outline::before {
  content: "\f144f";
}

.mdi-clock-time-four::before {
  content: "\f1442";
}

.mdi-clock-time-four-outline::before {
  content: "\f144e";
}

.mdi-clock-time-nine::before {
  content: "\f1447";
}

.mdi-clock-time-nine-outline::before {
  content: "\f1453";
}

.mdi-clock-time-one::before {
  content: "\f143f";
}

.mdi-clock-time-one-outline::before {
  content: "\f144b";
}

.mdi-clock-time-seven::before {
  content: "\f1445";
}

.mdi-clock-time-seven-outline::before {
  content: "\f1451";
}

.mdi-clock-time-six::before {
  content: "\f1444";
}

.mdi-clock-time-six-outline::before {
  content: "\f1450";
}

.mdi-clock-time-ten::before {
  content: "\f1448";
}

.mdi-clock-time-ten-outline::before {
  content: "\f1454";
}

.mdi-clock-time-three::before {
  content: "\f1441";
}

.mdi-clock-time-three-outline::before {
  content: "\f144d";
}

.mdi-clock-time-twelve::before {
  content: "\f144a";
}

.mdi-clock-time-twelve-outline::before {
  content: "\f1456";
}

.mdi-clock-time-two::before {
  content: "\f1440";
}

.mdi-clock-time-two-outline::before {
  content: "\f144c";
}

.mdi-close::before {
  content: "\f0156";
}

.mdi-close-box::before {
  content: "\f0157";
}

.mdi-close-box-multiple::before {
  content: "\f0c5d";
}

.mdi-close-box-multiple-outline::before {
  content: "\f0c5e";
}

.mdi-close-box-outline::before {
  content: "\f0158";
}

.mdi-close-circle::before {
  content: "\f0159";
}

.mdi-close-circle-multiple::before {
  content: "\f062a";
}

.mdi-close-circle-multiple-outline::before {
  content: "\f0883";
}

.mdi-close-circle-outline::before {
  content: "\f015a";
}

.mdi-close-network::before {
  content: "\f015b";
}

.mdi-close-network-outline::before {
  content: "\f0c5f";
}

.mdi-close-octagon::before {
  content: "\f015c";
}

.mdi-close-octagon-outline::before {
  content: "\f015d";
}

.mdi-close-outline::before {
  content: "\f06c9";
}

.mdi-close-thick::before {
  content: "\f1398";
}

.mdi-closed-caption::before {
  content: "\f015e";
}

.mdi-closed-caption-outline::before {
  content: "\f0dbd";
}

.mdi-cloud::before {
  content: "\f015f";
}

.mdi-cloud-alert::before {
  content: "\f09e0";
}

.mdi-cloud-braces::before {
  content: "\f07b5";
}

.mdi-cloud-check::before {
  content: "\f0160";
}

.mdi-cloud-check-outline::before {
  content: "\f12cc";
}

.mdi-cloud-circle::before {
  content: "\f0161";
}

.mdi-cloud-download::before {
  content: "\f0162";
}

.mdi-cloud-download-outline::before {
  content: "\f0b7d";
}

.mdi-cloud-lock::before {
  content: "\f11f1";
}

.mdi-cloud-lock-outline::before {
  content: "\f11f2";
}

.mdi-cloud-off-outline::before {
  content: "\f0164";
}

.mdi-cloud-outline::before {
  content: "\f0163";
}

.mdi-cloud-print::before {
  content: "\f0165";
}

.mdi-cloud-print-outline::before {
  content: "\f0166";
}

.mdi-cloud-question::before {
  content: "\f0a39";
}

.mdi-cloud-refresh::before {
  content: "\f052a";
}

.mdi-cloud-search::before {
  content: "\f0956";
}

.mdi-cloud-search-outline::before {
  content: "\f0957";
}

.mdi-cloud-sync::before {
  content: "\f063f";
}

.mdi-cloud-sync-outline::before {
  content: "\f12d6";
}

.mdi-cloud-tags::before {
  content: "\f07b6";
}

.mdi-cloud-upload::before {
  content: "\f0167";
}

.mdi-cloud-upload-outline::before {
  content: "\f0b7e";
}

.mdi-clover::before {
  content: "\f0816";
}

.mdi-coach-lamp::before {
  content: "\f1020";
}

.mdi-coat-rack::before {
  content: "\f109e";
}

.mdi-code-array::before {
  content: "\f0168";
}

.mdi-code-braces::before {
  content: "\f0169";
}

.mdi-code-braces-box::before {
  content: "\f10d6";
}

.mdi-code-brackets::before {
  content: "\f016a";
}

.mdi-code-equal::before {
  content: "\f016b";
}

.mdi-code-greater-than::before {
  content: "\f016c";
}

.mdi-code-greater-than-or-equal::before {
  content: "\f016d";
}

.mdi-code-json::before {
  content: "\f0626";
}

.mdi-code-less-than::before {
  content: "\f016e";
}

.mdi-code-less-than-or-equal::before {
  content: "\f016f";
}

.mdi-code-not-equal::before {
  content: "\f0170";
}

.mdi-code-not-equal-variant::before {
  content: "\f0171";
}

.mdi-code-parentheses::before {
  content: "\f0172";
}

.mdi-code-parentheses-box::before {
  content: "\f10d7";
}

.mdi-code-string::before {
  content: "\f0173";
}

.mdi-code-tags::before {
  content: "\f0174";
}

.mdi-code-tags-check::before {
  content: "\f0694";
}

.mdi-codepen::before {
  content: "\f0175";
}

.mdi-coffee::before {
  content: "\f0176";
}

.mdi-coffee-maker::before {
  content: "\f109f";
}

.mdi-coffee-maker-check::before {
  content: "\f1931";
}

.mdi-coffee-maker-check-outline::before {
  content: "\f1932";
}

.mdi-coffee-maker-outline::before {
  content: "\f181b";
}

.mdi-coffee-off::before {
  content: "\f0faa";
}

.mdi-coffee-off-outline::before {
  content: "\f0fab";
}

.mdi-coffee-outline::before {
  content: "\f06ca";
}

.mdi-coffee-to-go::before {
  content: "\f0177";
}

.mdi-coffee-to-go-outline::before {
  content: "\f130e";
}

.mdi-coffin::before {
  content: "\f0b7f";
}

.mdi-cog::before {
  content: "\f0493";
}

.mdi-cog-box::before {
  content: "\f0494";
}

.mdi-cog-clockwise::before {
  content: "\f11dd";
}

.mdi-cog-counterclockwise::before {
  content: "\f11de";
}

.mdi-cog-off::before {
  content: "\f13ce";
}

.mdi-cog-off-outline::before {
  content: "\f13cf";
}

.mdi-cog-outline::before {
  content: "\f08bb";
}

.mdi-cog-pause::before {
  content: "\f1933";
}

.mdi-cog-pause-outline::before {
  content: "\f1934";
}

.mdi-cog-play::before {
  content: "\f1935";
}

.mdi-cog-play-outline::before {
  content: "\f1936";
}

.mdi-cog-refresh::before {
  content: "\f145e";
}

.mdi-cog-refresh-outline::before {
  content: "\f145f";
}

.mdi-cog-stop::before {
  content: "\f1937";
}

.mdi-cog-stop-outline::before {
  content: "\f1938";
}

.mdi-cog-sync::before {
  content: "\f1460";
}

.mdi-cog-sync-outline::before {
  content: "\f1461";
}

.mdi-cog-transfer::before {
  content: "\f105b";
}

.mdi-cog-transfer-outline::before {
  content: "\f105c";
}

.mdi-cogs::before {
  content: "\f08d6";
}

.mdi-collage::before {
  content: "\f0640";
}

.mdi-collapse-all::before {
  content: "\f0aa6";
}

.mdi-collapse-all-outline::before {
  content: "\f0aa7";
}

.mdi-color-helper::before {
  content: "\f0179";
}

.mdi-comma::before {
  content: "\f0e23";
}

.mdi-comma-box::before {
  content: "\f0e2b";
}

.mdi-comma-box-outline::before {
  content: "\f0e24";
}

.mdi-comma-circle::before {
  content: "\f0e25";
}

.mdi-comma-circle-outline::before {
  content: "\f0e26";
}

.mdi-comment::before {
  content: "\f017a";
}

.mdi-comment-account::before {
  content: "\f017b";
}

.mdi-comment-account-outline::before {
  content: "\f017c";
}

.mdi-comment-alert::before {
  content: "\f017d";
}

.mdi-comment-alert-outline::before {
  content: "\f017e";
}

.mdi-comment-arrow-left::before {
  content: "\f09e1";
}

.mdi-comment-arrow-left-outline::before {
  content: "\f09e2";
}

.mdi-comment-arrow-right::before {
  content: "\f09e3";
}

.mdi-comment-arrow-right-outline::before {
  content: "\f09e4";
}

.mdi-comment-bookmark::before {
  content: "\f15ae";
}

.mdi-comment-bookmark-outline::before {
  content: "\f15af";
}

.mdi-comment-check::before {
  content: "\f017f";
}

.mdi-comment-check-outline::before {
  content: "\f0180";
}

.mdi-comment-edit::before {
  content: "\f11bf";
}

.mdi-comment-edit-outline::before {
  content: "\f12c4";
}

.mdi-comment-eye::before {
  content: "\f0a3a";
}

.mdi-comment-eye-outline::before {
  content: "\f0a3b";
}

.mdi-comment-flash::before {
  content: "\f15b0";
}

.mdi-comment-flash-outline::before {
  content: "\f15b1";
}

.mdi-comment-minus::before {
  content: "\f15df";
}

.mdi-comment-minus-outline::before {
  content: "\f15e0";
}

.mdi-comment-multiple::before {
  content: "\f085f";
}

.mdi-comment-multiple-outline::before {
  content: "\f0181";
}

.mdi-comment-off::before {
  content: "\f15e1";
}

.mdi-comment-off-outline::before {
  content: "\f15e2";
}

.mdi-comment-outline::before {
  content: "\f0182";
}

.mdi-comment-plus::before {
  content: "\f09e5";
}

.mdi-comment-plus-outline::before {
  content: "\f0183";
}

.mdi-comment-processing::before {
  content: "\f0184";
}

.mdi-comment-processing-outline::before {
  content: "\f0185";
}

.mdi-comment-question::before {
  content: "\f0817";
}

.mdi-comment-question-outline::before {
  content: "\f0186";
}

.mdi-comment-quote::before {
  content: "\f1021";
}

.mdi-comment-quote-outline::before {
  content: "\f1022";
}

.mdi-comment-remove::before {
  content: "\f05de";
}

.mdi-comment-remove-outline::before {
  content: "\f0187";
}

.mdi-comment-search::before {
  content: "\f0a3c";
}

.mdi-comment-search-outline::before {
  content: "\f0a3d";
}

.mdi-comment-text::before {
  content: "\f0188";
}

.mdi-comment-text-multiple::before {
  content: "\f0860";
}

.mdi-comment-text-multiple-outline::before {
  content: "\f0861";
}

.mdi-comment-text-outline::before {
  content: "\f0189";
}

.mdi-compare::before {
  content: "\f018a";
}

.mdi-compare-horizontal::before {
  content: "\f1492";
}

.mdi-compare-remove::before {
  content: "\f18b3";
}

.mdi-compare-vertical::before {
  content: "\f1493";
}

.mdi-compass::before {
  content: "\f018b";
}

.mdi-compass-off::before {
  content: "\f0b80";
}

.mdi-compass-off-outline::before {
  content: "\f0b81";
}

.mdi-compass-outline::before {
  content: "\f018c";
}

.mdi-compass-rose::before {
  content: "\f1382";
}

.mdi-cone::before {
  content: "\f194c";
}

.mdi-cone-off::before {
  content: "\f194d";
}

.mdi-connection::before {
  content: "\f1616";
}

.mdi-console::before {
  content: "\f018d";
}

.mdi-console-line::before {
  content: "\f07b7";
}

.mdi-console-network::before {
  content: "\f08a9";
}

.mdi-console-network-outline::before {
  content: "\f0c60";
}

.mdi-consolidate::before {
  content: "\f10d8";
}

.mdi-contactless-payment::before {
  content: "\f0d6a";
}

.mdi-contactless-payment-circle::before {
  content: "\f0321";
}

.mdi-contactless-payment-circle-outline::before {
  content: "\f0408";
}

.mdi-contacts::before {
  content: "\f06cb";
}

.mdi-contacts-outline::before {
  content: "\f05b8";
}

.mdi-contain::before {
  content: "\f0a3e";
}

.mdi-contain-end::before {
  content: "\f0a3f";
}

.mdi-contain-start::before {
  content: "\f0a40";
}

.mdi-content-copy::before {
  content: "\f018f";
}

.mdi-content-cut::before {
  content: "\f0190";
}

.mdi-content-duplicate::before {
  content: "\f0191";
}

.mdi-content-paste::before {
  content: "\f0192";
}

.mdi-content-save::before {
  content: "\f0193";
}

.mdi-content-save-alert::before {
  content: "\f0f42";
}

.mdi-content-save-alert-outline::before {
  content: "\f0f43";
}

.mdi-content-save-all::before {
  content: "\f0194";
}

.mdi-content-save-all-outline::before {
  content: "\f0f44";
}

.mdi-content-save-check::before {
  content: "\f18ea";
}

.mdi-content-save-check-outline::before {
  content: "\f18eb";
}

.mdi-content-save-cog::before {
  content: "\f145b";
}

.mdi-content-save-cog-outline::before {
  content: "\f145c";
}

.mdi-content-save-edit::before {
  content: "\f0cfb";
}

.mdi-content-save-edit-outline::before {
  content: "\f0cfc";
}

.mdi-content-save-move::before {
  content: "\f0e27";
}

.mdi-content-save-move-outline::before {
  content: "\f0e28";
}

.mdi-content-save-off::before {
  content: "\f1643";
}

.mdi-content-save-off-outline::before {
  content: "\f1644";
}

.mdi-content-save-outline::before {
  content: "\f0818";
}

.mdi-content-save-settings::before {
  content: "\f061b";
}

.mdi-content-save-settings-outline::before {
  content: "\f0b2e";
}

.mdi-contrast::before {
  content: "\f0195";
}

.mdi-contrast-box::before {
  content: "\f0196";
}

.mdi-contrast-circle::before {
  content: "\f0197";
}

.mdi-controller-classic::before {
  content: "\f0b82";
}

.mdi-controller-classic-outline::before {
  content: "\f0b83";
}

.mdi-cookie::before {
  content: "\f0198";
}

.mdi-cookie-alert::before {
  content: "\f16d0";
}

.mdi-cookie-alert-outline::before {
  content: "\f16d1";
}

.mdi-cookie-check::before {
  content: "\f16d2";
}

.mdi-cookie-check-outline::before {
  content: "\f16d3";
}

.mdi-cookie-clock::before {
  content: "\f16e4";
}

.mdi-cookie-clock-outline::before {
  content: "\f16e5";
}

.mdi-cookie-cog::before {
  content: "\f16d4";
}

.mdi-cookie-cog-outline::before {
  content: "\f16d5";
}

.mdi-cookie-edit::before {
  content: "\f16e6";
}

.mdi-cookie-edit-outline::before {
  content: "\f16e7";
}

.mdi-cookie-lock::before {
  content: "\f16e8";
}

.mdi-cookie-lock-outline::before {
  content: "\f16e9";
}

.mdi-cookie-minus::before {
  content: "\f16da";
}

.mdi-cookie-minus-outline::before {
  content: "\f16db";
}

.mdi-cookie-off::before {
  content: "\f16ea";
}

.mdi-cookie-off-outline::before {
  content: "\f16eb";
}

.mdi-cookie-outline::before {
  content: "\f16de";
}

.mdi-cookie-plus::before {
  content: "\f16d6";
}

.mdi-cookie-plus-outline::before {
  content: "\f16d7";
}

.mdi-cookie-refresh::before {
  content: "\f16ec";
}

.mdi-cookie-refresh-outline::before {
  content: "\f16ed";
}

.mdi-cookie-remove::before {
  content: "\f16d8";
}

.mdi-cookie-remove-outline::before {
  content: "\f16d9";
}

.mdi-cookie-settings::before {
  content: "\f16dc";
}

.mdi-cookie-settings-outline::before {
  content: "\f16dd";
}

.mdi-coolant-temperature::before {
  content: "\f03c8";
}

.mdi-copyleft::before {
  content: "\f1939";
}

.mdi-copyright::before {
  content: "\f05e6";
}

.mdi-cordova::before {
  content: "\f0958";
}

.mdi-corn::before {
  content: "\f07b8";
}

.mdi-corn-off::before {
  content: "\f13ef";
}

.mdi-cosine-wave::before {
  content: "\f1479";
}

.mdi-counter::before {
  content: "\f0199";
}

.mdi-countertop::before {
  content: "\f181c";
}

.mdi-countertop-outline::before {
  content: "\f181d";
}

.mdi-cow::before {
  content: "\f019a";
}

.mdi-cow-off::before {
  content: "\f18fc";
}

.mdi-cpu-32-bit::before {
  content: "\f0edf";
}

.mdi-cpu-64-bit::before {
  content: "\f0ee0";
}

.mdi-cradle::before {
  content: "\f198b";
}

.mdi-cradle-outline::before {
  content: "\f1991";
}

.mdi-crane::before {
  content: "\f0862";
}

.mdi-creation::before {
  content: "\f0674";
}

.mdi-creative-commons::before {
  content: "\f0d6b";
}

.mdi-credit-card::before {
  content: "\f0fef";
}

.mdi-credit-card-check::before {
  content: "\f13d0";
}

.mdi-credit-card-check-outline::before {
  content: "\f13d1";
}

.mdi-credit-card-chip::before {
  content: "\f190f";
}

.mdi-credit-card-chip-outline::before {
  content: "\f1910";
}

.mdi-credit-card-clock::before {
  content: "\f0ee1";
}

.mdi-credit-card-clock-outline::before {
  content: "\f0ee2";
}

.mdi-credit-card-edit::before {
  content: "\f17d7";
}

.mdi-credit-card-edit-outline::before {
  content: "\f17d8";
}

.mdi-credit-card-fast::before {
  content: "\f1911";
}

.mdi-credit-card-fast-outline::before {
  content: "\f1912";
}

.mdi-credit-card-lock::before {
  content: "\f18e7";
}

.mdi-credit-card-lock-outline::before {
  content: "\f18e8";
}

.mdi-credit-card-marker::before {
  content: "\f06a8";
}

.mdi-credit-card-marker-outline::before {
  content: "\f0dbe";
}

.mdi-credit-card-minus::before {
  content: "\f0fac";
}

.mdi-credit-card-minus-outline::before {
  content: "\f0fad";
}

.mdi-credit-card-multiple::before {
  content: "\f0ff0";
}

.mdi-credit-card-multiple-outline::before {
  content: "\f019c";
}

.mdi-credit-card-off::before {
  content: "\f0ff1";
}

.mdi-credit-card-off-outline::before {
  content: "\f05e4";
}

.mdi-credit-card-outline::before {
  content: "\f019b";
}

.mdi-credit-card-plus::before {
  content: "\f0ff2";
}

.mdi-credit-card-plus-outline::before {
  content: "\f0676";
}

.mdi-credit-card-refresh::before {
  content: "\f1645";
}

.mdi-credit-card-refresh-outline::before {
  content: "\f1646";
}

.mdi-credit-card-refund::before {
  content: "\f0ff3";
}

.mdi-credit-card-refund-outline::before {
  content: "\f0aa8";
}

.mdi-credit-card-remove::before {
  content: "\f0fae";
}

.mdi-credit-card-remove-outline::before {
  content: "\f0faf";
}

.mdi-credit-card-scan::before {
  content: "\f0ff4";
}

.mdi-credit-card-scan-outline::before {
  content: "\f019d";
}

.mdi-credit-card-search::before {
  content: "\f1647";
}

.mdi-credit-card-search-outline::before {
  content: "\f1648";
}

.mdi-credit-card-settings::before {
  content: "\f0ff5";
}

.mdi-credit-card-settings-outline::before {
  content: "\f08d7";
}

.mdi-credit-card-sync::before {
  content: "\f1649";
}

.mdi-credit-card-sync-outline::before {
  content: "\f164a";
}

.mdi-credit-card-wireless::before {
  content: "\f0802";
}

.mdi-credit-card-wireless-off::before {
  content: "\f057a";
}

.mdi-credit-card-wireless-off-outline::before {
  content: "\f057b";
}

.mdi-credit-card-wireless-outline::before {
  content: "\f0d6c";
}

.mdi-cricket::before {
  content: "\f0d6d";
}

.mdi-crop::before {
  content: "\f019e";
}

.mdi-crop-free::before {
  content: "\f019f";
}

.mdi-crop-landscape::before {
  content: "\f01a0";
}

.mdi-crop-portrait::before {
  content: "\f01a1";
}

.mdi-crop-rotate::before {
  content: "\f0696";
}

.mdi-crop-square::before {
  content: "\f01a2";
}

.mdi-cross::before {
  content: "\f0953";
}

.mdi-cross-bolnisi::before {
  content: "\f0ced";
}

.mdi-cross-celtic::before {
  content: "\f0cf5";
}

.mdi-cross-outline::before {
  content: "\f0cf6";
}

.mdi-crosshairs::before {
  content: "\f01a3";
}

.mdi-crosshairs-gps::before {
  content: "\f01a4";
}

.mdi-crosshairs-off::before {
  content: "\f0f45";
}

.mdi-crosshairs-question::before {
  content: "\f1136";
}

.mdi-crowd::before {
  content: "\f1975";
}

.mdi-crown::before {
  content: "\f01a5";
}

.mdi-crown-circle::before {
  content: "\f17dc";
}

.mdi-crown-circle-outline::before {
  content: "\f17dd";
}

.mdi-crown-outline::before {
  content: "\f11d0";
}

.mdi-cryengine::before {
  content: "\f0959";
}

.mdi-crystal-ball::before {
  content: "\f0b2f";
}

.mdi-cube::before {
  content: "\f01a6";
}

.mdi-cube-off::before {
  content: "\f141c";
}

.mdi-cube-off-outline::before {
  content: "\f141d";
}

.mdi-cube-outline::before {
  content: "\f01a7";
}

.mdi-cube-scan::before {
  content: "\f0b84";
}

.mdi-cube-send::before {
  content: "\f01a8";
}

.mdi-cube-unfolded::before {
  content: "\f01a9";
}

.mdi-cup::before {
  content: "\f01aa";
}

.mdi-cup-off::before {
  content: "\f05e5";
}

.mdi-cup-off-outline::before {
  content: "\f137d";
}

.mdi-cup-outline::before {
  content: "\f130f";
}

.mdi-cup-water::before {
  content: "\f01ab";
}

.mdi-cupboard::before {
  content: "\f0f46";
}

.mdi-cupboard-outline::before {
  content: "\f0f47";
}

.mdi-cupcake::before {
  content: "\f095a";
}

.mdi-curling::before {
  content: "\f0863";
}

.mdi-currency-bdt::before {
  content: "\f0864";
}

.mdi-currency-brl::before {
  content: "\f0b85";
}

.mdi-currency-btc::before {
  content: "\f01ac";
}

.mdi-currency-cny::before {
  content: "\f07ba";
}

.mdi-currency-eth::before {
  content: "\f07bb";
}

.mdi-currency-eur::before {
  content: "\f01ad";
}

.mdi-currency-eur-off::before {
  content: "\f1315";
}

.mdi-currency-gbp::before {
  content: "\f01ae";
}

.mdi-currency-ils::before {
  content: "\f0c61";
}

.mdi-currency-inr::before {
  content: "\f01af";
}

.mdi-currency-jpy::before {
  content: "\f07bc";
}

.mdi-currency-krw::before {
  content: "\f07bd";
}

.mdi-currency-kzt::before {
  content: "\f0865";
}

.mdi-currency-mnt::before {
  content: "\f1512";
}

.mdi-currency-ngn::before {
  content: "\f01b0";
}

.mdi-currency-php::before {
  content: "\f09e6";
}

.mdi-currency-rial::before {
  content: "\f0e9c";
}

.mdi-currency-rub::before {
  content: "\f01b1";
}

.mdi-currency-rupee::before {
  content: "\f1976";
}

.mdi-currency-sign::before {
  content: "\f07be";
}

.mdi-currency-try::before {
  content: "\f01b2";
}

.mdi-currency-twd::before {
  content: "\f07bf";
}

.mdi-currency-usd::before {
  content: "\f01c1";
}

.mdi-currency-usd-off::before {
  content: "\f067a";
}

.mdi-current-ac::before {
  content: "\f1480";
}

.mdi-current-dc::before {
  content: "\f095c";
}

.mdi-cursor-default::before {
  content: "\f01c0";
}

.mdi-cursor-default-click::before {
  content: "\f0cfd";
}

.mdi-cursor-default-click-outline::before {
  content: "\f0cfe";
}

.mdi-cursor-default-gesture::before {
  content: "\f1127";
}

.mdi-cursor-default-gesture-outline::before {
  content: "\f1128";
}

.mdi-cursor-default-outline::before {
  content: "\f01bf";
}

.mdi-cursor-move::before {
  content: "\f01be";
}

.mdi-cursor-pointer::before {
  content: "\f01bd";
}

.mdi-cursor-text::before {
  content: "\f05e7";
}

.mdi-curtains::before {
  content: "\f1846";
}

.mdi-curtains-closed::before {
  content: "\f1847";
}

.mdi-cylinder::before {
  content: "\f194e";
}

.mdi-cylinder-off::before {
  content: "\f194f";
}

.mdi-dance-ballroom::before {
  content: "\f15fb";
}

.mdi-dance-pole::before {
  content: "\f1578";
}

.mdi-data-matrix::before {
  content: "\f153c";
}

.mdi-data-matrix-edit::before {
  content: "\f153d";
}

.mdi-data-matrix-minus::before {
  content: "\f153e";
}

.mdi-data-matrix-plus::before {
  content: "\f153f";
}

.mdi-data-matrix-remove::before {
  content: "\f1540";
}

.mdi-data-matrix-scan::before {
  content: "\f1541";
}

.mdi-database::before {
  content: "\f01bc";
}

.mdi-database-alert::before {
  content: "\f163a";
}

.mdi-database-alert-outline::before {
  content: "\f1624";
}

.mdi-database-arrow-down::before {
  content: "\f163b";
}

.mdi-database-arrow-down-outline::before {
  content: "\f1625";
}

.mdi-database-arrow-left::before {
  content: "\f163c";
}

.mdi-database-arrow-left-outline::before {
  content: "\f1626";
}

.mdi-database-arrow-right::before {
  content: "\f163d";
}

.mdi-database-arrow-right-outline::before {
  content: "\f1627";
}

.mdi-database-arrow-up::before {
  content: "\f163e";
}

.mdi-database-arrow-up-outline::before {
  content: "\f1628";
}

.mdi-database-check::before {
  content: "\f0aa9";
}

.mdi-database-check-outline::before {
  content: "\f1629";
}

.mdi-database-clock::before {
  content: "\f163f";
}

.mdi-database-clock-outline::before {
  content: "\f162a";
}

.mdi-database-cog::before {
  content: "\f164b";
}

.mdi-database-cog-outline::before {
  content: "\f164c";
}

.mdi-database-edit::before {
  content: "\f0b86";
}

.mdi-database-edit-outline::before {
  content: "\f162b";
}

.mdi-database-export::before {
  content: "\f095e";
}

.mdi-database-export-outline::before {
  content: "\f162c";
}

.mdi-database-eye::before {
  content: "\f191f";
}

.mdi-database-eye-off::before {
  content: "\f1920";
}

.mdi-database-eye-off-outline::before {
  content: "\f1921";
}

.mdi-database-eye-outline::before {
  content: "\f1922";
}

.mdi-database-import::before {
  content: "\f095d";
}

.mdi-database-import-outline::before {
  content: "\f162d";
}

.mdi-database-lock::before {
  content: "\f0aaa";
}

.mdi-database-lock-outline::before {
  content: "\f162e";
}

.mdi-database-marker::before {
  content: "\f12f6";
}

.mdi-database-marker-outline::before {
  content: "\f162f";
}

.mdi-database-minus::before {
  content: "\f01bb";
}

.mdi-database-minus-outline::before {
  content: "\f1630";
}

.mdi-database-off::before {
  content: "\f1640";
}

.mdi-database-off-outline::before {
  content: "\f1631";
}

.mdi-database-outline::before {
  content: "\f1632";
}

.mdi-database-plus::before {
  content: "\f01ba";
}

.mdi-database-plus-outline::before {
  content: "\f1633";
}

.mdi-database-refresh::before {
  content: "\f05c2";
}

.mdi-database-refresh-outline::before {
  content: "\f1634";
}

.mdi-database-remove::before {
  content: "\f0d00";
}

.mdi-database-remove-outline::before {
  content: "\f1635";
}

.mdi-database-search::before {
  content: "\f0866";
}

.mdi-database-search-outline::before {
  content: "\f1636";
}

.mdi-database-settings::before {
  content: "\f0d01";
}

.mdi-database-settings-outline::before {
  content: "\f1637";
}

.mdi-database-sync::before {
  content: "\f0cff";
}

.mdi-database-sync-outline::before {
  content: "\f1638";
}

.mdi-death-star::before {
  content: "\f08d8";
}

.mdi-death-star-variant::before {
  content: "\f08d9";
}

.mdi-deathly-hallows::before {
  content: "\f0b87";
}

.mdi-debian::before {
  content: "\f08da";
}

.mdi-debug-step-into::before {
  content: "\f01b9";
}

.mdi-debug-step-out::before {
  content: "\f01b8";
}

.mdi-debug-step-over::before {
  content: "\f01b7";
}

.mdi-decagram::before {
  content: "\f076c";
}

.mdi-decagram-outline::before {
  content: "\f076d";
}

.mdi-decimal::before {
  content: "\f10a1";
}

.mdi-decimal-comma::before {
  content: "\f10a2";
}

.mdi-decimal-comma-decrease::before {
  content: "\f10a3";
}

.mdi-decimal-comma-increase::before {
  content: "\f10a4";
}

.mdi-decimal-decrease::before {
  content: "\f01b6";
}

.mdi-decimal-increase::before {
  content: "\f01b5";
}

.mdi-delete::before {
  content: "\f01b4";
}

.mdi-delete-alert::before {
  content: "\f10a5";
}

.mdi-delete-alert-outline::before {
  content: "\f10a6";
}

.mdi-delete-circle::before {
  content: "\f0683";
}

.mdi-delete-circle-outline::before {
  content: "\f0b88";
}

.mdi-delete-clock::before {
  content: "\f1556";
}

.mdi-delete-clock-outline::before {
  content: "\f1557";
}

.mdi-delete-empty::before {
  content: "\f06cc";
}

.mdi-delete-empty-outline::before {
  content: "\f0e9d";
}

.mdi-delete-forever::before {
  content: "\f05e8";
}

.mdi-delete-forever-outline::before {
  content: "\f0b89";
}

.mdi-delete-off::before {
  content: "\f10a7";
}

.mdi-delete-off-outline::before {
  content: "\f10a8";
}

.mdi-delete-outline::before {
  content: "\f09e7";
}

.mdi-delete-restore::before {
  content: "\f0819";
}

.mdi-delete-sweep::before {
  content: "\f05e9";
}

.mdi-delete-sweep-outline::before {
  content: "\f0c62";
}

.mdi-delete-variant::before {
  content: "\f01b3";
}

.mdi-delta::before {
  content: "\f01c2";
}

.mdi-desk::before {
  content: "\f1239";
}

.mdi-desk-lamp::before {
  content: "\f095f";
}

.mdi-deskphone::before {
  content: "\f01c3";
}

.mdi-desktop-classic::before {
  content: "\f07c0";
}

.mdi-desktop-mac::before {
  content: "\f01c4";
}

.mdi-desktop-mac-dashboard::before {
  content: "\f09e8";
}

.mdi-desktop-tower::before {
  content: "\f01c5";
}

.mdi-desktop-tower-monitor::before {
  content: "\f0aab";
}

.mdi-details::before {
  content: "\f01c6";
}

.mdi-dev-to::before {
  content: "\f0d6e";
}

.mdi-developer-board::before {
  content: "\f0697";
}

.mdi-deviantart::before {
  content: "\f01c7";
}

.mdi-devices::before {
  content: "\f0fb0";
}

.mdi-dharmachakra::before {
  content: "\f094b";
}

.mdi-diabetes::before {
  content: "\f1126";
}

.mdi-dialpad::before {
  content: "\f061c";
}

.mdi-diameter::before {
  content: "\f0c63";
}

.mdi-diameter-outline::before {
  content: "\f0c64";
}

.mdi-diameter-variant::before {
  content: "\f0c65";
}

.mdi-diamond::before {
  content: "\f0b8a";
}

.mdi-diamond-outline::before {
  content: "\f0b8b";
}

.mdi-diamond-stone::before {
  content: "\f01c8";
}

.mdi-dice-1::before {
  content: "\f01ca";
}

.mdi-dice-1-outline::before {
  content: "\f114a";
}

.mdi-dice-2::before {
  content: "\f01cb";
}

.mdi-dice-2-outline::before {
  content: "\f114b";
}

.mdi-dice-3::before {
  content: "\f01cc";
}

.mdi-dice-3-outline::before {
  content: "\f114c";
}

.mdi-dice-4::before {
  content: "\f01cd";
}

.mdi-dice-4-outline::before {
  content: "\f114d";
}

.mdi-dice-5::before {
  content: "\f01ce";
}

.mdi-dice-5-outline::before {
  content: "\f114e";
}

.mdi-dice-6::before {
  content: "\f01cf";
}

.mdi-dice-6-outline::before {
  content: "\f114f";
}

.mdi-dice-d10::before {
  content: "\f1153";
}

.mdi-dice-d10-outline::before {
  content: "\f076f";
}

.mdi-dice-d12::before {
  content: "\f1154";
}

.mdi-dice-d12-outline::before {
  content: "\f0867";
}

.mdi-dice-d20::before {
  content: "\f1155";
}

.mdi-dice-d20-outline::before {
  content: "\f05ea";
}

.mdi-dice-d4::before {
  content: "\f1150";
}

.mdi-dice-d4-outline::before {
  content: "\f05eb";
}

.mdi-dice-d6::before {
  content: "\f1151";
}

.mdi-dice-d6-outline::before {
  content: "\f05ed";
}

.mdi-dice-d8::before {
  content: "\f1152";
}

.mdi-dice-d8-outline::before {
  content: "\f05ec";
}

.mdi-dice-multiple::before {
  content: "\f076e";
}

.mdi-dice-multiple-outline::before {
  content: "\f1156";
}

.mdi-digital-ocean::before {
  content: "\f1237";
}

.mdi-dip-switch::before {
  content: "\f07c1";
}

.mdi-directions::before {
  content: "\f01d0";
}

.mdi-directions-fork::before {
  content: "\f0641";
}

.mdi-disc::before {
  content: "\f05ee";
}

.mdi-disc-alert::before {
  content: "\f01d1";
}

.mdi-disc-player::before {
  content: "\f0960";
}

.mdi-discord::before {
  content: "\f066f";
}

.mdi-dishwasher::before {
  content: "\f0aac";
}

.mdi-dishwasher-alert::before {
  content: "\f11b8";
}

.mdi-dishwasher-off::before {
  content: "\f11b9";
}

.mdi-disqus::before {
  content: "\f01d2";
}

.mdi-distribute-horizontal-center::before {
  content: "\f11c9";
}

.mdi-distribute-horizontal-left::before {
  content: "\f11c8";
}

.mdi-distribute-horizontal-right::before {
  content: "\f11ca";
}

.mdi-distribute-vertical-bottom::before {
  content: "\f11cb";
}

.mdi-distribute-vertical-center::before {
  content: "\f11cc";
}

.mdi-distribute-vertical-top::before {
  content: "\f11cd";
}

.mdi-diversify::before {
  content: "\f1877";
}

.mdi-diving::before {
  content: "\f1977";
}

.mdi-diving-flippers::before {
  content: "\f0dbf";
}

.mdi-diving-helmet::before {
  content: "\f0dc0";
}

.mdi-diving-scuba::before {
  content: "\f0dc1";
}

.mdi-diving-scuba-flag::before {
  content: "\f0dc2";
}

.mdi-diving-scuba-tank::before {
  content: "\f0dc3";
}

.mdi-diving-scuba-tank-multiple::before {
  content: "\f0dc4";
}

.mdi-diving-snorkel::before {
  content: "\f0dc5";
}

.mdi-division::before {
  content: "\f01d4";
}

.mdi-division-box::before {
  content: "\f01d5";
}

.mdi-dlna::before {
  content: "\f0a41";
}

.mdi-dna::before {
  content: "\f0684";
}

.mdi-dns::before {
  content: "\f01d6";
}

.mdi-dns-outline::before {
  content: "\f0b8c";
}

.mdi-dock-bottom::before {
  content: "\f10a9";
}

.mdi-dock-left::before {
  content: "\f10aa";
}

.mdi-dock-right::before {
  content: "\f10ab";
}

.mdi-dock-top::before {
  content: "\f1513";
}

.mdi-dock-window::before {
  content: "\f10ac";
}

.mdi-docker::before {
  content: "\f0868";
}

.mdi-doctor::before {
  content: "\f0a42";
}

.mdi-dog::before {
  content: "\f0a43";
}

.mdi-dog-service::before {
  content: "\f0aad";
}

.mdi-dog-side::before {
  content: "\f0a44";
}

.mdi-dog-side-off::before {
  content: "\f16ee";
}

.mdi-dolby::before {
  content: "\f06b3";
}

.mdi-dolly::before {
  content: "\f0e9e";
}

.mdi-dolphin::before {
  content: "\f18b4";
}

.mdi-domain::before {
  content: "\f01d7";
}

.mdi-domain-off::before {
  content: "\f0d6f";
}

.mdi-domain-plus::before {
  content: "\f10ad";
}

.mdi-domain-remove::before {
  content: "\f10ae";
}

.mdi-dome-light::before {
  content: "\f141e";
}

.mdi-domino-mask::before {
  content: "\f1023";
}

.mdi-donkey::before {
  content: "\f07c2";
}

.mdi-door::before {
  content: "\f081a";
}

.mdi-door-closed::before {
  content: "\f081b";
}

.mdi-door-closed-lock::before {
  content: "\f10af";
}

.mdi-door-open::before {
  content: "\f081c";
}

.mdi-door-sliding::before {
  content: "\f181e";
}

.mdi-door-sliding-lock::before {
  content: "\f181f";
}

.mdi-door-sliding-open::before {
  content: "\f1820";
}

.mdi-doorbell::before {
  content: "\f12e6";
}

.mdi-doorbell-video::before {
  content: "\f0869";
}

.mdi-dot-net::before {
  content: "\f0aae";
}

.mdi-dots-circle::before {
  content: "\f1978";
}

.mdi-dots-grid::before {
  content: "\f15fc";
}

.mdi-dots-hexagon::before {
  content: "\f15ff";
}

.mdi-dots-horizontal::before {
  content: "\f01d8";
}

.mdi-dots-horizontal-circle::before {
  content: "\f07c3";
}

.mdi-dots-horizontal-circle-outline::before {
  content: "\f0b8d";
}

.mdi-dots-square::before {
  content: "\f15fd";
}

.mdi-dots-triangle::before {
  content: "\f15fe";
}

.mdi-dots-vertical::before {
  content: "\f01d9";
}

.mdi-dots-vertical-circle::before {
  content: "\f07c4";
}

.mdi-dots-vertical-circle-outline::before {
  content: "\f0b8e";
}

.mdi-download::before {
  content: "\f01da";
}

.mdi-download-box::before {
  content: "\f1462";
}

.mdi-download-box-outline::before {
  content: "\f1463";
}

.mdi-download-circle::before {
  content: "\f1464";
}

.mdi-download-circle-outline::before {
  content: "\f1465";
}

.mdi-download-lock::before {
  content: "\f1320";
}

.mdi-download-lock-outline::before {
  content: "\f1321";
}

.mdi-download-multiple::before {
  content: "\f09e9";
}

.mdi-download-network::before {
  content: "\f06f4";
}

.mdi-download-network-outline::before {
  content: "\f0c66";
}

.mdi-download-off::before {
  content: "\f10b0";
}

.mdi-download-off-outline::before {
  content: "\f10b1";
}

.mdi-download-outline::before {
  content: "\f0b8f";
}

.mdi-drag::before {
  content: "\f01db";
}

.mdi-drag-horizontal::before {
  content: "\f01dc";
}

.mdi-drag-horizontal-variant::before {
  content: "\f12f0";
}

.mdi-drag-variant::before {
  content: "\f0b90";
}

.mdi-drag-vertical::before {
  content: "\f01dd";
}

.mdi-drag-vertical-variant::before {
  content: "\f12f1";
}

.mdi-drama-masks::before {
  content: "\f0d02";
}

.mdi-draw::before {
  content: "\f0f49";
}

.mdi-draw-pen::before {
  content: "\f19b9";
}

.mdi-drawing::before {
  content: "\f01de";
}

.mdi-drawing-box::before {
  content: "\f01df";
}

.mdi-dresser::before {
  content: "\f0f4a";
}

.mdi-dresser-outline::before {
  content: "\f0f4b";
}

.mdi-drone::before {
  content: "\f01e2";
}

.mdi-dropbox::before {
  content: "\f01e3";
}

.mdi-drupal::before {
  content: "\f01e4";
}

.mdi-duck::before {
  content: "\f01e5";
}

.mdi-dumbbell::before {
  content: "\f01e6";
}

.mdi-dump-truck::before {
  content: "\f0c67";
}

.mdi-ear-hearing::before {
  content: "\f07c5";
}

.mdi-ear-hearing-off::before {
  content: "\f0a45";
}

.mdi-earbuds::before {
  content: "\f184f";
}

.mdi-earbuds-off::before {
  content: "\f1850";
}

.mdi-earbuds-off-outline::before {
  content: "\f1851";
}

.mdi-earbuds-outline::before {
  content: "\f1852";
}

.mdi-earth::before {
  content: "\f01e7";
}

.mdi-earth-arrow-right::before {
  content: "\f1311";
}

.mdi-earth-box::before {
  content: "\f06cd";
}

.mdi-earth-box-minus::before {
  content: "\f1407";
}

.mdi-earth-box-off::before {
  content: "\f06ce";
}

.mdi-earth-box-plus::before {
  content: "\f1406";
}

.mdi-earth-box-remove::before {
  content: "\f1408";
}

.mdi-earth-minus::before {
  content: "\f1404";
}

.mdi-earth-off::before {
  content: "\f01e8";
}

.mdi-earth-plus::before {
  content: "\f1403";
}

.mdi-earth-remove::before {
  content: "\f1405";
}

.mdi-egg::before {
  content: "\f0aaf";
}

.mdi-egg-easter::before {
  content: "\f0ab0";
}

.mdi-egg-fried::before {
  content: "\f184a";
}

.mdi-egg-off::before {
  content: "\f13f0";
}

.mdi-egg-off-outline::before {
  content: "\f13f1";
}

.mdi-egg-outline::before {
  content: "\f13f2";
}

.mdi-eiffel-tower::before {
  content: "\f156b";
}

.mdi-eight-track::before {
  content: "\f09ea";
}

.mdi-eject::before {
  content: "\f01ea";
}

.mdi-eject-outline::before {
  content: "\f0b91";
}

.mdi-electric-switch::before {
  content: "\f0e9f";
}

.mdi-electric-switch-closed::before {
  content: "\f10d9";
}

.mdi-electron-framework::before {
  content: "\f1024";
}

.mdi-elephant::before {
  content: "\f07c6";
}

.mdi-elevation-decline::before {
  content: "\f01eb";
}

.mdi-elevation-rise::before {
  content: "\f01ec";
}

.mdi-elevator::before {
  content: "\f01ed";
}

.mdi-elevator-down::before {
  content: "\f12c2";
}

.mdi-elevator-passenger::before {
  content: "\f1381";
}

.mdi-elevator-passenger-off::before {
  content: "\f1979";
}

.mdi-elevator-passenger-off-outline::before {
  content: "\f197a";
}

.mdi-elevator-passenger-outline::before {
  content: "\f197b";
}

.mdi-elevator-up::before {
  content: "\f12c1";
}

.mdi-ellipse::before {
  content: "\f0ea0";
}

.mdi-ellipse-outline::before {
  content: "\f0ea1";
}

.mdi-email::before {
  content: "\f01ee";
}

.mdi-email-alert::before {
  content: "\f06cf";
}

.mdi-email-alert-outline::before {
  content: "\f0d42";
}

.mdi-email-box::before {
  content: "\f0d03";
}

.mdi-email-check::before {
  content: "\f0ab1";
}

.mdi-email-check-outline::before {
  content: "\f0ab2";
}

.mdi-email-edit::before {
  content: "\f0ee3";
}

.mdi-email-edit-outline::before {
  content: "\f0ee4";
}

.mdi-email-fast::before {
  content: "\f186f";
}

.mdi-email-fast-outline::before {
  content: "\f1870";
}

.mdi-email-lock::before {
  content: "\f01f1";
}

.mdi-email-mark-as-unread::before {
  content: "\f0b92";
}

.mdi-email-minus::before {
  content: "\f0ee5";
}

.mdi-email-minus-outline::before {
  content: "\f0ee6";
}

.mdi-email-multiple::before {
  content: "\f0ee7";
}

.mdi-email-multiple-outline::before {
  content: "\f0ee8";
}

.mdi-email-newsletter::before {
  content: "\f0fb1";
}

.mdi-email-off::before {
  content: "\f13e3";
}

.mdi-email-off-outline::before {
  content: "\f13e4";
}

.mdi-email-open::before {
  content: "\f01ef";
}

.mdi-email-open-multiple::before {
  content: "\f0ee9";
}

.mdi-email-open-multiple-outline::before {
  content: "\f0eea";
}

.mdi-email-open-outline::before {
  content: "\f05ef";
}

.mdi-email-outline::before {
  content: "\f01f0";
}

.mdi-email-plus::before {
  content: "\f09eb";
}

.mdi-email-plus-outline::before {
  content: "\f09ec";
}

.mdi-email-receive::before {
  content: "\f10da";
}

.mdi-email-receive-outline::before {
  content: "\f10db";
}

.mdi-email-remove::before {
  content: "\f1661";
}

.mdi-email-remove-outline::before {
  content: "\f1662";
}

.mdi-email-seal::before {
  content: "\f195b";
}

.mdi-email-seal-outline::before {
  content: "\f195c";
}

.mdi-email-search::before {
  content: "\f0961";
}

.mdi-email-search-outline::before {
  content: "\f0962";
}

.mdi-email-send::before {
  content: "\f10dc";
}

.mdi-email-send-outline::before {
  content: "\f10dd";
}

.mdi-email-sync::before {
  content: "\f12c7";
}

.mdi-email-sync-outline::before {
  content: "\f12c8";
}

.mdi-email-variant::before {
  content: "\f05f0";
}

.mdi-ember::before {
  content: "\f0b30";
}

.mdi-emby::before {
  content: "\f06b4";
}

.mdi-emoticon::before {
  content: "\f0c68";
}

.mdi-emoticon-angry::before {
  content: "\f0c69";
}

.mdi-emoticon-angry-outline::before {
  content: "\f0c6a";
}

.mdi-emoticon-confused::before {
  content: "\f10de";
}

.mdi-emoticon-confused-outline::before {
  content: "\f10df";
}

.mdi-emoticon-cool::before {
  content: "\f0c6b";
}

.mdi-emoticon-cool-outline::before {
  content: "\f01f3";
}

.mdi-emoticon-cry::before {
  content: "\f0c6c";
}

.mdi-emoticon-cry-outline::before {
  content: "\f0c6d";
}

.mdi-emoticon-dead::before {
  content: "\f0c6e";
}

.mdi-emoticon-dead-outline::before {
  content: "\f069b";
}

.mdi-emoticon-devil::before {
  content: "\f0c6f";
}

.mdi-emoticon-devil-outline::before {
  content: "\f01f4";
}

.mdi-emoticon-excited::before {
  content: "\f0c70";
}

.mdi-emoticon-excited-outline::before {
  content: "\f069c";
}

.mdi-emoticon-frown::before {
  content: "\f0f4c";
}

.mdi-emoticon-frown-outline::before {
  content: "\f0f4d";
}

.mdi-emoticon-happy::before {
  content: "\f0c71";
}

.mdi-emoticon-happy-outline::before {
  content: "\f01f5";
}

.mdi-emoticon-kiss::before {
  content: "\f0c72";
}

.mdi-emoticon-kiss-outline::before {
  content: "\f0c73";
}

.mdi-emoticon-lol::before {
  content: "\f1214";
}

.mdi-emoticon-lol-outline::before {
  content: "\f1215";
}

.mdi-emoticon-neutral::before {
  content: "\f0c74";
}

.mdi-emoticon-neutral-outline::before {
  content: "\f01f6";
}

.mdi-emoticon-outline::before {
  content: "\f01f2";
}

.mdi-emoticon-poop::before {
  content: "\f01f7";
}

.mdi-emoticon-poop-outline::before {
  content: "\f0c75";
}

.mdi-emoticon-sad::before {
  content: "\f0c76";
}

.mdi-emoticon-sad-outline::before {
  content: "\f01f8";
}

.mdi-emoticon-sick::before {
  content: "\f157c";
}

.mdi-emoticon-sick-outline::before {
  content: "\f157d";
}

.mdi-emoticon-tongue::before {
  content: "\f01f9";
}

.mdi-emoticon-tongue-outline::before {
  content: "\f0c77";
}

.mdi-emoticon-wink::before {
  content: "\f0c78";
}

.mdi-emoticon-wink-outline::before {
  content: "\f0c79";
}

.mdi-engine::before {
  content: "\f01fa";
}

.mdi-engine-off::before {
  content: "\f0a46";
}

.mdi-engine-off-outline::before {
  content: "\f0a47";
}

.mdi-engine-outline::before {
  content: "\f01fb";
}

.mdi-epsilon::before {
  content: "\f10e0";
}

.mdi-equal::before {
  content: "\f01fc";
}

.mdi-equal-box::before {
  content: "\f01fd";
}

.mdi-equalizer::before {
  content: "\f0ea2";
}

.mdi-equalizer-outline::before {
  content: "\f0ea3";
}

.mdi-eraser::before {
  content: "\f01fe";
}

.mdi-eraser-variant::before {
  content: "\f0642";
}

.mdi-escalator::before {
  content: "\f01ff";
}

.mdi-escalator-box::before {
  content: "\f1399";
}

.mdi-escalator-down::before {
  content: "\f12c0";
}

.mdi-escalator-up::before {
  content: "\f12bf";
}

.mdi-eslint::before {
  content: "\f0c7a";
}

.mdi-et::before {
  content: "\f0ab3";
}

.mdi-ethereum::before {
  content: "\f086a";
}

.mdi-ethernet::before {
  content: "\f0200";
}

.mdi-ethernet-cable::before {
  content: "\f0201";
}

.mdi-ethernet-cable-off::before {
  content: "\f0202";
}

.mdi-ev-plug-ccs1::before {
  content: "\f1519";
}

.mdi-ev-plug-ccs2::before {
  content: "\f151a";
}

.mdi-ev-plug-chademo::before {
  content: "\f151b";
}

.mdi-ev-plug-tesla::before {
  content: "\f151c";
}

.mdi-ev-plug-type1::before {
  content: "\f151d";
}

.mdi-ev-plug-type2::before {
  content: "\f151e";
}

.mdi-ev-station::before {
  content: "\f05f1";
}

.mdi-evernote::before {
  content: "\f0204";
}

.mdi-excavator::before {
  content: "\f1025";
}

.mdi-exclamation::before {
  content: "\f0205";
}

.mdi-exclamation-thick::before {
  content: "\f1238";
}

.mdi-exit-run::before {
  content: "\f0a48";
}

.mdi-exit-to-app::before {
  content: "\f0206";
}

.mdi-expand-all::before {
  content: "\f0ab4";
}

.mdi-expand-all-outline::before {
  content: "\f0ab5";
}

.mdi-expansion-card::before {
  content: "\f08ae";
}

.mdi-expansion-card-variant::before {
  content: "\f0fb2";
}

.mdi-exponent::before {
  content: "\f0963";
}

.mdi-exponent-box::before {
  content: "\f0964";
}

.mdi-export::before {
  content: "\f0207";
}

.mdi-export-variant::before {
  content: "\f0b93";
}

.mdi-eye::before {
  content: "\f0208";
}

.mdi-eye-arrow-left::before {
  content: "\f18fd";
}

.mdi-eye-arrow-left-outline::before {
  content: "\f18fe";
}

.mdi-eye-arrow-right::before {
  content: "\f18ff";
}

.mdi-eye-arrow-right-outline::before {
  content: "\f1900";
}

.mdi-eye-check::before {
  content: "\f0d04";
}

.mdi-eye-check-outline::before {
  content: "\f0d05";
}

.mdi-eye-circle::before {
  content: "\f0b94";
}

.mdi-eye-circle-outline::before {
  content: "\f0b95";
}

.mdi-eye-minus::before {
  content: "\f1026";
}

.mdi-eye-minus-outline::before {
  content: "\f1027";
}

.mdi-eye-off::before {
  content: "\f0209";
}

.mdi-eye-off-outline::before {
  content: "\f06d1";
}

.mdi-eye-outline::before {
  content: "\f06d0";
}

.mdi-eye-plus::before {
  content: "\f086b";
}

.mdi-eye-plus-outline::before {
  content: "\f086c";
}

.mdi-eye-refresh::before {
  content: "\f197c";
}

.mdi-eye-refresh-outline::before {
  content: "\f197d";
}

.mdi-eye-remove::before {
  content: "\f15e3";
}

.mdi-eye-remove-outline::before {
  content: "\f15e4";
}

.mdi-eye-settings::before {
  content: "\f086d";
}

.mdi-eye-settings-outline::before {
  content: "\f086e";
}

.mdi-eyedropper::before {
  content: "\f020a";
}

.mdi-eyedropper-minus::before {
  content: "\f13dd";
}

.mdi-eyedropper-off::before {
  content: "\f13df";
}

.mdi-eyedropper-plus::before {
  content: "\f13dc";
}

.mdi-eyedropper-remove::before {
  content: "\f13de";
}

.mdi-eyedropper-variant::before {
  content: "\f020b";
}

.mdi-face-agent::before {
  content: "\f0d70";
}

.mdi-face-man::before {
  content: "\f0643";
}

.mdi-face-man-outline::before {
  content: "\f0b96";
}

.mdi-face-man-profile::before {
  content: "\f0644";
}

.mdi-face-man-shimmer::before {
  content: "\f15cc";
}

.mdi-face-man-shimmer-outline::before {
  content: "\f15cd";
}

.mdi-face-mask::before {
  content: "\f1586";
}

.mdi-face-mask-outline::before {
  content: "\f1587";
}

.mdi-face-recognition::before {
  content: "\f0c7b";
}

.mdi-face-woman::before {
  content: "\f1077";
}

.mdi-face-woman-outline::before {
  content: "\f1078";
}

.mdi-face-woman-profile::before {
  content: "\f1076";
}

.mdi-face-woman-shimmer::before {
  content: "\f15ce";
}

.mdi-face-woman-shimmer-outline::before {
  content: "\f15cf";
}

.mdi-facebook::before {
  content: "\f020c";
}

.mdi-facebook-gaming::before {
  content: "\f07dd";
}

.mdi-facebook-messenger::before {
  content: "\f020e";
}

.mdi-facebook-workplace::before {
  content: "\f0b31";
}

.mdi-factory::before {
  content: "\f020f";
}

.mdi-family-tree::before {
  content: "\f160e";
}

.mdi-fan::before {
  content: "\f0210";
}

.mdi-fan-alert::before {
  content: "\f146c";
}

.mdi-fan-auto::before {
  content: "\f171d";
}

.mdi-fan-chevron-down::before {
  content: "\f146d";
}

.mdi-fan-chevron-up::before {
  content: "\f146e";
}

.mdi-fan-minus::before {
  content: "\f1470";
}

.mdi-fan-off::before {
  content: "\f081d";
}

.mdi-fan-plus::before {
  content: "\f146f";
}

.mdi-fan-remove::before {
  content: "\f1471";
}

.mdi-fan-speed-1::before {
  content: "\f1472";
}

.mdi-fan-speed-2::before {
  content: "\f1473";
}

.mdi-fan-speed-3::before {
  content: "\f1474";
}

.mdi-fast-forward::before {
  content: "\f0211";
}

.mdi-fast-forward-10::before {
  content: "\f0d71";
}

.mdi-fast-forward-15::before {
  content: "\f193a";
}

.mdi-fast-forward-30::before {
  content: "\f0d06";
}

.mdi-fast-forward-5::before {
  content: "\f11f8";
}

.mdi-fast-forward-60::before {
  content: "\f160b";
}

.mdi-fast-forward-outline::before {
  content: "\f06d2";
}

.mdi-fax::before {
  content: "\f0212";
}

.mdi-feather::before {
  content: "\f06d3";
}

.mdi-feature-search::before {
  content: "\f0a49";
}

.mdi-feature-search-outline::before {
  content: "\f0a4a";
}

.mdi-fedora::before {
  content: "\f08db";
}

.mdi-fence::before {
  content: "\f179a";
}

.mdi-fence-electric::before {
  content: "\f17f6";
}

.mdi-fencing::before {
  content: "\f14c1";
}

.mdi-ferris-wheel::before {
  content: "\f0ea4";
}

.mdi-ferry::before {
  content: "\f0213";
}

.mdi-file::before {
  content: "\f0214";
}

.mdi-file-account::before {
  content: "\f073b";
}

.mdi-file-account-outline::before {
  content: "\f1028";
}

.mdi-file-alert::before {
  content: "\f0a4b";
}

.mdi-file-alert-outline::before {
  content: "\f0a4c";
}

.mdi-file-cabinet::before {
  content: "\f0ab6";
}

.mdi-file-cad::before {
  content: "\f0eeb";
}

.mdi-file-cad-box::before {
  content: "\f0eec";
}

.mdi-file-cancel::before {
  content: "\f0dc6";
}

.mdi-file-cancel-outline::before {
  content: "\f0dc7";
}

.mdi-file-certificate::before {
  content: "\f1186";
}

.mdi-file-certificate-outline::before {
  content: "\f1187";
}

.mdi-file-chart::before {
  content: "\f0215";
}

.mdi-file-chart-outline::before {
  content: "\f1029";
}

.mdi-file-check::before {
  content: "\f0216";
}

.mdi-file-check-outline::before {
  content: "\f0e29";
}

.mdi-file-clock::before {
  content: "\f12e1";
}

.mdi-file-clock-outline::before {
  content: "\f12e2";
}

.mdi-file-cloud::before {
  content: "\f0217";
}

.mdi-file-cloud-outline::before {
  content: "\f102a";
}

.mdi-file-code::before {
  content: "\f022e";
}

.mdi-file-code-outline::before {
  content: "\f102b";
}

.mdi-file-cog::before {
  content: "\f107b";
}

.mdi-file-cog-outline::before {
  content: "\f107c";
}

.mdi-file-compare::before {
  content: "\f08aa";
}

.mdi-file-delimited::before {
  content: "\f0218";
}

.mdi-file-delimited-outline::before {
  content: "\f0ea5";
}

.mdi-file-document::before {
  content: "\f0219";
}

.mdi-file-document-edit::before {
  content: "\f0dc8";
}

.mdi-file-document-edit-outline::before {
  content: "\f0dc9";
}

.mdi-file-document-multiple::before {
  content: "\f1517";
}

.mdi-file-document-multiple-outline::before {
  content: "\f1518";
}

.mdi-file-document-outline::before {
  content: "\f09ee";
}

.mdi-file-download::before {
  content: "\f0965";
}

.mdi-file-download-outline::before {
  content: "\f0966";
}

.mdi-file-edit::before {
  content: "\f11e7";
}

.mdi-file-edit-outline::before {
  content: "\f11e8";
}

.mdi-file-excel::before {
  content: "\f021b";
}

.mdi-file-excel-box::before {
  content: "\f021c";
}

.mdi-file-excel-box-outline::before {
  content: "\f102c";
}

.mdi-file-excel-outline::before {
  content: "\f102d";
}

.mdi-file-export::before {
  content: "\f021d";
}

.mdi-file-export-outline::before {
  content: "\f102e";
}

.mdi-file-eye::before {
  content: "\f0dca";
}

.mdi-file-eye-outline::before {
  content: "\f0dcb";
}

.mdi-file-find::before {
  content: "\f021e";
}

.mdi-file-find-outline::before {
  content: "\f0b97";
}

.mdi-file-gif-box::before {
  content: "\f0d78";
}

.mdi-file-hidden::before {
  content: "\f0613";
}

.mdi-file-image::before {
  content: "\f021f";
}

.mdi-file-image-marker::before {
  content: "\f1772";
}

.mdi-file-image-marker-outline::before {
  content: "\f1773";
}

.mdi-file-image-minus::before {
  content: "\f193b";
}

.mdi-file-image-minus-outline::before {
  content: "\f193c";
}

.mdi-file-image-outline::before {
  content: "\f0eb0";
}

.mdi-file-image-plus::before {
  content: "\f193d";
}

.mdi-file-image-plus-outline::before {
  content: "\f193e";
}

.mdi-file-image-remove::before {
  content: "\f193f";
}

.mdi-file-image-remove-outline::before {
  content: "\f1940";
}

.mdi-file-import::before {
  content: "\f0220";
}

.mdi-file-import-outline::before {
  content: "\f102f";
}

.mdi-file-jpg-box::before {
  content: "\f0225";
}

.mdi-file-key::before {
  content: "\f1184";
}

.mdi-file-key-outline::before {
  content: "\f1185";
}

.mdi-file-link::before {
  content: "\f1177";
}

.mdi-file-link-outline::before {
  content: "\f1178";
}

.mdi-file-lock::before {
  content: "\f0221";
}

.mdi-file-lock-outline::before {
  content: "\f1030";
}

.mdi-file-marker::before {
  content: "\f1774";
}

.mdi-file-marker-outline::before {
  content: "\f1775";
}

.mdi-file-move::before {
  content: "\f0ab9";
}

.mdi-file-move-outline::before {
  content: "\f1031";
}

.mdi-file-multiple::before {
  content: "\f0222";
}

.mdi-file-multiple-outline::before {
  content: "\f1032";
}

.mdi-file-music::before {
  content: "\f0223";
}

.mdi-file-music-outline::before {
  content: "\f0e2a";
}

.mdi-file-outline::before {
  content: "\f0224";
}

.mdi-file-pdf-box::before {
  content: "\f0226";
}

.mdi-file-percent::before {
  content: "\f081e";
}

.mdi-file-percent-outline::before {
  content: "\f1033";
}

.mdi-file-phone::before {
  content: "\f1179";
}

.mdi-file-phone-outline::before {
  content: "\f117a";
}

.mdi-file-plus::before {
  content: "\f0752";
}

.mdi-file-plus-outline::before {
  content: "\f0eed";
}

.mdi-file-png-box::before {
  content: "\f0e2d";
}

.mdi-file-powerpoint::before {
  content: "\f0227";
}

.mdi-file-powerpoint-box::before {
  content: "\f0228";
}

.mdi-file-powerpoint-box-outline::before {
  content: "\f1034";
}

.mdi-file-powerpoint-outline::before {
  content: "\f1035";
}

.mdi-file-presentation-box::before {
  content: "\f0229";
}

.mdi-file-question::before {
  content: "\f086f";
}

.mdi-file-question-outline::before {
  content: "\f1036";
}

.mdi-file-refresh::before {
  content: "\f0918";
}

.mdi-file-refresh-outline::before {
  content: "\f0541";
}

.mdi-file-remove::before {
  content: "\f0b98";
}

.mdi-file-remove-outline::before {
  content: "\f1037";
}

.mdi-file-replace::before {
  content: "\f0b32";
}

.mdi-file-replace-outline::before {
  content: "\f0b33";
}

.mdi-file-restore::before {
  content: "\f0670";
}

.mdi-file-restore-outline::before {
  content: "\f1038";
}

.mdi-file-search::before {
  content: "\f0c7c";
}

.mdi-file-search-outline::before {
  content: "\f0c7d";
}

.mdi-file-send::before {
  content: "\f022a";
}

.mdi-file-send-outline::before {
  content: "\f1039";
}

.mdi-file-settings::before {
  content: "\f1079";
}

.mdi-file-settings-outline::before {
  content: "\f107a";
}

.mdi-file-sign::before {
  content: "\f19c3";
}

.mdi-file-star::before {
  content: "\f103a";
}

.mdi-file-star-outline::before {
  content: "\f103b";
}

.mdi-file-swap::before {
  content: "\f0fb4";
}

.mdi-file-swap-outline::before {
  content: "\f0fb5";
}

.mdi-file-sync::before {
  content: "\f1216";
}

.mdi-file-sync-outline::before {
  content: "\f1217";
}

.mdi-file-table::before {
  content: "\f0c7e";
}

.mdi-file-table-box::before {
  content: "\f10e1";
}

.mdi-file-table-box-multiple::before {
  content: "\f10e2";
}

.mdi-file-table-box-multiple-outline::before {
  content: "\f10e3";
}

.mdi-file-table-box-outline::before {
  content: "\f10e4";
}

.mdi-file-table-outline::before {
  content: "\f0c7f";
}

.mdi-file-tree::before {
  content: "\f0645";
}

.mdi-file-tree-outline::before {
  content: "\f13d2";
}

.mdi-file-undo::before {
  content: "\f08dc";
}

.mdi-file-undo-outline::before {
  content: "\f103c";
}

.mdi-file-upload::before {
  content: "\f0a4d";
}

.mdi-file-upload-outline::before {
  content: "\f0a4e";
}

.mdi-file-video::before {
  content: "\f022b";
}

.mdi-file-video-outline::before {
  content: "\f0e2c";
}

.mdi-file-word::before {
  content: "\f022c";
}

.mdi-file-word-box::before {
  content: "\f022d";
}

.mdi-file-word-box-outline::before {
  content: "\f103d";
}

.mdi-file-word-outline::before {
  content: "\f103e";
}

.mdi-film::before {
  content: "\f022f";
}

.mdi-filmstrip::before {
  content: "\f0230";
}

.mdi-filmstrip-box::before {
  content: "\f0332";
}

.mdi-filmstrip-box-multiple::before {
  content: "\f0d18";
}

.mdi-filmstrip-off::before {
  content: "\f0231";
}

.mdi-filter::before {
  content: "\f0232";
}

.mdi-filter-check::before {
  content: "\f18ec";
}

.mdi-filter-check-outline::before {
  content: "\f18ed";
}

.mdi-filter-menu::before {
  content: "\f10e5";
}

.mdi-filter-menu-outline::before {
  content: "\f10e6";
}

.mdi-filter-minus::before {
  content: "\f0eee";
}

.mdi-filter-minus-outline::before {
  content: "\f0eef";
}

.mdi-filter-off::before {
  content: "\f14ef";
}

.mdi-filter-off-outline::before {
  content: "\f14f0";
}

.mdi-filter-outline::before {
  content: "\f0233";
}

.mdi-filter-plus::before {
  content: "\f0ef0";
}

.mdi-filter-plus-outline::before {
  content: "\f0ef1";
}

.mdi-filter-remove::before {
  content: "\f0234";
}

.mdi-filter-remove-outline::before {
  content: "\f0235";
}

.mdi-filter-variant::before {
  content: "\f0236";
}

.mdi-filter-variant-minus::before {
  content: "\f1112";
}

.mdi-filter-variant-plus::before {
  content: "\f1113";
}

.mdi-filter-variant-remove::before {
  content: "\f103f";
}

.mdi-finance::before {
  content: "\f081f";
}

.mdi-find-replace::before {
  content: "\f06d4";
}

.mdi-fingerprint::before {
  content: "\f0237";
}

.mdi-fingerprint-off::before {
  content: "\f0eb1";
}

.mdi-fire::before {
  content: "\f0238";
}

.mdi-fire-alert::before {
  content: "\f15d7";
}

.mdi-fire-circle::before {
  content: "\f1807";
}

.mdi-fire-extinguisher::before {
  content: "\f0ef2";
}

.mdi-fire-hydrant::before {
  content: "\f1137";
}

.mdi-fire-hydrant-alert::before {
  content: "\f1138";
}

.mdi-fire-hydrant-off::before {
  content: "\f1139";
}

.mdi-fire-off::before {
  content: "\f1722";
}

.mdi-fire-truck::before {
  content: "\f08ab";
}

.mdi-firebase::before {
  content: "\f0967";
}

.mdi-firefox::before {
  content: "\f0239";
}

.mdi-fireplace::before {
  content: "\f0e2e";
}

.mdi-fireplace-off::before {
  content: "\f0e2f";
}

.mdi-firewire::before {
  content: "\f05be";
}

.mdi-firework::before {
  content: "\f0e30";
}

.mdi-firework-off::before {
  content: "\f1723";
}

.mdi-fish::before {
  content: "\f023a";
}

.mdi-fish-off::before {
  content: "\f13f3";
}

.mdi-fishbowl::before {
  content: "\f0ef3";
}

.mdi-fishbowl-outline::before {
  content: "\f0ef4";
}

.mdi-fit-to-page::before {
  content: "\f0ef5";
}

.mdi-fit-to-page-outline::before {
  content: "\f0ef6";
}

.mdi-fit-to-screen::before {
  content: "\f18f4";
}

.mdi-fit-to-screen-outline::before {
  content: "\f18f5";
}

.mdi-flag::before {
  content: "\f023b";
}

.mdi-flag-checkered::before {
  content: "\f023c";
}

.mdi-flag-minus::before {
  content: "\f0b99";
}

.mdi-flag-minus-outline::before {
  content: "\f10b2";
}

.mdi-flag-off::before {
  content: "\f18ee";
}

.mdi-flag-off-outline::before {
  content: "\f18ef";
}

.mdi-flag-outline::before {
  content: "\f023d";
}

.mdi-flag-plus::before {
  content: "\f0b9a";
}

.mdi-flag-plus-outline::before {
  content: "\f10b3";
}

.mdi-flag-remove::before {
  content: "\f0b9b";
}

.mdi-flag-remove-outline::before {
  content: "\f10b4";
}

.mdi-flag-triangle::before {
  content: "\f023f";
}

.mdi-flag-variant::before {
  content: "\f0240";
}

.mdi-flag-variant-outline::before {
  content: "\f023e";
}

.mdi-flare::before {
  content: "\f0d72";
}

.mdi-flash::before {
  content: "\f0241";
}

.mdi-flash-alert::before {
  content: "\f0ef7";
}

.mdi-flash-alert-outline::before {
  content: "\f0ef8";
}

.mdi-flash-auto::before {
  content: "\f0242";
}

.mdi-flash-off::before {
  content: "\f0243";
}

.mdi-flash-outline::before {
  content: "\f06d5";
}

.mdi-flash-red-eye::before {
  content: "\f067b";
}

.mdi-flashlight::before {
  content: "\f0244";
}

.mdi-flashlight-off::before {
  content: "\f0245";
}

.mdi-flask::before {
  content: "\f0093";
}

.mdi-flask-empty::before {
  content: "\f0094";
}

.mdi-flask-empty-minus::before {
  content: "\f123a";
}

.mdi-flask-empty-minus-outline::before {
  content: "\f123b";
}

.mdi-flask-empty-off::before {
  content: "\f13f4";
}

.mdi-flask-empty-off-outline::before {
  content: "\f13f5";
}

.mdi-flask-empty-outline::before {
  content: "\f0095";
}

.mdi-flask-empty-plus::before {
  content: "\f123c";
}

.mdi-flask-empty-plus-outline::before {
  content: "\f123d";
}

.mdi-flask-empty-remove::before {
  content: "\f123e";
}

.mdi-flask-empty-remove-outline::before {
  content: "\f123f";
}

.mdi-flask-minus::before {
  content: "\f1240";
}

.mdi-flask-minus-outline::before {
  content: "\f1241";
}

.mdi-flask-off::before {
  content: "\f13f6";
}

.mdi-flask-off-outline::before {
  content: "\f13f7";
}

.mdi-flask-outline::before {
  content: "\f0096";
}

.mdi-flask-plus::before {
  content: "\f1242";
}

.mdi-flask-plus-outline::before {
  content: "\f1243";
}

.mdi-flask-remove::before {
  content: "\f1244";
}

.mdi-flask-remove-outline::before {
  content: "\f1245";
}

.mdi-flask-round-bottom::before {
  content: "\f124b";
}

.mdi-flask-round-bottom-empty::before {
  content: "\f124c";
}

.mdi-flask-round-bottom-empty-outline::before {
  content: "\f124d";
}

.mdi-flask-round-bottom-outline::before {
  content: "\f124e";
}

.mdi-fleur-de-lis::before {
  content: "\f1303";
}

.mdi-flip-horizontal::before {
  content: "\f10e7";
}

.mdi-flip-to-back::before {
  content: "\f0247";
}

.mdi-flip-to-front::before {
  content: "\f0248";
}

.mdi-flip-vertical::before {
  content: "\f10e8";
}

.mdi-floor-lamp::before {
  content: "\f08dd";
}

.mdi-floor-lamp-dual::before {
  content: "\f1040";
}

.mdi-floor-lamp-dual-outline::before {
  content: "\f17ce";
}

.mdi-floor-lamp-outline::before {
  content: "\f17c8";
}

.mdi-floor-lamp-torchiere::before {
  content: "\f1747";
}

.mdi-floor-lamp-torchiere-outline::before {
  content: "\f17d6";
}

.mdi-floor-lamp-torchiere-variant::before {
  content: "\f1041";
}

.mdi-floor-lamp-torchiere-variant-outline::before {
  content: "\f17cf";
}

.mdi-floor-plan::before {
  content: "\f0821";
}

.mdi-floppy::before {
  content: "\f0249";
}

.mdi-floppy-variant::before {
  content: "\f09ef";
}

.mdi-flower::before {
  content: "\f024a";
}

.mdi-flower-outline::before {
  content: "\f09f0";
}

.mdi-flower-pollen::before {
  content: "\f1885";
}

.mdi-flower-pollen-outline::before {
  content: "\f1886";
}

.mdi-flower-poppy::before {
  content: "\f0d08";
}

.mdi-flower-tulip::before {
  content: "\f09f1";
}

.mdi-flower-tulip-outline::before {
  content: "\f09f2";
}

.mdi-focus-auto::before {
  content: "\f0f4e";
}

.mdi-focus-field::before {
  content: "\f0f4f";
}

.mdi-focus-field-horizontal::before {
  content: "\f0f50";
}

.mdi-focus-field-vertical::before {
  content: "\f0f51";
}

.mdi-folder::before {
  content: "\f024b";
}

.mdi-folder-account::before {
  content: "\f024c";
}

.mdi-folder-account-outline::before {
  content: "\f0b9c";
}

.mdi-folder-alert::before {
  content: "\f0dcc";
}

.mdi-folder-alert-outline::before {
  content: "\f0dcd";
}

.mdi-folder-check::before {
  content: "\f197e";
}

.mdi-folder-check-outline::before {
  content: "\f197f";
}

.mdi-folder-clock::before {
  content: "\f0aba";
}

.mdi-folder-clock-outline::before {
  content: "\f0abb";
}

.mdi-folder-cog::before {
  content: "\f107f";
}

.mdi-folder-cog-outline::before {
  content: "\f1080";
}

.mdi-folder-download::before {
  content: "\f024d";
}

.mdi-folder-download-outline::before {
  content: "\f10e9";
}

.mdi-folder-edit::before {
  content: "\f08de";
}

.mdi-folder-edit-outline::before {
  content: "\f0dce";
}

.mdi-folder-eye::before {
  content: "\f178a";
}

.mdi-folder-eye-outline::before {
  content: "\f178b";
}

.mdi-folder-google-drive::before {
  content: "\f024e";
}

.mdi-folder-heart::before {
  content: "\f10ea";
}

.mdi-folder-heart-outline::before {
  content: "\f10eb";
}

.mdi-folder-hidden::before {
  content: "\f179e";
}

.mdi-folder-home::before {
  content: "\f10b5";
}

.mdi-folder-home-outline::before {
  content: "\f10b6";
}

.mdi-folder-image::before {
  content: "\f024f";
}

.mdi-folder-information::before {
  content: "\f10b7";
}

.mdi-folder-information-outline::before {
  content: "\f10b8";
}

.mdi-folder-key::before {
  content: "\f08ac";
}

.mdi-folder-key-network::before {
  content: "\f08ad";
}

.mdi-folder-key-network-outline::before {
  content: "\f0c80";
}

.mdi-folder-key-outline::before {
  content: "\f10ec";
}

.mdi-folder-lock::before {
  content: "\f0250";
}

.mdi-folder-lock-open::before {
  content: "\f0251";
}

.mdi-folder-marker::before {
  content: "\f126d";
}

.mdi-folder-marker-outline::before {
  content: "\f126e";
}

.mdi-folder-move::before {
  content: "\f0252";
}

.mdi-folder-move-outline::before {
  content: "\f1246";
}

.mdi-folder-multiple::before {
  content: "\f0253";
}

.mdi-folder-multiple-image::before {
  content: "\f0254";
}

.mdi-folder-multiple-outline::before {
  content: "\f0255";
}

.mdi-folder-multiple-plus::before {
  content: "\f147e";
}

.mdi-folder-multiple-plus-outline::before {
  content: "\f147f";
}

.mdi-folder-music::before {
  content: "\f1359";
}

.mdi-folder-music-outline::before {
  content: "\f135a";
}

.mdi-folder-network::before {
  content: "\f0870";
}

.mdi-folder-network-outline::before {
  content: "\f0c81";
}

.mdi-folder-open::before {
  content: "\f0770";
}

.mdi-folder-open-outline::before {
  content: "\f0dcf";
}

.mdi-folder-outline::before {
  content: "\f0256";
}

.mdi-folder-plus::before {
  content: "\f0257";
}

.mdi-folder-plus-outline::before {
  content: "\f0b9d";
}

.mdi-folder-pound::before {
  content: "\f0d09";
}

.mdi-folder-pound-outline::before {
  content: "\f0d0a";
}

.mdi-folder-refresh::before {
  content: "\f0749";
}

.mdi-folder-refresh-outline::before {
  content: "\f0542";
}

.mdi-folder-remove::before {
  content: "\f0258";
}

.mdi-folder-remove-outline::before {
  content: "\f0b9e";
}

.mdi-folder-search::before {
  content: "\f0968";
}

.mdi-folder-search-outline::before {
  content: "\f0969";
}

.mdi-folder-settings::before {
  content: "\f107d";
}

.mdi-folder-settings-outline::before {
  content: "\f107e";
}

.mdi-folder-star::before {
  content: "\f069d";
}

.mdi-folder-star-multiple::before {
  content: "\f13d3";
}

.mdi-folder-star-multiple-outline::before {
  content: "\f13d4";
}

.mdi-folder-star-outline::before {
  content: "\f0b9f";
}

.mdi-folder-swap::before {
  content: "\f0fb6";
}

.mdi-folder-swap-outline::before {
  content: "\f0fb7";
}

.mdi-folder-sync::before {
  content: "\f0d0b";
}

.mdi-folder-sync-outline::before {
  content: "\f0d0c";
}

.mdi-folder-table::before {
  content: "\f12e3";
}

.mdi-folder-table-outline::before {
  content: "\f12e4";
}

.mdi-folder-text::before {
  content: "\f0c82";
}

.mdi-folder-text-outline::before {
  content: "\f0c83";
}

.mdi-folder-upload::before {
  content: "\f0259";
}

.mdi-folder-upload-outline::before {
  content: "\f10ed";
}

.mdi-folder-zip::before {
  content: "\f06eb";
}

.mdi-folder-zip-outline::before {
  content: "\f07b9";
}

.mdi-font-awesome::before {
  content: "\f003a";
}

.mdi-food::before {
  content: "\f025a";
}

.mdi-food-apple::before {
  content: "\f025b";
}

.mdi-food-apple-outline::before {
  content: "\f0c84";
}

.mdi-food-croissant::before {
  content: "\f07c8";
}

.mdi-food-drumstick::before {
  content: "\f141f";
}

.mdi-food-drumstick-off::before {
  content: "\f1468";
}

.mdi-food-drumstick-off-outline::before {
  content: "\f1469";
}

.mdi-food-drumstick-outline::before {
  content: "\f1420";
}

.mdi-food-fork-drink::before {
  content: "\f05f2";
}

.mdi-food-halal::before {
  content: "\f1572";
}

.mdi-food-hot-dog::before {
  content: "\f184b";
}

.mdi-food-kosher::before {
  content: "\f1573";
}

.mdi-food-off::before {
  content: "\f05f3";
}

.mdi-food-off-outline::before {
  content: "\f1915";
}

.mdi-food-outline::before {
  content: "\f1916";
}

.mdi-food-steak::before {
  content: "\f146a";
}

.mdi-food-steak-off::before {
  content: "\f146b";
}

.mdi-food-takeout-box::before {
  content: "\f1836";
}

.mdi-food-takeout-box-outline::before {
  content: "\f1837";
}

.mdi-food-turkey::before {
  content: "\f171c";
}

.mdi-food-variant::before {
  content: "\f025c";
}

.mdi-food-variant-off::before {
  content: "\f13e5";
}

.mdi-foot-print::before {
  content: "\f0f52";
}

.mdi-football::before {
  content: "\f025d";
}

.mdi-football-australian::before {
  content: "\f025e";
}

.mdi-football-helmet::before {
  content: "\f025f";
}

.mdi-forest::before {
  content: "\f1897";
}

.mdi-forklift::before {
  content: "\f07c9";
}

.mdi-form-dropdown::before {
  content: "\f1400";
}

.mdi-form-select::before {
  content: "\f1401";
}

.mdi-form-textarea::before {
  content: "\f1095";
}

.mdi-form-textbox::before {
  content: "\f060e";
}

.mdi-form-textbox-lock::before {
  content: "\f135d";
}

.mdi-form-textbox-password::before {
  content: "\f07f5";
}

.mdi-format-align-bottom::before {
  content: "\f0753";
}

.mdi-format-align-center::before {
  content: "\f0260";
}

.mdi-format-align-justify::before {
  content: "\f0261";
}

.mdi-format-align-left::before {
  content: "\f0262";
}

.mdi-format-align-middle::before {
  content: "\f0754";
}

.mdi-format-align-right::before {
  content: "\f0263";
}

.mdi-format-align-top::before {
  content: "\f0755";
}

.mdi-format-annotation-minus::before {
  content: "\f0abc";
}

.mdi-format-annotation-plus::before {
  content: "\f0646";
}

.mdi-format-bold::before {
  content: "\f0264";
}

.mdi-format-clear::before {
  content: "\f0265";
}

.mdi-format-color-fill::before {
  content: "\f0266";
}

.mdi-format-color-highlight::before {
  content: "\f0e31";
}

.mdi-format-color-marker-cancel::before {
  content: "\f1313";
}

.mdi-format-color-text::before {
  content: "\f069e";
}

.mdi-format-columns::before {
  content: "\f08df";
}

.mdi-format-float-center::before {
  content: "\f0267";
}

.mdi-format-float-left::before {
  content: "\f0268";
}

.mdi-format-float-none::before {
  content: "\f0269";
}

.mdi-format-float-right::before {
  content: "\f026a";
}

.mdi-format-font::before {
  content: "\f06d6";
}

.mdi-format-font-size-decrease::before {
  content: "\f09f3";
}

.mdi-format-font-size-increase::before {
  content: "\f09f4";
}

.mdi-format-header-1::before {
  content: "\f026b";
}

.mdi-format-header-2::before {
  content: "\f026c";
}

.mdi-format-header-3::before {
  content: "\f026d";
}

.mdi-format-header-4::before {
  content: "\f026e";
}

.mdi-format-header-5::before {
  content: "\f026f";
}

.mdi-format-header-6::before {
  content: "\f0270";
}

.mdi-format-header-decrease::before {
  content: "\f0271";
}

.mdi-format-header-equal::before {
  content: "\f0272";
}

.mdi-format-header-increase::before {
  content: "\f0273";
}

.mdi-format-header-pound::before {
  content: "\f0274";
}

.mdi-format-horizontal-align-center::before {
  content: "\f061e";
}

.mdi-format-horizontal-align-left::before {
  content: "\f061f";
}

.mdi-format-horizontal-align-right::before {
  content: "\f0620";
}

.mdi-format-indent-decrease::before {
  content: "\f0275";
}

.mdi-format-indent-increase::before {
  content: "\f0276";
}

.mdi-format-italic::before {
  content: "\f0277";
}

.mdi-format-letter-case::before {
  content: "\f0b34";
}

.mdi-format-letter-case-lower::before {
  content: "\f0b35";
}

.mdi-format-letter-case-upper::before {
  content: "\f0b36";
}

.mdi-format-letter-ends-with::before {
  content: "\f0fb8";
}

.mdi-format-letter-matches::before {
  content: "\f0fb9";
}

.mdi-format-letter-spacing::before {
  content: "\f1956";
}

.mdi-format-letter-starts-with::before {
  content: "\f0fba";
}

.mdi-format-line-spacing::before {
  content: "\f0278";
}

.mdi-format-line-style::before {
  content: "\f05c8";
}

.mdi-format-line-weight::before {
  content: "\f05c9";
}

.mdi-format-list-bulleted::before {
  content: "\f0279";
}

.mdi-format-list-bulleted-square::before {
  content: "\f0dd0";
}

.mdi-format-list-bulleted-triangle::before {
  content: "\f0eb2";
}

.mdi-format-list-bulleted-type::before {
  content: "\f027a";
}

.mdi-format-list-checkbox::before {
  content: "\f096a";
}

.mdi-format-list-checks::before {
  content: "\f0756";
}

.mdi-format-list-group::before {
  content: "\f1860";
}

.mdi-format-list-numbered::before {
  content: "\f027b";
}

.mdi-format-list-numbered-rtl::before {
  content: "\f0d0d";
}

.mdi-format-list-text::before {
  content: "\f126f";
}

.mdi-format-overline::before {
  content: "\f0eb3";
}

.mdi-format-page-break::before {
  content: "\f06d7";
}

.mdi-format-page-split::before {
  content: "\f1917";
}

.mdi-format-paint::before {
  content: "\f027c";
}

.mdi-format-paragraph::before {
  content: "\f027d";
}

.mdi-format-pilcrow::before {
  content: "\f06d8";
}

.mdi-format-quote-close::before {
  content: "\f027e";
}

.mdi-format-quote-close-outline::before {
  content: "\f11a8";
}

.mdi-format-quote-open::before {
  content: "\f0757";
}

.mdi-format-quote-open-outline::before {
  content: "\f11a7";
}

.mdi-format-rotate-90::before {
  content: "\f06aa";
}

.mdi-format-section::before {
  content: "\f069f";
}

.mdi-format-size::before {
  content: "\f027f";
}

.mdi-format-strikethrough::before {
  content: "\f0280";
}

.mdi-format-strikethrough-variant::before {
  content: "\f0281";
}

.mdi-format-subscript::before {
  content: "\f0282";
}

.mdi-format-superscript::before {
  content: "\f0283";
}

.mdi-format-text::before {
  content: "\f0284";
}

.mdi-format-text-rotation-angle-down::before {
  content: "\f0fbb";
}

.mdi-format-text-rotation-angle-up::before {
  content: "\f0fbc";
}

.mdi-format-text-rotation-down::before {
  content: "\f0d73";
}

.mdi-format-text-rotation-down-vertical::before {
  content: "\f0fbd";
}

.mdi-format-text-rotation-none::before {
  content: "\f0d74";
}

.mdi-format-text-rotation-up::before {
  content: "\f0fbe";
}

.mdi-format-text-rotation-vertical::before {
  content: "\f0fbf";
}

.mdi-format-text-variant::before {
  content: "\f0e32";
}

.mdi-format-text-variant-outline::before {
  content: "\f150f";
}

.mdi-format-text-wrapping-clip::before {
  content: "\f0d0e";
}

.mdi-format-text-wrapping-overflow::before {
  content: "\f0d0f";
}

.mdi-format-text-wrapping-wrap::before {
  content: "\f0d10";
}

.mdi-format-textbox::before {
  content: "\f0d11";
}

.mdi-format-textdirection-l-to-r::before {
  content: "\f0285";
}

.mdi-format-textdirection-r-to-l::before {
  content: "\f0286";
}

.mdi-format-title::before {
  content: "\f05f4";
}

.mdi-format-underline::before {
  content: "\f0287";
}

.mdi-format-underline-wavy::before {
  content: "\f18e9";
}

.mdi-format-vertical-align-bottom::before {
  content: "\f0621";
}

.mdi-format-vertical-align-center::before {
  content: "\f0622";
}

.mdi-format-vertical-align-top::before {
  content: "\f0623";
}

.mdi-format-wrap-inline::before {
  content: "\f0288";
}

.mdi-format-wrap-square::before {
  content: "\f0289";
}

.mdi-format-wrap-tight::before {
  content: "\f028a";
}

.mdi-format-wrap-top-bottom::before {
  content: "\f028b";
}

.mdi-forum::before {
  content: "\f028c";
}

.mdi-forum-outline::before {
  content: "\f0822";
}

.mdi-forward::before {
  content: "\f028d";
}

.mdi-forwardburger::before {
  content: "\f0d75";
}

.mdi-fountain::before {
  content: "\f096b";
}

.mdi-fountain-pen::before {
  content: "\f0d12";
}

.mdi-fountain-pen-tip::before {
  content: "\f0d13";
}

.mdi-fraction-one-half::before {
  content: "\f1992";
}

.mdi-freebsd::before {
  content: "\f08e0";
}

.mdi-french-fries::before {
  content: "\f1957";
}

.mdi-frequently-asked-questions::before {
  content: "\f0eb4";
}

.mdi-fridge::before {
  content: "\f0290";
}

.mdi-fridge-alert::before {
  content: "\f11b1";
}

.mdi-fridge-alert-outline::before {
  content: "\f11b2";
}

.mdi-fridge-bottom::before {
  content: "\f0292";
}

.mdi-fridge-industrial::before {
  content: "\f15ee";
}

.mdi-fridge-industrial-alert::before {
  content: "\f15ef";
}

.mdi-fridge-industrial-alert-outline::before {
  content: "\f15f0";
}

.mdi-fridge-industrial-off::before {
  content: "\f15f1";
}

.mdi-fridge-industrial-off-outline::before {
  content: "\f15f2";
}

.mdi-fridge-industrial-outline::before {
  content: "\f15f3";
}

.mdi-fridge-off::before {
  content: "\f11af";
}

.mdi-fridge-off-outline::before {
  content: "\f11b0";
}

.mdi-fridge-outline::before {
  content: "\f028f";
}

.mdi-fridge-top::before {
  content: "\f0291";
}

.mdi-fridge-variant::before {
  content: "\f15f4";
}

.mdi-fridge-variant-alert::before {
  content: "\f15f5";
}

.mdi-fridge-variant-alert-outline::before {
  content: "\f15f6";
}

.mdi-fridge-variant-off::before {
  content: "\f15f7";
}

.mdi-fridge-variant-off-outline::before {
  content: "\f15f8";
}

.mdi-fridge-variant-outline::before {
  content: "\f15f9";
}

.mdi-fruit-cherries::before {
  content: "\f1042";
}

.mdi-fruit-cherries-off::before {
  content: "\f13f8";
}

.mdi-fruit-citrus::before {
  content: "\f1043";
}

.mdi-fruit-citrus-off::before {
  content: "\f13f9";
}

.mdi-fruit-grapes::before {
  content: "\f1044";
}

.mdi-fruit-grapes-outline::before {
  content: "\f1045";
}

.mdi-fruit-pineapple::before {
  content: "\f1046";
}

.mdi-fruit-watermelon::before {
  content: "\f1047";
}

.mdi-fuel::before {
  content: "\f07ca";
}

.mdi-fuel-cell::before {
  content: "\f18b5";
}

.mdi-fullscreen::before {
  content: "\f0293";
}

.mdi-fullscreen-exit::before {
  content: "\f0294";
}

.mdi-function::before {
  content: "\f0295";
}

.mdi-function-variant::before {
  content: "\f0871";
}

.mdi-furigana-horizontal::before {
  content: "\f1081";
}

.mdi-furigana-vertical::before {
  content: "\f1082";
}

.mdi-fuse::before {
  content: "\f0c85";
}

.mdi-fuse-alert::before {
  content: "\f142d";
}

.mdi-fuse-blade::before {
  content: "\f0c86";
}

.mdi-fuse-off::before {
  content: "\f142c";
}

.mdi-gamepad::before {
  content: "\f0296";
}

.mdi-gamepad-circle::before {
  content: "\f0e33";
}

.mdi-gamepad-circle-down::before {
  content: "\f0e34";
}

.mdi-gamepad-circle-left::before {
  content: "\f0e35";
}

.mdi-gamepad-circle-outline::before {
  content: "\f0e36";
}

.mdi-gamepad-circle-right::before {
  content: "\f0e37";
}

.mdi-gamepad-circle-up::before {
  content: "\f0e38";
}

.mdi-gamepad-down::before {
  content: "\f0e39";
}

.mdi-gamepad-left::before {
  content: "\f0e3a";
}

.mdi-gamepad-outline::before {
  content: "\f1919";
}

.mdi-gamepad-right::before {
  content: "\f0e3b";
}

.mdi-gamepad-round::before {
  content: "\f0e3c";
}

.mdi-gamepad-round-down::before {
  content: "\f0e3d";
}

.mdi-gamepad-round-left::before {
  content: "\f0e3e";
}

.mdi-gamepad-round-outline::before {
  content: "\f0e3f";
}

.mdi-gamepad-round-right::before {
  content: "\f0e40";
}

.mdi-gamepad-round-up::before {
  content: "\f0e41";
}

.mdi-gamepad-square::before {
  content: "\f0eb5";
}

.mdi-gamepad-square-outline::before {
  content: "\f0eb6";
}

.mdi-gamepad-up::before {
  content: "\f0e42";
}

.mdi-gamepad-variant::before {
  content: "\f0297";
}

.mdi-gamepad-variant-outline::before {
  content: "\f0eb7";
}

.mdi-gamma::before {
  content: "\f10ee";
}

.mdi-gantry-crane::before {
  content: "\f0dd1";
}

.mdi-garage::before {
  content: "\f06d9";
}

.mdi-garage-alert::before {
  content: "\f0872";
}

.mdi-garage-alert-variant::before {
  content: "\f12d5";
}

.mdi-garage-lock::before {
  content: "\f17fb";
}

.mdi-garage-open::before {
  content: "\f06da";
}

.mdi-garage-open-variant::before {
  content: "\f12d4";
}

.mdi-garage-variant::before {
  content: "\f12d3";
}

.mdi-garage-variant-lock::before {
  content: "\f17fc";
}

.mdi-gas-cylinder::before {
  content: "\f0647";
}

.mdi-gas-station::before {
  content: "\f0298";
}

.mdi-gas-station-off::before {
  content: "\f1409";
}

.mdi-gas-station-off-outline::before {
  content: "\f140a";
}

.mdi-gas-station-outline::before {
  content: "\f0eb8";
}

.mdi-gate::before {
  content: "\f0299";
}

.mdi-gate-alert::before {
  content: "\f17f8";
}

.mdi-gate-and::before {
  content: "\f08e1";
}

.mdi-gate-arrow-left::before {
  content: "\f17f7";
}

.mdi-gate-arrow-right::before {
  content: "\f1169";
}

.mdi-gate-nand::before {
  content: "\f08e2";
}

.mdi-gate-nor::before {
  content: "\f08e3";
}

.mdi-gate-not::before {
  content: "\f08e4";
}

.mdi-gate-open::before {
  content: "\f116a";
}

.mdi-gate-or::before {
  content: "\f08e5";
}

.mdi-gate-xnor::before {
  content: "\f08e6";
}

.mdi-gate-xor::before {
  content: "\f08e7";
}

.mdi-gatsby::before {
  content: "\f0e43";
}

.mdi-gauge::before {
  content: "\f029a";
}

.mdi-gauge-empty::before {
  content: "\f0873";
}

.mdi-gauge-full::before {
  content: "\f0874";
}

.mdi-gauge-low::before {
  content: "\f0875";
}

.mdi-gavel::before {
  content: "\f029b";
}

.mdi-gender-female::before {
  content: "\f029c";
}

.mdi-gender-male::before {
  content: "\f029d";
}

.mdi-gender-male-female::before {
  content: "\f029e";
}

.mdi-gender-male-female-variant::before {
  content: "\f113f";
}

.mdi-gender-non-binary::before {
  content: "\f1140";
}

.mdi-gender-transgender::before {
  content: "\f029f";
}

.mdi-gentoo::before {
  content: "\f08e8";
}

.mdi-gesture::before {
  content: "\f07cb";
}

.mdi-gesture-double-tap::before {
  content: "\f073c";
}

.mdi-gesture-pinch::before {
  content: "\f0abd";
}

.mdi-gesture-spread::before {
  content: "\f0abe";
}

.mdi-gesture-swipe::before {
  content: "\f0d76";
}

.mdi-gesture-swipe-down::before {
  content: "\f073d";
}

.mdi-gesture-swipe-horizontal::before {
  content: "\f0abf";
}

.mdi-gesture-swipe-left::before {
  content: "\f073e";
}

.mdi-gesture-swipe-right::before {
  content: "\f073f";
}

.mdi-gesture-swipe-up::before {
  content: "\f0740";
}

.mdi-gesture-swipe-vertical::before {
  content: "\f0ac0";
}

.mdi-gesture-tap::before {
  content: "\f0741";
}

.mdi-gesture-tap-box::before {
  content: "\f12a9";
}

.mdi-gesture-tap-button::before {
  content: "\f12a8";
}

.mdi-gesture-tap-hold::before {
  content: "\f0d77";
}

.mdi-gesture-two-double-tap::before {
  content: "\f0742";
}

.mdi-gesture-two-tap::before {
  content: "\f0743";
}

.mdi-ghost::before {
  content: "\f02a0";
}

.mdi-ghost-off::before {
  content: "\f09f5";
}

.mdi-ghost-off-outline::before {
  content: "\f165c";
}

.mdi-ghost-outline::before {
  content: "\f165d";
}

.mdi-gift::before {
  content: "\f0e44";
}

.mdi-gift-off::before {
  content: "\f16ef";
}

.mdi-gift-off-outline::before {
  content: "\f16f0";
}

.mdi-gift-open::before {
  content: "\f16f1";
}

.mdi-gift-open-outline::before {
  content: "\f16f2";
}

.mdi-gift-outline::before {
  content: "\f02a1";
}

.mdi-git::before {
  content: "\f02a2";
}

.mdi-github::before {
  content: "\f02a4";
}

.mdi-gitlab::before {
  content: "\f0ba0";
}

.mdi-glass-cocktail::before {
  content: "\f0356";
}

.mdi-glass-cocktail-off::before {
  content: "\f15e6";
}

.mdi-glass-flute::before {
  content: "\f02a5";
}

.mdi-glass-fragile::before {
  content: "\f1873";
}

.mdi-glass-mug::before {
  content: "\f02a6";
}

.mdi-glass-mug-off::before {
  content: "\f15e7";
}

.mdi-glass-mug-variant::before {
  content: "\f1116";
}

.mdi-glass-mug-variant-off::before {
  content: "\f15e8";
}

.mdi-glass-pint-outline::before {
  content: "\f130d";
}

.mdi-glass-stange::before {
  content: "\f02a7";
}

.mdi-glass-tulip::before {
  content: "\f02a8";
}

.mdi-glass-wine::before {
  content: "\f0876";
}

.mdi-glasses::before {
  content: "\f02aa";
}

.mdi-globe-light::before {
  content: "\f12d7";
}

.mdi-globe-model::before {
  content: "\f08e9";
}

.mdi-gmail::before {
  content: "\f02ab";
}

.mdi-gnome::before {
  content: "\f02ac";
}

.mdi-go-kart::before {
  content: "\f0d79";
}

.mdi-go-kart-track::before {
  content: "\f0d7a";
}

.mdi-gog::before {
  content: "\f0ba1";
}

.mdi-gold::before {
  content: "\f124f";
}

.mdi-golf::before {
  content: "\f0823";
}

.mdi-golf-cart::before {
  content: "\f11a4";
}

.mdi-golf-tee::before {
  content: "\f1083";
}

.mdi-gondola::before {
  content: "\f0686";
}

.mdi-goodreads::before {
  content: "\f0d7b";
}

.mdi-google::before {
  content: "\f02ad";
}

.mdi-google-ads::before {
  content: "\f0c87";
}

.mdi-google-analytics::before {
  content: "\f07cc";
}

.mdi-google-assistant::before {
  content: "\f07cd";
}

.mdi-google-cardboard::before {
  content: "\f02ae";
}

.mdi-google-chrome::before {
  content: "\f02af";
}

.mdi-google-circles::before {
  content: "\f02b0";
}

.mdi-google-circles-communities::before {
  content: "\f02b1";
}

.mdi-google-circles-extended::before {
  content: "\f02b2";
}

.mdi-google-circles-group::before {
  content: "\f02b3";
}

.mdi-google-classroom::before {
  content: "\f02c0";
}

.mdi-google-cloud::before {
  content: "\f11f6";
}

.mdi-google-controller::before {
  content: "\f02b4";
}

.mdi-google-controller-off::before {
  content: "\f02b5";
}

.mdi-google-downasaur::before {
  content: "\f1362";
}

.mdi-google-drive::before {
  content: "\f02b6";
}

.mdi-google-earth::before {
  content: "\f02b7";
}

.mdi-google-fit::before {
  content: "\f096c";
}

.mdi-google-glass::before {
  content: "\f02b8";
}

.mdi-google-hangouts::before {
  content: "\f02c9";
}

.mdi-google-home::before {
  content: "\f0824";
}

.mdi-google-keep::before {
  content: "\f06dc";
}

.mdi-google-lens::before {
  content: "\f09f6";
}

.mdi-google-maps::before {
  content: "\f05f5";
}

.mdi-google-my-business::before {
  content: "\f1048";
}

.mdi-google-nearby::before {
  content: "\f02b9";
}

.mdi-google-play::before {
  content: "\f02bc";
}

.mdi-google-plus::before {
  content: "\f02bd";
}

.mdi-google-podcast::before {
  content: "\f0eb9";
}

.mdi-google-spreadsheet::before {
  content: "\f09f7";
}

.mdi-google-street-view::before {
  content: "\f0c88";
}

.mdi-google-translate::before {
  content: "\f02bf";
}

.mdi-gradient-horizontal::before {
  content: "\f174a";
}

.mdi-gradient-vertical::before {
  content: "\f06a0";
}

.mdi-grain::before {
  content: "\f0d7c";
}

.mdi-graph::before {
  content: "\f1049";
}

.mdi-graph-outline::before {
  content: "\f104a";
}

.mdi-graphql::before {
  content: "\f0877";
}

.mdi-grass::before {
  content: "\f1510";
}

.mdi-grave-stone::before {
  content: "\f0ba2";
}

.mdi-grease-pencil::before {
  content: "\f0648";
}

.mdi-greater-than::before {
  content: "\f096d";
}

.mdi-greater-than-or-equal::before {
  content: "\f096e";
}

.mdi-greenhouse::before {
  content: "\f002d";
}

.mdi-grid::before {
  content: "\f02c1";
}

.mdi-grid-large::before {
  content: "\f0758";
}

.mdi-grid-off::before {
  content: "\f02c2";
}

.mdi-grill::before {
  content: "\f0e45";
}

.mdi-grill-outline::before {
  content: "\f118a";
}

.mdi-group::before {
  content: "\f02c3";
}

.mdi-guitar-acoustic::before {
  content: "\f0771";
}

.mdi-guitar-electric::before {
  content: "\f02c4";
}

.mdi-guitar-pick::before {
  content: "\f02c5";
}

.mdi-guitar-pick-outline::before {
  content: "\f02c6";
}

.mdi-guy-fawkes-mask::before {
  content: "\f0825";
}

.mdi-hail::before {
  content: "\f0ac1";
}

.mdi-hair-dryer::before {
  content: "\f10ef";
}

.mdi-hair-dryer-outline::before {
  content: "\f10f0";
}

.mdi-halloween::before {
  content: "\f0ba3";
}

.mdi-hamburger::before {
  content: "\f0685";
}

.mdi-hamburger-check::before {
  content: "\f1776";
}

.mdi-hamburger-minus::before {
  content: "\f1777";
}

.mdi-hamburger-off::before {
  content: "\f1778";
}

.mdi-hamburger-plus::before {
  content: "\f1779";
}

.mdi-hamburger-remove::before {
  content: "\f177a";
}

.mdi-hammer::before {
  content: "\f08ea";
}

.mdi-hammer-screwdriver::before {
  content: "\f1322";
}

.mdi-hammer-sickle::before {
  content: "\f1887";
}

.mdi-hammer-wrench::before {
  content: "\f1323";
}

.mdi-hand-back-left::before {
  content: "\f0e46";
}

.mdi-hand-back-left-off::before {
  content: "\f1830";
}

.mdi-hand-back-left-off-outline::before {
  content: "\f1832";
}

.mdi-hand-back-left-outline::before {
  content: "\f182c";
}

.mdi-hand-back-right::before {
  content: "\f0e47";
}

.mdi-hand-back-right-off::before {
  content: "\f1831";
}

.mdi-hand-back-right-off-outline::before {
  content: "\f1833";
}

.mdi-hand-back-right-outline::before {
  content: "\f182d";
}

.mdi-hand-clap::before {
  content: "\f194b";
}

.mdi-hand-coin::before {
  content: "\f188f";
}

.mdi-hand-coin-outline::before {
  content: "\f1890";
}

.mdi-hand-extended::before {
  content: "\f18b6";
}

.mdi-hand-extended-outline::before {
  content: "\f18b7";
}

.mdi-hand-front-left::before {
  content: "\f182b";
}

.mdi-hand-front-left-outline::before {
  content: "\f182e";
}

.mdi-hand-front-right::before {
  content: "\f0a4f";
}

.mdi-hand-front-right-outline::before {
  content: "\f182f";
}

.mdi-hand-heart::before {
  content: "\f10f1";
}

.mdi-hand-heart-outline::before {
  content: "\f157e";
}

.mdi-hand-okay::before {
  content: "\f0a50";
}

.mdi-hand-peace::before {
  content: "\f0a51";
}

.mdi-hand-peace-variant::before {
  content: "\f0a52";
}

.mdi-hand-pointing-down::before {
  content: "\f0a53";
}

.mdi-hand-pointing-left::before {
  content: "\f0a54";
}

.mdi-hand-pointing-right::before {
  content: "\f02c7";
}

.mdi-hand-pointing-up::before {
  content: "\f0a55";
}

.mdi-hand-saw::before {
  content: "\f0e48";
}

.mdi-hand-wash::before {
  content: "\f157f";
}

.mdi-hand-wash-outline::before {
  content: "\f1580";
}

.mdi-hand-water::before {
  content: "\f139f";
}

.mdi-hand-wave::before {
  content: "\f1821";
}

.mdi-hand-wave-outline::before {
  content: "\f1822";
}

.mdi-handball::before {
  content: "\f0f53";
}

.mdi-handcuffs::before {
  content: "\f113e";
}

.mdi-hands-pray::before {
  content: "\f0579";
}

.mdi-handshake::before {
  content: "\f1218";
}

.mdi-handshake-outline::before {
  content: "\f15a1";
}

.mdi-hanger::before {
  content: "\f02c8";
}

.mdi-hard-hat::before {
  content: "\f096f";
}

.mdi-harddisk::before {
  content: "\f02ca";
}

.mdi-harddisk-plus::before {
  content: "\f104b";
}

.mdi-harddisk-remove::before {
  content: "\f104c";
}

.mdi-hat-fedora::before {
  content: "\f0ba4";
}

.mdi-hazard-lights::before {
  content: "\f0c89";
}

.mdi-hdr::before {
  content: "\f0d7d";
}

.mdi-hdr-off::before {
  content: "\f0d7e";
}

.mdi-head::before {
  content: "\f135e";
}

.mdi-head-alert::before {
  content: "\f1338";
}

.mdi-head-alert-outline::before {
  content: "\f1339";
}

.mdi-head-check::before {
  content: "\f133a";
}

.mdi-head-check-outline::before {
  content: "\f133b";
}

.mdi-head-cog::before {
  content: "\f133c";
}

.mdi-head-cog-outline::before {
  content: "\f133d";
}

.mdi-head-dots-horizontal::before {
  content: "\f133e";
}

.mdi-head-dots-horizontal-outline::before {
  content: "\f133f";
}

.mdi-head-flash::before {
  content: "\f1340";
}

.mdi-head-flash-outline::before {
  content: "\f1341";
}

.mdi-head-heart::before {
  content: "\f1342";
}

.mdi-head-heart-outline::before {
  content: "\f1343";
}

.mdi-head-lightbulb::before {
  content: "\f1344";
}

.mdi-head-lightbulb-outline::before {
  content: "\f1345";
}

.mdi-head-minus::before {
  content: "\f1346";
}

.mdi-head-minus-outline::before {
  content: "\f1347";
}

.mdi-head-outline::before {
  content: "\f135f";
}

.mdi-head-plus::before {
  content: "\f1348";
}

.mdi-head-plus-outline::before {
  content: "\f1349";
}

.mdi-head-question::before {
  content: "\f134a";
}

.mdi-head-question-outline::before {
  content: "\f134b";
}

.mdi-head-remove::before {
  content: "\f134c";
}

.mdi-head-remove-outline::before {
  content: "\f134d";
}

.mdi-head-snowflake::before {
  content: "\f134e";
}

.mdi-head-snowflake-outline::before {
  content: "\f134f";
}

.mdi-head-sync::before {
  content: "\f1350";
}

.mdi-head-sync-outline::before {
  content: "\f1351";
}

.mdi-headphones::before {
  content: "\f02cb";
}

.mdi-headphones-bluetooth::before {
  content: "\f0970";
}

.mdi-headphones-box::before {
  content: "\f02cc";
}

.mdi-headphones-off::before {
  content: "\f07ce";
}

.mdi-headphones-settings::before {
  content: "\f02cd";
}

.mdi-headset::before {
  content: "\f02ce";
}

.mdi-headset-dock::before {
  content: "\f02cf";
}

.mdi-headset-off::before {
  content: "\f02d0";
}

.mdi-heart::before {
  content: "\f02d1";
}

.mdi-heart-box::before {
  content: "\f02d2";
}

.mdi-heart-box-outline::before {
  content: "\f02d3";
}

.mdi-heart-broken::before {
  content: "\f02d4";
}

.mdi-heart-broken-outline::before {
  content: "\f0d14";
}

.mdi-heart-circle::before {
  content: "\f0971";
}

.mdi-heart-circle-outline::before {
  content: "\f0972";
}

.mdi-heart-cog::before {
  content: "\f1663";
}

.mdi-heart-cog-outline::before {
  content: "\f1664";
}

.mdi-heart-flash::before {
  content: "\f0ef9";
}

.mdi-heart-half::before {
  content: "\f06df";
}

.mdi-heart-half-full::before {
  content: "\f06de";
}

.mdi-heart-half-outline::before {
  content: "\f06e0";
}

.mdi-heart-minus::before {
  content: "\f142f";
}

.mdi-heart-minus-outline::before {
  content: "\f1432";
}

.mdi-heart-multiple::before {
  content: "\f0a56";
}

.mdi-heart-multiple-outline::before {
  content: "\f0a57";
}

.mdi-heart-off::before {
  content: "\f0759";
}

.mdi-heart-off-outline::before {
  content: "\f1434";
}

.mdi-heart-outline::before {
  content: "\f02d5";
}

.mdi-heart-plus::before {
  content: "\f142e";
}

.mdi-heart-plus-outline::before {
  content: "\f1431";
}

.mdi-heart-pulse::before {
  content: "\f05f6";
}

.mdi-heart-remove::before {
  content: "\f1430";
}

.mdi-heart-remove-outline::before {
  content: "\f1433";
}

.mdi-heart-settings::before {
  content: "\f1665";
}

.mdi-heart-settings-outline::before {
  content: "\f1666";
}

.mdi-helicopter::before {
  content: "\f0ac2";
}

.mdi-help::before {
  content: "\f02d6";
}

.mdi-help-box::before {
  content: "\f078b";
}

.mdi-help-circle::before {
  content: "\f02d7";
}

.mdi-help-circle-outline::before {
  content: "\f0625";
}

.mdi-help-network::before {
  content: "\f06f5";
}

.mdi-help-network-outline::before {
  content: "\f0c8a";
}

.mdi-help-rhombus::before {
  content: "\f0ba5";
}

.mdi-help-rhombus-outline::before {
  content: "\f0ba6";
}

.mdi-hexadecimal::before {
  content: "\f12a7";
}

.mdi-hexagon::before {
  content: "\f02d8";
}

.mdi-hexagon-multiple::before {
  content: "\f06e1";
}

.mdi-hexagon-multiple-outline::before {
  content: "\f10f2";
}

.mdi-hexagon-outline::before {
  content: "\f02d9";
}

.mdi-hexagon-slice-1::before {
  content: "\f0ac3";
}

.mdi-hexagon-slice-2::before {
  content: "\f0ac4";
}

.mdi-hexagon-slice-3::before {
  content: "\f0ac5";
}

.mdi-hexagon-slice-4::before {
  content: "\f0ac6";
}

.mdi-hexagon-slice-5::before {
  content: "\f0ac7";
}

.mdi-hexagon-slice-6::before {
  content: "\f0ac8";
}

.mdi-hexagram::before {
  content: "\f0ac9";
}

.mdi-hexagram-outline::before {
  content: "\f0aca";
}

.mdi-high-definition::before {
  content: "\f07cf";
}

.mdi-high-definition-box::before {
  content: "\f0878";
}

.mdi-highway::before {
  content: "\f05f7";
}

.mdi-hiking::before {
  content: "\f0d7f";
}

.mdi-history::before {
  content: "\f02da";
}

.mdi-hockey-puck::before {
  content: "\f0879";
}

.mdi-hockey-sticks::before {
  content: "\f087a";
}

.mdi-hololens::before {
  content: "\f02db";
}

.mdi-home::before {
  content: "\f02dc";
}

.mdi-home-account::before {
  content: "\f0826";
}

.mdi-home-alert::before {
  content: "\f087b";
}

.mdi-home-alert-outline::before {
  content: "\f15d0";
}

.mdi-home-analytics::before {
  content: "\f0eba";
}

.mdi-home-assistant::before {
  content: "\f07d0";
}

.mdi-home-automation::before {
  content: "\f07d1";
}

.mdi-home-battery::before {
  content: "\f1901";
}

.mdi-home-battery-outline::before {
  content: "\f1902";
}

.mdi-home-circle::before {
  content: "\f07d2";
}

.mdi-home-circle-outline::before {
  content: "\f104d";
}

.mdi-home-city::before {
  content: "\f0d15";
}

.mdi-home-city-outline::before {
  content: "\f0d16";
}

.mdi-home-edit::before {
  content: "\f1159";
}

.mdi-home-edit-outline::before {
  content: "\f115a";
}

.mdi-home-export-outline::before {
  content: "\f0f9b";
}

.mdi-home-flood::before {
  content: "\f0efa";
}

.mdi-home-floor-0::before {
  content: "\f0dd2";
}

.mdi-home-floor-1::before {
  content: "\f0d80";
}

.mdi-home-floor-2::before {
  content: "\f0d81";
}

.mdi-home-floor-3::before {
  content: "\f0d82";
}

.mdi-home-floor-a::before {
  content: "\f0d83";
}

.mdi-home-floor-b::before {
  content: "\f0d84";
}

.mdi-home-floor-g::before {
  content: "\f0d85";
}

.mdi-home-floor-l::before {
  content: "\f0d86";
}

.mdi-home-floor-negative-1::before {
  content: "\f0dd3";
}

.mdi-home-group::before {
  content: "\f0dd4";
}

.mdi-home-group-minus::before {
  content: "\f19c1";
}

.mdi-home-group-plus::before {
  content: "\f19c0";
}

.mdi-home-group-remove::before {
  content: "\f19c2";
}

.mdi-home-heart::before {
  content: "\f0827";
}

.mdi-home-import-outline::before {
  content: "\f0f9c";
}

.mdi-home-lightbulb::before {
  content: "\f1251";
}

.mdi-home-lightbulb-outline::before {
  content: "\f1252";
}

.mdi-home-lightning-bolt::before {
  content: "\f1903";
}

.mdi-home-lightning-bolt-outline::before {
  content: "\f1904";
}

.mdi-home-lock::before {
  content: "\f08eb";
}

.mdi-home-lock-open::before {
  content: "\f08ec";
}

.mdi-home-map-marker::before {
  content: "\f05f8";
}

.mdi-home-minus::before {
  content: "\f0974";
}

.mdi-home-minus-outline::before {
  content: "\f13d5";
}

.mdi-home-modern::before {
  content: "\f02dd";
}

.mdi-home-outline::before {
  content: "\f06a1";
}

.mdi-home-plus::before {
  content: "\f0975";
}

.mdi-home-plus-outline::before {
  content: "\f13d6";
}

.mdi-home-remove::before {
  content: "\f1247";
}

.mdi-home-remove-outline::before {
  content: "\f13d7";
}

.mdi-home-roof::before {
  content: "\f112b";
}

.mdi-home-search::before {
  content: "\f13b0";
}

.mdi-home-search-outline::before {
  content: "\f13b1";
}

.mdi-home-switch::before {
  content: "\f1794";
}

.mdi-home-switch-outline::before {
  content: "\f1795";
}

.mdi-home-thermometer::before {
  content: "\f0f54";
}

.mdi-home-thermometer-outline::before {
  content: "\f0f55";
}

.mdi-home-variant::before {
  content: "\f02de";
}

.mdi-home-variant-outline::before {
  content: "\f0ba7";
}

.mdi-hook::before {
  content: "\f06e2";
}

.mdi-hook-off::before {
  content: "\f06e3";
}

.mdi-hoop-house::before {
  content: "\f0e56";
}

.mdi-hops::before {
  content: "\f02df";
}

.mdi-horizontal-rotate-clockwise::before {
  content: "\f10f3";
}

.mdi-horizontal-rotate-counterclockwise::before {
  content: "\f10f4";
}

.mdi-horse::before {
  content: "\f15bf";
}

.mdi-horse-human::before {
  content: "\f15c0";
}

.mdi-horse-variant::before {
  content: "\f15c1";
}

.mdi-horse-variant-fast::before {
  content: "\f186e";
}

.mdi-horseshoe::before {
  content: "\f0a58";
}

.mdi-hospital::before {
  content: "\f0ff6";
}

.mdi-hospital-box::before {
  content: "\f02e0";
}

.mdi-hospital-box-outline::before {
  content: "\f0ff7";
}

.mdi-hospital-building::before {
  content: "\f02e1";
}

.mdi-hospital-marker::before {
  content: "\f02e2";
}

.mdi-hot-tub::before {
  content: "\f0828";
}

.mdi-hours-24::before {
  content: "\f1478";
}

.mdi-hubspot::before {
  content: "\f0d17";
}

.mdi-hulu::before {
  content: "\f0829";
}

.mdi-human::before {
  content: "\f02e6";
}

.mdi-human-baby-changing-table::before {
  content: "\f138b";
}

.mdi-human-cane::before {
  content: "\f1581";
}

.mdi-human-capacity-decrease::before {
  content: "\f159b";
}

.mdi-human-capacity-increase::before {
  content: "\f159c";
}

.mdi-human-child::before {
  content: "\f02e7";
}

.mdi-human-dolly::before {
  content: "\f1980";
}

.mdi-human-edit::before {
  content: "\f14e8";
}

.mdi-human-female::before {
  content: "\f0649";
}

.mdi-human-female-boy::before {
  content: "\f0a59";
}

.mdi-human-female-dance::before {
  content: "\f15c9";
}

.mdi-human-female-female::before {
  content: "\f0a5a";
}

.mdi-human-female-girl::before {
  content: "\f0a5b";
}

.mdi-human-greeting::before {
  content: "\f17c4";
}

.mdi-human-greeting-proximity::before {
  content: "\f159d";
}

.mdi-human-greeting-variant::before {
  content: "\f064a";
}

.mdi-human-handsdown::before {
  content: "\f064b";
}

.mdi-human-handsup::before {
  content: "\f064c";
}

.mdi-human-male::before {
  content: "\f064d";
}

.mdi-human-male-board::before {
  content: "\f0890";
}

.mdi-human-male-board-poll::before {
  content: "\f0846";
}

.mdi-human-male-boy::before {
  content: "\f0a5c";
}

.mdi-human-male-child::before {
  content: "\f138c";
}

.mdi-human-male-female::before {
  content: "\f02e8";
}

.mdi-human-male-female-child::before {
  content: "\f1823";
}

.mdi-human-male-girl::before {
  content: "\f0a5d";
}

.mdi-human-male-height::before {
  content: "\f0efb";
}

.mdi-human-male-height-variant::before {
  content: "\f0efc";
}

.mdi-human-male-male::before {
  content: "\f0a5e";
}

.mdi-human-non-binary::before {
  content: "\f1848";
}

.mdi-human-pregnant::before {
  content: "\f05cf";
}

.mdi-human-queue::before {
  content: "\f1571";
}

.mdi-human-scooter::before {
  content: "\f11e9";
}

.mdi-human-wheelchair::before {
  content: "\f138d";
}

.mdi-human-white-cane::before {
  content: "\f1981";
}

.mdi-humble-bundle::before {
  content: "\f0744";
}

.mdi-hvac::before {
  content: "\f1352";
}

.mdi-hvac-off::before {
  content: "\f159e";
}

.mdi-hydraulic-oil-level::before {
  content: "\f1324";
}

.mdi-hydraulic-oil-temperature::before {
  content: "\f1325";
}

.mdi-hydro-power::before {
  content: "\f12e5";
}

.mdi-hydrogen-station::before {
  content: "\f1894";
}

.mdi-ice-cream::before {
  content: "\f082a";
}

.mdi-ice-cream-off::before {
  content: "\f0e52";
}

.mdi-ice-pop::before {
  content: "\f0efd";
}

.mdi-id-card::before {
  content: "\f0fc0";
}

.mdi-identifier::before {
  content: "\f0efe";
}

.mdi-ideogram-cjk::before {
  content: "\f1331";
}

.mdi-ideogram-cjk-variant::before {
  content: "\f1332";
}

.mdi-image::before {
  content: "\f02e9";
}

.mdi-image-album::before {
  content: "\f02ea";
}

.mdi-image-area::before {
  content: "\f02eb";
}

.mdi-image-area-close::before {
  content: "\f02ec";
}

.mdi-image-auto-adjust::before {
  content: "\f0fc1";
}

.mdi-image-broken::before {
  content: "\f02ed";
}

.mdi-image-broken-variant::before {
  content: "\f02ee";
}

.mdi-image-edit::before {
  content: "\f11e3";
}

.mdi-image-edit-outline::before {
  content: "\f11e4";
}

.mdi-image-filter-black-white::before {
  content: "\f02f0";
}

.mdi-image-filter-center-focus::before {
  content: "\f02f1";
}

.mdi-image-filter-center-focus-strong::before {
  content: "\f0eff";
}

.mdi-image-filter-center-focus-strong-outline::before {
  content: "\f0f00";
}

.mdi-image-filter-center-focus-weak::before {
  content: "\f02f2";
}

.mdi-image-filter-drama::before {
  content: "\f02f3";
}

.mdi-image-filter-frames::before {
  content: "\f02f4";
}

.mdi-image-filter-hdr::before {
  content: "\f02f5";
}

.mdi-image-filter-none::before {
  content: "\f02f6";
}

.mdi-image-filter-tilt-shift::before {
  content: "\f02f7";
}

.mdi-image-filter-vintage::before {
  content: "\f02f8";
}

.mdi-image-frame::before {
  content: "\f0e49";
}

.mdi-image-marker::before {
  content: "\f177b";
}

.mdi-image-marker-outline::before {
  content: "\f177c";
}

.mdi-image-minus::before {
  content: "\f1419";
}

.mdi-image-move::before {
  content: "\f09f8";
}

.mdi-image-multiple::before {
  content: "\f02f9";
}

.mdi-image-multiple-outline::before {
  content: "\f02ef";
}

.mdi-image-off::before {
  content: "\f082b";
}

.mdi-image-off-outline::before {
  content: "\f11d1";
}

.mdi-image-outline::before {
  content: "\f0976";
}

.mdi-image-plus::before {
  content: "\f087c";
}

.mdi-image-remove::before {
  content: "\f1418";
}

.mdi-image-search::before {
  content: "\f0977";
}

.mdi-image-search-outline::before {
  content: "\f0978";
}

.mdi-image-size-select-actual::before {
  content: "\f0c8d";
}

.mdi-image-size-select-large::before {
  content: "\f0c8e";
}

.mdi-image-size-select-small::before {
  content: "\f0c8f";
}

.mdi-image-text::before {
  content: "\f160d";
}

.mdi-import::before {
  content: "\f02fa";
}

.mdi-inbox::before {
  content: "\f0687";
}

.mdi-inbox-arrow-down::before {
  content: "\f02fb";
}

.mdi-inbox-arrow-down-outline::before {
  content: "\f1270";
}

.mdi-inbox-arrow-up::before {
  content: "\f03d1";
}

.mdi-inbox-arrow-up-outline::before {
  content: "\f1271";
}

.mdi-inbox-full::before {
  content: "\f1272";
}

.mdi-inbox-full-outline::before {
  content: "\f1273";
}

.mdi-inbox-multiple::before {
  content: "\f08b0";
}

.mdi-inbox-multiple-outline::before {
  content: "\f0ba8";
}

.mdi-inbox-outline::before {
  content: "\f1274";
}

.mdi-inbox-remove::before {
  content: "\f159f";
}

.mdi-inbox-remove-outline::before {
  content: "\f15a0";
}

.mdi-incognito::before {
  content: "\f05f9";
}

.mdi-incognito-circle::before {
  content: "\f1421";
}

.mdi-incognito-circle-off::before {
  content: "\f1422";
}

.mdi-incognito-off::before {
  content: "\f0075";
}

.mdi-induction::before {
  content: "\f184c";
}

.mdi-infinity::before {
  content: "\f06e4";
}

.mdi-information::before {
  content: "\f02fc";
}

.mdi-information-off::before {
  content: "\f178c";
}

.mdi-information-off-outline::before {
  content: "\f178d";
}

.mdi-information-outline::before {
  content: "\f02fd";
}

.mdi-information-variant::before {
  content: "\f064e";
}

.mdi-instagram::before {
  content: "\f02fe";
}

.mdi-instrument-triangle::before {
  content: "\f104e";
}

.mdi-integrated-circuit-chip::before {
  content: "\f1913";
}

.mdi-invert-colors::before {
  content: "\f0301";
}

.mdi-invert-colors-off::before {
  content: "\f0e4a";
}

.mdi-iobroker::before {
  content: "\f12e8";
}

.mdi-ip::before {
  content: "\f0a5f";
}

.mdi-ip-network::before {
  content: "\f0a60";
}

.mdi-ip-network-outline::before {
  content: "\f0c90";
}

.mdi-ip-outline::before {
  content: "\f1982";
}

.mdi-ipod::before {
  content: "\f0c91";
}

.mdi-iron::before {
  content: "\f1824";
}

.mdi-iron-board::before {
  content: "\f1838";
}

.mdi-iron-outline::before {
  content: "\f1825";
}

.mdi-island::before {
  content: "\f104f";
}

.mdi-iv-bag::before {
  content: "\f10b9";
}

.mdi-jabber::before {
  content: "\f0dd5";
}

.mdi-jeepney::before {
  content: "\f0302";
}

.mdi-jellyfish::before {
  content: "\f0f01";
}

.mdi-jellyfish-outline::before {
  content: "\f0f02";
}

.mdi-jira::before {
  content: "\f0303";
}

.mdi-jquery::before {
  content: "\f087d";
}

.mdi-jsfiddle::before {
  content: "\f0304";
}

.mdi-jump-rope::before {
  content: "\f12ff";
}

.mdi-kabaddi::before {
  content: "\f0d87";
}

.mdi-kangaroo::before {
  content: "\f1558";
}

.mdi-karate::before {
  content: "\f082c";
}

.mdi-kayaking::before {
  content: "\f08af";
}

.mdi-keg::before {
  content: "\f0305";
}

.mdi-kettle::before {
  content: "\f05fa";
}

.mdi-kettle-alert::before {
  content: "\f1317";
}

.mdi-kettle-alert-outline::before {
  content: "\f1318";
}

.mdi-kettle-off::before {
  content: "\f131b";
}

.mdi-kettle-off-outline::before {
  content: "\f131c";
}

.mdi-kettle-outline::before {
  content: "\f0f56";
}

.mdi-kettle-pour-over::before {
  content: "\f173c";
}

.mdi-kettle-steam::before {
  content: "\f1319";
}

.mdi-kettle-steam-outline::before {
  content: "\f131a";
}

.mdi-kettlebell::before {
  content: "\f1300";
}

.mdi-key::before {
  content: "\f0306";
}

.mdi-key-alert::before {
  content: "\f1983";
}

.mdi-key-alert-outline::before {
  content: "\f1984";
}

.mdi-key-arrow-right::before {
  content: "\f1312";
}

.mdi-key-chain::before {
  content: "\f1574";
}

.mdi-key-chain-variant::before {
  content: "\f1575";
}

.mdi-key-change::before {
  content: "\f0307";
}

.mdi-key-link::before {
  content: "\f119f";
}

.mdi-key-minus::before {
  content: "\f0308";
}

.mdi-key-outline::before {
  content: "\f0dd6";
}

.mdi-key-plus::before {
  content: "\f0309";
}

.mdi-key-remove::before {
  content: "\f030a";
}

.mdi-key-star::before {
  content: "\f119e";
}

.mdi-key-variant::before {
  content: "\f030b";
}

.mdi-key-wireless::before {
  content: "\f0fc2";
}

.mdi-keyboard::before {
  content: "\f030c";
}

.mdi-keyboard-backspace::before {
  content: "\f030d";
}

.mdi-keyboard-caps::before {
  content: "\f030e";
}

.mdi-keyboard-close::before {
  content: "\f030f";
}

.mdi-keyboard-esc::before {
  content: "\f12b7";
}

.mdi-keyboard-f1::before {
  content: "\f12ab";
}

.mdi-keyboard-f10::before {
  content: "\f12b4";
}

.mdi-keyboard-f11::before {
  content: "\f12b5";
}

.mdi-keyboard-f12::before {
  content: "\f12b6";
}

.mdi-keyboard-f2::before {
  content: "\f12ac";
}

.mdi-keyboard-f3::before {
  content: "\f12ad";
}

.mdi-keyboard-f4::before {
  content: "\f12ae";
}

.mdi-keyboard-f5::before {
  content: "\f12af";
}

.mdi-keyboard-f6::before {
  content: "\f12b0";
}

.mdi-keyboard-f7::before {
  content: "\f12b1";
}

.mdi-keyboard-f8::before {
  content: "\f12b2";
}

.mdi-keyboard-f9::before {
  content: "\f12b3";
}

.mdi-keyboard-off::before {
  content: "\f0310";
}

.mdi-keyboard-off-outline::before {
  content: "\f0e4b";
}

.mdi-keyboard-outline::before {
  content: "\f097b";
}

.mdi-keyboard-return::before {
  content: "\f0311";
}

.mdi-keyboard-settings::before {
  content: "\f09f9";
}

.mdi-keyboard-settings-outline::before {
  content: "\f09fa";
}

.mdi-keyboard-space::before {
  content: "\f1050";
}

.mdi-keyboard-tab::before {
  content: "\f0312";
}

.mdi-keyboard-tab-reverse::before {
  content: "\f0325";
}

.mdi-keyboard-variant::before {
  content: "\f0313";
}

.mdi-khanda::before {
  content: "\f10fd";
}

.mdi-kickstarter::before {
  content: "\f0745";
}

.mdi-kite::before {
  content: "\f1985";
}

.mdi-kite-outline::before {
  content: "\f1986";
}

.mdi-kitesurfing::before {
  content: "\f1744";
}

.mdi-klingon::before {
  content: "\f135b";
}

.mdi-knife::before {
  content: "\f09fb";
}

.mdi-knife-military::before {
  content: "\f09fc";
}

.mdi-koala::before {
  content: "\f173f";
}

.mdi-kodi::before {
  content: "\f0314";
}

.mdi-kubernetes::before {
  content: "\f10fe";
}

.mdi-label::before {
  content: "\f0315";
}

.mdi-label-multiple::before {
  content: "\f1375";
}

.mdi-label-multiple-outline::before {
  content: "\f1376";
}

.mdi-label-off::before {
  content: "\f0acb";
}

.mdi-label-off-outline::before {
  content: "\f0acc";
}

.mdi-label-outline::before {
  content: "\f0316";
}

.mdi-label-percent::before {
  content: "\f12ea";
}

.mdi-label-percent-outline::before {
  content: "\f12eb";
}

.mdi-label-variant::before {
  content: "\f0acd";
}

.mdi-label-variant-outline::before {
  content: "\f0ace";
}

.mdi-ladder::before {
  content: "\f15a2";
}

.mdi-ladybug::before {
  content: "\f082d";
}

.mdi-lambda::before {
  content: "\f0627";
}

.mdi-lamp::before {
  content: "\f06b5";
}

.mdi-lamp-outline::before {
  content: "\f17d0";
}

.mdi-lamps::before {
  content: "\f1576";
}

.mdi-lamps-outline::before {
  content: "\f17d1";
}

.mdi-lan::before {
  content: "\f0317";
}

.mdi-lan-check::before {
  content: "\f12aa";
}

.mdi-lan-connect::before {
  content: "\f0318";
}

.mdi-lan-disconnect::before {
  content: "\f0319";
}

.mdi-lan-pending::before {
  content: "\f031a";
}

.mdi-language-c::before {
  content: "\f0671";
}

.mdi-language-cpp::before {
  content: "\f0672";
}

.mdi-language-csharp::before {
  content: "\f031b";
}

.mdi-language-css3::before {
  content: "\f031c";
}

.mdi-language-fortran::before {
  content: "\f121a";
}

.mdi-language-go::before {
  content: "\f07d3";
}

.mdi-language-haskell::before {
  content: "\f0c92";
}

.mdi-language-html5::before {
  content: "\f031d";
}

.mdi-language-java::before {
  content: "\f0b37";
}

.mdi-language-javascript::before {
  content: "\f031e";
}

.mdi-language-kotlin::before {
  content: "\f1219";
}

.mdi-language-lua::before {
  content: "\f08b1";
}

.mdi-language-markdown::before {
  content: "\f0354";
}

.mdi-language-markdown-outline::before {
  content: "\f0f5b";
}

.mdi-language-php::before {
  content: "\f031f";
}

.mdi-language-python::before {
  content: "\f0320";
}

.mdi-language-r::before {
  content: "\f07d4";
}

.mdi-language-ruby::before {
  content: "\f0d2d";
}

.mdi-language-ruby-on-rails::before {
  content: "\f0acf";
}

.mdi-language-rust::before {
  content: "\f1617";
}

.mdi-language-swift::before {
  content: "\f06e5";
}

.mdi-language-typescript::before {
  content: "\f06e6";
}

.mdi-language-xaml::before {
  content: "\f0673";
}

.mdi-laptop::before {
  content: "\f0322";
}

.mdi-laptop-off::before {
  content: "\f06e7";
}

.mdi-laravel::before {
  content: "\f0ad0";
}

.mdi-laser-pointer::before {
  content: "\f1484";
}

.mdi-lasso::before {
  content: "\f0f03";
}

.mdi-lastpass::before {
  content: "\f0446";
}

.mdi-latitude::before {
  content: "\f0f57";
}

.mdi-launch::before {
  content: "\f0327";
}

.mdi-lava-lamp::before {
  content: "\f07d5";
}

.mdi-layers::before {
  content: "\f0328";
}

.mdi-layers-edit::before {
  content: "\f1892";
}

.mdi-layers-minus::before {
  content: "\f0e4c";
}

.mdi-layers-off::before {
  content: "\f0329";
}

.mdi-layers-off-outline::before {
  content: "\f09fd";
}

.mdi-layers-outline::before {
  content: "\f09fe";
}

.mdi-layers-plus::before {
  content: "\f0e4d";
}

.mdi-layers-remove::before {
  content: "\f0e4e";
}

.mdi-layers-search::before {
  content: "\f1206";
}

.mdi-layers-search-outline::before {
  content: "\f1207";
}

.mdi-layers-triple::before {
  content: "\f0f58";
}

.mdi-layers-triple-outline::before {
  content: "\f0f59";
}

.mdi-lead-pencil::before {
  content: "\f064f";
}

.mdi-leaf::before {
  content: "\f032a";
}

.mdi-leaf-circle::before {
  content: "\f1905";
}

.mdi-leaf-circle-outline::before {
  content: "\f1906";
}

.mdi-leaf-maple::before {
  content: "\f0c93";
}

.mdi-leaf-maple-off::before {
  content: "\f12da";
}

.mdi-leaf-off::before {
  content: "\f12d9";
}

.mdi-leak::before {
  content: "\f0dd7";
}

.mdi-leak-off::before {
  content: "\f0dd8";
}

.mdi-led-off::before {
  content: "\f032b";
}

.mdi-led-on::before {
  content: "\f032c";
}

.mdi-led-outline::before {
  content: "\f032d";
}

.mdi-led-strip::before {
  content: "\f07d6";
}

.mdi-led-strip-variant::before {
  content: "\f1051";
}

.mdi-led-variant-off::before {
  content: "\f032e";
}

.mdi-led-variant-on::before {
  content: "\f032f";
}

.mdi-led-variant-outline::before {
  content: "\f0330";
}

.mdi-leek::before {
  content: "\f117d";
}

.mdi-less-than::before {
  content: "\f097c";
}

.mdi-less-than-or-equal::before {
  content: "\f097d";
}

.mdi-library::before {
  content: "\f0331";
}

.mdi-library-shelves::before {
  content: "\f0ba9";
}

.mdi-license::before {
  content: "\f0fc3";
}

.mdi-lifebuoy::before {
  content: "\f087e";
}

.mdi-light-flood-down::before {
  content: "\f1987";
}

.mdi-light-flood-up::before {
  content: "\f1988";
}

.mdi-light-recessed::before {
  content: "\f179b";
}

.mdi-light-switch::before {
  content: "\f097e";
}

.mdi-lightbulb::before {
  content: "\f0335";
}

.mdi-lightbulb-auto::before {
  content: "\f1800";
}

.mdi-lightbulb-auto-outline::before {
  content: "\f1801";
}

.mdi-lightbulb-cfl::before {
  content: "\f1208";
}

.mdi-lightbulb-cfl-off::before {
  content: "\f1209";
}

.mdi-lightbulb-cfl-spiral::before {
  content: "\f1275";
}

.mdi-lightbulb-cfl-spiral-off::before {
  content: "\f12c3";
}

.mdi-lightbulb-fluorescent-tube::before {
  content: "\f1804";
}

.mdi-lightbulb-fluorescent-tube-outline::before {
  content: "\f1805";
}

.mdi-lightbulb-group::before {
  content: "\f1253";
}

.mdi-lightbulb-group-off::before {
  content: "\f12cd";
}

.mdi-lightbulb-group-off-outline::before {
  content: "\f12ce";
}

.mdi-lightbulb-group-outline::before {
  content: "\f1254";
}

.mdi-lightbulb-multiple::before {
  content: "\f1255";
}

.mdi-lightbulb-multiple-off::before {
  content: "\f12cf";
}

.mdi-lightbulb-multiple-off-outline::before {
  content: "\f12d0";
}

.mdi-lightbulb-multiple-outline::before {
  content: "\f1256";
}

.mdi-lightbulb-off::before {
  content: "\f0e4f";
}

.mdi-lightbulb-off-outline::before {
  content: "\f0e50";
}

.mdi-lightbulb-on::before {
  content: "\f06e8";
}

.mdi-lightbulb-on-outline::before {
  content: "\f06e9";
}

.mdi-lightbulb-outline::before {
  content: "\f0336";
}

.mdi-lightbulb-spot::before {
  content: "\f17f4";
}

.mdi-lightbulb-spot-off::before {
  content: "\f17f5";
}

.mdi-lightbulb-variant::before {
  content: "\f1802";
}

.mdi-lightbulb-variant-outline::before {
  content: "\f1803";
}

.mdi-lighthouse::before {
  content: "\f09ff";
}

.mdi-lighthouse-on::before {
  content: "\f0a00";
}

.mdi-lightning-bolt::before {
  content: "\f140b";
}

.mdi-lightning-bolt-circle::before {
  content: "\f0820";
}

.mdi-lightning-bolt-outline::before {
  content: "\f140c";
}

.mdi-line-scan::before {
  content: "\f0624";
}

.mdi-lingerie::before {
  content: "\f1476";
}

.mdi-link::before {
  content: "\f0337";
}

.mdi-link-box::before {
  content: "\f0d1a";
}

.mdi-link-box-outline::before {
  content: "\f0d1b";
}

.mdi-link-box-variant::before {
  content: "\f0d1c";
}

.mdi-link-box-variant-outline::before {
  content: "\f0d1d";
}

.mdi-link-lock::before {
  content: "\f10ba";
}

.mdi-link-off::before {
  content: "\f0338";
}

.mdi-link-plus::before {
  content: "\f0c94";
}

.mdi-link-variant::before {
  content: "\f0339";
}

.mdi-link-variant-minus::before {
  content: "\f10ff";
}

.mdi-link-variant-off::before {
  content: "\f033a";
}

.mdi-link-variant-plus::before {
  content: "\f1100";
}

.mdi-link-variant-remove::before {
  content: "\f1101";
}

.mdi-linkedin::before {
  content: "\f033b";
}

.mdi-linux::before {
  content: "\f033d";
}

.mdi-linux-mint::before {
  content: "\f08ed";
}

.mdi-lipstick::before {
  content: "\f13b5";
}

.mdi-liquid-spot::before {
  content: "\f1826";
}

.mdi-liquor::before {
  content: "\f191e";
}

.mdi-list-status::before {
  content: "\f15ab";
}

.mdi-litecoin::before {
  content: "\f0a61";
}

.mdi-loading::before {
  content: "\f0772";
}

.mdi-location-enter::before {
  content: "\f0fc4";
}

.mdi-location-exit::before {
  content: "\f0fc5";
}

.mdi-lock::before {
  content: "\f033e";
}

.mdi-lock-alert::before {
  content: "\f08ee";
}

.mdi-lock-alert-outline::before {
  content: "\f15d1";
}

.mdi-lock-check::before {
  content: "\f139a";
}

.mdi-lock-check-outline::before {
  content: "\f16a8";
}

.mdi-lock-clock::before {
  content: "\f097f";
}

.mdi-lock-minus::before {
  content: "\f16a9";
}

.mdi-lock-minus-outline::before {
  content: "\f16aa";
}

.mdi-lock-off::before {
  content: "\f1671";
}

.mdi-lock-off-outline::before {
  content: "\f1672";
}

.mdi-lock-open::before {
  content: "\f033f";
}

.mdi-lock-open-alert::before {
  content: "\f139b";
}

.mdi-lock-open-alert-outline::before {
  content: "\f15d2";
}

.mdi-lock-open-check::before {
  content: "\f139c";
}

.mdi-lock-open-check-outline::before {
  content: "\f16ab";
}

.mdi-lock-open-minus::before {
  content: "\f16ac";
}

.mdi-lock-open-minus-outline::before {
  content: "\f16ad";
}

.mdi-lock-open-outline::before {
  content: "\f0340";
}

.mdi-lock-open-plus::before {
  content: "\f16ae";
}

.mdi-lock-open-plus-outline::before {
  content: "\f16af";
}

.mdi-lock-open-remove::before {
  content: "\f16b0";
}

.mdi-lock-open-remove-outline::before {
  content: "\f16b1";
}

.mdi-lock-open-variant::before {
  content: "\f0fc6";
}

.mdi-lock-open-variant-outline::before {
  content: "\f0fc7";
}

.mdi-lock-outline::before {
  content: "\f0341";
}

.mdi-lock-pattern::before {
  content: "\f06ea";
}

.mdi-lock-plus::before {
  content: "\f05fb";
}

.mdi-lock-plus-outline::before {
  content: "\f16b2";
}

.mdi-lock-question::before {
  content: "\f08ef";
}

.mdi-lock-remove::before {
  content: "\f16b3";
}

.mdi-lock-remove-outline::before {
  content: "\f16b4";
}

.mdi-lock-reset::before {
  content: "\f0773";
}

.mdi-lock-smart::before {
  content: "\f08b2";
}

.mdi-locker::before {
  content: "\f07d7";
}

.mdi-locker-multiple::before {
  content: "\f07d8";
}

.mdi-login::before {
  content: "\f0342";
}

.mdi-login-variant::before {
  content: "\f05fc";
}

.mdi-logout::before {
  content: "\f0343";
}

.mdi-logout-variant::before {
  content: "\f05fd";
}

.mdi-longitude::before {
  content: "\f0f5a";
}

.mdi-looks::before {
  content: "\f0344";
}

.mdi-lotion::before {
  content: "\f1582";
}

.mdi-lotion-outline::before {
  content: "\f1583";
}

.mdi-lotion-plus::before {
  content: "\f1584";
}

.mdi-lotion-plus-outline::before {
  content: "\f1585";
}

.mdi-loupe::before {
  content: "\f0345";
}

.mdi-lumx::before {
  content: "\f0346";
}

.mdi-lungs::before {
  content: "\f1084";
}

.mdi-mace::before {
  content: "\f1843";
}

.mdi-magazine-pistol::before {
  content: "\f0324";
}

.mdi-magazine-rifle::before {
  content: "\f0323";
}

.mdi-magic-staff::before {
  content: "\f1844";
}

.mdi-magnet::before {
  content: "\f0347";
}

.mdi-magnet-on::before {
  content: "\f0348";
}

.mdi-magnify::before {
  content: "\f0349";
}

.mdi-magnify-close::before {
  content: "\f0980";
}

.mdi-magnify-expand::before {
  content: "\f1874";
}

.mdi-magnify-minus::before {
  content: "\f034a";
}

.mdi-magnify-minus-cursor::before {
  content: "\f0a62";
}

.mdi-magnify-minus-outline::before {
  content: "\f06ec";
}

.mdi-magnify-plus::before {
  content: "\f034b";
}

.mdi-magnify-plus-cursor::before {
  content: "\f0a63";
}

.mdi-magnify-plus-outline::before {
  content: "\f06ed";
}

.mdi-magnify-remove-cursor::before {
  content: "\f120c";
}

.mdi-magnify-remove-outline::before {
  content: "\f120d";
}

.mdi-magnify-scan::before {
  content: "\f1276";
}

.mdi-mail::before {
  content: "\f0ebb";
}

.mdi-mailbox::before {
  content: "\f06ee";
}

.mdi-mailbox-open::before {
  content: "\f0d88";
}

.mdi-mailbox-open-outline::before {
  content: "\f0d89";
}

.mdi-mailbox-open-up::before {
  content: "\f0d8a";
}

.mdi-mailbox-open-up-outline::before {
  content: "\f0d8b";
}

.mdi-mailbox-outline::before {
  content: "\f0d8c";
}

.mdi-mailbox-up::before {
  content: "\f0d8d";
}

.mdi-mailbox-up-outline::before {
  content: "\f0d8e";
}

.mdi-manjaro::before {
  content: "\f160a";
}

.mdi-map::before {
  content: "\f034d";
}

.mdi-map-check::before {
  content: "\f0ebc";
}

.mdi-map-check-outline::before {
  content: "\f0ebd";
}

.mdi-map-clock::before {
  content: "\f0d1e";
}

.mdi-map-clock-outline::before {
  content: "\f0d1f";
}

.mdi-map-legend::before {
  content: "\f0a01";
}

.mdi-map-marker::before {
  content: "\f034e";
}

.mdi-map-marker-account::before {
  content: "\f18e3";
}

.mdi-map-marker-account-outline::before {
  content: "\f18e4";
}

.mdi-map-marker-alert::before {
  content: "\f0f05";
}

.mdi-map-marker-alert-outline::before {
  content: "\f0f06";
}

.mdi-map-marker-check::before {
  content: "\f0c95";
}

.mdi-map-marker-check-outline::before {
  content: "\f12fb";
}

.mdi-map-marker-circle::before {
  content: "\f034f";
}

.mdi-map-marker-distance::before {
  content: "\f08f0";
}

.mdi-map-marker-down::before {
  content: "\f1102";
}

.mdi-map-marker-left::before {
  content: "\f12db";
}

.mdi-map-marker-left-outline::before {
  content: "\f12dd";
}

.mdi-map-marker-minus::before {
  content: "\f0650";
}

.mdi-map-marker-minus-outline::before {
  content: "\f12f9";
}

.mdi-map-marker-multiple::before {
  content: "\f0350";
}

.mdi-map-marker-multiple-outline::before {
  content: "\f1277";
}

.mdi-map-marker-off::before {
  content: "\f0351";
}

.mdi-map-marker-off-outline::before {
  content: "\f12fd";
}

.mdi-map-marker-outline::before {
  content: "\f07d9";
}

.mdi-map-marker-path::before {
  content: "\f0d20";
}

.mdi-map-marker-plus::before {
  content: "\f0651";
}

.mdi-map-marker-plus-outline::before {
  content: "\f12f8";
}

.mdi-map-marker-question::before {
  content: "\f0f07";
}

.mdi-map-marker-question-outline::before {
  content: "\f0f08";
}

.mdi-map-marker-radius::before {
  content: "\f0352";
}

.mdi-map-marker-radius-outline::before {
  content: "\f12fc";
}

.mdi-map-marker-remove::before {
  content: "\f0f09";
}

.mdi-map-marker-remove-outline::before {
  content: "\f12fa";
}

.mdi-map-marker-remove-variant::before {
  content: "\f0f0a";
}

.mdi-map-marker-right::before {
  content: "\f12dc";
}

.mdi-map-marker-right-outline::before {
  content: "\f12de";
}

.mdi-map-marker-star::before {
  content: "\f1608";
}

.mdi-map-marker-star-outline::before {
  content: "\f1609";
}

.mdi-map-marker-up::before {
  content: "\f1103";
}

.mdi-map-minus::before {
  content: "\f0981";
}

.mdi-map-outline::before {
  content: "\f0982";
}

.mdi-map-plus::before {
  content: "\f0983";
}

.mdi-map-search::before {
  content: "\f0984";
}

.mdi-map-search-outline::before {
  content: "\f0985";
}

.mdi-mapbox::before {
  content: "\f0baa";
}

.mdi-margin::before {
  content: "\f0353";
}

.mdi-marker::before {
  content: "\f0652";
}

.mdi-marker-cancel::before {
  content: "\f0dd9";
}

.mdi-marker-check::before {
  content: "\f0355";
}

.mdi-mastodon::before {
  content: "\f0ad1";
}

.mdi-material-design::before {
  content: "\f0986";
}

.mdi-material-ui::before {
  content: "\f0357";
}

.mdi-math-compass::before {
  content: "\f0358";
}

.mdi-math-cos::before {
  content: "\f0c96";
}

.mdi-math-integral::before {
  content: "\f0fc8";
}

.mdi-math-integral-box::before {
  content: "\f0fc9";
}

.mdi-math-log::before {
  content: "\f1085";
}

.mdi-math-norm::before {
  content: "\f0fca";
}

.mdi-math-norm-box::before {
  content: "\f0fcb";
}

.mdi-math-sin::before {
  content: "\f0c97";
}

.mdi-math-tan::before {
  content: "\f0c98";
}

.mdi-matrix::before {
  content: "\f0628";
}

.mdi-medal::before {
  content: "\f0987";
}

.mdi-medal-outline::before {
  content: "\f1326";
}

.mdi-medical-bag::before {
  content: "\f06ef";
}

.mdi-meditation::before {
  content: "\f117b";
}

.mdi-memory::before {
  content: "\f035b";
}

.mdi-menorah::before {
  content: "\f17d4";
}

.mdi-menorah-fire::before {
  content: "\f17d5";
}

.mdi-menu::before {
  content: "\f035c";
}

.mdi-menu-down::before {
  content: "\f035d";
}

.mdi-menu-down-outline::before {
  content: "\f06b6";
}

.mdi-menu-left::before {
  content: "\f035e";
}

.mdi-menu-left-outline::before {
  content: "\f0a02";
}

.mdi-menu-open::before {
  content: "\f0bab";
}

.mdi-menu-right::before {
  content: "\f035f";
}

.mdi-menu-right-outline::before {
  content: "\f0a03";
}

.mdi-menu-swap::before {
  content: "\f0a64";
}

.mdi-menu-swap-outline::before {
  content: "\f0a65";
}

.mdi-menu-up::before {
  content: "\f0360";
}

.mdi-menu-up-outline::before {
  content: "\f06b7";
}

.mdi-merge::before {
  content: "\f0f5c";
}

.mdi-message::before {
  content: "\f0361";
}

.mdi-message-alert::before {
  content: "\f0362";
}

.mdi-message-alert-outline::before {
  content: "\f0a04";
}

.mdi-message-arrow-left::before {
  content: "\f12f2";
}

.mdi-message-arrow-left-outline::before {
  content: "\f12f3";
}

.mdi-message-arrow-right::before {
  content: "\f12f4";
}

.mdi-message-arrow-right-outline::before {
  content: "\f12f5";
}

.mdi-message-badge::before {
  content: "\f1941";
}

.mdi-message-badge-outline::before {
  content: "\f1942";
}

.mdi-message-bookmark::before {
  content: "\f15ac";
}

.mdi-message-bookmark-outline::before {
  content: "\f15ad";
}

.mdi-message-bulleted::before {
  content: "\f06a2";
}

.mdi-message-bulleted-off::before {
  content: "\f06a3";
}

.mdi-message-cog::before {
  content: "\f06f1";
}

.mdi-message-cog-outline::before {
  content: "\f1172";
}

.mdi-message-draw::before {
  content: "\f0363";
}

.mdi-message-flash::before {
  content: "\f15a9";
}

.mdi-message-flash-outline::before {
  content: "\f15aa";
}

.mdi-message-image::before {
  content: "\f0364";
}

.mdi-message-image-outline::before {
  content: "\f116c";
}

.mdi-message-lock::before {
  content: "\f0fcc";
}

.mdi-message-lock-outline::before {
  content: "\f116d";
}

.mdi-message-minus::before {
  content: "\f116e";
}

.mdi-message-minus-outline::before {
  content: "\f116f";
}

.mdi-message-off::before {
  content: "\f164d";
}

.mdi-message-off-outline::before {
  content: "\f164e";
}

.mdi-message-outline::before {
  content: "\f0365";
}

.mdi-message-plus::before {
  content: "\f0653";
}

.mdi-message-plus-outline::before {
  content: "\f10bb";
}

.mdi-message-processing::before {
  content: "\f0366";
}

.mdi-message-processing-outline::before {
  content: "\f1170";
}

.mdi-message-question::before {
  content: "\f173a";
}

.mdi-message-question-outline::before {
  content: "\f173b";
}

.mdi-message-reply::before {
  content: "\f0367";
}

.mdi-message-reply-outline::before {
  content: "\f173d";
}

.mdi-message-reply-text::before {
  content: "\f0368";
}

.mdi-message-reply-text-outline::before {
  content: "\f173e";
}

.mdi-message-settings::before {
  content: "\f06f0";
}

.mdi-message-settings-outline::before {
  content: "\f1171";
}

.mdi-message-star::before {
  content: "\f069a";
}

.mdi-message-star-outline::before {
  content: "\f1250";
}

.mdi-message-text::before {
  content: "\f0369";
}

.mdi-message-text-clock::before {
  content: "\f1173";
}

.mdi-message-text-clock-outline::before {
  content: "\f1174";
}

.mdi-message-text-lock::before {
  content: "\f0fcd";
}

.mdi-message-text-lock-outline::before {
  content: "\f1175";
}

.mdi-message-text-outline::before {
  content: "\f036a";
}

.mdi-message-video::before {
  content: "\f036b";
}

.mdi-meteor::before {
  content: "\f0629";
}

.mdi-metronome::before {
  content: "\f07da";
}

.mdi-metronome-tick::before {
  content: "\f07db";
}

.mdi-micro-sd::before {
  content: "\f07dc";
}

.mdi-microphone::before {
  content: "\f036c";
}

.mdi-microphone-minus::before {
  content: "\f08b3";
}

.mdi-microphone-off::before {
  content: "\f036d";
}

.mdi-microphone-outline::before {
  content: "\f036e";
}

.mdi-microphone-plus::before {
  content: "\f08b4";
}

.mdi-microphone-question::before {
  content: "\f1989";
}

.mdi-microphone-question-outline::before {
  content: "\f198a";
}

.mdi-microphone-settings::before {
  content: "\f036f";
}

.mdi-microphone-variant::before {
  content: "\f0370";
}

.mdi-microphone-variant-off::before {
  content: "\f0371";
}

.mdi-microscope::before {
  content: "\f0654";
}

.mdi-microsoft::before {
  content: "\f0372";
}

.mdi-microsoft-access::before {
  content: "\f138e";
}

.mdi-microsoft-azure::before {
  content: "\f0805";
}

.mdi-microsoft-azure-devops::before {
  content: "\f0fd5";
}

.mdi-microsoft-bing::before {
  content: "\f00a4";
}

.mdi-microsoft-dynamics-365::before {
  content: "\f0988";
}

.mdi-microsoft-edge::before {
  content: "\f01e9";
}

.mdi-microsoft-excel::before {
  content: "\f138f";
}

.mdi-microsoft-internet-explorer::before {
  content: "\f0300";
}

.mdi-microsoft-office::before {
  content: "\f03c6";
}

.mdi-microsoft-onedrive::before {
  content: "\f03ca";
}

.mdi-microsoft-onenote::before {
  content: "\f0747";
}

.mdi-microsoft-outlook::before {
  content: "\f0d22";
}

.mdi-microsoft-powerpoint::before {
  content: "\f1390";
}

.mdi-microsoft-sharepoint::before {
  content: "\f1391";
}

.mdi-microsoft-teams::before {
  content: "\f02bb";
}

.mdi-microsoft-visual-studio::before {
  content: "\f0610";
}

.mdi-microsoft-visual-studio-code::before {
  content: "\f0a1e";
}

.mdi-microsoft-windows::before {
  content: "\f05b3";
}

.mdi-microsoft-windows-classic::before {
  content: "\f0a21";
}

.mdi-microsoft-word::before {
  content: "\f1392";
}

.mdi-microsoft-xbox::before {
  content: "\f05b9";
}

.mdi-microsoft-xbox-controller::before {
  content: "\f05ba";
}

.mdi-microsoft-xbox-controller-battery-alert::before {
  content: "\f074b";
}

.mdi-microsoft-xbox-controller-battery-charging::before {
  content: "\f0a22";
}

.mdi-microsoft-xbox-controller-battery-empty::before {
  content: "\f074c";
}

.mdi-microsoft-xbox-controller-battery-full::before {
  content: "\f074d";
}

.mdi-microsoft-xbox-controller-battery-low::before {
  content: "\f074e";
}

.mdi-microsoft-xbox-controller-battery-medium::before {
  content: "\f074f";
}

.mdi-microsoft-xbox-controller-battery-unknown::before {
  content: "\f0750";
}

.mdi-microsoft-xbox-controller-menu::before {
  content: "\f0e6f";
}

.mdi-microsoft-xbox-controller-off::before {
  content: "\f05bb";
}

.mdi-microsoft-xbox-controller-view::before {
  content: "\f0e70";
}

.mdi-microwave::before {
  content: "\f0c99";
}

.mdi-microwave-off::before {
  content: "\f1423";
}

.mdi-middleware::before {
  content: "\f0f5d";
}

.mdi-middleware-outline::before {
  content: "\f0f5e";
}

.mdi-midi::before {
  content: "\f08f1";
}

.mdi-midi-port::before {
  content: "\f08f2";
}

.mdi-mine::before {
  content: "\f0dda";
}

.mdi-minecraft::before {
  content: "\f0373";
}

.mdi-mini-sd::before {
  content: "\f0a05";
}

.mdi-minidisc::before {
  content: "\f0a06";
}

.mdi-minus::before {
  content: "\f0374";
}

.mdi-minus-box::before {
  content: "\f0375";
}

.mdi-minus-box-multiple::before {
  content: "\f1141";
}

.mdi-minus-box-multiple-outline::before {
  content: "\f1142";
}

.mdi-minus-box-outline::before {
  content: "\f06f2";
}

.mdi-minus-circle::before {
  content: "\f0376";
}

.mdi-minus-circle-multiple::before {
  content: "\f035a";
}

.mdi-minus-circle-multiple-outline::before {
  content: "\f0ad3";
}

.mdi-minus-circle-off::before {
  content: "\f1459";
}

.mdi-minus-circle-off-outline::before {
  content: "\f145a";
}

.mdi-minus-circle-outline::before {
  content: "\f0377";
}

.mdi-minus-network::before {
  content: "\f0378";
}

.mdi-minus-network-outline::before {
  content: "\f0c9a";
}

.mdi-minus-thick::before {
  content: "\f1639";
}

.mdi-mirror::before {
  content: "\f11fd";
}

.mdi-mirror-rectangle::before {
  content: "\f179f";
}

.mdi-mirror-variant::before {
  content: "\f17a0";
}

.mdi-mixed-martial-arts::before {
  content: "\f0d8f";
}

.mdi-mixed-reality::before {
  content: "\f087f";
}

.mdi-molecule::before {
  content: "\f0bac";
}

.mdi-molecule-co::before {
  content: "\f12fe";
}

.mdi-molecule-co2::before {
  content: "\f07e4";
}

.mdi-monitor::before {
  content: "\f0379";
}

.mdi-monitor-cellphone::before {
  content: "\f0989";
}

.mdi-monitor-cellphone-star::before {
  content: "\f098a";
}

.mdi-monitor-dashboard::before {
  content: "\f0a07";
}

.mdi-monitor-edit::before {
  content: "\f12c6";
}

.mdi-monitor-eye::before {
  content: "\f13b4";
}

.mdi-monitor-lock::before {
  content: "\f0ddb";
}

.mdi-monitor-multiple::before {
  content: "\f037a";
}

.mdi-monitor-off::before {
  content: "\f0d90";
}

.mdi-monitor-screenshot::before {
  content: "\f0e51";
}

.mdi-monitor-share::before {
  content: "\f1483";
}

.mdi-monitor-shimmer::before {
  content: "\f1104";
}

.mdi-monitor-small::before {
  content: "\f1876";
}

.mdi-monitor-speaker::before {
  content: "\f0f5f";
}

.mdi-monitor-speaker-off::before {
  content: "\f0f60";
}

.mdi-monitor-star::before {
  content: "\f0ddc";
}

.mdi-moon-first-quarter::before {
  content: "\f0f61";
}

.mdi-moon-full::before {
  content: "\f0f62";
}

.mdi-moon-last-quarter::before {
  content: "\f0f63";
}

.mdi-moon-new::before {
  content: "\f0f64";
}

.mdi-moon-waning-crescent::before {
  content: "\f0f65";
}

.mdi-moon-waning-gibbous::before {
  content: "\f0f66";
}

.mdi-moon-waxing-crescent::before {
  content: "\f0f67";
}

.mdi-moon-waxing-gibbous::before {
  content: "\f0f68";
}

.mdi-moped::before {
  content: "\f1086";
}

.mdi-moped-electric::before {
  content: "\f15b7";
}

.mdi-moped-electric-outline::before {
  content: "\f15b8";
}

.mdi-moped-outline::before {
  content: "\f15b9";
}

.mdi-more::before {
  content: "\f037b";
}

.mdi-mortar-pestle::before {
  content: "\f1748";
}

.mdi-mortar-pestle-plus::before {
  content: "\f03f1";
}

.mdi-mosque::before {
  content: "\f1827";
}

.mdi-mother-heart::before {
  content: "\f1314";
}

.mdi-mother-nurse::before {
  content: "\f0d21";
}

.mdi-motion::before {
  content: "\f15b2";
}

.mdi-motion-outline::before {
  content: "\f15b3";
}

.mdi-motion-pause::before {
  content: "\f1590";
}

.mdi-motion-pause-outline::before {
  content: "\f1592";
}

.mdi-motion-play::before {
  content: "\f158f";
}

.mdi-motion-play-outline::before {
  content: "\f1591";
}

.mdi-motion-sensor::before {
  content: "\f0d91";
}

.mdi-motion-sensor-off::before {
  content: "\f1435";
}

.mdi-motorbike::before {
  content: "\f037c";
}

.mdi-motorbike-electric::before {
  content: "\f15ba";
}

.mdi-mouse::before {
  content: "\f037d";
}

.mdi-mouse-bluetooth::before {
  content: "\f098b";
}

.mdi-mouse-move-down::before {
  content: "\f1550";
}

.mdi-mouse-move-up::before {
  content: "\f1551";
}

.mdi-mouse-move-vertical::before {
  content: "\f1552";
}

.mdi-mouse-off::before {
  content: "\f037e";
}

.mdi-mouse-variant::before {
  content: "\f037f";
}

.mdi-mouse-variant-off::before {
  content: "\f0380";
}

.mdi-move-resize::before {
  content: "\f0655";
}

.mdi-move-resize-variant::before {
  content: "\f0656";
}

.mdi-movie::before {
  content: "\f0381";
}

.mdi-movie-check::before {
  content: "\f16f3";
}

.mdi-movie-check-outline::before {
  content: "\f16f4";
}

.mdi-movie-cog::before {
  content: "\f16f5";
}

.mdi-movie-cog-outline::before {
  content: "\f16f6";
}

.mdi-movie-edit::before {
  content: "\f1122";
}

.mdi-movie-edit-outline::before {
  content: "\f1123";
}

.mdi-movie-filter::before {
  content: "\f1124";
}

.mdi-movie-filter-outline::before {
  content: "\f1125";
}

.mdi-movie-minus::before {
  content: "\f16f7";
}

.mdi-movie-minus-outline::before {
  content: "\f16f8";
}

.mdi-movie-off::before {
  content: "\f16f9";
}

.mdi-movie-off-outline::before {
  content: "\f16fa";
}

.mdi-movie-open::before {
  content: "\f0fce";
}

.mdi-movie-open-check::before {
  content: "\f16fb";
}

.mdi-movie-open-check-outline::before {
  content: "\f16fc";
}

.mdi-movie-open-cog::before {
  content: "\f16fd";
}

.mdi-movie-open-cog-outline::before {
  content: "\f16fe";
}

.mdi-movie-open-edit::before {
  content: "\f16ff";
}

.mdi-movie-open-edit-outline::before {
  content: "\f1700";
}

.mdi-movie-open-minus::before {
  content: "\f1701";
}

.mdi-movie-open-minus-outline::before {
  content: "\f1702";
}

.mdi-movie-open-off::before {
  content: "\f1703";
}

.mdi-movie-open-off-outline::before {
  content: "\f1704";
}

.mdi-movie-open-outline::before {
  content: "\f0fcf";
}

.mdi-movie-open-play::before {
  content: "\f1705";
}

.mdi-movie-open-play-outline::before {
  content: "\f1706";
}

.mdi-movie-open-plus::before {
  content: "\f1707";
}

.mdi-movie-open-plus-outline::before {
  content: "\f1708";
}

.mdi-movie-open-remove::before {
  content: "\f1709";
}

.mdi-movie-open-remove-outline::before {
  content: "\f170a";
}

.mdi-movie-open-settings::before {
  content: "\f170b";
}

.mdi-movie-open-settings-outline::before {
  content: "\f170c";
}

.mdi-movie-open-star::before {
  content: "\f170d";
}

.mdi-movie-open-star-outline::before {
  content: "\f170e";
}

.mdi-movie-outline::before {
  content: "\f0ddd";
}

.mdi-movie-play::before {
  content: "\f170f";
}

.mdi-movie-play-outline::before {
  content: "\f1710";
}

.mdi-movie-plus::before {
  content: "\f1711";
}

.mdi-movie-plus-outline::before {
  content: "\f1712";
}

.mdi-movie-remove::before {
  content: "\f1713";
}

.mdi-movie-remove-outline::before {
  content: "\f1714";
}

.mdi-movie-roll::before {
  content: "\f07de";
}

.mdi-movie-search::before {
  content: "\f11d2";
}

.mdi-movie-search-outline::before {
  content: "\f11d3";
}

.mdi-movie-settings::before {
  content: "\f1715";
}

.mdi-movie-settings-outline::before {
  content: "\f1716";
}

.mdi-movie-star::before {
  content: "\f1717";
}

.mdi-movie-star-outline::before {
  content: "\f1718";
}

.mdi-mower::before {
  content: "\f166f";
}

.mdi-mower-bag::before {
  content: "\f1670";
}

.mdi-muffin::before {
  content: "\f098c";
}

.mdi-multicast::before {
  content: "\f1893";
}

.mdi-multiplication::before {
  content: "\f0382";
}

.mdi-multiplication-box::before {
  content: "\f0383";
}

.mdi-mushroom::before {
  content: "\f07df";
}

.mdi-mushroom-off::before {
  content: "\f13fa";
}

.mdi-mushroom-off-outline::before {
  content: "\f13fb";
}

.mdi-mushroom-outline::before {
  content: "\f07e0";
}

.mdi-music::before {
  content: "\f075a";
}

.mdi-music-accidental-double-flat::before {
  content: "\f0f69";
}

.mdi-music-accidental-double-sharp::before {
  content: "\f0f6a";
}

.mdi-music-accidental-flat::before {
  content: "\f0f6b";
}

.mdi-music-accidental-natural::before {
  content: "\f0f6c";
}

.mdi-music-accidental-sharp::before {
  content: "\f0f6d";
}

.mdi-music-box::before {
  content: "\f0384";
}

.mdi-music-box-multiple::before {
  content: "\f0333";
}

.mdi-music-box-multiple-outline::before {
  content: "\f0f04";
}

.mdi-music-box-outline::before {
  content: "\f0385";
}

.mdi-music-circle::before {
  content: "\f0386";
}

.mdi-music-circle-outline::before {
  content: "\f0ad4";
}

.mdi-music-clef-alto::before {
  content: "\f0f6e";
}

.mdi-music-clef-bass::before {
  content: "\f0f6f";
}

.mdi-music-clef-treble::before {
  content: "\f0f70";
}

.mdi-music-note::before {
  content: "\f0387";
}

.mdi-music-note-bluetooth::before {
  content: "\f05fe";
}

.mdi-music-note-bluetooth-off::before {
  content: "\f05ff";
}

.mdi-music-note-eighth::before {
  content: "\f0388";
}

.mdi-music-note-eighth-dotted::before {
  content: "\f0f71";
}

.mdi-music-note-half::before {
  content: "\f0389";
}

.mdi-music-note-half-dotted::before {
  content: "\f0f72";
}

.mdi-music-note-off::before {
  content: "\f038a";
}

.mdi-music-note-off-outline::before {
  content: "\f0f73";
}

.mdi-music-note-outline::before {
  content: "\f0f74";
}

.mdi-music-note-plus::before {
  content: "\f0dde";
}

.mdi-music-note-quarter::before {
  content: "\f038b";
}

.mdi-music-note-quarter-dotted::before {
  content: "\f0f75";
}

.mdi-music-note-sixteenth::before {
  content: "\f038c";
}

.mdi-music-note-sixteenth-dotted::before {
  content: "\f0f76";
}

.mdi-music-note-whole::before {
  content: "\f038d";
}

.mdi-music-note-whole-dotted::before {
  content: "\f0f77";
}

.mdi-music-off::before {
  content: "\f075b";
}

.mdi-music-rest-eighth::before {
  content: "\f0f78";
}

.mdi-music-rest-half::before {
  content: "\f0f79";
}

.mdi-music-rest-quarter::before {
  content: "\f0f7a";
}

.mdi-music-rest-sixteenth::before {
  content: "\f0f7b";
}

.mdi-music-rest-whole::before {
  content: "\f0f7c";
}

.mdi-mustache::before {
  content: "\f15de";
}

.mdi-nail::before {
  content: "\f0ddf";
}

.mdi-nas::before {
  content: "\f08f3";
}

.mdi-nativescript::before {
  content: "\f0880";
}

.mdi-nature::before {
  content: "\f038e";
}

.mdi-nature-people::before {
  content: "\f038f";
}

.mdi-navigation::before {
  content: "\f0390";
}

.mdi-navigation-outline::before {
  content: "\f1607";
}

.mdi-navigation-variant::before {
  content: "\f18f0";
}

.mdi-navigation-variant-outline::before {
  content: "\f18f1";
}

.mdi-near-me::before {
  content: "\f05cd";
}

.mdi-necklace::before {
  content: "\f0f0b";
}

.mdi-needle::before {
  content: "\f0391";
}

.mdi-netflix::before {
  content: "\f0746";
}

.mdi-network::before {
  content: "\f06f3";
}

.mdi-network-off::before {
  content: "\f0c9b";
}

.mdi-network-off-outline::before {
  content: "\f0c9c";
}

.mdi-network-outline::before {
  content: "\f0c9d";
}

.mdi-network-strength-1::before {
  content: "\f08f4";
}

.mdi-network-strength-1-alert::before {
  content: "\f08f5";
}

.mdi-network-strength-2::before {
  content: "\f08f6";
}

.mdi-network-strength-2-alert::before {
  content: "\f08f7";
}

.mdi-network-strength-3::before {
  content: "\f08f8";
}

.mdi-network-strength-3-alert::before {
  content: "\f08f9";
}

.mdi-network-strength-4::before {
  content: "\f08fa";
}

.mdi-network-strength-4-alert::before {
  content: "\f08fb";
}

.mdi-network-strength-4-cog::before {
  content: "\f191a";
}

.mdi-network-strength-off::before {
  content: "\f08fc";
}

.mdi-network-strength-off-outline::before {
  content: "\f08fd";
}

.mdi-network-strength-outline::before {
  content: "\f08fe";
}

.mdi-new-box::before {
  content: "\f0394";
}

.mdi-newspaper::before {
  content: "\f0395";
}

.mdi-newspaper-check::before {
  content: "\f1943";
}

.mdi-newspaper-minus::before {
  content: "\f0f0c";
}

.mdi-newspaper-plus::before {
  content: "\f0f0d";
}

.mdi-newspaper-remove::before {
  content: "\f1944";
}

.mdi-newspaper-variant::before {
  content: "\f1001";
}

.mdi-newspaper-variant-multiple::before {
  content: "\f1002";
}

.mdi-newspaper-variant-multiple-outline::before {
  content: "\f1003";
}

.mdi-newspaper-variant-outline::before {
  content: "\f1004";
}

.mdi-nfc::before {
  content: "\f0396";
}

.mdi-nfc-search-variant::before {
  content: "\f0e53";
}

.mdi-nfc-tap::before {
  content: "\f0397";
}

.mdi-nfc-variant::before {
  content: "\f0398";
}

.mdi-nfc-variant-off::before {
  content: "\f0e54";
}

.mdi-ninja::before {
  content: "\f0774";
}

.mdi-nintendo-game-boy::before {
  content: "\f1393";
}

.mdi-nintendo-switch::before {
  content: "\f07e1";
}

.mdi-nintendo-wii::before {
  content: "\f05ab";
}

.mdi-nintendo-wiiu::before {
  content: "\f072d";
}

.mdi-nix::before {
  content: "\f1105";
}

.mdi-nodejs::before {
  content: "\f0399";
}

.mdi-noodles::before {
  content: "\f117e";
}

.mdi-not-equal::before {
  content: "\f098d";
}

.mdi-not-equal-variant::before {
  content: "\f098e";
}

.mdi-note::before {
  content: "\f039a";
}

.mdi-note-alert::before {
  content: "\f177d";
}

.mdi-note-alert-outline::before {
  content: "\f177e";
}

.mdi-note-check::before {
  content: "\f177f";
}

.mdi-note-check-outline::before {
  content: "\f1780";
}

.mdi-note-edit::before {
  content: "\f1781";
}

.mdi-note-edit-outline::before {
  content: "\f1782";
}

.mdi-note-minus::before {
  content: "\f164f";
}

.mdi-note-minus-outline::before {
  content: "\f1650";
}

.mdi-note-multiple::before {
  content: "\f06b8";
}

.mdi-note-multiple-outline::before {
  content: "\f06b9";
}

.mdi-note-off::before {
  content: "\f1783";
}

.mdi-note-off-outline::before {
  content: "\f1784";
}

.mdi-note-outline::before {
  content: "\f039b";
}

.mdi-note-plus::before {
  content: "\f039c";
}

.mdi-note-plus-outline::before {
  content: "\f039d";
}

.mdi-note-remove::before {
  content: "\f1651";
}

.mdi-note-remove-outline::before {
  content: "\f1652";
}

.mdi-note-search::before {
  content: "\f1653";
}

.mdi-note-search-outline::before {
  content: "\f1654";
}

.mdi-note-text::before {
  content: "\f039e";
}

.mdi-note-text-outline::before {
  content: "\f11d7";
}

.mdi-notebook::before {
  content: "\f082e";
}

.mdi-notebook-check::before {
  content: "\f14f5";
}

.mdi-notebook-check-outline::before {
  content: "\f14f6";
}

.mdi-notebook-edit::before {
  content: "\f14e7";
}

.mdi-notebook-edit-outline::before {
  content: "\f14e9";
}

.mdi-notebook-minus::before {
  content: "\f1610";
}

.mdi-notebook-minus-outline::before {
  content: "\f1611";
}

.mdi-notebook-multiple::before {
  content: "\f0e55";
}

.mdi-notebook-outline::before {
  content: "\f0ebf";
}

.mdi-notebook-plus::before {
  content: "\f1612";
}

.mdi-notebook-plus-outline::before {
  content: "\f1613";
}

.mdi-notebook-remove::before {
  content: "\f1614";
}

.mdi-notebook-remove-outline::before {
  content: "\f1615";
}

.mdi-notification-clear-all::before {
  content: "\f039f";
}

.mdi-npm::before {
  content: "\f06f7";
}

.mdi-nuke::before {
  content: "\f06a4";
}

.mdi-null::before {
  content: "\f07e2";
}

.mdi-numeric::before {
  content: "\f03a0";
}

.mdi-numeric-0::before {
  content: "\f0b39";
}

.mdi-numeric-0-box::before {
  content: "\f03a1";
}

.mdi-numeric-0-box-multiple::before {
  content: "\f0f0e";
}

.mdi-numeric-0-box-multiple-outline::before {
  content: "\f03a2";
}

.mdi-numeric-0-box-outline::before {
  content: "\f03a3";
}

.mdi-numeric-0-circle::before {
  content: "\f0c9e";
}

.mdi-numeric-0-circle-outline::before {
  content: "\f0c9f";
}

.mdi-numeric-1::before {
  content: "\f0b3a";
}

.mdi-numeric-1-box::before {
  content: "\f03a4";
}

.mdi-numeric-1-box-multiple::before {
  content: "\f0f0f";
}

.mdi-numeric-1-box-multiple-outline::before {
  content: "\f03a5";
}

.mdi-numeric-1-box-outline::before {
  content: "\f03a6";
}

.mdi-numeric-1-circle::before {
  content: "\f0ca0";
}

.mdi-numeric-1-circle-outline::before {
  content: "\f0ca1";
}

.mdi-numeric-10::before {
  content: "\f0fe9";
}

.mdi-numeric-10-box::before {
  content: "\f0f7d";
}

.mdi-numeric-10-box-multiple::before {
  content: "\f0fea";
}

.mdi-numeric-10-box-multiple-outline::before {
  content: "\f0feb";
}

.mdi-numeric-10-box-outline::before {
  content: "\f0f7e";
}

.mdi-numeric-10-circle::before {
  content: "\f0fec";
}

.mdi-numeric-10-circle-outline::before {
  content: "\f0fed";
}

.mdi-numeric-2::before {
  content: "\f0b3b";
}

.mdi-numeric-2-box::before {
  content: "\f03a7";
}

.mdi-numeric-2-box-multiple::before {
  content: "\f0f10";
}

.mdi-numeric-2-box-multiple-outline::before {
  content: "\f03a8";
}

.mdi-numeric-2-box-outline::before {
  content: "\f03a9";
}

.mdi-numeric-2-circle::before {
  content: "\f0ca2";
}

.mdi-numeric-2-circle-outline::before {
  content: "\f0ca3";
}

.mdi-numeric-3::before {
  content: "\f0b3c";
}

.mdi-numeric-3-box::before {
  content: "\f03aa";
}

.mdi-numeric-3-box-multiple::before {
  content: "\f0f11";
}

.mdi-numeric-3-box-multiple-outline::before {
  content: "\f03ab";
}

.mdi-numeric-3-box-outline::before {
  content: "\f03ac";
}

.mdi-numeric-3-circle::before {
  content: "\f0ca4";
}

.mdi-numeric-3-circle-outline::before {
  content: "\f0ca5";
}

.mdi-numeric-4::before {
  content: "\f0b3d";
}

.mdi-numeric-4-box::before {
  content: "\f03ad";
}

.mdi-numeric-4-box-multiple::before {
  content: "\f0f12";
}

.mdi-numeric-4-box-multiple-outline::before {
  content: "\f03b2";
}

.mdi-numeric-4-box-outline::before {
  content: "\f03ae";
}

.mdi-numeric-4-circle::before {
  content: "\f0ca6";
}

.mdi-numeric-4-circle-outline::before {
  content: "\f0ca7";
}

.mdi-numeric-5::before {
  content: "\f0b3e";
}

.mdi-numeric-5-box::before {
  content: "\f03b1";
}

.mdi-numeric-5-box-multiple::before {
  content: "\f0f13";
}

.mdi-numeric-5-box-multiple-outline::before {
  content: "\f03af";
}

.mdi-numeric-5-box-outline::before {
  content: "\f03b0";
}

.mdi-numeric-5-circle::before {
  content: "\f0ca8";
}

.mdi-numeric-5-circle-outline::before {
  content: "\f0ca9";
}

.mdi-numeric-6::before {
  content: "\f0b3f";
}

.mdi-numeric-6-box::before {
  content: "\f03b3";
}

.mdi-numeric-6-box-multiple::before {
  content: "\f0f14";
}

.mdi-numeric-6-box-multiple-outline::before {
  content: "\f03b4";
}

.mdi-numeric-6-box-outline::before {
  content: "\f03b5";
}

.mdi-numeric-6-circle::before {
  content: "\f0caa";
}

.mdi-numeric-6-circle-outline::before {
  content: "\f0cab";
}

.mdi-numeric-7::before {
  content: "\f0b40";
}

.mdi-numeric-7-box::before {
  content: "\f03b6";
}

.mdi-numeric-7-box-multiple::before {
  content: "\f0f15";
}

.mdi-numeric-7-box-multiple-outline::before {
  content: "\f03b7";
}

.mdi-numeric-7-box-outline::before {
  content: "\f03b8";
}

.mdi-numeric-7-circle::before {
  content: "\f0cac";
}

.mdi-numeric-7-circle-outline::before {
  content: "\f0cad";
}

.mdi-numeric-8::before {
  content: "\f0b41";
}

.mdi-numeric-8-box::before {
  content: "\f03b9";
}

.mdi-numeric-8-box-multiple::before {
  content: "\f0f16";
}

.mdi-numeric-8-box-multiple-outline::before {
  content: "\f03ba";
}

.mdi-numeric-8-box-outline::before {
  content: "\f03bb";
}

.mdi-numeric-8-circle::before {
  content: "\f0cae";
}

.mdi-numeric-8-circle-outline::before {
  content: "\f0caf";
}

.mdi-numeric-9::before {
  content: "\f0b42";
}

.mdi-numeric-9-box::before {
  content: "\f03bc";
}

.mdi-numeric-9-box-multiple::before {
  content: "\f0f17";
}

.mdi-numeric-9-box-multiple-outline::before {
  content: "\f03bd";
}

.mdi-numeric-9-box-outline::before {
  content: "\f03be";
}

.mdi-numeric-9-circle::before {
  content: "\f0cb0";
}

.mdi-numeric-9-circle-outline::before {
  content: "\f0cb1";
}

.mdi-numeric-9-plus::before {
  content: "\f0fee";
}

.mdi-numeric-9-plus-box::before {
  content: "\f03bf";
}

.mdi-numeric-9-plus-box-multiple::before {
  content: "\f0f18";
}

.mdi-numeric-9-plus-box-multiple-outline::before {
  content: "\f03c0";
}

.mdi-numeric-9-plus-box-outline::before {
  content: "\f03c1";
}

.mdi-numeric-9-plus-circle::before {
  content: "\f0cb2";
}

.mdi-numeric-9-plus-circle-outline::before {
  content: "\f0cb3";
}

.mdi-numeric-negative-1::before {
  content: "\f1052";
}

.mdi-numeric-positive-1::before {
  content: "\f15cb";
}

.mdi-nut::before {
  content: "\f06f8";
}

.mdi-nutrition::before {
  content: "\f03c2";
}

.mdi-nuxt::before {
  content: "\f1106";
}

.mdi-oar::before {
  content: "\f067c";
}

.mdi-ocarina::before {
  content: "\f0de0";
}

.mdi-oci::before {
  content: "\f12e9";
}

.mdi-ocr::before {
  content: "\f113a";
}

.mdi-octagon::before {
  content: "\f03c3";
}

.mdi-octagon-outline::before {
  content: "\f03c4";
}

.mdi-octagram::before {
  content: "\f06f9";
}

.mdi-octagram-outline::before {
  content: "\f0775";
}

.mdi-octahedron::before {
  content: "\f1950";
}

.mdi-octahedron-off::before {
  content: "\f1951";
}

.mdi-odnoklassniki::before {
  content: "\f03c5";
}

.mdi-offer::before {
  content: "\f121b";
}

.mdi-office-building::before {
  content: "\f0991";
}

.mdi-office-building-cog::before {
  content: "\f1949";
}

.mdi-office-building-cog-outline::before {
  content: "\f194a";
}

.mdi-office-building-marker::before {
  content: "\f1520";
}

.mdi-office-building-marker-outline::before {
  content: "\f1521";
}

.mdi-office-building-outline::before {
  content: "\f151f";
}

.mdi-oil::before {
  content: "\f03c7";
}

.mdi-oil-lamp::before {
  content: "\f0f19";
}

.mdi-oil-level::before {
  content: "\f1053";
}

.mdi-oil-temperature::before {
  content: "\f0ff8";
}

.mdi-om::before {
  content: "\f0973";
}

.mdi-omega::before {
  content: "\f03c9";
}

.mdi-one-up::before {
  content: "\f0bad";
}

.mdi-onepassword::before {
  content: "\f0881";
}

.mdi-opacity::before {
  content: "\f05cc";
}

.mdi-open-in-app::before {
  content: "\f03cb";
}

.mdi-open-in-new::before {
  content: "\f03cc";
}

.mdi-open-source-initiative::before {
  content: "\f0bae";
}

.mdi-openid::before {
  content: "\f03cd";
}

.mdi-opera::before {
  content: "\f03ce";
}

.mdi-orbit::before {
  content: "\f0018";
}

.mdi-orbit-variant::before {
  content: "\f15db";
}

.mdi-order-alphabetical-ascending::before {
  content: "\f020d";
}

.mdi-order-alphabetical-descending::before {
  content: "\f0d07";
}

.mdi-order-bool-ascending::before {
  content: "\f02be";
}

.mdi-order-bool-ascending-variant::before {
  content: "\f098f";
}

.mdi-order-bool-descending::before {
  content: "\f1384";
}

.mdi-order-bool-descending-variant::before {
  content: "\f0990";
}

.mdi-order-numeric-ascending::before {
  content: "\f0545";
}

.mdi-order-numeric-descending::before {
  content: "\f0546";
}

.mdi-origin::before {
  content: "\f0b43";
}

.mdi-ornament::before {
  content: "\f03cf";
}

.mdi-ornament-variant::before {
  content: "\f03d0";
}

.mdi-outdoor-lamp::before {
  content: "\f1054";
}

.mdi-overscan::before {
  content: "\f1005";
}

.mdi-owl::before {
  content: "\f03d2";
}

.mdi-pac-man::before {
  content: "\f0baf";
}

.mdi-package::before {
  content: "\f03d3";
}

.mdi-package-down::before {
  content: "\f03d4";
}

.mdi-package-up::before {
  content: "\f03d5";
}

.mdi-package-variant::before {
  content: "\f03d6";
}

.mdi-package-variant-closed::before {
  content: "\f03d7";
}

.mdi-page-first::before {
  content: "\f0600";
}

.mdi-page-last::before {
  content: "\f0601";
}

.mdi-page-layout-body::before {
  content: "\f06fa";
}

.mdi-page-layout-footer::before {
  content: "\f06fb";
}

.mdi-page-layout-header::before {
  content: "\f06fc";
}

.mdi-page-layout-header-footer::before {
  content: "\f0f7f";
}

.mdi-page-layout-sidebar-left::before {
  content: "\f06fd";
}

.mdi-page-layout-sidebar-right::before {
  content: "\f06fe";
}

.mdi-page-next::before {
  content: "\f0bb0";
}

.mdi-page-next-outline::before {
  content: "\f0bb1";
}

.mdi-page-previous::before {
  content: "\f0bb2";
}

.mdi-page-previous-outline::before {
  content: "\f0bb3";
}

.mdi-pail::before {
  content: "\f1417";
}

.mdi-pail-minus::before {
  content: "\f1437";
}

.mdi-pail-minus-outline::before {
  content: "\f143c";
}

.mdi-pail-off::before {
  content: "\f1439";
}

.mdi-pail-off-outline::before {
  content: "\f143e";
}

.mdi-pail-outline::before {
  content: "\f143a";
}

.mdi-pail-plus::before {
  content: "\f1436";
}

.mdi-pail-plus-outline::before {
  content: "\f143b";
}

.mdi-pail-remove::before {
  content: "\f1438";
}

.mdi-pail-remove-outline::before {
  content: "\f143d";
}

.mdi-palette::before {
  content: "\f03d8";
}

.mdi-palette-advanced::before {
  content: "\f03d9";
}

.mdi-palette-outline::before {
  content: "\f0e0c";
}

.mdi-palette-swatch::before {
  content: "\f08b5";
}

.mdi-palette-swatch-outline::before {
  content: "\f135c";
}

.mdi-palette-swatch-variant::before {
  content: "\f195a";
}

.mdi-palm-tree::before {
  content: "\f1055";
}

.mdi-pan::before {
  content: "\f0bb4";
}

.mdi-pan-bottom-left::before {
  content: "\f0bb5";
}

.mdi-pan-bottom-right::before {
  content: "\f0bb6";
}

.mdi-pan-down::before {
  content: "\f0bb7";
}

.mdi-pan-horizontal::before {
  content: "\f0bb8";
}

.mdi-pan-left::before {
  content: "\f0bb9";
}

.mdi-pan-right::before {
  content: "\f0bba";
}

.mdi-pan-top-left::before {
  content: "\f0bbb";
}

.mdi-pan-top-right::before {
  content: "\f0bbc";
}

.mdi-pan-up::before {
  content: "\f0bbd";
}

.mdi-pan-vertical::before {
  content: "\f0bbe";
}

.mdi-panda::before {
  content: "\f03da";
}

.mdi-pandora::before {
  content: "\f03db";
}

.mdi-panorama::before {
  content: "\f03dc";
}

.mdi-panorama-fisheye::before {
  content: "\f03dd";
}

.mdi-panorama-horizontal::before {
  content: "\f1928";
}

.mdi-panorama-horizontal-outline::before {
  content: "\f03de";
}

.mdi-panorama-outline::before {
  content: "\f198c";
}

.mdi-panorama-sphere::before {
  content: "\f198d";
}

.mdi-panorama-sphere-outline::before {
  content: "\f198e";
}

.mdi-panorama-variant::before {
  content: "\f198f";
}

.mdi-panorama-variant-outline::before {
  content: "\f1990";
}

.mdi-panorama-vertical::before {
  content: "\f1929";
}

.mdi-panorama-vertical-outline::before {
  content: "\f03df";
}

.mdi-panorama-wide-angle::before {
  content: "\f195f";
}

.mdi-panorama-wide-angle-outline::before {
  content: "\f03e0";
}

.mdi-paper-cut-vertical::before {
  content: "\f03e1";
}

.mdi-paper-roll::before {
  content: "\f1157";
}

.mdi-paper-roll-outline::before {
  content: "\f1158";
}

.mdi-paperclip::before {
  content: "\f03e2";
}

.mdi-parachute::before {
  content: "\f0cb4";
}

.mdi-parachute-outline::before {
  content: "\f0cb5";
}

.mdi-paragliding::before {
  content: "\f1745";
}

.mdi-parking::before {
  content: "\f03e3";
}

.mdi-party-popper::before {
  content: "\f1056";
}

.mdi-passport::before {
  content: "\f07e3";
}

.mdi-passport-biometric::before {
  content: "\f0de1";
}

.mdi-pasta::before {
  content: "\f1160";
}

.mdi-patio-heater::before {
  content: "\f0f80";
}

.mdi-patreon::before {
  content: "\f0882";
}

.mdi-pause::before {
  content: "\f03e4";
}

.mdi-pause-circle::before {
  content: "\f03e5";
}

.mdi-pause-circle-outline::before {
  content: "\f03e6";
}

.mdi-pause-octagon::before {
  content: "\f03e7";
}

.mdi-pause-octagon-outline::before {
  content: "\f03e8";
}

.mdi-paw::before {
  content: "\f03e9";
}

.mdi-paw-off::before {
  content: "\f0657";
}

.mdi-paw-off-outline::before {
  content: "\f1676";
}

.mdi-paw-outline::before {
  content: "\f1675";
}

.mdi-peace::before {
  content: "\f0884";
}

.mdi-peanut::before {
  content: "\f0ffc";
}

.mdi-peanut-off::before {
  content: "\f0ffd";
}

.mdi-peanut-off-outline::before {
  content: "\f0fff";
}

.mdi-peanut-outline::before {
  content: "\f0ffe";
}

.mdi-pen::before {
  content: "\f03ea";
}

.mdi-pen-lock::before {
  content: "\f0de2";
}

.mdi-pen-minus::before {
  content: "\f0de3";
}

.mdi-pen-off::before {
  content: "\f0de4";
}

.mdi-pen-plus::before {
  content: "\f0de5";
}

.mdi-pen-remove::before {
  content: "\f0de6";
}

.mdi-pencil::before {
  content: "\f03eb";
}

.mdi-pencil-box::before {
  content: "\f03ec";
}

.mdi-pencil-box-multiple::before {
  content: "\f1144";
}

.mdi-pencil-box-multiple-outline::before {
  content: "\f1145";
}

.mdi-pencil-box-outline::before {
  content: "\f03ed";
}

.mdi-pencil-circle::before {
  content: "\f06ff";
}

.mdi-pencil-circle-outline::before {
  content: "\f0776";
}

.mdi-pencil-lock::before {
  content: "\f03ee";
}

.mdi-pencil-lock-outline::before {
  content: "\f0de7";
}

.mdi-pencil-minus::before {
  content: "\f0de8";
}

.mdi-pencil-minus-outline::before {
  content: "\f0de9";
}

.mdi-pencil-off::before {
  content: "\f03ef";
}

.mdi-pencil-off-outline::before {
  content: "\f0dea";
}

.mdi-pencil-outline::before {
  content: "\f0cb6";
}

.mdi-pencil-plus::before {
  content: "\f0deb";
}

.mdi-pencil-plus-outline::before {
  content: "\f0dec";
}

.mdi-pencil-remove::before {
  content: "\f0ded";
}

.mdi-pencil-remove-outline::before {
  content: "\f0dee";
}

.mdi-pencil-ruler::before {
  content: "\f1353";
}

.mdi-penguin::before {
  content: "\f0ec0";
}

.mdi-pentagon::before {
  content: "\f0701";
}

.mdi-pentagon-outline::before {
  content: "\f0700";
}

.mdi-pentagram::before {
  content: "\f1667";
}

.mdi-percent::before {
  content: "\f03f0";
}

.mdi-percent-outline::before {
  content: "\f1278";
}

.mdi-periodic-table::before {
  content: "\f08b6";
}

.mdi-perspective-less::before {
  content: "\f0d23";
}

.mdi-perspective-more::before {
  content: "\f0d24";
}

.mdi-ph::before {
  content: "\f17c5";
}

.mdi-phone::before {
  content: "\f03f2";
}

.mdi-phone-alert::before {
  content: "\f0f1a";
}

.mdi-phone-alert-outline::before {
  content: "\f118e";
}

.mdi-phone-bluetooth::before {
  content: "\f03f3";
}

.mdi-phone-bluetooth-outline::before {
  content: "\f118f";
}

.mdi-phone-cancel::before {
  content: "\f10bc";
}

.mdi-phone-cancel-outline::before {
  content: "\f1190";
}

.mdi-phone-check::before {
  content: "\f11a9";
}

.mdi-phone-check-outline::before {
  content: "\f11aa";
}

.mdi-phone-classic::before {
  content: "\f0602";
}

.mdi-phone-classic-off::before {
  content: "\f1279";
}

.mdi-phone-dial::before {
  content: "\f1559";
}

.mdi-phone-dial-outline::before {
  content: "\f155a";
}

.mdi-phone-forward::before {
  content: "\f03f4";
}

.mdi-phone-forward-outline::before {
  content: "\f1191";
}

.mdi-phone-hangup::before {
  content: "\f03f5";
}

.mdi-phone-hangup-outline::before {
  content: "\f1192";
}

.mdi-phone-in-talk::before {
  content: "\f03f6";
}

.mdi-phone-in-talk-outline::before {
  content: "\f1182";
}

.mdi-phone-incoming::before {
  content: "\f03f7";
}

.mdi-phone-incoming-outline::before {
  content: "\f1193";
}

.mdi-phone-lock::before {
  content: "\f03f8";
}

.mdi-phone-lock-outline::before {
  content: "\f1194";
}

.mdi-phone-log::before {
  content: "\f03f9";
}

.mdi-phone-log-outline::before {
  content: "\f1195";
}

.mdi-phone-message::before {
  content: "\f1196";
}

.mdi-phone-message-outline::before {
  content: "\f1197";
}

.mdi-phone-minus::before {
  content: "\f0658";
}

.mdi-phone-minus-outline::before {
  content: "\f1198";
}

.mdi-phone-missed::before {
  content: "\f03fa";
}

.mdi-phone-missed-outline::before {
  content: "\f11a5";
}

.mdi-phone-off::before {
  content: "\f0def";
}

.mdi-phone-off-outline::before {
  content: "\f11a6";
}

.mdi-phone-outgoing::before {
  content: "\f03fb";
}

.mdi-phone-outgoing-outline::before {
  content: "\f1199";
}

.mdi-phone-outline::before {
  content: "\f0df0";
}

.mdi-phone-paused::before {
  content: "\f03fc";
}

.mdi-phone-paused-outline::before {
  content: "\f119a";
}

.mdi-phone-plus::before {
  content: "\f0659";
}

.mdi-phone-plus-outline::before {
  content: "\f119b";
}

.mdi-phone-refresh::before {
  content: "\f1993";
}

.mdi-phone-refresh-outline::before {
  content: "\f1994";
}

.mdi-phone-remove::before {
  content: "\f152f";
}

.mdi-phone-remove-outline::before {
  content: "\f1530";
}

.mdi-phone-return::before {
  content: "\f082f";
}

.mdi-phone-return-outline::before {
  content: "\f119c";
}

.mdi-phone-ring::before {
  content: "\f11ab";
}

.mdi-phone-ring-outline::before {
  content: "\f11ac";
}

.mdi-phone-rotate-landscape::before {
  content: "\f0885";
}

.mdi-phone-rotate-portrait::before {
  content: "\f0886";
}

.mdi-phone-settings::before {
  content: "\f03fd";
}

.mdi-phone-settings-outline::before {
  content: "\f119d";
}

.mdi-phone-sync::before {
  content: "\f1995";
}

.mdi-phone-sync-outline::before {
  content: "\f1996";
}

.mdi-phone-voip::before {
  content: "\f03fe";
}

.mdi-pi::before {
  content: "\f03ff";
}

.mdi-pi-box::before {
  content: "\f0400";
}

.mdi-pi-hole::before {
  content: "\f0df1";
}

.mdi-piano::before {
  content: "\f067d";
}

.mdi-piano-off::before {
  content: "\f0698";
}

.mdi-pickaxe::before {
  content: "\f08b7";
}

.mdi-picture-in-picture-bottom-right::before {
  content: "\f0e57";
}

.mdi-picture-in-picture-bottom-right-outline::before {
  content: "\f0e58";
}

.mdi-picture-in-picture-top-right::before {
  content: "\f0e59";
}

.mdi-picture-in-picture-top-right-outline::before {
  content: "\f0e5a";
}

.mdi-pier::before {
  content: "\f0887";
}

.mdi-pier-crane::before {
  content: "\f0888";
}

.mdi-pig::before {
  content: "\f0401";
}

.mdi-pig-variant::before {
  content: "\f1006";
}

.mdi-pig-variant-outline::before {
  content: "\f1678";
}

.mdi-piggy-bank::before {
  content: "\f1007";
}

.mdi-piggy-bank-outline::before {
  content: "\f1679";
}

.mdi-pill::before {
  content: "\f0402";
}

.mdi-pillar::before {
  content: "\f0702";
}

.mdi-pin::before {
  content: "\f0403";
}

.mdi-pin-off::before {
  content: "\f0404";
}

.mdi-pin-off-outline::before {
  content: "\f0930";
}

.mdi-pin-outline::before {
  content: "\f0931";
}

.mdi-pine-tree::before {
  content: "\f0405";
}

.mdi-pine-tree-box::before {
  content: "\f0406";
}

.mdi-pine-tree-fire::before {
  content: "\f141a";
}

.mdi-pinterest::before {
  content: "\f0407";
}

.mdi-pinwheel::before {
  content: "\f0ad5";
}

.mdi-pinwheel-outline::before {
  content: "\f0ad6";
}

.mdi-pipe::before {
  content: "\f07e5";
}

.mdi-pipe-disconnected::before {
  content: "\f07e6";
}

.mdi-pipe-leak::before {
  content: "\f0889";
}

.mdi-pipe-valve::before {
  content: "\f184d";
}

.mdi-pipe-wrench::before {
  content: "\f1354";
}

.mdi-pirate::before {
  content: "\f0a08";
}

.mdi-pistol::before {
  content: "\f0703";
}

.mdi-piston::before {
  content: "\f088a";
}

.mdi-pitchfork::before {
  content: "\f1553";
}

.mdi-pizza::before {
  content: "\f0409";
}

.mdi-play::before {
  content: "\f040a";
}

.mdi-play-box::before {
  content: "\f127a";
}

.mdi-play-box-multiple::before {
  content: "\f0d19";
}

.mdi-play-box-multiple-outline::before {
  content: "\f13e6";
}

.mdi-play-box-outline::before {
  content: "\f040b";
}

.mdi-play-circle::before {
  content: "\f040c";
}

.mdi-play-circle-outline::before {
  content: "\f040d";
}

.mdi-play-network::before {
  content: "\f088b";
}

.mdi-play-network-outline::before {
  content: "\f0cb7";
}

.mdi-play-outline::before {
  content: "\f0f1b";
}

.mdi-play-pause::before {
  content: "\f040e";
}

.mdi-play-protected-content::before {
  content: "\f040f";
}

.mdi-play-speed::before {
  content: "\f08ff";
}

.mdi-playlist-check::before {
  content: "\f05c7";
}

.mdi-playlist-edit::before {
  content: "\f0900";
}

.mdi-playlist-minus::before {
  content: "\f0410";
}

.mdi-playlist-music::before {
  content: "\f0cb8";
}

.mdi-playlist-music-outline::before {
  content: "\f0cb9";
}

.mdi-playlist-play::before {
  content: "\f0411";
}

.mdi-playlist-plus::before {
  content: "\f0412";
}

.mdi-playlist-remove::before {
  content: "\f0413";
}

.mdi-playlist-star::before {
  content: "\f0df2";
}

.mdi-plex::before {
  content: "\f06ba";
}

.mdi-pliers::before {
  content: "\f19a4";
}

.mdi-plus::before {
  content: "\f0415";
}

.mdi-plus-box::before {
  content: "\f0416";
}

.mdi-plus-box-multiple::before {
  content: "\f0334";
}

.mdi-plus-box-multiple-outline::before {
  content: "\f1143";
}

.mdi-plus-box-outline::before {
  content: "\f0704";
}

.mdi-plus-circle::before {
  content: "\f0417";
}

.mdi-plus-circle-multiple::before {
  content: "\f034c";
}

.mdi-plus-circle-multiple-outline::before {
  content: "\f0418";
}

.mdi-plus-circle-outline::before {
  content: "\f0419";
}

.mdi-plus-minus::before {
  content: "\f0992";
}

.mdi-plus-minus-box::before {
  content: "\f0993";
}

.mdi-plus-minus-variant::before {
  content: "\f14c9";
}

.mdi-plus-network::before {
  content: "\f041a";
}

.mdi-plus-network-outline::before {
  content: "\f0cba";
}

.mdi-plus-outline::before {
  content: "\f0705";
}

.mdi-plus-thick::before {
  content: "\f11ec";
}

.mdi-podcast::before {
  content: "\f0994";
}

.mdi-podium::before {
  content: "\f0d25";
}

.mdi-podium-bronze::before {
  content: "\f0d26";
}

.mdi-podium-gold::before {
  content: "\f0d27";
}

.mdi-podium-silver::before {
  content: "\f0d28";
}

.mdi-point-of-sale::before {
  content: "\f0d92";
}

.mdi-pokeball::before {
  content: "\f041d";
}

.mdi-pokemon-go::before {
  content: "\f0a09";
}

.mdi-poker-chip::before {
  content: "\f0830";
}

.mdi-polaroid::before {
  content: "\f041e";
}

.mdi-police-badge::before {
  content: "\f1167";
}

.mdi-police-badge-outline::before {
  content: "\f1168";
}

.mdi-police-station::before {
  content: "\f1839";
}

.mdi-poll::before {
  content: "\f041f";
}

.mdi-polo::before {
  content: "\f14c3";
}

.mdi-polymer::before {
  content: "\f0421";
}

.mdi-pool::before {
  content: "\f0606";
}

.mdi-popcorn::before {
  content: "\f0422";
}

.mdi-post::before {
  content: "\f1008";
}

.mdi-post-outline::before {
  content: "\f1009";
}

.mdi-postage-stamp::before {
  content: "\f0cbb";
}

.mdi-pot::before {
  content: "\f02e5";
}

.mdi-pot-mix::before {
  content: "\f065b";
}

.mdi-pot-mix-outline::before {
  content: "\f0677";
}

.mdi-pot-outline::before {
  content: "\f02ff";
}

.mdi-pot-steam::before {
  content: "\f065a";
}

.mdi-pot-steam-outline::before {
  content: "\f0326";
}

.mdi-pound::before {
  content: "\f0423";
}

.mdi-pound-box::before {
  content: "\f0424";
}

.mdi-pound-box-outline::before {
  content: "\f117f";
}

.mdi-power::before {
  content: "\f0425";
}

.mdi-power-cycle::before {
  content: "\f0901";
}

.mdi-power-off::before {
  content: "\f0902";
}

.mdi-power-on::before {
  content: "\f0903";
}

.mdi-power-plug::before {
  content: "\f06a5";
}

.mdi-power-plug-off::before {
  content: "\f06a6";
}

.mdi-power-plug-off-outline::before {
  content: "\f1424";
}

.mdi-power-plug-outline::before {
  content: "\f1425";
}

.mdi-power-settings::before {
  content: "\f0426";
}

.mdi-power-sleep::before {
  content: "\f0904";
}

.mdi-power-socket::before {
  content: "\f0427";
}

.mdi-power-socket-au::before {
  content: "\f0905";
}

.mdi-power-socket-ch::before {
  content: "\f0fb3";
}

.mdi-power-socket-de::before {
  content: "\f1107";
}

.mdi-power-socket-eu::before {
  content: "\f07e7";
}

.mdi-power-socket-fr::before {
  content: "\f1108";
}

.mdi-power-socket-it::before {
  content: "\f14ff";
}

.mdi-power-socket-jp::before {
  content: "\f1109";
}

.mdi-power-socket-uk::before {
  content: "\f07e8";
}

.mdi-power-socket-us::before {
  content: "\f07e9";
}

.mdi-power-standby::before {
  content: "\f0906";
}

.mdi-powershell::before {
  content: "\f0a0a";
}

.mdi-prescription::before {
  content: "\f0706";
}

.mdi-presentation::before {
  content: "\f0428";
}

.mdi-presentation-play::before {
  content: "\f0429";
}

.mdi-pretzel::before {
  content: "\f1562";
}

.mdi-printer::before {
  content: "\f042a";
}

.mdi-printer-3d::before {
  content: "\f042b";
}

.mdi-printer-3d-nozzle::before {
  content: "\f0e5b";
}

.mdi-printer-3d-nozzle-alert::before {
  content: "\f11c0";
}

.mdi-printer-3d-nozzle-alert-outline::before {
  content: "\f11c1";
}

.mdi-printer-3d-nozzle-heat::before {
  content: "\f18b8";
}

.mdi-printer-3d-nozzle-heat-outline::before {
  content: "\f18b9";
}

.mdi-printer-3d-nozzle-outline::before {
  content: "\f0e5c";
}

.mdi-printer-alert::before {
  content: "\f042c";
}

.mdi-printer-check::before {
  content: "\f1146";
}

.mdi-printer-eye::before {
  content: "\f1458";
}

.mdi-printer-off::before {
  content: "\f0e5d";
}

.mdi-printer-off-outline::before {
  content: "\f1785";
}

.mdi-printer-outline::before {
  content: "\f1786";
}

.mdi-printer-pos::before {
  content: "\f1057";
}

.mdi-printer-search::before {
  content: "\f1457";
}

.mdi-printer-settings::before {
  content: "\f0707";
}

.mdi-printer-wireless::before {
  content: "\f0a0b";
}

.mdi-priority-high::before {
  content: "\f0603";
}

.mdi-priority-low::before {
  content: "\f0604";
}

.mdi-professional-hexagon::before {
  content: "\f042d";
}

.mdi-progress-alert::before {
  content: "\f0cbc";
}

.mdi-progress-check::before {
  content: "\f0995";
}

.mdi-progress-clock::before {
  content: "\f0996";
}

.mdi-progress-close::before {
  content: "\f110a";
}

.mdi-progress-download::before {
  content: "\f0997";
}

.mdi-progress-pencil::before {
  content: "\f1787";
}

.mdi-progress-question::before {
  content: "\f1522";
}

.mdi-progress-star::before {
  content: "\f1788";
}

.mdi-progress-upload::before {
  content: "\f0998";
}

.mdi-progress-wrench::before {
  content: "\f0cbd";
}

.mdi-projector::before {
  content: "\f042e";
}

.mdi-projector-screen::before {
  content: "\f042f";
}

.mdi-projector-screen-off::before {
  content: "\f180d";
}

.mdi-projector-screen-off-outline::before {
  content: "\f180e";
}

.mdi-projector-screen-outline::before {
  content: "\f1724";
}

.mdi-projector-screen-variant::before {
  content: "\f180f";
}

.mdi-projector-screen-variant-off::before {
  content: "\f1810";
}

.mdi-projector-screen-variant-off-outline::before {
  content: "\f1811";
}

.mdi-projector-screen-variant-outline::before {
  content: "\f1812";
}

.mdi-propane-tank::before {
  content: "\f1357";
}

.mdi-propane-tank-outline::before {
  content: "\f1358";
}

.mdi-protocol::before {
  content: "\f0fd8";
}

.mdi-publish::before {
  content: "\f06a7";
}

.mdi-publish-off::before {
  content: "\f1945";
}

.mdi-pulse::before {
  content: "\f0430";
}

.mdi-pump::before {
  content: "\f1402";
}

.mdi-pumpkin::before {
  content: "\f0bbf";
}

.mdi-purse::before {
  content: "\f0f1c";
}

.mdi-purse-outline::before {
  content: "\f0f1d";
}

.mdi-puzzle::before {
  content: "\f0431";
}

.mdi-puzzle-check::before {
  content: "\f1426";
}

.mdi-puzzle-check-outline::before {
  content: "\f1427";
}

.mdi-puzzle-edit::before {
  content: "\f14d3";
}

.mdi-puzzle-edit-outline::before {
  content: "\f14d9";
}

.mdi-puzzle-heart::before {
  content: "\f14d4";
}

.mdi-puzzle-heart-outline::before {
  content: "\f14da";
}

.mdi-puzzle-minus::before {
  content: "\f14d1";
}

.mdi-puzzle-minus-outline::before {
  content: "\f14d7";
}

.mdi-puzzle-outline::before {
  content: "\f0a66";
}

.mdi-puzzle-plus::before {
  content: "\f14d0";
}

.mdi-puzzle-plus-outline::before {
  content: "\f14d6";
}

.mdi-puzzle-remove::before {
  content: "\f14d2";
}

.mdi-puzzle-remove-outline::before {
  content: "\f14d8";
}

.mdi-puzzle-star::before {
  content: "\f14d5";
}

.mdi-puzzle-star-outline::before {
  content: "\f14db";
}

.mdi-pyramid::before {
  content: "\f1952";
}

.mdi-pyramid-off::before {
  content: "\f1953";
}

.mdi-qi::before {
  content: "\f0999";
}

.mdi-qqchat::before {
  content: "\f0605";
}

.mdi-qrcode::before {
  content: "\f0432";
}

.mdi-qrcode-edit::before {
  content: "\f08b8";
}

.mdi-qrcode-minus::before {
  content: "\f118c";
}

.mdi-qrcode-plus::before {
  content: "\f118b";
}

.mdi-qrcode-remove::before {
  content: "\f118d";
}

.mdi-qrcode-scan::before {
  content: "\f0433";
}

.mdi-quadcopter::before {
  content: "\f0434";
}

.mdi-quality-high::before {
  content: "\f0435";
}

.mdi-quality-low::before {
  content: "\f0a0c";
}

.mdi-quality-medium::before {
  content: "\f0a0d";
}

.mdi-quora::before {
  content: "\f0d29";
}

.mdi-rabbit::before {
  content: "\f0907";
}

.mdi-racing-helmet::before {
  content: "\f0d93";
}

.mdi-racquetball::before {
  content: "\f0d94";
}

.mdi-radar::before {
  content: "\f0437";
}

.mdi-radiator::before {
  content: "\f0438";
}

.mdi-radiator-disabled::before {
  content: "\f0ad7";
}

.mdi-radiator-off::before {
  content: "\f0ad8";
}

.mdi-radio::before {
  content: "\f0439";
}

.mdi-radio-am::before {
  content: "\f0cbe";
}

.mdi-radio-fm::before {
  content: "\f0cbf";
}

.mdi-radio-handheld::before {
  content: "\f043a";
}

.mdi-radio-off::before {
  content: "\f121c";
}

.mdi-radio-tower::before {
  content: "\f043b";
}

.mdi-radioactive::before {
  content: "\f043c";
}

.mdi-radioactive-circle::before {
  content: "\f185d";
}

.mdi-radioactive-circle-outline::before {
  content: "\f185e";
}

.mdi-radioactive-off::before {
  content: "\f0ec1";
}

.mdi-radiobox-blank::before {
  content: "\f043d";
}

.mdi-radiobox-marked::before {
  content: "\f043e";
}

.mdi-radiology-box::before {
  content: "\f14c5";
}

.mdi-radiology-box-outline::before {
  content: "\f14c6";
}

.mdi-radius::before {
  content: "\f0cc0";
}

.mdi-radius-outline::before {
  content: "\f0cc1";
}

.mdi-railroad-light::before {
  content: "\f0f1e";
}

.mdi-rake::before {
  content: "\f1544";
}

.mdi-raspberry-pi::before {
  content: "\f043f";
}

.mdi-ray-end::before {
  content: "\f0440";
}

.mdi-ray-end-arrow::before {
  content: "\f0441";
}

.mdi-ray-start::before {
  content: "\f0442";
}

.mdi-ray-start-arrow::before {
  content: "\f0443";
}

.mdi-ray-start-end::before {
  content: "\f0444";
}

.mdi-ray-start-vertex-end::before {
  content: "\f15d8";
}

.mdi-ray-vertex::before {
  content: "\f0445";
}

.mdi-razor-double-edge::before {
  content: "\f1997";
}

.mdi-razor-single-edge::before {
  content: "\f1998";
}

.mdi-react::before {
  content: "\f0708";
}

.mdi-read::before {
  content: "\f0447";
}

.mdi-receipt::before {
  content: "\f0449";
}

.mdi-record::before {
  content: "\f044a";
}

.mdi-record-circle::before {
  content: "\f0ec2";
}

.mdi-record-circle-outline::before {
  content: "\f0ec3";
}

.mdi-record-player::before {
  content: "\f099a";
}

.mdi-record-rec::before {
  content: "\f044b";
}

.mdi-rectangle::before {
  content: "\f0e5e";
}

.mdi-rectangle-outline::before {
  content: "\f0e5f";
}

.mdi-recycle::before {
  content: "\f044c";
}

.mdi-recycle-variant::before {
  content: "\f139d";
}

.mdi-reddit::before {
  content: "\f044d";
}

.mdi-redhat::before {
  content: "\f111b";
}

.mdi-redo::before {
  content: "\f044e";
}

.mdi-redo-variant::before {
  content: "\f044f";
}

.mdi-reflect-horizontal::before {
  content: "\f0a0e";
}

.mdi-reflect-vertical::before {
  content: "\f0a0f";
}

.mdi-refresh::before {
  content: "\f0450";
}

.mdi-refresh-auto::before {
  content: "\f18f2";
}

.mdi-refresh-circle::before {
  content: "\f1377";
}

.mdi-regex::before {
  content: "\f0451";
}

.mdi-registered-trademark::before {
  content: "\f0a67";
}

.mdi-reiterate::before {
  content: "\f1588";
}

.mdi-relation-many-to-many::before {
  content: "\f1496";
}

.mdi-relation-many-to-one::before {
  content: "\f1497";
}

.mdi-relation-many-to-one-or-many::before {
  content: "\f1498";
}

.mdi-relation-many-to-only-one::before {
  content: "\f1499";
}

.mdi-relation-many-to-zero-or-many::before {
  content: "\f149a";
}

.mdi-relation-many-to-zero-or-one::before {
  content: "\f149b";
}

.mdi-relation-one-or-many-to-many::before {
  content: "\f149c";
}

.mdi-relation-one-or-many-to-one::before {
  content: "\f149d";
}

.mdi-relation-one-or-many-to-one-or-many::before {
  content: "\f149e";
}

.mdi-relation-one-or-many-to-only-one::before {
  content: "\f149f";
}

.mdi-relation-one-or-many-to-zero-or-many::before {
  content: "\f14a0";
}

.mdi-relation-one-or-many-to-zero-or-one::before {
  content: "\f14a1";
}

.mdi-relation-one-to-many::before {
  content: "\f14a2";
}

.mdi-relation-one-to-one::before {
  content: "\f14a3";
}

.mdi-relation-one-to-one-or-many::before {
  content: "\f14a4";
}

.mdi-relation-one-to-only-one::before {
  content: "\f14a5";
}

.mdi-relation-one-to-zero-or-many::before {
  content: "\f14a6";
}

.mdi-relation-one-to-zero-or-one::before {
  content: "\f14a7";
}

.mdi-relation-only-one-to-many::before {
  content: "\f14a8";
}

.mdi-relation-only-one-to-one::before {
  content: "\f14a9";
}

.mdi-relation-only-one-to-one-or-many::before {
  content: "\f14aa";
}

.mdi-relation-only-one-to-only-one::before {
  content: "\f14ab";
}

.mdi-relation-only-one-to-zero-or-many::before {
  content: "\f14ac";
}

.mdi-relation-only-one-to-zero-or-one::before {
  content: "\f14ad";
}

.mdi-relation-zero-or-many-to-many::before {
  content: "\f14ae";
}

.mdi-relation-zero-or-many-to-one::before {
  content: "\f14af";
}

.mdi-relation-zero-or-many-to-one-or-many::before {
  content: "\f14b0";
}

.mdi-relation-zero-or-many-to-only-one::before {
  content: "\f14b1";
}

.mdi-relation-zero-or-many-to-zero-or-many::before {
  content: "\f14b2";
}

.mdi-relation-zero-or-many-to-zero-or-one::before {
  content: "\f14b3";
}

.mdi-relation-zero-or-one-to-many::before {
  content: "\f14b4";
}

.mdi-relation-zero-or-one-to-one::before {
  content: "\f14b5";
}

.mdi-relation-zero-or-one-to-one-or-many::before {
  content: "\f14b6";
}

.mdi-relation-zero-or-one-to-only-one::before {
  content: "\f14b7";
}

.mdi-relation-zero-or-one-to-zero-or-many::before {
  content: "\f14b8";
}

.mdi-relation-zero-or-one-to-zero-or-one::before {
  content: "\f14b9";
}

.mdi-relative-scale::before {
  content: "\f0452";
}

.mdi-reload::before {
  content: "\f0453";
}

.mdi-reload-alert::before {
  content: "\f110b";
}

.mdi-reminder::before {
  content: "\f088c";
}

.mdi-remote::before {
  content: "\f0454";
}

.mdi-remote-desktop::before {
  content: "\f08b9";
}

.mdi-remote-off::before {
  content: "\f0ec4";
}

.mdi-remote-tv::before {
  content: "\f0ec5";
}

.mdi-remote-tv-off::before {
  content: "\f0ec6";
}

.mdi-rename-box::before {
  content: "\f0455";
}

.mdi-reorder-horizontal::before {
  content: "\f0688";
}

.mdi-reorder-vertical::before {
  content: "\f0689";
}

.mdi-repeat::before {
  content: "\f0456";
}

.mdi-repeat-off::before {
  content: "\f0457";
}

.mdi-repeat-once::before {
  content: "\f0458";
}

.mdi-repeat-variant::before {
  content: "\f0547";
}

.mdi-replay::before {
  content: "\f0459";
}

.mdi-reply::before {
  content: "\f045a";
}

.mdi-reply-all::before {
  content: "\f045b";
}

.mdi-reply-all-outline::before {
  content: "\f0f1f";
}

.mdi-reply-circle::before {
  content: "\f11ae";
}

.mdi-reply-outline::before {
  content: "\f0f20";
}

.mdi-reproduction::before {
  content: "\f045c";
}

.mdi-resistor::before {
  content: "\f0b44";
}

.mdi-resistor-nodes::before {
  content: "\f0b45";
}

.mdi-resize::before {
  content: "\f0a68";
}

.mdi-resize-bottom-right::before {
  content: "\f045d";
}

.mdi-responsive::before {
  content: "\f045e";
}

.mdi-restart::before {
  content: "\f0709";
}

.mdi-restart-alert::before {
  content: "\f110c";
}

.mdi-restart-off::before {
  content: "\f0d95";
}

.mdi-restore::before {
  content: "\f099b";
}

.mdi-restore-alert::before {
  content: "\f110d";
}

.mdi-rewind::before {
  content: "\f045f";
}

.mdi-rewind-10::before {
  content: "\f0d2a";
}

.mdi-rewind-15::before {
  content: "\f1946";
}

.mdi-rewind-30::before {
  content: "\f0d96";
}

.mdi-rewind-5::before {
  content: "\f11f9";
}

.mdi-rewind-60::before {
  content: "\f160c";
}

.mdi-rewind-outline::before {
  content: "\f070a";
}

.mdi-rhombus::before {
  content: "\f070b";
}

.mdi-rhombus-medium::before {
  content: "\f0a10";
}

.mdi-rhombus-medium-outline::before {
  content: "\f14dc";
}

.mdi-rhombus-outline::before {
  content: "\f070c";
}

.mdi-rhombus-split::before {
  content: "\f0a11";
}

.mdi-rhombus-split-outline::before {
  content: "\f14dd";
}

.mdi-ribbon::before {
  content: "\f0460";
}

.mdi-rice::before {
  content: "\f07ea";
}

.mdi-rickshaw::before {
  content: "\f15bb";
}

.mdi-rickshaw-electric::before {
  content: "\f15bc";
}

.mdi-ring::before {
  content: "\f07eb";
}

.mdi-rivet::before {
  content: "\f0e60";
}

.mdi-road::before {
  content: "\f0461";
}

.mdi-road-variant::before {
  content: "\f0462";
}

.mdi-robber::before {
  content: "\f1058";
}

.mdi-robot::before {
  content: "\f06a9";
}

.mdi-robot-angry::before {
  content: "\f169d";
}

.mdi-robot-angry-outline::before {
  content: "\f169e";
}

.mdi-robot-confused::before {
  content: "\f169f";
}

.mdi-robot-confused-outline::before {
  content: "\f16a0";
}

.mdi-robot-dead::before {
  content: "\f16a1";
}

.mdi-robot-dead-outline::before {
  content: "\f16a2";
}

.mdi-robot-excited::before {
  content: "\f16a3";
}

.mdi-robot-excited-outline::before {
  content: "\f16a4";
}

.mdi-robot-happy::before {
  content: "\f1719";
}

.mdi-robot-happy-outline::before {
  content: "\f171a";
}

.mdi-robot-industrial::before {
  content: "\f0b46";
}

.mdi-robot-love::before {
  content: "\f16a5";
}

.mdi-robot-love-outline::before {
  content: "\f16a6";
}

.mdi-robot-mower::before {
  content: "\f11f7";
}

.mdi-robot-mower-outline::before {
  content: "\f11f3";
}

.mdi-robot-off::before {
  content: "\f16a7";
}

.mdi-robot-off-outline::before {
  content: "\f167b";
}

.mdi-robot-outline::before {
  content: "\f167a";
}

.mdi-robot-vacuum::before {
  content: "\f070d";
}

.mdi-robot-vacuum-variant::before {
  content: "\f0908";
}

.mdi-rocket::before {
  content: "\f0463";
}

.mdi-rocket-launch::before {
  content: "\f14de";
}

.mdi-rocket-launch-outline::before {
  content: "\f14df";
}

.mdi-rocket-outline::before {
  content: "\f13af";
}

.mdi-rodent::before {
  content: "\f1327";
}

.mdi-roller-skate::before {
  content: "\f0d2b";
}

.mdi-roller-skate-off::before {
  content: "\f0145";
}

.mdi-rollerblade::before {
  content: "\f0d2c";
}

.mdi-rollerblade-off::before {
  content: "\f002e";
}

.mdi-rollupjs::before {
  content: "\f0bc0";
}

.mdi-roman-numeral-1::before {
  content: "\f1088";
}

.mdi-roman-numeral-10::before {
  content: "\f1091";
}

.mdi-roman-numeral-2::before {
  content: "\f1089";
}

.mdi-roman-numeral-3::before {
  content: "\f108a";
}

.mdi-roman-numeral-4::before {
  content: "\f108b";
}

.mdi-roman-numeral-5::before {
  content: "\f108c";
}

.mdi-roman-numeral-6::before {
  content: "\f108d";
}

.mdi-roman-numeral-7::before {
  content: "\f108e";
}

.mdi-roman-numeral-8::before {
  content: "\f108f";
}

.mdi-roman-numeral-9::before {
  content: "\f1090";
}

.mdi-room-service::before {
  content: "\f088d";
}

.mdi-room-service-outline::before {
  content: "\f0d97";
}

.mdi-rotate-360::before {
  content: "\f1999";
}

.mdi-rotate-3d::before {
  content: "\f0ec7";
}

.mdi-rotate-3d-variant::before {
  content: "\f0464";
}

.mdi-rotate-left::before {
  content: "\f0465";
}

.mdi-rotate-left-variant::before {
  content: "\f0466";
}

.mdi-rotate-orbit::before {
  content: "\f0d98";
}

.mdi-rotate-right::before {
  content: "\f0467";
}

.mdi-rotate-right-variant::before {
  content: "\f0468";
}

.mdi-rounded-corner::before {
  content: "\f0607";
}

.mdi-router::before {
  content: "\f11e2";
}

.mdi-router-network::before {
  content: "\f1087";
}

.mdi-router-wireless::before {
  content: "\f0469";
}

.mdi-router-wireless-off::before {
  content: "\f15a3";
}

.mdi-router-wireless-settings::before {
  content: "\f0a69";
}

.mdi-routes::before {
  content: "\f046a";
}

.mdi-routes-clock::before {
  content: "\f1059";
}

.mdi-rowing::before {
  content: "\f0608";
}

.mdi-rss::before {
  content: "\f046b";
}

.mdi-rss-box::before {
  content: "\f046c";
}

.mdi-rss-off::before {
  content: "\f0f21";
}

.mdi-rug::before {
  content: "\f1475";
}

.mdi-rugby::before {
  content: "\f0d99";
}

.mdi-ruler::before {
  content: "\f046d";
}

.mdi-ruler-square::before {
  content: "\f0cc2";
}

.mdi-ruler-square-compass::before {
  content: "\f0ebe";
}

.mdi-run::before {
  content: "\f070e";
}

.mdi-run-fast::before {
  content: "\f046e";
}

.mdi-rv-truck::before {
  content: "\f11d4";
}

.mdi-sack::before {
  content: "\f0d2e";
}

.mdi-sack-percent::before {
  content: "\f0d2f";
}

.mdi-safe::before {
  content: "\f0a6a";
}

.mdi-safe-square::before {
  content: "\f127c";
}

.mdi-safe-square-outline::before {
  content: "\f127d";
}

.mdi-safety-goggles::before {
  content: "\f0d30";
}

.mdi-sail-boat::before {
  content: "\f0ec8";
}

.mdi-sale::before {
  content: "\f046f";
}

.mdi-salesforce::before {
  content: "\f088e";
}

.mdi-sass::before {
  content: "\f07ec";
}

.mdi-satellite::before {
  content: "\f0470";
}

.mdi-satellite-uplink::before {
  content: "\f0909";
}

.mdi-satellite-variant::before {
  content: "\f0471";
}

.mdi-sausage::before {
  content: "\f08ba";
}

.mdi-sausage-off::before {
  content: "\f1789";
}

.mdi-saw-blade::before {
  content: "\f0e61";
}

.mdi-sawtooth-wave::before {
  content: "\f147a";
}

.mdi-saxophone::before {
  content: "\f0609";
}

.mdi-scale::before {
  content: "\f0472";
}

.mdi-scale-balance::before {
  content: "\f05d1";
}

.mdi-scale-bathroom::before {
  content: "\f0473";
}

.mdi-scale-off::before {
  content: "\f105a";
}

.mdi-scale-unbalanced::before {
  content: "\f19b8";
}

.mdi-scan-helper::before {
  content: "\f13d8";
}

.mdi-scanner::before {
  content: "\f06ab";
}

.mdi-scanner-off::before {
  content: "\f090a";
}

.mdi-scatter-plot::before {
  content: "\f0ec9";
}

.mdi-scatter-plot-outline::before {
  content: "\f0eca";
}

.mdi-scent::before {
  content: "\f1958";
}

.mdi-scent-off::before {
  content: "\f1959";
}

.mdi-school::before {
  content: "\f0474";
}

.mdi-school-outline::before {
  content: "\f1180";
}

.mdi-scissors-cutting::before {
  content: "\f0a6b";
}

.mdi-scooter::before {
  content: "\f15bd";
}

.mdi-scooter-electric::before {
  content: "\f15be";
}

.mdi-scoreboard::before {
  content: "\f127e";
}

.mdi-scoreboard-outline::before {
  content: "\f127f";
}

.mdi-screen-rotation::before {
  content: "\f0475";
}

.mdi-screen-rotation-lock::before {
  content: "\f0478";
}

.mdi-screw-flat-top::before {
  content: "\f0df3";
}

.mdi-screw-lag::before {
  content: "\f0df4";
}

.mdi-screw-machine-flat-top::before {
  content: "\f0df5";
}

.mdi-screw-machine-round-top::before {
  content: "\f0df6";
}

.mdi-screw-round-top::before {
  content: "\f0df7";
}

.mdi-screwdriver::before {
  content: "\f0476";
}

.mdi-script::before {
  content: "\f0bc1";
}

.mdi-script-outline::before {
  content: "\f0477";
}

.mdi-script-text::before {
  content: "\f0bc2";
}

.mdi-script-text-key::before {
  content: "\f1725";
}

.mdi-script-text-key-outline::before {
  content: "\f1726";
}

.mdi-script-text-outline::before {
  content: "\f0bc3";
}

.mdi-script-text-play::before {
  content: "\f1727";
}

.mdi-script-text-play-outline::before {
  content: "\f1728";
}

.mdi-sd::before {
  content: "\f0479";
}

.mdi-seal::before {
  content: "\f047a";
}

.mdi-seal-variant::before {
  content: "\f0fd9";
}

.mdi-search-web::before {
  content: "\f070f";
}

.mdi-seat::before {
  content: "\f0cc3";
}

.mdi-seat-flat::before {
  content: "\f047b";
}

.mdi-seat-flat-angled::before {
  content: "\f047c";
}

.mdi-seat-individual-suite::before {
  content: "\f047d";
}

.mdi-seat-legroom-extra::before {
  content: "\f047e";
}

.mdi-seat-legroom-normal::before {
  content: "\f047f";
}

.mdi-seat-legroom-reduced::before {
  content: "\f0480";
}

.mdi-seat-outline::before {
  content: "\f0cc4";
}

.mdi-seat-passenger::before {
  content: "\f1249";
}

.mdi-seat-recline-extra::before {
  content: "\f0481";
}

.mdi-seat-recline-normal::before {
  content: "\f0482";
}

.mdi-seatbelt::before {
  content: "\f0cc5";
}

.mdi-security::before {
  content: "\f0483";
}

.mdi-security-network::before {
  content: "\f0484";
}

.mdi-seed::before {
  content: "\f0e62";
}

.mdi-seed-off::before {
  content: "\f13fd";
}

.mdi-seed-off-outline::before {
  content: "\f13fe";
}

.mdi-seed-outline::before {
  content: "\f0e63";
}

.mdi-seesaw::before {
  content: "\f15a4";
}

.mdi-segment::before {
  content: "\f0ecb";
}

.mdi-select::before {
  content: "\f0485";
}

.mdi-select-all::before {
  content: "\f0486";
}

.mdi-select-color::before {
  content: "\f0d31";
}

.mdi-select-compare::before {
  content: "\f0ad9";
}

.mdi-select-drag::before {
  content: "\f0a6c";
}

.mdi-select-group::before {
  content: "\f0f82";
}

.mdi-select-inverse::before {
  content: "\f0487";
}

.mdi-select-marker::before {
  content: "\f1280";
}

.mdi-select-multiple::before {
  content: "\f1281";
}

.mdi-select-multiple-marker::before {
  content: "\f1282";
}

.mdi-select-off::before {
  content: "\f0488";
}

.mdi-select-place::before {
  content: "\f0fda";
}

.mdi-select-remove::before {
  content: "\f17c1";
}

.mdi-select-search::before {
  content: "\f1204";
}

.mdi-selection::before {
  content: "\f0489";
}

.mdi-selection-drag::before {
  content: "\f0a6d";
}

.mdi-selection-ellipse::before {
  content: "\f0d32";
}

.mdi-selection-ellipse-arrow-inside::before {
  content: "\f0f22";
}

.mdi-selection-ellipse-remove::before {
  content: "\f17c2";
}

.mdi-selection-marker::before {
  content: "\f1283";
}

.mdi-selection-multiple::before {
  content: "\f1285";
}

.mdi-selection-multiple-marker::before {
  content: "\f1284";
}

.mdi-selection-off::before {
  content: "\f0777";
}

.mdi-selection-remove::before {
  content: "\f17c3";
}

.mdi-selection-search::before {
  content: "\f1205";
}

.mdi-semantic-web::before {
  content: "\f1316";
}

.mdi-send::before {
  content: "\f048a";
}

.mdi-send-check::before {
  content: "\f1161";
}

.mdi-send-check-outline::before {
  content: "\f1162";
}

.mdi-send-circle::before {
  content: "\f0df8";
}

.mdi-send-circle-outline::before {
  content: "\f0df9";
}

.mdi-send-clock::before {
  content: "\f1163";
}

.mdi-send-clock-outline::before {
  content: "\f1164";
}

.mdi-send-lock::before {
  content: "\f07ed";
}

.mdi-send-lock-outline::before {
  content: "\f1166";
}

.mdi-send-outline::before {
  content: "\f1165";
}

.mdi-serial-port::before {
  content: "\f065c";
}

.mdi-server::before {
  content: "\f048b";
}

.mdi-server-minus::before {
  content: "\f048c";
}

.mdi-server-network::before {
  content: "\f048d";
}

.mdi-server-network-off::before {
  content: "\f048e";
}

.mdi-server-off::before {
  content: "\f048f";
}

.mdi-server-plus::before {
  content: "\f0490";
}

.mdi-server-remove::before {
  content: "\f0491";
}

.mdi-server-security::before {
  content: "\f0492";
}

.mdi-set-all::before {
  content: "\f0778";
}

.mdi-set-center::before {
  content: "\f0779";
}

.mdi-set-center-right::before {
  content: "\f077a";
}

.mdi-set-left::before {
  content: "\f077b";
}

.mdi-set-left-center::before {
  content: "\f077c";
}

.mdi-set-left-right::before {
  content: "\f077d";
}

.mdi-set-merge::before {
  content: "\f14e0";
}

.mdi-set-none::before {
  content: "\f077e";
}

.mdi-set-right::before {
  content: "\f077f";
}

.mdi-set-split::before {
  content: "\f14e1";
}

.mdi-set-square::before {
  content: "\f145d";
}

.mdi-set-top-box::before {
  content: "\f099f";
}

.mdi-settings-helper::before {
  content: "\f0a6e";
}

.mdi-shaker::before {
  content: "\f110e";
}

.mdi-shaker-outline::before {
  content: "\f110f";
}

.mdi-shape::before {
  content: "\f0831";
}

.mdi-shape-circle-plus::before {
  content: "\f065d";
}

.mdi-shape-outline::before {
  content: "\f0832";
}

.mdi-shape-oval-plus::before {
  content: "\f11fa";
}

.mdi-shape-plus::before {
  content: "\f0495";
}

.mdi-shape-polygon-plus::before {
  content: "\f065e";
}

.mdi-shape-rectangle-plus::before {
  content: "\f065f";
}

.mdi-shape-square-plus::before {
  content: "\f0660";
}

.mdi-shape-square-rounded-plus::before {
  content: "\f14fa";
}

.mdi-share::before {
  content: "\f0496";
}

.mdi-share-all::before {
  content: "\f11f4";
}

.mdi-share-all-outline::before {
  content: "\f11f5";
}

.mdi-share-circle::before {
  content: "\f11ad";
}

.mdi-share-off::before {
  content: "\f0f23";
}

.mdi-share-off-outline::before {
  content: "\f0f24";
}

.mdi-share-outline::before {
  content: "\f0932";
}

.mdi-share-variant::before {
  content: "\f0497";
}

.mdi-share-variant-outline::before {
  content: "\f1514";
}

.mdi-shark::before {
  content: "\f18ba";
}

.mdi-shark-fin::before {
  content: "\f1673";
}

.mdi-shark-fin-outline::before {
  content: "\f1674";
}

.mdi-shark-off::before {
  content: "\f18bb";
}

.mdi-sheep::before {
  content: "\f0cc6";
}

.mdi-shield::before {
  content: "\f0498";
}

.mdi-shield-account::before {
  content: "\f088f";
}

.mdi-shield-account-outline::before {
  content: "\f0a12";
}

.mdi-shield-account-variant::before {
  content: "\f15a7";
}

.mdi-shield-account-variant-outline::before {
  content: "\f15a8";
}

.mdi-shield-airplane::before {
  content: "\f06bb";
}

.mdi-shield-airplane-outline::before {
  content: "\f0cc7";
}

.mdi-shield-alert::before {
  content: "\f0ecc";
}

.mdi-shield-alert-outline::before {
  content: "\f0ecd";
}

.mdi-shield-bug::before {
  content: "\f13da";
}

.mdi-shield-bug-outline::before {
  content: "\f13db";
}

.mdi-shield-car::before {
  content: "\f0f83";
}

.mdi-shield-check::before {
  content: "\f0565";
}

.mdi-shield-check-outline::before {
  content: "\f0cc8";
}

.mdi-shield-cross::before {
  content: "\f0cc9";
}

.mdi-shield-cross-outline::before {
  content: "\f0cca";
}

.mdi-shield-crown::before {
  content: "\f18bc";
}

.mdi-shield-crown-outline::before {
  content: "\f18bd";
}

.mdi-shield-edit::before {
  content: "\f11a0";
}

.mdi-shield-edit-outline::before {
  content: "\f11a1";
}

.mdi-shield-half::before {
  content: "\f1360";
}

.mdi-shield-half-full::before {
  content: "\f0780";
}

.mdi-shield-home::before {
  content: "\f068a";
}

.mdi-shield-home-outline::before {
  content: "\f0ccb";
}

.mdi-shield-key::before {
  content: "\f0bc4";
}

.mdi-shield-key-outline::before {
  content: "\f0bc5";
}

.mdi-shield-link-variant::before {
  content: "\f0d33";
}

.mdi-shield-link-variant-outline::before {
  content: "\f0d34";
}

.mdi-shield-lock::before {
  content: "\f099d";
}

.mdi-shield-lock-open::before {
  content: "\f199a";
}

.mdi-shield-lock-open-outline::before {
  content: "\f199b";
}

.mdi-shield-lock-outline::before {
  content: "\f0ccc";
}

.mdi-shield-moon::before {
  content: "\f1828";
}

.mdi-shield-moon-outline::before {
  content: "\f1829";
}

.mdi-shield-off::before {
  content: "\f099e";
}

.mdi-shield-off-outline::before {
  content: "\f099c";
}

.mdi-shield-outline::before {
  content: "\f0499";
}

.mdi-shield-plus::before {
  content: "\f0ada";
}

.mdi-shield-plus-outline::before {
  content: "\f0adb";
}

.mdi-shield-refresh::before {
  content: "\f00aa";
}

.mdi-shield-refresh-outline::before {
  content: "\f01e0";
}

.mdi-shield-remove::before {
  content: "\f0adc";
}

.mdi-shield-remove-outline::before {
  content: "\f0add";
}

.mdi-shield-search::before {
  content: "\f0d9a";
}

.mdi-shield-star::before {
  content: "\f113b";
}

.mdi-shield-star-outline::before {
  content: "\f113c";
}

.mdi-shield-sun::before {
  content: "\f105d";
}

.mdi-shield-sun-outline::before {
  content: "\f105e";
}

.mdi-shield-sword::before {
  content: "\f18be";
}

.mdi-shield-sword-outline::before {
  content: "\f18bf";
}

.mdi-shield-sync::before {
  content: "\f11a2";
}

.mdi-shield-sync-outline::before {
  content: "\f11a3";
}

.mdi-shimmer::before {
  content: "\f1545";
}

.mdi-ship-wheel::before {
  content: "\f0833";
}

.mdi-shipping-pallet::before {
  content: "\f184e";
}

.mdi-shoe-ballet::before {
  content: "\f15ca";
}

.mdi-shoe-cleat::before {
  content: "\f15c7";
}

.mdi-shoe-formal::before {
  content: "\f0b47";
}

.mdi-shoe-heel::before {
  content: "\f0b48";
}

.mdi-shoe-print::before {
  content: "\f0dfa";
}

.mdi-shoe-sneaker::before {
  content: "\f15c8";
}

.mdi-shopping::before {
  content: "\f049a";
}

.mdi-shopping-music::before {
  content: "\f049b";
}

.mdi-shopping-outline::before {
  content: "\f11d5";
}

.mdi-shopping-search::before {
  content: "\f0f84";
}

.mdi-shore::before {
  content: "\f14f9";
}

.mdi-shovel::before {
  content: "\f0710";
}

.mdi-shovel-off::before {
  content: "\f0711";
}

.mdi-shower::before {
  content: "\f09a0";
}

.mdi-shower-head::before {
  content: "\f09a1";
}

.mdi-shredder::before {
  content: "\f049c";
}

.mdi-shuffle::before {
  content: "\f049d";
}

.mdi-shuffle-disabled::before {
  content: "\f049e";
}

.mdi-shuffle-variant::before {
  content: "\f049f";
}

.mdi-shuriken::before {
  content: "\f137f";
}

.mdi-sickle::before {
  content: "\f18c0";
}

.mdi-sigma::before {
  content: "\f04a0";
}

.mdi-sigma-lower::before {
  content: "\f062b";
}

.mdi-sign-caution::before {
  content: "\f04a1";
}

.mdi-sign-direction::before {
  content: "\f0781";
}

.mdi-sign-direction-minus::before {
  content: "\f1000";
}

.mdi-sign-direction-plus::before {
  content: "\f0fdc";
}

.mdi-sign-direction-remove::before {
  content: "\f0fdd";
}

.mdi-sign-pole::before {
  content: "\f14f8";
}

.mdi-sign-real-estate::before {
  content: "\f1118";
}

.mdi-sign-text::before {
  content: "\f0782";
}

.mdi-signal::before {
  content: "\f04a2";
}

.mdi-signal-2g::before {
  content: "\f0712";
}

.mdi-signal-3g::before {
  content: "\f0713";
}

.mdi-signal-4g::before {
  content: "\f0714";
}

.mdi-signal-5g::before {
  content: "\f0a6f";
}

.mdi-signal-cellular-1::before {
  content: "\f08bc";
}

.mdi-signal-cellular-2::before {
  content: "\f08bd";
}

.mdi-signal-cellular-3::before {
  content: "\f08be";
}

.mdi-signal-cellular-outline::before {
  content: "\f08bf";
}

.mdi-signal-distance-variant::before {
  content: "\f0e64";
}

.mdi-signal-hspa::before {
  content: "\f0715";
}

.mdi-signal-hspa-plus::before {
  content: "\f0716";
}

.mdi-signal-off::before {
  content: "\f0783";
}

.mdi-signal-variant::before {
  content: "\f060a";
}

.mdi-signature::before {
  content: "\f0dfb";
}

.mdi-signature-freehand::before {
  content: "\f0dfc";
}

.mdi-signature-image::before {
  content: "\f0dfd";
}

.mdi-signature-text::before {
  content: "\f0dfe";
}

.mdi-silo::before {
  content: "\f0b49";
}

.mdi-silverware::before {
  content: "\f04a3";
}

.mdi-silverware-clean::before {
  content: "\f0fde";
}

.mdi-silverware-fork::before {
  content: "\f04a4";
}

.mdi-silverware-fork-knife::before {
  content: "\f0a70";
}

.mdi-silverware-spoon::before {
  content: "\f04a5";
}

.mdi-silverware-variant::before {
  content: "\f04a6";
}

.mdi-sim::before {
  content: "\f04a7";
}

.mdi-sim-alert::before {
  content: "\f04a8";
}

.mdi-sim-alert-outline::before {
  content: "\f15d3";
}

.mdi-sim-off::before {
  content: "\f04a9";
}

.mdi-sim-off-outline::before {
  content: "\f15d4";
}

.mdi-sim-outline::before {
  content: "\f15d5";
}

.mdi-simple-icons::before {
  content: "\f131d";
}

.mdi-sina-weibo::before {
  content: "\f0adf";
}

.mdi-sine-wave::before {
  content: "\f095b";
}

.mdi-sitemap::before {
  content: "\f04aa";
}

.mdi-sitemap-outline::before {
  content: "\f199c";
}

.mdi-size-l::before {
  content: "\f13a6";
}

.mdi-size-m::before {
  content: "\f13a5";
}

.mdi-size-s::before {
  content: "\f13a4";
}

.mdi-size-xl::before {
  content: "\f13a7";
}

.mdi-size-xs::before {
  content: "\f13a3";
}

.mdi-size-xxl::before {
  content: "\f13a8";
}

.mdi-size-xxs::before {
  content: "\f13a2";
}

.mdi-size-xxxl::before {
  content: "\f13a9";
}

.mdi-skate::before {
  content: "\f0d35";
}

.mdi-skate-off::before {
  content: "\f0699";
}

.mdi-skateboard::before {
  content: "\f14c2";
}

.mdi-skateboarding::before {
  content: "\f0501";
}

.mdi-skew-less::before {
  content: "\f0d36";
}

.mdi-skew-more::before {
  content: "\f0d37";
}

.mdi-ski::before {
  content: "\f1304";
}

.mdi-ski-cross-country::before {
  content: "\f1305";
}

.mdi-ski-water::before {
  content: "\f1306";
}

.mdi-skip-backward::before {
  content: "\f04ab";
}

.mdi-skip-backward-outline::before {
  content: "\f0f25";
}

.mdi-skip-forward::before {
  content: "\f04ac";
}

.mdi-skip-forward-outline::before {
  content: "\f0f26";
}

.mdi-skip-next::before {
  content: "\f04ad";
}

.mdi-skip-next-circle::before {
  content: "\f0661";
}

.mdi-skip-next-circle-outline::before {
  content: "\f0662";
}

.mdi-skip-next-outline::before {
  content: "\f0f27";
}

.mdi-skip-previous::before {
  content: "\f04ae";
}

.mdi-skip-previous-circle::before {
  content: "\f0663";
}

.mdi-skip-previous-circle-outline::before {
  content: "\f0664";
}

.mdi-skip-previous-outline::before {
  content: "\f0f28";
}

.mdi-skull::before {
  content: "\f068c";
}

.mdi-skull-crossbones::before {
  content: "\f0bc6";
}

.mdi-skull-crossbones-outline::before {
  content: "\f0bc7";
}

.mdi-skull-outline::before {
  content: "\f0bc8";
}

.mdi-skull-scan::before {
  content: "\f14c7";
}

.mdi-skull-scan-outline::before {
  content: "\f14c8";
}

.mdi-skype::before {
  content: "\f04af";
}

.mdi-skype-business::before {
  content: "\f04b0";
}

.mdi-slack::before {
  content: "\f04b1";
}

.mdi-slash-forward::before {
  content: "\f0fdf";
}

.mdi-slash-forward-box::before {
  content: "\f0fe0";
}

.mdi-sledding::before {
  content: "\f041b";
}

.mdi-sleep::before {
  content: "\f04b2";
}

.mdi-sleep-off::before {
  content: "\f04b3";
}

.mdi-slide::before {
  content: "\f15a5";
}

.mdi-slope-downhill::before {
  content: "\f0dff";
}

.mdi-slope-uphill::before {
  content: "\f0e00";
}

.mdi-slot-machine::before {
  content: "\f1114";
}

.mdi-slot-machine-outline::before {
  content: "\f1115";
}

.mdi-smart-card::before {
  content: "\f10bd";
}

.mdi-smart-card-off::before {
  content: "\f18f7";
}

.mdi-smart-card-off-outline::before {
  content: "\f18f8";
}

.mdi-smart-card-outline::before {
  content: "\f10be";
}

.mdi-smart-card-reader::before {
  content: "\f10bf";
}

.mdi-smart-card-reader-outline::before {
  content: "\f10c0";
}

.mdi-smog::before {
  content: "\f0a71";
}

.mdi-smoke::before {
  content: "\f1799";
}

.mdi-smoke-detector::before {
  content: "\f0392";
}

.mdi-smoke-detector-alert::before {
  content: "\f192e";
}

.mdi-smoke-detector-alert-outline::before {
  content: "\f192f";
}

.mdi-smoke-detector-off::before {
  content: "\f1809";
}

.mdi-smoke-detector-off-outline::before {
  content: "\f180a";
}

.mdi-smoke-detector-outline::before {
  content: "\f1808";
}

.mdi-smoke-detector-variant::before {
  content: "\f180b";
}

.mdi-smoke-detector-variant-alert::before {
  content: "\f1930";
}

.mdi-smoke-detector-variant-off::before {
  content: "\f180c";
}

.mdi-smoking::before {
  content: "\f04b4";
}

.mdi-smoking-off::before {
  content: "\f04b5";
}

.mdi-smoking-pipe::before {
  content: "\f140d";
}

.mdi-smoking-pipe-off::before {
  content: "\f1428";
}

.mdi-snail::before {
  content: "\f1677";
}

.mdi-snake::before {
  content: "\f150e";
}

.mdi-snapchat::before {
  content: "\f04b6";
}

.mdi-snowboard::before {
  content: "\f1307";
}

.mdi-snowflake::before {
  content: "\f0717";
}

.mdi-snowflake-alert::before {
  content: "\f0f29";
}

.mdi-snowflake-melt::before {
  content: "\f12cb";
}

.mdi-snowflake-off::before {
  content: "\f14e3";
}

.mdi-snowflake-variant::before {
  content: "\f0f2a";
}

.mdi-snowman::before {
  content: "\f04b7";
}

.mdi-snowmobile::before {
  content: "\f06dd";
}

.mdi-soccer::before {
  content: "\f04b8";
}

.mdi-soccer-field::before {
  content: "\f0834";
}

.mdi-social-distance-2-meters::before {
  content: "\f1579";
}

.mdi-social-distance-6-feet::before {
  content: "\f157a";
}

.mdi-sofa::before {
  content: "\f04b9";
}

.mdi-sofa-outline::before {
  content: "\f156d";
}

.mdi-sofa-single::before {
  content: "\f156e";
}

.mdi-sofa-single-outline::before {
  content: "\f156f";
}

.mdi-solar-panel::before {
  content: "\f0d9b";
}

.mdi-solar-panel-large::before {
  content: "\f0d9c";
}

.mdi-solar-power::before {
  content: "\f0a72";
}

.mdi-soldering-iron::before {
  content: "\f1092";
}

.mdi-solid::before {
  content: "\f068d";
}

.mdi-sony-playstation::before {
  content: "\f0414";
}

.mdi-sort::before {
  content: "\f04ba";
}

.mdi-sort-alphabetical-ascending::before {
  content: "\f05bd";
}

.mdi-sort-alphabetical-ascending-variant::before {
  content: "\f1148";
}

.mdi-sort-alphabetical-descending::before {
  content: "\f05bf";
}

.mdi-sort-alphabetical-descending-variant::before {
  content: "\f1149";
}

.mdi-sort-alphabetical-variant::before {
  content: "\f04bb";
}

.mdi-sort-ascending::before {
  content: "\f04bc";
}

.mdi-sort-bool-ascending::before {
  content: "\f1385";
}

.mdi-sort-bool-ascending-variant::before {
  content: "\f1386";
}

.mdi-sort-bool-descending::before {
  content: "\f1387";
}

.mdi-sort-bool-descending-variant::before {
  content: "\f1388";
}

.mdi-sort-calendar-ascending::before {
  content: "\f1547";
}

.mdi-sort-calendar-descending::before {
  content: "\f1548";
}

.mdi-sort-clock-ascending::before {
  content: "\f1549";
}

.mdi-sort-clock-ascending-outline::before {
  content: "\f154a";
}

.mdi-sort-clock-descending::before {
  content: "\f154b";
}

.mdi-sort-clock-descending-outline::before {
  content: "\f154c";
}

.mdi-sort-descending::before {
  content: "\f04bd";
}

.mdi-sort-numeric-ascending::before {
  content: "\f1389";
}

.mdi-sort-numeric-ascending-variant::before {
  content: "\f090d";
}

.mdi-sort-numeric-descending::before {
  content: "\f138a";
}

.mdi-sort-numeric-descending-variant::before {
  content: "\f0ad2";
}

.mdi-sort-numeric-variant::before {
  content: "\f04be";
}

.mdi-sort-reverse-variant::before {
  content: "\f033c";
}

.mdi-sort-variant::before {
  content: "\f04bf";
}

.mdi-sort-variant-lock::before {
  content: "\f0ccd";
}

.mdi-sort-variant-lock-open::before {
  content: "\f0cce";
}

.mdi-sort-variant-remove::before {
  content: "\f1147";
}

.mdi-soundbar::before {
  content: "\f17db";
}

.mdi-soundcloud::before {
  content: "\f04c0";
}

.mdi-source-branch::before {
  content: "\f062c";
}

.mdi-source-branch-check::before {
  content: "\f14cf";
}

.mdi-source-branch-minus::before {
  content: "\f14cb";
}

.mdi-source-branch-plus::before {
  content: "\f14ca";
}

.mdi-source-branch-refresh::before {
  content: "\f14cd";
}

.mdi-source-branch-remove::before {
  content: "\f14cc";
}

.mdi-source-branch-sync::before {
  content: "\f14ce";
}

.mdi-source-commit::before {
  content: "\f0718";
}

.mdi-source-commit-end::before {
  content: "\f0719";
}

.mdi-source-commit-end-local::before {
  content: "\f071a";
}

.mdi-source-commit-local::before {
  content: "\f071b";
}

.mdi-source-commit-next-local::before {
  content: "\f071c";
}

.mdi-source-commit-start::before {
  content: "\f071d";
}

.mdi-source-commit-start-next-local::before {
  content: "\f071e";
}

.mdi-source-fork::before {
  content: "\f04c1";
}

.mdi-source-merge::before {
  content: "\f062d";
}

.mdi-source-pull::before {
  content: "\f04c2";
}

.mdi-source-repository::before {
  content: "\f0ccf";
}

.mdi-source-repository-multiple::before {
  content: "\f0cd0";
}

.mdi-soy-sauce::before {
  content: "\f07ee";
}

.mdi-soy-sauce-off::before {
  content: "\f13fc";
}

.mdi-spa::before {
  content: "\f0cd1";
}

.mdi-spa-outline::before {
  content: "\f0cd2";
}

.mdi-space-invaders::before {
  content: "\f0bc9";
}

.mdi-space-station::before {
  content: "\f1383";
}

.mdi-spade::before {
  content: "\f0e65";
}

.mdi-speaker::before {
  content: "\f04c3";
}

.mdi-speaker-bluetooth::before {
  content: "\f09a2";
}

.mdi-speaker-multiple::before {
  content: "\f0d38";
}

.mdi-speaker-off::before {
  content: "\f04c4";
}

.mdi-speaker-wireless::before {
  content: "\f071f";
}

.mdi-spear::before {
  content: "\f1845";
}

.mdi-speedometer::before {
  content: "\f04c5";
}

.mdi-speedometer-medium::before {
  content: "\f0f85";
}

.mdi-speedometer-slow::before {
  content: "\f0f86";
}

.mdi-spellcheck::before {
  content: "\f04c6";
}

.mdi-sphere::before {
  content: "\f1954";
}

.mdi-sphere-off::before {
  content: "\f1955";
}

.mdi-spider::before {
  content: "\f11ea";
}

.mdi-spider-thread::before {
  content: "\f11eb";
}

.mdi-spider-web::before {
  content: "\f0bca";
}

.mdi-spirit-level::before {
  content: "\f14f1";
}

.mdi-spoon-sugar::before {
  content: "\f1429";
}

.mdi-spotify::before {
  content: "\f04c7";
}

.mdi-spotlight::before {
  content: "\f04c8";
}

.mdi-spotlight-beam::before {
  content: "\f04c9";
}

.mdi-spray::before {
  content: "\f0665";
}

.mdi-spray-bottle::before {
  content: "\f0ae0";
}

.mdi-sprinkler::before {
  content: "\f105f";
}

.mdi-sprinkler-fire::before {
  content: "\f199d";
}

.mdi-sprinkler-variant::before {
  content: "\f1060";
}

.mdi-sprout::before {
  content: "\f0e66";
}

.mdi-sprout-outline::before {
  content: "\f0e67";
}

.mdi-square::before {
  content: "\f0764";
}

.mdi-square-circle::before {
  content: "\f1500";
}

.mdi-square-edit-outline::before {
  content: "\f090c";
}

.mdi-square-medium::before {
  content: "\f0a13";
}

.mdi-square-medium-outline::before {
  content: "\f0a14";
}

.mdi-square-off::before {
  content: "\f12ee";
}

.mdi-square-off-outline::before {
  content: "\f12ef";
}

.mdi-square-opacity::before {
  content: "\f1854";
}

.mdi-square-outline::before {
  content: "\f0763";
}

.mdi-square-root::before {
  content: "\f0784";
}

.mdi-square-root-box::before {
  content: "\f09a3";
}

.mdi-square-rounded::before {
  content: "\f14fb";
}

.mdi-square-rounded-outline::before {
  content: "\f14fc";
}

.mdi-square-small::before {
  content: "\f0a15";
}

.mdi-square-wave::before {
  content: "\f147b";
}

.mdi-squeegee::before {
  content: "\f0ae1";
}

.mdi-ssh::before {
  content: "\f08c0";
}

.mdi-stack-exchange::before {
  content: "\f060b";
}

.mdi-stack-overflow::before {
  content: "\f04cc";
}

.mdi-stackpath::before {
  content: "\f0359";
}

.mdi-stadium::before {
  content: "\f0ff9";
}

.mdi-stadium-variant::before {
  content: "\f0720";
}

.mdi-stairs::before {
  content: "\f04cd";
}

.mdi-stairs-box::before {
  content: "\f139e";
}

.mdi-stairs-down::before {
  content: "\f12be";
}

.mdi-stairs-up::before {
  content: "\f12bd";
}

.mdi-stamper::before {
  content: "\f0d39";
}

.mdi-standard-definition::before {
  content: "\f07ef";
}

.mdi-star::before {
  content: "\f04ce";
}

.mdi-star-box::before {
  content: "\f0a73";
}

.mdi-star-box-multiple::before {
  content: "\f1286";
}

.mdi-star-box-multiple-outline::before {
  content: "\f1287";
}

.mdi-star-box-outline::before {
  content: "\f0a74";
}

.mdi-star-check::before {
  content: "\f1566";
}

.mdi-star-check-outline::before {
  content: "\f156a";
}

.mdi-star-circle::before {
  content: "\f04cf";
}

.mdi-star-circle-outline::before {
  content: "\f09a4";
}

.mdi-star-cog::before {
  content: "\f1668";
}

.mdi-star-cog-outline::before {
  content: "\f1669";
}

.mdi-star-crescent::before {
  content: "\f0979";
}

.mdi-star-david::before {
  content: "\f097a";
}

.mdi-star-face::before {
  content: "\f09a5";
}

.mdi-star-four-points::before {
  content: "\f0ae2";
}

.mdi-star-four-points-outline::before {
  content: "\f0ae3";
}

.mdi-star-half::before {
  content: "\f0246";
}

.mdi-star-half-full::before {
  content: "\f04d0";
}

.mdi-star-minus::before {
  content: "\f1564";
}

.mdi-star-minus-outline::before {
  content: "\f1568";
}

.mdi-star-off::before {
  content: "\f04d1";
}

.mdi-star-off-outline::before {
  content: "\f155b";
}

.mdi-star-outline::before {
  content: "\f04d2";
}

.mdi-star-plus::before {
  content: "\f1563";
}

.mdi-star-plus-outline::before {
  content: "\f1567";
}

.mdi-star-remove::before {
  content: "\f1565";
}

.mdi-star-remove-outline::before {
  content: "\f1569";
}

.mdi-star-settings::before {
  content: "\f166a";
}

.mdi-star-settings-outline::before {
  content: "\f166b";
}

.mdi-star-shooting::before {
  content: "\f1741";
}

.mdi-star-shooting-outline::before {
  content: "\f1742";
}

.mdi-star-three-points::before {
  content: "\f0ae4";
}

.mdi-star-three-points-outline::before {
  content: "\f0ae5";
}

.mdi-state-machine::before {
  content: "\f11ef";
}

.mdi-steam::before {
  content: "\f04d3";
}

.mdi-steering::before {
  content: "\f04d4";
}

.mdi-steering-off::before {
  content: "\f090e";
}

.mdi-step-backward::before {
  content: "\f04d5";
}

.mdi-step-backward-2::before {
  content: "\f04d6";
}

.mdi-step-forward::before {
  content: "\f04d7";
}

.mdi-step-forward-2::before {
  content: "\f04d8";
}

.mdi-stethoscope::before {
  content: "\f04d9";
}

.mdi-sticker::before {
  content: "\f1364";
}

.mdi-sticker-alert::before {
  content: "\f1365";
}

.mdi-sticker-alert-outline::before {
  content: "\f1366";
}

.mdi-sticker-check::before {
  content: "\f1367";
}

.mdi-sticker-check-outline::before {
  content: "\f1368";
}

.mdi-sticker-circle-outline::before {
  content: "\f05d0";
}

.mdi-sticker-emoji::before {
  content: "\f0785";
}

.mdi-sticker-minus::before {
  content: "\f1369";
}

.mdi-sticker-minus-outline::before {
  content: "\f136a";
}

.mdi-sticker-outline::before {
  content: "\f136b";
}

.mdi-sticker-plus::before {
  content: "\f136c";
}

.mdi-sticker-plus-outline::before {
  content: "\f136d";
}

.mdi-sticker-remove::before {
  content: "\f136e";
}

.mdi-sticker-remove-outline::before {
  content: "\f136f";
}

.mdi-sticker-text::before {
  content: "\f178e";
}

.mdi-sticker-text-outline::before {
  content: "\f178f";
}

.mdi-stocking::before {
  content: "\f04da";
}

.mdi-stomach::before {
  content: "\f1093";
}

.mdi-stool::before {
  content: "\f195d";
}

.mdi-stool-outline::before {
  content: "\f195e";
}

.mdi-stop::before {
  content: "\f04db";
}

.mdi-stop-circle::before {
  content: "\f0666";
}

.mdi-stop-circle-outline::before {
  content: "\f0667";
}

.mdi-store::before {
  content: "\f04dc";
}

.mdi-store-24-hour::before {
  content: "\f04dd";
}

.mdi-store-alert::before {
  content: "\f18c1";
}

.mdi-store-alert-outline::before {
  content: "\f18c2";
}

.mdi-store-check::before {
  content: "\f18c3";
}

.mdi-store-check-outline::before {
  content: "\f18c4";
}

.mdi-store-clock::before {
  content: "\f18c5";
}

.mdi-store-clock-outline::before {
  content: "\f18c6";
}

.mdi-store-cog::before {
  content: "\f18c7";
}

.mdi-store-cog-outline::before {
  content: "\f18c8";
}

.mdi-store-edit::before {
  content: "\f18c9";
}

.mdi-store-edit-outline::before {
  content: "\f18ca";
}

.mdi-store-marker::before {
  content: "\f18cb";
}

.mdi-store-marker-outline::before {
  content: "\f18cc";
}

.mdi-store-minus::before {
  content: "\f165e";
}

.mdi-store-minus-outline::before {
  content: "\f18cd";
}

.mdi-store-off::before {
  content: "\f18ce";
}

.mdi-store-off-outline::before {
  content: "\f18cf";
}

.mdi-store-outline::before {
  content: "\f1361";
}

.mdi-store-plus::before {
  content: "\f165f";
}

.mdi-store-plus-outline::before {
  content: "\f18d0";
}

.mdi-store-remove::before {
  content: "\f1660";
}

.mdi-store-remove-outline::before {
  content: "\f18d1";
}

.mdi-store-search::before {
  content: "\f18d2";
}

.mdi-store-search-outline::before {
  content: "\f18d3";
}

.mdi-store-settings::before {
  content: "\f18d4";
}

.mdi-store-settings-outline::before {
  content: "\f18d5";
}

.mdi-storefront::before {
  content: "\f07c7";
}

.mdi-storefront-outline::before {
  content: "\f10c1";
}

.mdi-stove::before {
  content: "\f04de";
}

.mdi-strategy::before {
  content: "\f11d6";
}

.mdi-stretch-to-page::before {
  content: "\f0f2b";
}

.mdi-stretch-to-page-outline::before {
  content: "\f0f2c";
}

.mdi-string-lights::before {
  content: "\f12ba";
}

.mdi-string-lights-off::before {
  content: "\f12bb";
}

.mdi-subdirectory-arrow-left::before {
  content: "\f060c";
}

.mdi-subdirectory-arrow-right::before {
  content: "\f060d";
}

.mdi-submarine::before {
  content: "\f156c";
}

.mdi-subtitles::before {
  content: "\f0a16";
}

.mdi-subtitles-outline::before {
  content: "\f0a17";
}

.mdi-subway::before {
  content: "\f06ac";
}

.mdi-subway-alert-variant::before {
  content: "\f0d9d";
}

.mdi-subway-variant::before {
  content: "\f04df";
}

.mdi-summit::before {
  content: "\f0786";
}

.mdi-sun-compass::before {
  content: "\f19a5";
}

.mdi-sun-snowflake::before {
  content: "\f1796";
}

.mdi-sun-thermometer::before {
  content: "\f18d6";
}

.mdi-sun-thermometer-outline::before {
  content: "\f18d7";
}

.mdi-sun-wireless::before {
  content: "\f17fe";
}

.mdi-sun-wireless-outline::before {
  content: "\f17ff";
}

.mdi-sunglasses::before {
  content: "\f04e0";
}

.mdi-surfing::before {
  content: "\f1746";
}

.mdi-surround-sound::before {
  content: "\f05c5";
}

.mdi-surround-sound-2-0::before {
  content: "\f07f0";
}

.mdi-surround-sound-2-1::before {
  content: "\f1729";
}

.mdi-surround-sound-3-1::before {
  content: "\f07f1";
}

.mdi-surround-sound-5-1::before {
  content: "\f07f2";
}

.mdi-surround-sound-5-1-2::before {
  content: "\f172a";
}

.mdi-surround-sound-7-1::before {
  content: "\f07f3";
}

.mdi-svg::before {
  content: "\f0721";
}

.mdi-swap-horizontal::before {
  content: "\f04e1";
}

.mdi-swap-horizontal-bold::before {
  content: "\f0bcd";
}

.mdi-swap-horizontal-circle::before {
  content: "\f0fe1";
}

.mdi-swap-horizontal-circle-outline::before {
  content: "\f0fe2";
}

.mdi-swap-horizontal-variant::before {
  content: "\f08c1";
}

.mdi-swap-vertical::before {
  content: "\f04e2";
}

.mdi-swap-vertical-bold::before {
  content: "\f0bce";
}

.mdi-swap-vertical-circle::before {
  content: "\f0fe3";
}

.mdi-swap-vertical-circle-outline::before {
  content: "\f0fe4";
}

.mdi-swap-vertical-variant::before {
  content: "\f08c2";
}

.mdi-swim::before {
  content: "\f04e3";
}

.mdi-switch::before {
  content: "\f04e4";
}

.mdi-sword::before {
  content: "\f04e5";
}

.mdi-sword-cross::before {
  content: "\f0787";
}

.mdi-syllabary-hangul::before {
  content: "\f1333";
}

.mdi-syllabary-hiragana::before {
  content: "\f1334";
}

.mdi-syllabary-katakana::before {
  content: "\f1335";
}

.mdi-syllabary-katakana-halfwidth::before {
  content: "\f1336";
}

.mdi-symbol::before {
  content: "\f1501";
}

.mdi-symfony::before {
  content: "\f0ae6";
}

.mdi-sync::before {
  content: "\f04e6";
}

.mdi-sync-alert::before {
  content: "\f04e7";
}

.mdi-sync-circle::before {
  content: "\f1378";
}

.mdi-sync-off::before {
  content: "\f04e8";
}

.mdi-tab::before {
  content: "\f04e9";
}

.mdi-tab-minus::before {
  content: "\f0b4b";
}

.mdi-tab-plus::before {
  content: "\f075c";
}

.mdi-tab-remove::before {
  content: "\f0b4c";
}

.mdi-tab-search::before {
  content: "\f199e";
}

.mdi-tab-unselected::before {
  content: "\f04ea";
}

.mdi-table::before {
  content: "\f04eb";
}

.mdi-table-account::before {
  content: "\f13b9";
}

.mdi-table-alert::before {
  content: "\f13ba";
}

.mdi-table-arrow-down::before {
  content: "\f13bb";
}

.mdi-table-arrow-left::before {
  content: "\f13bc";
}

.mdi-table-arrow-right::before {
  content: "\f13bd";
}

.mdi-table-arrow-up::before {
  content: "\f13be";
}

.mdi-table-border::before {
  content: "\f0a18";
}

.mdi-table-cancel::before {
  content: "\f13bf";
}

.mdi-table-chair::before {
  content: "\f1061";
}

.mdi-table-check::before {
  content: "\f13c0";
}

.mdi-table-clock::before {
  content: "\f13c1";
}

.mdi-table-cog::before {
  content: "\f13c2";
}

.mdi-table-column::before {
  content: "\f0835";
}

.mdi-table-column-plus-after::before {
  content: "\f04ec";
}

.mdi-table-column-plus-before::before {
  content: "\f04ed";
}

.mdi-table-column-remove::before {
  content: "\f04ee";
}

.mdi-table-column-width::before {
  content: "\f04ef";
}

.mdi-table-edit::before {
  content: "\f04f0";
}

.mdi-table-eye::before {
  content: "\f1094";
}

.mdi-table-eye-off::before {
  content: "\f13c3";
}

.mdi-table-furniture::before {
  content: "\f05bc";
}

.mdi-table-headers-eye::before {
  content: "\f121d";
}

.mdi-table-headers-eye-off::before {
  content: "\f121e";
}

.mdi-table-heart::before {
  content: "\f13c4";
}

.mdi-table-key::before {
  content: "\f13c5";
}

.mdi-table-large::before {
  content: "\f04f1";
}

.mdi-table-large-plus::before {
  content: "\f0f87";
}

.mdi-table-large-remove::before {
  content: "\f0f88";
}

.mdi-table-lock::before {
  content: "\f13c6";
}

.mdi-table-merge-cells::before {
  content: "\f09a6";
}

.mdi-table-minus::before {
  content: "\f13c7";
}

.mdi-table-multiple::before {
  content: "\f13c8";
}

.mdi-table-network::before {
  content: "\f13c9";
}

.mdi-table-of-contents::before {
  content: "\f0836";
}

.mdi-table-off::before {
  content: "\f13ca";
}

.mdi-table-picnic::before {
  content: "\f1743";
}

.mdi-table-pivot::before {
  content: "\f183c";
}

.mdi-table-plus::before {
  content: "\f0a75";
}

.mdi-table-refresh::before {
  content: "\f13a0";
}

.mdi-table-remove::before {
  content: "\f0a76";
}

.mdi-table-row::before {
  content: "\f0837";
}

.mdi-table-row-height::before {
  content: "\f04f2";
}

.mdi-table-row-plus-after::before {
  content: "\f04f3";
}

.mdi-table-row-plus-before::before {
  content: "\f04f4";
}

.mdi-table-row-remove::before {
  content: "\f04f5";
}

.mdi-table-search::before {
  content: "\f090f";
}

.mdi-table-settings::before {
  content: "\f0838";
}

.mdi-table-split-cell::before {
  content: "\f142a";
}

.mdi-table-star::before {
  content: "\f13cb";
}

.mdi-table-sync::before {
  content: "\f13a1";
}

.mdi-table-tennis::before {
  content: "\f0e68";
}

.mdi-tablet::before {
  content: "\f04f6";
}

.mdi-tablet-android::before {
  content: "\f04f7";
}

.mdi-tablet-cellphone::before {
  content: "\f09a7";
}

.mdi-tablet-dashboard::before {
  content: "\f0ece";
}

.mdi-taco::before {
  content: "\f0762";
}

.mdi-tag::before {
  content: "\f04f9";
}

.mdi-tag-arrow-down::before {
  content: "\f172b";
}

.mdi-tag-arrow-down-outline::before {
  content: "\f172c";
}

.mdi-tag-arrow-left::before {
  content: "\f172d";
}

.mdi-tag-arrow-left-outline::before {
  content: "\f172e";
}

.mdi-tag-arrow-right::before {
  content: "\f172f";
}

.mdi-tag-arrow-right-outline::before {
  content: "\f1730";
}

.mdi-tag-arrow-up::before {
  content: "\f1731";
}

.mdi-tag-arrow-up-outline::before {
  content: "\f1732";
}

.mdi-tag-faces::before {
  content: "\f04fa";
}

.mdi-tag-heart::before {
  content: "\f068b";
}

.mdi-tag-heart-outline::before {
  content: "\f0bcf";
}

.mdi-tag-minus::before {
  content: "\f0910";
}

.mdi-tag-minus-outline::before {
  content: "\f121f";
}

.mdi-tag-multiple::before {
  content: "\f04fb";
}

.mdi-tag-multiple-outline::before {
  content: "\f12f7";
}

.mdi-tag-off::before {
  content: "\f1220";
}

.mdi-tag-off-outline::before {
  content: "\f1221";
}

.mdi-tag-outline::before {
  content: "\f04fc";
}

.mdi-tag-plus::before {
  content: "\f0722";
}

.mdi-tag-plus-outline::before {
  content: "\f1222";
}

.mdi-tag-remove::before {
  content: "\f0723";
}

.mdi-tag-remove-outline::before {
  content: "\f1223";
}

.mdi-tag-search::before {
  content: "\f1907";
}

.mdi-tag-search-outline::before {
  content: "\f1908";
}

.mdi-tag-text::before {
  content: "\f1224";
}

.mdi-tag-text-outline::before {
  content: "\f04fd";
}

.mdi-tailwind::before {
  content: "\f13ff";
}

.mdi-tangram::before {
  content: "\f04f8";
}

.mdi-tank::before {
  content: "\f0d3a";
}

.mdi-tanker-truck::before {
  content: "\f0fe5";
}

.mdi-tape-drive::before {
  content: "\f16df";
}

.mdi-tape-measure::before {
  content: "\f0b4d";
}

.mdi-target::before {
  content: "\f04fe";
}

.mdi-target-account::before {
  content: "\f0bd0";
}

.mdi-target-variant::before {
  content: "\f0a77";
}

.mdi-taxi::before {
  content: "\f04ff";
}

.mdi-tea::before {
  content: "\f0d9e";
}

.mdi-tea-outline::before {
  content: "\f0d9f";
}

.mdi-teamviewer::before {
  content: "\f0500";
}

.mdi-teddy-bear::before {
  content: "\f18fb";
}

.mdi-telescope::before {
  content: "\f0b4e";
}

.mdi-television::before {
  content: "\f0502";
}

.mdi-television-ambient-light::before {
  content: "\f1356";
}

.mdi-television-box::before {
  content: "\f0839";
}

.mdi-television-classic::before {
  content: "\f07f4";
}

.mdi-television-classic-off::before {
  content: "\f083a";
}

.mdi-television-guide::before {
  content: "\f0503";
}

.mdi-television-off::before {
  content: "\f083b";
}

.mdi-television-pause::before {
  content: "\f0f89";
}

.mdi-television-play::before {
  content: "\f0ecf";
}

.mdi-television-shimmer::before {
  content: "\f1110";
}

.mdi-television-stop::before {
  content: "\f0f8a";
}

.mdi-temperature-celsius::before {
  content: "\f0504";
}

.mdi-temperature-fahrenheit::before {
  content: "\f0505";
}

.mdi-temperature-kelvin::before {
  content: "\f0506";
}

.mdi-tennis::before {
  content: "\f0da0";
}

.mdi-tennis-ball::before {
  content: "\f0507";
}

.mdi-tent::before {
  content: "\f0508";
}

.mdi-terraform::before {
  content: "\f1062";
}

.mdi-terrain::before {
  content: "\f0509";
}

.mdi-test-tube::before {
  content: "\f0668";
}

.mdi-test-tube-empty::before {
  content: "\f0911";
}

.mdi-test-tube-off::before {
  content: "\f0912";
}

.mdi-text::before {
  content: "\f09a8";
}

.mdi-text-account::before {
  content: "\f1570";
}

.mdi-text-box::before {
  content: "\f021a";
}

.mdi-text-box-check::before {
  content: "\f0ea6";
}

.mdi-text-box-check-outline::before {
  content: "\f0ea7";
}

.mdi-text-box-minus::before {
  content: "\f0ea8";
}

.mdi-text-box-minus-outline::before {
  content: "\f0ea9";
}

.mdi-text-box-multiple::before {
  content: "\f0ab7";
}

.mdi-text-box-multiple-outline::before {
  content: "\f0ab8";
}

.mdi-text-box-outline::before {
  content: "\f09ed";
}

.mdi-text-box-plus::before {
  content: "\f0eaa";
}

.mdi-text-box-plus-outline::before {
  content: "\f0eab";
}

.mdi-text-box-remove::before {
  content: "\f0eac";
}

.mdi-text-box-remove-outline::before {
  content: "\f0ead";
}

.mdi-text-box-search::before {
  content: "\f0eae";
}

.mdi-text-box-search-outline::before {
  content: "\f0eaf";
}

.mdi-text-long::before {
  content: "\f09aa";
}

.mdi-text-recognition::before {
  content: "\f113d";
}

.mdi-text-search::before {
  content: "\f13b8";
}

.mdi-text-shadow::before {
  content: "\f0669";
}

.mdi-text-short::before {
  content: "\f09a9";
}

.mdi-text-to-speech::before {
  content: "\f050a";
}

.mdi-text-to-speech-off::before {
  content: "\f050b";
}

.mdi-texture::before {
  content: "\f050c";
}

.mdi-texture-box::before {
  content: "\f0fe6";
}

.mdi-theater::before {
  content: "\f050d";
}

.mdi-theme-light-dark::before {
  content: "\f050e";
}

.mdi-thermometer::before {
  content: "\f050f";
}

.mdi-thermometer-alert::before {
  content: "\f0e01";
}

.mdi-thermometer-bluetooth::before {
  content: "\f1895";
}

.mdi-thermometer-chevron-down::before {
  content: "\f0e02";
}

.mdi-thermometer-chevron-up::before {
  content: "\f0e03";
}

.mdi-thermometer-high::before {
  content: "\f10c2";
}

.mdi-thermometer-lines::before {
  content: "\f0510";
}

.mdi-thermometer-low::before {
  content: "\f10c3";
}

.mdi-thermometer-minus::before {
  content: "\f0e04";
}

.mdi-thermometer-off::before {
  content: "\f1531";
}

.mdi-thermometer-plus::before {
  content: "\f0e05";
}

.mdi-thermostat::before {
  content: "\f0393";
}

.mdi-thermostat-box::before {
  content: "\f0891";
}

.mdi-thought-bubble::before {
  content: "\f07f6";
}

.mdi-thought-bubble-outline::before {
  content: "\f07f7";
}

.mdi-thumb-down::before {
  content: "\f0511";
}

.mdi-thumb-down-outline::before {
  content: "\f0512";
}

.mdi-thumb-up::before {
  content: "\f0513";
}

.mdi-thumb-up-outline::before {
  content: "\f0514";
}

.mdi-thumbs-up-down::before {
  content: "\f0515";
}

.mdi-thumbs-up-down-outline::before {
  content: "\f1914";
}

.mdi-ticket::before {
  content: "\f0516";
}

.mdi-ticket-account::before {
  content: "\f0517";
}

.mdi-ticket-confirmation::before {
  content: "\f0518";
}

.mdi-ticket-confirmation-outline::before {
  content: "\f13aa";
}

.mdi-ticket-outline::before {
  content: "\f0913";
}

.mdi-ticket-percent::before {
  content: "\f0724";
}

.mdi-ticket-percent-outline::before {
  content: "\f142b";
}

.mdi-tie::before {
  content: "\f0519";
}

.mdi-tilde::before {
  content: "\f0725";
}

.mdi-tilde-off::before {
  content: "\f18f3";
}

.mdi-timelapse::before {
  content: "\f051a";
}

.mdi-timeline::before {
  content: "\f0bd1";
}

.mdi-timeline-alert::before {
  content: "\f0f95";
}

.mdi-timeline-alert-outline::before {
  content: "\f0f98";
}

.mdi-timeline-check::before {
  content: "\f1532";
}

.mdi-timeline-check-outline::before {
  content: "\f1533";
}

.mdi-timeline-clock::before {
  content: "\f11fb";
}

.mdi-timeline-clock-outline::before {
  content: "\f11fc";
}

.mdi-timeline-help::before {
  content: "\f0f99";
}

.mdi-timeline-help-outline::before {
  content: "\f0f9a";
}

.mdi-timeline-minus::before {
  content: "\f1534";
}

.mdi-timeline-minus-outline::before {
  content: "\f1535";
}

.mdi-timeline-outline::before {
  content: "\f0bd2";
}

.mdi-timeline-plus::before {
  content: "\f0f96";
}

.mdi-timeline-plus-outline::before {
  content: "\f0f97";
}

.mdi-timeline-remove::before {
  content: "\f1536";
}

.mdi-timeline-remove-outline::before {
  content: "\f1537";
}

.mdi-timeline-text::before {
  content: "\f0bd3";
}

.mdi-timeline-text-outline::before {
  content: "\f0bd4";
}

.mdi-timer::before {
  content: "\f13ab";
}

.mdi-timer-10::before {
  content: "\f051c";
}

.mdi-timer-3::before {
  content: "\f051d";
}

.mdi-timer-cog::before {
  content: "\f1925";
}

.mdi-timer-cog-outline::before {
  content: "\f1926";
}

.mdi-timer-off::before {
  content: "\f13ac";
}

.mdi-timer-off-outline::before {
  content: "\f051e";
}

.mdi-timer-outline::before {
  content: "\f051b";
}

.mdi-timer-sand::before {
  content: "\f051f";
}

.mdi-timer-sand-complete::before {
  content: "\f199f";
}

.mdi-timer-sand-empty::before {
  content: "\f06ad";
}

.mdi-timer-sand-full::before {
  content: "\f078c";
}

.mdi-timer-sand-paused::before {
  content: "\f19a0";
}

.mdi-timer-settings::before {
  content: "\f1923";
}

.mdi-timer-settings-outline::before {
  content: "\f1924";
}

.mdi-timetable::before {
  content: "\f0520";
}

.mdi-tire::before {
  content: "\f1896";
}

.mdi-toaster::before {
  content: "\f1063";
}

.mdi-toaster-off::before {
  content: "\f11b7";
}

.mdi-toaster-oven::before {
  content: "\f0cd3";
}

.mdi-toggle-switch::before {
  content: "\f0521";
}

.mdi-toggle-switch-off::before {
  content: "\f0522";
}

.mdi-toggle-switch-off-outline::before {
  content: "\f0a19";
}

.mdi-toggle-switch-outline::before {
  content: "\f0a1a";
}

.mdi-toilet::before {
  content: "\f09ab";
}

.mdi-toolbox::before {
  content: "\f09ac";
}

.mdi-toolbox-outline::before {
  content: "\f09ad";
}

.mdi-tools::before {
  content: "\f1064";
}

.mdi-tooltip::before {
  content: "\f0523";
}

.mdi-tooltip-account::before {
  content: "\f000c";
}

.mdi-tooltip-cellphone::before {
  content: "\f183b";
}

.mdi-tooltip-check::before {
  content: "\f155c";
}

.mdi-tooltip-check-outline::before {
  content: "\f155d";
}

.mdi-tooltip-edit::before {
  content: "\f0524";
}

.mdi-tooltip-edit-outline::before {
  content: "\f12c5";
}

.mdi-tooltip-image::before {
  content: "\f0525";
}

.mdi-tooltip-image-outline::before {
  content: "\f0bd5";
}

.mdi-tooltip-minus::before {
  content: "\f155e";
}

.mdi-tooltip-minus-outline::before {
  content: "\f155f";
}

.mdi-tooltip-outline::before {
  content: "\f0526";
}

.mdi-tooltip-plus::before {
  content: "\f0bd6";
}

.mdi-tooltip-plus-outline::before {
  content: "\f0527";
}

.mdi-tooltip-remove::before {
  content: "\f1560";
}

.mdi-tooltip-remove-outline::before {
  content: "\f1561";
}

.mdi-tooltip-text::before {
  content: "\f0528";
}

.mdi-tooltip-text-outline::before {
  content: "\f0bd7";
}

.mdi-tooth::before {
  content: "\f08c3";
}

.mdi-tooth-outline::before {
  content: "\f0529";
}

.mdi-toothbrush::before {
  content: "\f1129";
}

.mdi-toothbrush-electric::before {
  content: "\f112c";
}

.mdi-toothbrush-paste::before {
  content: "\f112a";
}

.mdi-torch::before {
  content: "\f1606";
}

.mdi-tortoise::before {
  content: "\f0d3b";
}

.mdi-toslink::before {
  content: "\f12b8";
}

.mdi-tournament::before {
  content: "\f09ae";
}

.mdi-tow-truck::before {
  content: "\f083c";
}

.mdi-tower-beach::before {
  content: "\f0681";
}

.mdi-tower-fire::before {
  content: "\f0682";
}

.mdi-town-hall::before {
  content: "\f1875";
}

.mdi-toy-brick::before {
  content: "\f1288";
}

.mdi-toy-brick-marker::before {
  content: "\f1289";
}

.mdi-toy-brick-marker-outline::before {
  content: "\f128a";
}

.mdi-toy-brick-minus::before {
  content: "\f128b";
}

.mdi-toy-brick-minus-outline::before {
  content: "\f128c";
}

.mdi-toy-brick-outline::before {
  content: "\f128d";
}

.mdi-toy-brick-plus::before {
  content: "\f128e";
}

.mdi-toy-brick-plus-outline::before {
  content: "\f128f";
}

.mdi-toy-brick-remove::before {
  content: "\f1290";
}

.mdi-toy-brick-remove-outline::before {
  content: "\f1291";
}

.mdi-toy-brick-search::before {
  content: "\f1292";
}

.mdi-toy-brick-search-outline::before {
  content: "\f1293";
}

.mdi-track-light::before {
  content: "\f0914";
}

.mdi-trackpad::before {
  content: "\f07f8";
}

.mdi-trackpad-lock::before {
  content: "\f0933";
}

.mdi-tractor::before {
  content: "\f0892";
}

.mdi-tractor-variant::before {
  content: "\f14c4";
}

.mdi-trademark::before {
  content: "\f0a78";
}

.mdi-traffic-cone::before {
  content: "\f137c";
}

.mdi-traffic-light::before {
  content: "\f052b";
}

.mdi-traffic-light-outline::before {
  content: "\f182a";
}

.mdi-train::before {
  content: "\f052c";
}

.mdi-train-car::before {
  content: "\f0bd8";
}

.mdi-train-car-passenger::before {
  content: "\f1733";
}

.mdi-train-car-passenger-door::before {
  content: "\f1734";
}

.mdi-train-car-passenger-door-open::before {
  content: "\f1735";
}

.mdi-train-car-passenger-variant::before {
  content: "\f1736";
}

.mdi-train-variant::before {
  content: "\f08c4";
}

.mdi-tram::before {
  content: "\f052d";
}

.mdi-tram-side::before {
  content: "\f0fe7";
}

.mdi-transcribe::before {
  content: "\f052e";
}

.mdi-transcribe-close::before {
  content: "\f052f";
}

.mdi-transfer::before {
  content: "\f1065";
}

.mdi-transfer-down::before {
  content: "\f0da1";
}

.mdi-transfer-left::before {
  content: "\f0da2";
}

.mdi-transfer-right::before {
  content: "\f0530";
}

.mdi-transfer-up::before {
  content: "\f0da3";
}

.mdi-transit-connection::before {
  content: "\f0d3c";
}

.mdi-transit-connection-horizontal::before {
  content: "\f1546";
}

.mdi-transit-connection-variant::before {
  content: "\f0d3d";
}

.mdi-transit-detour::before {
  content: "\f0f8b";
}

.mdi-transit-skip::before {
  content: "\f1515";
}

.mdi-transit-transfer::before {
  content: "\f06ae";
}

.mdi-transition::before {
  content: "\f0915";
}

.mdi-transition-masked::before {
  content: "\f0916";
}

.mdi-translate::before {
  content: "\f05ca";
}

.mdi-translate-off::before {
  content: "\f0e06";
}

.mdi-transmission-tower::before {
  content: "\f0d3e";
}

.mdi-transmission-tower-export::before {
  content: "\f192c";
}

.mdi-transmission-tower-import::before {
  content: "\f192d";
}

.mdi-trash-can::before {
  content: "\f0a79";
}

.mdi-trash-can-outline::before {
  content: "\f0a7a";
}

.mdi-tray::before {
  content: "\f1294";
}

.mdi-tray-alert::before {
  content: "\f1295";
}

.mdi-tray-arrow-down::before {
  content: "\f0120";
}

.mdi-tray-arrow-up::before {
  content: "\f011d";
}

.mdi-tray-full::before {
  content: "\f1296";
}

.mdi-tray-minus::before {
  content: "\f1297";
}

.mdi-tray-plus::before {
  content: "\f1298";
}

.mdi-tray-remove::before {
  content: "\f1299";
}

.mdi-treasure-chest::before {
  content: "\f0726";
}

.mdi-tree::before {
  content: "\f0531";
}

.mdi-tree-outline::before {
  content: "\f0e69";
}

.mdi-trello::before {
  content: "\f0532";
}

.mdi-trending-down::before {
  content: "\f0533";
}

.mdi-trending-neutral::before {
  content: "\f0534";
}

.mdi-trending-up::before {
  content: "\f0535";
}

.mdi-triangle::before {
  content: "\f0536";
}

.mdi-triangle-outline::before {
  content: "\f0537";
}

.mdi-triangle-wave::before {
  content: "\f147c";
}

.mdi-triforce::before {
  content: "\f0bd9";
}

.mdi-trophy::before {
  content: "\f0538";
}

.mdi-trophy-award::before {
  content: "\f0539";
}

.mdi-trophy-broken::before {
  content: "\f0da4";
}

.mdi-trophy-outline::before {
  content: "\f053a";
}

.mdi-trophy-variant::before {
  content: "\f053b";
}

.mdi-trophy-variant-outline::before {
  content: "\f053c";
}

.mdi-truck::before {
  content: "\f053d";
}

.mdi-truck-cargo-container::before {
  content: "\f18d8";
}

.mdi-truck-check::before {
  content: "\f0cd4";
}

.mdi-truck-check-outline::before {
  content: "\f129a";
}

.mdi-truck-delivery::before {
  content: "\f053e";
}

.mdi-truck-delivery-outline::before {
  content: "\f129b";
}

.mdi-truck-fast::before {
  content: "\f0788";
}

.mdi-truck-fast-outline::before {
  content: "\f129c";
}

.mdi-truck-flatbed::before {
  content: "\f1891";
}

.mdi-truck-minus::before {
  content: "\f19ae";
}

.mdi-truck-minus-outline::before {
  content: "\f19bd";
}

.mdi-truck-outline::before {
  content: "\f129d";
}

.mdi-truck-plus::before {
  content: "\f19ad";
}

.mdi-truck-plus-outline::before {
  content: "\f19bc";
}

.mdi-truck-remove::before {
  content: "\f19af";
}

.mdi-truck-remove-outline::before {
  content: "\f19be";
}

.mdi-truck-snowflake::before {
  content: "\f19a6";
}

.mdi-truck-trailer::before {
  content: "\f0727";
}

.mdi-trumpet::before {
  content: "\f1096";
}

.mdi-tshirt-crew::before {
  content: "\f0a7b";
}

.mdi-tshirt-crew-outline::before {
  content: "\f053f";
}

.mdi-tshirt-v::before {
  content: "\f0a7c";
}

.mdi-tshirt-v-outline::before {
  content: "\f0540";
}

.mdi-tumble-dryer::before {
  content: "\f0917";
}

.mdi-tumble-dryer-alert::before {
  content: "\f11ba";
}

.mdi-tumble-dryer-off::before {
  content: "\f11bb";
}

.mdi-tune::before {
  content: "\f062e";
}

.mdi-tune-variant::before {
  content: "\f1542";
}

.mdi-tune-vertical::before {
  content: "\f066a";
}

.mdi-tune-vertical-variant::before {
  content: "\f1543";
}

.mdi-tunnel::before {
  content: "\f183d";
}

.mdi-tunnel-outline::before {
  content: "\f183e";
}

.mdi-turkey::before {
  content: "\f171b";
}

.mdi-turnstile::before {
  content: "\f0cd5";
}

.mdi-turnstile-outline::before {
  content: "\f0cd6";
}

.mdi-turtle::before {
  content: "\f0cd7";
}

.mdi-twitch::before {
  content: "\f0543";
}

.mdi-twitter::before {
  content: "\f0544";
}

.mdi-two-factor-authentication::before {
  content: "\f09af";
}

.mdi-typewriter::before {
  content: "\f0f2d";
}

.mdi-ubisoft::before {
  content: "\f0bda";
}

.mdi-ubuntu::before {
  content: "\f0548";
}

.mdi-ufo::before {
  content: "\f10c4";
}

.mdi-ufo-outline::before {
  content: "\f10c5";
}

.mdi-ultra-high-definition::before {
  content: "\f07f9";
}

.mdi-umbraco::before {
  content: "\f0549";
}

.mdi-umbrella::before {
  content: "\f054a";
}

.mdi-umbrella-beach::before {
  content: "\f188a";
}

.mdi-umbrella-beach-outline::before {
  content: "\f188b";
}

.mdi-umbrella-closed::before {
  content: "\f09b0";
}

.mdi-umbrella-closed-outline::before {
  content: "\f13e2";
}

.mdi-umbrella-closed-variant::before {
  content: "\f13e1";
}

.mdi-umbrella-outline::before {
  content: "\f054b";
}

.mdi-undo::before {
  content: "\f054c";
}

.mdi-undo-variant::before {
  content: "\f054d";
}

.mdi-unfold-less-horizontal::before {
  content: "\f054e";
}

.mdi-unfold-less-vertical::before {
  content: "\f0760";
}

.mdi-unfold-more-horizontal::before {
  content: "\f054f";
}

.mdi-unfold-more-vertical::before {
  content: "\f0761";
}

.mdi-ungroup::before {
  content: "\f0550";
}

.mdi-unicode::before {
  content: "\f0ed0";
}

.mdi-unicorn::before {
  content: "\f15c2";
}

.mdi-unicorn-variant::before {
  content: "\f15c3";
}

.mdi-unicycle::before {
  content: "\f15e5";
}

.mdi-unity::before {
  content: "\f06af";
}

.mdi-unreal::before {
  content: "\f09b1";
}

.mdi-update::before {
  content: "\f06b0";
}

.mdi-upload::before {
  content: "\f0552";
}

.mdi-upload-lock::before {
  content: "\f1373";
}

.mdi-upload-lock-outline::before {
  content: "\f1374";
}

.mdi-upload-multiple::before {
  content: "\f083d";
}

.mdi-upload-network::before {
  content: "\f06f6";
}

.mdi-upload-network-outline::before {
  content: "\f0cd8";
}

.mdi-upload-off::before {
  content: "\f10c6";
}

.mdi-upload-off-outline::before {
  content: "\f10c7";
}

.mdi-upload-outline::before {
  content: "\f0e07";
}

.mdi-usb::before {
  content: "\f0553";
}

.mdi-usb-flash-drive::before {
  content: "\f129e";
}

.mdi-usb-flash-drive-outline::before {
  content: "\f129f";
}

.mdi-usb-port::before {
  content: "\f11f0";
}

.mdi-vacuum::before {
  content: "\f19a1";
}

.mdi-vacuum-outline::before {
  content: "\f19a2";
}

.mdi-valve::before {
  content: "\f1066";
}

.mdi-valve-closed::before {
  content: "\f1067";
}

.mdi-valve-open::before {
  content: "\f1068";
}

.mdi-van-passenger::before {
  content: "\f07fa";
}

.mdi-van-utility::before {
  content: "\f07fb";
}

.mdi-vanish::before {
  content: "\f07fc";
}

.mdi-vanish-quarter::before {
  content: "\f1554";
}

.mdi-vanity-light::before {
  content: "\f11e1";
}

.mdi-variable::before {
  content: "\f0ae7";
}

.mdi-variable-box::before {
  content: "\f1111";
}

.mdi-vector-arrange-above::before {
  content: "\f0554";
}

.mdi-vector-arrange-below::before {
  content: "\f0555";
}

.mdi-vector-bezier::before {
  content: "\f0ae8";
}

.mdi-vector-circle::before {
  content: "\f0556";
}

.mdi-vector-circle-variant::before {
  content: "\f0557";
}

.mdi-vector-combine::before {
  content: "\f0558";
}

.mdi-vector-curve::before {
  content: "\f0559";
}

.mdi-vector-difference::before {
  content: "\f055a";
}

.mdi-vector-difference-ab::before {
  content: "\f055b";
}

.mdi-vector-difference-ba::before {
  content: "\f055c";
}

.mdi-vector-ellipse::before {
  content: "\f0893";
}

.mdi-vector-intersection::before {
  content: "\f055d";
}

.mdi-vector-line::before {
  content: "\f055e";
}

.mdi-vector-link::before {
  content: "\f0fe8";
}

.mdi-vector-point::before {
  content: "\f055f";
}

.mdi-vector-polygon::before {
  content: "\f0560";
}

.mdi-vector-polygon-variant::before {
  content: "\f1856";
}

.mdi-vector-polyline::before {
  content: "\f0561";
}

.mdi-vector-polyline-edit::before {
  content: "\f1225";
}

.mdi-vector-polyline-minus::before {
  content: "\f1226";
}

.mdi-vector-polyline-plus::before {
  content: "\f1227";
}

.mdi-vector-polyline-remove::before {
  content: "\f1228";
}

.mdi-vector-radius::before {
  content: "\f074a";
}

.mdi-vector-rectangle::before {
  content: "\f05c6";
}

.mdi-vector-selection::before {
  content: "\f0562";
}

.mdi-vector-square::before {
  content: "\f0001";
}

.mdi-vector-square-close::before {
  content: "\f1857";
}

.mdi-vector-square-edit::before {
  content: "\f18d9";
}

.mdi-vector-square-minus::before {
  content: "\f18da";
}

.mdi-vector-square-open::before {
  content: "\f1858";
}

.mdi-vector-square-plus::before {
  content: "\f18db";
}

.mdi-vector-square-remove::before {
  content: "\f18dc";
}

.mdi-vector-triangle::before {
  content: "\f0563";
}

.mdi-vector-union::before {
  content: "\f0564";
}

.mdi-vhs::before {
  content: "\f0a1b";
}

.mdi-vibrate::before {
  content: "\f0566";
}

.mdi-vibrate-off::before {
  content: "\f0cd9";
}

.mdi-video::before {
  content: "\f0567";
}

.mdi-video-3d::before {
  content: "\f07fd";
}

.mdi-video-3d-off::before {
  content: "\f13d9";
}

.mdi-video-3d-variant::before {
  content: "\f0ed1";
}

.mdi-video-4k-box::before {
  content: "\f083e";
}

.mdi-video-account::before {
  content: "\f0919";
}

.mdi-video-box::before {
  content: "\f00fd";
}

.mdi-video-box-off::before {
  content: "\f00fe";
}

.mdi-video-check::before {
  content: "\f1069";
}

.mdi-video-check-outline::before {
  content: "\f106a";
}

.mdi-video-high-definition::before {
  content: "\f152e";
}

.mdi-video-image::before {
  content: "\f091a";
}

.mdi-video-input-antenna::before {
  content: "\f083f";
}

.mdi-video-input-component::before {
  content: "\f0840";
}

.mdi-video-input-hdmi::before {
  content: "\f0841";
}

.mdi-video-input-scart::before {
  content: "\f0f8c";
}

.mdi-video-input-svideo::before {
  content: "\f0842";
}

.mdi-video-marker::before {
  content: "\f19a9";
}

.mdi-video-marker-outline::before {
  content: "\f19aa";
}

.mdi-video-minus::before {
  content: "\f09b2";
}

.mdi-video-minus-outline::before {
  content: "\f02ba";
}

.mdi-video-off::before {
  content: "\f0568";
}

.mdi-video-off-outline::before {
  content: "\f0bdb";
}

.mdi-video-outline::before {
  content: "\f0bdc";
}

.mdi-video-plus::before {
  content: "\f09b3";
}

.mdi-video-plus-outline::before {
  content: "\f01d3";
}

.mdi-video-stabilization::before {
  content: "\f091b";
}

.mdi-video-switch::before {
  content: "\f0569";
}

.mdi-video-switch-outline::before {
  content: "\f0790";
}

.mdi-video-vintage::before {
  content: "\f0a1c";
}

.mdi-video-wireless::before {
  content: "\f0ed2";
}

.mdi-video-wireless-outline::before {
  content: "\f0ed3";
}

.mdi-view-agenda::before {
  content: "\f056a";
}

.mdi-view-agenda-outline::before {
  content: "\f11d8";
}

.mdi-view-array::before {
  content: "\f056b";
}

.mdi-view-array-outline::before {
  content: "\f1485";
}

.mdi-view-carousel::before {
  content: "\f056c";
}

.mdi-view-carousel-outline::before {
  content: "\f1486";
}

.mdi-view-column::before {
  content: "\f056d";
}

.mdi-view-column-outline::before {
  content: "\f1487";
}

.mdi-view-comfy::before {
  content: "\f0e6a";
}

.mdi-view-comfy-outline::before {
  content: "\f1488";
}

.mdi-view-compact::before {
  content: "\f0e6b";
}

.mdi-view-compact-outline::before {
  content: "\f0e6c";
}

.mdi-view-dashboard::before {
  content: "\f056e";
}

.mdi-view-dashboard-edit::before {
  content: "\f1947";
}

.mdi-view-dashboard-edit-outline::before {
  content: "\f1948";
}

.mdi-view-dashboard-outline::before {
  content: "\f0a1d";
}

.mdi-view-dashboard-variant::before {
  content: "\f0843";
}

.mdi-view-dashboard-variant-outline::before {
  content: "\f1489";
}

.mdi-view-day::before {
  content: "\f056f";
}

.mdi-view-day-outline::before {
  content: "\f148a";
}

.mdi-view-gallery::before {
  content: "\f1888";
}

.mdi-view-gallery-outline::before {
  content: "\f1889";
}

.mdi-view-grid::before {
  content: "\f0570";
}

.mdi-view-grid-outline::before {
  content: "\f11d9";
}

.mdi-view-grid-plus::before {
  content: "\f0f8d";
}

.mdi-view-grid-plus-outline::before {
  content: "\f11da";
}

.mdi-view-headline::before {
  content: "\f0571";
}

.mdi-view-list::before {
  content: "\f0572";
}

.mdi-view-list-outline::before {
  content: "\f148b";
}

.mdi-view-module::before {
  content: "\f0573";
}

.mdi-view-module-outline::before {
  content: "\f148c";
}

.mdi-view-parallel::before {
  content: "\f0728";
}

.mdi-view-parallel-outline::before {
  content: "\f148d";
}

.mdi-view-quilt::before {
  content: "\f0574";
}

.mdi-view-quilt-outline::before {
  content: "\f148e";
}

.mdi-view-sequential::before {
  content: "\f0729";
}

.mdi-view-sequential-outline::before {
  content: "\f148f";
}

.mdi-view-split-horizontal::before {
  content: "\f0bcb";
}

.mdi-view-split-vertical::before {
  content: "\f0bcc";
}

.mdi-view-stream::before {
  content: "\f0575";
}

.mdi-view-stream-outline::before {
  content: "\f1490";
}

.mdi-view-week::before {
  content: "\f0576";
}

.mdi-view-week-outline::before {
  content: "\f1491";
}

.mdi-vimeo::before {
  content: "\f0577";
}

.mdi-violin::before {
  content: "\f060f";
}

.mdi-virtual-reality::before {
  content: "\f0894";
}

.mdi-virus::before {
  content: "\f13b6";
}

.mdi-virus-off::before {
  content: "\f18e1";
}

.mdi-virus-off-outline::before {
  content: "\f18e2";
}

.mdi-virus-outline::before {
  content: "\f13b7";
}

.mdi-vlc::before {
  content: "\f057c";
}

.mdi-voicemail::before {
  content: "\f057d";
}

.mdi-volleyball::before {
  content: "\f09b4";
}

.mdi-volume-high::before {
  content: "\f057e";
}

.mdi-volume-low::before {
  content: "\f057f";
}

.mdi-volume-medium::before {
  content: "\f0580";
}

.mdi-volume-minus::before {
  content: "\f075e";
}

.mdi-volume-mute::before {
  content: "\f075f";
}

.mdi-volume-off::before {
  content: "\f0581";
}

.mdi-volume-plus::before {
  content: "\f075d";
}

.mdi-volume-source::before {
  content: "\f1120";
}

.mdi-volume-variant-off::before {
  content: "\f0e08";
}

.mdi-volume-vibrate::before {
  content: "\f1121";
}

.mdi-vote::before {
  content: "\f0a1f";
}

.mdi-vote-outline::before {
  content: "\f0a20";
}

.mdi-vpn::before {
  content: "\f0582";
}

.mdi-vuejs::before {
  content: "\f0844";
}

.mdi-vuetify::before {
  content: "\f0e6d";
}

.mdi-walk::before {
  content: "\f0583";
}

.mdi-wall::before {
  content: "\f07fe";
}

.mdi-wall-sconce::before {
  content: "\f091c";
}

.mdi-wall-sconce-flat::before {
  content: "\f091d";
}

.mdi-wall-sconce-flat-outline::before {
  content: "\f17c9";
}

.mdi-wall-sconce-flat-variant::before {
  content: "\f041c";
}

.mdi-wall-sconce-flat-variant-outline::before {
  content: "\f17ca";
}

.mdi-wall-sconce-outline::before {
  content: "\f17cb";
}

.mdi-wall-sconce-round::before {
  content: "\f0748";
}

.mdi-wall-sconce-round-outline::before {
  content: "\f17cc";
}

.mdi-wall-sconce-round-variant::before {
  content: "\f091e";
}

.mdi-wall-sconce-round-variant-outline::before {
  content: "\f17cd";
}

.mdi-wallet::before {
  content: "\f0584";
}

.mdi-wallet-giftcard::before {
  content: "\f0585";
}

.mdi-wallet-membership::before {
  content: "\f0586";
}

.mdi-wallet-outline::before {
  content: "\f0bdd";
}

.mdi-wallet-plus::before {
  content: "\f0f8e";
}

.mdi-wallet-plus-outline::before {
  content: "\f0f8f";
}

.mdi-wallet-travel::before {
  content: "\f0587";
}

.mdi-wallpaper::before {
  content: "\f0e09";
}

.mdi-wan::before {
  content: "\f0588";
}

.mdi-wardrobe::before {
  content: "\f0f90";
}

.mdi-wardrobe-outline::before {
  content: "\f0f91";
}

.mdi-warehouse::before {
  content: "\f0f81";
}

.mdi-washing-machine::before {
  content: "\f072a";
}

.mdi-washing-machine-alert::before {
  content: "\f11bc";
}

.mdi-washing-machine-off::before {
  content: "\f11bd";
}

.mdi-watch::before {
  content: "\f0589";
}

.mdi-watch-export::before {
  content: "\f058a";
}

.mdi-watch-export-variant::before {
  content: "\f0895";
}

.mdi-watch-import::before {
  content: "\f058b";
}

.mdi-watch-import-variant::before {
  content: "\f0896";
}

.mdi-watch-variant::before {
  content: "\f0897";
}

.mdi-watch-vibrate::before {
  content: "\f06b1";
}

.mdi-watch-vibrate-off::before {
  content: "\f0cda";
}

.mdi-water::before {
  content: "\f058c";
}

.mdi-water-alert::before {
  content: "\f1502";
}

.mdi-water-alert-outline::before {
  content: "\f1503";
}

.mdi-water-boiler::before {
  content: "\f0f92";
}

.mdi-water-boiler-alert::before {
  content: "\f11b3";
}

.mdi-water-boiler-off::before {
  content: "\f11b4";
}

.mdi-water-check::before {
  content: "\f1504";
}

.mdi-water-check-outline::before {
  content: "\f1505";
}

.mdi-water-circle::before {
  content: "\f1806";
}

.mdi-water-minus::before {
  content: "\f1506";
}

.mdi-water-minus-outline::before {
  content: "\f1507";
}

.mdi-water-off::before {
  content: "\f058d";
}

.mdi-water-off-outline::before {
  content: "\f1508";
}

.mdi-water-opacity::before {
  content: "\f1855";
}

.mdi-water-outline::before {
  content: "\f0e0a";
}

.mdi-water-percent::before {
  content: "\f058e";
}

.mdi-water-percent-alert::before {
  content: "\f1509";
}

.mdi-water-plus::before {
  content: "\f150a";
}

.mdi-water-plus-outline::before {
  content: "\f150b";
}

.mdi-water-polo::before {
  content: "\f12a0";
}

.mdi-water-pump::before {
  content: "\f058f";
}

.mdi-water-pump-off::before {
  content: "\f0f93";
}

.mdi-water-remove::before {
  content: "\f150c";
}

.mdi-water-remove-outline::before {
  content: "\f150d";
}

.mdi-water-sync::before {
  content: "\f17c6";
}

.mdi-water-well::before {
  content: "\f106b";
}

.mdi-water-well-outline::before {
  content: "\f106c";
}

.mdi-waterfall::before {
  content: "\f1849";
}

.mdi-watering-can::before {
  content: "\f1481";
}

.mdi-watering-can-outline::before {
  content: "\f1482";
}

.mdi-watermark::before {
  content: "\f0612";
}

.mdi-wave::before {
  content: "\f0f2e";
}

.mdi-waveform::before {
  content: "\f147d";
}

.mdi-waves::before {
  content: "\f078d";
}

.mdi-waves-arrow-left::before {
  content: "\f1859";
}

.mdi-waves-arrow-right::before {
  content: "\f185a";
}

.mdi-waves-arrow-up::before {
  content: "\f185b";
}

.mdi-waze::before {
  content: "\f0bde";
}

.mdi-weather-cloudy::before {
  content: "\f0590";
}

.mdi-weather-cloudy-alert::before {
  content: "\f0f2f";
}

.mdi-weather-cloudy-arrow-right::before {
  content: "\f0e6e";
}

.mdi-weather-cloudy-clock::before {
  content: "\f18f6";
}

.mdi-weather-fog::before {
  content: "\f0591";
}

.mdi-weather-hail::before {
  content: "\f0592";
}

.mdi-weather-hazy::before {
  content: "\f0f30";
}

.mdi-weather-hurricane::before {
  content: "\f0898";
}

.mdi-weather-lightning::before {
  content: "\f0593";
}

.mdi-weather-lightning-rainy::before {
  content: "\f067e";
}

.mdi-weather-night::before {
  content: "\f0594";
}

.mdi-weather-night-partly-cloudy::before {
  content: "\f0f31";
}

.mdi-weather-partly-cloudy::before {
  content: "\f0595";
}

.mdi-weather-partly-lightning::before {
  content: "\f0f32";
}

.mdi-weather-partly-rainy::before {
  content: "\f0f33";
}

.mdi-weather-partly-snowy::before {
  content: "\f0f34";
}

.mdi-weather-partly-snowy-rainy::before {
  content: "\f0f35";
}

.mdi-weather-pouring::before {
  content: "\f0596";
}

.mdi-weather-rainy::before {
  content: "\f0597";
}

.mdi-weather-snowy::before {
  content: "\f0598";
}

.mdi-weather-snowy-heavy::before {
  content: "\f0f36";
}

.mdi-weather-snowy-rainy::before {
  content: "\f067f";
}

.mdi-weather-sunny::before {
  content: "\f0599";
}

.mdi-weather-sunny-alert::before {
  content: "\f0f37";
}

.mdi-weather-sunny-off::before {
  content: "\f14e4";
}

.mdi-weather-sunset::before {
  content: "\f059a";
}

.mdi-weather-sunset-down::before {
  content: "\f059b";
}

.mdi-weather-sunset-up::before {
  content: "\f059c";
}

.mdi-weather-tornado::before {
  content: "\f0f38";
}

.mdi-weather-windy::before {
  content: "\f059d";
}

.mdi-weather-windy-variant::before {
  content: "\f059e";
}

.mdi-web::before {
  content: "\f059f";
}

.mdi-web-box::before {
  content: "\f0f94";
}

.mdi-web-cancel::before {
  content: "\f1790";
}

.mdi-web-check::before {
  content: "\f0789";
}

.mdi-web-clock::before {
  content: "\f124a";
}

.mdi-web-minus::before {
  content: "\f10a0";
}

.mdi-web-off::before {
  content: "\f0a8e";
}

.mdi-web-plus::before {
  content: "\f0033";
}

.mdi-web-refresh::before {
  content: "\f1791";
}

.mdi-web-remove::before {
  content: "\f0551";
}

.mdi-web-sync::before {
  content: "\f1792";
}

.mdi-webcam::before {
  content: "\f05a0";
}

.mdi-webcam-off::before {
  content: "\f1737";
}

.mdi-webhook::before {
  content: "\f062f";
}

.mdi-webpack::before {
  content: "\f072b";
}

.mdi-webrtc::before {
  content: "\f1248";
}

.mdi-wechat::before {
  content: "\f0611";
}

.mdi-weight::before {
  content: "\f05a1";
}

.mdi-weight-gram::before {
  content: "\f0d3f";
}

.mdi-weight-kilogram::before {
  content: "\f05a2";
}

.mdi-weight-lifter::before {
  content: "\f115d";
}

.mdi-weight-pound::before {
  content: "\f09b5";
}

.mdi-whatsapp::before {
  content: "\f05a3";
}

.mdi-wheel-barrow::before {
  content: "\f14f2";
}

.mdi-wheelchair-accessibility::before {
  content: "\f05a4";
}

.mdi-whistle::before {
  content: "\f09b6";
}

.mdi-whistle-outline::before {
  content: "\f12bc";
}

.mdi-white-balance-auto::before {
  content: "\f05a5";
}

.mdi-white-balance-incandescent::before {
  content: "\f05a6";
}

.mdi-white-balance-iridescent::before {
  content: "\f05a7";
}

.mdi-white-balance-sunny::before {
  content: "\f05a8";
}

.mdi-widgets::before {
  content: "\f072c";
}

.mdi-widgets-outline::before {
  content: "\f1355";
}

.mdi-wifi::before {
  content: "\f05a9";
}

.mdi-wifi-alert::before {
  content: "\f16b5";
}

.mdi-wifi-arrow-down::before {
  content: "\f16b6";
}

.mdi-wifi-arrow-left::before {
  content: "\f16b7";
}

.mdi-wifi-arrow-left-right::before {
  content: "\f16b8";
}

.mdi-wifi-arrow-right::before {
  content: "\f16b9";
}

.mdi-wifi-arrow-up::before {
  content: "\f16ba";
}

.mdi-wifi-arrow-up-down::before {
  content: "\f16bb";
}

.mdi-wifi-cancel::before {
  content: "\f16bc";
}

.mdi-wifi-check::before {
  content: "\f16bd";
}

.mdi-wifi-cog::before {
  content: "\f16be";
}

.mdi-wifi-lock::before {
  content: "\f16bf";
}

.mdi-wifi-lock-open::before {
  content: "\f16c0";
}

.mdi-wifi-marker::before {
  content: "\f16c1";
}

.mdi-wifi-minus::before {
  content: "\f16c2";
}

.mdi-wifi-off::before {
  content: "\f05aa";
}

.mdi-wifi-plus::before {
  content: "\f16c3";
}

.mdi-wifi-refresh::before {
  content: "\f16c4";
}

.mdi-wifi-remove::before {
  content: "\f16c5";
}

.mdi-wifi-settings::before {
  content: "\f16c6";
}

.mdi-wifi-star::before {
  content: "\f0e0b";
}

.mdi-wifi-strength-1::before {
  content: "\f091f";
}

.mdi-wifi-strength-1-alert::before {
  content: "\f0920";
}

.mdi-wifi-strength-1-lock::before {
  content: "\f0921";
}

.mdi-wifi-strength-1-lock-open::before {
  content: "\f16cb";
}

.mdi-wifi-strength-2::before {
  content: "\f0922";
}

.mdi-wifi-strength-2-alert::before {
  content: "\f0923";
}

.mdi-wifi-strength-2-lock::before {
  content: "\f0924";
}

.mdi-wifi-strength-2-lock-open::before {
  content: "\f16cc";
}

.mdi-wifi-strength-3::before {
  content: "\f0925";
}

.mdi-wifi-strength-3-alert::before {
  content: "\f0926";
}

.mdi-wifi-strength-3-lock::before {
  content: "\f0927";
}

.mdi-wifi-strength-3-lock-open::before {
  content: "\f16cd";
}

.mdi-wifi-strength-4::before {
  content: "\f0928";
}

.mdi-wifi-strength-4-alert::before {
  content: "\f0929";
}

.mdi-wifi-strength-4-lock::before {
  content: "\f092a";
}

.mdi-wifi-strength-4-lock-open::before {
  content: "\f16ce";
}

.mdi-wifi-strength-alert-outline::before {
  content: "\f092b";
}

.mdi-wifi-strength-lock-open-outline::before {
  content: "\f16cf";
}

.mdi-wifi-strength-lock-outline::before {
  content: "\f092c";
}

.mdi-wifi-strength-off::before {
  content: "\f092d";
}

.mdi-wifi-strength-off-outline::before {
  content: "\f092e";
}

.mdi-wifi-strength-outline::before {
  content: "\f092f";
}

.mdi-wifi-sync::before {
  content: "\f16c7";
}

.mdi-wikipedia::before {
  content: "\f05ac";
}

.mdi-wind-turbine::before {
  content: "\f0da5";
}

.mdi-wind-turbine-alert::before {
  content: "\f19ab";
}

.mdi-wind-turbine-check::before {
  content: "\f19ac";
}

.mdi-window-close::before {
  content: "\f05ad";
}

.mdi-window-closed::before {
  content: "\f05ae";
}

.mdi-window-closed-variant::before {
  content: "\f11db";
}

.mdi-window-maximize::before {
  content: "\f05af";
}

.mdi-window-minimize::before {
  content: "\f05b0";
}

.mdi-window-open::before {
  content: "\f05b1";
}

.mdi-window-open-variant::before {
  content: "\f11dc";
}

.mdi-window-restore::before {
  content: "\f05b2";
}

.mdi-window-shutter::before {
  content: "\f111c";
}

.mdi-window-shutter-alert::before {
  content: "\f111d";
}

.mdi-window-shutter-open::before {
  content: "\f111e";
}

.mdi-windsock::before {
  content: "\f15fa";
}

.mdi-wiper::before {
  content: "\f0ae9";
}

.mdi-wiper-wash::before {
  content: "\f0da6";
}

.mdi-wiper-wash-alert::before {
  content: "\f18df";
}

.mdi-wizard-hat::before {
  content: "\f1477";
}

.mdi-wordpress::before {
  content: "\f05b4";
}

.mdi-wrap::before {
  content: "\f05b6";
}

.mdi-wrap-disabled::before {
  content: "\f0bdf";
}

.mdi-wrench::before {
  content: "\f05b7";
}

.mdi-wrench-clock::before {
  content: "\f19a3";
}

.mdi-wrench-outline::before {
  content: "\f0be0";
}

.mdi-xamarin::before {
  content: "\f0845";
}

.mdi-xml::before {
  content: "\f05c0";
}

.mdi-xmpp::before {
  content: "\f07ff";
}

.mdi-yahoo::before {
  content: "\f0b4f";
}

.mdi-yeast::before {
  content: "\f05c1";
}

.mdi-yin-yang::before {
  content: "\f0680";
}

.mdi-yoga::before {
  content: "\f117c";
}

.mdi-youtube::before {
  content: "\f05c3";
}

.mdi-youtube-gaming::before {
  content: "\f0848";
}

.mdi-youtube-studio::before {
  content: "\f0847";
}

.mdi-youtube-subscription::before {
  content: "\f0d40";
}

.mdi-youtube-tv::before {
  content: "\f0448";
}

.mdi-yurt::before {
  content: "\f1516";
}

.mdi-z-wave::before {
  content: "\f0aea";
}

.mdi-zend::before {
  content: "\f0aeb";
}

.mdi-zigbee::before {
  content: "\f0d41";
}

.mdi-zip-box::before {
  content: "\f05c4";
}

.mdi-zip-box-outline::before {
  content: "\f0ffa";
}

.mdi-zip-disk::before {
  content: "\f0a23";
}

.mdi-zodiac-aquarius::before {
  content: "\f0a7d";
}

.mdi-zodiac-aries::before {
  content: "\f0a7e";
}

.mdi-zodiac-cancer::before {
  content: "\f0a7f";
}

.mdi-zodiac-capricorn::before {
  content: "\f0a80";
}

.mdi-zodiac-gemini::before {
  content: "\f0a81";
}

.mdi-zodiac-leo::before {
  content: "\f0a82";
}

.mdi-zodiac-libra::before {
  content: "\f0a83";
}

.mdi-zodiac-pisces::before {
  content: "\f0a84";
}

.mdi-zodiac-sagittarius::before {
  content: "\f0a85";
}

.mdi-zodiac-scorpio::before {
  content: "\f0a86";
}

.mdi-zodiac-taurus::before {
  content: "\f0a87";
}

.mdi-zodiac-virgo::before {
  content: "\f0a88";
}

.mdi-blank::before {
  content: "\f68c";
  visibility: hidden;
}

.mdi-18px.mdi-set, .mdi-18px.mdi:before {
  font-size: 18px;
}

.mdi-24px.mdi-set, .mdi-24px.mdi:before {
  font-size: 24px;
}

.mdi-36px.mdi-set, .mdi-36px.mdi:before {
  font-size: 36px;
}

.mdi-48px.mdi-set, .mdi-48px.mdi:before {
  font-size: 48px;
}

.mdi-dark:before {
  color: rgba(0, 0, 0, 0.54);
}

.mdi-dark.mdi-inactive:before {
  color: rgba(0, 0, 0, 0.26);
}

.mdi-light:before {
  color: white;
}

.mdi-light.mdi-inactive:before {
  color: rgba(255, 255, 255, 0.3);
}

.mdi-rotate-45 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(45deg);
            transform: scaleX(-1) rotate(45deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: scaleY(-1) rotate(45deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-45:before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.mdi-rotate-90 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(90deg);
            transform: scaleX(-1) rotate(90deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: scaleY(-1) rotate(90deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-90:before {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.mdi-rotate-135 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(135deg);
            transform: scaleX(-1) rotate(135deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(135deg);
            -ms-transform: rotate(135deg);
            transform: scaleY(-1) rotate(135deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-135:before {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}

.mdi-rotate-180 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(180deg);
            transform: scaleX(-1) rotate(180deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: scaleY(-1) rotate(180deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-180:before {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.mdi-rotate-225 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(225deg);
            transform: scaleX(-1) rotate(225deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(225deg);
            -ms-transform: rotate(225deg);
            transform: scaleY(-1) rotate(225deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-225:before {
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
}

.mdi-rotate-270 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(270deg);
            transform: scaleX(-1) rotate(270deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(270deg);
            -ms-transform: rotate(270deg);
            transform: scaleY(-1) rotate(270deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-270:before {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.mdi-rotate-315 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(315deg);
            transform: scaleX(-1) rotate(315deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(315deg);
            -ms-transform: rotate(315deg);
            transform: scaleY(-1) rotate(315deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-315:before {
  -webkit-transform: rotate(315deg);
  -ms-transform: rotate(315deg);
  transform: rotate(315deg);
}

.mdi-flip-h:before {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

.mdi-flip-v:before {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipV;
  -ms-filter: "FlipV";
}

.mdi-spin:before {
  -webkit-animation: mdi-spin 2s infinite linear;
  animation: mdi-spin 2s infinite linear;
}

@-webkit-keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

/*!
 * Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2022 Fonticons, Inc.
 */
.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900);
}

.fa,
.fa-classic,
.fa-sharp,
.fas,
.fa-solid,
.far,
.fa-regular,
.fal,
.fa-light,
.fat,
.fa-thin,
.fad,
.fa-duotone,
.fass,
.fa-sharp-solid,
.fab,
.fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1.6;
  text-rendering: auto;
}

.fas,
.fa-classic,
.fa-solid,
.far,
.fa-regular,
.fal,
.fa-light,
.fat,
.fa-thin {
  font-family: "Font Awesome 6 Pro";
}

.fab,
.fa-brands {
  font-family: "Font Awesome 6 Brands";
}

.fad,
.fa-classic.fa-duotone,
.fa-duotone {
  font-family: "Font Awesome 6 Duotone";
}

.fass,
.fa-sharp {
  font-family: "Font Awesome 6 Sharp";
  font-weight: 900;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-2xs {
  font-size: 0.625em;
  line-height: 0.1em;
  vertical-align: 0.225em;
}

.fa-xs {
  font-size: 0.75em;
  line-height: 0.0833333337em;
  vertical-align: 0.125em;
}

.fa-sm {
  font-size: 0.875em;
  line-height: 0.0714285718em;
  vertical-align: 0.0535714295em;
}

.fa-lg {
  font-size: 1.25em;
  line-height: 0.05em;
  vertical-align: -0.075em;
}

.fa-xl {
  font-size: 1.5em;
  line-height: 0.0416666682em;
  vertical-align: -0.125em;
}

.fa-2xl {
  font-size: 2em;
  line-height: 0.03125em;
  vertical-align: -0.1875em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0;
}
.fa-ul > li {
  position: relative;
}

.fa-li {
  left: calc(-1 * var(--fa-li-width, 2em));
  position: absolute;
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit;
}

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, 0.1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, 0.08em);
  padding: var(--fa-border-padding, 0.2em 0.25em 0.15em);
}

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, 0.3em);
}

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, 0.3em);
}

.fa-beat {
  animation-name: fa-beat;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-bounce {
  animation-name: fa-bounce;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
}

.fa-fade {
  animation-name: fa-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}

.fa-beat-fade {
  animation-name: fa-beat-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}

.fa-flip {
  animation-name: fa-flip;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-shake {
  animation-name: fa-shake;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin {
  animation-name: fa-spin;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 2s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin-reverse {
  --fa-animation-direction: reverse;
}

.fa-pulse,
.fa-spin-pulse {
  animation-name: fa-spin;
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, steps(8));
}

@media (prefers-reduced-motion: reduce) {
  .fa-beat,
  .fa-bounce,
  .fa-fade,
  .fa-beat-fade,
  .fa-flip,
  .fa-pulse,
  .fa-shake,
  .fa-spin,
  .fa-spin-pulse {
    animation-delay: -1ms;
    animation-duration: 1ms;
    animation-iteration-count: 1;
    transition-delay: 0s;
    transition-duration: 0s;
  }
}
@keyframes fa-beat {
  0%, 90% {
    transform: scale(1);
  }
  45% {
    transform: scale(var(--fa-beat-scale, 1.25));
  }
}
@keyframes fa-bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
  }
  30% {
    transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
  }
  50% {
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, 0.4);
  }
}
@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, 0.4);
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(var(--fa-beat-fade-scale, 1.125));
  }
}
@keyframes fa-flip {
  50% {
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}
@keyframes fa-shake {
  0% {
    transform: rotate(-15deg);
  }
  4% {
    transform: rotate(15deg);
  }
  8%, 24% {
    transform: rotate(-18deg);
  }
  12%, 28% {
    transform: rotate(18deg);
  }
  16% {
    transform: rotate(-22deg);
  }
  20% {
    transform: rotate(22deg);
  }
  32% {
    transform: rotate(-12deg);
  }
  36% {
    transform: rotate(12deg);
  }
  40%, 100% {
    transform: rotate(0deg);
  }
}
@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fa-rotate-90 {
  transform: rotate(90deg);
}

.fa-rotate-180 {
  transform: rotate(180deg);
}

.fa-rotate-270 {
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  transform: scale(1, -1);
}

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1, -1);
}

.fa-rotate-by {
  transform: rotate(var(--fa-rotate-angle, 0));
}

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: var(--fa-stack-z-index, auto);
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: var(--fa-inverse, #fff);
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-0::before {
  content: "\30 ";
}

.fa-1::before {
  content: "\31 ";
}

.fa-2::before {
  content: "\32 ";
}

.fa-3::before {
  content: "\33 ";
}

.fa-4::before {
  content: "\34 ";
}

.fa-5::before {
  content: "\35 ";
}

.fa-6::before {
  content: "\36 ";
}

.fa-7::before {
  content: "\37 ";
}

.fa-8::before {
  content: "\38 ";
}

.fa-9::before {
  content: "\39 ";
}

.fa-fill-drip::before {
  content: "\f576";
}

.fa-arrows-to-circle::before {
  content: "\e4bd";
}

.fa-circle-chevron-right::before {
  content: "\f138";
}

.fa-chevron-circle-right::before {
  content: "\f138";
}

.fa-wagon-covered::before {
  content: "\f8ee";
}

.fa-line-height::before {
  content: "\f871";
}

.fa-bagel::before {
  content: "\e3d7";
}

.fa-transporter-7::before {
  content: "\e2a8";
}

.fa-at::before {
  content: "\@";
}

.fa-rectangles-mixed::before {
  content: "\e323";
}

.fa-phone-arrow-up-right::before {
  content: "\e224";
}

.fa-phone-arrow-up::before {
  content: "\e224";
}

.fa-phone-outgoing::before {
  content: "\e224";
}

.fa-trash-can::before {
  content: "\f2ed";
}

.fa-trash-alt::before {
  content: "\f2ed";
}

.fa-circle-l::before {
  content: "\e114";
}

.fa-head-side-goggles::before {
  content: "\f6ea";
}

.fa-head-vr::before {
  content: "\f6ea";
}

.fa-text-height::before {
  content: "\f034";
}

.fa-user-xmark::before {
  content: "\f235";
}

.fa-user-times::before {
  content: "\f235";
}

.fa-face-hand-yawn::before {
  content: "\e379";
}

.fa-gauge-simple-min::before {
  content: "\f62d";
}

.fa-tachometer-slowest::before {
  content: "\f62d";
}

.fa-stethoscope::before {
  content: "\f0f1";
}

.fa-coffin::before {
  content: "\f6c6";
}

.fa-message::before {
  content: "\f27a";
}

.fa-comment-alt::before {
  content: "\f27a";
}

.fa-salad::before {
  content: "\f81e";
}

.fa-bowl-salad::before {
  content: "\f81e";
}

.fa-info::before {
  content: "\f129";
}

.fa-robot-astromech::before {
  content: "\e2d2";
}

.fa-ring-diamond::before {
  content: "\e5ab";
}

.fa-fondue-pot::before {
  content: "\e40d";
}

.fa-theta::before {
  content: "\f69e";
}

.fa-face-hand-peeking::before {
  content: "\e481";
}

.fa-square-user::before {
  content: "\e283";
}

.fa-down-left-and-up-right-to-center::before {
  content: "\f422";
}

.fa-compress-alt::before {
  content: "\f422";
}

.fa-explosion::before {
  content: "\e4e9";
}

.fa-file-lines::before {
  content: "\f15c";
}

.fa-file-alt::before {
  content: "\f15c";
}

.fa-file-text::before {
  content: "\f15c";
}

.fa-wave-square::before {
  content: "\f83e";
}

.fa-ring::before {
  content: "\f70b";
}

.fa-building-un::before {
  content: "\e4d9";
}

.fa-dice-three::before {
  content: "\f527";
}

.fa-tire-pressure-warning::before {
  content: "\f633";
}

.fa-wifi-fair::before {
  content: "\f6ab";
}

.fa-wifi-2::before {
  content: "\f6ab";
}

.fa-calendar-days::before {
  content: "\f073";
}

.fa-calendar-alt::before {
  content: "\f073";
}

.fa-mp3-player::before {
  content: "\f8ce";
}

.fa-anchor-circle-check::before {
  content: "\e4aa";
}

.fa-tally-4::before {
  content: "\e297";
}

.fa-rectangle-history::before {
  content: "\e4a2";
}

.fa-building-circle-arrow-right::before {
  content: "\e4d1";
}

.fa-volleyball::before {
  content: "\f45f";
}

.fa-volleyball-ball::before {
  content: "\f45f";
}

.fa-sun-haze::before {
  content: "\f765";
}

.fa-text-size::before {
  content: "\f894";
}

.fa-ufo::before {
  content: "\e047";
}

.fa-fork::before {
  content: "\f2e3";
}

.fa-utensil-fork::before {
  content: "\f2e3";
}

.fa-arrows-up-to-line::before {
  content: "\e4c2";
}

.fa-mobile-signal::before {
  content: "\e1ef";
}

.fa-barcode-scan::before {
  content: "\f465";
}

.fa-sort-down::before {
  content: "\f0dd";
}

.fa-sort-desc::before {
  content: "\f0dd";
}

.fa-folder-arrow-down::before {
  content: "\e053";
}

.fa-folder-download::before {
  content: "\e053";
}

.fa-circle-minus::before {
  content: "\f056";
}

.fa-minus-circle::before {
  content: "\f056";
}

.fa-face-icicles::before {
  content: "\e37c";
}

.fa-shovel::before {
  content: "\f713";
}

.fa-door-open::before {
  content: "\f52b";
}

.fa-films::before {
  content: "\e17a";
}

.fa-right-from-bracket::before {
  content: "\f2f5";
}

.fa-sign-out-alt::before {
  content: "\f2f5";
}

.fa-face-glasses::before {
  content: "\e377";
}

.fa-nfc::before {
  content: "\e1f7";
}

.fa-atom::before {
  content: "\f5d2";
}

.fa-soap::before {
  content: "\e06e";
}

.fa-icons::before {
  content: "\f86d";
}

.fa-heart-music-camera-bolt::before {
  content: "\f86d";
}

.fa-microphone-lines-slash::before {
  content: "\f539";
}

.fa-microphone-alt-slash::before {
  content: "\f539";
}

.fa-closed-captioning-slash::before {
  content: "\e135";
}

.fa-calculator-simple::before {
  content: "\f64c";
}

.fa-calculator-alt::before {
  content: "\f64c";
}

.fa-bridge-circle-check::before {
  content: "\e4c9";
}

.fa-sliders-up::before {
  content: "\f3f1";
}

.fa-sliders-v::before {
  content: "\f3f1";
}

.fa-location-minus::before {
  content: "\f609";
}

.fa-map-marker-minus::before {
  content: "\f609";
}

.fa-pump-medical::before {
  content: "\e06a";
}

.fa-fingerprint::before {
  content: "\f577";
}

.fa-ski-boot::before {
  content: "\e3cc";
}

.fa-standard-definition::before {
  content: "\e28a";
}

.fa-rectangle-sd::before {
  content: "\e28a";
}

.fa-h1::before {
  content: "\f313";
}

.fa-hand-point-right::before {
  content: "\f0a4";
}

.fa-magnifying-glass-location::before {
  content: "\f689";
}

.fa-search-location::before {
  content: "\f689";
}

.fa-message-bot::before {
  content: "\e3b8";
}

.fa-forward-step::before {
  content: "\f051";
}

.fa-step-forward::before {
  content: "\f051";
}

.fa-face-smile-beam::before {
  content: "\f5b8";
}

.fa-smile-beam::before {
  content: "\f5b8";
}

.fa-light-ceiling::before {
  content: "\e016";
}

.fa-message-exclamation::before {
  content: "\f4a5";
}

.fa-comment-alt-exclamation::before {
  content: "\f4a5";
}

.fa-bowl-scoop::before {
  content: "\e3de";
}

.fa-bowl-shaved-ice::before {
  content: "\e3de";
}

.fa-square-x::before {
  content: "\e286";
}

.fa-utility-pole-double::before {
  content: "\e2c4";
}

.fa-flag-checkered::before {
  content: "\f11e";
}

.fa-chevrons-up::before {
  content: "\f325";
}

.fa-chevron-double-up::before {
  content: "\f325";
}

.fa-football::before {
  content: "\f44e";
}

.fa-football-ball::before {
  content: "\f44e";
}

.fa-user-vneck::before {
  content: "\e461";
}

.fa-school-circle-exclamation::before {
  content: "\e56c";
}

.fa-crop::before {
  content: "\f125";
}

.fa-angles-down::before {
  content: "\f103";
}

.fa-angle-double-down::before {
  content: "\f103";
}

.fa-users-rectangle::before {
  content: "\e594";
}

.fa-people-roof::before {
  content: "\e537";
}

.fa-square-arrow-right::before {
  content: "\f33b";
}

.fa-arrow-square-right::before {
  content: "\f33b";
}

.fa-location-plus::before {
  content: "\f60a";
}

.fa-map-marker-plus::before {
  content: "\f60a";
}

.fa-lightbulb-exclamation-on::before {
  content: "\e1ca";
}

.fa-people-line::before {
  content: "\e534";
}

.fa-beer-mug-empty::before {
  content: "\f0fc";
}

.fa-beer::before {
  content: "\f0fc";
}

.fa-crate-empty::before {
  content: "\e151";
}

.fa-diagram-predecessor::before {
  content: "\e477";
}

.fa-transporter::before {
  content: "\e042";
}

.fa-calendar-circle-user::before {
  content: "\e471";
}

.fa-arrow-up-long::before {
  content: "\f176";
}

.fa-long-arrow-up::before {
  content: "\f176";
}

.fa-person-carry-box::before {
  content: "\f4cf";
}

.fa-person-carry::before {
  content: "\f4cf";
}

.fa-fire-flame-simple::before {
  content: "\f46a";
}

.fa-burn::before {
  content: "\f46a";
}

.fa-person::before {
  content: "\f183";
}

.fa-male::before {
  content: "\f183";
}

.fa-laptop::before {
  content: "\f109";
}

.fa-file-csv::before {
  content: "\f6dd";
}

.fa-menorah::before {
  content: "\f676";
}

.fa-union::before {
  content: "\f6a2";
}

.fa-chevrons-left::before {
  content: "\f323";
}

.fa-chevron-double-left::before {
  content: "\f323";
}

.fa-circle-heart::before {
  content: "\f4c7";
}

.fa-heart-circle::before {
  content: "\f4c7";
}

.fa-truck-plane::before {
  content: "\e58f";
}

.fa-record-vinyl::before {
  content: "\f8d9";
}

.fa-bring-forward::before {
  content: "\f856";
}

.fa-square-p::before {
  content: "\e279";
}

.fa-face-grin-stars::before {
  content: "\f587";
}

.fa-grin-stars::before {
  content: "\f587";
}

.fa-sigma::before {
  content: "\f68b";
}

.fa-camera-movie::before {
  content: "\f8a9";
}

.fa-bong::before {
  content: "\f55c";
}

.fa-clarinet::before {
  content: "\f8ad";
}

.fa-truck-flatbed::before {
  content: "\e2b6";
}

.fa-spaghetti-monster-flying::before {
  content: "\f67b";
}

.fa-pastafarianism::before {
  content: "\f67b";
}

.fa-arrow-down-up-across-line::before {
  content: "\e4af";
}

.fa-leaf-heart::before {
  content: "\f4cb";
}

.fa-house-building::before {
  content: "\e1b1";
}

.fa-cheese-swiss::before {
  content: "\f7f0";
}

.fa-spoon::before {
  content: "\f2e5";
}

.fa-utensil-spoon::before {
  content: "\f2e5";
}

.fa-jar-wheat::before {
  content: "\e517";
}

.fa-envelopes-bulk::before {
  content: "\f674";
}

.fa-mail-bulk::before {
  content: "\f674";
}

.fa-file-circle-exclamation::before {
  content: "\e4eb";
}

.fa-bow-arrow::before {
  content: "\f6b9";
}

.fa-cart-xmark::before {
  content: "\e0dd";
}

.fa-hexagon-xmark::before {
  content: "\f2ee";
}

.fa-times-hexagon::before {
  content: "\f2ee";
}

.fa-xmark-hexagon::before {
  content: "\f2ee";
}

.fa-circle-h::before {
  content: "\f47e";
}

.fa-hospital-symbol::before {
  content: "\f47e";
}

.fa-merge::before {
  content: "\e526";
}

.fa-pager::before {
  content: "\f815";
}

.fa-cart-minus::before {
  content: "\e0db";
}

.fa-address-book::before {
  content: "\f2b9";
}

.fa-contact-book::before {
  content: "\f2b9";
}

.fa-pan-frying::before {
  content: "\e42c";
}

.fa-grid::before {
  content: "\e195";
}

.fa-grid-3::before {
  content: "\e195";
}

.fa-football-helmet::before {
  content: "\f44f";
}

.fa-hand-love::before {
  content: "\e1a5";
}

.fa-trees::before {
  content: "\f724";
}

.fa-strikethrough::before {
  content: "\f0cc";
}

.fa-page::before {
  content: "\e428";
}

.fa-k::before {
  content: "K";
}

.fa-diagram-previous::before {
  content: "\e478";
}

.fa-gauge-min::before {
  content: "\f628";
}

.fa-tachometer-alt-slowest::before {
  content: "\f628";
}

.fa-folder-grid::before {
  content: "\e188";
}

.fa-eggplant::before {
  content: "\e16c";
}

.fa-ram::before {
  content: "\f70a";
}

.fa-landmark-flag::before {
  content: "\e51c";
}

.fa-lips::before {
  content: "\f600";
}

.fa-pencil::before {
  content: "\f303";
}

.fa-pencil-alt::before {
  content: "\f303";
}

.fa-backward::before {
  content: "\f04a";
}

.fa-caret-right::before {
  content: "\f0da";
}

.fa-comments::before {
  content: "\f086";
}

.fa-paste::before {
  content: "\f0ea";
}

.fa-file-clipboard::before {
  content: "\f0ea";
}

.fa-desktop-arrow-down::before {
  content: "\e155";
}

.fa-code-pull-request::before {
  content: "\e13c";
}

.fa-pumpkin::before {
  content: "\f707";
}

.fa-clipboard-list::before {
  content: "\f46d";
}

.fa-pen-field::before {
  content: "\e211";
}

.fa-blueberries::before {
  content: "\e2e8";
}

.fa-truck-ramp-box::before {
  content: "\f4de";
}

.fa-truck-loading::before {
  content: "\f4de";
}

.fa-note::before {
  content: "\e1ff";
}

.fa-arrow-down-to-square::before {
  content: "\e096";
}

.fa-user-check::before {
  content: "\f4fc";
}

.fa-cloud-xmark::before {
  content: "\e35f";
}

.fa-vial-virus::before {
  content: "\e597";
}

.fa-book-blank::before {
  content: "\f5d9";
}

.fa-book-alt::before {
  content: "\f5d9";
}

.fa-golf-flag-hole::before {
  content: "\e3ac";
}

.fa-message-arrow-down::before {
  content: "\e1db";
}

.fa-comment-alt-arrow-down::before {
  content: "\e1db";
}

.fa-face-unamused::before {
  content: "\e39f";
}

.fa-sheet-plastic::before {
  content: "\e571";
}

.fa-circle-9::before {
  content: "\e0f6";
}

.fa-blog::before {
  content: "\f781";
}

.fa-user-ninja::before {
  content: "\f504";
}

.fa-pencil-slash::before {
  content: "\e215";
}

.fa-bowling-pins::before {
  content: "\f437";
}

.fa-person-arrow-up-from-line::before {
  content: "\e539";
}

.fa-down-right::before {
  content: "\e16b";
}

.fa-scroll-torah::before {
  content: "\f6a0";
}

.fa-torah::before {
  content: "\f6a0";
}

.fa-blinds-open::before {
  content: "\f8fc";
}

.fa-fence::before {
  content: "\e303";
}

.fa-up::before {
  content: "\f357";
}

.fa-arrow-alt-up::before {
  content: "\f357";
}

.fa-broom-ball::before {
  content: "\f458";
}

.fa-quidditch::before {
  content: "\f458";
}

.fa-quidditch-broom-ball::before {
  content: "\f458";
}

.fa-drumstick::before {
  content: "\f6d6";
}

.fa-square-v::before {
  content: "\e284";
}

.fa-face-awesome::before {
  content: "\e409";
}

.fa-gave-dandy::before {
  content: "\e409";
}

.fa-dial-off::before {
  content: "\e162";
}

.fa-toggle-off::before {
  content: "\f204";
}

.fa-face-smile-horns::before {
  content: "\e391";
}

.fa-box-archive::before {
  content: "\f187";
}

.fa-archive::before {
  content: "\f187";
}

.fa-grapes::before {
  content: "\e306";
}

.fa-person-drowning::before {
  content: "\e545";
}

.fa-dial-max::before {
  content: "\e15e";
}

.fa-circle-m::before {
  content: "\e115";
}

.fa-calendar-image::before {
  content: "\e0d4";
}

.fa-circle-caret-down::before {
  content: "\f32d";
}

.fa-caret-circle-down::before {
  content: "\f32d";
}

.fa-arrow-down-9-1::before {
  content: "\f886";
}

.fa-sort-numeric-desc::before {
  content: "\f886";
}

.fa-sort-numeric-down-alt::before {
  content: "\f886";
}

.fa-face-grin-tongue-squint::before {
  content: "\f58a";
}

.fa-grin-tongue-squint::before {
  content: "\f58a";
}

.fa-shish-kebab::before {
  content: "\f821";
}

.fa-spray-can::before {
  content: "\f5bd";
}

.fa-alarm-snooze::before {
  content: "\f845";
}

.fa-scarecrow::before {
  content: "\f70d";
}

.fa-truck-monster::before {
  content: "\f63b";
}

.fa-gift-card::before {
  content: "\f663";
}

.fa-w::before {
  content: "W";
}

.fa-code-pull-request-draft::before {
  content: "\e3fa";
}

.fa-square-b::before {
  content: "\e264";
}

.fa-elephant::before {
  content: "\f6da";
}

.fa-earth-africa::before {
  content: "\f57c";
}

.fa-globe-africa::before {
  content: "\f57c";
}

.fa-rainbow::before {
  content: "\f75b";
}

.fa-circle-notch::before {
  content: "\f1ce";
}

.fa-tablet-screen-button::before {
  content: "\f3fa";
}

.fa-tablet-alt::before {
  content: "\f3fa";
}

.fa-paw::before {
  content: "\f1b0";
}

.fa-message-question::before {
  content: "\e1e3";
}

.fa-cloud::before {
  content: "\f0c2";
}

.fa-trowel-bricks::before {
  content: "\e58a";
}

.fa-square-3::before {
  content: "\e258";
}

.fa-face-flushed::before {
  content: "\f579";
}

.fa-flushed::before {
  content: "\f579";
}

.fa-hospital-user::before {
  content: "\f80d";
}

.fa-microwave::before {
  content: "\e01b";
}

.fa-tent-arrow-left-right::before {
  content: "\e57f";
}

.fa-cart-circle-arrow-up::before {
  content: "\e3f0";
}

.fa-trash-clock::before {
  content: "\e2b0";
}

.fa-gavel::before {
  content: "\f0e3";
}

.fa-legal::before {
  content: "\f0e3";
}

.fa-sprinkler-ceiling::before {
  content: "\e44c";
}

.fa-browsers::before {
  content: "\e0cb";
}

.fa-trillium::before {
  content: "\e588";
}

.fa-music-slash::before {
  content: "\f8d1";
}

.fa-truck-ramp::before {
  content: "\f4e0";
}

.fa-binoculars::before {
  content: "\f1e5";
}

.fa-microphone-slash::before {
  content: "\f131";
}

.fa-box-tissue::before {
  content: "\e05b";
}

.fa-circle-c::before {
  content: "\e101";
}

.fa-star-christmas::before {
  content: "\f7d4";
}

.fa-chart-bullet::before {
  content: "\e0e1";
}

.fa-motorcycle::before {
  content: "\f21c";
}

.fa-tree-christmas::before {
  content: "\f7db";
}

.fa-tire-flat::before {
  content: "\f632";
}

.fa-sunglasses::before {
  content: "\f892";
}

.fa-badge::before {
  content: "\f335";
}

.fa-message-pen::before {
  content: "\f4a4";
}

.fa-comment-alt-edit::before {
  content: "\f4a4";
}

.fa-message-edit::before {
  content: "\f4a4";
}

.fa-bell-concierge::before {
  content: "\f562";
}

.fa-concierge-bell::before {
  content: "\f562";
}

.fa-pen-ruler::before {
  content: "\f5ae";
}

.fa-pencil-ruler::before {
  content: "\f5ae";
}

.fa-chess-rook-piece::before {
  content: "\f448";
}

.fa-chess-rook-alt::before {
  content: "\f448";
}

.fa-square-root::before {
  content: "\f697";
}

.fa-album-collection-circle-plus::before {
  content: "\e48e";
}

.fa-people-arrows::before {
  content: "\e068";
}

.fa-people-arrows-left-right::before {
  content: "\e068";
}

.fa-face-angry-horns::before {
  content: "\e368";
}

.fa-mars-and-venus-burst::before {
  content: "\e523";
}

.fa-tombstone::before {
  content: "\f720";
}

.fa-square-caret-right::before {
  content: "\f152";
}

.fa-caret-square-right::before {
  content: "\f152";
}

.fa-scissors::before {
  content: "\f0c4";
}

.fa-cut::before {
  content: "\f0c4";
}

.fa-list-music::before {
  content: "\f8c9";
}

.fa-sun-plant-wilt::before {
  content: "\e57a";
}

.fa-toilets-portable::before {
  content: "\e584";
}

.fa-hockey-puck::before {
  content: "\f453";
}

.fa-mustache::before {
  content: "\e5bc";
}

.fa-hyphen::before {
  content: "\-";
}

.fa-table::before {
  content: "\f0ce";
}

.fa-user-chef::before {
  content: "\e3d2";
}

.fa-message-image::before {
  content: "\e1e0";
}

.fa-comment-alt-image::before {
  content: "\e1e0";
}

.fa-users-medical::before {
  content: "\f830";
}

.fa-sensor-triangle-exclamation::before {
  content: "\e029";
}

.fa-sensor-alert::before {
  content: "\e029";
}

.fa-magnifying-glass-arrow-right::before {
  content: "\e521";
}

.fa-tachograph-digital::before {
  content: "\f566";
}

.fa-digital-tachograph::before {
  content: "\f566";
}

.fa-face-mask::before {
  content: "\e37f";
}

.fa-pickleball::before {
  content: "\e435";
}

.fa-star-sharp-half::before {
  content: "\e28c";
}

.fa-users-slash::before {
  content: "\e073";
}

.fa-clover::before {
  content: "\e139";
}

.fa-meat::before {
  content: "\f814";
}

.fa-reply::before {
  content: "\f3e5";
}

.fa-mail-reply::before {
  content: "\f3e5";
}

.fa-star-and-crescent::before {
  content: "\f699";
}

.fa-empty-set::before {
  content: "\f656";
}

.fa-house-fire::before {
  content: "\e50c";
}

.fa-square-minus::before {
  content: "\f146";
}

.fa-minus-square::before {
  content: "\f146";
}

.fa-helicopter::before {
  content: "\f533";
}

.fa-bird::before {
  content: "\e469";
}

.fa-compass::before {
  content: "\f14e";
}

.fa-square-caret-down::before {
  content: "\f150";
}

.fa-caret-square-down::before {
  content: "\f150";
}

.fa-heart-half-stroke::before {
  content: "\e1ac";
}

.fa-heart-half-alt::before {
  content: "\e1ac";
}

.fa-file-circle-question::before {
  content: "\e4ef";
}

.fa-laptop-code::before {
  content: "\f5fc";
}

.fa-joystick::before {
  content: "\f8c5";
}

.fa-grill-fire::before {
  content: "\e5a4";
}

.fa-rectangle-vertical-history::before {
  content: "\e237";
}

.fa-swatchbook::before {
  content: "\f5c3";
}

.fa-prescription-bottle::before {
  content: "\f485";
}

.fa-bars::before {
  content: "\f0c9";
}

.fa-navicon::before {
  content: "\f0c9";
}

.fa-keyboard-left::before {
  content: "\e1c3";
}

.fa-people-group::before {
  content: "\e533";
}

.fa-hourglass-end::before {
  content: "\f253";
}

.fa-hourglass-3::before {
  content: "\f253";
}

.fa-heart-crack::before {
  content: "\f7a9";
}

.fa-heart-broken::before {
  content: "\f7a9";
}

.fa-face-beam-hand-over-mouth::before {
  content: "\e47c";
}

.fa-droplet-percent::before {
  content: "\f750";
}

.fa-humidity::before {
  content: "\f750";
}

.fa-square-up-right::before {
  content: "\f360";
}

.fa-external-link-square-alt::before {
  content: "\f360";
}

.fa-face-kiss-beam::before {
  content: "\f597";
}

.fa-kiss-beam::before {
  content: "\f597";
}

.fa-corn::before {
  content: "\f6c7";
}

.fa-roller-coaster::before {
  content: "\e324";
}

.fa-photo-film-music::before {
  content: "\e228";
}

.fa-radar::before {
  content: "\e024";
}

.fa-sickle::before {
  content: "\f822";
}

.fa-film::before {
  content: "\f008";
}

.fa-coconut::before {
  content: "\e2f6";
}

.fa-ruler-horizontal::before {
  content: "\f547";
}

.fa-shield-cross::before {
  content: "\f712";
}

.fa-cassette-tape::before {
  content: "\f8ab";
}

.fa-square-terminal::before {
  content: "\e32a";
}

.fa-people-robbery::before {
  content: "\e536";
}

.fa-lightbulb::before {
  content: "\f0eb";
}

.fa-caret-left::before {
  content: "\f0d9";
}

.fa-comment-middle::before {
  content: "\e149";
}

.fa-trash-can-list::before {
  content: "\e2ab";
}

.fa-block::before {
  content: "\e46a";
}

.fa-circle-exclamation::before {
  content: "\f06a";
}

.fa-exclamation-circle::before {
  content: "\f06a";
}

.fa-school-circle-xmark::before {
  content: "\e56d";
}

.fa-arrow-right-from-bracket::before {
  content: "\f08b";
}

.fa-sign-out::before {
  content: "\f08b";
}

.fa-face-frown-slight::before {
  content: "\e376";
}

.fa-circle-chevron-down::before {
  content: "\f13a";
}

.fa-chevron-circle-down::before {
  content: "\f13a";
}

.fa-sidebar-flip::before {
  content: "\e24f";
}

.fa-unlock-keyhole::before {
  content: "\f13e";
}

.fa-unlock-alt::before {
  content: "\f13e";
}

.fa-temperature-list::before {
  content: "\e299";
}

.fa-cloud-showers-heavy::before {
  content: "\f740";
}

.fa-headphones-simple::before {
  content: "\f58f";
}

.fa-headphones-alt::before {
  content: "\f58f";
}

.fa-sitemap::before {
  content: "\f0e8";
}

.fa-pipe-section::before {
  content: "\e438";
}

.fa-space-station-moon-construction::before {
  content: "\e034";
}

.fa-space-station-moon-alt::before {
  content: "\e034";
}

.fa-circle-dollar-to-slot::before {
  content: "\f4b9";
}

.fa-donate::before {
  content: "\f4b9";
}

.fa-memory::before {
  content: "\f538";
}

.fa-face-sleeping::before {
  content: "\e38d";
}

.fa-road-spikes::before {
  content: "\e568";
}

.fa-fire-burner::before {
  content: "\e4f1";
}

.fa-squirrel::before {
  content: "\f71a";
}

.fa-arrow-up-to-line::before {
  content: "\f341";
}

.fa-arrow-to-top::before {
  content: "\f341";
}

.fa-flag::before {
  content: "\f024";
}

.fa-face-cowboy-hat::before {
  content: "\e36e";
}

.fa-hanukiah::before {
  content: "\f6e6";
}

.fa-chart-scatter-3d::before {
  content: "\e0e8";
}

.fa-square-code::before {
  content: "\e267";
}

.fa-feather::before {
  content: "\f52d";
}

.fa-volume-low::before {
  content: "\f027";
}

.fa-volume-down::before {
  content: "\f027";
}

.fa-xmark-to-slot::before {
  content: "\f771";
}

.fa-times-to-slot::before {
  content: "\f771";
}

.fa-vote-nay::before {
  content: "\f771";
}

.fa-box-taped::before {
  content: "\f49a";
}

.fa-box-alt::before {
  content: "\f49a";
}

.fa-comment-slash::before {
  content: "\f4b3";
}

.fa-swords::before {
  content: "\f71d";
}

.fa-cloud-sun-rain::before {
  content: "\f743";
}

.fa-album::before {
  content: "\f89f";
}

.fa-circle-n::before {
  content: "\e118";
}

.fa-compress::before {
  content: "\f066";
}

.fa-wheat-awn::before {
  content: "\e2cd";
}

.fa-wheat-alt::before {
  content: "\e2cd";
}

.fa-ankh::before {
  content: "\f644";
}

.fa-hands-holding-child::before {
  content: "\e4fa";
}

.fa-asterisk::before {
  content: "\*";
}

.fa-key-skeleton-left-right::before {
  content: "\e3b4";
}

.fa-comment-lines::before {
  content: "\f4b0";
}

.fa-luchador-mask::before {
  content: "\f455";
}

.fa-luchador::before {
  content: "\f455";
}

.fa-mask-luchador::before {
  content: "\f455";
}

.fa-square-check::before {
  content: "\f14a";
}

.fa-check-square::before {
  content: "\f14a";
}

.fa-shredder::before {
  content: "\f68a";
}

.fa-book-open-cover::before {
  content: "\e0c0";
}

.fa-book-open-alt::before {
  content: "\e0c0";
}

.fa-sandwich::before {
  content: "\f81f";
}

.fa-peseta-sign::before {
  content: "\e221";
}

.fa-square-parking-slash::before {
  content: "\f617";
}

.fa-parking-slash::before {
  content: "\f617";
}

.fa-train-tunnel::before {
  content: "\e454";
}

.fa-heading::before {
  content: "\f1dc";
}

.fa-header::before {
  content: "\f1dc";
}

.fa-ghost::before {
  content: "\f6e2";
}

.fa-face-anguished::before {
  content: "\e369";
}

.fa-hockey-sticks::before {
  content: "\f454";
}

.fa-abacus::before {
  content: "\f640";
}

.fa-film-simple::before {
  content: "\f3a0";
}

.fa-film-alt::before {
  content: "\f3a0";
}

.fa-list::before {
  content: "\f03a";
}

.fa-list-squares::before {
  content: "\f03a";
}

.fa-tree-palm::before {
  content: "\f82b";
}

.fa-square-phone-flip::before {
  content: "\f87b";
}

.fa-phone-square-alt::before {
  content: "\f87b";
}

.fa-cart-plus::before {
  content: "\f217";
}

.fa-gamepad::before {
  content: "\f11b";
}

.fa-border-center-v::before {
  content: "\f89d";
}

.fa-circle-dot::before {
  content: "\f192";
}

.fa-dot-circle::before {
  content: "\f192";
}

.fa-clipboard-medical::before {
  content: "\e133";
}

.fa-face-dizzy::before {
  content: "\f567";
}

.fa-dizzy::before {
  content: "\f567";
}

.fa-egg::before {
  content: "\f7fb";
}

.fa-up-to-line::before {
  content: "\f34d";
}

.fa-arrow-alt-to-top::before {
  content: "\f34d";
}

.fa-house-medical-circle-xmark::before {
  content: "\e513";
}

.fa-watch-fitness::before {
  content: "\f63e";
}

.fa-clock-nine-thirty::before {
  content: "\e34d";
}

.fa-campground::before {
  content: "\f6bb";
}

.fa-folder-plus::before {
  content: "\f65e";
}

.fa-jug::before {
  content: "\f8c6";
}

.fa-futbol::before {
  content: "\f1e3";
}

.fa-futbol-ball::before {
  content: "\f1e3";
}

.fa-soccer-ball::before {
  content: "\f1e3";
}

.fa-snow-blowing::before {
  content: "\f761";
}

.fa-paintbrush::before {
  content: "\f1fc";
}

.fa-paint-brush::before {
  content: "\f1fc";
}

.fa-lock::before {
  content: "\f023";
}

.fa-arrow-down-from-line::before {
  content: "\f345";
}

.fa-arrow-from-top::before {
  content: "\f345";
}

.fa-gas-pump::before {
  content: "\f52f";
}

.fa-signal-bars-slash::before {
  content: "\f694";
}

.fa-signal-alt-slash::before {
  content: "\f694";
}

.fa-monkey::before {
  content: "\f6fb";
}

.fa-rectangle-pro::before {
  content: "\e235";
}

.fa-pro::before {
  content: "\e235";
}

.fa-house-night::before {
  content: "\e010";
}

.fa-hot-tub-person::before {
  content: "\f593";
}

.fa-hot-tub::before {
  content: "\f593";
}

.fa-blanket::before {
  content: "\f498";
}

.fa-map-location::before {
  content: "\f59f";
}

.fa-map-marked::before {
  content: "\f59f";
}

.fa-house-flood-water::before {
  content: "\e50e";
}

.fa-comments-question-check::before {
  content: "\e14f";
}

.fa-tree::before {
  content: "\f1bb";
}

.fa-arrows-cross::before {
  content: "\e0a2";
}

.fa-backpack::before {
  content: "\f5d4";
}

.fa-square-small::before {
  content: "\e27e";
}

.fa-folder-arrow-up::before {
  content: "\e054";
}

.fa-folder-upload::before {
  content: "\e054";
}

.fa-bridge-lock::before {
  content: "\e4cc";
}

.fa-crosshairs-simple::before {
  content: "\e59f";
}

.fa-sack-dollar::before {
  content: "\f81d";
}

.fa-pen-to-square::before {
  content: "\f044";
}

.fa-edit::before {
  content: "\f044";
}

.fa-square-sliders::before {
  content: "\f3f0";
}

.fa-sliders-h-square::before {
  content: "\f3f0";
}

.fa-car-side::before {
  content: "\f5e4";
}

.fa-message-middle-top::before {
  content: "\e1e2";
}

.fa-comment-middle-top-alt::before {
  content: "\e1e2";
}

.fa-lightbulb-on::before {
  content: "\f672";
}

.fa-knife::before {
  content: "\f2e4";
}

.fa-utensil-knife::before {
  content: "\f2e4";
}

.fa-share-nodes::before {
  content: "\f1e0";
}

.fa-share-alt::before {
  content: "\f1e0";
}

.fa-wave-sine::before {
  content: "\f899";
}

.fa-heart-circle-minus::before {
  content: "\e4ff";
}

.fa-circle-w::before {
  content: "\e12c";
}

.fa-circle-calendar::before {
  content: "\e102";
}

.fa-calendar-circle::before {
  content: "\e102";
}

.fa-hourglass-half::before {
  content: "\f252";
}

.fa-hourglass-2::before {
  content: "\f252";
}

.fa-microscope::before {
  content: "\f610";
}

.fa-sunset::before {
  content: "\f767";
}

.fa-sink::before {
  content: "\e06d";
}

.fa-calendar-exclamation::before {
  content: "\f334";
}

.fa-truck-container-empty::before {
  content: "\e2b5";
}

.fa-hand-heart::before {
  content: "\f4bc";
}

.fa-bag-shopping::before {
  content: "\f290";
}

.fa-shopping-bag::before {
  content: "\f290";
}

.fa-arrow-down-z-a::before {
  content: "\f881";
}

.fa-sort-alpha-desc::before {
  content: "\f881";
}

.fa-sort-alpha-down-alt::before {
  content: "\f881";
}

.fa-mitten::before {
  content: "\f7b5";
}

.fa-reply-clock::before {
  content: "\e239";
}

.fa-reply-time::before {
  content: "\e239";
}

.fa-person-rays::before {
  content: "\e54d";
}

.fa-right::before {
  content: "\f356";
}

.fa-arrow-alt-right::before {
  content: "\f356";
}

.fa-circle-f::before {
  content: "\e10e";
}

.fa-users::before {
  content: "\f0c0";
}

.fa-face-pleading::before {
  content: "\e386";
}

.fa-eye-slash::before {
  content: "\f070";
}

.fa-flask-vial::before {
  content: "\e4f3";
}

.fa-police-box::before {
  content: "\e021";
}

.fa-cucumber::before {
  content: "\e401";
}

.fa-head-side-brain::before {
  content: "\f808";
}

.fa-hand::before {
  content: "\f256";
}

.fa-hand-paper::before {
  content: "\f256";
}

.fa-person-biking-mountain::before {
  content: "\f84b";
}

.fa-biking-mountain::before {
  content: "\f84b";
}

.fa-utensils-slash::before {
  content: "\e464";
}

.fa-print-magnifying-glass::before {
  content: "\f81a";
}

.fa-print-search::before {
  content: "\f81a";
}

.fa-folder-bookmark::before {
  content: "\e186";
}

.fa-om::before {
  content: "\f679";
}

.fa-pi::before {
  content: "\f67e";
}

.fa-flask-round-potion::before {
  content: "\f6e1";
}

.fa-flask-potion::before {
  content: "\f6e1";
}

.fa-face-shush::before {
  content: "\e38c";
}

.fa-worm::before {
  content: "\e599";
}

.fa-house-circle-xmark::before {
  content: "\e50b";
}

.fa-plug::before {
  content: "\f1e6";
}

.fa-calendar-circle-exclamation::before {
  content: "\e46e";
}

.fa-square-i::before {
  content: "\e272";
}

.fa-chevron-up::before {
  content: "\f077";
}

.fa-face-saluting::before {
  content: "\e484";
}

.fa-gauge-simple-low::before {
  content: "\f62c";
}

.fa-tachometer-slow::before {
  content: "\f62c";
}

.fa-face-persevering::before {
  content: "\e385";
}

.fa-circle-camera::before {
  content: "\e103";
}

.fa-camera-circle::before {
  content: "\e103";
}

.fa-hand-spock::before {
  content: "\f259";
}

.fa-spider-web::before {
  content: "\f719";
}

.fa-circle-microphone::before {
  content: "\e116";
}

.fa-microphone-circle::before {
  content: "\e116";
}

.fa-book-arrow-up::before {
  content: "\e0ba";
}

.fa-popsicle::before {
  content: "\e43e";
}

.fa-command::before {
  content: "\e142";
}

.fa-blinds::before {
  content: "\f8fb";
}

.fa-stopwatch::before {
  content: "\f2f2";
}

.fa-saxophone::before {
  content: "\f8dc";
}

.fa-square-2::before {
  content: "\e257";
}

.fa-field-hockey-stick-ball::before {
  content: "\f44c";
}

.fa-field-hockey::before {
  content: "\f44c";
}

.fa-arrow-up-square-triangle::before {
  content: "\f88b";
}

.fa-sort-shapes-up-alt::before {
  content: "\f88b";
}

.fa-face-scream::before {
  content: "\e38b";
}

.fa-square-m::before {
  content: "\e276";
}

.fa-camera-web::before {
  content: "\f832";
}

.fa-webcam::before {
  content: "\f832";
}

.fa-comment-arrow-down::before {
  content: "\e143";
}

.fa-lightbulb-cfl::before {
  content: "\e5a6";
}

.fa-window-frame-open::before {
  content: "\e050";
}

.fa-face-kiss::before {
  content: "\f596";
}

.fa-kiss::before {
  content: "\f596";
}

.fa-bridge-circle-xmark::before {
  content: "\e4cb";
}

.fa-period::before {
  content: "\.";
}

.fa-face-grin-tongue::before {
  content: "\f589";
}

.fa-grin-tongue::before {
  content: "\f589";
}

.fa-up-to-dotted-line::before {
  content: "\e457";
}

.fa-thought-bubble::before {
  content: "\e32e";
}

.fa-raygun::before {
  content: "\e025";
}

.fa-flute::before {
  content: "\f8b9";
}

.fa-acorn::before {
  content: "\f6ae";
}

.fa-video-arrow-up-right::before {
  content: "\e2c9";
}

.fa-grate-droplet::before {
  content: "\e194";
}

.fa-seal-exclamation::before {
  content: "\e242";
}

.fa-chess-bishop::before {
  content: "\f43a";
}

.fa-message-sms::before {
  content: "\e1e5";
}

.fa-coffee-beans::before {
  content: "\e13f";
}

.fa-hat-witch::before {
  content: "\f6e7";
}

.fa-face-grin-wink::before {
  content: "\f58c";
}

.fa-grin-wink::before {
  content: "\f58c";
}

.fa-clock-three-thirty::before {
  content: "\e357";
}

.fa-ear-deaf::before {
  content: "\f2a4";
}

.fa-deaf::before {
  content: "\f2a4";
}

.fa-deafness::before {
  content: "\f2a4";
}

.fa-hard-of-hearing::before {
  content: "\f2a4";
}

.fa-alarm-clock::before {
  content: "\f34e";
}

.fa-eclipse::before {
  content: "\f749";
}

.fa-face-relieved::before {
  content: "\e389";
}

.fa-road-circle-check::before {
  content: "\e564";
}

.fa-dice-five::before {
  content: "\f523";
}

.fa-octagon-minus::before {
  content: "\f308";
}

.fa-minus-octagon::before {
  content: "\f308";
}

.fa-square-rss::before {
  content: "\f143";
}

.fa-rss-square::before {
  content: "\f143";
}

.fa-face-zany::before {
  content: "\e3a4";
}

.fa-tricycle::before {
  content: "\e5c3";
}

.fa-land-mine-on::before {
  content: "\e51b";
}

.fa-square-arrow-up-left::before {
  content: "\e263";
}

.fa-i-cursor::before {
  content: "\f246";
}

.fa-salt-shaker::before {
  content: "\e446";
}

.fa-stamp::before {
  content: "\f5bf";
}

.fa-file-plus::before {
  content: "\f319";
}

.fa-draw-square::before {
  content: "\f5ef";
}

.fa-toilet-paper-under-slash::before {
  content: "\e2a1";
}

.fa-toilet-paper-reverse-slash::before {
  content: "\e2a1";
}

.fa-stairs::before {
  content: "\e289";
}

.fa-drone-front::before {
  content: "\f860";
}

.fa-drone-alt::before {
  content: "\f860";
}

.fa-glass-empty::before {
  content: "\e191";
}

.fa-dial-high::before {
  content: "\e15c";
}

.fa-user-helmet-safety::before {
  content: "\f82c";
}

.fa-user-construction::before {
  content: "\f82c";
}

.fa-user-hard-hat::before {
  content: "\f82c";
}

.fa-i::before {
  content: "I";
}

.fa-hryvnia-sign::before {
  content: "\f6f2";
}

.fa-hryvnia::before {
  content: "\f6f2";
}

.fa-arrow-down-left-and-arrow-up-right-to-center::before {
  content: "\e092";
}

.fa-pills::before {
  content: "\f484";
}

.fa-face-grin-wide::before {
  content: "\f581";
}

.fa-grin-alt::before {
  content: "\f581";
}

.fa-tooth::before {
  content: "\f5c9";
}

.fa-basketball-hoop::before {
  content: "\f435";
}

.fa-objects-align-bottom::before {
  content: "\e3bb";
}

.fa-v::before {
  content: "V";
}

.fa-sparkles::before {
  content: "\f890";
}

.fa-squid::before {
  content: "\e450";
}

.fa-leafy-green::before {
  content: "\e41d";
}

.fa-circle-arrow-up-right::before {
  content: "\e0fc";
}

.fa-calendars::before {
  content: "\e0d7";
}

.fa-bangladeshi-taka-sign::before {
  content: "\e2e6";
}

.fa-bicycle::before {
  content: "\f206";
}

.fa-hammer-war::before {
  content: "\f6e4";
}

.fa-circle-d::before {
  content: "\e104";
}

.fa-spider-black-widow::before {
  content: "\f718";
}

.fa-staff-snake::before {
  content: "\e579";
}

.fa-rod-asclepius::before {
  content: "\e579";
}

.fa-rod-snake::before {
  content: "\e579";
}

.fa-staff-aesculapius::before {
  content: "\e579";
}

.fa-pear::before {
  content: "\e20c";
}

.fa-head-side-cough-slash::before {
  content: "\e062";
}

.fa-triangle::before {
  content: "\f2ec";
}

.fa-apartment::before {
  content: "\e468";
}

.fa-truck-medical::before {
  content: "\f0f9";
}

.fa-ambulance::before {
  content: "\f0f9";
}

.fa-pepper::before {
  content: "\e432";
}

.fa-piano::before {
  content: "\f8d4";
}

.fa-gun-squirt::before {
  content: "\e19d";
}

.fa-wheat-awn-circle-exclamation::before {
  content: "\e598";
}

.fa-snowman::before {
  content: "\f7d0";
}

.fa-user-alien::before {
  content: "\e04a";
}

.fa-shield-check::before {
  content: "\f2f7";
}

.fa-mortar-pestle::before {
  content: "\f5a7";
}

.fa-road-barrier::before {
  content: "\e562";
}

.fa-chart-candlestick::before {
  content: "\e0e2";
}

.fa-briefcase-blank::before {
  content: "\e0c8";
}

.fa-school::before {
  content: "\f549";
}

.fa-igloo::before {
  content: "\f7ae";
}

.fa-bracket-round::before {
  content: "\(";
}

.fa-parenthesis::before {
  content: "\(";
}

.fa-joint::before {
  content: "\f595";
}

.fa-horse-saddle::before {
  content: "\f8c3";
}

.fa-mug-marshmallows::before {
  content: "\f7b7";
}

.fa-filters::before {
  content: "\e17e";
}

.fa-bell-on::before {
  content: "\f8fa";
}

.fa-angle-right::before {
  content: "\f105";
}

.fa-dial-med::before {
  content: "\e15f";
}

.fa-horse::before {
  content: "\f6f0";
}

.fa-q::before {
  content: "Q";
}

.fa-monitor-waveform::before {
  content: "\f611";
}

.fa-monitor-heart-rate::before {
  content: "\f611";
}

.fa-link-simple::before {
  content: "\e1cd";
}

.fa-whistle::before {
  content: "\f460";
}

.fa-g::before {
  content: "G";
}

.fa-wine-glass-crack::before {
  content: "\f4bb";
}

.fa-fragile::before {
  content: "\f4bb";
}

.fa-slot-machine::before {
  content: "\e3ce";
}

.fa-notes-medical::before {
  content: "\f481";
}

.fa-car-wash::before {
  content: "\f5e6";
}

.fa-escalator::before {
  content: "\e171";
}

.fa-comment-image::before {
  content: "\e148";
}

.fa-temperature-half::before {
  content: "\f2c9";
}

.fa-temperature-2::before {
  content: "\f2c9";
}

.fa-thermometer-2::before {
  content: "\f2c9";
}

.fa-thermometer-half::before {
  content: "\f2c9";
}

.fa-dong-sign::before {
  content: "\e169";
}

.fa-donut::before {
  content: "\e406";
}

.fa-doughnut::before {
  content: "\e406";
}

.fa-capsules::before {
  content: "\f46b";
}

.fa-poo-storm::before {
  content: "\f75a";
}

.fa-poo-bolt::before {
  content: "\f75a";
}

.fa-tally-1::before {
  content: "\e294";
}

.fa-face-frown-open::before {
  content: "\f57a";
}

.fa-frown-open::before {
  content: "\f57a";
}

.fa-square-dashed::before {
  content: "\e269";
}

.fa-square-j::before {
  content: "\e273";
}

.fa-hand-point-up::before {
  content: "\f0a6";
}

.fa-money-bill::before {
  content: "\f0d6";
}

.fa-arrow-up-big-small::before {
  content: "\f88e";
}

.fa-sort-size-up::before {
  content: "\f88e";
}

.fa-barcode-read::before {
  content: "\f464";
}

.fa-baguette::before {
  content: "\e3d8";
}

.fa-bowl-soft-serve::before {
  content: "\e46b";
}

.fa-face-holding-back-tears::before {
  content: "\e482";
}

.fa-square-up::before {
  content: "\f353";
}

.fa-arrow-alt-square-up::before {
  content: "\f353";
}

.fa-train-subway-tunnel::before {
  content: "\e2a3";
}

.fa-subway-tunnel::before {
  content: "\e2a3";
}

.fa-square-exclamation::before {
  content: "\f321";
}

.fa-exclamation-square::before {
  content: "\f321";
}

.fa-semicolon::before {
  content: "\;";
}

.fa-bookmark::before {
  content: "\f02e";
}

.fa-fan-table::before {
  content: "\e004";
}

.fa-align-justify::before {
  content: "\f039";
}

.fa-battery-low::before {
  content: "\e0b1";
}

.fa-battery-1::before {
  content: "\e0b1";
}

.fa-credit-card-front::before {
  content: "\f38a";
}

.fa-brain-arrow-curved-right::before {
  content: "\f677";
}

.fa-mind-share::before {
  content: "\f677";
}

.fa-umbrella-beach::before {
  content: "\f5ca";
}

.fa-helmet-un::before {
  content: "\e503";
}

.fa-location-smile::before {
  content: "\f60d";
}

.fa-map-marker-smile::before {
  content: "\f60d";
}

.fa-arrow-left-to-line::before {
  content: "\f33e";
}

.fa-arrow-to-left::before {
  content: "\f33e";
}

.fa-bullseye::before {
  content: "\f140";
}

.fa-sushi::before {
  content: "\e48a";
}

.fa-nigiri::before {
  content: "\e48a";
}

.fa-message-captions::before {
  content: "\e1de";
}

.fa-comment-alt-captions::before {
  content: "\e1de";
}

.fa-trash-list::before {
  content: "\e2b1";
}

.fa-bacon::before {
  content: "\f7e5";
}

.fa-option::before {
  content: "\e318";
}

.fa-hand-point-down::before {
  content: "\f0a7";
}

.fa-arrow-up-from-bracket::before {
  content: "\e09a";
}

.fa-trash-plus::before {
  content: "\e2b2";
}

.fa-objects-align-top::before {
  content: "\e3c0";
}

.fa-folder::before {
  content: "\f07b";
}

.fa-folder-blank::before {
  content: "\f07b";
}

.fa-face-anxious-sweat::before {
  content: "\e36a";
}

.fa-credit-card-blank::before {
  content: "\f389";
}

.fa-file-waveform::before {
  content: "\f478";
}

.fa-file-medical-alt::before {
  content: "\f478";
}

.fa-microchip-ai::before {
  content: "\e1ec";
}

.fa-mug::before {
  content: "\f874";
}

.fa-plane-up-slash::before {
  content: "\e22e";
}

.fa-radiation::before {
  content: "\f7b9";
}

.fa-pen-circle::before {
  content: "\e20e";
}

.fa-chart-simple::before {
  content: "\e473";
}

.fa-crutches::before {
  content: "\f7f8";
}

.fa-circle-parking::before {
  content: "\f615";
}

.fa-parking-circle::before {
  content: "\f615";
}

.fa-mars-stroke::before {
  content: "\f229";
}

.fa-leaf-oak::before {
  content: "\f6f7";
}

.fa-square-bolt::before {
  content: "\e265";
}

.fa-vial::before {
  content: "\f492";
}

.fa-gauge::before {
  content: "\f624";
}

.fa-dashboard::before {
  content: "\f624";
}

.fa-gauge-med::before {
  content: "\f624";
}

.fa-tachometer-alt-average::before {
  content: "\f624";
}

.fa-wand-magic-sparkles::before {
  content: "\e2ca";
}

.fa-magic-wand-sparkles::before {
  content: "\e2ca";
}

.fa-lambda::before {
  content: "\f66e";
}

.fa-e::before {
  content: "E";
}

.fa-pizza::before {
  content: "\f817";
}

.fa-bowl-chopsticks-noodles::before {
  content: "\e2ea";
}

.fa-h3::before {
  content: "\f315";
}

.fa-pen-clip::before {
  content: "\f305";
}

.fa-pen-alt::before {
  content: "\f305";
}

.fa-bridge-circle-exclamation::before {
  content: "\e4ca";
}

.fa-badge-percent::before {
  content: "\f646";
}

.fa-user::before {
  content: "\f007";
}

.fa-sensor::before {
  content: "\e028";
}

.fa-comma::before {
  content: "\,";
}

.fa-school-circle-check::before {
  content: "\e56b";
}

.fa-toilet-paper-under::before {
  content: "\e2a0";
}

.fa-toilet-paper-reverse::before {
  content: "\e2a0";
}

.fa-light-emergency::before {
  content: "\e41f";
}

.fa-arrow-down-to-arc::before {
  content: "\e4ae";
}

.fa-dumpster::before {
  content: "\f793";
}

.fa-van-shuttle::before {
  content: "\f5b6";
}

.fa-shuttle-van::before {
  content: "\f5b6";
}

.fa-building-user::before {
  content: "\e4da";
}

.fa-light-switch::before {
  content: "\e017";
}

.fa-square-caret-left::before {
  content: "\f191";
}

.fa-caret-square-left::before {
  content: "\f191";
}

.fa-highlighter::before {
  content: "\f591";
}

.fa-wave-pulse::before {
  content: "\f5f8";
}

.fa-heart-rate::before {
  content: "\f5f8";
}

.fa-key::before {
  content: "\f084";
}

.fa-hat-santa::before {
  content: "\f7a7";
}

.fa-tamale::before {
  content: "\e451";
}

.fa-box-check::before {
  content: "\f467";
}

.fa-bullhorn::before {
  content: "\f0a1";
}

.fa-steak::before {
  content: "\f824";
}

.fa-location-crosshairs-slash::before {
  content: "\f603";
}

.fa-location-slash::before {
  content: "\f603";
}

.fa-person-dolly::before {
  content: "\f4d0";
}

.fa-globe::before {
  content: "\f0ac";
}

.fa-synagogue::before {
  content: "\f69b";
}

.fa-file-chart-column::before {
  content: "\f659";
}

.fa-file-chart-line::before {
  content: "\f659";
}

.fa-person-half-dress::before {
  content: "\e548";
}

.fa-folder-image::before {
  content: "\e18a";
}

.fa-calendar-pen::before {
  content: "\f333";
}

.fa-calendar-edit::before {
  content: "\f333";
}

.fa-road-bridge::before {
  content: "\e563";
}

.fa-face-smile-tear::before {
  content: "\e393";
}

.fa-message-plus::before {
  content: "\f4a8";
}

.fa-comment-alt-plus::before {
  content: "\f4a8";
}

.fa-location-arrow::before {
  content: "\f124";
}

.fa-c::before {
  content: "C";
}

.fa-tablet-button::before {
  content: "\f10a";
}

.fa-rectangle-history-circle-user::before {
  content: "\e4a4";
}

.fa-building-lock::before {
  content: "\e4d6";
}

.fa-chart-line-up::before {
  content: "\e0e5";
}

.fa-mailbox::before {
  content: "\f813";
}

.fa-truck-bolt::before {
  content: "\e3d0";
}

.fa-pizza-slice::before {
  content: "\f818";
}

.fa-money-bill-wave::before {
  content: "\f53a";
}

.fa-chart-area::before {
  content: "\f1fe";
}

.fa-area-chart::before {
  content: "\f1fe";
}

.fa-house-flag::before {
  content: "\e50d";
}

.fa-person-circle-minus::before {
  content: "\e540";
}

.fa-scalpel::before {
  content: "\f61d";
}

.fa-ban::before {
  content: "\f05e";
}

.fa-cancel::before {
  content: "\f05e";
}

.fa-bell-exclamation::before {
  content: "\f848";
}

.fa-circle-bookmark::before {
  content: "\e100";
}

.fa-bookmark-circle::before {
  content: "\e100";
}

.fa-egg-fried::before {
  content: "\f7fc";
}

.fa-face-weary::before {
  content: "\e3a1";
}

.fa-uniform-martial-arts::before {
  content: "\e3d1";
}

.fa-camera-rotate::before {
  content: "\e0d8";
}

.fa-sun-dust::before {
  content: "\f764";
}

.fa-comment-text::before {
  content: "\e14d";
}

.fa-spray-can-sparkles::before {
  content: "\f5d0";
}

.fa-air-freshener::before {
  content: "\f5d0";
}

.fa-signal-bars::before {
  content: "\f690";
}

.fa-signal-alt::before {
  content: "\f690";
}

.fa-signal-alt-4::before {
  content: "\f690";
}

.fa-signal-bars-strong::before {
  content: "\f690";
}

.fa-diamond-exclamation::before {
  content: "\e405";
}

.fa-star::before {
  content: "\f005";
}

.fa-dial-min::before {
  content: "\e161";
}

.fa-repeat::before {
  content: "\f363";
}

.fa-cross::before {
  content: "\f654";
}

.fa-page-caret-down::before {
  content: "\e429";
}

.fa-file-caret-down::before {
  content: "\e429";
}

.fa-box::before {
  content: "\f466";
}

.fa-venus-mars::before {
  content: "\f228";
}

.fa-clock-seven-thirty::before {
  content: "\e351";
}

.fa-arrow-pointer::before {
  content: "\f245";
}

.fa-mouse-pointer::before {
  content: "\f245";
}

.fa-clock-four-thirty::before {
  content: "\e34b";
}

.fa-signal-bars-good::before {
  content: "\f693";
}

.fa-signal-alt-3::before {
  content: "\f693";
}

.fa-cactus::before {
  content: "\f8a7";
}

.fa-maximize::before {
  content: "\f31e";
}

.fa-expand-arrows-alt::before {
  content: "\f31e";
}

.fa-charging-station::before {
  content: "\f5e7";
}

.fa-shapes::before {
  content: "\f61f";
}

.fa-triangle-circle-square::before {
  content: "\f61f";
}

.fa-plane-tail::before {
  content: "\e22c";
}

.fa-gauge-simple-max::before {
  content: "\f62b";
}

.fa-tachometer-fastest::before {
  content: "\f62b";
}

.fa-circle-u::before {
  content: "\e127";
}

.fa-shield-slash::before {
  content: "\e24b";
}

.fa-square-phone-hangup::before {
  content: "\e27a";
}

.fa-phone-square-down::before {
  content: "\e27a";
}

.fa-arrow-up-left::before {
  content: "\e09d";
}

.fa-transporter-1::before {
  content: "\e043";
}

.fa-peanuts::before {
  content: "\e431";
}

.fa-shuffle::before {
  content: "\f074";
}

.fa-random::before {
  content: "\f074";
}

.fa-person-running::before {
  content: "\f70c";
}

.fa-running::before {
  content: "\f70c";
}

.fa-mobile-retro::before {
  content: "\e527";
}

.fa-grip-lines-vertical::before {
  content: "\f7a5";
}

.fa-arrow-up-from-square::before {
  content: "\e09c";
}

.fa-file-dashed-line::before {
  content: "\f877";
}

.fa-page-break::before {
  content: "\f877";
}

.fa-bracket-curly-right::before {
  content: "\}";
}

.fa-spider::before {
  content: "\f717";
}

.fa-clock-three::before {
  content: "\e356";
}

.fa-hands-bound::before {
  content: "\e4f9";
}

.fa-scalpel-line-dashed::before {
  content: "\f61e";
}

.fa-scalpel-path::before {
  content: "\f61e";
}

.fa-file-invoice-dollar::before {
  content: "\f571";
}

.fa-pipe-smoking::before {
  content: "\e3c4";
}

.fa-face-astonished::before {
  content: "\e36b";
}

.fa-window::before {
  content: "\f40e";
}

.fa-plane-circle-exclamation::before {
  content: "\e556";
}

.fa-ear::before {
  content: "\f5f0";
}

.fa-file-lock::before {
  content: "\e3a6";
}

.fa-diagram-venn::before {
  content: "\e15a";
}

.fa-x-ray::before {
  content: "\f497";
}

.fa-goal-net::before {
  content: "\e3ab";
}

.fa-coffin-cross::before {
  content: "\e051";
}

.fa-spell-check::before {
  content: "\f891";
}

.fa-location-xmark::before {
  content: "\f60e";
}

.fa-map-marker-times::before {
  content: "\f60e";
}

.fa-map-marker-xmark::before {
  content: "\f60e";
}

.fa-lasso::before {
  content: "\f8c8";
}

.fa-slash::before {
  content: "\f715";
}

.fa-person-to-portal::before {
  content: "\e022";
}

.fa-portal-enter::before {
  content: "\e022";
}

.fa-calendar-star::before {
  content: "\f736";
}

.fa-computer-mouse::before {
  content: "\f8cc";
}

.fa-mouse::before {
  content: "\f8cc";
}

.fa-arrow-right-to-bracket::before {
  content: "\f090";
}

.fa-sign-in::before {
  content: "\f090";
}

.fa-pegasus::before {
  content: "\f703";
}

.fa-files-medical::before {
  content: "\f7fd";
}

.fa-nfc-lock::before {
  content: "\e1f8";
}

.fa-person-ski-lift::before {
  content: "\f7c8";
}

.fa-ski-lift::before {
  content: "\f7c8";
}

.fa-square-6::before {
  content: "\e25b";
}

.fa-shop-slash::before {
  content: "\e070";
}

.fa-store-alt-slash::before {
  content: "\e070";
}

.fa-wind-turbine::before {
  content: "\f89b";
}

.fa-sliders-simple::before {
  content: "\e253";
}

.fa-badge-sheriff::before {
  content: "\f8a2";
}

.fa-server::before {
  content: "\f233";
}

.fa-virus-covid-slash::before {
  content: "\e4a9";
}

.fa-intersection::before {
  content: "\f668";
}

.fa-shop-lock::before {
  content: "\e4a5";
}

.fa-family::before {
  content: "\e300";
}

.fa-hourglass-start::before {
  content: "\f251";
}

.fa-hourglass-1::before {
  content: "\f251";
}

.fa-user-hair-buns::before {
  content: "\e3d3";
}

.fa-blender-phone::before {
  content: "\f6b6";
}

.fa-hourglass-clock::before {
  content: "\e41b";
}

.fa-person-seat-reclined::before {
  content: "\e21f";
}

.fa-paper-plane-top::before {
  content: "\e20a";
}

.fa-paper-plane-alt::before {
  content: "\e20a";
}

.fa-send::before {
  content: "\e20a";
}

.fa-message-arrow-up::before {
  content: "\e1dc";
}

.fa-comment-alt-arrow-up::before {
  content: "\e1dc";
}

.fa-lightbulb-exclamation::before {
  content: "\f671";
}

.fa-layer-minus::before {
  content: "\f5fe";
}

.fa-layer-group-minus::before {
  content: "\f5fe";
}

.fa-circle-e::before {
  content: "\e109";
}

.fa-building-wheat::before {
  content: "\e4db";
}

.fa-gauge-max::before {
  content: "\f626";
}

.fa-tachometer-alt-fastest::before {
  content: "\f626";
}

.fa-person-breastfeeding::before {
  content: "\e53a";
}

.fa-apostrophe::before {
  content: "\'";
}

.fa-fire-hydrant::before {
  content: "\e17f";
}

.fa-right-to-bracket::before {
  content: "\f2f6";
}

.fa-sign-in-alt::before {
  content: "\f2f6";
}

.fa-video-plus::before {
  content: "\f4e1";
}

.fa-square-right::before {
  content: "\f352";
}

.fa-arrow-alt-square-right::before {
  content: "\f352";
}

.fa-comment-smile::before {
  content: "\f4b4";
}

.fa-venus::before {
  content: "\f221";
}

.fa-passport::before {
  content: "\f5ab";
}

.fa-inbox-in::before {
  content: "\f310";
}

.fa-inbox-arrow-down::before {
  content: "\f310";
}

.fa-heart-pulse::before {
  content: "\f21e";
}

.fa-heartbeat::before {
  content: "\f21e";
}

.fa-circle-8::before {
  content: "\e0f5";
}

.fa-clouds-moon::before {
  content: "\f745";
}

.fa-clock-ten-thirty::before {
  content: "\e355";
}

.fa-people-carry-box::before {
  content: "\f4ce";
}

.fa-people-carry::before {
  content: "\f4ce";
}

.fa-folder-user::before {
  content: "\e18e";
}

.fa-trash-can-xmark::before {
  content: "\e2ae";
}

.fa-temperature-high::before {
  content: "\f769";
}

.fa-microchip::before {
  content: "\f2db";
}

.fa-left-long-to-line::before {
  content: "\e41e";
}

.fa-crown::before {
  content: "\f521";
}

.fa-weight-hanging::before {
  content: "\f5cd";
}

.fa-xmarks-lines::before {
  content: "\e59a";
}

.fa-file-prescription::before {
  content: "\f572";
}

.fa-calendar-range::before {
  content: "\e0d6";
}

.fa-flower-daffodil::before {
  content: "\f800";
}

.fa-hand-back-point-up::before {
  content: "\e1a2";
}

.fa-weight-scale::before {
  content: "\f496";
}

.fa-weight::before {
  content: "\f496";
}

.fa-star-exclamation::before {
  content: "\f2f3";
}

.fa-books::before {
  content: "\f5db";
}

.fa-user-group::before {
  content: "\f500";
}

.fa-user-friends::before {
  content: "\f500";
}

.fa-arrow-up-a-z::before {
  content: "\f15e";
}

.fa-sort-alpha-up::before {
  content: "\f15e";
}

.fa-layer-plus::before {
  content: "\f5ff";
}

.fa-layer-group-plus::before {
  content: "\f5ff";
}

.fa-play-pause::before {
  content: "\e22f";
}

.fa-block-question::before {
  content: "\e3dd";
}

.fa-snooze::before {
  content: "\f880";
}

.fa-zzz::before {
  content: "\f880";
}

.fa-scanner-image::before {
  content: "\f8f3";
}

.fa-tv-retro::before {
  content: "\f401";
}

.fa-square-t::before {
  content: "\e280";
}

.fa-farm::before {
  content: "\f864";
}

.fa-barn-silo::before {
  content: "\f864";
}

.fa-chess-knight::before {
  content: "\f441";
}

.fa-bars-sort::before {
  content: "\e0ae";
}

.fa-pallet-boxes::before {
  content: "\f483";
}

.fa-palette-boxes::before {
  content: "\f483";
}

.fa-pallet-alt::before {
  content: "\f483";
}

.fa-face-laugh-squint::before {
  content: "\f59b";
}

.fa-laugh-squint::before {
  content: "\f59b";
}

.fa-code-simple::before {
  content: "\e13d";
}

.fa-bolt-slash::before {
  content: "\e0b8";
}

.fa-panel-fire::before {
  content: "\e42f";
}

.fa-binary-circle-check::before {
  content: "\e33c";
}

.fa-comment-minus::before {
  content: "\f4b1";
}

.fa-burrito::before {
  content: "\f7ed";
}

.fa-violin::before {
  content: "\f8ed";
}

.fa-objects-column::before {
  content: "\e3c1";
}

.fa-square-chevron-down::before {
  content: "\f329";
}

.fa-chevron-square-down::before {
  content: "\f329";
}

.fa-comment-plus::before {
  content: "\f4b2";
}

.fa-triangle-instrument::before {
  content: "\f8e2";
}

.fa-triangle-music::before {
  content: "\f8e2";
}

.fa-wheelchair::before {
  content: "\f193";
}

.fa-user-pilot-tie::before {
  content: "\e2c1";
}

.fa-piano-keyboard::before {
  content: "\f8d5";
}

.fa-bed-empty::before {
  content: "\f8f9";
}

.fa-circle-arrow-up::before {
  content: "\f0aa";
}

.fa-arrow-circle-up::before {
  content: "\f0aa";
}

.fa-toggle-on::before {
  content: "\f205";
}

.fa-rectangle-vertical::before {
  content: "\f2fb";
}

.fa-rectangle-portrait::before {
  content: "\f2fb";
}

.fa-person-walking::before {
  content: "\f554";
}

.fa-walking::before {
  content: "\f554";
}

.fa-l::before {
  content: "L";
}

.fa-signal-stream::before {
  content: "\f8dd";
}

.fa-down-to-bracket::before {
  content: "\e4e7";
}

.fa-circle-z::before {
  content: "\e130";
}

.fa-stars::before {
  content: "\f762";
}

.fa-fire::before {
  content: "\f06d";
}

.fa-bed-pulse::before {
  content: "\f487";
}

.fa-procedures::before {
  content: "\f487";
}

.fa-house-day::before {
  content: "\e00e";
}

.fa-shuttle-space::before {
  content: "\f197";
}

.fa-space-shuttle::before {
  content: "\f197";
}

.fa-shirt-long-sleeve::before {
  content: "\e3c7";
}

.fa-chart-pie-simple::before {
  content: "\f64e";
}

.fa-chart-pie-alt::before {
  content: "\f64e";
}

.fa-face-laugh::before {
  content: "\f599";
}

.fa-laugh::before {
  content: "\f599";
}

.fa-folder-open::before {
  content: "\f07c";
}

.fa-album-collection-circle-user::before {
  content: "\e48f";
}

.fa-candy::before {
  content: "\e3e7";
}

.fa-bowl-hot::before {
  content: "\f823";
}

.fa-soup::before {
  content: "\f823";
}

.fa-flatbread::before {
  content: "\e40b";
}

.fa-heart-circle-plus::before {
  content: "\e500";
}

.fa-code-fork::before {
  content: "\e13b";
}

.fa-city::before {
  content: "\f64f";
}

.fa-signal-bars-weak::before {
  content: "\f691";
}

.fa-signal-alt-1::before {
  content: "\f691";
}

.fa-microphone-lines::before {
  content: "\f3c9";
}

.fa-microphone-alt::before {
  content: "\f3c9";
}

.fa-clock-twelve::before {
  content: "\e358";
}

.fa-pepper-hot::before {
  content: "\f816";
}

.fa-citrus-slice::before {
  content: "\e2f5";
}

.fa-sheep::before {
  content: "\f711";
}

.fa-unlock::before {
  content: "\f09c";
}

.fa-colon-sign::before {
  content: "\e140";
}

.fa-headset::before {
  content: "\f590";
}

.fa-badger-honey::before {
  content: "\f6b4";
}

.fa-h4::before {
  content: "\f86a";
}

.fa-store-slash::before {
  content: "\e071";
}

.fa-road-circle-xmark::before {
  content: "\e566";
}

.fa-signal-slash::before {
  content: "\f695";
}

.fa-user-minus::before {
  content: "\f503";
}

.fa-mars-stroke-up::before {
  content: "\f22a";
}

.fa-mars-stroke-v::before {
  content: "\f22a";
}

.fa-champagne-glasses::before {
  content: "\f79f";
}

.fa-glass-cheers::before {
  content: "\f79f";
}

.fa-taco::before {
  content: "\f826";
}

.fa-hexagon-plus::before {
  content: "\f300";
}

.fa-plus-hexagon::before {
  content: "\f300";
}

.fa-clipboard::before {
  content: "\f328";
}

.fa-house-circle-exclamation::before {
  content: "\e50a";
}

.fa-file-arrow-up::before {
  content: "\f574";
}

.fa-file-upload::before {
  content: "\f574";
}

.fa-wifi::before {
  content: "\f1eb";
}

.fa-wifi-3::before {
  content: "\f1eb";
}

.fa-wifi-strong::before {
  content: "\f1eb";
}

.fa-messages::before {
  content: "\f4b6";
}

.fa-comments-alt::before {
  content: "\f4b6";
}

.fa-bath::before {
  content: "\f2cd";
}

.fa-bathtub::before {
  content: "\f2cd";
}

.fa-umbrella-simple::before {
  content: "\e2bc";
}

.fa-umbrella-alt::before {
  content: "\e2bc";
}

.fa-rectangle-history-circle-plus::before {
  content: "\e4a3";
}

.fa-underline::before {
  content: "\f0cd";
}

.fa-prescription-bottle-pill::before {
  content: "\e5c0";
}

.fa-user-pen::before {
  content: "\f4ff";
}

.fa-user-edit::before {
  content: "\f4ff";
}

.fa-binary-slash::before {
  content: "\e33e";
}

.fa-square-o::before {
  content: "\e278";
}

.fa-signature::before {
  content: "\f5b7";
}

.fa-stroopwafel::before {
  content: "\f551";
}

.fa-bold::before {
  content: "\f032";
}

.fa-anchor-lock::before {
  content: "\e4ad";
}

.fa-building-ngo::before {
  content: "\e4d7";
}

.fa-transporter-3::before {
  content: "\e045";
}

.fa-engine-warning::before {
  content: "\f5f2";
}

.fa-engine-exclamation::before {
  content: "\f5f2";
}

.fa-circle-down-right::before {
  content: "\e108";
}

.fa-square-k::before {
  content: "\e274";
}

.fa-manat-sign::before {
  content: "\e1d5";
}

.fa-money-check-pen::before {
  content: "\f872";
}

.fa-money-check-edit::before {
  content: "\f872";
}

.fa-not-equal::before {
  content: "\f53e";
}

.fa-border-top-left::before {
  content: "\f853";
}

.fa-border-style::before {
  content: "\f853";
}

.fa-map-location-dot::before {
  content: "\f5a0";
}

.fa-map-marked-alt::before {
  content: "\f5a0";
}

.fa-tilde::before {
  content: "\~";
}

.fa-jedi::before {
  content: "\f669";
}

.fa-square-poll-vertical::before {
  content: "\f681";
}

.fa-poll::before {
  content: "\f681";
}

.fa-arrow-down-square-triangle::before {
  content: "\f889";
}

.fa-sort-shapes-down-alt::before {
  content: "\f889";
}

.fa-mug-hot::before {
  content: "\f7b6";
}

.fa-dog-leashed::before {
  content: "\f6d4";
}

.fa-car-battery::before {
  content: "\f5df";
}

.fa-battery-car::before {
  content: "\f5df";
}

.fa-face-downcast-sweat::before {
  content: "\e371";
}

.fa-mailbox-flag-up::before {
  content: "\e5bb";
}

.fa-memo-circle-info::before {
  content: "\e49a";
}

.fa-gift::before {
  content: "\f06b";
}

.fa-dice-two::before {
  content: "\f528";
}

.fa-volume::before {
  content: "\f6a8";
}

.fa-volume-medium::before {
  content: "\f6a8";
}

.fa-transporter-5::before {
  content: "\e2a6";
}

.fa-gauge-circle-bolt::before {
  content: "\e496";
}

.fa-coin-front::before {
  content: "\e3fc";
}

.fa-file-slash::before {
  content: "\e3a7";
}

.fa-message-arrow-up-right::before {
  content: "\e1dd";
}

.fa-treasure-chest::before {
  content: "\f723";
}

.fa-chess-queen::before {
  content: "\f445";
}

.fa-paintbrush-fine::before {
  content: "\f5a9";
}

.fa-paint-brush-alt::before {
  content: "\f5a9";
}

.fa-paint-brush-fine::before {
  content: "\f5a9";
}

.fa-paintbrush-alt::before {
  content: "\f5a9";
}

.fa-glasses::before {
  content: "\f530";
}

.fa-hood-cloak::before {
  content: "\f6ef";
}

.fa-square-quote::before {
  content: "\e329";
}

.fa-up-left::before {
  content: "\e2bd";
}

.fa-bring-front::before {
  content: "\f857";
}

.fa-chess-board::before {
  content: "\f43c";
}

.fa-burger-cheese::before {
  content: "\f7f1";
}

.fa-cheeseburger::before {
  content: "\f7f1";
}

.fa-building-circle-check::before {
  content: "\e4d2";
}

.fa-repeat-1::before {
  content: "\f365";
}

.fa-arrow-down-to-line::before {
  content: "\f33d";
}

.fa-arrow-to-bottom::before {
  content: "\f33d";
}

.fa-grid-5::before {
  content: "\e199";
}

.fa-right-long-to-line::before {
  content: "\e444";
}

.fa-person-chalkboard::before {
  content: "\e53d";
}

.fa-mars-stroke-right::before {
  content: "\f22b";
}

.fa-mars-stroke-h::before {
  content: "\f22b";
}

.fa-hand-back-fist::before {
  content: "\f255";
}

.fa-hand-rock::before {
  content: "\f255";
}

.fa-tally::before {
  content: "\f69c";
}

.fa-tally-5::before {
  content: "\f69c";
}

.fa-square-caret-up::before {
  content: "\f151";
}

.fa-caret-square-up::before {
  content: "\f151";
}

.fa-cloud-showers-water::before {
  content: "\e4e4";
}

.fa-chart-bar::before {
  content: "\f080";
}

.fa-bar-chart::before {
  content: "\f080";
}

.fa-hands-bubbles::before {
  content: "\e05e";
}

.fa-hands-wash::before {
  content: "\e05e";
}

.fa-less-than-equal::before {
  content: "\f537";
}

.fa-train::before {
  content: "\f238";
}

.fa-up-from-dotted-line::before {
  content: "\e456";
}

.fa-eye-low-vision::before {
  content: "\f2a8";
}

.fa-low-vision::before {
  content: "\f2a8";
}

.fa-traffic-light-go::before {
  content: "\f638";
}

.fa-face-exhaling::before {
  content: "\e480";
}

.fa-sensor-fire::before {
  content: "\e02a";
}

.fa-user-unlock::before {
  content: "\e058";
}

.fa-hexagon-divide::before {
  content: "\e1ad";
}

.fa-00::before {
  content: "\e467";
}

.fa-crow::before {
  content: "\f520";
}

.fa-cassette-betamax::before {
  content: "\f8a4";
}

.fa-betamax::before {
  content: "\f8a4";
}

.fa-sailboat::before {
  content: "\e445";
}

.fa-window-restore::before {
  content: "\f2d2";
}

.fa-nfc-magnifying-glass::before {
  content: "\e1f9";
}

.fa-file-binary::before {
  content: "\e175";
}

.fa-circle-v::before {
  content: "\e12a";
}

.fa-square-plus::before {
  content: "\f0fe";
}

.fa-plus-square::before {
  content: "\f0fe";
}

.fa-bowl-scoops::before {
  content: "\e3df";
}

.fa-mistletoe::before {
  content: "\f7b4";
}

.fa-custard::before {
  content: "\e403";
}

.fa-lacrosse-stick::before {
  content: "\e3b5";
}

.fa-hockey-mask::before {
  content: "\f6ee";
}

.fa-sunrise::before {
  content: "\f766";
}

.fa-panel-ews::before {
  content: "\e42e";
}

.fa-torii-gate::before {
  content: "\f6a1";
}

.fa-cloud-exclamation::before {
  content: "\e491";
}

.fa-message-lines::before {
  content: "\f4a6";
}

.fa-comment-alt-lines::before {
  content: "\f4a6";
}

.fa-frog::before {
  content: "\f52e";
}

.fa-bucket::before {
  content: "\e4cf";
}

.fa-floppy-disk-pen::before {
  content: "\e182";
}

.fa-image::before {
  content: "\f03e";
}

.fa-window-frame::before {
  content: "\e04f";
}

.fa-microphone::before {
  content: "\f130";
}

.fa-cow::before {
  content: "\f6c8";
}

.fa-square-ring::before {
  content: "\e44f";
}

.fa-down-from-line::before {
  content: "\f349";
}

.fa-arrow-alt-from-top::before {
  content: "\f349";
}

.fa-caret-up::before {
  content: "\f0d8";
}

.fa-shield-xmark::before {
  content: "\e24c";
}

.fa-shield-times::before {
  content: "\e24c";
}

.fa-screwdriver::before {
  content: "\f54a";
}

.fa-circle-sort-down::before {
  content: "\e031";
}

.fa-sort-circle-down::before {
  content: "\e031";
}

.fa-folder-closed::before {
  content: "\e185";
}

.fa-house-tsunami::before {
  content: "\e515";
}

.fa-square-nfi::before {
  content: "\e576";
}

.fa-forklift::before {
  content: "\f47a";
}

.fa-arrow-up-from-ground-water::before {
  content: "\e4b5";
}

.fa-bracket-square-right::before {
  content: "\]";
}

.fa-martini-glass::before {
  content: "\f57b";
}

.fa-glass-martini-alt::before {
  content: "\f57b";
}

.fa-rotate-left::before {
  content: "\f2ea";
}

.fa-rotate-back::before {
  content: "\f2ea";
}

.fa-rotate-backward::before {
  content: "\f2ea";
}

.fa-undo-alt::before {
  content: "\f2ea";
}

.fa-table-columns::before {
  content: "\f0db";
}

.fa-columns::before {
  content: "\f0db";
}

.fa-square-a::before {
  content: "\e25f";
}

.fa-tick::before {
  content: "\e32f";
}

.fa-lemon::before {
  content: "\f094";
}

.fa-head-side-mask::before {
  content: "\e063";
}

.fa-handshake::before {
  content: "\f2b5";
}

.fa-gem::before {
  content: "\f3a5";
}

.fa-dolly::before {
  content: "\f472";
}

.fa-dolly-box::before {
  content: "\f472";
}

.fa-smoking::before {
  content: "\f48d";
}

.fa-minimize::before {
  content: "\f78c";
}

.fa-compress-arrows-alt::before {
  content: "\f78c";
}

.fa-refrigerator::before {
  content: "\e026";
}

.fa-monument::before {
  content: "\f5a6";
}

.fa-octagon-xmark::before {
  content: "\f2f0";
}

.fa-times-octagon::before {
  content: "\f2f0";
}

.fa-xmark-octagon::before {
  content: "\f2f0";
}

.fa-align-slash::before {
  content: "\f846";
}

.fa-snowplow::before {
  content: "\f7d2";
}

.fa-angles-right::before {
  content: "\f101";
}

.fa-angle-double-right::before {
  content: "\f101";
}

.fa-truck-ramp-couch::before {
  content: "\f4dd";
}

.fa-truck-couch::before {
  content: "\f4dd";
}

.fa-cannabis::before {
  content: "\f55f";
}

.fa-circle-play::before {
  content: "\f144";
}

.fa-play-circle::before {
  content: "\f144";
}

.fa-arrow-up-right-and-arrow-down-left-from-center::before {
  content: "\e0a0";
}

.fa-tablets::before {
  content: "\f490";
}

.fa-360-degrees::before {
  content: "\e2dc";
}

.fa-ethernet::before {
  content: "\f796";
}

.fa-euro-sign::before {
  content: "\f153";
}

.fa-eur::before {
  content: "\f153";
}

.fa-euro::before {
  content: "\f153";
}

.fa-chair::before {
  content: "\f6c0";
}

.fa-circle-check::before {
  content: "\f058";
}

.fa-check-circle::before {
  content: "\f058";
}

.fa-square-dashed-circle-plus::before {
  content: "\e5c2";
}

.fa-money-simple-from-bracket::before {
  content: "\e313";
}

.fa-bat::before {
  content: "\f6b5";
}

.fa-circle-stop::before {
  content: "\f28d";
}

.fa-stop-circle::before {
  content: "\f28d";
}

.fa-head-side-headphones::before {
  content: "\f8c2";
}

.fa-phone-rotary::before {
  content: "\f8d3";
}

.fa-compass-drafting::before {
  content: "\f568";
}

.fa-drafting-compass::before {
  content: "\f568";
}

.fa-plate-wheat::before {
  content: "\e55a";
}

.fa-calendar-circle-minus::before {
  content: "\e46f";
}

.fa-chopsticks::before {
  content: "\e3f7";
}

.fa-car-wrench::before {
  content: "\f5e3";
}

.fa-car-mechanic::before {
  content: "\f5e3";
}

.fa-icicles::before {
  content: "\f7ad";
}

.fa-person-shelter::before {
  content: "\e54f";
}

.fa-neuter::before {
  content: "\f22c";
}

.fa-id-badge::before {
  content: "\f2c1";
}

.fa-kazoo::before {
  content: "\f8c7";
}

.fa-marker::before {
  content: "\f5a1";
}

.fa-face-laugh-beam::before {
  content: "\f59a";
}

.fa-laugh-beam::before {
  content: "\f59a";
}

.fa-square-arrow-down-left::before {
  content: "\e261";
}

.fa-battery-bolt::before {
  content: "\f376";
}

.fa-tree-large::before {
  content: "\f7dd";
}

.fa-helicopter-symbol::before {
  content: "\e502";
}

.fa-aperture::before {
  content: "\e2df";
}

.fa-universal-access::before {
  content: "\f29a";
}

.fa-file-magnifying-glass::before {
  content: "\f865";
}

.fa-file-search::before {
  content: "\f865";
}

.fa-up-right::before {
  content: "\e2be";
}

.fa-circle-chevron-up::before {
  content: "\f139";
}

.fa-chevron-circle-up::before {
  content: "\f139";
}

.fa-user-police::before {
  content: "\e333";
}

.fa-lari-sign::before {
  content: "\e1c8";
}

.fa-volcano::before {
  content: "\f770";
}

.fa-teddy-bear::before {
  content: "\e3cf";
}

.fa-stocking::before {
  content: "\f7d5";
}

.fa-person-walking-dashed-line-arrow-right::before {
  content: "\e553";
}

.fa-image-slash::before {
  content: "\e1b7";
}

.fa-mask-snorkel::before {
  content: "\e3b7";
}

.fa-smoke::before {
  content: "\f760";
}

.fa-sterling-sign::before {
  content: "\f154";
}

.fa-gbp::before {
  content: "\f154";
}

.fa-pound-sign::before {
  content: "\f154";
}

.fa-battery-exclamation::before {
  content: "\e0b0";
}

.fa-viruses::before {
  content: "\e076";
}

.fa-square-person-confined::before {
  content: "\e577";
}

.fa-user-tie::before {
  content: "\f508";
}

.fa-arrow-down-long::before {
  content: "\f175";
}

.fa-long-arrow-down::before {
  content: "\f175";
}

.fa-tent-arrow-down-to-line::before {
  content: "\e57e";
}

.fa-certificate::before {
  content: "\f0a3";
}

.fa-crystal-ball::before {
  content: "\e362";
}

.fa-reply-all::before {
  content: "\f122";
}

.fa-mail-reply-all::before {
  content: "\f122";
}

.fa-suitcase::before {
  content: "\f0f2";
}

.fa-person-skating::before {
  content: "\f7c5";
}

.fa-skating::before {
  content: "\f7c5";
}

.fa-star-shooting::before {
  content: "\e036";
}

.fa-binary-lock::before {
  content: "\e33d";
}

.fa-filter-circle-dollar::before {
  content: "\f662";
}

.fa-funnel-dollar::before {
  content: "\f662";
}

.fa-camera-retro::before {
  content: "\f083";
}

.fa-circle-arrow-down::before {
  content: "\f0ab";
}

.fa-arrow-circle-down::before {
  content: "\f0ab";
}

.fa-comment-pen::before {
  content: "\f4ae";
}

.fa-comment-edit::before {
  content: "\f4ae";
}

.fa-file-import::before {
  content: "\f56f";
}

.fa-arrow-right-to-file::before {
  content: "\f56f";
}

.fa-banjo::before {
  content: "\f8a3";
}

.fa-square-arrow-up-right::before {
  content: "\f14c";
}

.fa-external-link-square::before {
  content: "\f14c";
}

.fa-light-emergency-on::before {
  content: "\e420";
}

.fa-kerning::before {
  content: "\f86f";
}

.fa-box-open::before {
  content: "\f49e";
}

.fa-square-f::before {
  content: "\e270";
}

.fa-scroll::before {
  content: "\f70e";
}

.fa-spa::before {
  content: "\f5bb";
}

.fa-arrow-left-from-line::before {
  content: "\f344";
}

.fa-arrow-from-right::before {
  content: "\f344";
}

.fa-strawberry::before {
  content: "\e32b";
}

.fa-location-pin-lock::before {
  content: "\e51f";
}

.fa-pause::before {
  content: "\f04c";
}

.fa-clock-eight-thirty::before {
  content: "\e346";
}

.fa-plane-engines::before {
  content: "\f3de";
}

.fa-plane-alt::before {
  content: "\f3de";
}

.fa-hill-avalanche::before {
  content: "\e507";
}

.fa-temperature-empty::before {
  content: "\f2cb";
}

.fa-temperature-0::before {
  content: "\f2cb";
}

.fa-thermometer-0::before {
  content: "\f2cb";
}

.fa-thermometer-empty::before {
  content: "\f2cb";
}

.fa-bomb::before {
  content: "\f1e2";
}

.fa-gauge-low::before {
  content: "\f627";
}

.fa-tachometer-alt-slow::before {
  content: "\f627";
}

.fa-registered::before {
  content: "\f25d";
}

.fa-trash-can-plus::before {
  content: "\e2ac";
}

.fa-address-card::before {
  content: "\f2bb";
}

.fa-contact-card::before {
  content: "\f2bb";
}

.fa-vcard::before {
  content: "\f2bb";
}

.fa-scale-unbalanced-flip::before {
  content: "\f516";
}

.fa-balance-scale-right::before {
  content: "\f516";
}

.fa-globe-snow::before {
  content: "\f7a3";
}

.fa-subscript::before {
  content: "\f12c";
}

.fa-diamond-turn-right::before {
  content: "\f5eb";
}

.fa-directions::before {
  content: "\f5eb";
}

.fa-integral::before {
  content: "\f667";
}

.fa-burst::before {
  content: "\e4dc";
}

.fa-house-laptop::before {
  content: "\e066";
}

.fa-laptop-house::before {
  content: "\e066";
}

.fa-face-tired::before {
  content: "\f5c8";
}

.fa-tired::before {
  content: "\f5c8";
}

.fa-money-bills::before {
  content: "\e1f3";
}

.fa-blinds-raised::before {
  content: "\f8fd";
}

.fa-smog::before {
  content: "\f75f";
}

.fa-ufo-beam::before {
  content: "\e048";
}

.fa-circle-caret-up::before {
  content: "\f331";
}

.fa-caret-circle-up::before {
  content: "\f331";
}

.fa-user-vneck-hair-long::before {
  content: "\e463";
}

.fa-square-a-lock::before {
  content: "\e44d";
}

.fa-crutch::before {
  content: "\f7f7";
}

.fa-gas-pump-slash::before {
  content: "\f5f4";
}

.fa-cloud-arrow-up::before {
  content: "\f0ee";
}

.fa-cloud-upload::before {
  content: "\f0ee";
}

.fa-cloud-upload-alt::before {
  content: "\f0ee";
}

.fa-palette::before {
  content: "\f53f";
}

.fa-transporter-4::before {
  content: "\e2a5";
}

.fa-objects-align-right::before {
  content: "\e3bf";
}

.fa-arrows-turn-right::before {
  content: "\e4c0";
}

.fa-vest::before {
  content: "\e085";
}

.fa-pig::before {
  content: "\f706";
}

.fa-inbox-full::before {
  content: "\e1ba";
}

.fa-circle-envelope::before {
  content: "\e10c";
}

.fa-envelope-circle::before {
  content: "\e10c";
}

.fa-triangle-person-digging::before {
  content: "\f85d";
}

.fa-construction::before {
  content: "\f85d";
}

.fa-ferry::before {
  content: "\e4ea";
}

.fa-bullseye-arrow::before {
  content: "\f648";
}

.fa-arrows-down-to-people::before {
  content: "\e4b9";
}

.fa-seedling::before {
  content: "\f4d8";
}

.fa-sprout::before {
  content: "\f4d8";
}

.fa-clock-seven::before {
  content: "\e350";
}

.fa-left-right::before {
  content: "\f337";
}

.fa-arrows-alt-h::before {
  content: "\f337";
}

.fa-boxes-packing::before {
  content: "\e4c7";
}

.fa-circle-arrow-left::before {
  content: "\f0a8";
}

.fa-arrow-circle-left::before {
  content: "\f0a8";
}

.fa-flashlight::before {
  content: "\f8b8";
}

.fa-group-arrows-rotate::before {
  content: "\e4f6";
}

.fa-bowl-food::before {
  content: "\e4c6";
}

.fa-square-9::before {
  content: "\e25e";
}

.fa-candy-cane::before {
  content: "\f786";
}

.fa-arrow-down-wide-short::before {
  content: "\f160";
}

.fa-sort-amount-asc::before {
  content: "\f160";
}

.fa-sort-amount-down::before {
  content: "\f160";
}

.fa-square-dollar::before {
  content: "\f2e9";
}

.fa-dollar-square::before {
  content: "\f2e9";
}

.fa-usd-square::before {
  content: "\f2e9";
}

.fa-phone-arrow-right::before {
  content: "\e5be";
}

.fa-hand-holding-seedling::before {
  content: "\f4bf";
}

.fa-message-check::before {
  content: "\f4a2";
}

.fa-comment-alt-check::before {
  content: "\f4a2";
}

.fa-cloud-bolt::before {
  content: "\f76c";
}

.fa-thunderstorm::before {
  content: "\f76c";
}

.fa-text-slash::before {
  content: "\f87d";
}

.fa-remove-format::before {
  content: "\f87d";
}

.fa-watch::before {
  content: "\f2e1";
}

.fa-circle-down-left::before {
  content: "\e107";
}

.fa-text::before {
  content: "\f893";
}

.fa-projector::before {
  content: "\f8d6";
}

.fa-face-smile-wink::before {
  content: "\f4da";
}

.fa-smile-wink::before {
  content: "\f4da";
}

.fa-tombstone-blank::before {
  content: "\f721";
}

.fa-tombstone-alt::before {
  content: "\f721";
}

.fa-chess-king-piece::before {
  content: "\f440";
}

.fa-chess-king-alt::before {
  content: "\f440";
}

.fa-circle-6::before {
  content: "\e0f3";
}

.fa-left::before {
  content: "\f355";
}

.fa-arrow-alt-left::before {
  content: "\f355";
}

.fa-file-word::before {
  content: "\f1c2";
}

.fa-file-powerpoint::before {
  content: "\f1c4";
}

.fa-square-down::before {
  content: "\f350";
}

.fa-arrow-alt-square-down::before {
  content: "\f350";
}

.fa-objects-align-center-vertical::before {
  content: "\e3bd";
}

.fa-arrows-left-right::before {
  content: "\f07e";
}

.fa-arrows-h::before {
  content: "\f07e";
}

.fa-house-lock::before {
  content: "\e510";
}

.fa-cloud-arrow-down::before {
  content: "\f0ed";
}

.fa-cloud-download::before {
  content: "\f0ed";
}

.fa-cloud-download-alt::before {
  content: "\f0ed";
}

.fa-wreath::before {
  content: "\f7e2";
}

.fa-children::before {
  content: "\e4e1";
}

.fa-meter-droplet::before {
  content: "\e1ea";
}

.fa-chalkboard::before {
  content: "\f51b";
}

.fa-blackboard::before {
  content: "\f51b";
}

.fa-user-large-slash::before {
  content: "\f4fa";
}

.fa-user-alt-slash::before {
  content: "\f4fa";
}

.fa-signal-strong::before {
  content: "\f68f";
}

.fa-signal-4::before {
  content: "\f68f";
}

.fa-lollipop::before {
  content: "\e424";
}

.fa-lollypop::before {
  content: "\e424";
}

.fa-list-tree::before {
  content: "\e1d2";
}

.fa-envelope-open::before {
  content: "\f2b6";
}

.fa-draw-circle::before {
  content: "\f5ed";
}

.fa-cat-space::before {
  content: "\e001";
}

.fa-handshake-simple-slash::before {
  content: "\e05f";
}

.fa-handshake-alt-slash::before {
  content: "\e05f";
}

.fa-rabbit-running::before {
  content: "\f709";
}

.fa-rabbit-fast::before {
  content: "\f709";
}

.fa-memo-pad::before {
  content: "\e1da";
}

.fa-mattress-pillow::before {
  content: "\e525";
}

.fa-alarm-plus::before {
  content: "\f844";
}

.fa-alicorn::before {
  content: "\f6b0";
}

.fa-comment-question::before {
  content: "\e14b";
}

.fa-gingerbread-man::before {
  content: "\f79d";
}

.fa-guarani-sign::before {
  content: "\e19a";
}

.fa-burger-fries::before {
  content: "\e0cd";
}

.fa-mug-tea::before {
  content: "\f875";
}

.fa-border-top::before {
  content: "\f855";
}

.fa-arrows-rotate::before {
  content: "\f021";
}

.fa-refresh::before {
  content: "\f021";
}

.fa-sync::before {
  content: "\f021";
}

.fa-circle-book-open::before {
  content: "\e0ff";
}

.fa-book-circle::before {
  content: "\e0ff";
}

.fa-arrows-to-dotted-line::before {
  content: "\e0a6";
}

.fa-fire-extinguisher::before {
  content: "\f134";
}

.fa-garage-open::before {
  content: "\e00b";
}

.fa-shelves-empty::before {
  content: "\e246";
}

.fa-cruzeiro-sign::before {
  content: "\e152";
}

.fa-watch-apple::before {
  content: "\e2cb";
}

.fa-watch-calculator::before {
  content: "\f8f0";
}

.fa-list-dropdown::before {
  content: "\e1cf";
}

.fa-cabinet-filing::before {
  content: "\f64b";
}

.fa-burger-soda::before {
  content: "\f858";
}

.fa-square-arrow-up::before {
  content: "\f33c";
}

.fa-arrow-square-up::before {
  content: "\f33c";
}

.fa-greater-than-equal::before {
  content: "\f532";
}

.fa-pallet-box::before {
  content: "\e208";
}

.fa-face-confounded::before {
  content: "\e36c";
}

.fa-shield-halved::before {
  content: "\f3ed";
}

.fa-shield-alt::before {
  content: "\f3ed";
}

.fa-truck-plow::before {
  content: "\f7de";
}

.fa-book-atlas::before {
  content: "\f558";
}

.fa-atlas::before {
  content: "\f558";
}

.fa-virus::before {
  content: "\e074";
}

.fa-comment-middle-top::before {
  content: "\e14a";
}

.fa-envelope-circle-check::before {
  content: "\e4e8";
}

.fa-layer-group::before {
  content: "\f5fd";
}

.fa-restroom-simple::before {
  content: "\e23a";
}

.fa-arrows-to-dot::before {
  content: "\e4be";
}

.fa-border-outer::before {
  content: "\f851";
}

.fa-hashtag-lock::before {
  content: "\e415";
}

.fa-clock-two-thirty::before {
  content: "\e35b";
}

.fa-archway::before {
  content: "\f557";
}

.fa-heart-circle-check::before {
  content: "\e4fd";
}

.fa-house-chimney-crack::before {
  content: "\f6f1";
}

.fa-house-damage::before {
  content: "\f6f1";
}

.fa-file-zipper::before {
  content: "\f1c6";
}

.fa-file-archive::before {
  content: "\f1c6";
}

.fa-heart-half::before {
  content: "\e1ab";
}

.fa-comment-check::before {
  content: "\f4ac";
}

.fa-square::before {
  content: "\f0c8";
}

.fa-memo::before {
  content: "\e1d8";
}

.fa-martini-glass-empty::before {
  content: "\f000";
}

.fa-glass-martini::before {
  content: "\f000";
}

.fa-couch::before {
  content: "\f4b8";
}

.fa-cedi-sign::before {
  content: "\e0df";
}

.fa-italic::before {
  content: "\f033";
}

.fa-glass-citrus::before {
  content: "\f869";
}

.fa-calendar-lines-pen::before {
  content: "\e472";
}

.fa-church::before {
  content: "\f51d";
}

.fa-person-snowmobiling::before {
  content: "\f7d1";
}

.fa-snowmobile::before {
  content: "\f7d1";
}

.fa-face-hushed::before {
  content: "\e37b";
}

.fa-comments-dollar::before {
  content: "\f653";
}

.fa-pickaxe::before {
  content: "\e5bf";
}

.fa-link-simple-slash::before {
  content: "\e1ce";
}

.fa-democrat::before {
  content: "\f747";
}

.fa-face-confused::before {
  content: "\e36d";
}

.fa-pinball::before {
  content: "\e229";
}

.fa-z::before {
  content: "Z";
}

.fa-person-skiing::before {
  content: "\f7c9";
}

.fa-skiing::before {
  content: "\f7c9";
}

.fa-deer::before {
  content: "\f78e";
}

.fa-input-pipe::before {
  content: "\e1be";
}

.fa-road-lock::before {
  content: "\e567";
}

.fa-a::before {
  content: "A";
}

.fa-bookmark-slash::before {
  content: "\e0c2";
}

.fa-temperature-arrow-down::before {
  content: "\e03f";
}

.fa-temperature-down::before {
  content: "\e03f";
}

.fa-mace::before {
  content: "\f6f8";
}

.fa-feather-pointed::before {
  content: "\f56b";
}

.fa-feather-alt::before {
  content: "\f56b";
}

.fa-sausage::before {
  content: "\f820";
}

.fa-trash-can-clock::before {
  content: "\e2aa";
}

.fa-p::before {
  content: "P";
}

.fa-snowflake::before {
  content: "\f2dc";
}

.fa-stomach::before {
  content: "\f623";
}

.fa-newspaper::before {
  content: "\f1ea";
}

.fa-rectangle-ad::before {
  content: "\f641";
}

.fa-ad::before {
  content: "\f641";
}

.fa-guitar-electric::before {
  content: "\f8be";
}

.fa-arrow-turn-down-right::before {
  content: "\e3d6";
}

.fa-moon-cloud::before {
  content: "\f754";
}

.fa-bread-slice-butter::before {
  content: "\e3e1";
}

.fa-circle-arrow-right::before {
  content: "\f0a9";
}

.fa-arrow-circle-right::before {
  content: "\f0a9";
}

.fa-user-group-crown::before {
  content: "\f6a5";
}

.fa-users-crown::before {
  content: "\f6a5";
}

.fa-circle-i::before {
  content: "\e111";
}

.fa-toilet-paper-check::before {
  content: "\e5b2";
}

.fa-filter-circle-xmark::before {
  content: "\e17b";
}

.fa-locust::before {
  content: "\e520";
}

.fa-sort::before {
  content: "\f0dc";
}

.fa-unsorted::before {
  content: "\f0dc";
}

.fa-list-ol::before {
  content: "\f0cb";
}

.fa-list-1-2::before {
  content: "\f0cb";
}

.fa-list-numeric::before {
  content: "\f0cb";
}

.fa-chart-waterfall::before {
  content: "\e0eb";
}

.fa-face-party::before {
  content: "\e383";
}

.fa-kidneys::before {
  content: "\f5fb";
}

.fa-wifi-exclamation::before {
  content: "\e2cf";
}

.fa-chart-network::before {
  content: "\f78a";
}

.fa-person-dress-burst::before {
  content: "\e544";
}

.fa-dice-d4::before {
  content: "\f6d0";
}

.fa-money-check-dollar::before {
  content: "\f53d";
}

.fa-money-check-alt::before {
  content: "\f53d";
}

.fa-vector-square::before {
  content: "\f5cb";
}

.fa-bread-slice::before {
  content: "\f7ec";
}

.fa-language::before {
  content: "\f1ab";
}

.fa-wheat-awn-slash::before {
  content: "\e338";
}

.fa-face-kiss-wink-heart::before {
  content: "\f598";
}

.fa-kiss-wink-heart::before {
  content: "\f598";
}

.fa-dagger::before {
  content: "\f6cb";
}

.fa-podium::before {
  content: "\f680";
}

.fa-memo-circle-check::before {
  content: "\e1d9";
}

.fa-route-highway::before {
  content: "\f61a";
}

.fa-down-to-line::before {
  content: "\f34a";
}

.fa-arrow-alt-to-bottom::before {
  content: "\f34a";
}

.fa-filter::before {
  content: "\f0b0";
}

.fa-square-g::before {
  content: "\e271";
}

.fa-circle-phone::before {
  content: "\e11b";
}

.fa-phone-circle::before {
  content: "\e11b";
}

.fa-clipboard-prescription::before {
  content: "\f5e8";
}

.fa-user-nurse-hair::before {
  content: "\e45d";
}

.fa-question::before {
  content: "\?";
}

.fa-file-signature::before {
  content: "\f573";
}

.fa-toggle-large-on::before {
  content: "\e5b1";
}

.fa-up-down-left-right::before {
  content: "\f0b2";
}

.fa-arrows-alt::before {
  content: "\f0b2";
}

.fa-dryer-heat::before {
  content: "\f862";
}

.fa-dryer-alt::before {
  content: "\f862";
}

.fa-house-chimney-user::before {
  content: "\e065";
}

.fa-hand-holding-heart::before {
  content: "\f4be";
}

.fa-arrow-up-small-big::before {
  content: "\f88f";
}

.fa-sort-size-up-alt::before {
  content: "\f88f";
}

.fa-train-track::before {
  content: "\e453";
}

.fa-puzzle-piece::before {
  content: "\f12e";
}

.fa-money-check::before {
  content: "\f53c";
}

.fa-star-half-stroke::before {
  content: "\f5c0";
}

.fa-star-half-alt::before {
  content: "\f5c0";
}

.fa-file-exclamation::before {
  content: "\f31a";
}

.fa-code::before {
  content: "\f121";
}

.fa-whiskey-glass::before {
  content: "\f7a0";
}

.fa-glass-whiskey::before {
  content: "\f7a0";
}

.fa-moon-stars::before {
  content: "\f755";
}

.fa-building-circle-exclamation::before {
  content: "\e4d3";
}

.fa-clothes-hanger::before {
  content: "\e136";
}

.fa-mobile-notch::before {
  content: "\e1ee";
}

.fa-mobile-iphone::before {
  content: "\e1ee";
}

.fa-magnifying-glass-chart::before {
  content: "\e522";
}

.fa-arrow-up-right-from-square::before {
  content: "\f08e";
}

.fa-external-link::before {
  content: "\f08e";
}

.fa-cubes-stacked::before {
  content: "\e4e6";
}

.fa-images-user::before {
  content: "\e1b9";
}

.fa-won-sign::before {
  content: "\f159";
}

.fa-krw::before {
  content: "\f159";
}

.fa-won::before {
  content: "\f159";
}

.fa-image-polaroid-user::before {
  content: "\e1b6";
}

.fa-virus-covid::before {
  content: "\e4a8";
}

.fa-square-ellipsis::before {
  content: "\e26e";
}

.fa-pie::before {
  content: "\f705";
}

.fa-chess-knight-piece::before {
  content: "\f442";
}

.fa-chess-knight-alt::before {
  content: "\f442";
}

.fa-austral-sign::before {
  content: "\e0a9";
}

.fa-cloud-plus::before {
  content: "\e35e";
}

.fa-f::before {
  content: "F";
}

.fa-leaf::before {
  content: "\f06c";
}

.fa-bed-bunk::before {
  content: "\f8f8";
}

.fa-road::before {
  content: "\f018";
}

.fa-taxi::before {
  content: "\f1ba";
}

.fa-cab::before {
  content: "\f1ba";
}

.fa-person-circle-plus::before {
  content: "\e541";
}

.fa-chart-pie::before {
  content: "\f200";
}

.fa-pie-chart::before {
  content: "\f200";
}

.fa-bolt-lightning::before {
  content: "\e0b7";
}

.fa-clock-eight::before {
  content: "\e345";
}

.fa-sack-xmark::before {
  content: "\e56a";
}

.fa-file-excel::before {
  content: "\f1c3";
}

.fa-file-contract::before {
  content: "\f56c";
}

.fa-fish-fins::before {
  content: "\e4f2";
}

.fa-circle-q::before {
  content: "\e11e";
}

.fa-building-flag::before {
  content: "\e4d5";
}

.fa-face-grin-beam::before {
  content: "\f582";
}

.fa-grin-beam::before {
  content: "\f582";
}

.fa-object-ungroup::before {
  content: "\f248";
}

.fa-face-disguise::before {
  content: "\e370";
}

.fa-circle-arrow-down-right::before {
  content: "\e0fa";
}

.fa-alien-8bit::before {
  content: "\f8f6";
}

.fa-alien-monster::before {
  content: "\f8f6";
}

.fa-hand-point-ribbon::before {
  content: "\e1a6";
}

.fa-poop::before {
  content: "\f619";
}

.fa-object-exclude::before {
  content: "\e49c";
}

.fa-telescope::before {
  content: "\e03e";
}

.fa-location-pin::before {
  content: "\f041";
}

.fa-map-marker::before {
  content: "\f041";
}

.fa-square-list::before {
  content: "\e489";
}

.fa-kaaba::before {
  content: "\f66b";
}

.fa-toilet-paper::before {
  content: "\f71e";
}

.fa-helmet-safety::before {
  content: "\f807";
}

.fa-hard-hat::before {
  content: "\f807";
}

.fa-hat-hard::before {
  content: "\f807";
}

.fa-comment-code::before {
  content: "\e147";
}

.fa-sim-cards::before {
  content: "\e251";
}

.fa-starship::before {
  content: "\e039";
}

.fa-eject::before {
  content: "\f052";
}

.fa-circle-right::before {
  content: "\f35a";
}

.fa-arrow-alt-circle-right::before {
  content: "\f35a";
}

.fa-plane-circle-check::before {
  content: "\e555";
}

.fa-seal::before {
  content: "\e241";
}

.fa-user-cowboy::before {
  content: "\f8ea";
}

.fa-hexagon-vertical-nft::before {
  content: "\e505";
}

.fa-face-rolling-eyes::before {
  content: "\f5a5";
}

.fa-meh-rolling-eyes::before {
  content: "\f5a5";
}

.fa-bread-loaf::before {
  content: "\f7eb";
}

.fa-rings-wedding::before {
  content: "\f81b";
}

.fa-object-group::before {
  content: "\f247";
}

.fa-french-fries::before {
  content: "\f803";
}

.fa-chart-line::before {
  content: "\f201";
}

.fa-line-chart::before {
  content: "\f201";
}

.fa-calendar-arrow-down::before {
  content: "\e0d0";
}

.fa-calendar-download::before {
  content: "\e0d0";
}

.fa-send-back::before {
  content: "\f87e";
}

.fa-mask-ventilator::before {
  content: "\e524";
}

.fa-signature-lock::before {
  content: "\e3ca";
}

.fa-arrow-right::before {
  content: "\f061";
}

.fa-signs-post::before {
  content: "\f277";
}

.fa-map-signs::before {
  content: "\f277";
}

.fa-octagon-plus::before {
  content: "\f301";
}

.fa-plus-octagon::before {
  content: "\f301";
}

.fa-cash-register::before {
  content: "\f788";
}

.fa-person-circle-question::before {
  content: "\e542";
}

.fa-melon-slice::before {
  content: "\e311";
}

.fa-space-station-moon::before {
  content: "\e033";
}

.fa-message-smile::before {
  content: "\f4aa";
}

.fa-comment-alt-smile::before {
  content: "\f4aa";
}

.fa-cup-straw::before {
  content: "\e363";
}

.fa-left-from-line::before {
  content: "\f348";
}

.fa-arrow-alt-from-right::before {
  content: "\f348";
}

.fa-h::before {
  content: "H";
}

.fa-basket-shopping-simple::before {
  content: "\e0af";
}

.fa-shopping-basket-alt::before {
  content: "\e0af";
}

.fa-hands-holding-heart::before {
  content: "\f4c3";
}

.fa-hands-heart::before {
  content: "\f4c3";
}

.fa-clock-nine::before {
  content: "\e34c";
}

.fa-tarp::before {
  content: "\e57b";
}

.fa-face-sleepy::before {
  content: "\e38e";
}

.fa-hand-horns::before {
  content: "\e1a9";
}

.fa-screwdriver-wrench::before {
  content: "\f7d9";
}

.fa-tools::before {
  content: "\f7d9";
}

.fa-arrows-to-eye::before {
  content: "\e4bf";
}

.fa-circle-three-quarters::before {
  content: "\e125";
}

.fa-trophy-star::before {
  content: "\f2eb";
}

.fa-trophy-alt::before {
  content: "\f2eb";
}

.fa-plug-circle-bolt::before {
  content: "\e55b";
}

.fa-face-thermometer::before {
  content: "\e39a";
}

.fa-shirt-running::before {
  content: "\e3c8";
}

.fa-book-circle-arrow-up::before {
  content: "\e0bd";
}

.fa-face-nauseated::before {
  content: "\e381";
}

.fa-heart::before {
  content: "\f004";
}

.fa-file-chart-pie::before {
  content: "\f65a";
}

.fa-mars-and-venus::before {
  content: "\f224";
}

.fa-house-user::before {
  content: "\e1b0";
}

.fa-home-user::before {
  content: "\e1b0";
}

.fa-circle-arrow-down-left::before {
  content: "\e0f9";
}

.fa-dumpster-fire::before {
  content: "\f794";
}

.fa-hexagon-minus::before {
  content: "\f307";
}

.fa-minus-hexagon::before {
  content: "\f307";
}

.fa-left-to-line::before {
  content: "\f34b";
}

.fa-arrow-alt-to-left::before {
  content: "\f34b";
}

.fa-house-crack::before {
  content: "\e3b1";
}

.fa-paw-simple::before {
  content: "\f701";
}

.fa-paw-alt::before {
  content: "\f701";
}

.fa-arrow-left-long-to-line::before {
  content: "\e3d4";
}

.fa-brackets-round::before {
  content: "\e0c5";
}

.fa-parentheses::before {
  content: "\e0c5";
}

.fa-martini-glass-citrus::before {
  content: "\f561";
}

.fa-cocktail::before {
  content: "\f561";
}

.fa-user-shakespeare::before {
  content: "\e2c2";
}

.fa-arrow-right-to-arc::before {
  content: "\e4b2";
}

.fa-face-surprise::before {
  content: "\f5c2";
}

.fa-surprise::before {
  content: "\f5c2";
}

.fa-bottle-water::before {
  content: "\e4c5";
}

.fa-circle-pause::before {
  content: "\f28b";
}

.fa-pause-circle::before {
  content: "\f28b";
}

.fa-gauge-circle-plus::before {
  content: "\e498";
}

.fa-folders::before {
  content: "\f660";
}

.fa-angel::before {
  content: "\f779";
}

.fa-value-absolute::before {
  content: "\f6a6";
}

.fa-rabbit::before {
  content: "\f708";
}

.fa-toilet-paper-slash::before {
  content: "\e072";
}

.fa-apple-whole::before {
  content: "\f5d1";
}

.fa-apple-alt::before {
  content: "\f5d1";
}

.fa-kitchen-set::before {
  content: "\e51a";
}

.fa-diamond-half::before {
  content: "\e5b7";
}

.fa-lock-keyhole::before {
  content: "\f30d";
}

.fa-lock-alt::before {
  content: "\f30d";
}

.fa-r::before {
  content: "R";
}

.fa-temperature-quarter::before {
  content: "\f2ca";
}

.fa-temperature-1::before {
  content: "\f2ca";
}

.fa-thermometer-1::before {
  content: "\f2ca";
}

.fa-thermometer-quarter::before {
  content: "\f2ca";
}

.fa-square-info::before {
  content: "\f30f";
}

.fa-info-square::before {
  content: "\f30f";
}

.fa-wifi-slash::before {
  content: "\f6ac";
}

.fa-toilet-paper-xmark::before {
  content: "\e5b3";
}

.fa-hands-holding-dollar::before {
  content: "\f4c5";
}

.fa-hands-usd::before {
  content: "\f4c5";
}

.fa-cube::before {
  content: "\f1b2";
}

.fa-arrow-down-triangle-square::before {
  content: "\f888";
}

.fa-sort-shapes-down::before {
  content: "\f888";
}

.fa-bitcoin-sign::before {
  content: "\e0b4";
}

.fa-shutters::before {
  content: "\e449";
}

.fa-shield-dog::before {
  content: "\e573";
}

.fa-solar-panel::before {
  content: "\f5ba";
}

.fa-lock-open::before {
  content: "\f3c1";
}

.fa-table-tree::before {
  content: "\e293";
}

.fa-house-chimney-heart::before {
  content: "\e1b2";
}

.fa-tally-3::before {
  content: "\e296";
}

.fa-elevator::before {
  content: "\e16d";
}

.fa-money-bill-transfer::before {
  content: "\e528";
}

.fa-money-bill-trend-up::before {
  content: "\e529";
}

.fa-house-flood-water-circle-arrow-right::before {
  content: "\e50f";
}

.fa-square-poll-horizontal::before {
  content: "\f682";
}

.fa-poll-h::before {
  content: "\f682";
}

.fa-circle::before {
  content: "\f111";
}

.fa-cart-circle-exclamation::before {
  content: "\e3f2";
}

.fa-sword::before {
  content: "\f71c";
}

.fa-backward-fast::before {
  content: "\f049";
}

.fa-fast-backward::before {
  content: "\f049";
}

.fa-recycle::before {
  content: "\f1b8";
}

.fa-user-astronaut::before {
  content: "\f4fb";
}

.fa-interrobang::before {
  content: "\e5ba";
}

.fa-plane-slash::before {
  content: "\e069";
}

.fa-circle-dashed::before {
  content: "\e105";
}

.fa-trademark::before {
  content: "\f25c";
}

.fa-basketball::before {
  content: "\f434";
}

.fa-basketball-ball::before {
  content: "\f434";
}

.fa-fork-knife::before {
  content: "\f2e6";
}

.fa-utensils-alt::before {
  content: "\f2e6";
}

.fa-satellite-dish::before {
  content: "\f7c0";
}

.fa-badge-check::before {
  content: "\f336";
}

.fa-circle-up::before {
  content: "\f35b";
}

.fa-arrow-alt-circle-up::before {
  content: "\f35b";
}

.fa-slider::before {
  content: "\e252";
}

.fa-mobile-screen-button::before {
  content: "\f3cd";
}

.fa-mobile-alt::before {
  content: "\f3cd";
}

.fa-clock-one-thirty::before {
  content: "\e34f";
}

.fa-inbox-out::before {
  content: "\f311";
}

.fa-inbox-arrow-up::before {
  content: "\f311";
}

.fa-cloud-slash::before {
  content: "\e137";
}

.fa-volume-high::before {
  content: "\f028";
}

.fa-volume-up::before {
  content: "\f028";
}

.fa-users-rays::before {
  content: "\e593";
}

.fa-wallet::before {
  content: "\f555";
}

.fa-octagon-check::before {
  content: "\e426";
}

.fa-flatbread-stuffed::before {
  content: "\e40c";
}

.fa-clipboard-check::before {
  content: "\f46c";
}

.fa-cart-circle-plus::before {
  content: "\e3f3";
}

.fa-truck-clock::before {
  content: "\f48c";
}

.fa-shipping-timed::before {
  content: "\f48c";
}

.fa-pool-8-ball::before {
  content: "\e3c5";
}

.fa-file-audio::before {
  content: "\f1c7";
}

.fa-turn-down-left::before {
  content: "\e331";
}

.fa-lock-hashtag::before {
  content: "\e423";
}

.fa-chart-radar::before {
  content: "\e0e7";
}

.fa-staff::before {
  content: "\f71b";
}

.fa-burger::before {
  content: "\f805";
}

.fa-hamburger::before {
  content: "\f805";
}

.fa-utility-pole::before {
  content: "\e2c3";
}

.fa-transporter-6::before {
  content: "\e2a7";
}

.fa-wrench::before {
  content: "\f0ad";
}

.fa-bugs::before {
  content: "\e4d0";
}

.fa-vector-polygon::before {
  content: "\e2c7";
}

.fa-diagram-nested::before {
  content: "\e157";
}

.fa-rupee-sign::before {
  content: "\f156";
}

.fa-rupee::before {
  content: "\f156";
}

.fa-file-image::before {
  content: "\f1c5";
}

.fa-circle-question::before {
  content: "\f059";
}

.fa-question-circle::before {
  content: "\f059";
}

.fa-image-user::before {
  content: "\e1b8";
}

.fa-buoy::before {
  content: "\e5b5";
}

.fa-plane-departure::before {
  content: "\f5b0";
}

.fa-handshake-slash::before {
  content: "\e060";
}

.fa-book-bookmark::before {
  content: "\e0bb";
}

.fa-border-center-h::before {
  content: "\f89c";
}

.fa-can-food::before {
  content: "\e3e6";
}

.fa-typewriter::before {
  content: "\f8e7";
}

.fa-arrow-right-from-arc::before {
  content: "\e4b1";
}

.fa-circle-k::before {
  content: "\e113";
}

.fa-face-hand-over-mouth::before {
  content: "\e378";
}

.fa-popcorn::before {
  content: "\f819";
}

.fa-house-water::before {
  content: "\f74f";
}

.fa-house-flood::before {
  content: "\f74f";
}

.fa-object-subtract::before {
  content: "\e49e";
}

.fa-code-branch::before {
  content: "\f126";
}

.fa-warehouse-full::before {
  content: "\f495";
}

.fa-warehouse-alt::before {
  content: "\f495";
}

.fa-hat-cowboy::before {
  content: "\f8c0";
}

.fa-bridge::before {
  content: "\e4c8";
}

.fa-phone-flip::before {
  content: "\f879";
}

.fa-phone-alt::before {
  content: "\f879";
}

.fa-arrow-down-from-dotted-line::before {
  content: "\e090";
}

.fa-square-quarters::before {
  content: "\e44e";
}

.fa-truck-front::before {
  content: "\e2b7";
}

.fa-cat::before {
  content: "\f6be";
}

.fa-trash-xmark::before {
  content: "\e2b4";
}

.fa-circle-caret-left::before {
  content: "\f32e";
}

.fa-caret-circle-left::before {
  content: "\f32e";
}

.fa-files::before {
  content: "\e178";
}

.fa-anchor-circle-exclamation::before {
  content: "\e4ab";
}

.fa-face-clouds::before {
  content: "\e47d";
}

.fa-user-crown::before {
  content: "\f6a4";
}

.fa-truck-field::before {
  content: "\e58d";
}

.fa-route::before {
  content: "\f4d7";
}

.fa-cart-circle-check::before {
  content: "\e3f1";
}

.fa-clipboard-question::before {
  content: "\e4e3";
}

.fa-panorama::before {
  content: "\e209";
}

.fa-comment-medical::before {
  content: "\f7f5";
}

.fa-teeth-open::before {
  content: "\f62f";
}

.fa-user-tie-hair-long::before {
  content: "\e460";
}

.fa-file-circle-minus::before {
  content: "\e4ed";
}

.fa-head-side-medical::before {
  content: "\f809";
}

.fa-tags::before {
  content: "\f02c";
}

.fa-wine-glass::before {
  content: "\f4e3";
}

.fa-forward-fast::before {
  content: "\f050";
}

.fa-fast-forward::before {
  content: "\f050";
}

.fa-face-meh-blank::before {
  content: "\f5a4";
}

.fa-meh-blank::before {
  content: "\f5a4";
}

.fa-user-robot::before {
  content: "\e04b";
}

.fa-square-parking::before {
  content: "\f540";
}

.fa-parking::before {
  content: "\f540";
}

.fa-card-diamond::before {
  content: "\e3ea";
}

.fa-face-zipper::before {
  content: "\e3a5";
}

.fa-face-raised-eyebrow::before {
  content: "\e388";
}

.fa-house-signal::before {
  content: "\e012";
}

.fa-square-chevron-up::before {
  content: "\f32c";
}

.fa-chevron-square-up::before {
  content: "\f32c";
}

.fa-bars-progress::before {
  content: "\f828";
}

.fa-tasks-alt::before {
  content: "\f828";
}

.fa-faucet-drip::before {
  content: "\e006";
}

.fa-arrows-to-line::before {
  content: "\e0a7";
}

.fa-dolphin::before {
  content: "\e168";
}

.fa-arrow-up-right::before {
  content: "\e09f";
}

.fa-circle-r::before {
  content: "\e120";
}

.fa-cart-flatbed::before {
  content: "\f474";
}

.fa-dolly-flatbed::before {
  content: "\f474";
}

.fa-ban-smoking::before {
  content: "\f54d";
}

.fa-smoking-ban::before {
  content: "\f54d";
}

.fa-circle-sort-up::before {
  content: "\e032";
}

.fa-sort-circle-up::before {
  content: "\e032";
}

.fa-terminal::before {
  content: "\f120";
}

.fa-mobile-button::before {
  content: "\f10b";
}

.fa-house-medical-flag::before {
  content: "\e514";
}

.fa-basket-shopping::before {
  content: "\f291";
}

.fa-shopping-basket::before {
  content: "\f291";
}

.fa-tape::before {
  content: "\f4db";
}

.fa-chestnut::before {
  content: "\e3f6";
}

.fa-bus-simple::before {
  content: "\f55e";
}

.fa-bus-alt::before {
  content: "\f55e";
}

.fa-eye::before {
  content: "\f06e";
}

.fa-face-sad-cry::before {
  content: "\f5b3";
}

.fa-sad-cry::before {
  content: "\f5b3";
}

.fa-heat::before {
  content: "\e00c";
}

.fa-ticket-airline::before {
  content: "\e29a";
}

.fa-boot-heeled::before {
  content: "\e33f";
}

.fa-arrows-minimize::before {
  content: "\e0a5";
}

.fa-compress-arrows::before {
  content: "\e0a5";
}

.fa-audio-description::before {
  content: "\f29e";
}

.fa-person-military-to-person::before {
  content: "\e54c";
}

.fa-file-shield::before {
  content: "\e4f0";
}

.fa-hexagon::before {
  content: "\f312";
}

.fa-manhole::before {
  content: "\e1d6";
}

.fa-user-slash::before {
  content: "\f506";
}

.fa-pen::before {
  content: "\f304";
}

.fa-tower-observation::before {
  content: "\e586";
}

.fa-floppy-disks::before {
  content: "\e183";
}

.fa-toilet-paper-blank-under::before {
  content: "\e29f";
}

.fa-toilet-paper-reverse-alt::before {
  content: "\e29f";
}

.fa-file-code::before {
  content: "\f1c9";
}

.fa-signal::before {
  content: "\f012";
}

.fa-signal-5::before {
  content: "\f012";
}

.fa-signal-perfect::before {
  content: "\f012";
}

.fa-pump::before {
  content: "\e442";
}

.fa-bus::before {
  content: "\f207";
}

.fa-heart-circle-xmark::before {
  content: "\e501";
}

.fa-arrow-up-left-from-circle::before {
  content: "\e09e";
}

.fa-house-chimney::before {
  content: "\e3af";
}

.fa-home-lg::before {
  content: "\e3af";
}

.fa-window-maximize::before {
  content: "\f2d0";
}

.fa-dryer::before {
  content: "\f861";
}

.fa-face-frown::before {
  content: "\f119";
}

.fa-frown::before {
  content: "\f119";
}

.fa-chess-bishop-piece::before {
  content: "\f43b";
}

.fa-chess-bishop-alt::before {
  content: "\f43b";
}

.fa-shirt-tank-top::before {
  content: "\e3c9";
}

.fa-diploma::before {
  content: "\f5ea";
}

.fa-scroll-ribbon::before {
  content: "\f5ea";
}

.fa-screencast::before {
  content: "\e23e";
}

.fa-walker::before {
  content: "\f831";
}

.fa-prescription::before {
  content: "\f5b1";
}

.fa-shop::before {
  content: "\f54f";
}

.fa-store-alt::before {
  content: "\f54f";
}

.fa-floppy-disk::before {
  content: "\f0c7";
}

.fa-save::before {
  content: "\f0c7";
}

.fa-vihara::before {
  content: "\f6a7";
}

.fa-face-kiss-closed-eyes::before {
  content: "\e37d";
}

.fa-scale-unbalanced::before {
  content: "\f515";
}

.fa-balance-scale-left::before {
  content: "\f515";
}

.fa-file-user::before {
  content: "\f65c";
}

.fa-user-police-tie::before {
  content: "\e334";
}

.fa-face-tongue-money::before {
  content: "\e39d";
}

.fa-tennis-ball::before {
  content: "\f45e";
}

.fa-square-l::before {
  content: "\e275";
}

.fa-sort-up::before {
  content: "\f0de";
}

.fa-sort-asc::before {
  content: "\f0de";
}

.fa-calendar-arrow-up::before {
  content: "\e0d1";
}

.fa-calendar-upload::before {
  content: "\e0d1";
}

.fa-comment-dots::before {
  content: "\f4ad";
}

.fa-commenting::before {
  content: "\f4ad";
}

.fa-plant-wilt::before {
  content: "\e5aa";
}

.fa-scarf::before {
  content: "\f7c1";
}

.fa-album-circle-plus::before {
  content: "\e48c";
}

.fa-user-nurse-hair-long::before {
  content: "\e45e";
}

.fa-diamond::before {
  content: "\f219";
}

.fa-square-left::before {
  content: "\f351";
}

.fa-arrow-alt-square-left::before {
  content: "\f351";
}

.fa-face-grin-squint::before {
  content: "\f585";
}

.fa-grin-squint::before {
  content: "\f585";
}

.fa-circle-ellipsis-vertical::before {
  content: "\e10b";
}

.fa-hand-holding-dollar::before {
  content: "\f4c0";
}

.fa-hand-holding-usd::before {
  content: "\f4c0";
}

.fa-grid-dividers::before {
  content: "\e3ad";
}

.fa-bacterium::before {
  content: "\e05a";
}

.fa-hand-pointer::before {
  content: "\f25a";
}

.fa-drum-steelpan::before {
  content: "\f56a";
}

.fa-hand-scissors::before {
  content: "\f257";
}

.fa-hands-praying::before {
  content: "\f684";
}

.fa-praying-hands::before {
  content: "\f684";
}

.fa-face-pensive::before {
  content: "\e384";
}

.fa-user-music::before {
  content: "\f8eb";
}

.fa-arrow-rotate-right::before {
  content: "\f01e";
}

.fa-arrow-right-rotate::before {
  content: "\f01e";
}

.fa-arrow-rotate-forward::before {
  content: "\f01e";
}

.fa-redo::before {
  content: "\f01e";
}

.fa-messages-dollar::before {
  content: "\f652";
}

.fa-comments-alt-dollar::before {
  content: "\f652";
}

.fa-sensor-on::before {
  content: "\e02b";
}

.fa-balloon::before {
  content: "\e2e3";
}

.fa-biohazard::before {
  content: "\f780";
}

.fa-chess-queen-piece::before {
  content: "\f446";
}

.fa-chess-queen-alt::before {
  content: "\f446";
}

.fa-location-crosshairs::before {
  content: "\f601";
}

.fa-location::before {
  content: "\f601";
}

.fa-mars-double::before {
  content: "\f227";
}

.fa-house-person-leave::before {
  content: "\e00f";
}

.fa-house-leave::before {
  content: "\e00f";
}

.fa-house-person-depart::before {
  content: "\e00f";
}

.fa-ruler-triangle::before {
  content: "\f61c";
}

.fa-card-club::before {
  content: "\e3e9";
}

.fa-child-dress::before {
  content: "\e59c";
}

.fa-users-between-lines::before {
  content: "\e591";
}

.fa-lungs-virus::before {
  content: "\e067";
}

.fa-spinner-third::before {
  content: "\f3f4";
}

.fa-face-grin-tears::before {
  content: "\f588";
}

.fa-grin-tears::before {
  content: "\f588";
}

.fa-phone::before {
  content: "\f095";
}

.fa-computer-mouse-scrollwheel::before {
  content: "\f8cd";
}

.fa-mouse-alt::before {
  content: "\f8cd";
}

.fa-calendar-xmark::before {
  content: "\f273";
}

.fa-calendar-times::before {
  content: "\f273";
}

.fa-child-reaching::before {
  content: "\e59d";
}

.fa-table-layout::before {
  content: "\e290";
}

.fa-narwhal::before {
  content: "\f6fe";
}

.fa-ramp-loading::before {
  content: "\f4d4";
}

.fa-calendar-circle-plus::before {
  content: "\e470";
}

.fa-toothbrush::before {
  content: "\f635";
}

.fa-border-inner::before {
  content: "\f84e";
}

.fa-paw-claws::before {
  content: "\f702";
}

.fa-kiwi-fruit::before {
  content: "\e30c";
}

.fa-traffic-light-slow::before {
  content: "\f639";
}

.fa-rectangle-code::before {
  content: "\e322";
}

.fa-head-side-virus::before {
  content: "\e064";
}

.fa-keyboard-brightness::before {
  content: "\e1c0";
}

.fa-books-medical::before {
  content: "\f7e8";
}

.fa-lightbulb-slash::before {
  content: "\f673";
}

.fa-house-blank::before {
  content: "\e487";
}

.fa-home-blank::before {
  content: "\e487";
}

.fa-square-5::before {
  content: "\e25a";
}

.fa-square-heart::before {
  content: "\f4c8";
}

.fa-heart-square::before {
  content: "\f4c8";
}

.fa-puzzle::before {
  content: "\e443";
}

.fa-user-gear::before {
  content: "\f4fe";
}

.fa-user-cog::before {
  content: "\f4fe";
}

.fa-pipe-circle-check::before {
  content: "\e436";
}

.fa-arrow-up-1-9::before {
  content: "\f163";
}

.fa-sort-numeric-up::before {
  content: "\f163";
}

.fa-octagon-exclamation::before {
  content: "\e204";
}

.fa-dial-low::before {
  content: "\e15d";
}

.fa-door-closed::before {
  content: "\f52a";
}

.fa-laptop-mobile::before {
  content: "\f87a";
}

.fa-phone-laptop::before {
  content: "\f87a";
}

.fa-conveyor-belt-boxes::before {
  content: "\f46f";
}

.fa-conveyor-belt-alt::before {
  content: "\f46f";
}

.fa-shield-virus::before {
  content: "\e06c";
}

.fa-starfighter-twin-ion-engine-advanced::before {
  content: "\e28e";
}

.fa-starfighter-alt-advanced::before {
  content: "\e28e";
}

.fa-dice-six::before {
  content: "\f526";
}

.fa-starfighter-twin-ion-engine::before {
  content: "\e038";
}

.fa-starfighter-alt::before {
  content: "\e038";
}

.fa-rocket-launch::before {
  content: "\e027";
}

.fa-mosquito-net::before {
  content: "\e52c";
}

.fa-vent-damper::before {
  content: "\e465";
}

.fa-bridge-water::before {
  content: "\e4ce";
}

.fa-ban-bug::before {
  content: "\f7f9";
}

.fa-debug::before {
  content: "\f7f9";
}

.fa-person-booth::before {
  content: "\f756";
}

.fa-text-width::before {
  content: "\f035";
}

.fa-garage-car::before {
  content: "\e00a";
}

.fa-square-kanban::before {
  content: "\e488";
}

.fa-hat-wizard::before {
  content: "\f6e8";
}

.fa-pen-fancy::before {
  content: "\f5ac";
}

.fa-coffee-pot::before {
  content: "\e002";
}

.fa-mouse-field::before {
  content: "\e5a8";
}

.fa-person-digging::before {
  content: "\f85e";
}

.fa-digging::before {
  content: "\f85e";
}

.fa-shower-down::before {
  content: "\e24d";
}

.fa-shower-alt::before {
  content: "\e24d";
}

.fa-box-circle-check::before {
  content: "\e0c4";
}

.fa-brightness::before {
  content: "\e0c9";
}

.fa-car-side-bolt::before {
  content: "\e344";
}

.fa-ornament::before {
  content: "\f7b8";
}

.fa-phone-arrow-down-left::before {
  content: "\e223";
}

.fa-phone-arrow-down::before {
  content: "\e223";
}

.fa-phone-incoming::before {
  content: "\e223";
}

.fa-cloud-word::before {
  content: "\e138";
}

.fa-hand-fingers-crossed::before {
  content: "\e1a3";
}

.fa-trash::before {
  content: "\f1f8";
}

.fa-gauge-simple::before {
  content: "\f629";
}

.fa-gauge-simple-med::before {
  content: "\f629";
}

.fa-tachometer-average::before {
  content: "\f629";
}

.fa-arrow-down-small-big::before {
  content: "\f88d";
}

.fa-sort-size-down-alt::before {
  content: "\f88d";
}

.fa-book-medical::before {
  content: "\f7e6";
}

.fa-face-melting::before {
  content: "\e483";
}

.fa-poo::before {
  content: "\f2fe";
}

.fa-pen-clip-slash::before {
  content: "\e20f";
}

.fa-pen-alt-slash::before {
  content: "\e20f";
}

.fa-quote-right::before {
  content: "\f10e";
}

.fa-quote-right-alt::before {
  content: "\f10e";
}

.fa-scroll-old::before {
  content: "\f70f";
}

.fa-guitars::before {
  content: "\f8bf";
}

.fa-phone-xmark::before {
  content: "\e227";
}

.fa-hose::before {
  content: "\e419";
}

.fa-clock-six::before {
  content: "\e352";
}

.fa-shirt::before {
  content: "\f553";
}

.fa-t-shirt::before {
  content: "\f553";
}

.fa-tshirt::before {
  content: "\f553";
}

.fa-square-r::before {
  content: "\e27c";
}

.fa-cubes::before {
  content: "\f1b3";
}

.fa-envelope-open-dollar::before {
  content: "\f657";
}

.fa-divide::before {
  content: "\f529";
}

.fa-sun-cloud::before {
  content: "\f763";
}

.fa-lamp-floor::before {
  content: "\e015";
}

.fa-square-7::before {
  content: "\e25c";
}

.fa-tenge-sign::before {
  content: "\f7d7";
}

.fa-tenge::before {
  content: "\f7d7";
}

.fa-headphones::before {
  content: "\f025";
}

.fa-hands-holding::before {
  content: "\f4c2";
}

.fa-campfire::before {
  content: "\f6ba";
}

.fa-circle-ampersand::before {
  content: "\e0f8";
}

.fa-snowflakes::before {
  content: "\f7cf";
}

.fa-hands-clapping::before {
  content: "\e1a8";
}

.fa-republican::before {
  content: "\f75e";
}

.fa-leaf-maple::before {
  content: "\f6f6";
}

.fa-arrow-left::before {
  content: "\f060";
}

.fa-person-circle-xmark::before {
  content: "\e543";
}

.fa-ruler::before {
  content: "\f545";
}

.fa-cup-straw-swoosh::before {
  content: "\e364";
}

.fa-temperature-sun::before {
  content: "\f76a";
}

.fa-temperature-hot::before {
  content: "\f76a";
}

.fa-align-left::before {
  content: "\f036";
}

.fa-dice-d6::before {
  content: "\f6d1";
}

.fa-restroom::before {
  content: "\f7bd";
}

.fa-high-definition::before {
  content: "\e1ae";
}

.fa-rectangle-hd::before {
  content: "\e1ae";
}

.fa-j::before {
  content: "J";
}

.fa-galaxy::before {
  content: "\e008";
}

.fa-users-viewfinder::before {
  content: "\e595";
}

.fa-file-video::before {
  content: "\f1c8";
}

.fa-cherries::before {
  content: "\e0ec";
}

.fa-up-right-from-square::before {
  content: "\f35d";
}

.fa-external-link-alt::before {
  content: "\f35d";
}

.fa-circle-sort::before {
  content: "\e030";
}

.fa-sort-circle::before {
  content: "\e030";
}

.fa-table-cells::before {
  content: "\f00a";
}

.fa-th::before {
  content: "\f00a";
}

.fa-file-pdf::before {
  content: "\f1c1";
}

.fa-siren::before {
  content: "\e02d";
}

.fa-arrow-up-to-dotted-line::before {
  content: "\e0a1";
}

.fa-image-landscape::before {
  content: "\e1b5";
}

.fa-landscape::before {
  content: "\e1b5";
}

.fa-tank-water::before {
  content: "\e452";
}

.fa-curling-stone::before {
  content: "\f44a";
}

.fa-curling::before {
  content: "\f44a";
}

.fa-gamepad-modern::before {
  content: "\e5a2";
}

.fa-gamepad-alt::before {
  content: "\e5a2";
}

.fa-messages-question::before {
  content: "\e1e7";
}

.fa-book-bible::before {
  content: "\f647";
}

.fa-bible::before {
  content: "\f647";
}

.fa-o::before {
  content: "O";
}

.fa-suitcase-medical::before {
  content: "\f0fa";
}

.fa-medkit::before {
  content: "\f0fa";
}

.fa-briefcase-arrow-right::before {
  content: "\e2f2";
}

.fa-expand-wide::before {
  content: "\f320";
}

.fa-clock-eleven-thirty::before {
  content: "\e348";
}

.fa-rv::before {
  content: "\f7be";
}

.fa-user-secret::before {
  content: "\f21b";
}

.fa-otter::before {
  content: "\f700";
}

.fa-dreidel::before {
  content: "\f792";
}

.fa-person-dress::before {
  content: "\f182";
}

.fa-female::before {
  content: "\f182";
}

.fa-comment-dollar::before {
  content: "\f651";
}

.fa-business-time::before {
  content: "\f64a";
}

.fa-briefcase-clock::before {
  content: "\f64a";
}

.fa-flower-tulip::before {
  content: "\f801";
}

.fa-people-pants-simple::before {
  content: "\e21a";
}

.fa-cloud-drizzle::before {
  content: "\f738";
}

.fa-table-cells-large::before {
  content: "\f009";
}

.fa-th-large::before {
  content: "\f009";
}

.fa-book-tanakh::before {
  content: "\f827";
}

.fa-tanakh::before {
  content: "\f827";
}

.fa-solar-system::before {
  content: "\e02f";
}

.fa-seal-question::before {
  content: "\e243";
}

.fa-phone-volume::before {
  content: "\f2a0";
}

.fa-volume-control-phone::before {
  content: "\f2a0";
}

.fa-disc-drive::before {
  content: "\f8b5";
}

.fa-hat-cowboy-side::before {
  content: "\f8c1";
}

.fa-table-rows::before {
  content: "\e292";
}

.fa-rows::before {
  content: "\e292";
}

.fa-location-exclamation::before {
  content: "\f608";
}

.fa-map-marker-exclamation::before {
  content: "\f608";
}

.fa-face-fearful::before {
  content: "\e375";
}

.fa-clipboard-user::before {
  content: "\f7f3";
}

.fa-bus-school::before {
  content: "\f5dd";
}

.fa-film-slash::before {
  content: "\e179";
}

.fa-square-arrow-down-right::before {
  content: "\e262";
}

.fa-book-sparkles::before {
  content: "\f6b8";
}

.fa-book-spells::before {
  content: "\f6b8";
}

.fa-washing-machine::before {
  content: "\f898";
}

.fa-washer::before {
  content: "\f898";
}

.fa-child::before {
  content: "\f1ae";
}

.fa-lira-sign::before {
  content: "\f195";
}

.fa-user-visor::before {
  content: "\e04c";
}

.fa-file-plus-minus::before {
  content: "\e177";
}

.fa-chess-clock-flip::before {
  content: "\f43e";
}

.fa-chess-clock-alt::before {
  content: "\f43e";
}

.fa-satellite::before {
  content: "\f7bf";
}

.fa-plane-lock::before {
  content: "\e558";
}

.fa-steering-wheel::before {
  content: "\f622";
}

.fa-tag::before {
  content: "\f02b";
}

.fa-stretcher::before {
  content: "\f825";
}

.fa-book-section::before {
  content: "\e0c1";
}

.fa-book-law::before {
  content: "\e0c1";
}

.fa-inboxes::before {
  content: "\e1bb";
}

.fa-coffee-bean::before {
  content: "\e13e";
}

.fa-brackets-curly::before {
  content: "\f7ea";
}

.fa-ellipsis-stroke-vertical::before {
  content: "\f39c";
}

.fa-ellipsis-v-alt::before {
  content: "\f39c";
}

.fa-comment::before {
  content: "\f075";
}

.fa-square-1::before {
  content: "\e256";
}

.fa-cake-candles::before {
  content: "\f1fd";
}

.fa-birthday-cake::before {
  content: "\f1fd";
}

.fa-cake::before {
  content: "\f1fd";
}

.fa-head-side::before {
  content: "\f6e9";
}

.fa-envelope::before {
  content: "\f0e0";
}

.fa-dolly-empty::before {
  content: "\f473";
}

.fa-face-tissue::before {
  content: "\e39c";
}

.fa-angles-up::before {
  content: "\f102";
}

.fa-angle-double-up::before {
  content: "\f102";
}

.fa-paperclip::before {
  content: "\f0c6";
}

.fa-chart-line-down::before {
  content: "\f64d";
}

.fa-arrow-right-to-city::before {
  content: "\e4b3";
}

.fa-lock-a::before {
  content: "\e422";
}

.fa-ribbon::before {
  content: "\f4d6";
}

.fa-lungs::before {
  content: "\f604";
}

.fa-person-pinball::before {
  content: "\e21d";
}

.fa-arrow-up-9-1::before {
  content: "\f887";
}

.fa-sort-numeric-up-alt::before {
  content: "\f887";
}

.fa-apple-core::before {
  content: "\e08f";
}

.fa-circle-y::before {
  content: "\e12f";
}

.fa-h6::before {
  content: "\e413";
}

.fa-litecoin-sign::before {
  content: "\e1d3";
}

.fa-circle-small::before {
  content: "\e122";
}

.fa-border-none::before {
  content: "\f850";
}

.fa-arrow-turn-down-left::before {
  content: "\e2e1";
}

.fa-circle-nodes::before {
  content: "\e4e2";
}

.fa-parachute-box::before {
  content: "\f4cd";
}

.fa-message-medical::before {
  content: "\f7f4";
}

.fa-comment-alt-medical::before {
  content: "\f7f4";
}

.fa-rugby-ball::before {
  content: "\e3c6";
}

.fa-comment-music::before {
  content: "\f8b0";
}

.fa-indent::before {
  content: "\f03c";
}

.fa-tree-deciduous::before {
  content: "\f400";
}

.fa-tree-alt::before {
  content: "\f400";
}

.fa-puzzle-piece-simple::before {
  content: "\e231";
}

.fa-puzzle-piece-alt::before {
  content: "\e231";
}

.fa-truck-field-un::before {
  content: "\e58e";
}

.fa-nfc-trash::before {
  content: "\e1fd";
}

.fa-hourglass::before {
  content: "\f254";
}

.fa-hourglass-empty::before {
  content: "\f254";
}

.fa-mountain::before {
  content: "\f6fc";
}

.fa-file-xmark::before {
  content: "\f317";
}

.fa-file-times::before {
  content: "\f317";
}

.fa-house-heart::before {
  content: "\f4c9";
}

.fa-home-heart::before {
  content: "\f4c9";
}

.fa-house-chimney-blank::before {
  content: "\e3b0";
}

.fa-meter-bolt::before {
  content: "\e1e9";
}

.fa-user-doctor::before {
  content: "\f0f0";
}

.fa-user-md::before {
  content: "\f0f0";
}

.fa-slash-back::before {
  content: "\\";
}

.fa-circle-info::before {
  content: "\f05a";
}

.fa-info-circle::before {
  content: "\f05a";
}

.fa-fishing-rod::before {
  content: "\e3a8";
}

.fa-hammer-crash::before {
  content: "\e414";
}

.fa-message-heart::before {
  content: "\e5c9";
}

.fa-cloud-meatball::before {
  content: "\f73b";
}

.fa-camera-polaroid::before {
  content: "\f8aa";
}

.fa-camera::before {
  content: "\f030";
}

.fa-camera-alt::before {
  content: "\f030";
}

.fa-square-virus::before {
  content: "\e578";
}

.fa-cart-arrow-up::before {
  content: "\e3ee";
}

.fa-meteor::before {
  content: "\f753";
}

.fa-car-on::before {
  content: "\e4dd";
}

.fa-sleigh::before {
  content: "\f7cc";
}

.fa-arrow-down-1-9::before {
  content: "\f162";
}

.fa-sort-numeric-asc::before {
  content: "\f162";
}

.fa-sort-numeric-down::before {
  content: "\f162";
}

.fa-buoy-mooring::before {
  content: "\e5b6";
}

.fa-square-4::before {
  content: "\e259";
}

.fa-hand-holding-droplet::before {
  content: "\f4c1";
}

.fa-hand-holding-water::before {
  content: "\f4c1";
}

.fa-tricycle-adult::before {
  content: "\e5c4";
}

.fa-waveform::before {
  content: "\f8f1";
}

.fa-water::before {
  content: "\f773";
}

.fa-star-sharp-half-stroke::before {
  content: "\e28d";
}

.fa-star-sharp-half-alt::before {
  content: "\e28d";
}

.fa-nfc-signal::before {
  content: "\e1fb";
}

.fa-plane-prop::before {
  content: "\e22b";
}

.fa-calendar-check::before {
  content: "\f274";
}

.fa-clock-desk::before {
  content: "\e134";
}

.fa-calendar-clock::before {
  content: "\e0d2";
}

.fa-calendar-time::before {
  content: "\e0d2";
}

.fa-braille::before {
  content: "\f2a1";
}

.fa-prescription-bottle-medical::before {
  content: "\f486";
}

.fa-prescription-bottle-alt::before {
  content: "\f486";
}

.fa-plate-utensils::before {
  content: "\e43b";
}

.fa-family-pants::before {
  content: "\e302";
}

.fa-hose-reel::before {
  content: "\e41a";
}

.fa-house-window::before {
  content: "\e3b3";
}

.fa-landmark::before {
  content: "\f66f";
}

.fa-truck::before {
  content: "\f0d1";
}

.fa-crosshairs::before {
  content: "\f05b";
}

.fa-cloud-rainbow::before {
  content: "\f73e";
}

.fa-person-cane::before {
  content: "\e53c";
}

.fa-alien::before {
  content: "\f8f5";
}

.fa-tent::before {
  content: "\e57d";
}

.fa-vest-patches::before {
  content: "\e086";
}

.fa-people-dress-simple::before {
  content: "\e218";
}

.fa-check-double::before {
  content: "\f560";
}

.fa-arrow-down-a-z::before {
  content: "\f15d";
}

.fa-sort-alpha-asc::before {
  content: "\f15d";
}

.fa-sort-alpha-down::before {
  content: "\f15d";
}

.fa-bowling-ball-pin::before {
  content: "\e0c3";
}

.fa-bell-school-slash::before {
  content: "\f5d6";
}

.fa-plus-large::before {
  content: "\e59e";
}

.fa-money-bill-wheat::before {
  content: "\e52a";
}

.fa-camera-viewfinder::before {
  content: "\e0da";
}

.fa-screenshot::before {
  content: "\e0da";
}

.fa-message-music::before {
  content: "\f8af";
}

.fa-comment-alt-music::before {
  content: "\f8af";
}

.fa-car-building::before {
  content: "\f859";
}

.fa-border-bottom-right::before {
  content: "\f854";
}

.fa-border-style-alt::before {
  content: "\f854";
}

.fa-octagon::before {
  content: "\f306";
}

.fa-comment-arrow-up-right::before {
  content: "\e145";
}

.fa-octagon-divide::before {
  content: "\e203";
}

.fa-cookie::before {
  content: "\f563";
}

.fa-arrow-rotate-left::before {
  content: "\f0e2";
}

.fa-arrow-left-rotate::before {
  content: "\f0e2";
}

.fa-arrow-rotate-back::before {
  content: "\f0e2";
}

.fa-arrow-rotate-backward::before {
  content: "\f0e2";
}

.fa-undo::before {
  content: "\f0e2";
}

.fa-tv-music::before {
  content: "\f8e6";
}

.fa-hard-drive::before {
  content: "\f0a0";
}

.fa-hdd::before {
  content: "\f0a0";
}

.fa-reel::before {
  content: "\e238";
}

.fa-face-grin-squint-tears::before {
  content: "\f586";
}

.fa-grin-squint-tears::before {
  content: "\f586";
}

.fa-dumbbell::before {
  content: "\f44b";
}

.fa-rectangle-list::before {
  content: "\f022";
}

.fa-list-alt::before {
  content: "\f022";
}

.fa-tarp-droplet::before {
  content: "\e57c";
}

.fa-alarm-exclamation::before {
  content: "\f843";
}

.fa-house-medical-circle-check::before {
  content: "\e511";
}

.fa-traffic-cone::before {
  content: "\f636";
}

.fa-grate::before {
  content: "\e193";
}

.fa-arrow-down-right::before {
  content: "\e093";
}

.fa-person-skiing-nordic::before {
  content: "\f7ca";
}

.fa-skiing-nordic::before {
  content: "\f7ca";
}

.fa-calendar-plus::before {
  content: "\f271";
}

.fa-person-from-portal::before {
  content: "\e023";
}

.fa-portal-exit::before {
  content: "\e023";
}

.fa-plane-arrival::before {
  content: "\f5af";
}

.fa-cowbell-circle-plus::before {
  content: "\f8b4";
}

.fa-cowbell-more::before {
  content: "\f8b4";
}

.fa-circle-left::before {
  content: "\f359";
}

.fa-arrow-alt-circle-left::before {
  content: "\f359";
}

.fa-distribute-spacing-vertical::before {
  content: "\e366";
}

.fa-signal-bars-fair::before {
  content: "\f692";
}

.fa-signal-alt-2::before {
  content: "\f692";
}

.fa-sportsball::before {
  content: "\e44b";
}

.fa-game-console-handheld-crank::before {
  content: "\e5b9";
}

.fa-train-subway::before {
  content: "\f239";
}

.fa-subway::before {
  content: "\f239";
}

.fa-chart-gantt::before {
  content: "\e0e4";
}

.fa-face-smile-upside-down::before {
  content: "\e395";
}

.fa-ball-pile::before {
  content: "\f77e";
}

.fa-badge-dollar::before {
  content: "\f645";
}

.fa-money-bills-simple::before {
  content: "\e1f4";
}

.fa-money-bills-alt::before {
  content: "\e1f4";
}

.fa-list-timeline::before {
  content: "\e1d1";
}

.fa-indian-rupee-sign::before {
  content: "\e1bc";
}

.fa-indian-rupee::before {
  content: "\e1bc";
}

.fa-inr::before {
  content: "\e1bc";
}

.fa-crop-simple::before {
  content: "\f565";
}

.fa-crop-alt::before {
  content: "\f565";
}

.fa-money-bill-1::before {
  content: "\f3d1";
}

.fa-money-bill-alt::before {
  content: "\f3d1";
}

.fa-left-long::before {
  content: "\f30a";
}

.fa-long-arrow-alt-left::before {
  content: "\f30a";
}

.fa-keyboard-down::before {
  content: "\e1c2";
}

.fa-circle-up-right::before {
  content: "\e129";
}

.fa-cloud-bolt-moon::before {
  content: "\f76d";
}

.fa-thunderstorm-moon::before {
  content: "\f76d";
}

.fa-dna::before {
  content: "\f471";
}

.fa-virus-slash::before {
  content: "\e075";
}

.fa-bracket-round-right::before {
  content: "\)";
}

.fa-circle-5::before {
  content: "\e0f2";
}

.fa-minus::before {
  content: "\f068";
}

.fa-subtract::before {
  content: "\f068";
}

.fa-fire-flame::before {
  content: "\f6df";
}

.fa-flame::before {
  content: "\f6df";
}

.fa-right-to-line::before {
  content: "\f34c";
}

.fa-arrow-alt-to-right::before {
  content: "\f34c";
}

.fa-gif::before {
  content: "\e190";
}

.fa-chess::before {
  content: "\f439";
}

.fa-trash-slash::before {
  content: "\e2b3";
}

.fa-arrow-left-long::before {
  content: "\f177";
}

.fa-long-arrow-left::before {
  content: "\f177";
}

.fa-plug-circle-check::before {
  content: "\e55c";
}

.fa-font-case::before {
  content: "\f866";
}

.fa-street-view::before {
  content: "\f21d";
}

.fa-arrow-down-left::before {
  content: "\e091";
}

.fa-franc-sign::before {
  content: "\e18f";
}

.fa-flask-round-poison::before {
  content: "\f6e0";
}

.fa-flask-poison::before {
  content: "\f6e0";
}

.fa-volume-off::before {
  content: "\f026";
}

.fa-book-circle-arrow-right::before {
  content: "\e0bc";
}

.fa-chart-user::before {
  content: "\f6a3";
}

.fa-user-chart::before {
  content: "\f6a3";
}

.fa-hands-asl-interpreting::before {
  content: "\f2a3";
}

.fa-american-sign-language-interpreting::before {
  content: "\f2a3";
}

.fa-asl-interpreting::before {
  content: "\f2a3";
}

.fa-hands-american-sign-language-interpreting::before {
  content: "\f2a3";
}

.fa-presentation-screen::before {
  content: "\f685";
}

.fa-presentation::before {
  content: "\f685";
}

.fa-circle-bolt::before {
  content: "\e0fe";
}

.fa-face-smile-halo::before {
  content: "\e38f";
}

.fa-cart-circle-arrow-down::before {
  content: "\e3ef";
}

.fa-house-person-return::before {
  content: "\e011";
}

.fa-house-person-arrive::before {
  content: "\e011";
}

.fa-house-return::before {
  content: "\e011";
}

.fa-message-xmark::before {
  content: "\f4ab";
}

.fa-comment-alt-times::before {
  content: "\f4ab";
}

.fa-message-times::before {
  content: "\f4ab";
}

.fa-file-certificate::before {
  content: "\f5f3";
}

.fa-file-award::before {
  content: "\f5f3";
}

.fa-user-doctor-hair-long::before {
  content: "\e459";
}

.fa-camera-security::before {
  content: "\f8fe";
}

.fa-camera-home::before {
  content: "\f8fe";
}

.fa-gear::before {
  content: "\f013";
}

.fa-cog::before {
  content: "\f013";
}

.fa-droplet-slash::before {
  content: "\f5c7";
}

.fa-tint-slash::before {
  content: "\f5c7";
}

.fa-book-heart::before {
  content: "\f499";
}

.fa-mosque::before {
  content: "\f678";
}

.fa-duck::before {
  content: "\f6d8";
}

.fa-mosquito::before {
  content: "\e52b";
}

.fa-star-of-david::before {
  content: "\f69a";
}

.fa-flag-swallowtail::before {
  content: "\f74c";
}

.fa-flag-alt::before {
  content: "\f74c";
}

.fa-person-military-rifle::before {
  content: "\e54b";
}

.fa-car-garage::before {
  content: "\f5e2";
}

.fa-cart-shopping::before {
  content: "\f07a";
}

.fa-shopping-cart::before {
  content: "\f07a";
}

.fa-book-font::before {
  content: "\e0bf";
}

.fa-shield-plus::before {
  content: "\e24a";
}

.fa-vials::before {
  content: "\f493";
}

.fa-eye-dropper-full::before {
  content: "\e172";
}

.fa-distribute-spacing-horizontal::before {
  content: "\e365";
}

.fa-tablet-rugged::before {
  content: "\f48f";
}

.fa-temperature-snow::before {
  content: "\f768";
}

.fa-temperature-frigid::before {
  content: "\f768";
}

.fa-moped::before {
  content: "\e3b9";
}

.fa-face-smile-plus::before {
  content: "\f5b9";
}

.fa-smile-plus::before {
  content: "\f5b9";
}

.fa-radio-tuner::before {
  content: "\f8d8";
}

.fa-radio-alt::before {
  content: "\f8d8";
}

.fa-face-swear::before {
  content: "\e399";
}

.fa-water-arrow-down::before {
  content: "\f774";
}

.fa-water-lower::before {
  content: "\f774";
}

.fa-scanner-touchscreen::before {
  content: "\f48a";
}

.fa-circle-7::before {
  content: "\e0f4";
}

.fa-plug-circle-plus::before {
  content: "\e55f";
}

.fa-person-ski-jumping::before {
  content: "\f7c7";
}

.fa-ski-jump::before {
  content: "\f7c7";
}

.fa-place-of-worship::before {
  content: "\f67f";
}

.fa-water-arrow-up::before {
  content: "\f775";
}

.fa-water-rise::before {
  content: "\f775";
}

.fa-waveform-lines::before {
  content: "\f8f2";
}

.fa-waveform-path::before {
  content: "\f8f2";
}

.fa-split::before {
  content: "\e254";
}

.fa-film-canister::before {
  content: "\f8b7";
}

.fa-film-cannister::before {
  content: "\f8b7";
}

.fa-folder-xmark::before {
  content: "\f65f";
}

.fa-folder-times::before {
  content: "\f65f";
}

.fa-toilet-paper-blank::before {
  content: "\f71f";
}

.fa-toilet-paper-alt::before {
  content: "\f71f";
}

.fa-tablet-screen::before {
  content: "\f3fc";
}

.fa-tablet-android-alt::before {
  content: "\f3fc";
}

.fa-hexagon-vertical-nft-slanted::before {
  content: "\e506";
}

.fa-folder-music::before {
  content: "\e18d";
}

.fa-display-medical::before {
  content: "\e166";
}

.fa-desktop-medical::before {
  content: "\e166";
}

.fa-share-all::before {
  content: "\f367";
}

.fa-peapod::before {
  content: "\e31c";
}

.fa-chess-clock::before {
  content: "\f43d";
}

.fa-axe::before {
  content: "\f6b2";
}

.fa-square-d::before {
  content: "\e268";
}

.fa-grip-vertical::before {
  content: "\f58e";
}

.fa-mobile-signal-out::before {
  content: "\e1f0";
}

.fa-arrow-turn-up::before {
  content: "\f148";
}

.fa-level-up::before {
  content: "\f148";
}

.fa-u::before {
  content: "U";
}

.fa-arrow-up-from-dotted-line::before {
  content: "\e09b";
}

.fa-square-root-variable::before {
  content: "\f698";
}

.fa-square-root-alt::before {
  content: "\f698";
}

.fa-light-switch-on::before {
  content: "\e019";
}

.fa-arrow-down-arrow-up::before {
  content: "\f883";
}

.fa-sort-alt::before {
  content: "\f883";
}

.fa-raindrops::before {
  content: "\f75c";
}

.fa-dash::before {
  content: "\e404";
}

.fa-minus-large::before {
  content: "\e404";
}

.fa-clock::before {
  content: "\f017";
}

.fa-clock-four::before {
  content: "\f017";
}

.fa-input-numeric::before {
  content: "\e1bd";
}

.fa-truck-tow::before {
  content: "\e2b8";
}

.fa-backward-step::before {
  content: "\f048";
}

.fa-step-backward::before {
  content: "\f048";
}

.fa-pallet::before {
  content: "\f482";
}

.fa-car-bolt::before {
  content: "\e341";
}

.fa-arrows-maximize::before {
  content: "\f31d";
}

.fa-expand-arrows::before {
  content: "\f31d";
}

.fa-faucet::before {
  content: "\e005";
}

.fa-cloud-sleet::before {
  content: "\f741";
}

.fa-lamp-street::before {
  content: "\e1c5";
}

.fa-list-radio::before {
  content: "\e1d0";
}

.fa-pen-nib-slash::before {
  content: "\e4a1";
}

.fa-baseball-bat-ball::before {
  content: "\f432";
}

.fa-square-up-left::before {
  content: "\e282";
}

.fa-overline::before {
  content: "\f876";
}

.fa-s::before {
  content: "S";
}

.fa-timeline::before {
  content: "\e29c";
}

.fa-keyboard::before {
  content: "\f11c";
}

.fa-arrows-from-dotted-line::before {
  content: "\e0a3";
}

.fa-usb-drive::before {
  content: "\f8e9";
}

.fa-ballot::before {
  content: "\f732";
}

.fa-caret-down::before {
  content: "\f0d7";
}

.fa-location-dot-slash::before {
  content: "\f605";
}

.fa-map-marker-alt-slash::before {
  content: "\f605";
}

.fa-cards::before {
  content: "\e3ed";
}

.fa-house-chimney-medical::before {
  content: "\f7f2";
}

.fa-clinic-medical::before {
  content: "\f7f2";
}

.fa-boxing-glove::before {
  content: "\f438";
}

.fa-glove-boxing::before {
  content: "\f438";
}

.fa-temperature-three-quarters::before {
  content: "\f2c8";
}

.fa-temperature-3::before {
  content: "\f2c8";
}

.fa-thermometer-3::before {
  content: "\f2c8";
}

.fa-thermometer-three-quarters::before {
  content: "\f2c8";
}

.fa-bell-school::before {
  content: "\f5d5";
}

.fa-mobile-screen::before {
  content: "\f3cf";
}

.fa-mobile-android-alt::before {
  content: "\f3cf";
}

.fa-plane-up::before {
  content: "\e22d";
}

.fa-folder-heart::before {
  content: "\e189";
}

.fa-circle-location-arrow::before {
  content: "\f602";
}

.fa-location-circle::before {
  content: "\f602";
}

.fa-face-head-bandage::before {
  content: "\e37a";
}

.fa-sushi-roll::before {
  content: "\e48b";
}

.fa-maki-roll::before {
  content: "\e48b";
}

.fa-makizushi::before {
  content: "\e48b";
}

.fa-car-bump::before {
  content: "\f5e0";
}

.fa-piggy-bank::before {
  content: "\f4d3";
}

.fa-racquet::before {
  content: "\f45a";
}

.fa-car-mirrors::before {
  content: "\e343";
}

.fa-industry-windows::before {
  content: "\f3b3";
}

.fa-industry-alt::before {
  content: "\f3b3";
}

.fa-bolt-auto::before {
  content: "\e0b6";
}

.fa-battery-half::before {
  content: "\f242";
}

.fa-battery-3::before {
  content: "\f242";
}

.fa-flux-capacitor::before {
  content: "\f8ba";
}

.fa-mountain-city::before {
  content: "\e52e";
}

.fa-coins::before {
  content: "\f51e";
}

.fa-honey-pot::before {
  content: "\e418";
}

.fa-olive::before {
  content: "\e316";
}

.fa-khanda::before {
  content: "\f66d";
}

.fa-filter-list::before {
  content: "\e17c";
}

.fa-outlet::before {
  content: "\e01c";
}

.fa-sliders::before {
  content: "\f1de";
}

.fa-sliders-h::before {
  content: "\f1de";
}

.fa-cauldron::before {
  content: "\f6bf";
}

.fa-people::before {
  content: "\e216";
}

.fa-folder-tree::before {
  content: "\f802";
}

.fa-network-wired::before {
  content: "\f6ff";
}

.fa-croissant::before {
  content: "\f7f6";
}

.fa-map-pin::before {
  content: "\f276";
}

.fa-hamsa::before {
  content: "\f665";
}

.fa-cent-sign::before {
  content: "\e3f5";
}

.fa-swords-laser::before {
  content: "\e03d";
}

.fa-flask::before {
  content: "\f0c3";
}

.fa-person-pregnant::before {
  content: "\e31e";
}

.fa-square-u::before {
  content: "\e281";
}

.fa-wand-sparkles::before {
  content: "\f72b";
}

.fa-router::before {
  content: "\f8da";
}

.fa-ellipsis-vertical::before {
  content: "\f142";
}

.fa-ellipsis-v::before {
  content: "\f142";
}

.fa-sword-laser-alt::before {
  content: "\e03c";
}

.fa-ticket::before {
  content: "\f145";
}

.fa-power-off::before {
  content: "\f011";
}

.fa-coin::before {
  content: "\f85c";
}

.fa-laptop-slash::before {
  content: "\e1c7";
}

.fa-right-long::before {
  content: "\f30b";
}

.fa-long-arrow-alt-right::before {
  content: "\f30b";
}

.fa-circle-b::before {
  content: "\e0fd";
}

.fa-person-dress-simple::before {
  content: "\e21c";
}

.fa-pipe-collar::before {
  content: "\e437";
}

.fa-lights-holiday::before {
  content: "\f7b2";
}

.fa-citrus::before {
  content: "\e2f4";
}

.fa-flag-usa::before {
  content: "\f74d";
}

.fa-laptop-file::before {
  content: "\e51d";
}

.fa-tty::before {
  content: "\f1e4";
}

.fa-teletype::before {
  content: "\f1e4";
}

.fa-chart-tree-map::before {
  content: "\e0ea";
}

.fa-diagram-next::before {
  content: "\e476";
}

.fa-person-rifle::before {
  content: "\e54e";
}

.fa-clock-five-thirty::before {
  content: "\e34a";
}

.fa-pipe-valve::before {
  content: "\e439";
}

.fa-arrow-up-from-arc::before {
  content: "\e4b4";
}

.fa-face-spiral-eyes::before {
  content: "\e485";
}

.fa-compress-wide::before {
  content: "\f326";
}

.fa-circle-phone-hangup::before {
  content: "\e11d";
}

.fa-phone-circle-down::before {
  content: "\e11d";
}

.fa-house-medical-circle-exclamation::before {
  content: "\e512";
}

.fa-badminton::before {
  content: "\e33a";
}

.fa-closed-captioning::before {
  content: "\f20a";
}

.fa-person-hiking::before {
  content: "\f6ec";
}

.fa-hiking::before {
  content: "\f6ec";
}

.fa-right-from-line::before {
  content: "\f347";
}

.fa-arrow-alt-from-left::before {
  content: "\f347";
}

.fa-venus-double::before {
  content: "\f226";
}

.fa-images::before {
  content: "\f302";
}

.fa-calculator::before {
  content: "\f1ec";
}

.fa-shuttlecock::before {
  content: "\f45b";
}

.fa-user-hair::before {
  content: "\e45a";
}

.fa-eye-evil::before {
  content: "\f6db";
}

.fa-people-pulling::before {
  content: "\e535";
}

.fa-n::before {
  content: "N";
}

.fa-garage::before {
  content: "\e009";
}

.fa-cable-car::before {
  content: "\f7da";
}

.fa-tram::before {
  content: "\f7da";
}

.fa-shovel-snow::before {
  content: "\f7c3";
}

.fa-cloud-rain::before {
  content: "\f73d";
}

.fa-face-lying::before {
  content: "\e37e";
}

.fa-sprinkler::before {
  content: "\e035";
}

.fa-building-circle-xmark::before {
  content: "\e4d4";
}

.fa-person-sledding::before {
  content: "\f7cb";
}

.fa-sledding::before {
  content: "\f7cb";
}

.fa-game-console-handheld::before {
  content: "\f8bb";
}

.fa-ship::before {
  content: "\f21a";
}

.fa-clock-six-thirty::before {
  content: "\e353";
}

.fa-battery-slash::before {
  content: "\f377";
}

.fa-tugrik-sign::before {
  content: "\e2ba";
}

.fa-arrows-down-to-line::before {
  content: "\e4b8";
}

.fa-download::before {
  content: "\f019";
}

.fa-shelves::before {
  content: "\f480";
}

.fa-inventory::before {
  content: "\f480";
}

.fa-cloud-snow::before {
  content: "\f742";
}

.fa-face-grin::before {
  content: "\f580";
}

.fa-grin::before {
  content: "\f580";
}

.fa-delete-left::before {
  content: "\f55a";
}

.fa-backspace::before {
  content: "\f55a";
}

.fa-oven::before {
  content: "\e01d";
}

.fa-eye-dropper::before {
  content: "\f1fb";
}

.fa-eye-dropper-empty::before {
  content: "\f1fb";
}

.fa-eyedropper::before {
  content: "\f1fb";
}

.fa-comment-captions::before {
  content: "\e146";
}

.fa-comments-question::before {
  content: "\e14e";
}

.fa-scribble::before {
  content: "\e23f";
}

.fa-rotate-exclamation::before {
  content: "\e23c";
}

.fa-file-circle-check::before {
  content: "\e5a0";
}

.fa-glass::before {
  content: "\f804";
}

.fa-loader::before {
  content: "\e1d4";
}

.fa-forward::before {
  content: "\f04e";
}

.fa-user-pilot::before {
  content: "\e2c0";
}

.fa-mobile::before {
  content: "\f3ce";
}

.fa-mobile-android::before {
  content: "\f3ce";
}

.fa-mobile-phone::before {
  content: "\f3ce";
}

.fa-code-pull-request-closed::before {
  content: "\e3f9";
}

.fa-face-meh::before {
  content: "\f11a";
}

.fa-meh::before {
  content: "\f11a";
}

.fa-align-center::before {
  content: "\f037";
}

.fa-book-skull::before {
  content: "\f6b7";
}

.fa-book-dead::before {
  content: "\f6b7";
}

.fa-id-card::before {
  content: "\f2c2";
}

.fa-drivers-license::before {
  content: "\f2c2";
}

.fa-face-dotted::before {
  content: "\e47f";
}

.fa-face-worried::before {
  content: "\e3a3";
}

.fa-outdent::before {
  content: "\f03b";
}

.fa-dedent::before {
  content: "\f03b";
}

.fa-heart-circle-exclamation::before {
  content: "\e4fe";
}

.fa-house::before {
  content: "\f015";
}

.fa-home::before {
  content: "\f015";
}

.fa-home-alt::before {
  content: "\f015";
}

.fa-home-lg-alt::before {
  content: "\f015";
}

.fa-vector-circle::before {
  content: "\e2c6";
}

.fa-car-circle-bolt::before {
  content: "\e342";
}

.fa-calendar-week::before {
  content: "\f784";
}

.fa-flying-disc::before {
  content: "\e3a9";
}

.fa-laptop-medical::before {
  content: "\f812";
}

.fa-square-down-right::before {
  content: "\e26c";
}

.fa-b::before {
  content: "B";
}

.fa-seat-airline::before {
  content: "\e244";
}

.fa-moon-over-sun::before {
  content: "\f74a";
}

.fa-eclipse-alt::before {
  content: "\f74a";
}

.fa-pipe::before {
  content: "\|";
}

.fa-file-medical::before {
  content: "\f477";
}

.fa-potato::before {
  content: "\e440";
}

.fa-dice-one::before {
  content: "\f525";
}

.fa-circle-a::before {
  content: "\e0f7";
}

.fa-helmet-battle::before {
  content: "\f6eb";
}

.fa-butter::before {
  content: "\e3e4";
}

.fa-blanket-fire::before {
  content: "\e3da";
}

.fa-kiwi-bird::before {
  content: "\f535";
}

.fa-castle::before {
  content: "\e0de";
}

.fa-golf-club::before {
  content: "\f451";
}

.fa-arrow-right-arrow-left::before {
  content: "\f0ec";
}

.fa-exchange::before {
  content: "\f0ec";
}

.fa-rotate-right::before {
  content: "\f2f9";
}

.fa-redo-alt::before {
  content: "\f2f9";
}

.fa-rotate-forward::before {
  content: "\f2f9";
}

.fa-utensils::before {
  content: "\f2e7";
}

.fa-cutlery::before {
  content: "\f2e7";
}

.fa-arrow-up-wide-short::before {
  content: "\f161";
}

.fa-sort-amount-up::before {
  content: "\f161";
}

.fa-balloons::before {
  content: "\e2e4";
}

.fa-mill-sign::before {
  content: "\e1ed";
}

.fa-bowl-rice::before {
  content: "\e2eb";
}

.fa-timeline-arrow::before {
  content: "\e29d";
}

.fa-skull::before {
  content: "\f54c";
}

.fa-game-board-simple::before {
  content: "\f868";
}

.fa-game-board-alt::before {
  content: "\f868";
}

.fa-circle-video::before {
  content: "\e12b";
}

.fa-video-circle::before {
  content: "\e12b";
}

.fa-chart-scatter-bubble::before {
  content: "\e0e9";
}

.fa-house-turret::before {
  content: "\e1b4";
}

.fa-banana::before {
  content: "\e2e5";
}

.fa-hand-holding-skull::before {
  content: "\e1a4";
}

.fa-people-dress::before {
  content: "\e217";
}

.fa-loveseat::before {
  content: "\f4cc";
}

.fa-couch-small::before {
  content: "\f4cc";
}

.fa-tower-broadcast::before {
  content: "\f519";
}

.fa-broadcast-tower::before {
  content: "\f519";
}

.fa-truck-pickup::before {
  content: "\f63c";
}

.fa-block-quote::before {
  content: "\e0b5";
}

.fa-up-long::before {
  content: "\f30c";
}

.fa-long-arrow-alt-up::before {
  content: "\f30c";
}

.fa-stop::before {
  content: "\f04d";
}

.fa-code-merge::before {
  content: "\f387";
}

.fa-money-check-dollar-pen::before {
  content: "\f873";
}

.fa-money-check-edit-alt::before {
  content: "\f873";
}

.fa-up-from-line::before {
  content: "\f346";
}

.fa-arrow-alt-from-bottom::before {
  content: "\f346";
}

.fa-upload::before {
  content: "\f093";
}

.fa-hurricane::before {
  content: "\f751";
}

.fa-people-pants::before {
  content: "\e219";
}

.fa-mound::before {
  content: "\e52d";
}

.fa-windsock::before {
  content: "\f777";
}

.fa-circle-half::before {
  content: "\e110";
}

.fa-brake-warning::before {
  content: "\e0c7";
}

.fa-toilet-portable::before {
  content: "\e583";
}

.fa-compact-disc::before {
  content: "\f51f";
}

.fa-file-arrow-down::before {
  content: "\f56d";
}

.fa-file-download::before {
  content: "\f56d";
}

.fa-saxophone-fire::before {
  content: "\f8db";
}

.fa-sax-hot::before {
  content: "\f8db";
}

.fa-camera-web-slash::before {
  content: "\f833";
}

.fa-webcam-slash::before {
  content: "\f833";
}

.fa-folder-medical::before {
  content: "\e18c";
}

.fa-folder-gear::before {
  content: "\e187";
}

.fa-folder-cog::before {
  content: "\e187";
}

.fa-hand-wave::before {
  content: "\e1a7";
}

.fa-arrow-up-arrow-down::before {
  content: "\e099";
}

.fa-sort-up-down::before {
  content: "\e099";
}

.fa-caravan::before {
  content: "\f8ff";
}

.fa-shield-cat::before {
  content: "\e572";
}

.fa-message-slash::before {
  content: "\f4a9";
}

.fa-comment-alt-slash::before {
  content: "\f4a9";
}

.fa-bolt::before {
  content: "\f0e7";
}

.fa-zap::before {
  content: "\f0e7";
}

.fa-trash-can-check::before {
  content: "\e2a9";
}

.fa-glass-water::before {
  content: "\e4f4";
}

.fa-oil-well::before {
  content: "\e532";
}

.fa-person-simple::before {
  content: "\e220";
}

.fa-vault::before {
  content: "\e2c5";
}

.fa-mars::before {
  content: "\f222";
}

.fa-toilet::before {
  content: "\f7d8";
}

.fa-plane-circle-xmark::before {
  content: "\e557";
}

.fa-yen-sign::before {
  content: "\f157";
}

.fa-cny::before {
  content: "\f157";
}

.fa-jpy::before {
  content: "\f157";
}

.fa-rmb::before {
  content: "\f157";
}

.fa-yen::before {
  content: "\f157";
}

.fa-notes::before {
  content: "\e202";
}

.fa-ruble-sign::before {
  content: "\f158";
}

.fa-rouble::before {
  content: "\f158";
}

.fa-rub::before {
  content: "\f158";
}

.fa-ruble::before {
  content: "\f158";
}

.fa-trash-undo::before {
  content: "\f895";
}

.fa-trash-arrow-turn-left::before {
  content: "\f895";
}

.fa-champagne-glass::before {
  content: "\f79e";
}

.fa-glass-champagne::before {
  content: "\f79e";
}

.fa-objects-align-center-horizontal::before {
  content: "\e3bc";
}

.fa-sun::before {
  content: "\f185";
}

.fa-trash-can-slash::before {
  content: "\e2ad";
}

.fa-trash-alt-slash::before {
  content: "\e2ad";
}

.fa-screen-users::before {
  content: "\f63d";
}

.fa-users-class::before {
  content: "\f63d";
}

.fa-guitar::before {
  content: "\f7a6";
}

.fa-square-arrow-left::before {
  content: "\f33a";
}

.fa-arrow-square-left::before {
  content: "\f33a";
}

.fa-square-8::before {
  content: "\e25d";
}

.fa-face-smile-hearts::before {
  content: "\e390";
}

.fa-brackets-square::before {
  content: "\f7e9";
}

.fa-brackets::before {
  content: "\f7e9";
}

.fa-laptop-arrow-down::before {
  content: "\e1c6";
}

.fa-hockey-stick-puck::before {
  content: "\e3ae";
}

.fa-house-tree::before {
  content: "\e1b3";
}

.fa-signal-fair::before {
  content: "\f68d";
}

.fa-signal-2::before {
  content: "\f68d";
}

.fa-face-laugh-wink::before {
  content: "\f59c";
}

.fa-laugh-wink::before {
  content: "\f59c";
}

.fa-circle-dollar::before {
  content: "\f2e8";
}

.fa-dollar-circle::before {
  content: "\f2e8";
}

.fa-usd-circle::before {
  content: "\f2e8";
}

.fa-horse-head::before {
  content: "\f7ab";
}

.fa-arrows-repeat::before {
  content: "\f364";
}

.fa-repeat-alt::before {
  content: "\f364";
}

.fa-bore-hole::before {
  content: "\e4c3";
}

.fa-industry::before {
  content: "\f275";
}

.fa-image-polaroid::before {
  content: "\f8c4";
}

.fa-wave-triangle::before {
  content: "\f89a";
}

.fa-circle-down::before {
  content: "\f358";
}

.fa-arrow-alt-circle-down::before {
  content: "\f358";
}

.fa-grill::before {
  content: "\e5a3";
}

.fa-arrows-turn-to-dots::before {
  content: "\e4c1";
}

.fa-chart-mixed::before {
  content: "\f643";
}

.fa-analytics::before {
  content: "\f643";
}

.fa-florin-sign::before {
  content: "\e184";
}

.fa-arrow-down-short-wide::before {
  content: "\f884";
}

.fa-sort-amount-desc::before {
  content: "\f884";
}

.fa-sort-amount-down-alt::before {
  content: "\f884";
}

.fa-less-than::before {
  content: "\<";
}

.fa-display-code::before {
  content: "\e165";
}

.fa-desktop-code::before {
  content: "\e165";
}

.fa-face-drooling::before {
  content: "\e372";
}

.fa-oil-temperature::before {
  content: "\f614";
}

.fa-oil-temp::before {
  content: "\f614";
}

.fa-square-question::before {
  content: "\f2fd";
}

.fa-question-square::before {
  content: "\f2fd";
}

.fa-air-conditioner::before {
  content: "\f8f4";
}

.fa-angle-down::before {
  content: "\f107";
}

.fa-mountains::before {
  content: "\f6fd";
}

.fa-omega::before {
  content: "\f67a";
}

.fa-car-tunnel::before {
  content: "\e4de";
}

.fa-person-dolly-empty::before {
  content: "\f4d1";
}

.fa-pan-food::before {
  content: "\e42b";
}

.fa-head-side-cough::before {
  content: "\e061";
}

.fa-grip-lines::before {
  content: "\f7a4";
}

.fa-thumbs-down::before {
  content: "\f165";
}

.fa-user-lock::before {
  content: "\f502";
}

.fa-arrow-right-long::before {
  content: "\f178";
}

.fa-long-arrow-right::before {
  content: "\f178";
}

.fa-tickets-airline::before {
  content: "\e29b";
}

.fa-anchor-circle-xmark::before {
  content: "\e4ac";
}

.fa-ellipsis::before {
  content: "\f141";
}

.fa-ellipsis-h::before {
  content: "\f141";
}

.fa-nfc-slash::before {
  content: "\e1fc";
}

.fa-chess-pawn::before {
  content: "\f443";
}

.fa-kit-medical::before {
  content: "\f479";
}

.fa-first-aid::before {
  content: "\f479";
}

.fa-grid-2-plus::before {
  content: "\e197";
}

.fa-bells::before {
  content: "\f77f";
}

.fa-person-through-window::before {
  content: "\e5a9";
}

.fa-toolbox::before {
  content: "\f552";
}

.fa-envelope-dot::before {
  content: "\e16f";
}

.fa-envelope-badge::before {
  content: "\e16f";
}

.fa-hands-holding-circle::before {
  content: "\e4fb";
}

.fa-bug::before {
  content: "\f188";
}

.fa-bowl-chopsticks::before {
  content: "\e2e9";
}

.fa-credit-card::before {
  content: "\f09d";
}

.fa-credit-card-alt::before {
  content: "\f09d";
}

.fa-circle-s::before {
  content: "\e121";
}

.fa-box-ballot::before {
  content: "\f735";
}

.fa-car::before {
  content: "\f1b9";
}

.fa-automobile::before {
  content: "\f1b9";
}

.fa-hand-holding-hand::before {
  content: "\e4f7";
}

.fa-user-tie-hair::before {
  content: "\e45f";
}

.fa-podium-star::before {
  content: "\f758";
}

.fa-user-hair-mullet::before {
  content: "\e45c";
}

.fa-business-front::before {
  content: "\e45c";
}

.fa-party-back::before {
  content: "\e45c";
}

.fa-trian-balbot::before {
  content: "\e45c";
}

.fa-microphone-stand::before {
  content: "\f8cb";
}

.fa-book-open-reader::before {
  content: "\f5da";
}

.fa-book-reader::before {
  content: "\f5da";
}

.fa-family-dress::before {
  content: "\e301";
}

.fa-circle-x::before {
  content: "\e12e";
}

.fa-cabin::before {
  content: "\e46d";
}

.fa-mountain-sun::before {
  content: "\e52f";
}

.fa-chart-simple-horizontal::before {
  content: "\e474";
}

.fa-arrows-left-right-to-line::before {
  content: "\e4ba";
}

.fa-hand-back-point-left::before {
  content: "\e19f";
}

.fa-message-dots::before {
  content: "\f4a3";
}

.fa-comment-alt-dots::before {
  content: "\f4a3";
}

.fa-messaging::before {
  content: "\f4a3";
}

.fa-file-heart::before {
  content: "\e176";
}

.fa-beer-mug::before {
  content: "\e0b3";
}

.fa-beer-foam::before {
  content: "\e0b3";
}

.fa-dice-d20::before {
  content: "\f6cf";
}

.fa-drone::before {
  content: "\f85f";
}

.fa-truck-droplet::before {
  content: "\e58c";
}

.fa-file-circle-xmark::before {
  content: "\e5a1";
}

.fa-temperature-arrow-up::before {
  content: "\e040";
}

.fa-temperature-up::before {
  content: "\e040";
}

.fa-medal::before {
  content: "\f5a2";
}

.fa-bed::before {
  content: "\f236";
}

.fa-book-copy::before {
  content: "\e0be";
}

.fa-square-h::before {
  content: "\f0fd";
}

.fa-h-square::before {
  content: "\f0fd";
}

.fa-square-c::before {
  content: "\e266";
}

.fa-clock-two::before {
  content: "\e35a";
}

.fa-square-ellipsis-vertical::before {
  content: "\e26f";
}

.fa-podcast::before {
  content: "\f2ce";
}

.fa-bee::before {
  content: "\e0b2";
}

.fa-temperature-full::before {
  content: "\f2c7";
}

.fa-temperature-4::before {
  content: "\f2c7";
}

.fa-thermometer-4::before {
  content: "\f2c7";
}

.fa-thermometer-full::before {
  content: "\f2c7";
}

.fa-bell::before {
  content: "\f0f3";
}

.fa-candy-bar::before {
  content: "\e3e8";
}

.fa-chocolate-bar::before {
  content: "\e3e8";
}

.fa-xmark-large::before {
  content: "\e59b";
}

.fa-pinata::before {
  content: "\e3c3";
}

.fa-arrows-from-line::before {
  content: "\e0a4";
}

.fa-superscript::before {
  content: "\f12b";
}

.fa-bowl-spoon::before {
  content: "\e3e0";
}

.fa-hexagon-check::before {
  content: "\e416";
}

.fa-plug-circle-xmark::before {
  content: "\e560";
}

.fa-star-of-life::before {
  content: "\f621";
}

.fa-phone-slash::before {
  content: "\f3dd";
}

.fa-traffic-light-stop::before {
  content: "\f63a";
}

.fa-paint-roller::before {
  content: "\f5aa";
}

.fa-accent-grave::before {
  content: "\`";
}

.fa-handshake-angle::before {
  content: "\f4c4";
}

.fa-hands-helping::before {
  content: "\f4c4";
}

.fa-circle-0::before {
  content: "\e0ed";
}

.fa-dial-med-low::before {
  content: "\e160";
}

.fa-location-dot::before {
  content: "\f3c5";
}

.fa-map-marker-alt::before {
  content: "\f3c5";
}

.fa-crab::before {
  content: "\e3ff";
}

.fa-box-open-full::before {
  content: "\f49c";
}

.fa-box-full::before {
  content: "\f49c";
}

.fa-file::before {
  content: "\f15b";
}

.fa-greater-than::before {
  content: "\>";
}

.fa-quotes::before {
  content: "\e234";
}

.fa-pretzel::before {
  content: "\e441";
}

.fa-person-swimming::before {
  content: "\f5c4";
}

.fa-swimmer::before {
  content: "\f5c4";
}

.fa-arrow-down::before {
  content: "\f063";
}

.fa-user-robot-xmarks::before {
  content: "\e4a7";
}

.fa-message-quote::before {
  content: "\e1e4";
}

.fa-comment-alt-quote::before {
  content: "\e1e4";
}

.fa-candy-corn::before {
  content: "\f6bd";
}

.fa-folder-magnifying-glass::before {
  content: "\e18b";
}

.fa-folder-search::before {
  content: "\e18b";
}

.fa-notebook::before {
  content: "\e201";
}

.fa-droplet::before {
  content: "\f043";
}

.fa-tint::before {
  content: "\f043";
}

.fa-bullseye-pointer::before {
  content: "\f649";
}

.fa-eraser::before {
  content: "\f12d";
}

.fa-hexagon-image::before {
  content: "\e504";
}

.fa-earth-americas::before {
  content: "\f57d";
}

.fa-earth::before {
  content: "\f57d";
}

.fa-earth-america::before {
  content: "\f57d";
}

.fa-globe-americas::before {
  content: "\f57d";
}

.fa-crate-apple::before {
  content: "\f6b1";
}

.fa-apple-crate::before {
  content: "\f6b1";
}

.fa-person-burst::before {
  content: "\e53b";
}

.fa-game-board::before {
  content: "\f867";
}

.fa-hat-chef::before {
  content: "\f86b";
}

.fa-hand-back-point-right::before {
  content: "\e1a1";
}

.fa-dove::before {
  content: "\f4ba";
}

.fa-snowflake-droplets::before {
  content: "\e5c1";
}

.fa-battery-empty::before {
  content: "\f244";
}

.fa-battery-0::before {
  content: "\f244";
}

.fa-grid-4::before {
  content: "\e198";
}

.fa-socks::before {
  content: "\f696";
}

.fa-face-sunglasses::before {
  content: "\e398";
}

.fa-inbox::before {
  content: "\f01c";
}

.fa-square-0::before {
  content: "\e255";
}

.fa-section::before {
  content: "\e447";
}

.fa-square-this-way-up::before {
  content: "\f49f";
}

.fa-box-up::before {
  content: "\f49f";
}

.fa-gauge-high::before {
  content: "\f625";
}

.fa-tachometer-alt::before {
  content: "\f625";
}

.fa-tachometer-alt-fast::before {
  content: "\f625";
}

.fa-square-ampersand::before {
  content: "\e260";
}

.fa-envelope-open-text::before {
  content: "\f658";
}

.fa-lamp-desk::before {
  content: "\e014";
}

.fa-hospital::before {
  content: "\f0f8";
}

.fa-hospital-alt::before {
  content: "\f0f8";
}

.fa-hospital-wide::before {
  content: "\f0f8";
}

.fa-poll-people::before {
  content: "\f759";
}

.fa-whiskey-glass-ice::before {
  content: "\f7a1";
}

.fa-glass-whiskey-rocks::before {
  content: "\f7a1";
}

.fa-wine-bottle::before {
  content: "\f72f";
}

.fa-chess-rook::before {
  content: "\f447";
}

.fa-user-bounty-hunter::before {
  content: "\e2bf";
}

.fa-bars-staggered::before {
  content: "\f550";
}

.fa-reorder::before {
  content: "\f550";
}

.fa-stream::before {
  content: "\f550";
}

.fa-diagram-sankey::before {
  content: "\e158";
}

.fa-cloud-hail-mixed::before {
  content: "\f73a";
}

.fa-circle-up-left::before {
  content: "\e128";
}

.fa-dharmachakra::before {
  content: "\f655";
}

.fa-objects-align-left::before {
  content: "\e3be";
}

.fa-oil-can-drip::before {
  content: "\e205";
}

.fa-face-smiling-hands::before {
  content: "\e396";
}

.fa-broccoli::before {
  content: "\e3e2";
}

.fa-route-interstate::before {
  content: "\f61b";
}

.fa-ear-muffs::before {
  content: "\f795";
}

.fa-hotdog::before {
  content: "\f80f";
}

.fa-transporter-empty::before {
  content: "\e046";
}

.fa-person-walking-with-cane::before {
  content: "\f29d";
}

.fa-blind::before {
  content: "\f29d";
}

.fa-angle-90::before {
  content: "\e08d";
}

.fa-rectangle-terminal::before {
  content: "\e236";
}

.fa-kite::before {
  content: "\f6f4";
}

.fa-drum::before {
  content: "\f569";
}

.fa-scrubber::before {
  content: "\f2f8";
}

.fa-ice-cream::before {
  content: "\f810";
}

.fa-heart-circle-bolt::before {
  content: "\e4fc";
}

.fa-fish-bones::before {
  content: "\e304";
}

.fa-deer-rudolph::before {
  content: "\f78f";
}

.fa-fax::before {
  content: "\f1ac";
}

.fa-paragraph::before {
  content: "\f1dd";
}

.fa-head-side-heart::before {
  content: "\e1aa";
}

.fa-square-e::before {
  content: "\e26d";
}

.fa-meter-fire::before {
  content: "\e1eb";
}

.fa-cloud-hail::before {
  content: "\f739";
}

.fa-check-to-slot::before {
  content: "\f772";
}

.fa-vote-yea::before {
  content: "\f772";
}

.fa-money-from-bracket::before {
  content: "\e312";
}

.fa-star-half::before {
  content: "\f089";
}

.fa-car-bus::before {
  content: "\f85a";
}

.fa-speaker::before {
  content: "\f8df";
}

.fa-timer::before {
  content: "\e29e";
}

.fa-boxes-stacked::before {
  content: "\f468";
}

.fa-boxes::before {
  content: "\f468";
}

.fa-boxes-alt::before {
  content: "\f468";
}

.fa-grill-hot::before {
  content: "\e5a5";
}

.fa-ballot-check::before {
  content: "\f733";
}

.fa-link::before {
  content: "\f0c1";
}

.fa-chain::before {
  content: "\f0c1";
}

.fa-ear-listen::before {
  content: "\f2a2";
}

.fa-assistive-listening-systems::before {
  content: "\f2a2";
}

.fa-file-minus::before {
  content: "\f318";
}

.fa-tree-city::before {
  content: "\e587";
}

.fa-play::before {
  content: "\f04b";
}

.fa-font::before {
  content: "\f031";
}

.fa-cup-togo::before {
  content: "\f6c5";
}

.fa-coffee-togo::before {
  content: "\f6c5";
}

.fa-square-down-left::before {
  content: "\e26b";
}

.fa-burger-lettuce::before {
  content: "\e3e3";
}

.fa-rupiah-sign::before {
  content: "\e23d";
}

.fa-magnifying-glass::before {
  content: "\f002";
}

.fa-search::before {
  content: "\f002";
}

.fa-table-tennis-paddle-ball::before {
  content: "\f45d";
}

.fa-ping-pong-paddle-ball::before {
  content: "\f45d";
}

.fa-table-tennis::before {
  content: "\f45d";
}

.fa-person-dots-from-line::before {
  content: "\f470";
}

.fa-diagnoses::before {
  content: "\f470";
}

.fa-chevrons-down::before {
  content: "\f322";
}

.fa-chevron-double-down::before {
  content: "\f322";
}

.fa-trash-can-arrow-up::before {
  content: "\f82a";
}

.fa-trash-restore-alt::before {
  content: "\f82a";
}

.fa-signal-good::before {
  content: "\f68e";
}

.fa-signal-3::before {
  content: "\f68e";
}

.fa-location-question::before {
  content: "\f60b";
}

.fa-map-marker-question::before {
  content: "\f60b";
}

.fa-floppy-disk-circle-xmark::before {
  content: "\e181";
}

.fa-floppy-disk-times::before {
  content: "\e181";
}

.fa-save-circle-xmark::before {
  content: "\e181";
}

.fa-save-times::before {
  content: "\e181";
}

.fa-naira-sign::before {
  content: "\e1f6";
}

.fa-peach::before {
  content: "\e20b";
}

.fa-taxi-bus::before {
  content: "\e298";
}

.fa-bracket-curly::before {
  content: "\{";
}

.fa-bracket-curly-left::before {
  content: "\{";
}

.fa-lobster::before {
  content: "\e421";
}

.fa-cart-flatbed-empty::before {
  content: "\f476";
}

.fa-dolly-flatbed-empty::before {
  content: "\f476";
}

.fa-colon::before {
  content: "\:";
}

.fa-cart-arrow-down::before {
  content: "\f218";
}

.fa-wand::before {
  content: "\f72a";
}

.fa-walkie-talkie::before {
  content: "\f8ef";
}

.fa-file-pen::before {
  content: "\f31c";
}

.fa-file-edit::before {
  content: "\f31c";
}

.fa-receipt::before {
  content: "\f543";
}

.fa-table-picnic::before {
  content: "\e32d";
}

.fa-square-pen::before {
  content: "\f14b";
}

.fa-pen-square::before {
  content: "\f14b";
}

.fa-pencil-square::before {
  content: "\f14b";
}

.fa-circle-microphone-lines::before {
  content: "\e117";
}

.fa-microphone-circle-alt::before {
  content: "\e117";
}

.fa-display-slash::before {
  content: "\e2fa";
}

.fa-desktop-slash::before {
  content: "\e2fa";
}

.fa-suitcase-rolling::before {
  content: "\f5c1";
}

.fa-person-circle-exclamation::before {
  content: "\e53f";
}

.fa-transporter-2::before {
  content: "\e044";
}

.fa-hands-holding-diamond::before {
  content: "\f47c";
}

.fa-hand-receiving::before {
  content: "\f47c";
}

.fa-money-bill-simple-wave::before {
  content: "\e1f2";
}

.fa-chevron-down::before {
  content: "\f078";
}

.fa-battery-full::before {
  content: "\f240";
}

.fa-battery::before {
  content: "\f240";
}

.fa-battery-5::before {
  content: "\f240";
}

.fa-bell-plus::before {
  content: "\f849";
}

.fa-book-arrow-right::before {
  content: "\e0b9";
}

.fa-hospitals::before {
  content: "\f80e";
}

.fa-club::before {
  content: "\f327";
}

.fa-skull-crossbones::before {
  content: "\f714";
}

.fa-droplet-degree::before {
  content: "\f748";
}

.fa-dewpoint::before {
  content: "\f748";
}

.fa-code-compare::before {
  content: "\e13a";
}

.fa-list-ul::before {
  content: "\f0ca";
}

.fa-list-dots::before {
  content: "\f0ca";
}

.fa-hand-holding-magic::before {
  content: "\f6e5";
}

.fa-watermelon-slice::before {
  content: "\e337";
}

.fa-circle-ellipsis::before {
  content: "\e10a";
}

.fa-school-lock::before {
  content: "\e56f";
}

.fa-tower-cell::before {
  content: "\e585";
}

.fa-sd-cards::before {
  content: "\e240";
}

.fa-down-long::before {
  content: "\f309";
}

.fa-long-arrow-alt-down::before {
  content: "\f309";
}

.fa-envelopes::before {
  content: "\e170";
}

.fa-phone-office::before {
  content: "\f67d";
}

.fa-ranking-star::before {
  content: "\e561";
}

.fa-chess-king::before {
  content: "\f43f";
}

.fa-nfc-pen::before {
  content: "\e1fa";
}

.fa-person-harassing::before {
  content: "\e549";
}

.fa-hat-winter::before {
  content: "\f7a8";
}

.fa-brazilian-real-sign::before {
  content: "\e46c";
}

.fa-landmark-dome::before {
  content: "\f752";
}

.fa-landmark-alt::before {
  content: "\f752";
}

.fa-bone-break::before {
  content: "\f5d8";
}

.fa-arrow-up::before {
  content: "\f062";
}

.fa-down-from-dotted-line::before {
  content: "\e407";
}

.fa-tv::before {
  content: "\f26c";
}

.fa-television::before {
  content: "\f26c";
}

.fa-tv-alt::before {
  content: "\f26c";
}

.fa-border-left::before {
  content: "\f84f";
}

.fa-circle-divide::before {
  content: "\e106";
}

.fa-shrimp::before {
  content: "\e448";
}

.fa-list-check::before {
  content: "\f0ae";
}

.fa-tasks::before {
  content: "\f0ae";
}

.fa-diagram-subtask::before {
  content: "\e479";
}

.fa-jug-detergent::before {
  content: "\e519";
}

.fa-circle-user::before {
  content: "\f2bd";
}

.fa-user-circle::before {
  content: "\f2bd";
}

.fa-square-y::before {
  content: "\e287";
}

.fa-user-doctor-hair::before {
  content: "\e458";
}

.fa-planet-ringed::before {
  content: "\e020";
}

.fa-mushroom::before {
  content: "\e425";
}

.fa-user-shield::before {
  content: "\f505";
}

.fa-megaphone::before {
  content: "\f675";
}

.fa-circle-exclamation-check::before {
  content: "\e10d";
}

.fa-wind::before {
  content: "\f72e";
}

.fa-box-dollar::before {
  content: "\f4a0";
}

.fa-box-usd::before {
  content: "\f4a0";
}

.fa-car-burst::before {
  content: "\f5e1";
}

.fa-car-crash::before {
  content: "\f5e1";
}

.fa-y::before {
  content: "Y";
}

.fa-user-headset::before {
  content: "\f82d";
}

.fa-arrows-retweet::before {
  content: "\f361";
}

.fa-retweet-alt::before {
  content: "\f361";
}

.fa-person-snowboarding::before {
  content: "\f7ce";
}

.fa-snowboarding::before {
  content: "\f7ce";
}

.fa-square-chevron-right::before {
  content: "\f32b";
}

.fa-chevron-square-right::before {
  content: "\f32b";
}

.fa-lacrosse-stick-ball::before {
  content: "\e3b6";
}

.fa-truck-fast::before {
  content: "\f48b";
}

.fa-shipping-fast::before {
  content: "\f48b";
}

.fa-user-magnifying-glass::before {
  content: "\e5c5";
}

.fa-star-sharp::before {
  content: "\e28b";
}

.fa-comment-heart::before {
  content: "\e5c8";
}

.fa-circle-1::before {
  content: "\e0ee";
}

.fa-circle-star::before {
  content: "\e123";
}

.fa-star-circle::before {
  content: "\e123";
}

.fa-fish::before {
  content: "\f578";
}

.fa-cloud-fog::before {
  content: "\f74e";
}

.fa-fog::before {
  content: "\f74e";
}

.fa-waffle::before {
  content: "\e466";
}

.fa-music-note::before {
  content: "\f8cf";
}

.fa-music-alt::before {
  content: "\f8cf";
}

.fa-hexagon-exclamation::before {
  content: "\e417";
}

.fa-cart-shopping-fast::before {
  content: "\e0dc";
}

.fa-object-union::before {
  content: "\e49f";
}

.fa-user-graduate::before {
  content: "\f501";
}

.fa-starfighter::before {
  content: "\e037";
}

.fa-circle-half-stroke::before {
  content: "\f042";
}

.fa-adjust::before {
  content: "\f042";
}

.fa-arrow-right-long-to-line::before {
  content: "\e3d5";
}

.fa-square-arrow-down::before {
  content: "\f339";
}

.fa-arrow-square-down::before {
  content: "\f339";
}

.fa-diamond-half-stroke::before {
  content: "\e5b8";
}

.fa-clapperboard::before {
  content: "\e131";
}

.fa-square-chevron-left::before {
  content: "\f32a";
}

.fa-chevron-square-left::before {
  content: "\f32a";
}

.fa-phone-intercom::before {
  content: "\e434";
}

.fa-link-horizontal::before {
  content: "\e1cb";
}

.fa-chain-horizontal::before {
  content: "\e1cb";
}

.fa-mango::before {
  content: "\e30f";
}

.fa-music-note-slash::before {
  content: "\f8d0";
}

.fa-music-alt-slash::before {
  content: "\f8d0";
}

.fa-circle-radiation::before {
  content: "\f7ba";
}

.fa-radiation-alt::before {
  content: "\f7ba";
}

.fa-face-tongue-sweat::before {
  content: "\e39e";
}

.fa-globe-stand::before {
  content: "\f5f6";
}

.fa-baseball::before {
  content: "\f433";
}

.fa-baseball-ball::before {
  content: "\f433";
}

.fa-circle-p::before {
  content: "\e11a";
}

.fa-award-simple::before {
  content: "\e0ab";
}

.fa-jet-fighter-up::before {
  content: "\e518";
}

.fa-diagram-project::before {
  content: "\f542";
}

.fa-project-diagram::before {
  content: "\f542";
}

.fa-pedestal::before {
  content: "\e20d";
}

.fa-chart-pyramid::before {
  content: "\e0e6";
}

.fa-sidebar::before {
  content: "\e24e";
}

.fa-snowman-head::before {
  content: "\f79b";
}

.fa-frosty-head::before {
  content: "\f79b";
}

.fa-copy::before {
  content: "\f0c5";
}

.fa-burger-glass::before {
  content: "\e0ce";
}

.fa-volume-xmark::before {
  content: "\f6a9";
}

.fa-volume-mute::before {
  content: "\f6a9";
}

.fa-volume-times::before {
  content: "\f6a9";
}

.fa-hand-sparkles::before {
  content: "\e05d";
}

.fa-bars-filter::before {
  content: "\e0ad";
}

.fa-paintbrush-pencil::before {
  content: "\e206";
}

.fa-party-bell::before {
  content: "\e31a";
}

.fa-user-vneck-hair::before {
  content: "\e462";
}

.fa-jack-o-lantern::before {
  content: "\f30e";
}

.fa-grip::before {
  content: "\f58d";
}

.fa-grip-horizontal::before {
  content: "\f58d";
}

.fa-share-from-square::before {
  content: "\f14d";
}

.fa-share-square::before {
  content: "\f14d";
}

.fa-keynote::before {
  content: "\f66c";
}

.fa-child-combatant::before {
  content: "\e4e0";
}

.fa-child-rifle::before {
  content: "\e4e0";
}

.fa-gun::before {
  content: "\e19b";
}

.fa-square-phone::before {
  content: "\f098";
}

.fa-phone-square::before {
  content: "\f098";
}

.fa-plus::before {
  content: "\+";
}

.fa-add::before {
  content: "\+";
}

.fa-expand::before {
  content: "\f065";
}

.fa-computer::before {
  content: "\e4e5";
}

.fa-fort::before {
  content: "\e486";
}

.fa-cloud-check::before {
  content: "\e35c";
}

.fa-xmark::before {
  content: "\f00d";
}

.fa-close::before {
  content: "\f00d";
}

.fa-multiply::before {
  content: "\f00d";
}

.fa-remove::before {
  content: "\f00d";
}

.fa-times::before {
  content: "\f00d";
}

.fa-face-smirking::before {
  content: "\e397";
}

.fa-arrows-up-down-left-right::before {
  content: "\f047";
}

.fa-arrows::before {
  content: "\f047";
}

.fa-chalkboard-user::before {
  content: "\f51c";
}

.fa-chalkboard-teacher::before {
  content: "\f51c";
}

.fa-rhombus::before {
  content: "\e23b";
}

.fa-claw-marks::before {
  content: "\f6c2";
}

.fa-peso-sign::before {
  content: "\e222";
}

.fa-face-smile-tongue::before {
  content: "\e394";
}

.fa-cart-circle-xmark::before {
  content: "\e3f4";
}

.fa-building-shield::before {
  content: "\e4d8";
}

.fa-circle-phone-flip::before {
  content: "\e11c";
}

.fa-phone-circle-alt::before {
  content: "\e11c";
}

.fa-baby::before {
  content: "\f77c";
}

.fa-users-line::before {
  content: "\e592";
}

.fa-quote-left::before {
  content: "\f10d";
}

.fa-quote-left-alt::before {
  content: "\f10d";
}

.fa-tractor::before {
  content: "\f722";
}

.fa-key-skeleton::before {
  content: "\f6f3";
}

.fa-trash-arrow-up::before {
  content: "\f829";
}

.fa-trash-restore::before {
  content: "\f829";
}

.fa-arrow-down-up-lock::before {
  content: "\e4b0";
}

.fa-arrow-down-to-bracket::before {
  content: "\e094";
}

.fa-lines-leaning::before {
  content: "\e51e";
}

.fa-square-q::before {
  content: "\e27b";
}

.fa-ruler-combined::before {
  content: "\f546";
}

.fa-symbols::before {
  content: "\f86e";
}

.fa-icons-alt::before {
  content: "\f86e";
}

.fa-copyright::before {
  content: "\f1f9";
}

.fa-highlighter-line::before {
  content: "\e1af";
}

.fa-bracket-square::before {
  content: "\[";
}

.fa-bracket::before {
  content: "\[";
}

.fa-bracket-left::before {
  content: "\[";
}

.fa-island-tropical::before {
  content: "\f811";
}

.fa-island-tree-palm::before {
  content: "\f811";
}

.fa-arrow-right-from-line::before {
  content: "\f343";
}

.fa-arrow-from-left::before {
  content: "\f343";
}

.fa-h2::before {
  content: "\f314";
}

.fa-equals::before {
  content: "\=";
}

.fa-cake-slice::before {
  content: "\e3e5";
}

.fa-shortcake::before {
  content: "\e3e5";
}

.fa-peanut::before {
  content: "\e430";
}

.fa-wrench-simple::before {
  content: "\e2d1";
}

.fa-blender::before {
  content: "\f517";
}

.fa-teeth::before {
  content: "\f62e";
}

.fa-tally-2::before {
  content: "\e295";
}

.fa-shekel-sign::before {
  content: "\f20b";
}

.fa-ils::before {
  content: "\f20b";
}

.fa-shekel::before {
  content: "\f20b";
}

.fa-sheqel::before {
  content: "\f20b";
}

.fa-sheqel-sign::before {
  content: "\f20b";
}

.fa-cars::before {
  content: "\f85b";
}

.fa-axe-battle::before {
  content: "\f6b3";
}

.fa-user-hair-long::before {
  content: "\e45b";
}

.fa-map::before {
  content: "\f279";
}

.fa-file-circle-info::before {
  content: "\e493";
}

.fa-face-disappointed::before {
  content: "\e36f";
}

.fa-lasso-sparkles::before {
  content: "\e1c9";
}

.fa-clock-eleven::before {
  content: "\e347";
}

.fa-rocket::before {
  content: "\f135";
}

.fa-siren-on::before {
  content: "\e02e";
}

.fa-clock-ten::before {
  content: "\e354";
}

.fa-candle-holder::before {
  content: "\f6bc";
}

.fa-video-arrow-down-left::before {
  content: "\e2c8";
}

.fa-photo-film::before {
  content: "\f87c";
}

.fa-photo-video::before {
  content: "\f87c";
}

.fa-floppy-disk-circle-arrow-right::before {
  content: "\e180";
}

.fa-save-circle-arrow-right::before {
  content: "\e180";
}

.fa-folder-minus::before {
  content: "\f65d";
}

.fa-planet-moon::before {
  content: "\e01f";
}

.fa-face-eyes-xmarks::before {
  content: "\e374";
}

.fa-chart-scatter::before {
  content: "\f7ee";
}

.fa-display-arrow-down::before {
  content: "\e164";
}

.fa-store::before {
  content: "\f54e";
}

.fa-arrow-trend-up::before {
  content: "\e098";
}

.fa-plug-circle-minus::before {
  content: "\e55e";
}

.fa-olive-branch::before {
  content: "\e317";
}

.fa-angle::before {
  content: "\e08c";
}

.fa-vacuum-robot::before {
  content: "\e04e";
}

.fa-sign-hanging::before {
  content: "\f4d9";
}

.fa-sign::before {
  content: "\f4d9";
}

.fa-square-divide::before {
  content: "\e26a";
}

.fa-signal-stream-slash::before {
  content: "\e250";
}

.fa-bezier-curve::before {
  content: "\f55b";
}

.fa-eye-dropper-half::before {
  content: "\e173";
}

.fa-store-lock::before {
  content: "\e4a6";
}

.fa-bell-slash::before {
  content: "\f1f6";
}

.fa-cloud-bolt-sun::before {
  content: "\f76e";
}

.fa-thunderstorm-sun::before {
  content: "\f76e";
}

.fa-camera-slash::before {
  content: "\e0d9";
}

.fa-comment-quote::before {
  content: "\e14c";
}

.fa-tablet::before {
  content: "\f3fb";
}

.fa-tablet-android::before {
  content: "\f3fb";
}

.fa-school-flag::before {
  content: "\e56e";
}

.fa-message-code::before {
  content: "\e1df";
}

.fa-glass-half::before {
  content: "\e192";
}

.fa-glass-half-empty::before {
  content: "\e192";
}

.fa-glass-half-full::before {
  content: "\e192";
}

.fa-fill::before {
  content: "\f575";
}

.fa-message-minus::before {
  content: "\f4a7";
}

.fa-comment-alt-minus::before {
  content: "\f4a7";
}

.fa-angle-up::before {
  content: "\f106";
}

.fa-drumstick-bite::before {
  content: "\f6d7";
}

.fa-link-horizontal-slash::before {
  content: "\e1cc";
}

.fa-chain-horizontal-slash::before {
  content: "\e1cc";
}

.fa-holly-berry::before {
  content: "\f7aa";
}

.fa-nose::before {
  content: "\e5bd";
}

.fa-chevron-left::before {
  content: "\f053";
}

.fa-bacteria::before {
  content: "\e059";
}

.fa-clouds::before {
  content: "\f744";
}

.fa-money-bill-simple::before {
  content: "\e1f1";
}

.fa-hand-lizard::before {
  content: "\f258";
}

.fa-table-pivot::before {
  content: "\e291";
}

.fa-filter-slash::before {
  content: "\e17d";
}

.fa-trash-can-undo::before {
  content: "\f896";
}

.fa-trash-can-arrow-turn-left::before {
  content: "\f896";
}

.fa-trash-undo-alt::before {
  content: "\f896";
}

.fa-notdef::before {
  content: "\e1fe";
}

.fa-disease::before {
  content: "\f7fa";
}

.fa-person-to-door::before {
  content: "\e433";
}

.fa-turntable::before {
  content: "\f8e4";
}

.fa-briefcase-medical::before {
  content: "\f469";
}

.fa-genderless::before {
  content: "\f22d";
}

.fa-chevron-right::before {
  content: "\f054";
}

.fa-signal-weak::before {
  content: "\f68c";
}

.fa-signal-1::before {
  content: "\f68c";
}

.fa-clock-five::before {
  content: "\e349";
}

.fa-retweet::before {
  content: "\f079";
}

.fa-car-rear::before {
  content: "\f5de";
}

.fa-car-alt::before {
  content: "\f5de";
}

.fa-pump-soap::before {
  content: "\e06b";
}

.fa-computer-classic::before {
  content: "\f8b1";
}

.fa-frame::before {
  content: "\e495";
}

.fa-video-slash::before {
  content: "\f4e2";
}

.fa-battery-quarter::before {
  content: "\f243";
}

.fa-battery-2::before {
  content: "\f243";
}

.fa-ellipsis-stroke::before {
  content: "\f39b";
}

.fa-ellipsis-h-alt::before {
  content: "\f39b";
}

.fa-radio::before {
  content: "\f8d7";
}

.fa-baby-carriage::before {
  content: "\f77d";
}

.fa-carriage-baby::before {
  content: "\f77d";
}

.fa-face-expressionless::before {
  content: "\e373";
}

.fa-down-to-dotted-line::before {
  content: "\e408";
}

.fa-cloud-music::before {
  content: "\f8ae";
}

.fa-traffic-light::before {
  content: "\f637";
}

.fa-cloud-minus::before {
  content: "\e35d";
}

.fa-thermometer::before {
  content: "\f491";
}

.fa-shield-minus::before {
  content: "\e249";
}

.fa-vr-cardboard::before {
  content: "\f729";
}

.fa-car-tilt::before {
  content: "\f5e5";
}

.fa-gauge-circle-minus::before {
  content: "\e497";
}

.fa-brightness-low::before {
  content: "\e0ca";
}

.fa-hand-middle-finger::before {
  content: "\f806";
}

.fa-percent::before {
  content: "\%";
}

.fa-percentage::before {
  content: "\%";
}

.fa-truck-moving::before {
  content: "\f4df";
}

.fa-glass-water-droplet::before {
  content: "\e4f5";
}

.fa-conveyor-belt::before {
  content: "\f46e";
}

.fa-location-check::before {
  content: "\f606";
}

.fa-map-marker-check::before {
  content: "\f606";
}

.fa-coin-vertical::before {
  content: "\e3fd";
}

.fa-display::before {
  content: "\e163";
}

.fa-person-sign::before {
  content: "\f757";
}

.fa-face-smile::before {
  content: "\f118";
}

.fa-smile::before {
  content: "\f118";
}

.fa-phone-hangup::before {
  content: "\e225";
}

.fa-signature-slash::before {
  content: "\e3cb";
}

.fa-thumbtack::before {
  content: "\f08d";
}

.fa-thumb-tack::before {
  content: "\f08d";
}

.fa-wheat-slash::before {
  content: "\e339";
}

.fa-trophy::before {
  content: "\f091";
}

.fa-clouds-sun::before {
  content: "\f746";
}

.fa-person-praying::before {
  content: "\f683";
}

.fa-pray::before {
  content: "\f683";
}

.fa-hammer::before {
  content: "\f6e3";
}

.fa-face-vomit::before {
  content: "\e3a0";
}

.fa-speakers::before {
  content: "\f8e0";
}

.fa-tty-answer::before {
  content: "\e2b9";
}

.fa-teletype-answer::before {
  content: "\e2b9";
}

.fa-mug-tea-saucer::before {
  content: "\e1f5";
}

.fa-diagram-lean-canvas::before {
  content: "\e156";
}

.fa-alt::before {
  content: "\e08a";
}

.fa-dial::before {
  content: "\e15b";
}

.fa-dial-med-high::before {
  content: "\e15b";
}

.fa-hand-peace::before {
  content: "\f25b";
}

.fa-circle-trash::before {
  content: "\e126";
}

.fa-trash-circle::before {
  content: "\e126";
}

.fa-rotate::before {
  content: "\f2f1";
}

.fa-sync-alt::before {
  content: "\f2f1";
}

.fa-circle-quarters::before {
  content: "\e3f8";
}

.fa-spinner::before {
  content: "\f110";
}

.fa-tower-control::before {
  content: "\e2a2";
}

.fa-arrow-up-triangle-square::before {
  content: "\f88a";
}

.fa-sort-shapes-up::before {
  content: "\f88a";
}

.fa-whale::before {
  content: "\f72c";
}

.fa-robot::before {
  content: "\f544";
}

.fa-peace::before {
  content: "\f67c";
}

.fa-party-horn::before {
  content: "\e31b";
}

.fa-gears::before {
  content: "\f085";
}

.fa-cogs::before {
  content: "\f085";
}

.fa-sun-bright::before {
  content: "\e28f";
}

.fa-sun-alt::before {
  content: "\e28f";
}

.fa-warehouse::before {
  content: "\f494";
}

.fa-lock-keyhole-open::before {
  content: "\f3c2";
}

.fa-lock-open-alt::before {
  content: "\f3c2";
}

.fa-square-fragile::before {
  content: "\f49b";
}

.fa-box-fragile::before {
  content: "\f49b";
}

.fa-square-wine-glass-crack::before {
  content: "\f49b";
}

.fa-arrow-up-right-dots::before {
  content: "\e4b7";
}

.fa-square-n::before {
  content: "\e277";
}

.fa-splotch::before {
  content: "\f5bc";
}

.fa-face-grin-hearts::before {
  content: "\f584";
}

.fa-grin-hearts::before {
  content: "\f584";
}

.fa-meter::before {
  content: "\e1e8";
}

.fa-mandolin::before {
  content: "\f6f9";
}

.fa-dice-four::before {
  content: "\f524";
}

.fa-sim-card::before {
  content: "\f7c4";
}

.fa-transgender::before {
  content: "\f225";
}

.fa-transgender-alt::before {
  content: "\f225";
}

.fa-mercury::before {
  content: "\f223";
}

.fa-up-from-bracket::before {
  content: "\e590";
}

.fa-knife-kitchen::before {
  content: "\f6f5";
}

.fa-border-right::before {
  content: "\f852";
}

.fa-arrow-turn-down::before {
  content: "\f149";
}

.fa-level-down::before {
  content: "\f149";
}

.fa-spade::before {
  content: "\f2f4";
}

.fa-card-spade::before {
  content: "\e3ec";
}

.fa-line-columns::before {
  content: "\f870";
}

.fa-arrow-right-to-line::before {
  content: "\f340";
}

.fa-arrow-to-right::before {
  content: "\f340";
}

.fa-person-falling-burst::before {
  content: "\e547";
}

.fa-flag-pennant::before {
  content: "\f456";
}

.fa-pennant::before {
  content: "\f456";
}

.fa-conveyor-belt-empty::before {
  content: "\e150";
}

.fa-award::before {
  content: "\f559";
}

.fa-ticket-simple::before {
  content: "\f3ff";
}

.fa-ticket-alt::before {
  content: "\f3ff";
}

.fa-building::before {
  content: "\f1ad";
}

.fa-angles-left::before {
  content: "\f100";
}

.fa-angle-double-left::before {
  content: "\f100";
}

.fa-camcorder::before {
  content: "\f8a8";
}

.fa-video-handheld::before {
  content: "\f8a8";
}

.fa-pancakes::before {
  content: "\e42d";
}

.fa-album-circle-user::before {
  content: "\e48d";
}

.fa-qrcode::before {
  content: "\f029";
}

.fa-dice-d10::before {
  content: "\f6cd";
}

.fa-fireplace::before {
  content: "\f79a";
}

.fa-browser::before {
  content: "\f37e";
}

.fa-pen-paintbrush::before {
  content: "\f618";
}

.fa-pencil-paintbrush::before {
  content: "\f618";
}

.fa-fish-cooked::before {
  content: "\f7fe";
}

.fa-chair-office::before {
  content: "\f6c1";
}

.fa-nesting-dolls::before {
  content: "\e3ba";
}

.fa-clock-rotate-left::before {
  content: "\f1da";
}

.fa-history::before {
  content: "\f1da";
}

.fa-trumpet::before {
  content: "\f8e3";
}

.fa-face-grin-beam-sweat::before {
  content: "\f583";
}

.fa-grin-beam-sweat::before {
  content: "\f583";
}

.fa-fire-smoke::before {
  content: "\f74b";
}

.fa-phone-missed::before {
  content: "\e226";
}

.fa-file-export::before {
  content: "\f56e";
}

.fa-arrow-right-from-file::before {
  content: "\f56e";
}

.fa-shield::before {
  content: "\f132";
}

.fa-shield-blank::before {
  content: "\f132";
}

.fa-arrow-up-short-wide::before {
  content: "\f885";
}

.fa-sort-amount-up-alt::before {
  content: "\f885";
}

.fa-arrows-repeat-1::before {
  content: "\f366";
}

.fa-repeat-1-alt::before {
  content: "\f366";
}

.fa-gun-slash::before {
  content: "\e19c";
}

.fa-avocado::before {
  content: "\e0aa";
}

.fa-binary::before {
  content: "\e33b";
}

.fa-glasses-round::before {
  content: "\f5f5";
}

.fa-glasses-alt::before {
  content: "\f5f5";
}

.fa-phone-plus::before {
  content: "\f4d2";
}

.fa-ditto::before {
  content: "\"";
}

.fa-person-seat::before {
  content: "\e21e";
}

.fa-house-medical::before {
  content: "\e3b2";
}

.fa-golf-ball-tee::before {
  content: "\f450";
}

.fa-golf-ball::before {
  content: "\f450";
}

.fa-circle-chevron-left::before {
  content: "\f137";
}

.fa-chevron-circle-left::before {
  content: "\f137";
}

.fa-house-chimney-window::before {
  content: "\e00d";
}

.fa-scythe::before {
  content: "\f710";
}

.fa-pen-nib::before {
  content: "\f5ad";
}

.fa-ban-parking::before {
  content: "\f616";
}

.fa-parking-circle-slash::before {
  content: "\f616";
}

.fa-tent-arrow-turn-left::before {
  content: "\e580";
}

.fa-face-diagonal-mouth::before {
  content: "\e47e";
}

.fa-diagram-cells::before {
  content: "\e475";
}

.fa-cricket-bat-ball::before {
  content: "\f449";
}

.fa-cricket::before {
  content: "\f449";
}

.fa-tents::before {
  content: "\e582";
}

.fa-wand-magic::before {
  content: "\f0d0";
}

.fa-magic::before {
  content: "\f0d0";
}

.fa-dog::before {
  content: "\f6d3";
}

.fa-pen-line::before {
  content: "\e212";
}

.fa-atom-simple::before {
  content: "\f5d3";
}

.fa-atom-alt::before {
  content: "\f5d3";
}

.fa-ampersand::before {
  content: "\&";
}

.fa-carrot::before {
  content: "\f787";
}

.fa-arrow-up-from-line::before {
  content: "\f342";
}

.fa-arrow-from-bottom::before {
  content: "\f342";
}

.fa-moon::before {
  content: "\f186";
}

.fa-pen-slash::before {
  content: "\e213";
}

.fa-wine-glass-empty::before {
  content: "\f5ce";
}

.fa-wine-glass-alt::before {
  content: "\f5ce";
}

.fa-square-star::before {
  content: "\e27f";
}

.fa-cheese::before {
  content: "\f7ef";
}

.fa-send-backward::before {
  content: "\f87f";
}

.fa-yin-yang::before {
  content: "\f6ad";
}

.fa-music::before {
  content: "\f001";
}

.fa-compass-slash::before {
  content: "\f5e9";
}

.fa-clock-one::before {
  content: "\e34e";
}

.fa-file-music::before {
  content: "\f8b6";
}

.fa-code-commit::before {
  content: "\f386";
}

.fa-temperature-low::before {
  content: "\f76b";
}

.fa-person-biking::before {
  content: "\f84a";
}

.fa-biking::before {
  content: "\f84a";
}

.fa-skeleton::before {
  content: "\f620";
}

.fa-circle-g::before {
  content: "\e10f";
}

.fa-circle-arrow-up-left::before {
  content: "\e0fb";
}

.fa-coin-blank::before {
  content: "\e3fb";
}

.fa-broom::before {
  content: "\f51a";
}

.fa-vacuum::before {
  content: "\e04d";
}

.fa-shield-heart::before {
  content: "\e574";
}

.fa-card-heart::before {
  content: "\e3eb";
}

.fa-lightbulb-cfl-on::before {
  content: "\e5a7";
}

.fa-melon::before {
  content: "\e310";
}

.fa-gopuram::before {
  content: "\f664";
}

.fa-earth-oceania::before {
  content: "\e47b";
}

.fa-globe-oceania::before {
  content: "\e47b";
}

.fa-container-storage::before {
  content: "\f4b7";
}

.fa-face-pouting::before {
  content: "\e387";
}

.fa-square-xmark::before {
  content: "\f2d3";
}

.fa-times-square::before {
  content: "\f2d3";
}

.fa-xmark-square::before {
  content: "\f2d3";
}

.fa-face-explode::before {
  content: "\e2fe";
}

.fa-exploding-head::before {
  content: "\e2fe";
}

.fa-hashtag::before {
  content: "\#";
}

.fa-up-right-and-down-left-from-center::before {
  content: "\f424";
}

.fa-expand-alt::before {
  content: "\f424";
}

.fa-oil-can::before {
  content: "\f613";
}

.fa-t::before {
  content: "T";
}

.fa-transformer-bolt::before {
  content: "\e2a4";
}

.fa-hippo::before {
  content: "\f6ed";
}

.fa-chart-column::before {
  content: "\e0e3";
}

.fa-cassette-vhs::before {
  content: "\f8ec";
}

.fa-vhs::before {
  content: "\f8ec";
}

.fa-infinity::before {
  content: "\f534";
}

.fa-vial-circle-check::before {
  content: "\e596";
}

.fa-chimney::before {
  content: "\f78b";
}

.fa-object-intersect::before {
  content: "\e49d";
}

.fa-person-arrow-down-to-line::before {
  content: "\e538";
}

.fa-voicemail::before {
  content: "\f897";
}

.fa-block-brick::before {
  content: "\e3db";
}

.fa-wall-brick::before {
  content: "\e3db";
}

.fa-fan::before {
  content: "\f863";
}

.fa-bags-shopping::before {
  content: "\f847";
}

.fa-paragraph-left::before {
  content: "\f878";
}

.fa-paragraph-rtl::before {
  content: "\f878";
}

.fa-person-walking-luggage::before {
  content: "\e554";
}

.fa-caravan-simple::before {
  content: "\e000";
}

.fa-caravan-alt::before {
  content: "\e000";
}

.fa-turtle::before {
  content: "\f726";
}

.fa-up-down::before {
  content: "\f338";
}

.fa-arrows-alt-v::before {
  content: "\f338";
}

.fa-cloud-moon-rain::before {
  content: "\f73c";
}

.fa-booth-curtain::before {
  content: "\f734";
}

.fa-calendar::before {
  content: "\f133";
}

.fa-box-heart::before {
  content: "\f49d";
}

.fa-trailer::before {
  content: "\e041";
}

.fa-user-doctor-message::before {
  content: "\f82e";
}

.fa-user-md-chat::before {
  content: "\f82e";
}

.fa-bahai::before {
  content: "\f666";
}

.fa-haykal::before {
  content: "\f666";
}

.fa-amp-guitar::before {
  content: "\f8a1";
}

.fa-sd-card::before {
  content: "\f7c2";
}

.fa-volume-slash::before {
  content: "\f2e2";
}

.fa-border-bottom::before {
  content: "\f84d";
}

.fa-wifi-weak::before {
  content: "\f6aa";
}

.fa-wifi-1::before {
  content: "\f6aa";
}

.fa-dragon::before {
  content: "\f6d5";
}

.fa-shoe-prints::before {
  content: "\f54b";
}

.fa-circle-plus::before {
  content: "\f055";
}

.fa-plus-circle::before {
  content: "\f055";
}

.fa-face-grin-tongue-wink::before {
  content: "\f58b";
}

.fa-grin-tongue-wink::before {
  content: "\f58b";
}

.fa-hand-holding::before {
  content: "\f4bd";
}

.fa-plug-circle-exclamation::before {
  content: "\e55d";
}

.fa-link-slash::before {
  content: "\f127";
}

.fa-chain-broken::before {
  content: "\f127";
}

.fa-chain-slash::before {
  content: "\f127";
}

.fa-unlink::before {
  content: "\f127";
}

.fa-clone::before {
  content: "\f24d";
}

.fa-person-walking-arrow-loop-left::before {
  content: "\e551";
}

.fa-arrow-up-z-a::before {
  content: "\f882";
}

.fa-sort-alpha-up-alt::before {
  content: "\f882";
}

.fa-fire-flame-curved::before {
  content: "\f7e4";
}

.fa-fire-alt::before {
  content: "\f7e4";
}

.fa-tornado::before {
  content: "\f76f";
}

.fa-file-circle-plus::before {
  content: "\e494";
}

.fa-delete-right::before {
  content: "\e154";
}

.fa-book-quran::before {
  content: "\f687";
}

.fa-quran::before {
  content: "\f687";
}

.fa-circle-quarter::before {
  content: "\e11f";
}

.fa-anchor::before {
  content: "\f13d";
}

.fa-border-all::before {
  content: "\f84c";
}

.fa-function::before {
  content: "\f661";
}

.fa-face-angry::before {
  content: "\f556";
}

.fa-angry::before {
  content: "\f556";
}

.fa-people-simple::before {
  content: "\e21b";
}

.fa-cookie-bite::before {
  content: "\f564";
}

.fa-arrow-trend-down::before {
  content: "\e097";
}

.fa-rss::before {
  content: "\f09e";
}

.fa-feed::before {
  content: "\f09e";
}

.fa-face-monocle::before {
  content: "\e380";
}

.fa-draw-polygon::before {
  content: "\f5ee";
}

.fa-scale-balanced::before {
  content: "\f24e";
}

.fa-balance-scale::before {
  content: "\f24e";
}

.fa-calendar-lines::before {
  content: "\e0d5";
}

.fa-calendar-note::before {
  content: "\e0d5";
}

.fa-arrow-down-big-small::before {
  content: "\f88c";
}

.fa-sort-size-down::before {
  content: "\f88c";
}

.fa-gauge-simple-high::before {
  content: "\f62a";
}

.fa-tachometer::before {
  content: "\f62a";
}

.fa-tachometer-fast::before {
  content: "\f62a";
}

.fa-do-not-enter::before {
  content: "\f5ec";
}

.fa-shower::before {
  content: "\f2cc";
}

.fa-dice-d8::before {
  content: "\f6d2";
}

.fa-desktop::before {
  content: "\f390";
}

.fa-desktop-alt::before {
  content: "\f390";
}

.fa-m::before {
  content: "M";
}

.fa-grip-dots-vertical::before {
  content: "\e411";
}

.fa-face-viewfinder::before {
  content: "\e2ff";
}

.fa-soft-serve::before {
  content: "\e400";
}

.fa-creemee::before {
  content: "\e400";
}

.fa-h5::before {
  content: "\e412";
}

.fa-hand-back-point-down::before {
  content: "\e19e";
}

.fa-table-list::before {
  content: "\f00b";
}

.fa-th-list::before {
  content: "\f00b";
}

.fa-comment-sms::before {
  content: "\f7cd";
}

.fa-sms::before {
  content: "\f7cd";
}

.fa-rectangle::before {
  content: "\f2fa";
}

.fa-rectangle-landscape::before {
  content: "\f2fa";
}

.fa-clipboard-list-check::before {
  content: "\f737";
}

.fa-turkey::before {
  content: "\f725";
}

.fa-book::before {
  content: "\f02d";
}

.fa-user-plus::before {
  content: "\f234";
}

.fa-ice-skate::before {
  content: "\f7ac";
}

.fa-check::before {
  content: "\f00c";
}

.fa-battery-three-quarters::before {
  content: "\f241";
}

.fa-battery-4::before {
  content: "\f241";
}

.fa-tomato::before {
  content: "\e330";
}

.fa-sword-laser::before {
  content: "\e03b";
}

.fa-house-circle-check::before {
  content: "\e509";
}

.fa-buildings::before {
  content: "\e0cc";
}

.fa-angle-left::before {
  content: "\f104";
}

.fa-cart-flatbed-boxes::before {
  content: "\f475";
}

.fa-dolly-flatbed-alt::before {
  content: "\f475";
}

.fa-diagram-successor::before {
  content: "\e47a";
}

.fa-truck-arrow-right::before {
  content: "\e58b";
}

.fa-square-w::before {
  content: "\e285";
}

.fa-arrows-split-up-and-left::before {
  content: "\e4bc";
}

.fa-lamp::before {
  content: "\f4ca";
}

.fa-airplay::before {
  content: "\e089";
}

.fa-hand-fist::before {
  content: "\f6de";
}

.fa-fist-raised::before {
  content: "\f6de";
}

.fa-shield-quartered::before {
  content: "\e575";
}

.fa-slash-forward::before {
  content: "\/";
}

.fa-location-pen::before {
  content: "\f607";
}

.fa-map-marker-edit::before {
  content: "\f607";
}

.fa-cloud-moon::before {
  content: "\f6c3";
}

.fa-pot-food::before {
  content: "\e43f";
}

.fa-briefcase::before {
  content: "\f0b1";
}

.fa-person-falling::before {
  content: "\e546";
}

.fa-image-portrait::before {
  content: "\f3e0";
}

.fa-portrait::before {
  content: "\f3e0";
}

.fa-user-tag::before {
  content: "\f507";
}

.fa-rug::before {
  content: "\e569";
}

.fa-print-slash::before {
  content: "\f686";
}

.fa-earth-europe::before {
  content: "\f7a2";
}

.fa-globe-europe::before {
  content: "\f7a2";
}

.fa-cart-flatbed-suitcase::before {
  content: "\f59d";
}

.fa-luggage-cart::before {
  content: "\f59d";
}

.fa-hand-back-point-ribbon::before {
  content: "\e1a0";
}

.fa-rectangle-xmark::before {
  content: "\f410";
}

.fa-rectangle-times::before {
  content: "\f410";
}

.fa-times-rectangle::before {
  content: "\f410";
}

.fa-window-close::before {
  content: "\f410";
}

.fa-tire-rugged::before {
  content: "\f634";
}

.fa-lightbulb-dollar::before {
  content: "\f670";
}

.fa-cowbell::before {
  content: "\f8b3";
}

.fa-baht-sign::before {
  content: "\e0ac";
}

.fa-corner::before {
  content: "\e3fe";
}

.fa-chevrons-right::before {
  content: "\f324";
}

.fa-chevron-double-right::before {
  content: "\f324";
}

.fa-book-open::before {
  content: "\f518";
}

.fa-book-journal-whills::before {
  content: "\f66a";
}

.fa-journal-whills::before {
  content: "\f66a";
}

.fa-inhaler::before {
  content: "\f5f9";
}

.fa-handcuffs::before {
  content: "\e4f8";
}

.fa-snake::before {
  content: "\f716";
}

.fa-triangle-exclamation::before {
  content: "\f071";
}

.fa-exclamation-triangle::before {
  content: "\f071";
}

.fa-warning::before {
  content: "\f071";
}

.fa-note-medical::before {
  content: "\e200";
}

.fa-database::before {
  content: "\f1c0";
}

.fa-down-left::before {
  content: "\e16a";
}

.fa-share::before {
  content: "\f064";
}

.fa-arrow-turn-right::before {
  content: "\f064";
}

.fa-mail-forward::before {
  content: "\f064";
}

.fa-face-thinking::before {
  content: "\e39b";
}

.fa-turn-down-right::before {
  content: "\e455";
}

.fa-bottle-droplet::before {
  content: "\e4c4";
}

.fa-mask-face::before {
  content: "\e1d7";
}

.fa-hill-rockslide::before {
  content: "\e508";
}

.fa-scanner-keyboard::before {
  content: "\f489";
}

.fa-circle-o::before {
  content: "\e119";
}

.fa-grid-horizontal::before {
  content: "\e307";
}

.fa-message-dollar::before {
  content: "\f650";
}

.fa-comment-alt-dollar::before {
  content: "\f650";
}

.fa-right-left::before {
  content: "\f362";
}

.fa-exchange-alt::before {
  content: "\f362";
}

.fa-columns-3::before {
  content: "\e361";
}

.fa-paper-plane::before {
  content: "\f1d8";
}

.fa-road-circle-exclamation::before {
  content: "\e565";
}

.fa-dungeon::before {
  content: "\f6d9";
}

.fa-hand-holding-box::before {
  content: "\f47b";
}

.fa-input-text::before {
  content: "\e1bf";
}

.fa-window-flip::before {
  content: "\f40f";
}

.fa-window-alt::before {
  content: "\f40f";
}

.fa-align-right::before {
  content: "\f038";
}

.fa-scanner-gun::before {
  content: "\f488";
}

.fa-scanner::before {
  content: "\f488";
}

.fa-tire::before {
  content: "\f631";
}

.fa-engine::before {
  content: "\e16e";
}

.fa-money-bill-1-wave::before {
  content: "\f53b";
}

.fa-money-bill-wave-alt::before {
  content: "\f53b";
}

.fa-life-ring::before {
  content: "\f1cd";
}

.fa-hands::before {
  content: "\f2a7";
}

.fa-sign-language::before {
  content: "\f2a7";
}

.fa-signing::before {
  content: "\f2a7";
}

.fa-circle-caret-right::before {
  content: "\f330";
}

.fa-caret-circle-right::before {
  content: "\f330";
}

.fa-wheat::before {
  content: "\f72d";
}

.fa-file-spreadsheet::before {
  content: "\f65b";
}

.fa-audio-description-slash::before {
  content: "\e0a8";
}

.fa-calendar-day::before {
  content: "\f783";
}

.fa-water-ladder::before {
  content: "\f5c5";
}

.fa-ladder-water::before {
  content: "\f5c5";
}

.fa-swimming-pool::before {
  content: "\f5c5";
}

.fa-arrows-up-down::before {
  content: "\f07d";
}

.fa-arrows-v::before {
  content: "\f07d";
}

.fa-chess-pawn-piece::before {
  content: "\f444";
}

.fa-chess-pawn-alt::before {
  content: "\f444";
}

.fa-face-grimace::before {
  content: "\f57f";
}

.fa-grimace::before {
  content: "\f57f";
}

.fa-wheelchair-move::before {
  content: "\e2ce";
}

.fa-wheelchair-alt::before {
  content: "\e2ce";
}

.fa-turn-down::before {
  content: "\f3be";
}

.fa-level-down-alt::before {
  content: "\f3be";
}

.fa-square-s::before {
  content: "\e27d";
}

.fa-rectangle-barcode::before {
  content: "\f463";
}

.fa-barcode-alt::before {
  content: "\f463";
}

.fa-person-walking-arrow-right::before {
  content: "\e552";
}

.fa-square-envelope::before {
  content: "\f199";
}

.fa-envelope-square::before {
  content: "\f199";
}

.fa-dice::before {
  content: "\f522";
}

.fa-unicorn::before {
  content: "\f727";
}

.fa-bowling-ball::before {
  content: "\f436";
}

.fa-pompebled::before {
  content: "\e43d";
}

.fa-brain::before {
  content: "\f5dc";
}

.fa-watch-smart::before {
  content: "\e2cc";
}

.fa-book-user::before {
  content: "\f7e7";
}

.fa-sensor-cloud::before {
  content: "\e02c";
}

.fa-sensor-smoke::before {
  content: "\e02c";
}

.fa-clapperboard-play::before {
  content: "\e132";
}

.fa-bandage::before {
  content: "\f462";
}

.fa-band-aid::before {
  content: "\f462";
}

.fa-calendar-minus::before {
  content: "\f272";
}

.fa-circle-xmark::before {
  content: "\f057";
}

.fa-times-circle::before {
  content: "\f057";
}

.fa-xmark-circle::before {
  content: "\f057";
}

.fa-circle-4::before {
  content: "\e0f1";
}

.fa-gifts::before {
  content: "\f79c";
}

.fa-album-collection::before {
  content: "\f8a0";
}

.fa-hotel::before {
  content: "\f594";
}

.fa-earth-asia::before {
  content: "\f57e";
}

.fa-globe-asia::before {
  content: "\f57e";
}

.fa-id-card-clip::before {
  content: "\f47f";
}

.fa-id-card-alt::before {
  content: "\f47f";
}

.fa-magnifying-glass-plus::before {
  content: "\f00e";
}

.fa-search-plus::before {
  content: "\f00e";
}

.fa-thumbs-up::before {
  content: "\f164";
}

.fa-cloud-showers::before {
  content: "\f73f";
}

.fa-user-clock::before {
  content: "\f4fd";
}

.fa-onion::before {
  content: "\e427";
}

.fa-clock-twelve-thirty::before {
  content: "\e359";
}

.fa-arrow-down-to-dotted-line::before {
  content: "\e095";
}

.fa-hand-dots::before {
  content: "\f461";
}

.fa-allergies::before {
  content: "\f461";
}

.fa-file-invoice::before {
  content: "\f570";
}

.fa-window-minimize::before {
  content: "\f2d1";
}

.fa-rectangle-wide::before {
  content: "\f2fc";
}

.fa-comment-arrow-up::before {
  content: "\e144";
}

.fa-garlic::before {
  content: "\e40e";
}

.fa-mug-saucer::before {
  content: "\f0f4";
}

.fa-coffee::before {
  content: "\f0f4";
}

.fa-brush::before {
  content: "\f55d";
}

.fa-tree-decorated::before {
  content: "\f7dc";
}

.fa-mask::before {
  content: "\f6fa";
}

.fa-calendar-heart::before {
  content: "\e0d3";
}

.fa-magnifying-glass-minus::before {
  content: "\f010";
}

.fa-search-minus::before {
  content: "\f010";
}

.fa-flower::before {
  content: "\f7ff";
}

.fa-ruler-vertical::before {
  content: "\f548";
}

.fa-user-large::before {
  content: "\f406";
}

.fa-user-alt::before {
  content: "\f406";
}

.fa-starship-freighter::before {
  content: "\e03a";
}

.fa-train-tram::before {
  content: "\e5b4";
}

.fa-bridge-suspension::before {
  content: "\e4cd";
}

.fa-trash-check::before {
  content: "\e2af";
}

.fa-user-nurse::before {
  content: "\f82f";
}

.fa-boombox::before {
  content: "\f8a5";
}

.fa-syringe::before {
  content: "\f48e";
}

.fa-cloud-sun::before {
  content: "\f6c4";
}

.fa-shield-exclamation::before {
  content: "\e247";
}

.fa-stopwatch-20::before {
  content: "\e06f";
}

.fa-square-full::before {
  content: "\f45c";
}

.fa-grip-dots::before {
  content: "\e410";
}

.fa-comment-exclamation::before {
  content: "\f4af";
}

.fa-pen-swirl::before {
  content: "\e214";
}

.fa-falafel::before {
  content: "\e40a";
}

.fa-circle-2::before {
  content: "\e0ef";
}

.fa-magnet::before {
  content: "\f076";
}

.fa-jar::before {
  content: "\e516";
}

.fa-gramophone::before {
  content: "\f8bd";
}

.fa-dice-d12::before {
  content: "\f6ce";
}

.fa-note-sticky::before {
  content: "\f249";
}

.fa-sticky-note::before {
  content: "\f249";
}

.fa-down::before {
  content: "\f354";
}

.fa-arrow-alt-down::before {
  content: "\f354";
}

.fa-hundred-points::before {
  content: "\e41c";
}

.fa-100::before {
  content: "\e41c";
}

.fa-paperclip-vertical::before {
  content: "\e3c2";
}

.fa-wind-warning::before {
  content: "\f776";
}

.fa-wind-circle-exclamation::before {
  content: "\f776";
}

.fa-location-pin-slash::before {
  content: "\f60c";
}

.fa-map-marker-slash::before {
  content: "\f60c";
}

.fa-face-sad-sweat::before {
  content: "\e38a";
}

.fa-bug-slash::before {
  content: "\e490";
}

.fa-cupcake::before {
  content: "\e402";
}

.fa-light-switch-off::before {
  content: "\e018";
}

.fa-toggle-large-off::before {
  content: "\e5b0";
}

.fa-pen-fancy-slash::before {
  content: "\e210";
}

.fa-truck-container::before {
  content: "\f4dc";
}

.fa-boot::before {
  content: "\f782";
}

.fa-arrow-up-from-water-pump::before {
  content: "\e4b6";
}

.fa-file-check::before {
  content: "\f316";
}

.fa-bone::before {
  content: "\f5d7";
}

.fa-cards-blank::before {
  content: "\e4df";
}

.fa-circle-3::before {
  content: "\e0f0";
}

.fa-bench-tree::before {
  content: "\e2e7";
}

.fa-keyboard-brightness-low::before {
  content: "\e1c1";
}

.fa-ski-boot-ski::before {
  content: "\e3cd";
}

.fa-brain-circuit::before {
  content: "\e0c6";
}

.fa-user-injured::before {
  content: "\f728";
}

.fa-block-brick-fire::before {
  content: "\e3dc";
}

.fa-firewall::before {
  content: "\e3dc";
}

.fa-face-sad-tear::before {
  content: "\f5b4";
}

.fa-sad-tear::before {
  content: "\f5b4";
}

.fa-plane::before {
  content: "\f072";
}

.fa-tent-arrows-down::before {
  content: "\e581";
}

.fa-exclamation::before {
  content: "\!";
}

.fa-arrows-spin::before {
  content: "\e4bb";
}

.fa-face-smile-relaxed::before {
  content: "\e392";
}

.fa-comment-xmark::before {
  content: "\f4b5";
}

.fa-comment-times::before {
  content: "\f4b5";
}

.fa-print::before {
  content: "\f02f";
}

.fa-turkish-lira-sign::before {
  content: "\e2bb";
}

.fa-try::before {
  content: "\e2bb";
}

.fa-turkish-lira::before {
  content: "\e2bb";
}

.fa-face-nose-steam::before {
  content: "\e382";
}

.fa-circle-waveform-lines::before {
  content: "\e12d";
}

.fa-waveform-circle::before {
  content: "\e12d";
}

.fa-dollar-sign::before {
  content: "\$";
}

.fa-dollar::before {
  content: "\$";
}

.fa-usd::before {
  content: "\$";
}

.fa-ferris-wheel::before {
  content: "\e174";
}

.fa-computer-speaker::before {
  content: "\f8b2";
}

.fa-skull-cow::before {
  content: "\f8de";
}

.fa-x::before {
  content: "X";
}

.fa-magnifying-glass-dollar::before {
  content: "\f688";
}

.fa-search-dollar::before {
  content: "\f688";
}

.fa-users-gear::before {
  content: "\f509";
}

.fa-users-cog::before {
  content: "\f509";
}

.fa-person-military-pointing::before {
  content: "\e54a";
}

.fa-building-columns::before {
  content: "\f19c";
}

.fa-bank::before {
  content: "\f19c";
}

.fa-institution::before {
  content: "\f19c";
}

.fa-museum::before {
  content: "\f19c";
}

.fa-university::before {
  content: "\f19c";
}

.fa-circle-t::before {
  content: "\e124";
}

.fa-sack::before {
  content: "\f81c";
}

.fa-grid-2::before {
  content: "\e196";
}

.fa-camera-cctv::before {
  content: "\f8ac";
}

.fa-cctv::before {
  content: "\f8ac";
}

.fa-umbrella::before {
  content: "\f0e9";
}

.fa-trowel::before {
  content: "\e589";
}

.fa-horizontal-rule::before {
  content: "\f86c";
}

.fa-bed-front::before {
  content: "\f8f7";
}

.fa-bed-alt::before {
  content: "\f8f7";
}

.fa-d::before {
  content: "D";
}

.fa-stapler::before {
  content: "\e5af";
}

.fa-masks-theater::before {
  content: "\f630";
}

.fa-theater-masks::before {
  content: "\f630";
}

.fa-kip-sign::before {
  content: "\e1c4";
}

.fa-face-woozy::before {
  content: "\e3a2";
}

.fa-cloud-question::before {
  content: "\e492";
}

.fa-pineapple::before {
  content: "\e31f";
}

.fa-hand-point-left::before {
  content: "\f0a5";
}

.fa-gallery-thumbnails::before {
  content: "\e3aa";
}

.fa-circle-j::before {
  content: "\e112";
}

.fa-eyes::before {
  content: "\e367";
}

.fa-handshake-simple::before {
  content: "\f4c6";
}

.fa-handshake-alt::before {
  content: "\f4c6";
}

.fa-page-caret-up::before {
  content: "\e42a";
}

.fa-file-caret-up::before {
  content: "\e42a";
}

.fa-jet-fighter::before {
  content: "\f0fb";
}

.fa-fighter-jet::before {
  content: "\f0fb";
}

.fa-comet::before {
  content: "\e003";
}

.fa-square-share-nodes::before {
  content: "\f1e1";
}

.fa-share-alt-square::before {
  content: "\f1e1";
}

.fa-shield-keyhole::before {
  content: "\e248";
}

.fa-barcode::before {
  content: "\f02a";
}

.fa-plus-minus::before {
  content: "\e43c";
}

.fa-square-sliders-vertical::before {
  content: "\f3f2";
}

.fa-sliders-v-square::before {
  content: "\f3f2";
}

.fa-video::before {
  content: "\f03d";
}

.fa-video-camera::before {
  content: "\f03d";
}

.fa-message-middle::before {
  content: "\e1e1";
}

.fa-comment-middle-alt::before {
  content: "\e1e1";
}

.fa-graduation-cap::before {
  content: "\f19d";
}

.fa-mortar-board::before {
  content: "\f19d";
}

.fa-hand-holding-medical::before {
  content: "\e05c";
}

.fa-person-circle-check::before {
  content: "\e53e";
}

.fa-square-z::before {
  content: "\e288";
}

.fa-message-text::before {
  content: "\e1e6";
}

.fa-comment-alt-text::before {
  content: "\e1e6";
}

.fa-turn-up::before {
  content: "\f3bf";
}

.fa-level-up-alt::before {
  content: "\f3bf";
}

.sr-only,
.fa-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.sr-only-focusable:not(:focus),
.fa-sr-only-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

/*!
 * Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-brands: "Font Awesome 6 Brands";
  --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands";
}

@font-face {
  font-family: "Font Awesome 6 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../webfonts/fa-brands-400.woff2") format("woff2"), url("../webfonts/fa-brands-400.ttf") format("truetype");
}
.fab,
.fa-brands {
  font-weight: 400;
}

.fa-monero:before {
  content: "\f3d0";
}

.fa-hooli:before {
  content: "\f427";
}

.fa-yelp:before {
  content: "\f1e9";
}

.fa-cc-visa:before {
  content: "\f1f0";
}

.fa-lastfm:before {
  content: "\f202";
}

.fa-shopware:before {
  content: "\f5b5";
}

.fa-creative-commons-nc:before {
  content: "\f4e8";
}

.fa-aws:before {
  content: "\f375";
}

.fa-redhat:before {
  content: "\f7bc";
}

.fa-yoast:before {
  content: "\f2b1";
}

.fa-cloudflare:before {
  content: "\e07d";
}

.fa-ups:before {
  content: "\f7e0";
}

.fa-pixiv:before {
  content: "\e640";
}

.fa-wpexplorer:before {
  content: "\f2de";
}

.fa-dyalog:before {
  content: "\f399";
}

.fa-bity:before {
  content: "\f37a";
}

.fa-stackpath:before {
  content: "\f842";
}

.fa-buysellads:before {
  content: "\f20d";
}

.fa-first-order:before {
  content: "\f2b0";
}

.fa-modx:before {
  content: "\f285";
}

.fa-guilded:before {
  content: "\e07e";
}

.fa-vnv:before {
  content: "\f40b";
}

.fa-square-js:before {
  content: "\f3b9";
}

.fa-js-square:before {
  content: "\f3b9";
}

.fa-microsoft:before {
  content: "\f3ca";
}

.fa-qq:before {
  content: "\f1d6";
}

.fa-orcid:before {
  content: "\f8d2";
}

.fa-java:before {
  content: "\f4e4";
}

.fa-invision:before {
  content: "\f7b0";
}

.fa-creative-commons-pd-alt:before {
  content: "\f4ed";
}

.fa-centercode:before {
  content: "\f380";
}

.fa-glide-g:before {
  content: "\f2a6";
}

.fa-drupal:before {
  content: "\f1a9";
}

.fa-jxl:before {
  content: "\e67b";
}

.fa-dart-lang:before {
  content: "\e693";
}

.fa-hire-a-helper:before {
  content: "\f3b0";
}

.fa-creative-commons-by:before {
  content: "\f4e7";
}

.fa-unity:before {
  content: "\e049";
}

.fa-whmcs:before {
  content: "\f40d";
}

.fa-rocketchat:before {
  content: "\f3e8";
}

.fa-vk:before {
  content: "\f189";
}

.fa-untappd:before {
  content: "\f405";
}

.fa-mailchimp:before {
  content: "\f59e";
}

.fa-css3-alt:before {
  content: "\f38b";
}

.fa-square-reddit:before {
  content: "\f1a2";
}

.fa-reddit-square:before {
  content: "\f1a2";
}

.fa-vimeo-v:before {
  content: "\f27d";
}

.fa-contao:before {
  content: "\f26d";
}

.fa-square-font-awesome:before {
  content: "\e5ad";
}

.fa-deskpro:before {
  content: "\f38f";
}

.fa-brave:before {
  content: "\e63c";
}

.fa-sistrix:before {
  content: "\f3ee";
}

.fa-square-instagram:before {
  content: "\e055";
}

.fa-instagram-square:before {
  content: "\e055";
}

.fa-battle-net:before {
  content: "\f835";
}

.fa-the-red-yeti:before {
  content: "\f69d";
}

.fa-square-hacker-news:before {
  content: "\f3af";
}

.fa-hacker-news-square:before {
  content: "\f3af";
}

.fa-edge:before {
  content: "\f282";
}

.fa-threads:before {
  content: "\e618";
}

.fa-napster:before {
  content: "\f3d2";
}

.fa-square-snapchat:before {
  content: "\f2ad";
}

.fa-snapchat-square:before {
  content: "\f2ad";
}

.fa-google-plus-g:before {
  content: "\f0d5";
}

.fa-artstation:before {
  content: "\f77a";
}

.fa-markdown:before {
  content: "\f60f";
}

.fa-sourcetree:before {
  content: "\f7d3";
}

.fa-google-plus:before {
  content: "\f2b3";
}

.fa-diaspora:before {
  content: "\f791";
}

.fa-foursquare:before {
  content: "\f180";
}

.fa-stack-overflow:before {
  content: "\f16c";
}

.fa-github-alt:before {
  content: "\f113";
}

.fa-phoenix-squadron:before {
  content: "\f511";
}

.fa-pagelines:before {
  content: "\f18c";
}

.fa-algolia:before {
  content: "\f36c";
}

.fa-red-river:before {
  content: "\f3e3";
}

.fa-creative-commons-sa:before {
  content: "\f4ef";
}

.fa-safari:before {
  content: "\f267";
}

.fa-google:before {
  content: "\f1a0";
}

.fa-square-font-awesome-stroke:before {
  content: "\f35c";
}

.fa-font-awesome-alt:before {
  content: "\f35c";
}

.fa-atlassian:before {
  content: "\f77b";
}

.fa-linkedin-in:before {
  content: "\f0e1";
}

.fa-digital-ocean:before {
  content: "\f391";
}

.fa-nimblr:before {
  content: "\f5a8";
}

.fa-chromecast:before {
  content: "\f838";
}

.fa-evernote:before {
  content: "\f839";
}

.fa-hacker-news:before {
  content: "\f1d4";
}

.fa-creative-commons-sampling:before {
  content: "\f4f0";
}

.fa-adversal:before {
  content: "\f36a";
}

.fa-creative-commons:before {
  content: "\f25e";
}

.fa-watchman-monitoring:before {
  content: "\e087";
}

.fa-fonticons:before {
  content: "\f280";
}

.fa-weixin:before {
  content: "\f1d7";
}

.fa-shirtsinbulk:before {
  content: "\f214";
}

.fa-codepen:before {
  content: "\f1cb";
}

.fa-git-alt:before {
  content: "\f841";
}

.fa-lyft:before {
  content: "\f3c3";
}

.fa-rev:before {
  content: "\f5b2";
}

.fa-windows:before {
  content: "\f17a";
}

.fa-wizards-of-the-coast:before {
  content: "\f730";
}

.fa-square-viadeo:before {
  content: "\f2aa";
}

.fa-viadeo-square:before {
  content: "\f2aa";
}

.fa-meetup:before {
  content: "\f2e0";
}

.fa-centos:before {
  content: "\f789";
}

.fa-adn:before {
  content: "\f170";
}

.fa-cloudsmith:before {
  content: "\f384";
}

.fa-opensuse:before {
  content: "\e62b";
}

.fa-pied-piper-alt:before {
  content: "\f1a8";
}

.fa-square-dribbble:before {
  content: "\f397";
}

.fa-dribbble-square:before {
  content: "\f397";
}

.fa-codiepie:before {
  content: "\f284";
}

.fa-node:before {
  content: "\f419";
}

.fa-mix:before {
  content: "\f3cb";
}

.fa-steam:before {
  content: "\f1b6";
}

.fa-cc-apple-pay:before {
  content: "\f416";
}

.fa-scribd:before {
  content: "\f28a";
}

.fa-debian:before {
  content: "\e60b";
}

.fa-openid:before {
  content: "\f19b";
}

.fa-instalod:before {
  content: "\e081";
}

.fa-expeditedssl:before {
  content: "\f23e";
}

.fa-sellcast:before {
  content: "\f2da";
}

.fa-square-twitter:before {
  content: "\f081";
}

.fa-twitter-square:before {
  content: "\f081";
}

.fa-r-project:before {
  content: "\f4f7";
}

.fa-delicious:before {
  content: "\f1a5";
}

.fa-freebsd:before {
  content: "\f3a4";
}

.fa-vuejs:before {
  content: "\f41f";
}

.fa-accusoft:before {
  content: "\f369";
}

.fa-ioxhost:before {
  content: "\f208";
}

.fa-fonticons-fi:before {
  content: "\f3a2";
}

.fa-app-store:before {
  content: "\f36f";
}

.fa-cc-mastercard:before {
  content: "\f1f1";
}

.fa-itunes-note:before {
  content: "\f3b5";
}

.fa-golang:before {
  content: "\e40f";
}

.fa-kickstarter:before {
  content: "\f3bb";
}

.fa-square-kickstarter:before {
  content: "\f3bb";
}

.fa-grav:before {
  content: "\f2d6";
}

.fa-weibo:before {
  content: "\f18a";
}

.fa-uncharted:before {
  content: "\e084";
}

.fa-firstdraft:before {
  content: "\f3a1";
}

.fa-square-youtube:before {
  content: "\f431";
}

.fa-youtube-square:before {
  content: "\f431";
}

.fa-wikipedia-w:before {
  content: "\f266";
}

.fa-wpressr:before {
  content: "\f3e4";
}

.fa-rendact:before {
  content: "\f3e4";
}

.fa-angellist:before {
  content: "\f209";
}

.fa-galactic-republic:before {
  content: "\f50c";
}

.fa-nfc-directional:before {
  content: "\e530";
}

.fa-skype:before {
  content: "\f17e";
}

.fa-joget:before {
  content: "\f3b7";
}

.fa-fedora:before {
  content: "\f798";
}

.fa-stripe-s:before {
  content: "\f42a";
}

.fa-meta:before {
  content: "\e49b";
}

.fa-laravel:before {
  content: "\f3bd";
}

.fa-hotjar:before {
  content: "\f3b1";
}

.fa-bluetooth-b:before {
  content: "\f294";
}

.fa-square-letterboxd:before {
  content: "\e62e";
}

.fa-sticker-mule:before {
  content: "\f3f7";
}

.fa-creative-commons-zero:before {
  content: "\f4f3";
}

.fa-hips:before {
  content: "\f452";
}

.fa-behance:before {
  content: "\f1b4";
}

.fa-reddit:before {
  content: "\f1a1";
}

.fa-discord:before {
  content: "\f392";
}

.fa-chrome:before {
  content: "\f268";
}

.fa-app-store-ios:before {
  content: "\f370";
}

.fa-cc-discover:before {
  content: "\f1f2";
}

.fa-wpbeginner:before {
  content: "\f297";
}

.fa-confluence:before {
  content: "\f78d";
}

.fa-shoelace:before {
  content: "\e60c";
}

.fa-mdb:before {
  content: "\f8ca";
}

.fa-dochub:before {
  content: "\f394";
}

.fa-accessible-icon:before {
  content: "\f368";
}

.fa-ebay:before {
  content: "\f4f4";
}

.fa-amazon:before {
  content: "\f270";
}

.fa-unsplash:before {
  content: "\e07c";
}

.fa-yarn:before {
  content: "\f7e3";
}

.fa-square-steam:before {
  content: "\f1b7";
}

.fa-steam-square:before {
  content: "\f1b7";
}

.fa-500px:before {
  content: "\f26e";
}

.fa-square-vimeo:before {
  content: "\f194";
}

.fa-vimeo-square:before {
  content: "\f194";
}

.fa-asymmetrik:before {
  content: "\f372";
}

.fa-font-awesome:before {
  content: "\f2b4";
}

.fa-font-awesome-flag:before {
  content: "\f2b4";
}

.fa-font-awesome-logo-full:before {
  content: "\f2b4";
}

.fa-gratipay:before {
  content: "\f184";
}

.fa-apple:before {
  content: "\f179";
}

.fa-hive:before {
  content: "\e07f";
}

.fa-gitkraken:before {
  content: "\f3a6";
}

.fa-keybase:before {
  content: "\f4f5";
}

.fa-apple-pay:before {
  content: "\f415";
}

.fa-padlet:before {
  content: "\e4a0";
}

.fa-amazon-pay:before {
  content: "\f42c";
}

.fa-square-github:before {
  content: "\f092";
}

.fa-github-square:before {
  content: "\f092";
}

.fa-stumbleupon:before {
  content: "\f1a4";
}

.fa-fedex:before {
  content: "\f797";
}

.fa-phoenix-framework:before {
  content: "\f3dc";
}

.fa-shopify:before {
  content: "\e057";
}

.fa-neos:before {
  content: "\f612";
}

.fa-square-threads:before {
  content: "\e619";
}

.fa-hackerrank:before {
  content: "\f5f7";
}

.fa-researchgate:before {
  content: "\f4f8";
}

.fa-swift:before {
  content: "\f8e1";
}

.fa-angular:before {
  content: "\f420";
}

.fa-speakap:before {
  content: "\f3f3";
}

.fa-angrycreative:before {
  content: "\f36e";
}

.fa-y-combinator:before {
  content: "\f23b";
}

.fa-empire:before {
  content: "\f1d1";
}

.fa-envira:before {
  content: "\f299";
}

.fa-google-scholar:before {
  content: "\e63b";
}

.fa-square-gitlab:before {
  content: "\e5ae";
}

.fa-gitlab-square:before {
  content: "\e5ae";
}

.fa-studiovinari:before {
  content: "\f3f8";
}

.fa-pied-piper:before {
  content: "\f2ae";
}

.fa-wordpress:before {
  content: "\f19a";
}

.fa-product-hunt:before {
  content: "\f288";
}

.fa-firefox:before {
  content: "\f269";
}

.fa-linode:before {
  content: "\f2b8";
}

.fa-goodreads:before {
  content: "\f3a8";
}

.fa-square-odnoklassniki:before {
  content: "\f264";
}

.fa-odnoklassniki-square:before {
  content: "\f264";
}

.fa-jsfiddle:before {
  content: "\f1cc";
}

.fa-sith:before {
  content: "\f512";
}

.fa-themeisle:before {
  content: "\f2b2";
}

.fa-page4:before {
  content: "\f3d7";
}

.fa-hashnode:before {
  content: "\e499";
}

.fa-react:before {
  content: "\f41b";
}

.fa-cc-paypal:before {
  content: "\f1f4";
}

.fa-squarespace:before {
  content: "\f5be";
}

.fa-cc-stripe:before {
  content: "\f1f5";
}

.fa-creative-commons-share:before {
  content: "\f4f2";
}

.fa-bitcoin:before {
  content: "\f379";
}

.fa-keycdn:before {
  content: "\f3ba";
}

.fa-opera:before {
  content: "\f26a";
}

.fa-itch-io:before {
  content: "\f83a";
}

.fa-umbraco:before {
  content: "\f8e8";
}

.fa-galactic-senate:before {
  content: "\f50d";
}

.fa-ubuntu:before {
  content: "\f7df";
}

.fa-draft2digital:before {
  content: "\f396";
}

.fa-stripe:before {
  content: "\f429";
}

.fa-houzz:before {
  content: "\f27c";
}

.fa-gg:before {
  content: "\f260";
}

.fa-dhl:before {
  content: "\f790";
}

.fa-square-pinterest:before {
  content: "\f0d3";
}

.fa-pinterest-square:before {
  content: "\f0d3";
}

.fa-xing:before {
  content: "\f168";
}

.fa-blackberry:before {
  content: "\f37b";
}

.fa-creative-commons-pd:before {
  content: "\f4ec";
}

.fa-playstation:before {
  content: "\f3df";
}

.fa-quinscape:before {
  content: "\f459";
}

.fa-less:before {
  content: "\f41d";
}

.fa-blogger-b:before {
  content: "\f37d";
}

.fa-opencart:before {
  content: "\f23d";
}

.fa-vine:before {
  content: "\f1ca";
}

.fa-signal-messenger:before {
  content: "\e663";
}

.fa-paypal:before {
  content: "\f1ed";
}

.fa-gitlab:before {
  content: "\f296";
}

.fa-typo3:before {
  content: "\f42b";
}

.fa-reddit-alien:before {
  content: "\f281";
}

.fa-yahoo:before {
  content: "\f19e";
}

.fa-dailymotion:before {
  content: "\e052";
}

.fa-affiliatetheme:before {
  content: "\f36b";
}

.fa-pied-piper-pp:before {
  content: "\f1a7";
}

.fa-bootstrap:before {
  content: "\f836";
}

.fa-odnoklassniki:before {
  content: "\f263";
}

.fa-nfc-symbol:before {
  content: "\e531";
}

.fa-mintbit:before {
  content: "\e62f";
}

.fa-ethereum:before {
  content: "\f42e";
}

.fa-speaker-deck:before {
  content: "\f83c";
}

.fa-creative-commons-nc-eu:before {
  content: "\f4e9";
}

.fa-patreon:before {
  content: "\f3d9";
}

.fa-avianex:before {
  content: "\f374";
}

.fa-ello:before {
  content: "\f5f1";
}

.fa-gofore:before {
  content: "\f3a7";
}

.fa-bimobject:before {
  content: "\f378";
}

.fa-brave-reverse:before {
  content: "\e63d";
}

.fa-facebook-f:before {
  content: "\f39e";
}

.fa-square-google-plus:before {
  content: "\f0d4";
}

.fa-google-plus-square:before {
  content: "\f0d4";
}

.fa-web-awesome:before {
  content: "\e682";
}

.fa-mandalorian:before {
  content: "\f50f";
}

.fa-first-order-alt:before {
  content: "\f50a";
}

.fa-osi:before {
  content: "\f41a";
}

.fa-google-wallet:before {
  content: "\f1ee";
}

.fa-d-and-d-beyond:before {
  content: "\f6ca";
}

.fa-periscope:before {
  content: "\f3da";
}

.fa-fulcrum:before {
  content: "\f50b";
}

.fa-cloudscale:before {
  content: "\f383";
}

.fa-forumbee:before {
  content: "\f211";
}

.fa-mizuni:before {
  content: "\f3cc";
}

.fa-schlix:before {
  content: "\f3ea";
}

.fa-square-xing:before {
  content: "\f169";
}

.fa-xing-square:before {
  content: "\f169";
}

.fa-bandcamp:before {
  content: "\f2d5";
}

.fa-wpforms:before {
  content: "\f298";
}

.fa-cloudversify:before {
  content: "\f385";
}

.fa-usps:before {
  content: "\f7e1";
}

.fa-megaport:before {
  content: "\f5a3";
}

.fa-magento:before {
  content: "\f3c4";
}

.fa-spotify:before {
  content: "\f1bc";
}

.fa-optin-monster:before {
  content: "\f23c";
}

.fa-fly:before {
  content: "\f417";
}

.fa-aviato:before {
  content: "\f421";
}

.fa-itunes:before {
  content: "\f3b4";
}

.fa-cuttlefish:before {
  content: "\f38c";
}

.fa-blogger:before {
  content: "\f37c";
}

.fa-flickr:before {
  content: "\f16e";
}

.fa-viber:before {
  content: "\f409";
}

.fa-soundcloud:before {
  content: "\f1be";
}

.fa-digg:before {
  content: "\f1a6";
}

.fa-tencent-weibo:before {
  content: "\f1d5";
}

.fa-letterboxd:before {
  content: "\e62d";
}

.fa-symfony:before {
  content: "\f83d";
}

.fa-maxcdn:before {
  content: "\f136";
}

.fa-etsy:before {
  content: "\f2d7";
}

.fa-facebook-messenger:before {
  content: "\f39f";
}

.fa-audible:before {
  content: "\f373";
}

.fa-think-peaks:before {
  content: "\f731";
}

.fa-bilibili:before {
  content: "\e3d9";
}

.fa-erlang:before {
  content: "\f39d";
}

.fa-x-twitter:before {
  content: "\e61b";
}

.fa-cotton-bureau:before {
  content: "\f89e";
}

.fa-dashcube:before {
  content: "\f210";
}

.fa-42-group:before {
  content: "\e080";
}

.fa-innosoft:before {
  content: "\e080";
}

.fa-stack-exchange:before {
  content: "\f18d";
}

.fa-elementor:before {
  content: "\f430";
}

.fa-square-pied-piper:before {
  content: "\e01e";
}

.fa-pied-piper-square:before {
  content: "\e01e";
}

.fa-creative-commons-nd:before {
  content: "\f4eb";
}

.fa-palfed:before {
  content: "\f3d8";
}

.fa-superpowers:before {
  content: "\f2dd";
}

.fa-resolving:before {
  content: "\f3e7";
}

.fa-xbox:before {
  content: "\f412";
}

.fa-square-web-awesome-stroke:before {
  content: "\e684";
}

.fa-searchengin:before {
  content: "\f3eb";
}

.fa-tiktok:before {
  content: "\e07b";
}

.fa-square-facebook:before {
  content: "\f082";
}

.fa-facebook-square:before {
  content: "\f082";
}

.fa-renren:before {
  content: "\f18b";
}

.fa-linux:before {
  content: "\f17c";
}

.fa-glide:before {
  content: "\f2a5";
}

.fa-linkedin:before {
  content: "\f08c";
}

.fa-hubspot:before {
  content: "\f3b2";
}

.fa-deploydog:before {
  content: "\f38e";
}

.fa-twitch:before {
  content: "\f1e8";
}

.fa-flutter:before {
  content: "\e694";
}

.fa-ravelry:before {
  content: "\f2d9";
}

.fa-mixer:before {
  content: "\e056";
}

.fa-square-lastfm:before {
  content: "\f203";
}

.fa-lastfm-square:before {
  content: "\f203";
}

.fa-vimeo:before {
  content: "\f40a";
}

.fa-mendeley:before {
  content: "\f7b3";
}

.fa-uniregistry:before {
  content: "\f404";
}

.fa-figma:before {
  content: "\f799";
}

.fa-creative-commons-remix:before {
  content: "\f4ee";
}

.fa-cc-amazon-pay:before {
  content: "\f42d";
}

.fa-dropbox:before {
  content: "\f16b";
}

.fa-instagram:before {
  content: "\f16d";
}

.fa-cmplid:before {
  content: "\e360";
}

.fa-upwork:before {
  content: "\e641";
}

.fa-facebook:before {
  content: "\f09a";
}

.fa-gripfire:before {
  content: "\f3ac";
}

.fa-jedi-order:before {
  content: "\f50e";
}

.fa-uikit:before {
  content: "\f403";
}

.fa-fort-awesome-alt:before {
  content: "\f3a3";
}

.fa-phabricator:before {
  content: "\f3db";
}

.fa-ussunnah:before {
  content: "\f407";
}

.fa-earlybirds:before {
  content: "\f39a";
}

.fa-trade-federation:before {
  content: "\f513";
}

.fa-autoprefixer:before {
  content: "\f41c";
}

.fa-whatsapp:before {
  content: "\f232";
}

.fa-square-upwork:before {
  content: "\e67c";
}

.fa-slideshare:before {
  content: "\f1e7";
}

.fa-google-play:before {
  content: "\f3ab";
}

.fa-viadeo:before {
  content: "\f2a9";
}

.fa-line:before {
  content: "\f3c0";
}

.fa-google-drive:before {
  content: "\f3aa";
}

.fa-servicestack:before {
  content: "\f3ec";
}

.fa-simplybuilt:before {
  content: "\f215";
}

.fa-bitbucket:before {
  content: "\f171";
}

.fa-imdb:before {
  content: "\f2d8";
}

.fa-deezer:before {
  content: "\e077";
}

.fa-raspberry-pi:before {
  content: "\f7bb";
}

.fa-jira:before {
  content: "\f7b1";
}

.fa-docker:before {
  content: "\f395";
}

.fa-screenpal:before {
  content: "\e570";
}

.fa-bluetooth:before {
  content: "\f293";
}

.fa-gitter:before {
  content: "\f426";
}

.fa-d-and-d:before {
  content: "\f38d";
}

.fa-microblog:before {
  content: "\e01a";
}

.fa-cc-diners-club:before {
  content: "\f24c";
}

.fa-gg-circle:before {
  content: "\f261";
}

.fa-pied-piper-hat:before {
  content: "\f4e5";
}

.fa-kickstarter-k:before {
  content: "\f3bc";
}

.fa-yandex:before {
  content: "\f413";
}

.fa-readme:before {
  content: "\f4d5";
}

.fa-html5:before {
  content: "\f13b";
}

.fa-sellsy:before {
  content: "\f213";
}

.fa-square-web-awesome:before {
  content: "\e683";
}

.fa-sass:before {
  content: "\f41e";
}

.fa-wirsindhandwerk:before {
  content: "\e2d0";
}

.fa-wsh:before {
  content: "\e2d0";
}

.fa-buromobelexperte:before {
  content: "\f37f";
}

.fa-salesforce:before {
  content: "\f83b";
}

.fa-octopus-deploy:before {
  content: "\e082";
}

.fa-medapps:before {
  content: "\f3c6";
}

.fa-ns8:before {
  content: "\f3d5";
}

.fa-pinterest-p:before {
  content: "\f231";
}

.fa-apper:before {
  content: "\f371";
}

.fa-fort-awesome:before {
  content: "\f286";
}

.fa-waze:before {
  content: "\f83f";
}

.fa-bluesky:before {
  content: "\e671";
}

.fa-cc-jcb:before {
  content: "\f24b";
}

.fa-snapchat:before {
  content: "\f2ab";
}

.fa-snapchat-ghost:before {
  content: "\f2ab";
}

.fa-fantasy-flight-games:before {
  content: "\f6dc";
}

.fa-rust:before {
  content: "\e07a";
}

.fa-wix:before {
  content: "\f5cf";
}

.fa-square-behance:before {
  content: "\f1b5";
}

.fa-behance-square:before {
  content: "\f1b5";
}

.fa-supple:before {
  content: "\f3f9";
}

.fa-webflow:before {
  content: "\e65c";
}

.fa-rebel:before {
  content: "\f1d0";
}

.fa-css3:before {
  content: "\f13c";
}

.fa-staylinked:before {
  content: "\f3f5";
}

.fa-kaggle:before {
  content: "\f5fa";
}

.fa-space-awesome:before {
  content: "\e5ac";
}

.fa-deviantart:before {
  content: "\f1bd";
}

.fa-cpanel:before {
  content: "\f388";
}

.fa-goodreads-g:before {
  content: "\f3a9";
}

.fa-square-git:before {
  content: "\f1d2";
}

.fa-git-square:before {
  content: "\f1d2";
}

.fa-square-tumblr:before {
  content: "\f174";
}

.fa-tumblr-square:before {
  content: "\f174";
}

.fa-trello:before {
  content: "\f181";
}

.fa-creative-commons-nc-jp:before {
  content: "\f4ea";
}

.fa-get-pocket:before {
  content: "\f265";
}

.fa-perbyte:before {
  content: "\e083";
}

.fa-grunt:before {
  content: "\f3ad";
}

.fa-weebly:before {
  content: "\f5cc";
}

.fa-connectdevelop:before {
  content: "\f20e";
}

.fa-leanpub:before {
  content: "\f212";
}

.fa-black-tie:before {
  content: "\f27e";
}

.fa-themeco:before {
  content: "\f5c6";
}

.fa-python:before {
  content: "\f3e2";
}

.fa-android:before {
  content: "\f17b";
}

.fa-bots:before {
  content: "\e340";
}

.fa-free-code-camp:before {
  content: "\f2c5";
}

.fa-hornbill:before {
  content: "\f592";
}

.fa-js:before {
  content: "\f3b8";
}

.fa-ideal:before {
  content: "\e013";
}

.fa-git:before {
  content: "\f1d3";
}

.fa-dev:before {
  content: "\f6cc";
}

.fa-sketch:before {
  content: "\f7c6";
}

.fa-yandex-international:before {
  content: "\f414";
}

.fa-cc-amex:before {
  content: "\f1f3";
}

.fa-uber:before {
  content: "\f402";
}

.fa-github:before {
  content: "\f09b";
}

.fa-php:before {
  content: "\f457";
}

.fa-alipay:before {
  content: "\f642";
}

.fa-youtube:before {
  content: "\f167";
}

.fa-skyatlas:before {
  content: "\f216";
}

.fa-firefox-browser:before {
  content: "\e007";
}

.fa-replyd:before {
  content: "\f3e6";
}

.fa-suse:before {
  content: "\f7d6";
}

.fa-jenkins:before {
  content: "\f3b6";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-rockrms:before {
  content: "\f3e9";
}

.fa-pinterest:before {
  content: "\f0d2";
}

.fa-buffer:before {
  content: "\f837";
}

.fa-npm:before {
  content: "\f3d4";
}

.fa-yammer:before {
  content: "\f840";
}

.fa-btc:before {
  content: "\f15a";
}

.fa-dribbble:before {
  content: "\f17d";
}

.fa-stumbleupon-circle:before {
  content: "\f1a3";
}

.fa-internet-explorer:before {
  content: "\f26b";
}

.fa-stubber:before {
  content: "\e5c7";
}

.fa-telegram:before {
  content: "\f2c6";
}

.fa-telegram-plane:before {
  content: "\f2c6";
}

.fa-old-republic:before {
  content: "\f510";
}

.fa-odysee:before {
  content: "\e5c6";
}

.fa-square-whatsapp:before {
  content: "\f40c";
}

.fa-whatsapp-square:before {
  content: "\f40c";
}

.fa-node-js:before {
  content: "\f3d3";
}

.fa-edge-legacy:before {
  content: "\e078";
}

.fa-slack:before {
  content: "\f198";
}

.fa-slack-hash:before {
  content: "\f198";
}

.fa-medrt:before {
  content: "\f3c8";
}

.fa-usb:before {
  content: "\f287";
}

.fa-tumblr:before {
  content: "\f173";
}

.fa-vaadin:before {
  content: "\f408";
}

.fa-quora:before {
  content: "\f2c4";
}

.fa-square-x-twitter:before {
  content: "\e61a";
}

.fa-reacteurope:before {
  content: "\f75d";
}

.fa-medium:before {
  content: "\f23a";
}

.fa-medium-m:before {
  content: "\f23a";
}

.fa-amilia:before {
  content: "\f36d";
}

.fa-mixcloud:before {
  content: "\f289";
}

.fa-flipboard:before {
  content: "\f44d";
}

.fa-viacoin:before {
  content: "\f237";
}

.fa-critical-role:before {
  content: "\f6c9";
}

.fa-sitrox:before {
  content: "\e44a";
}

.fa-discourse:before {
  content: "\f393";
}

.fa-joomla:before {
  content: "\f1aa";
}

.fa-mastodon:before {
  content: "\f4f6";
}

.fa-airbnb:before {
  content: "\f834";
}

.fa-wolf-pack-battalion:before {
  content: "\f514";
}

.fa-buy-n-large:before {
  content: "\f8a6";
}

.fa-gulp:before {
  content: "\f3ae";
}

.fa-creative-commons-sampling-plus:before {
  content: "\f4f1";
}

.fa-strava:before {
  content: "\f428";
}

.fa-ember:before {
  content: "\f423";
}

.fa-canadian-maple-leaf:before {
  content: "\f785";
}

.fa-teamspeak:before {
  content: "\f4f9";
}

.fa-pushed:before {
  content: "\f3e1";
}

.fa-wordpress-simple:before {
  content: "\f411";
}

.fa-nutritionix:before {
  content: "\f3d6";
}

.fa-wodu:before {
  content: "\e088";
}

.fa-google-pay:before {
  content: "\e079";
}

.fa-intercom:before {
  content: "\f7af";
}

.fa-zhihu:before {
  content: "\f63f";
}

.fa-korvue:before {
  content: "\f42f";
}

.fa-pix:before {
  content: "\e43a";
}

.fa-steam-symbol:before {
  content: "\f3f6";
}

/*!
 * Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../webfonts/fa-regular-400.woff2") format("woff2"), url("../webfonts/fa-regular-400.ttf") format("truetype");
}
.far,
.fa-regular {
  font-weight: 400;
}

/*!
 * Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../webfonts/fa-solid-900.woff2") format("woff2"), url("../webfonts/fa-solid-900.ttf") format("truetype");
}
.fas,
.fa-solid {
  font-weight: 900;
}

/*!
 * Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-light: normal 300 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("../webfonts/fa-light-300.woff2") format("woff2"), url("../webfonts/fa-light-300.ttf") format("truetype");
}
.fal,
.fa-light {
  font-weight: 300;
}

/*!
 * Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-duotone: "Font Awesome 6 Duotone";
  --fa-font-duotone: normal 900 1em/1 "Font Awesome 6 Duotone";
}

@font-face {
  font-family: "Font Awesome 6 Duotone";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../webfonts/fa-duotone-900.woff2") format("woff2"), url("../webfonts/fa-duotone-900.ttf") format("truetype");
}
.fad,
.fa-duotone {
  position: relative;
  font-weight: 900;
  letter-spacing: normal;
}

.fad::before,
.fa-duotone::before {
  position: absolute;
  color: var(--fa-primary-color, inherit);
  opacity: var(--fa-primary-opacity, 1);
}

.fad::after,
.fa-duotone::after {
  color: var(--fa-secondary-color, inherit);
  opacity: var(--fa-secondary-opacity, 0.4);
}

.fa-swap-opacity .fad::before,
.fa-swap-opacity .fa-duotone::before,
.fad.fa-swap-opacity::before,
.fa-duotone.fa-swap-opacity::before {
  opacity: var(--fa-secondary-opacity, 0.4);
}

.fa-swap-opacity .fad::after,
.fa-swap-opacity .fa-duotone::after,
.fad.fa-swap-opacity::after,
.fa-duotone.fa-swap-opacity::after {
  opacity: var(--fa-primary-opacity, 1);
}

.fad.fa-inverse,
.fa-duotone.fa-inverse {
  color: var(--fa-inverse, #fff);
}

.fad.fa-stack-1x, .fad.fa-stack-2x,
.fa-duotone.fa-stack-1x, .fa-duotone.fa-stack-2x {
  position: absolute;
}

/*!
 * Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-sharp: "Font Awesome 6 Sharp";
  --fa-font-sharp-solid: normal 900 1em/1 "Font Awesome 6 Sharp";
}

@font-face {
  font-family: "Font Awesome 6 Sharp";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../webfonts/fa-sharp-solid-900.woff2") format("woff2"), url("../webfonts/fa-sharp-solid-900.ttf") format("truetype");
}
.fass,
.fa-solid {
  font-weight: 900;
}

/*!
 * Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-thin: normal 100 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url("../webfonts/fa-thin-100.woff2") format("woff2"), url("../webfonts/fa-thin-100.ttf") format("truetype");
}
.fat,
.fa-thin {
  font-weight: 100;
}