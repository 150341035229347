/*!
 * Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2022 Fonticons, Inc.
 */
// Font Awesome core compile (Web Fonts-based)
// -------------------------

@import '@thirdparty/fontawesome-pro-6.6.0-web/scss/functions';
@import 'variables';
@import '@thirdparty/fontawesome-pro-6.6.0-web/scss/mixins';
@import 'core';
@import '@thirdparty/fontawesome-pro-6.6.0-web/scss/sizing';
@import '@thirdparty/fontawesome-pro-6.6.0-web/scss/fixed-width';
@import '@thirdparty/fontawesome-pro-6.6.0-web/scss/list';
@import '@thirdparty/fontawesome-pro-6.6.0-web/scss/bordered-pulled';
@import '@thirdparty/fontawesome-pro-6.6.0-web/scss/animated';
@import '@thirdparty/fontawesome-pro-6.6.0-web/scss/rotated-flipped';
@import '@thirdparty/fontawesome-pro-6.6.0-web/scss/stacked';
@import '@thirdparty/fontawesome-pro-6.6.0-web/scss/icons';
@import '@thirdparty/fontawesome-pro-6.6.0-web/scss/screen-reader';

@import '@thirdparty/fontawesome-pro-6.6.0-web/scss/brands';
@import '@thirdparty/fontawesome-pro-6.6.0-web/scss/regular';
@import '@thirdparty/fontawesome-pro-6.6.0-web/scss/solid';
@import '@thirdparty/fontawesome-pro-6.6.0-web/scss/light';
@import '@thirdparty/fontawesome-pro-6.6.0-web/scss/duotone';
@import '@thirdparty/fontawesome-pro-6.6.0-web/scss/sharp-solid';
@import '@thirdparty/fontawesome-pro-6.6.0-web/scss/thin';
