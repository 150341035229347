//
// Select 2
//

.select2-container {
    width: 100% !important;

    .select2-selection--single {
        border: $input-border-width solid $input-border-color;
        height: $input-height;
        background-color: $input-bg;
        outline: none;

        .select2-selection__rendered {
            line-height: 36px;
            padding-left: 12px;
            color: $input-color;
        }

        .select2-selection__arrow {
            height: 34px;
            width: 34px;
            /*rtl:ignore*/
            right: 3px;

            b {
                border-color: var(--#{$prefix}gray-500) transparent transparent transparent;
                border-width: 5px 5px 0 5px;
            }
        }
    }
}

.select2-container--open {
    .select2-selection--single {
        .select2-selection__arrow {
            b {
                border-color: transparent transparent var(--#{$prefix}gray-500) transparent !important;
                border-width: 0 5px 5px 5px !important;
            }
        }
    }
}

.select2-results__option {
    padding: 6px 12px;


}

.select2-dropdown {
    border: $dropdown-border-width solid $dropdown-border-color;
    box-shadow: var(--#{$prefix}box-shadow);
    background-color: $dropdown-bg;
    z-index: $zindex-modal + 1;
}

.select2-results__options {
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 5px;
        margin-right: 5px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(var(--#{$prefix}dark-rgb), 0.2);
        border-radius: 10px;
        margin-right: 5px;
        border: none;
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-corner {
        background-color: transparent;
    }
}

.select2-container--default {
    .select2-results__option--selected {
        background-color: var(--#{$prefix}tertiary-bg);
    }

    .select2-search--dropdown {
        padding: 10px;
        background-color: $dropdown-bg;

        .select2-search__field {
            outline: none;
            border: 1px solid $input-border-color;
            background-color: $input-bg;
            color: $input-color;
            border-radius: $input-border-radius;
        }
    }

    .select2-results__option--highlighted[aria-selected] {
        background-color: var(--#{$prefix}primary);
    }

    .select2-results__option[aria-selected="true"] {
        background-color: $dropdown-bg;
        color: $dropdown-link-active-color;

        &:hover {
            background-color: var(--#{$prefix}primary);
            color: $white;
        }
    }

    .select2-selection--multiple {
        .select2-selection__choice__display {
            padding-left: 36px;
            padding-right: 5px;
        }
    }

    .select2-selection--single {
        .select2-selection__arrow {
            /*rtl:ignore*/
            right: 1px;
            /*rtl:ignore*/
            left: auto;
        }
    }

    // validated input box
    // stylelint-disable selector-no-qualifying-type
    select.is-invalid~& .select2-selection,
    .was-validated select:invalid~& .select2-selection {
         border-color: $danger;

         @if $enable-validation-icons {
              padding-right: $input-height-inner;
              background-image: escape-svg($form-feedback-icon-invalid);
              background-repeat: no-repeat;
              background-position: right $input-height-inner-quarter center;
              background-size: $input-height-inner-half $input-height-inner-half;
         }

         &:focus {
              border-color: $danger;
              box-shadow: 0 0 0 $input-focus-width rgba($danger, .25);
         }
    }

    select.is-valid~& .select2-selection,
    .was-validated select:valid~& .select2-selection {
          border-color: var(--#{$prefix}form-valid-color);

          @if $enable-validation-icons {

               &:not([multiple]):not([size]),
               &:not([multiple])[size="1"] {
                    --#{$prefix}form-select-bg-icon: #{escape-svg($form-feedback-icon-valid)};
                    background-image: var(--#{$prefix}form-select-bg-icon, none);
                    padding-right: $form-select-feedback-icon-padding-end;
                    background-position: $form-select-feedback-icon-position;
                    background-size: $form-select-feedback-icon-size;
                    background-repeat: no-repeat;
                    @include transition($form-select-transition);
               }
          }

          &:focus {
               border-color: var(--#{$prefix}form-valid-color);
               box-shadow: 0 0 $input-btn-focus-blur $input-focus-width rgba(var(--#{$prefix}form-valid-color), $input-btn-focus-color-opacity);
          }



         /* @if $enable-validation-icons {
              padding-right: $input-height-inner;
              background-image: escape-svg($form-feedback-icon-valid);
              background-repeat: no-repeat;
              background-position: right $input-height-inner-quarter center;
              background-size: $input-height-inner-half $input-height-inner-half;
         } */

    }
}

.select2-container {
    .select2-selection--multiple {
        min-height: $input-height;
        border: $input-border-width solid $input-border-color !important;
        background-color: $input-bg;

        .select2-selection__rendered {
            padding: 1px 4px;
        }

        .select2-search__field {
            border: 0;
            color: $input-color;
        }

        .select2-selection__choice {
            background-color: var(--#{$prefix}primary);
            border: none;
            color: $white;
            border-radius: 3px;
            padding: 0px 7px 0 0;
            margin-top: 6px;
        }

        .select2-selection__choice__remove {
            color: $white;
            margin-right: 7px;
            border-color: tint-color($primary, 10%);
            padding: 0 8px;

            // position: relative;
            &:hover {
                color: $white;
                background-color: var(--#{$prefix}primary);
            }
        }
    }

    .select2-search--inline {
        .select2-search__field {
            margin-top: 7px;
            font-family: $font-family-base;
        }
    }

    .select2-search {
        textarea {
            &::placeholder {
                color: $input-placeholder-color;
            }
        }
    }
}



[dir="rtl"] .select2-container--open .select2-dropdown {
    left: auto;
    right: 0;
}
