//
// formm-wizard.scss
//

.form-wizard-header {
    margin-left: -1.5rem;
    margin-right: -1.5rem;

    .modal-body & {
        margin-left: calc(var(--ct-modal-padding) * -1);
        margin-right: calc(var(--ct-modal-padding) * -1);
    }

    background-color: var(--#{$prefix}tertiary-bg);
}
