/* html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Roboto', sans-serif;
    color: #333;
}

img {
    max-width: 100%;
    height: auto;
}

* {
    box-sizing: border-box;
}

a {
    color: $mainOrange;
} */



.hyphens-auto {
    hyphens: auto;
}